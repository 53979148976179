export const initialState = {
  isLoading: false,
  isSuccess: false,
  isLoadingSubmitApproval: false,
  isSuccessSubmitApproval: false,
  isError: false,
  isDone: false,
  message: null,
  data: {},
  userMeta: {},
};

export const APPROVAL_STATE = {
  GET_APPROVAL_BY_TOKEN: 'GET_APPROVAL_BY_TOKEN',
  GET_APPROVAL_BY_TOKEN_SUCCESS: 'GET_APPROVAL_BY_TOKEN_SUCCESS',
  GET_APPROVAL_BY_TOKEN_FAILURE: 'GET_APPROVAL_BY_TOKEN_FAILURE',

  SUBMIT_APPROVAL: 'SUBMIT_APPROVAL',
  SUBMIT_APPROVAL_SUCCESS: 'SUBMIT_APPROVAL_SUCCESS',
  SUBMIT_APPROVAL_FAILURE: 'SUBMIT_APPROVAL_FAILURE',

  RESET_LOADER: 'RESET_LOADER',
};

/* eslint-disable max-len */
import {MASTER_HOLIDAY} from '../../services';
import {MASTER_HOLIDAY_STATE as STATE} from './masterHoliday.types';
import {transformObject, isArray} from '../../helpers';

export const getMasterHoliday = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    MASTER_HOLIDAY.getMasterHoliday(params)
      .then(
          (response) => {
            dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
          },
          (error) => {
            const response = error.response.data;
            dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
          },
      );
  };
};

export const createMasterHoliday = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE));
    MASTER_HOLIDAY.createMasterHoliday(params)
      .then(
          (response) => {
            dispatch(requestSuccess(STATE.CREATE_SUCCESS, response.data.data.message));
          },
          (error) => {
            const errors = error.response.data.data.errors;
            const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
            dispatch(requestFailed(STATE.CREATE_FAILED, message));
          },
      );
  };
};

export const updateMasterHoliday = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    MASTER_HOLIDAY.updateMasterHoliday(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const deleteMasterHoliday = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.DROP));
    MASTER_HOLIDAY.deleteMasterHoliday(id)
      .then(
          (response) => {
            dispatch(requestSuccess(STATE.DROP_SUCCESS, response.data.data.message));
          },
          (error) => {
            const errors = error.response.data.data.errors;
            const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
            dispatch(requestFailed(STATE.DROP_FAILED, message));
          },
      );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

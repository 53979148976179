/* eslint-disable max-len */
export const policy = [
    {
        title: 'Pemberitahuan Privasi',
        line1: 'Situs web dan atau aplikasi ini (“Pagii”) dikelola dan dioperasikan oleh PT. Smooets Technology Outsourcing dan afiliasinya (“Kami”). Adanya Kebijakan Privasi ini adalah komitmen Kami untuk menghargai dan melindungi setiap data atau informasi pribadi Pengguna situs www.pagii.co, serta aplikasi gawai Pagii yang tersedia di platform android maupun iOS.',
        line2: 'Pemberitahuan Privasi ini meliputi proses perolehan, pengelolaan, penggunaan, perlindungan yang berhubungan dengan informasi dan data pribadi pengguna berdasarkan aturan perundang-undangan yang berlaku di wilayah Negara Kesatuan Republik Indonesia dan standar keamanan informasi yang bersifat global. Dalam proses peningkatan layanan kami, Pemberitahuan Privasi bisa berubah atau diperbaharui sewaktu-waktu tanpa ada pemberitahuan dan/atau pengumuman terlebih dahulu.',
        line3: 'Dengan mengakses dan menggunakan layanan pada Platform Pagii, Anda dianggap telah membaca, memahami, dan memberikan persetujuan kepada Kami atas pengumpulan, pemrosesan, pengungkapan, dan penggunaan informasi dan juga data pribadi Anda.',
        childLine: [],
    },
    {
        title: 'Perolehan Data Pribadi',
        line1: 'Untuk menunjang layanan yang disediakan Platform dan Aplikasi Pagii, kami akan melakukan pengambilan data pribadi dan informasi lainnya termasuk dan tidak terbatas kepada nama, alamat, nomor telepon, email, dan informasi lainnya yang dapat diperlukan (“Data Pribadi”). ',
        line2: 'Proses pengumpulan, pengolahan dan penyimpanan Data Pribadi melalui mekanisme sebagai berikut:',
        line3: null,
        childLine: [],
    },
    {
        title: 'Data/Informasi yang Diberikan',
        line1: 'Data /informasi pribadi yang diserahkan secara mandiri dan sukarela oleh Anda, namun tidak terbatas pada saat:',
        line2: `1. Pendaftaran atau pengisian formulir digital dalam penggunaan layanan Pagii baik melalui situs atau aplikasi Pagii.\n2. Memberikan data pribadi pada saat mengakses dan/atau menggunakan layanan dari Pihak Ketiga yang telah bekerjasama secara resmi dengan Pagii.\n3. Memberikan informasi tambahan kepada Pagii dalam bentuk foto, tulisan, atau tanggapan lain dalam penggunaan aplikasi Pagii untuk kelengkapan data Perusahaan Anda.`,
        line3: null,
        childLine: [],
    },
    {
        title: 'Data/Informasi yang Terkoleksi Secara Otomatis',
        line1: 'Untuk peningkatan layanan seperti ketepatan lokasi absensi, Kami mengumpulkan informasi lokasi pengguna dari perangkat Anda yang terhubungkan dengan layanan kami dan sudah mendapatkan izin dari Anda melalui fitur perangkat atau aplikasi. Lokasi ini tidak terbatas kepada alamat tempat bekerja yang berdasarkan wilayah, kota, provinsi namun juga berdasarkan koordinat lokasi pengguna.',
        line2: null,
        line3: null,
        childLine: [],
    },
    {
        title: 'Tujuan Penggunaan',
        line1: 'Alasan Kami menggunakan informasi pribadi Anda adalah agar kami dapat menyediakan layanan absensi secara real time dan. Membantu Anda mengelola absensi dan manajerial perusahaan dengan akurat dan efisien.',
        line2: 'Informasi data Data Pribadi yang telah diperoleh senantiasa Kami kelola dengan mengikuti kaidah dan standar keamanan yang sesuai. Adapun data atau informasi yang Kami peroleh akan digunakan untuk kepentingan pengguna termasuk namun tidak terbatas kepada:',
        line3: null,
        childLine: [
            {
                childTitle: '1. Komunikasi',
                childDesc: 'Menghubungkan Anda dengan Kami, dalam bentuk pertukaran atau pengiriman informasi untuk menciptakan pemahaman menyeluruh terkait produk, layanan, verifikasi, serta pelayanan konsumen apabila terdapat permasalahan dengan penggunaan layanan pada platform Pagii.',
            },
            {
                childTitle: '2. Pemasaran Reguler',
                childDesc: 'Memberikan informasi kepada Anda terkait dengan penawaran khusus, pengumuman, berita, pesan singkat melalui e-mail ataupun WhatsApp di ponsel Anda dan lain-lain.',
            },
            {
                childTitle: '3. Penyediaan Layanan',
                childDesc: 'Memberikan Anda Layanan Pagii serta fungsi utamanya untuk mempermudah Anda dalam melakukan manajerial perusahaan serta proses rekap data untuk kepentingan perusahaan Anda. Kami tidak akan mengakses, menggunakan, atau menyalahgunakan Informasi Pribadi Anda untuk kepentingan pribadi Kami.',
            },
            {
                childTitle: '4. Catatan Administrasi',
                childDesc: 'Untuk kepentingan catatan internal/administrasi Kami dalam proses analisa pengguna layanan. Serta untuk kepentingan audit dan atau pemeriksaan lainnya terkait dengan penggunaan Platform Pagii.',
            },
            {
                childTitle: '5. Ekspansi dan/atau Pengembangan Bisnis',
                childDesc: 'Kami tentu akan terus membuka peluang untuk pengemebangan bisnis sehingga Kami dapat meningkatkan kualitas dan memfasilitasi kebutuhan Anda.\nKami dapat menggunakan layanan Pihak Ketiga untuk membantu mencapai Tujuan Kami agar dapat lebih memahami Anda dan kualitas layanan Kami. Kami tidak akan memberikan izin kepada Pihak Ketiga untuk menggunakan Informasi Pribadi Anda untuk tujuannya sendiri.',
            },
        ],
    },
    {
        title: 'Hak Akses Data Pribadi',
        line1: 'Sebagai penyedia jasa layanan, Kami memiliki catatan terhadap Data dan Informasi dari setiap pengguna. Sebagai pemilik dari Data Pribadi, pengguna berhak untuk mendapatkan informasi terkait informasi Pribadi dan informasi transaksional seperti pembayaran dan metode pembayaran, pengiriman produk, daftar transaksi dan beberapa informasi terkait data pengguna.',
        line2: 'Selain itu, pengguna dapat melakukan perubahan, perbaikan, atau penghapusan terhadap data pribadi pengguna melalui mekanisme yang telah kami siapkan dengan mengikuti kaidah hukum yang berlaku. Dengan mengikuti dan menggunakan prosedur tersebut, kami menganggap data yang diberikan adalah data yang syah sesuai dengan fakta dan hukum dari kepemilikan data tersebut dan/atau setelah mendapat persetujuan dari pemilik data.',
        line3: null,
        childLine: [],
    },
    {
        title: 'Jangka Waktu Penyimpanan',
        line1: 'Kami akan menyimpan data riwayat kehadiran karyawan termasuk dan tidak terbatas kepada, nama, waktu mulai bekerja, waktu selesai bekerja, lokasi mulai bekerja dan selesai bekerja, foto, dan juga deskripsi pekerjaan dalam tiga bulan. Kami akan melakukan penghapusan data riwayat tersebut setiap tiga bulan sekali. Anda disarankan untuk melakukan export data setiap bulannya  secara manual melalui fitur Export yang telah kami sediakan di platform mobile maupun website. Penghapusan data otomatis melalui sistem ini kami maksudkan untuk melindungi data Anda dari kemungkinan-kemungkinan yang tidak diinginkan yang dapat merugikan Anda sebagai pengguna. Selain itu kapan saja, jika Anda ingin data Anda dihapus, Kami akan segera bertindak untuk menghapus data Anda dari semua basis data kami pada waktu yang tepat.',
        line2: null,
        line3: null,
        childLine: [],
    },
    {
        title: 'Cookie',
        line1: 'Cookie adalah file dengan sejumlah kecil data yang biasanya digunakan sebagai pengidentifikasi unik anonim. Ini dikirim ke browser Anda dari situs web yang Anda kunjungi dan disimpan di memori internal perangkat Anda.Layanan ini tidak menggunakan “cookies” ini secara eksplisit. Namun, aplikasi dapat menggunakan kode dan perpustakaan pihak ketiga yang menggunakan “cookies” untuk mengumpulkan informasi dan meningkatkan layanan mereka. Anda memiliki pilihan untuk menerima atau menolak cookie ini dan mengetahui kapan cookie dikirim ke perangkat Anda. Jika Anda memilih untuk menolak cookie kami, Anda mungkin tidak dapat menggunakan beberapa bagian dari Layanan ini.',
        line2: null,
        line3: null,
        childLine: [],
    },
    {
        title: 'Keamanan',
        line1: 'Kami berkomitmen dalam memberikan upaya maksimal untuk menjaga keamanan Data Pribadi pengguna dengan menerapkan standar keamanan dan mengikuti peraturan perundang-undangan yang berlaku. Namun, pengguna perlu menyadari dan memahami bahwa tidak ada jaminan keamanan yang mutlak terhadap segala risiko secara utuh.',
        line2: 'Berikut adalah hal-hal yang kami lakukan untuk menjaga keamanan data Anda:',
        line3: null,
        childLine: [
            {
                childTitle: '1. Security Socket Layer (SSL) 256-bit',
                childDesc: 'Tanda gembok di alamat situs (URL) Pagii (pagii.co) menunjukkan bahwa situs kami terlindungi oleh Security Socket Layer atau SSL. SSL memastikan bahwa data yang diterima tidak mengalami perubahan dalam perjalanannya dan tidak dapat dibaca oleh pihak-pihak lain.',
            },
            {
                childTitle: '2. Penyimpanan Data',
                childDesc: 'Data Anda tersimpan di server Spaces by Digitalocean, yang merupakan salah satu web host paling aman di dunia karena menggunakan end-to-end encryption.',
            },
        ],
    },
    {
        title: 'Tautan ke Situs Lain',
        line1: 'Layanan ini mungkin berisi tautan ke situs lain. Jika Anda mengklik tautan pihak ketiga, Anda akan diarahkan ke situs itu. Perhatikan bahwa situs eksternal ini tidak dioperasikan oleh kami. Oleh karena itu, kami sangat menyarankan Anda untuk meninjau Kebijakan Privasi situs web ini. Kami tidak memiliki kendali atas dan tidak bertanggung jawab atas konten, kebijakan privasi, atau praktik situs atau layanan pihak ketiga mana pun.',
        line2: null,
        line3: null,
        childLine: [],
    },
    {
        title: 'Ketentuan Pihak Ketiga',
        line1: 'Kami tidak akan mengungkapkan, memberikan akses, atau menyebarkan Data Pribadi Anda tanpa persetujuan dari Anda kecuali ditentukan lain dalam Kebijakan ini. Kami hanya akan mengungkapkan Data Pribadi Anda kepada pihak lain (i) berdasarkan persetujuan Anda atau (ii) apabila diizinkan atau dipersyaratkan oleh Hukum Yang Berlaku atau (iii) diinstruksikan oleh pemerintah yang berwenang, atau (iv) berdasarkan perintah pengadilan (sebagaimana relevan).',
        line2: 'Kami tidak akan menjual atau menyewakan Informasi Pribadi Anda ke pihak manapun.',
        line3: null,
        childLine: [],
    },
    {
        title: 'Privasi Anak-anak',
        line1: 'Layanan ini tidak ditujukan kepada siapa pun yang berusia di bawah 13 tahun. Kami tidak dengan sengaja mengumpulkan informasi pengenal pribadi dari anak-anak di bawah 13 tahun. Jika kami menemukan bahwa seorang anak di bawah 13 tahun telah memberi kami informasi pribadi, kami segera menghapusnya dari server kami. Jika Anda adalah orang tua atau wali dan Anda mengetahui bahwa anak Anda telah memberikan informasi pribadi kepada kami, silakan hubungi kami sehingga kami dapat melakukan tindakan yang diperlukan.',
        line2: null,
        line3: null,
        childLine: [],
    },
    {
        title: 'Penghapusan Data Pribadi',
        line1: 'Sebagai penyedia jasa layanan, Kami akan melakukan penyimpanan Data Pribadi selama pengguna menggunakan layanan Pagii atau selama akun pengguna masih aktif.',
        line2: 'Perusahaan dapat melakukan penghapusan Data Pribadi sesuai ketentuan hukum yang berlaku, termasuk namun tidak terbatas dengan sebab sebagai berikut:',
        line3: '1. Data Pribadi pengguna tidak lagi diperlukan untuk memenuhi tujuan pengumpulan;\n2. Penyimpanan Data Pribadi pengguna tidak lagi diperlukan untuk memenuhi kewajiban kepatuhan terhadap ketentuan hukum yang berlaku; atau\n3. Pengguna mengajukan permohonan penghapusan Data Pribadi pada Platform Pagii.',
        childLine: [],
    },
    {
        title: 'Bantuan Lebih Lanjut',
        line1: 'Pengguna dapat menyampaikan pertanyaan, komentar, atau keperluan lainnya sehubungan dengan Pemberitahuan Privasi ini, termasuk mengajukan permohonan penarikan kembali persetujuan, akses, koreksi, atau penghapusan terhadap data pribadinya dengan cara menghubungi tim Support Pagii melalui WhatsApp di +62 812-2285-5971 atau melalui e-mail support@pagii.co.',
        line2: null,
        line3: null,
        childLine: [],
    },
];

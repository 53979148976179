export default (theme) => ({
  headerTemplate: {
    background: '#fff',
  },
  imgHeaderTemplate: {
    width: '310px',
    display: 'flex',
    margin: 'auto',
    padding: 30,
  },
  webTemplate: {
    background: '#eee',
    minHeight: '100vh',
    flexDirection: 'column',
    display: 'flex',
  },
  footerTemplate: {
    padding: '3em 0',
    backgroundColor: '#4d4d4d !important',
  },
  footerLogo: {
    '@media (max-width: 780px)': {
      display: 'none',
    },
  },
});

import {ENDPOINTS, apiService} from '../config';

export const getAll = (params) => {
  return apiService.get(
      ENDPOINTS.SHIFT_MANAGEMENT.ALL,
      {params},
  );
};

export const update = (params) => {
  return apiService.put(
      ENDPOINTS.SHIFT_MANAGEMENT.UPDATE,
      params,
  );
};


/* eslint-disable max-len */
import {initialState, LEAVE_MANAGEMENT_STATE as STATE} from './leaveManagement.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.requestType,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.requestType,
      };
    case STATE.FETCH_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        leaveManagementData: action.payload.data,
        leaveManagementMeta: action.payload.meta,
        trigger: action.type,
      };
    case STATE.FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.requestType,
      };
    case STATE.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

/* eslint-disable max-len */
/* eslint-disable react/display-name */
import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  IconButton,
  withStyles,
  Button,
  Paper,
  Grid,
} from '@material-ui/core';
import {ReactComponent as Search} from '../../../../assets/icon/event-management/search.svg';
import {
  MInput,
  MSelect,
  MKeyBoardDatepicker,
  MButton,
} from '../../../../components/form';
import styles from './styles.js';
import {convDate, showPopup} from '../../../../helpers/index.js';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getEvents, requestCancelEvent, togglePopupAnnouncement, togglePopupRequestCancelEvent} from '../../../../redux/eventManagement/eventManagement.actions.js';
import {Datatable} from '../../../../components/index.js';
import RenderActions from './actionMenu';
import {Refresh} from '@material-ui/icons';
import moment from 'moment';
import Popup from '../../components/popup/index.jsx';
import RequestCancelApprovalPopup from '../requestCancelApprovalPopup/index.jsx';

const eventTypeOptions = [
  {id: 'private', name: 'Private'},
  {id: 'public', name: 'Public'},
  {id: 'all', name: 'All'},
];

const initialFilter = {
  pagination: true,
  page: 1,
  limit: 10,
  keyword: null,
  type: null,
  start_date: null,
  end_date: null,
};

const columns = [
  {name: 'id', display: false},
  {name: 'title', label: 'Event Name', display: true},
  {
    name: 'date_start',
    label: 'Start Date',
    display: true,
    displayName: 'title',
    customBodyRender: (value) => {
      return value ? (
        <Button
          variant="outlined"
          size="small"
          style={{whiteSpace: 'nowrap'}}
        >
          {convDate(value, 'DD-MM-YYYY')}
        </Button>
      ) : (
        '-'
      );
    },
  },
  {
    name: 'date_end',
    label: 'End Date',
    display: true,
    customBodyRender: (value) => {
      return value ? (
        <Button
          variant="outlined"
          size="small"
          style={{whiteSpace: 'nowrap'}}
        >
          {convDate(value, 'DD-MM-YYYY')}
        </Button>
      ) : (
        '-'
      );
    },
  },
  {
    name: 'type',
    label: 'Type',
    display: true,
    customBodyRender: (value) => {
      return <span style={{textTransform: 'capitalize'}}>{value}</span>;
    },
  },
  {
    name: 'payment_type',
    label: 'Payment',
    display: true,
    customBodyRender: (value) => {
      return value === 'free' ? (
        <Box
          sx={{
            backgroundColor: '#008000',
            padding: '2px 0px',
            color: '#FFF',
            borderRadius: '20px',
            textAlign: 'center',
            width: '80px',
            textTransform: 'capitalize',
          }}
        >
          {value}
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: '#00AFF0',
            padding: '2px 0px',
            color: '#FFF',
            borderRadius: '20px',
            textAlign: 'center',
            width: '80px',
            textTransform: 'capitalize',
          }}
        >
          {value}
        </Box>
      );
    },
  },
  {
    name: 'status',
    label: 'Status',
    display: true,
    customBodyRender: (value) => {
      return value === 'active' ? (
        <span
          style={{
            color: '#008000',
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        >
          published
        </span>
      ) : value === 'inactive' ? (
        <span
          style={{
            color: '#E5AF5A',
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        >
          draft
        </span>
      ) : value === 'ended' ? (
        <span
          style={{
            color: '#777777',
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        >
          {value?.replace('_', ' ')}
        </span>
      ) : (
        <span
          style={{
            color: '#F22951',
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        >
          {value?.replace('_', ' ')}
        </span>
      );
    },
  },
];

function EventListHR({classes}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterState, setFilterState] = useState({...initialFilter});
  const [selectedEventId, setSelectedEventId] = useState();
  const [cancelEventId, setCancelEventId] = useState();

  const {
    isLoading,
    events,
    showPopupAnnouncement,
    showPopupRequestCancelEvent,
  } = useSelector((state) => state.eventManagement);

  const toggleCancelEventPopup = (id = null) => {
    setCancelEventId(typeof id === 'string' ? id : null);
    dispatch(togglePopupRequestCancelEvent());
  };

  const fetchList = useCallback((params = initialFilter) => {
    const newParams = {
      ...initialFilter,
      ...params,
      limit: params?.limit !== initialFilter?.limit ? params?.limit : initialFilter?.limit,
    };

    if (params?.sortBy) {
      delete newParams.sortBy;
      newParams.order = params.sortBy;
      newParams.sort = params.order?.toUpperCase();
    }

    setFilterState({...newParams});
    dispatch(getEvents(newParams));
  }, [dispatch]);

  const handleCancelEvent = (reason) => {
    showPopup('confirm', 'Are you sure want to make a request to cancel this event?', 'Yes', () => {
      dispatch(requestCancelEvent(cancelEventId, {reason}, filterState));
    });
  };

  const handleToggleCancelEvent = (data) => {
    const id = data[1];
    const status = data[7];
    const startDate = moment(data[3]);
    const diff = startDate.diff(moment(), 'days');

    if (status !== 'active') return;

    if (diff < 5) {
      showPopup(
        'error',
        'You cannot request to cancel this event at this time. The deadline to cancel an event is 5 days before the scheduled start date. Please contact support@pagii.co',
      );
     } else {
      toggleCancelEventPopup(id);
    }
  };

  const handleDetail = (id) => {
    if (!id) return;
    history.push('/event-management/detail', {id});
  };

  const handleFilterChange = (event) => {
    const {name, value} = event?.target || {};

    setFilterState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (name, value) => {
    setFilterState((prevState) => ({
      ...prevState,
      [name]: convDate(value, 'YYYY-MM-DD'),
    }));
  };

  const handleApplyFilter = () => {
    if (!filterState?.keyword && !filterState?.type && !filterState?.end_date && !filterState?.start_date) return;
    fetchList({
      ...filterState,
      type: filterState?.type === 'all' ? null : filterState?.type,
    });
  };

  const handleResetFilter = () => {
    if (!filterState?.keyword && !filterState?.type && !filterState?.end_date && !filterState?.start_date) return;
    const newFilter = {
      ...filterState,
      ...initialFilter,
      page: filterState?.page || initialFilter?.page,
      limit: filterState?.limit || initialFilter?.limit,
    };

    fetchList(newFilter);
    setFilterState({...newFilter});
  };

  const toggleAnnouncement = (id) => {
    setSelectedEventId(id);
    dispatch(togglePopupAnnouncement());
  };

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <>
      <Popup
        open={showPopupAnnouncement}
        setOpen={toggleAnnouncement}
        title="Create Announcement"
        id={selectedEventId}
      />

      <RequestCancelApprovalPopup
        open={showPopupRequestCancelEvent}
        toggle={toggleCancelEventPopup}
        title="Request Cancel Event"
        handleSubmit={handleCancelEvent}
      />

      <Paper variant="outlined" className={classes.paper}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <MInput
              autoFocus
              fullWidth
              variant="standard"
              name="keyword"
              label="Search"
              placeholder="Search keyword"
              onChange={handleFilterChange}
              value={filterState?.keyword || ''}
              InputProps={{
                endAdornment: <IconButton className={classes.searchIcon} position="end">
                  <Search/>
                </IconButton>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <MSelect
              shrink={true}
              fullWidth
              variant="standard"
              name="type"
              label="Type"
              keyPair={['id', 'name']}
              options={eventTypeOptions}
              onChange={handleFilterChange}
              value={filterState?.type}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <MKeyBoardDatepicker
              fullWidth
              label="Start Date"
              name="start_date"
              onChange={(value) => handleDateChange('start_date', value)}
              value={filterState?.start_date}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <MKeyBoardDatepicker
              fullWidth
              label="End Date"
              name="end_date"
              minDate={filterState?.start_date}
              onChange={(value) => handleDateChange('end_date', value)}
              value={filterState?.end_date}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" direction="row-reverse">
          <Grid item>
            <MButton
              label="Reset"
              color="primary"
              icon={<Refresh />}
              style={{height: '32px'}}
              onClick={handleResetFilter}
            />
          </Grid>

          <Grid item>
            <MButton
              label="Apply"
              color="primary"
              style={{height: '32px'}}
              onClick={handleApplyFilter}
            />
          </Grid>
        </Grid>
      </Paper>

      <Datatable
        title={'Event List'}
        loading={isLoading}
        dataSource={events?.events || []}
        total={events?.paginator?.itemCount || 0}
        page={events?.paginator?.page || initialFilter.page}
        columns={columns}
        customActions={(data) =>
          <RenderActions
            handleDetail={handleDetail}
            handleCancelEvent={() => handleToggleCancelEvent(data)}
            data={data}
            toggleAnnouncement={toggleAnnouncement}
          />}
        handleReload={(params) => fetchList({...filterState, ...params})}
        handleCreate={() => history.push('/event-management/create')}
      />
    </>
  );
}

export default withStyles(styles)(EventListHR);

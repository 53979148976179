import {ENDPOINTS, apiService} from '../config';

export const getAll = (params) => {
  return apiService.get(
      ENDPOINTS.COMPANY_TYPE.ALL,
      {params},
  );
};

export const postCompanyType = (params) => {
  return apiService.post(
      ENDPOINTS.COMPANY_TYPE.ADD,
      params,
  );
};

export const updateCompanyType = (id, params) => {
  return apiService.put(
      ENDPOINTS.COMPANY_TYPE.UPDATE + id,
      params,
  );
};

export const dropCompanyType = (id) => {
  return apiService.delete(
      ENDPOINTS.COMPANY_TYPE.DELETE + id,
  );
};



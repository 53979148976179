export default (theme) => ({
  menuActionBlock: {
    color: 'crimson',
  },
  paper: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    border: 'none',
    boxShadow: 'none',
    borderRadius: 18,
  },
  btnActionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'end !important',
  },
  btnAction: {
    'backgroundColor': '#00AFF0',
    'color': '#FFF',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
  },
  searchAndFilter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start !important',
    alignItems: 'end',
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  searchInput: {
    width: '250px',
    marginBottom: '8px',
  },
  filterType: {
    marginLeft: 10,
    marginBottom: '8px',
    marginRight: 12,
    width: '150px',
  },
  filterCheckbox: {
    height: '100% !important',
    marginBottom: '8px',
  },
  filter: {
    marginRight: 25,
  },
  dateInput: {
    width: '200px',
  },
  tableTitle: {
    fontSize: '18px',
    fontWeight: '700',
    color: '#656464',
    fontFamily: 'Poppins !important',
    marginTop: '-2px !important',
    marginLeft: '-8px !important',
  },
  tableTooltip: {
    fontSize: '14px',
  },
});

export const initialState = {
  isLoading: false,
  isLoadingCancelApproval: false,
  isLoadingRequestCancel: false,
  isLoadingBlockEvent: false,
  isLoadingAnnouncement: false,
  isLoadingUpdateVoucher: false,
  isLoadingExportParticipant: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  message: null,
  events: [],
  eventDetail: null,
  participants: [],
  eventQR: null,
  showPopupAnnouncement: false,
  showPopupRequestCancelEvent: false,
  showPopupCancelApproval: false,
  showPopupBlockEvent: false,
  showPopupDuplicated: false,
  exportData: {file: null},
  isloadingLocation: false,
  locationDataEvent: [],
  isLoadingEventGroup: false,
  eventGroups: [],
  eventGroupDetail: null,
};

export const EVENT_MANAGEMENT_STATE = {
  REQUEST: 'EVENT_MANAGEMENT_REQUEST',
  REQUEST_FAILED: 'EVENT_MANAGEMENT_REQUEST_FAILED',
  CLEAR_STATE: 'EVENT_MANAGEMENT_CLEAR_STATE',
  CREATE_EVENT: 'CREATE_EVENT',
  CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
  CREATE_EVENT_FAILED: 'CREATE_EVENT_FAILED',
  UPDATE_EVENT: 'UPDATE_EVENT',
  UPDATE_EVENT_SUCCESS: 'UPDATE_EVENT_SUCCESS',
  UPDATE_EVENT_FAILED: 'UPDATE_EVENT_FAILED',
  REQUEST_CANCEL_EVENT: 'REQUEST_CANCEL_EVENT',
  REQUEST_CANCEL_EVENT_SUCCESS: 'REQUEST_CANCEL_EVENT_SUCCESS',
  REQUEST_CANCEL_EVENT_FAILED: 'REQUEST_CANCEL_EVENT_FAILED',
  CANCEL_APPROVAL_EVENT: 'CANCEL_APPROVAL_EVENT',
  CANCEL_APPROVAL_EVENT_SUCCESS: 'CANCEL_APPROVAL_EVENT_SUCCESS',
  CANCEL_APPROVAL_EVENT_FAILED: 'CANCEL_APPROVAL_EVENT_FAILED',
  BLOCK_EVENT: 'BLOCK_EVENT',
  BLOCK_EVENT_SUCCESS: 'BLOCK_EVENT_SUCCESS',
  BLOCK_EVENT_FAILED: 'BLOCK_EVENT_FAILED',
  FETCH_EVENTS: 'FETCH_EVENTS',
  FETCH_EVENTS_SUCCESS: 'FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_FAILED: 'FETCH_EVENTS_FAILED',
  FETCH_EVENT_DETAIL: 'FETCH_EVENT_DETAIL',
  FETCH_EVENT_DETAIL_SUCCESS: 'FETCH_EVENT_DETAIL_SUCCESS',
  FETCH_EVENT_DETAIL_FAILED: 'FETCH_EVENT_DETAIL_FAILED',
  FETCH_PARTICIPANTS: 'FETCH_PARTICIPANTS',
  FETCH_PARTICIPANTS_SUCCESS: 'FETCH_PARTICIPANTS_SUCCESS',
  FETCH_PARTICIPANTS_FAILED: 'FETCH_PARTICIPANTS_FAILED',
  FETCH_EVENT_QR: 'FETCH_EVENT_QR',
  FETCH_EVENT_QR_SUCCESS: 'FETCH_EVENT_QR_SUCCESS',
  FETCH_EVENT_QR_FAILED: 'FETCH_EVENT_QR_FAILED',
  GENERATE_EVENT_QR: 'GENERATE_EVENT_QR',
  GENERATE_EVENT_QR_SUCCESS: 'GENERATE_EVENT_QR_SUCCESS',
  GENERATE_EVENT_QR_FAILED: 'GENERATE_EVENT_QR_FAILED',
  TOGGLE_POPUP_EVENT_ANNOUNCEMENT: 'TOGGLE_POPUP_EVENT_ANNOUNCEMENT',
  TOGGLE_POPUP_REQUEST_CANCEL_EVENT: 'TOGGLE_POPUP_REQUEST_CANCEL_EVENT',
  TOGGLE_POPUP_CANCEL_APPROVAL_EVENT: 'TOGGLE_POPUP_CANCEL_APPROVAL_EVENT',
  TOGGLE_POPUP_BLOCK_EVENT: 'TOGGLE_POPUP_BLOCK_EVENT',
  TOGGLE_POPUP_DUPLICATED: 'TOGGLE_POPUP_DUPLICATED',
  SEND_EVENT_ANNOUNCEMENT: 'SEND_EVENT_ANNOUNCEMENT',
  SEND_EVENT_ANNOUNCEMENT_SUCCESS: 'SEND_EVENT_ANNOUNCEMENT_SUCCESS',
  SEND_EVENT_ANNOUNCEMENT_FAILED: 'SEND_EVENT_ANNOUNCEMENT_FAILED',
  UPDATE_EVENT_VOUCHER: 'UPDATE_EVENT_VOUCHER',
  UPDATE_EVENT_VOUCHER_SUCCESS: 'UPDATE_EVENT_VOUCHER_SUCCESS',
  UPDATE_EVENT_VOUCHER_FAILED: 'UPDATE_EVENT_VOUCHER_FAILED',
  EXPORT_PARTICIPANTS: 'EXPORT_PARTICIPANTS',
  EXPORT_PARTICIPANTS_SUCCESS: 'EXPORT_PARTICIPANTS_SUCCESS',
  EXPORT_PARTICIPANTS_FAILED: 'EXPORT_PARTICIPANTS_FAILED',
  GET_LIST_LOCATION_EVENT: 'GET_LIST_LOCATION_EVENT',
  GET_LIST_LOCATION_EVENT_SUCCESS: 'GET_LIST_LOCATION_EVENT_SUCCESS',
  GET_LIST_LOCATION_EVENT_FAILED: 'GET_LIST_LOCATION_EVENT_FAILED',
  REQUEST_GROUP: 'EVENT_MANAGEMENT_REQUEST_GROUP',
  REQUEST_GROUP_FAILED: 'EVENT_MANAGEMENT_REQUEST_GROUP_FAILED',
  CREATE_EVENT_GROUP_SUCCESS: 'CREATE_EVENT_GROUP_SUCCESS',
  CREATE_EVENT_GROUP_FAILED: 'CREATE_EVENT_GROUP_FAILED',
  UPDATE_EVENT_GROUP: 'UPDATE_EVENT_GROUP',
  UPDATE_EVENT_GROUP_SUCCESS: 'UPDATE_EVENT_GROUP_SUCCESS',
  UPDATE_EVENT_GROUP_FAILED: 'UPDATE_EVENT_GROUP_FAILED',
  FETCH_EVENT_GROUPS: 'FETCH_EVENT_GROUPS',
  FETCH_EVENT_GROUPS_SUCCESS: 'FETCH_EVENT_GROUPS_SUCCESS',
  FETCH_EVENT_GROUPS_FAILED: 'FETCH_EVENT_GROUPS_FAILED',
  FETCH_EVENT_GROUP_DETAIL: 'FETCH_EVENT_GROUP_DETAIL',
  FETCH_EVENT_GROUP_DETAIL_SUCCESS: 'FETCH_EVENT_GROUP_DETAIL_SUCCESS',
  FETCH_EVENT_GROUP_DETAIL_FAILED: 'FETCH_EVENT_GROUP_DETAIL_FAILED',
};

export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: 18,
    paddingBottom: 14,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  formControl: {
    marginBottom: 0,
    marginTop: 0,
    minWidth: 200,
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  checkInTrue: {
    color: '#89d300',
    fontSize: 20,
  },
  checkInFalse: {
    color: '#FFCC00',
    fontSize: 20,
  },
  tableHeaderIcon: {
    fontSize: 30,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    marginBottom: 30,
    textalign: 'center',
    fontWeight: 600,
  },
  fade: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    width: 600,
  },
  modalItemSpacing: {
    margin: theme.spacing(1),
  },
  bold: {
    fontWeight: 'bold',
    color: 'black',
  },
  modalAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  modalCloseBtn: {
    float: 'right',
    marginBottom: 10,
  },
  dialogContent: {
    padding: 20,
  },
  cardColor: {
    backgroundColor: '#ffffff',
    width: '70%',
  },
  imgSize: {
    width: 128,
    height: 128,
  },
  modalStyle1: {
    overflow: 'scroll',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterBtn: {
    'marginRight': 20,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 0,
  },
  filter: {
    marginRight: 0,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  filterDatePicker: {
    marginRight: 25,
    maxWidth: 175,
  },
  detailBtn: {
    'backgroundColor': '#ef9c02',
    'color': '#fff',
    '&:hover': {
      backgroundColor: '#c98200',
    },
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  btnExport: {
    'backgroundColor': '#009688',
    '&:hover': {
      backgroundColor: '#00796b',
    },
    'color': '#fff',
  },
  tableImg: {
    height: 50,
    width: 50,
  },
});

import React, {useEffect, useMemo, useState} from 'react';
import {Dialog, DialogActions, DialogContent, Grid, TextField, Box, withStyles} from '@material-ui/core';
import {MButton, ModalTitle} from '../../../../components/form';
import styles from './styles';
import {useDispatch, useSelector} from 'react-redux';
import {sendAnnouncement, togglePopupAnnouncement} from '../../../../redux/eventManagement/eventManagement.actions';

function PopUp({classes, title, id}) {
  const dispatch = useDispatch();
  const [description, setDescription] = useState(null);
  const {isLoadingAnnouncement, showPopupAnnouncement} = useSelector((state) => state?.eventManagement);
  const isEmpty = useMemo(() => !description?.trim()?.length || !id, [id, description]);

  const handleChange = (e) => {
    setDescription(e?.target?.value);
  };

  const handleSubmitAnnouncement = (e) => {
    e.preventDefault();
    dispatch(sendAnnouncement({event_id: id, description}));
  };

  const handleClose = () => dispatch(togglePopupAnnouncement());

  useEffect(() => {
    if (!showPopupAnnouncement) setDescription(null);
  }, [showPopupAnnouncement]);

  return (
    <Dialog
      className={classes.modal}
      open={showPopupAnnouncement}
      maxWidth="md"
    >
      <ModalTitle title={title} onClose={handleClose} />

      <DialogContent className={classes.dialogContent}>
        <TextField
          placeholder="Input Announcement Description"
          className={classes.announcementText}
          fullWidth
          InputProps={{
            disableUnderline: true,
            fullWidth: true,
          }}
          multiline
          rows={4}
          variant="filled"
          name="description"
          value={description}
          onChange={handleChange}
        />
      </DialogContent>

      <DialogActions className={classes.dialogFooter}>
        <Grid container direction="row" justifyContent="flex-end">
          <Box>
            <MButton
              type="button"
              label="cancel"
              className={classes.btnCancel}
              style={{marginRight: 15}}
              onClick={handleClose}
              loading={isLoadingAnnouncement}
            />

            <MButton
              type="button"
              className={classes.btnPublish}
              label="publish"
              onClick={handleSubmitAnnouncement}
              loading={isLoadingAnnouncement}
              disabled={isEmpty || isLoadingAnnouncement}
            />
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(PopUp);

/* eslint-disable no-dupe-keys */
export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  paperNavigation: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  remotePaper: {
    borderRadius: 18,
    boxShadow: 'none',
    backgroundColor: '#F8F8F8',
    padding: 12,
  },
  tablePaper: {
    color: theme.palette.text.secondary,
    borderRadius: 18,
    boxShadow: 'none',
  },
  tablePaper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: theme.palette.text.secondary,
    marginBottom: 10,
    borderRadius: 18,
    boxShadow: 'none',
  },
  datatable: {
    marginTop: 25,
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  checkBox: {
    width: 70,
  },

  parentFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '16px !important',
    color: '#656464',
  },
  rowFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '450 !important',
    fontSize: '14px !important',
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  accordionTitle: {
    fontSize: 14,
    fontWeight: '600',
    color: '#656464',
  },
  accordionValue: {
    fontSize: 14,
    fontWeight: '700',
    color: '#00A2DE',
  },
  statusRequest: {
    fontSize: 14,
    fontWeight: '700',
    color: '#00A2DE',
  },
  statusOngoing: {
    fontSize: 14,
    fontWeight: '700',
    color: '#FFC226',
  },
  statusRejected: {
    fontSize: 14,
    fontWeight: '700',
    color: '#F00B38',
  },
  statusCompleted: {
    fontSize: 14,
    fontWeight: '700',
    color: '#008000',
  },
  timelineDate: {
    fontSize: 12,
    fontWeight: '400',
    color: '#737373',
  },
  informationTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: '#656464',
    marginBottom: 8,
  },
  informationDetail: {
    fontSize: 14,
    fontWeight: '400',
    color: '#656464',
  },
  createUserTitle: {
    color: '#656464',
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  defaultBtnContract: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
    'textTransform': 'capitalize',
    'heigth': '40px',
  },
  defaultBtnResign: {
    'backgroundColor': '#E5AF5A',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#D09F53',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#D09F53',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#D09F53',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#D09F53',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
    'textTransform': 'capitalize',
    'heigth': '40px',
  },
  hasError: {
    color: 'red',
  },
  photo: {
    height: 300,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
  filterBtn: {
    marginTop: 25,
    marginRight: 15,
    marginLeft: 10,
  },
  filterBtnLeave: {
    marginTop: 25,
    marginRight: 15,
    marginBottom: 10,
  },
  filterDatePicker: {
    maxWidth: 180,
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 15,
  },
  filter: {
    marginRight: 25,
  },
  filterSearch: {
    minWidth: 300,
    marginRight: 25,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  dialogFooter: {
    padding: 20,
  },
  dialogContent: {
    paddingTop: '0 !important',
  },
  dialogContentContract: {
    paddingTop: '18px !important',
    paddingBottom: '24px !important',
  },
  dialogContentGuide: {
    paddingTop: '20px !important',
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  btnCrop: {
    marginTop: 10,
  },
  searchInput: {
    width: '350px',
  },
  syncTrue: {
    color: '#89d300',
    fontSize: 20,
  },
  syncFalse: {
    color: '#34a3ed',
    fontSize: 20,
  },
  syncWarn: {
    color: '#FFCC00',
    fontSize: 20,
  },
  textLink: {
    'color': '#00AFF0',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#00A2DE',
    },
  },
  btnReset: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  btnExport: {
    'backgroundColor': '#00AFF0',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow: 'none',
    },
  },
  btnStyle: {
    width: '2px',
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
  dropZoneArea: {
    padding: 15,
    minHeight: 160,
    minWidth: 500,
  },
  dropZoneText: {
    color: '#8D8D97',
    fontSize: '1.4em',
  },
  dropZoneIcon: {
    color: '#8D8D97',
  },
  hidden: {
    minWidth: 500,
    visibility: 'hidden',
    height: 0,
    minHeight: 0,
  },
  primary: {
    color: '#00AFF0',
  },
  personalInformation: {
    fontSize: 16,
    fontWeight: '600',
    color: '#656464',
    marginBottom: 4,
    marginTop: 16,
  },
  remoteData: {
    fontSize: 16,
    fontWeight: '600',
    color: '#656464',
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#656464',
  },
  limitRemote: {
    fontSize: 12,
    fontWeight: '400',
    color: '#656464',
  },
  fontGuide: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  guideLink: {
    fontSize: 14,
    fontWeight: 500,
  },
  guideLinkPopup: {
    'fontSize': 14,
    'fontWeight': 500,
    'color': '#00AFF0',
    'cursor': 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  titleGuide: {
    fontSize: 14,
    fontWeight: 600,
    color: '#656464',
  },
  subtitleGuide: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  tableTitle: {
    fontSize: '18px',
    fontWeight: '700',
    color: '#656464',
    fontFamily: 'Poppins !important',
    marginTop: '-2px !important',
  },
  btnBack: {
    'width': '14px',
    'height': '14px',
    'boxShadow': 'none',
    'color': '#656464',
    'marginRight': '24px',
  },
  btnInfo: {
    'boxShadow': 'none',
    'color': '#E5AF5A',
    'marginRight': '14px',
  },
  employeeData: {
    textAlign: '-webkit-center',
    padding: 24,
  },
  hrBadge: {
    fontSize: 16,
    fontWeight: 700,
    marginTop: 16,
    marginBottom: 8,
    color: '#FFFFFF',
    backgroundColor: '#00AFF0',
    marginLeft: 8,
    borderRadius: 8,
    paddingLeft: 6,
    paddingRight: 6,
  },
  generalName: {
    fontSize: 16,
    fontWeight: 700,
    marginTop: 16,
    marginBottom: 8,
    color: '#656464',
  },
  contractEmployee: {
    fontSize: 14,
    fontWeight: 500,
    color: '#898989',
    textTransform: 'capitalize',
  },
  personalTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: '#656464',
  },
  leaveMenuTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: '#656464',
  },
  personalDataInformation: {
    fontSize: 16,
    fontWeight: 600,
    color: '#656464',
    marginTop: 32,
    marginBottom: 16,
  },
  bankDataInformation: {
    fontSize: 16,
    fontWeight: 600,
    color: '#656464',
    marginBottom: 16,
  },
  titleText: {
    fontSize: 14,
    fontWeight: 600,
    color: '#656464',
  },
  valueText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
    textTransform: 'capitalize',
  },
  modalValueText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  emailText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  limitRemoteTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: '#777777',
    marginRight: 8,
  },
  remoteDescription: {
    fontSize: 12,
    fontWeight: 400,
    color: '#969696',
    marginTop: 8,
    marginBottom: 16,
  },
  paperTextRemote: {
    fontSize: 12,
    fontWeight: 400,
    color: '#969696',
  },

  modalTitle: {
    'fontSize': '20px !important',
    'fontWeight': '600 !important',
    'color': '#656464',
  },
  contractSubTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: '#656464',
  },
  employeeStatus: {
    fontSize: 14,
    fontWeight: 500,
    color: '#777777',
    marginRight: 16,
  },
  employeeDesc: {
    fontSize: 12,
    fontWeight: 400,
    color: '#969696',
    marginBottom: 16,
  },
  reminderDesc: {
    fontSize: 12,
    fontWeight: 400,
    color: '#969696',
  },
  tableText: {
    fontSize: 13,
    fontWeight: 400,
    color: '#656464',
    textTransform: 'capitalize',
  },
  modalTitleLeave: {
    fontSize: 16,
    fontWeight: 600,
    color: '#656464',
    textTransform: 'capitalize',
  },
  modalSubtitleLeave: {
    fontSize: 12,
    fontWeight: 400,
    color: '#656464',
    textTransform: 'capitalize',
  },
  titleStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 18,
  },
  guideLinkPopup: {
    'marginTop': 8,
    'fontSize': 11,
    'fontWeight': 400,
    'cursor': 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    'color': '#00AFF0 !important',
  },
});

export const initialState = {
  dtLoading: false,
  loading: false,
  error: null,
  success: null,
  message: null,
  trigger: null,
  paidLeaveData: [],
  paidLeaveMeta: {page: 0, total: 0},

  isLoadingPut: false,
  isSuccessPut: false,
  isErrorPut: false,
  messagePut: '',
  status: '',
  exportLoading: false,
  exportData: {file: null},
};

export const PAID_LEAVE_STATE = {
  GET_PAID_LEAVE_HISTORY: 'GET_PAID_LEAVE_HISTORY',
  GET_PAID_LEAVE_HISTORY_SUCCESS: 'GET_PAID_LEAVE_HISTORY_SUCCESS',
  GET_PAID_LEAVE_HISTORY_FAILURE: 'GET_PAID_LEAVE_HISTORY_FAILURE',

  PUT_CHANGE_STATUS: 'PUT_CHANGE_STATUS',
  PUT_CHANGE_STATUS_SUCCESS: 'PUT_CHANGE_STATUS_SUCCESS',
  PUT_CHANGE_STATUS_FAILURE: 'PUT_CHANGE_STATUS_FAILURE',

  EXPORT: 'PAID_LEAVE_EXPORT',
  EXPORT_SUCCESS: 'PAID_LEAVE_EXPORT_SUCCESS',
  EXPORT_FAILED: 'PAID_LEAVE_EXPORT_FAILED',

  RESET_LEAVE: 'RESET_LEAVE',
  CLEAR_FORM_LEAVE: 'CLEAR_FORM_LEAVE',
};

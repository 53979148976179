export default (theme) => ({
  btnCrop: {

  },
  btnReset: {
    marginRight: 10,
  },
  dialogActions: {
    padding: 15,
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#0198D0',
    },
    'color': '#fff',
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow: 'none',
    },
  },
});

/* eslint-disable max-len */
import {initialState, BANK_STATE as STATE} from './bank.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.requestType,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.requestType,
      };
    case STATE.GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        bankData: action.payload.data,
        bankMeta: action.payload.meta,
        trigger: action.type,
      };
    default:
      return state;
  }
};

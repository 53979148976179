/* eslint-disable no-duplicate-case */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
import {initialState, ORGANIZATION_STATE as STATE} from './organization.types';
export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.requestType,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: action.requestType,
        message: action.payload,
        trigger: action.requestType,
      };
    case STATE.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.requestType,
        organizationData: action.payload.data,
        organizationMeta: action.payload.meta,
        trigger: STATE.FETCH_SUCCESS,
      };
    case STATE.FETCH_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        organizationDataById: action.payload.data,
        trigger: STATE.FETCH_BY_ID_SUCCESS,
      };
    case STATE.FETCH_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        orgTypeData: action.payload.data,
        trigger: STATE.FETCH_TYPE_SUCCESS,
      };
    case STATE.FETCH_TYPE_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: null,
        trigger: STATE.FETCH_TYPE_FAILED,
      };
    case STATE.FETCH_ADMIN:
      return {
        ...state,
        orgAdminLoading: true,
        trigger: STATE.FETCH_ADMIN,
      };
    case STATE.FETCH_ADMIN_SUCCESS:
      return {
        ...state,
        orgAdminLoading: false,
        orgAdminData: action.payload.data,
        trigger: STATE.FETCH_ADMIN_SUCCESS,
      };
    case STATE.FETCH_ADMIN_FAILED:
      return {
        ...state,
        orgAdminLoading: false,
        trigger: STATE.FETCH_ADMIN_FAILED,
      };
    case STATE.FETCH_DETAIL:
      return {
        ...state,
        modalLoading: true,
        trigger: STATE.FETCH_DETAIL,
      };
    case STATE.FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        modalLoading: false,
        detailData: action.payload.data,
        trigger: STATE.FETCH_DETAIL_SUCCESS,
      };
    case STATE.FETCH_DETAIL_FAILED:
      return {
        ...state,
        modalLoading: false,
        detailData: null,
        trigger: STATE.FETCH_DETAIL_FAILED,
      };
    case STATE.CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.CREATE_SUCCESS,
      };
    case STATE.CREATE_FAILED:
      const validation = {};
      for (let i = 0; i < action.message.length; i++) validation[action.message[i].field] = action.message[i].value;
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: validation,
        trigger: STATE.CREATE_FAILED,
      };
    case STATE.CREATE_CHILD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.CREATE_CHILD_SUCCESS,
      };
    case STATE.CREATE_CHILD_FAILED:
      const validationChild = {};
      for (let i = 0; i < action.message.length; i++) validationChild[action.message[i].field] = action.message[i].value;
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: validationChild,
        trigger: STATE.CREATE_CHILD_FAILED,
      };
    case STATE.DROP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.DROP_SUCCESS,
      };
    case STATE.DROP_CHILD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.DROP_CHILD_SUCCESS,
      };
    case STATE.DROP_CHILD_FAILED:
      console.log(action.payload);
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: STATE.DROP_CHILD_FAILED,
      };
    case STATE.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.UPDATE_SUCCESS,
      };
    case STATE.CONFIRM_INVITATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.CONFIRM_INVITATION_SUCCESS,
      };
    case STATE.CONFIRM_INVITATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: STATE.CONFIRM_INVITATION_FAILED,
      };
    case STATE.ACTIVATE_INVITATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.CONFIRM_INVITATION_SUCCESS,
      };
    case STATE.ACTIVATE_INVITATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.ACTIVATE_INVITATION_FAILED,
      };
    case STATE.ACTIVATE_PARENT_INVITATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.ACTIVATE_PARENT_INVITATION_FAILED,
      };
    case STATE.ACTIVATE_PARENT_INVITATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.ACTIVATE_PARENT_INVITATION_FAILED,
      };
    case STATE.INVITATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.INVITATION_SUCCESS,
      };
    case STATE.INVITATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.INVITATION_FAILED,
      };
      case STATE.PARENT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          isSuccess: true,
          message: action.payload,
          trigger: STATE.PARENT_SUCCESS,
        };
      case STATE.PARENT_FAILED:
        return {
          ...state,
          isLoading: false,
          isError: false,
          isSuccess: true,
          message: action.payload,
          trigger: STATE.PARENT_FAILED,
        };
    case STATE.FETCH_ORG_PROFILE:
      return {
        ...state,
        trigger: STATE.FETCH_ORG_PROFILE,
      };
    case STATE.FETCH_ORG_PROFILE_SUCCESS:
      return {
        ...state,
        orgProfile: action.payload.data,
        trigger: STATE.FETCH_ORG_PROFILE_SUCCESS,
      };
    case STATE.FETCH_ORG_PROFILE_FAILED:
      return {
        ...state,
        trigger: STATE.FETCH_ORG_PROFILE_FAILED,
      };
    case STATE.UPDATE_MY_PROFILE:
      return {
        ...state,
        trigger: STATE.UPDATE_MY_PROFILE,
      };
    case STATE.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    case STATE.CHECK_STATUS:
      return {
        ...state,
      };
    case STATE.CHECK_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        trigger: STATE.CHECK_STATUS_SUCCESS,
        orgStatusData: action.payload,
      };
    case STATE.CHECK_STATUS_FAILED:
      return {
        ...state,
      };
    case STATE.ORG_ANALYTICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        analyticsOrg: action.payload.data,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.ORG_ANALYTICS_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.FETCH_ANALYTICS_DETAILS_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.FETCH_ANALYTICS_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        analyticsDetails: action.payload.data,
        analyticsMeta: action.payload.meta,
        trigger: action.type,
      };
    case STATE.CHECK_ODOO_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.CHECK_ODOO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.CLEAR_MESSAGE_ODOO:
      return {
        ...state,
        message: {
          name: null,
          country_code: null,
          user_ids: null,
          job_title_ids: null,
          address: null,
          logo: null,
        },
      };
    case STATE.EXPORT:
      return {
        ...state,
        exportLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case STATE.EXPORT_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        isError: false,
        isSuccess: true,
        exportData: action.payload,
        trigger: action.type,
      };
    case STATE.EXPORT_FAILED:
      return {
        ...state,
        exportLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.ACCEPT_TERMS_SUCCESS:
      return {
        ...state,
        isLoadingTerms: false,
        isErrorTerms: false,
        isSuccessTerms: true,
        messageTerms: action.payload,
        triggerTerms: STATE.ACCEPT_TERMS_SUCCESS,
      };
    case STATE.FETCH_LIST_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.requestType,
        organizationData: action.payload.data,
        trigger: STATE.FETCH_LIST_ORGANIZATION_SUCCESS,
      };
    case STATE.ASSIGN_HR_SUCCESS:
      return {
        ...state,
        isSuccessAssignHR: true,
        isLoadingAssignHR: false,
        isErrorAssignHR: false,
        message: action?.payload?.data?.message,
        trigger: STATE.ASSIGN_HR_SUCCESS,
      };
    case STATE.ASSIGN_HR_FAILED:
      return {
        ...state,
        isSuccessAssignHR: false,
        isLoadingAssignHR: false,
        isErrorAssignHR: true,
        message: action?.payload?.data?.message,
        trigger: STATE.ASSIGN_HR_FAILED,
      };
    default:
      return state;
  }
};

/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {CASH_ADVANCE} from '../../services';
import {CASH_ADVANCE_STATE as STATE} from './cashAdvance.types';
import {transformObject, isArray} from '../../helpers';

export const getCashAdvance = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    CASH_ADVANCE.getCashAdvance(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getCashAdvanceDetail = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_BY_ID));
    CASH_ADVANCE.getCashAdvanceDetail(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_BY_ID_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_BY_ID_FAILED, response.data.message));
            },
        );
  };
};

export const exportExcel = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT, true));
    CASH_ADVANCE.exportExcel(params)
        .then(
            (response) => {
              const file = response.data.data.file;
              dispatch(requestSuccess(STATE.EXPORT_SUCCESS, response.data.data));
              window.location.href = file; // alternative : window.open(file);
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(exportFailed(STATE.EXPORT_FAILED, message));
            },
        );
  };
};

export const completeCashAdvance = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.REVIEW));
    CASH_ADVANCE.completeCashAdvance(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.REVIEW_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.REVIEW_FAILED, message));
            },
        );
  };
};

export const acceptCashAdvance = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.ACCEPT));
    CASH_ADVANCE.acceptCashAdvance(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.ACCEPT_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.ACCEPT_FAILED, message));
            },
        );
  };
};

export const denyCashAdvance = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.DENY));
    CASH_ADVANCE.denyCashAdvance(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DENY_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.DENY_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestId = (requestType) => ({
  type: STATE.REQUEST_BY_ID,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

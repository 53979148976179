import {ENDPOINTS, apiService} from '../config';

export const getMasterHoliday = (id, params) => {
  return apiService.get(
      ENDPOINTS.MASTER_HOLIDAY.ALL + id,
      {params},
  );
};

export const createMasterHoliday = (params) => {
  return apiService.post(
      ENDPOINTS.MASTER_HOLIDAY.ADD,
      params,
  );
};

export const updateMasterHoliday = (id, params) => {
  return apiService.put(
      ENDPOINTS.MASTER_HOLIDAY.UPDATE + id,
      params,
  );
};

export const deleteMasterHoliday = (id) => {
  return apiService.delete(
      ENDPOINTS.MASTER_HOLIDAY.DELETE + id,
  );
};

/* eslint-disable max-len */
import {GLOBAL_STATE as STATE} from './global.types';

export const saveAnnouncementDraft = () => {
  return (dispatch) => dispatch(saveDraft());
};

export const resetAnnouncementDraft = () => {
  return (dispatch) => dispatch(resetDraft());
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const saveDraft = () => ({
  type: STATE.ANNOUNCEMENT_SAVE_DRAFT,
});

const resetDraft = () => ({
  type: STATE.ANNOUNCEMENT_RESET_DRAFT,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

import {initialState, FRAUD_STATE} from './fraud.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case FRAUD_STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case FRAUD_STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case FRAUD_STATE.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        fraudData: action.payload.data,
        fraudMeta: action.payload.meta,
        trigger: action.type,
      };
    case FRAUD_STATE.FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        fraudData: [],
        message: action.payload,
        trigger: action.type,
      };
    case FRAUD_STATE.FETCH_DETAIL:
      return {
        ...state,
        detailLoading: true,
        isSuccess: false,
        isError: false,
        message: null,
        detailData: null,
        trigger: action.type,
      };
    case FRAUD_STATE.FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        isSuccess: true,
        detailData: action.payload,
        trigger: action.type,
      };
    case FRAUD_STATE.FETCH_DETAIL_FAILED:
      return {
        ...state,
        detailLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case FRAUD_STATE.EXPORT:
      return {
        ...state,
        exportLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case FRAUD_STATE.EXPORT_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        isError: false,
        isSuccess: true,
        exportData: action.payload,
        trigger: action.type,
      };
    case FRAUD_STATE.EXPORT_FAILED:
      return {
        ...state,
        exportLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
      case FRAUD_STATE.CHANGE_STATUS_FRAUD:
        return {
          ...state,
          isLoadingPost: true,
          isSuccessPost: false,
          isErrorPost: false,
          messagePost: '',
          trigger: action.type,
        };
      case FRAUD_STATE.CHANGE_STATUS_FRAUD_SUCCESS:
        return {
          ...state,
          isLoadingPost: false,
          isSuccessPost: true,
          isErrorPost: false,
          messagePost: action.payload,
          trigger: action.type,
          status: action.status,
        };
      case FRAUD_STATE.CHANGE_STATUS_FRAUD_FAILURE:
        return {
          ...state,
          isLoadingPost: false,
          isSuccessPost: false,
          isErrorPost: true,
          messagePost: action.payload,
          trigger: action.type,
          status: action.status,
        };
    default:
      return state;
  }
};

export const initialState = {
  trigger: '',
  isDraftSaved: false,
};

export const GLOBAL_STATE = {
  CLEAR_STATE: 'GLOBAL_CLEAR_STATE',
  ANNOUNCEMENT_SAVE_DRAFT: 'GLOBAL_ANNOUNCEMENT_SAVE_DRAFT',
  ANNOUNCEMENT_RESET_DRAFT: 'GLOBAL_ANNOUNCEMENT_RESET_DRAFT',
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
  Chip,
  Paper,
  CircularProgress,
} from '@mui/material';
import './styles.css';
import AddIcon from '@mui/icons-material/Add';
import {useDispatch, useSelector} from 'react-redux';
import {getEvents} from '../../../../../redux/eventManagement/eventManagement.actions';
import debounce from 'lodash.debounce';
import _ from 'lodash';

const initialFilter = {
  pagination: true,
  page: 1,
  limit: 10,
  keyword: null,
  type: null,
  status: 'active',
  start_date: null,
  end_date: null,
  is_group: true,
};

const MultiSelectWithModal = ({handleChange, getError, clearErrors, getValues}) => {
  const dispatch = useDispatch();
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalSelectedEvents, setModalSelectedEvents] = useState([]);
  const [filterState, setFilterState] = useState({...initialFilter});
  const {
    isLoading,
    events,
  } = useSelector((state) => state.eventManagement);
  const paperRef = useRef(null);
  const scrollPosition = useRef(0);
  const lastPageRef = useRef(filterState.page);
  const [localEvents, setLocalEvents] = useState([]);


  const handleOpenModal = () => {
    setModalSelectedEvents(selectedEvents);
    setOpenModal(true);
    };

    const fetchList = useCallback((params = initialFilter) => {
      const newParams = {
        ...initialFilter,
        ...params,
        limit: params?.limit !== initialFilter?.limit ? params?.limit : initialFilter?.limit,
      };

      newParams.keyword = _.isString(params.keyword) && params.keyword.trim() !== '' ? params.keyword : null;

      if (params?.sortBy) {
        delete newParams.sortBy;
        newParams.order = params.sortBy;
        newParams.sort = params.order?.toUpperCase();
      }

      setFilterState({...newParams});
        const isSameRequest =
          JSON.stringify(params) === JSON.stringify(filterState);
        if (!isSameRequest || filterState.page === 1) {
          dispatch(getEvents(newParams));
        }
    }, [dispatch]);


  const handleCloseModal = () => setOpenModal(false);

  const handleModalConfirm = () => {
    setSelectedEvents(modalSelectedEvents);
    setModalSelectedEvents([]);
    handleCloseModal();
    clearErrors('groups');
  };

  const handleScroll = (event) => {
    const target = event.currentTarget;
    const bottom = target.scrollHeight === target.scrollTop + target.clientHeight;

    scrollPosition.current = target.scrollTop;

    if (bottom && !isLoading && events?.events?.length > 0) {
      setFilterState((prev) => ({
            ...prev,
            page: prev.page + 1,
        }));
    }
};

const remainingOptions = localEvents.filter(
  (option) => !modalSelectedEvents.some((selected) => selected.id === option.id),
);


useEffect(() => {
  if (remainingOptions.length < 6 && remainingOptions.length > 1 && !isLoading && events?.events?.length > 0) {
      setFilterState((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
  }
}, [remainingOptions, isLoading]);


useEffect(() => {
  if (events.events) {
    setLocalEvents((prev) => {
      const newEvents = events.events
        .filter((event) => event.status !== 'ended' && event.status !== 'inactive' && event.status !== 'on_review')
        .filter(
          (event) => !prev.some((existingEvent) => existingEvent.id === event.id),
        );
      return [...prev, ...newEvents];
    });
    if (paperRef.current) {
      paperRef.current.scrollTop = scrollPosition.current;
    }
  }
}, [events, isLoading]);


  const handleInputChange = useCallback(
    debounce((event, newValue) => {
        setFilterState({
            ...filterState,
            keyword: newValue ? newValue : null,
            page: 1,
        });
        fetchList({...filterState, page: 1, keyword: newValue});
    }, 300),
    [filterState],
  );

  useEffect(() => {
    if (filterState.page > 1 && filterState.page > lastPageRef.current) {
      fetchList(filterState);
      lastPageRef.current = filterState.page;
    }
  }, [fetchList, filterState]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);


  useEffect(() => {
    handleChange({target: {name: 'groups', value: selectedEvents}});
  }, [selectedEvents]);

  useEffect(() => {
   if (getValues && getValues.length > 0 && !_.isEqual(selectedEvents, getValues)) {
      setSelectedEvents(getValues);
   }
  }, [getValues, setSelectedEvents]);


  return (
    <Box style={{margin: '16px 0px'}}>
        <Box className='container-group-event'>
            <Box className='field-group-event'>
                <Autocomplete
                    multiple
                    options={[]}
                    value={getValues ?? []}
                    onChange={() => {}}
                    open={false}
                    renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                        key={option?.id}
                        label={option.title}
                        {...getTagProps({index})}
                        onDelete={() =>
                            setSelectedEvents((prev) =>
                              prev.filter((item) => item?.id !== option?.id),
                            )
                        }
                        />
                    ))
                    }
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        label="Group Events Active *"
                        sx={{
                          minHeight: '108px',
                        }}
                        error={getError}
                        helperText={getError}
                        className="custom-field-event-group"
                        onClick={(event) => {
                        event.preventDefault();
                        handleOpenModal();
                        }}
                        InputProps={{
                        ...params.InputProps,
                        readOnly: true,
                        }}
                    />
                    )}
                    disableClearable
                />
            </Box>
            <Box className='button-add'>
                <Button variant="contained" startIcon={<AddIcon />} onClick={handleOpenModal}>
                    Add Event
                </Button>
            </Box>
        </Box>


      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>Select Events</DialogTitle>
        <DialogContent>
          <Autocomplete
            multiple
            options={localEvents}
            getOptionLabel={(option) => option.title}
            value={modalSelectedEvents}
            onInputChange={handleInputChange}
            onChange={(event, newValue) => setModalSelectedEvents(newValue)}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{shrink: true}}
                variant="outlined"
                label="Group Events Active *"
                sx={{
                    minHeight: '108px',
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: isLoading ? <CircularProgress color="inherit" size={20} /> : null,
                }}
                placeholder="Choose events"
                className="custom-field-event-group"
              />
            )}
            PaperComponent={(props) => (
              <Paper
                  {...props}
                  ref={paperRef} // Attach ref to Paper component
                  onScroll={handleScroll}
                  onChange={(e)=>console.log('eve', e)
                  }
                  style={{maxHeight: 200, overflowY: 'auto'}}
              >
                  {props.children}
              </Paper>
          )}
          classes={{
              listbox: 'custom-listbox',
          }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button variant="contained" onClick={handleModalConfirm}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MultiSelectWithModal;

import React, {useCallback, useEffect, useMemo} from 'react';
import {
  Box,
  createTheme,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  ThemeProvider,
  Typography,
  withStyles,
  IconButton,
} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';
import DOMPurify from 'dompurify';
import {convDate, getImageUrl} from '../../../../helpers';
import styles from './styles.js';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getEventGroupDetail} from '../../../../redux/eventManagement/eventManagement.actions.js';
import EventCardList from './cardEvent.jsx';
import {checkSuperadmin} from '../../../../helpers';
import {ArrowBack, Edit as EditIcon} from '@material-ui/icons';
import {MButton} from '../../../../components/form/index.js';

const EventGroupDetail = ({classes}) => {
const {auth} = useSelector((state) => state) || {};
const dispatch = useDispatch();
const history = useHistory();
const {state} = useLocation();
const {
  eventManagement: {isLoadingEventGroup, eventGroupDetail},
} = useSelector((state) => state) || {};
const eventId = useMemo(() => eventGroupDetail?.id, [eventGroupDetail]);

const stateId = state?.id;
const stateIsEdit = state?.isEdit;

const handleEdit = () => {
  if (!checkSuperadmin(auth) && eventGroupDetail?.id) {
    history.push('/event-group/update', {isEdit: true, id: eventGroupDetail?.id});
  }
};

const fetchDetail = useCallback(
  (_eventId) => {
    dispatch(getEventGroupDetail(_eventId));
  },
  [dispatch],
);

useEffect(() => {
  const unlisten = history.listen((location) => {
    if (location.pathname === '/event-group/detail' && stateId) {
      fetchDetail(stateId);
    }
  });

  return () => {
    unlisten();
  };
}, [history, stateId, fetchDetail]);

useEffect(() => {
  if (!stateId) {
    history.push('/event-group');
  } else if (eventId !== stateId) {
    fetchDetail(stateId, stateIsEdit);
  }
}, [history, stateId, stateIsEdit, fetchDetail, eventId]);

    return (
        <>
        <Paper variant="outlined" className={classes.topBox}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <IconButton onClick={() => history.push('/event-group')}>
              <ArrowBack />
            </IconButton>

            <Typography
              style={{
                fontWeight: 700,
                fontSize: '18px',
                color: '#656464',
              }}
              >
              Event Group List
            </Typography>
          </div>
          <div style={{marginRight: '16px'}}>
          {checkSuperadmin(auth) || ['on_review', 'blocked', 'canceled'].includes(eventGroupDetail?.status) ? null :
            <MButton
              label="Update"
              color="primary"
              onClick={handleEdit}
              icon={<EditIcon />}
              loading={isLoadingEventGroup}
            />}
          </div>
        </div>
      </Paper>
      <Paper variant="outlined" className={classes.eventDashboard}>
        <Box>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: '18px',
              marginBottom: '20px',
            }}
          >
            Event Information
          </Typography>
        </Box>
        <Grid container>
          <Grid item lg={5} md={5} xl={5} style={{display: 'flex', alignItems: 'center'}}>
            {isLoadingEventGroup ?
              <ThemeProvider theme={createTheme({palette: {primary: grey}})}>
                <LinearProgress className={classes?.loaderImg} color="primary" />
              </ThemeProvider> :
              <img
                loading="lazy"
                src={getImageUrl(eventGroupDetail?.photo)}
                alt="Event Banner"
                style={{borderRadius: '6px', width: '100%', height: 'auto', objectFit: 'contain'}}
              />
            }
          </Grid>
          <Grid item lg={7} md={7} xl={7} style={{display: 'flex'}}>
            <Grid container>
            <Grid item xs={6}>
            <Box
              style={{
                marginLeft: '30px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Box>
                <Typography className={classes.dashboardItem}>
                  Event Name
                </Typography>

                <Typography className={classes.dashboardValue}>
                  {eventGroupDetail?.title || '-'}
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.dashboardItem}>
                  Event Type
                </Typography>

                <Typography className={`${classes.dashboardValue} text-capitalize`}>
                  {eventGroupDetail?.type || '-'}
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.dashboardItem}>
                  Event Status
                </Typography>

                <Typography className={`${classes.dashboardValue} text-capitalize`}>
                  {eventGroupDetail?.status ? getStatusLabel(eventGroupDetail?.status) : '-'}
                </Typography>
              </Box>
            </Box>
            </Grid>
            <Grid item xs={6}>
            <Box
              style={{
                marginLeft: '20px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
                <Box>
                <Typography className={classes.dashboardItem}>
                  Start Date
                </Typography>

                <Typography className={classes.dashboardValue}>

                  {convDate(eventGroupDetail?.date_start, 'DD MMMM YYYY') || '-'}
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.dashboardItem}>
                  End Date
                </Typography>

                <Typography className={classes.dashboardValue}>
                  {convDate(eventGroupDetail?.date_end, 'DD MMMM YYYY') || '-'}
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.dashboardItem}>
                  Event Group Active
                </Typography>

                <Typography className={classes.dashboardValue}>
                  {eventGroupDetail?.group.length > 0 ? eventGroupDetail?.group.length : '-'}
                </Typography>
              </Box>
            </Box>
            </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper variant="outlined" className={classes.paper}>
        <Box>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: '18px',
              marginBottom: '20px',
            }}
          >
            Event Group Detail
          </Typography>
        </Box>
        <Divider/>
        <Box>
        <Box>
          <Typography style={{fontWeight: 600, fontSize: '16px', padding: '24px 0px'}}>
            Description
          </Typography>

          <div
            style={{fontSize: '14px', fontWeight: 400, minHeight: 'auto', paddingBottom: '16px'}}
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(eventGroupDetail?.description)}}
          />
        </Box>
        <Divider/>
        <Box style={{padding: '24px 0px'}}>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: '18px',
              marginBottom: '20px',
            }}
          >
            List Event Active
          </Typography>
        </Box>
        <Box>
            <EventCardList events={eventGroupDetail?.group ?? []}/>
        </Box>
        </Box>
        </Paper>
        </>
    );
};

const getStatusLabel = (status) => {
    return status === 'active' ? (
      <span
        style={{
          color: '#008000',
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
      >
        published
      </span>
    ) : status === 'inactive' ? (
      <span
        style={{
          color: '#E5AF5A',
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
      >
        draft
      </span>
    ) : status === 'ended' ? (
      <span
        style={{
          color: '#777777',
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
      >
        {status?.replace('_', ' ')}
      </span>
    ) : (
      <span
        style={{
          color: '#F22951',
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
      >
        {status?.replace('_', ' ')}
      </span>
    );
  };


export default withStyles(styles)(EventGroupDetail);

import {initialState, PERMIT_STATE} from './permit.type';

export default (state = initialState, action) => {
  switch (action.type) {
    case PERMIT_STATE.GET_PERMIT_HISTORY:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        isLoadingPut: false,
        isSuccessPut: false,
        isErrorPut: false,
        message: null,
        trigger: action.type,
      };
    case PERMIT_STATE.GET_PERMIT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        isLoadingPut: false,
        isSuccessPut: false,
        isErrorPut: false,
        permitData: action.payload.data,
        permitMeta: action.payload.meta,
        message: null,
        trigger: action.type,
      };
    case PERMIT_STATE.GET_PERMIT_HISTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        isLoadingPut: false,
        isSuccessPut: false,
        isErrorPut: false,
        message: action.payload,
        trigger: action.type,
      };
    case PERMIT_STATE.PUT_PERMIT_CHANGE_STATUS:
      return {
        ...state,
        isLoadingPut: true,
        isSuccessPut: false,
        isErrorPut: false,
        messagePut: '',
        trigger: action.type,
      };
    case PERMIT_STATE.PUT_PERMIT_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingPut: false,
        isSuccessPut: true,
        isErrorPut: false,
        messagePut: action.payload,
        trigger: action.type,
        status: action.status,
      };
    case PERMIT_STATE.PUT_PERMIT_CHANGE_STATUS_FAILURE:
      return {
        ...state,
        isLoadingPut: false,
        isSuccessPut: false,
        isErrorPut: true,
        messagePut: action.payload,
        trigger: action.type,
        status: action.status,
      };
    case PERMIT_STATE.EXPORT:
      return {
        ...state,
        exportLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case PERMIT_STATE.EXPORT_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        isError: false,
        isSuccess: true,
        exportData: action.payload,
        trigger: action.type,
      };
    case PERMIT_STATE.EXPORT_FAILED:
      return {
        ...state,
        exportLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    default:
      return state;
  }
};

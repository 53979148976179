/* eslint-disable max-len */
export const initialState = {
    isLoading: false,
    isSuccess: false,
    isLoadingSubmitApproval: false,
    isSuccessSubmitApproval: false,
    isError: false,
    isDone: false,
    message: null,
    data: {},
    userMeta: {},
  };

  export const APPROVAL_STATE = {
    GET_APPROVAL_OVERTIME_BY_TOKEN: 'GET_APPROVAL_OVERTIME_BY_TOKEN',
    GET_APPROVAL_OVERTIME_BY_TOKEN_SUCCESS: 'GET_APPROVAL_OVERTIME_BY_TOKEN_SUCCESS',
    GET_APPROVAL_OVERTIME_BY_TOKEN_FAILURE: 'GET_APPROVAL_OVERTIME_BY_TOKEN_FAILURE',

    SUBMIT_APPROVAL_OVERTIME: 'SUBMIT_APPROVAL_OVERTIME',
    SUBMIT_APPROVAL_OVERTIME_SUCCESS: 'SUBMIT_APPROVAL_OVERTIME_SUCCESS',
    SUBMIT_APPROVAL_OVERTIME_FAILURE: 'SUBMIT_APPROVAL_OVERTIME_FAILURE',

    RESET_LOADER: 'RESET_LOADER',
  };


/* eslint-disable max-len */
import {XENDIT} from '../../services';
import {XENDIT_STATE as STATE} from './xendit.types';
import {transformObject, isArray} from '../../helpers';

export const viaXendit = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GET_TOKEN));
    XENDIT.viaXendit(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.GET_TOKEN_SUCCESS, response.data.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data;
              dispatch(requestFailed(STATE.GET_TOKEN_FAILED, message));
            },
        );
  };
};

export const checkXenditStatus = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CHECK_XENDIT));
    XENDIT.checkXenditStatus(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CHECK_XENDIT_SUCCESS, response.data.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data;
              dispatch(requestFailed(STATE.CHECK_XENDIT_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

export const clearStateGenerate = () => {
  return (dispatch) => dispatch(resetStateGenerate());
};

const request = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE_XENDIT,
});

const resetStateGenerate = () => ({
  type: STATE.CLEAR_STATE_XENDIT_GENERATE,
});

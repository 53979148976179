export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  paymentData: [],
  paymentMeta: {page: 1, total: 0},
  analyticsDetails: [],
  analyticsMeta: {page: 1, total: 0},
  message: null,
  analyticsData: null,
};

export const PAYMENT_STATE = {
  REQUEST: 'PAYMENT_REQUEST',
  REQUEST_FAILED: 'PAYMENT_REQUEST_FAILED',
  CLEAR_STATE: 'PAYMENT_CLEAR_STATE',
  FETCH: 'PAYMENT_FETCH',
  FETCH_SUCCCESS: 'PAYMENT_FETCH_SUCCCESS',
  FETCH_FAILED: 'PAYMENT_FETCH_FAILED',
  CREATE: 'PAYMENT_CREATE',
  CREATE_FAILED: 'PAYMENT_CREATE_FAILED',
  CREATE_SUCCESS: 'PAYMENT_CREATE_SUCCESS',
  UPDATE: 'PAYMENT_UPDATE',
  UPDATE_SUCCESS: 'PAYMENT_UPDATE_SUCCESS',
  UPDATE_FAILED: 'PAYMENT_UPDATE_FAILED',
  DROP: 'PAYMENT_DROP',
  DROP_SUCCESS: 'PAYMENT_DROP_SUCCESS',
  DROP_FAILED: 'PAYMENT_DROP_FAILED',
  FETCH_ANALYTICS: 'ANALYTICS_FETCH',
  FETCH_ANALYTICS_SUCCCESS: 'ANALYTICS_FETCH_SUCCCESS',
  FETCH_ANALYTICS_FAILED: 'ANALYTICS_FETCH_FAILED',
  FETCH_ANALYTICS_DETAILS: 'ANALYTICS_DETAILS_FETCH',
  FETCH_ANALYTICS_DETAILS_SUCCCESS: 'ANALYTICS_DETAILS_FETCH_SUCCCESS',
  FETCH_ANALYTICS_DETAILS_FAILED: 'ANALYTICS_DETAILS_FETCH_FAILED',
};

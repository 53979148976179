import {Grid, Paper, Typography, withStyles} from '@material-ui/core';
import {Delete, Share} from '@mui/icons-material';
import React from 'react';
import {Controller} from 'react-hook-form';
import {MButton, MInput, MSelect} from '../../../../../components/form';
import styles from './styles.js';

const VoucherItem = ({
  classes,
  control,
  disableActions,
  getValues,
  index,
  onDelete,
  onShare,
  rowNumber,
  packageOptions,
}) => {
  return (
    <Paper variant="outlined" className={classes?.voucherItemPaper}>
      <Grid container spacing={2} style={{alignItems: 'center', justifyContent: 'space-between'}}>
        <Grid item xs={1} sm={1}>
          <Typography fontSize={16}>{rowNumber}</Typography>
        </Grid>

        <Grid item xs={2} sm={2}>
          <Typography fontSize={16}>{getValues(`voucher[${index}].code`)}</Typography>
        </Grid>

        <Grid item xs={3} sm={4}>
          <Controller
            control={control}
            name={`voucher[${index}].package`}
            render={(props) => (
              <MSelect
                {...props.field}
                value={props?.field?.value?.trim()}
                label="Package *"
                keyPair={['id', 'name']}
                options={packageOptions}
                shrink
                error={props?.fieldState?.error?.message?.replace(props?.field?.name, 'Package')}
                disabled={disableActions}
              />
            )}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Controller
            control={control}
            name={`voucher[${index}].limit`}
            render={(props) => (
              <MInput
                {...props?.field}
                label="Max Participants"
                placeholder="0"
                error={props?.fieldState?.error?.message?.replace(props?.field?.name, 'Max Participants')}
                disabled={disableActions}
              />
            )}
          />
        </Grid>

        {disableActions ? null : <Grid item xs={6} sm={2}>
          <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
            <MButton
              color="primary"
              size="small"
              icon={<Share />}
              style={{
                minWidth: '10px',
                paddingRight: '0px',
              }}
              onClick={onShare}
            />

            <MButton
              variant="contained"
              size="small"
              className={classes?.deleteButton}
              startIcon={<Delete />}
              onClick={onDelete}
            />
          </div>
        </Grid>}
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(VoucherItem);

export const initialState = {
  dtLoading: false,
  loading: false,
  error: null,
  success: null,
  message: null,
  trigger: null,
  reimburseData: [],
  reimburseMeta: {page: 0, total: 0},

  isLoadingPut: false,
  isSuccessPut: false,
  isErrorPut: false,
  messagePut: '',
  status: '',

  exportLoading: false,
  exportData: {file: null},
  isLoadingPost: false,
  isSuccessPost: false,
  isErrorPost: false,
  messagePost: '',
};

export const REIMBURSE_HISTORY_STATE = {
  GET_REIMBURSE_HISTORY: 'GET_REIMBURSE_HISTORY',
  GET_REIMBURSE_HISTORY_SUCCESS: 'GET_REIMBURSE_HISTORY_SUCCESS',
  GET_REIMBURSE_HISTORY_FAILURE: 'GET_REIMBURSE_HISTORY_FAILURE',

  PUT_CHANGE_STATUS: 'PUT_CHANGE_STATUS',
  PUT_CHANGE_STATUS_SUCCESS: 'PUT_CHANGE_STATUS_SUCCESS',
  PUT_CHANGE_STATUS_FAILURE: 'PUT_CHANGE_STATUS_FAILURE',

  RESET_REIMBURSE: 'RESET_REIMBURSE',
  CLEAR_FORM_REIMBURSE: 'CLEAR_FORM_REIMBURSE',
  EXPORT: 'REIMBURSE_EXPORT',
  EXPORT_SUCCESS: 'REIMBURSE_EXPORT_SUCCESS',
  EXPORT_FAILED: 'REIMBURSE_EXPORT_FAILED',
};


import {ENDPOINTS, apiService} from '../config';

export const getOfficeInventory = (params) => {
  return apiService.get(
      ENDPOINTS.OFFICE_INVENTORY.ALL,
      {params},
  );
};

export const getOfficeInventoryDetail = (id) => {
  return apiService.get(
      ENDPOINTS.OFFICE_INVENTORY.DETAIL + id,
  );
};


export const completeOfficeInventory = (id, params) => {
  return apiService.put(
      ENDPOINTS.OFFICE_INVENTORY.COMPLETE + id,
      params,
      {headers: {'Content-Type': 'multipart/form-data'}},
  );
};

export const acceptOfficeInventory = (id, params) => {
  return apiService.put(
      ENDPOINTS.OFFICE_INVENTORY.ACCEPT + id,
      params,
      {headers: {'Content-Type': 'multipart/form-data'}},
  );
};

export const denyOfficeInventory = (id, params) => {
  return apiService.put(
      ENDPOINTS.OFFICE_INVENTORY.DENY + id,
      params,
  );
};


export const exportExcel = (params) => {
  return apiService.post(
      ENDPOINTS.OFFICE_INVENTORY.EXPORT,
      params,
  );
};



import {ENDPOINTS, apiService} from '../config';

export const checkToken = (params) => {
  return apiService.get(
      ENDPOINTS.ACCOUNT.CHECK_TOKEN,
      {params},
  );
};

export const updatePassword = (params) => {
  return apiService.post(
      ENDPOINTS.ACCOUNT.UPDATE_PASSWORD,
      params,
  );
};

export const forgotPassword = (params) => {
  return apiService.post(
      ENDPOINTS.ACCOUNT.FORGOT_PASSWORD,
      params,
  );
};

/* eslint-disable max-len */
import {AUTH} from '../../services';
import {LOGIN_STATE as STATE} from './login.types';

export const login = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.LOGIN));
    AUTH.login(params)
        .then(
            (response) => {
              if (response?.response?.status >= 400) {
                const {message} = response?.response?.data?.data || response?.response?.data || {};
                dispatch(requestFailed(STATE.LOGIN_FAILED, message || 'Please check your input and try again'));
              } else {
                dispatch(requestSuccess(STATE.LOGIN_SUCCESS, response.data));
              }
            },
            (error) => {
              const response = error?.response?.data;
              dispatch(requestFailed(STATE.LOGIN_FAILED, response?.data?.message));
            },
        );
  };
};

export const ssoValidation = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.SSO_VALIDATE));
    AUTH.ssoValidation(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.SSO_VALIDATE_SUCCESS, response.data));
            },
            (error) => {
              const response = error?.response?.data;
              dispatch(requestFailed(STATE.SSO_VALIDATE_FAILED, response?.data?.message));
            },
        );
  };
};

export const loginSSO = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.LOGIN_SSO));
    AUTH.loginSSO(params)
      .then(
        (response) => {
          if (response?.response?.status >= 400) {
            const {message} = response?.response?.data?.data || response?.response?.data || {};
            dispatch(requestFailed(STATE.LOGIN_SSO_FAILED, message || 'Please check your input and try again'));
          } else {
            dispatch(requestSuccess(STATE.LOGIN_SSO_SUCCESS, response.data));
          }
        },
        (error) => {
          const response = error?.response?.data;
          dispatch(requestFailed(STATE.LOGIN_SSO_FAILED, response?.data?.message));
        },
      );
  };
};

export const setRedirectURL = (params) => {
  return (dispatch) => dispatch(setURL(params));
};

export const setSSORedirectURL = (params) => {
  return (dispatch) => dispatch(setURL(params));
};

export const logout = () => {
  return (dispatch) => dispatch(logoutApp());
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const setURL = (url) => ({
  type: STATE.REDIRECT,
  payload: url,
});

const logoutApp = () => ({
  type: STATE.LOGOUT,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  message: {
    name: '',
    email: '',
  },
  analyticsDetails: [],
  analyticsMeta: {page: 1, total: 0},
  analyticsRegister: null,
};

export const REGISTER_STATE = {
  REQUEST: 'REGISTER_REQUEST',
  REQUEST_FAILED: 'REGISTER_REQUEST_FAILED',
  CLEAR_STATE: 'REGISTER_CLEAR_STATE',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILED: 'REGISTER_FAILED',
  APPROVAL: 'REGISTER_APPROVAL',
  APPROVAL_SUCCESS: 'REGISTER_APPROVAL_SUCCESS',
  APPROVAL_FAILED: 'REGISTER_APPROVAL_FAILED',
  APPROVAL_USER: 'REGISTER_APPROVAL_USER',
  APPROVAL_USER_SUCCESS: 'REGISTER_APPROVAL_USER_SUCCESS',
  APPROVAL_USER_FAILED: 'REGISTER_APPROVAL_USER_FAILED',
  ANALYTICS_REGISTER: 'ANALYTICS_REGISTER',
  ANALYTICS_REGISTER_SUCCCESS: 'ANALYTICS_REGISTER_SUCCCESS',
  ANALYTICS_REGISTER_FAILED: 'ANALYTICS_REGISTER_FAILED',
  FETCH_ANALYTICS_DETAILS: 'ANALYTICS_DETAILS_FETCH',
  FETCH_ANALYTICS_DETAILS_SUCCCESS: 'ANALYTICS_DETAILS_FETCH_SUCCCESS',
  FETCH_ANALYTICS_DETAILS_FAILED: 'ANALYTICS_DETAILS_FETCH_FAILED',
};

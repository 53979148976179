/* eslint-disable camelcase */
import React, {Component} from 'react';
import Routes from './routes/index.js';
import './assets/style/global.css';
import {ThemeProvider} from '@material-ui/core/styles';
import customTheme from './components/theme/theme';

import {initializeApp} from 'firebase/app';
import {firebaseConfig} from './config/firebase.js';
import {isSupported} from 'firebase/messaging';

class App extends Component {
  UNSAFE_componentWillMount() {
    async function fcmSupport() {
      const supported = await isSupported();
      if (supported) {
        initializeApp(firebaseConfig);
      }
    }
    fcmSupport();
  }

  render() {
    return (
      <ThemeProvider theme={customTheme}>
        <Routes />
      </ThemeProvider>
    );
  }
}

export default App;

export default (theme) => ({
  deleteButton: {
    'backgroundColor': '#E5AF5A',
    'color': '#fff',
    'minWidth': '10px',
    'paddingRight': '0px',
    '&:hover': {
      backgroundColor: '#D09F53',
    },
  },
  voucherItemPaper: {
    color: theme.palette.text.secondary,
    borderRadius: 8,
    boxShadow: 'none',
    padding: '16px',
  },
});

/* eslint-disable max-len */
import {DASHBOARD, ATTENDANCE} from '../../services';
import {DASHBOARD_STATE as STATE} from './dashboard.types';
export const getAll = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH, true));
    DASHBOARD.getAll(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

export const getNoRecord = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GET_NO_RECORD, true));
    ATTENDANCE.getAttendanceNoRecord(params)
        .then(
            (response) => {
              const data = response.data;
              dispatch(requestSuccess(STATE.GET_NO_RECORD_SUCCESS, data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.GET_NO_RECORD_FAILURE, response.data.message));
            },
        );
  };
};

export const getToday = (params) => {
  return (dispatch) => {
    dispatch(requestToday(STATE.FETCH_TODAY, true));
    DASHBOARD.getToday(params)
        .then(
            (response) => {
              dispatch(requestTodaySuccess(STATE.FETCH_TODAY_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              const message = response.data.message ? response.data.message : response.message;
              dispatch(requestTodayFailed(STATE.FETCH_TODAY_FAILED, message));
            },
        );
  };
};

export const clearTodayState = () => {
  return (dispatch) => dispatch(resetTodayState());
};

export const getYesterday = (params) => {
  return (dispatch) => {
    dispatch(requestYesterday(STATE.FETCH_YESTERDAY, true));
    DASHBOARD.getYesterday(params)
        .then(
            (response) => {
              dispatch(requestYesterdaySuccess(STATE.FETCH_YESTERDAY_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              const message = response.data.message ? response.data.message : response.message;
              dispatch(requestYesterdayFailed(STATE.FETCH_YESTERDAY_FAILED, message));
            },
        );
  };
};

export const clearYesterdayState = () => {
  return (dispatch) => dispatch(resetYesterdayState());
};

export const getLastWeek = (params) => {
  return (dispatch) => {
    dispatch(requestLastWeek(STATE.FETCH_LAST_WEEK, true));
    DASHBOARD.getLastWeek(params)
        .then(
            (response) => {
              dispatch(requestLastWeekSuccess(STATE.FETCH_LAST_WEEK_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              const message = response.data.message ? response.data.message : response.message;
              dispatch(requestLastWeekFailed(STATE.FETCH_LAST_WEEK_FAILED, message));
            },
        );
  };
};

export const clearLastWeekState = () => {
  return (dispatch) => dispatch(resetLastWeekState());
};

export const getLastMonth = (params) => {
  return (dispatch) => {
    dispatch(requestLastMonth(STATE.FETCH_LAST_MONTH, true));
    DASHBOARD.getLastMonth(params)
        .then(
            (response) => {
              dispatch(requestLastMonthSuccess(STATE.FETCH_LAST_MONTH_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              const message = response.data.message ? response.data.message : response.message;
              dispatch(requestLastMonthFailed(STATE.FETCH_LAST_MONTH_FAILED, message));
            },
        );
  };
};

export const clearLastMonthState = () => {
  return (dispatch) => dispatch(resetLastMonthState());
};

const request = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

const requestToday = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST_TODAY,
  requestType,
});

const requestTodayFailed = (requestType, response) => ({
  type: requestType,
  requestType,
  payload: response,
});

const requestTodaySuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetTodayState = () => ({
  type: STATE.CLEAR_TODAYSTATE,
});

const requestYesterday = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST_YESTERDAY,
  requestType,
});

const requestYesterdayFailed = (requestType, response) => ({
  type: requestType,
  requestType,
  payload: response,
});

const requestYesterdaySuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetYesterdayState = () => ({
  type: STATE.CLEAR_YESTERDAY_STATE,
});

const requestLastWeek = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST_LAST_WEEK,
  requestType,
});

const requestLastWeekFailed = (requestType, response) => ({
  type: requestType,
  requestType,
  payload: response,
});

const requestLastWeekSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetLastWeekState = () => ({
  type: STATE.CLEAR_LAST_WEEK_STATE,
});

const requestLastMonth = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST_LAST_MONTH,
  requestType,
});

const requestLastMonthFailed = (requestType, response) => ({
  type: requestType,
  requestType,
  payload: response,
});

const requestLastMonthSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetLastMonthState = () => ({
  type: STATE.CLEAR_LAST_MONTH_STATE,
});

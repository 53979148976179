/* eslint-disable camelcase */
/* eslint-disable max-len */
import {yupResolver} from '@hookform/resolvers/yup';
import {Paper, withStyles} from '@material-ui/core';
import {Add, Send} from '@mui/icons-material';
import {Grid, Typography} from '@mui/material';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useFieldArray, useForm} from 'react-hook-form';
import voucherGenerator from 'voucher-code-generator';
import moment from 'moment';
import * as yup from 'yup';
import {MButton} from '../../../../components/form';
import {showPopup, toast} from '../../../../helpers';
import VoucherItem from './voucherItem';
import styles from './styles';
import {updateVoucher} from '../../../../redux/eventManagement/eventManagement.actions';
import {EVENT_MANAGEMENT_STATE as STATE} from '../../../../redux/eventManagement/eventManagement.types';

const schema = yup.object({
  voucher: yup.array(yup.object({
    code: yup.string().required(),
    limit: yup.number('Max Participants should be numeric').required().min(1),
    package: yup.string('Package is a required field').required(),
  })).required(),
});

const EventVoucher = ({classes}) => {
  const dispatch = useDispatch();
  const {voucher, status, id, package: eventPackage, date_end} = useSelector((state) => state?.eventManagement?.eventDetail) || {};
  const {isLoading, isLoadingUpdateVoucher, trigger} = useSelector((state) => state?.eventManagement);

  const {control, getValues, handleSubmit, setValue} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      voucher: voucher?.length ? [...voucher] : [],
    },
  });

  const {fields: vouchers, append, remove} = useFieldArray({
    control,
    name: 'voucher',
  });

  const isEnded = useMemo(() => {
    return moment(date_end).isBefore(moment());
  }, [date_end]);

  const generateVoucherCode = () => voucherGenerator.generate({
    length: 6,
    count: 1,
    charset: voucherGenerator.charset('alphanumeric'),
  })[0];

  const onAddVoucher = () => {
    const newCode = generateVoucherCode();
    append({code: newCode, limit: undefined, package: undefined});
  };

  const onDelete = (index) => {
    showPopup('confirm', 'Are you sure want to delete this voucher?', 'Yes', () => {
      remove(index);
    });
  };

  const onShare = async (index) => {
    const eventWeb = process?.env?.REACT_APP_PAGII_EVENT_WEB || 'pagii.co/';
    const voucherCode = btoa(getValues(`voucher[${index}].code`));
    const _package = getValues(`voucher[${index}].package`);
    const payload = btoa(JSON.stringify({package: _package, voucher: voucherCode}));
    const shareUrl = `${eventWeb}events/${id}?v=${payload}`;
    await navigator.clipboard.writeText(encodeURI(shareUrl));
    toast('success', 'Link Copied to Clipboard!');
  };

  const onSubmit = (data) => {
    dispatch(updateVoucher(id, data));
  };

  const getPackageOptions = (packages = []) => {
    const optionAll = {id: 'all', name: 'All'};
    const options = [optionAll, ...packages.map((item) => ({id: item.name.trim(), name: item.name.trim()}))];
    return options;
  };

  useEffect(() => {
    if (trigger === STATE.UPDATE_EVENT_VOUCHER_FAILED) {
      const prevVouchers = getValues('voucher');
      const prevVoucherCodes = prevVouchers.map((item) => item?.code);
      setValue('voucher', [...voucher?.filter((item) => !prevVoucherCodes.includes(item.code)), ...prevVouchers]);
    }
  }, [trigger, voucher, getValues, setValue]);

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paper}>
        <Grid container spacing={2} style={{marginBottom: '32px'}}>
          <Grid item xs={12} sm={isEnded ? 12 : 8} md={isEnded ? 12 : 8}>
            <Typography style={{fontSize: '14px'}}>You can share voucher URL and set a maximum redemption limit per voucher. You can set multiple URLs to share with different groups. Be sure to share the URL before the Maximum Registration Date</Typography>
          </Grid>

          {isEnded ? null : <Grid item xs={12} sm={4} md={4}>
            <MButton
              label="Generate Voucher"
              color="primary"
              startIcon={<Add />}
              style={{fontSize: '12px'}}
              fullWidth
              onClick={onAddVoucher}
              disabled={isLoading || isLoadingUpdateVoucher}
            />
          </Grid>}
        </Grid>

        {!vouchers?.length ? (
          <div style={{textAlign: 'center'}}>
            <Typography>This voucher list is empty</Typography>
          </div>
          ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              marginBottom: '32px',
            }}
          >
            {vouchers.map((item, index) => (
              <VoucherItem
                control={control}
                disableActions={status !== 'active' || isEnded}
                getValues={getValues}
                index={index}
                key={item?.id}
                onDelete={() => onDelete(index)}
                onShare={() => onShare(index)}
                rowNumber={index + 1}
                packageOptions={getPackageOptions(eventPackage)}
              />
            ))}
          </div>
        )}

        {!vouchers?.length || status !== 'active' || isEnded ? null : (
          <div style={{marginTop: '16px', textAlign: 'right'}}>
            <MButton
              label="Update"
              color="primary"
              icon={<Send />}
              onClick={isLoadingUpdateVoucher ? undefined : handleSubmit(onSubmit)}
              loading={isLoadingUpdateVoucher}
            />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default withStyles(styles)(EventVoucher);

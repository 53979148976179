/* eslint-disable max-len */
import {GENERAL_SETTING} from '../../services';
import {GENERAL_SETTING_STATE as STATE} from './generalSetting.types';
import {transformObject, isArray} from '../../helpers';

export const getGeneralSetting = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_ALL));
    GENERAL_SETTING.getAll(params)
        .then((response) => {
          dispatch(requestSuccess(STATE.FETCH_ALL_SUCCCESS, response.data));
        })
        .catch((error) => {
          const response = error.response.data;
          dispatch(requestFailed(STATE.FETCH_ALL_FAILED, response.data.message));
        });
  };
};

export const updateGeneralSetting = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    GENERAL_SETTING.update(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const publishAnnouncements = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.PUBLISH, true));
    GENERAL_SETTING.publish(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.PUBLISH_SUCCESS, response.data));
            },
            () => {
              dispatch(requestFailed(STATE.PUBLISH_FAILED, null));
            },
        );
  };
};

export const fetchQRSettings = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_QR_SETTINGS, true));
    GENERAL_SETTING.getQRSettings(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_QR_SETTINGS_SUCCESS, response.data.data));
            },
            () => {
              dispatch(requestFailed(STATE.FETCH_QR_SETTINGS_FAILED, null));
            },
        );
  };
};

export const generateQR = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GENERATE_QR, true));
    GENERAL_SETTING.generateQR(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.GENERATE_QR_SUCCESS, response.data.data));
            },
            () => {
              dispatch(requestFailed(STATE.GENERATE_QR_FAILED, null));
            },
        );
  };
};

export const generateLink = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GENERATE_LINK, true));
    GENERAL_SETTING.generateLink(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.GENERATE_LINK_SUCCESS, response.data.data));
            },
            () => {
              dispatch(requestFailed(STATE.GENERATE_LINK_FAILED, null));
            },
        );
  };
};

export const updateAutoRefreshQR = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_AUTO_REFRESH, true));
    GENERAL_SETTING.updateAutoRefreshQR(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_AUTO_REFRESH_SUCCESS, response.data.data));
            },
            () => {
              dispatch(requestFailed(STATE.UPDATE_AUTO_REFRESH_FAILED, null));
            },
        );
  };
};

export const checkTokenQR = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CHECK_TOKEN, true));
    GENERAL_SETTING.checkToken(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CHECK_TOKEN_SUCCESS, response.data.data));
            },
            () => {
              dispatch(requestFailed(STATE.CHECK_TOKEN_FAILED, null));
            },
        );
  };
};

export const getBannerLogin = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_BANNER));
    GENERAL_SETTING.getBannerLogin(params)
        .then((response) => {
          dispatch(requestSuccess(STATE.FETCH_BANNER_SUCCESS, response.data));
        })
        .catch((error) => {
          dispatch(requestSuccess(STATE.FETCH_BANNER_SUCCESS, []));
          // const response = error.response.data;
          // dispatch(requestFailed(STATE.FETCH_BANNER_FAILED, response.data.message));
        });
  };
};

export const uploadBanner = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.POST_BANNER));
    GENERAL_SETTING.uploadBanner(params)
        .then((response) => {
          dispatch(requestSuccess(STATE.POST_BANNER_SUCCESS, response.data));
        })
        .catch((error) => {
          const response = error.response.data;
          dispatch(requestFailed(STATE.POST_BANNER_FAILED, response.data.message));
        });
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: 2,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  datatable: {
    marginTop: 25,
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  checkBox: {
    width: 70,
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    '&:hover': {
      backgroundColor: '#00A2DE',
    },
    'color': '#fff',
  },
  hasError: {
    color: 'red',
  },
  photo: {
    height: 150,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
  filterBtn: {
    'marginRight': 15,
    'marginLeft': 10,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'marginTop': 20,
    'color': '#fff',
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 0,
  },
  filter: {
    marginRight: 25,
  },
  filterSearch: {
    minWidth: 300,
    marginRight: 25,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  dialogFooter: {
    padding: 20,
  },
  dialogContent: {
    paddingTop: '0 !important',
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  btnCrop: {
    marginTop: 10,
  },
  searchInput: {
    width: '350px',
  },
  syncTrue: {
    color: '#89d300',
    fontSize: 20,
  },
  syncFalse: {
    color: '#34a3ed',
    fontSize: 20,
  },
  syncWarn: {
    color: '#FFCC00',
    fontSize: 20,
  },
  btnExport: {
    'backgroundColor': '#009688',
    '&:hover': {
      backgroundColor: '#00796b',
    },
    'color': '#fff',
  },
});

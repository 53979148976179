/* eslint-disable max-len */
import {ACCOUNT} from '../../services';
import {ACCOUNT_STATE as STATE} from './account.types';
import {transformObject, isArray} from '../../helpers';

export const checkToken = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CHECK_TOKEN, true));
    ACCOUNT.checkToken(params)
        .then(
            () => {
              dispatch(requestSuccess(STATE.CHECK_TOKEN_SUCCESS, null));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CHECK_TOKEN_FAILED, message));
            },
        );
  };
};

export const updatePassword = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CHANGE_PASSWORD));
    ACCOUNT.updatePassword(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CHANGE_PASSWORD_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CHANGE_PASSWORD_FAILED, message));
            },
        );
  };
};

export const forgotPassword = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FORGOT_PASSWORD));
    ACCOUNT.forgotPassword(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FORGOT_PASSWORD_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.FORGOT_PASSWORD_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

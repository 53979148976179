/* eslint-disable max-len */
import React, {Component} from 'react';
import {Switch, Route, Router} from 'react-router-dom';
import {connect} from 'react-redux';
import {BrowserHistory} from '../config';
import Public from './public';
import Private from './private';
import PrivateRoute from './private-route';
import RouteList from '../routes.js';

class Routes extends Component {
  render() {
    const withoutAuth = RouteList.filter((item) => item.exact === true);
    const withAuth = RouteList.filter((item) => item.exact === false);
    const paths = withAuth.map((item) => item.path);
    return (
      <Router history={BrowserHistory}>
        <Switch>
          <Route exact path={paths}>
            <Private>
              {withAuth.map((item, index) => (
                <PrivateRoute
                  exact
                  key={index.toString()}
                  path={item.path}
                  component={item.component}
                  authed={this.props.token}
                />
              ))}
            </Private>
          </Route>
          <Route exact>
            <Public>
              {withoutAuth.map((item, index) => (
                <Route
                  exact={item.exact}
                  key={index.toString()}
                  path={item.path}
                  component={item.component}
                />
              ))}
            </Public>
          </Route>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = ({auth}) => ({
  token: auth.data.authorization,
});

export default connect(
    mapStateToProps,
)(Routes);

export const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    trigger: '',
    leaveConfigData: [],
    leaveConfigMeta: {page: 1, total: 0},
    message: {
      name: '',
    },
  };

  export const LEAVE_CONFIG_STATE = {
    REQUEST: 'LEAVE_CONFIG_REQUEST',
    REQUEST_FAILED: 'LEAVE_CONFIG_REQUEST_FAILED',
    CLEAR_STATE: 'LEAVE_CONFIG_CLEAR_STATE',
    FETCH: 'LEAVE_CONFIG_FETCH',
    FETCH_SUCCCESS: 'LEAVE_CONFIG_FETCH_SUCCCESS',
    FETCH_FAILED: 'LEAVE_CONFIG_FETCH_FAILED',
    CREATE: 'LEAVE_CONFIG_CREATE',
    CREATE_FAILED: 'LEAVE_CONFIG_CREATE_FAILED',
    CREATE_SUCCESS: 'LEAVE_CONFIG_CREATE_SUCCESS',
    UPDATE: 'LEAVE_CONFIG_UPDATE',
    UPDATE_SUCCESS: 'LEAVE_CONFIG_UPDATE_SUCCESS',
    UPDATE_FAILED: 'LEAVE_CONFIG_UPDATE_FAILED',
    DROP: 'LEAVE_CONFIG_DROP',
    DROP_SUCCESS: 'LEAVE_CONFIG_DROP_SUCCESS',
    DROP_FAILED: 'LEAVE_CONFIG_DROP_FAILED',
  };


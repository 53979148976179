/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import {Send} from '@material-ui/icons';
import styles from './styles';
import {showPopup, toast, isObject, checkSuperadmin} from '../../helpers';
import {Datatable} from '../../components';
import {MInput, MButton, ModalTitle} from '../../components/form';
import {ROLE_STATE as STATE} from '../../redux/roleManagement/rolemanagement.types';
import {
  getRole,
  createRole,
  updateRole,
  deleteRole,
  clearState,
} from './../../redux/roleManagement/rolemanagement.actions';

class RoleManagement extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      editing: false,
      showModal: false,
      formData: {
        id: null,
        name: null,
        description: null,
      },
      errorMessage: null,
      sortBy: null,
      order: 'desc',
    };
  }

  getMuiTheme = () => createTheme({
    overrides: {
      MuiDialog: {
        paperWidthSm: {
          width: 575,
        },
      },
      MuiDialogContent: {
        root: {
          '&:first-child': {
            paddingTop: 0,
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  UNSAFE_componentWillMount() {
    this.fetchDatatable();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message} = nextProps;

    if (isSuccess) {
      if (trigger === STATE.CREATE_SUCCESS || trigger === STATE.UPDATE_SUCCESS || trigger === STATE.DROP_SUCCESS) {
        this.handleResetForm(true);
        this.fetchDatatable();
        toast('success', message);
      }
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  fetchDatatable = () => {
    const {page, limit, searchFilter, order, sortBy} = this.state;
    this.props.getRole({page, limit, query: searchFilter, order, sort_by: sortBy});
  }

  handleReload = (params) => {
    this.setState(
        params,
        () => this.fetchDatatable(),
    );
  }

  handleResetForm = (closeModal) => {
    this.setState({
      errorMessage: null,
      formData: {
        id: null,
        name: null,
        description: null,
      },
    });
    if (closeModal) this.setState({showModal: false});
  }

  handleInputChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      formData: {...this.state.formData, [name]: value ? value : null},
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  };

  handleSubmitDelete = (id) => {
    this.props.deleteRole(id);
  }

  handleFormToggle = (type, data = null) => {
    if (type === 'edit') {
      this.setState({
        showModal: !this.state.showModal,
        editing: true,
        formData: {
          id: data[1],
          name: data[2],
          description: data[3],
        },
      });
    } else {
      this.handleResetForm();
      this.setState({
        editing: false,
        showModal: !this.state.showModal,
      });
    }
  }

  handleConfirmDelete = (data) => {
    showPopup(
        'confirm',
        'Are you sure you want to delete this data ?',
        'Yes',
        () => this.props.deleteRole(data[1]),
    );
  }

  handleSearch = (keyword) => {
    this.setState({searchFilter: keyword, page: 1}, () => this.fetchDatatable());
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {createRole, updateRole} = this.props;
    const {editing} = this.state;
    const {id, name, description} = this.state.formData;
    const params = {
      ...(name && {name}),
      ...(description && {description}),
    };
    return (!editing) ? createRole(params) : updateRole(id, params);
  }

  renderModalForm = () => {
    const {classes, isLoading} = this.props;
    const {showModal, editing, errorMessage} = this.state;
    const {name, description} = this.state.formData;
    const titleDialog = editing ? 'Update Role' : 'Create Role';

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <Dialog
          className={classes.dialogContent}
          open={showModal}
          onClose={() => this.handleFormToggle('close')}
        >
          <ModalTitle
            title={<Typography className={classes.modalTitle}>{titleDialog}</Typography>}
            onClose={() => this.handleFormToggle('close')}
          />
          <form onSubmit={this.handleSubmit}>
            <DialogContent className={classes.dialogContent}>
              <MInput
                classNameFC={classes.formControl}
                autoFocus
                name="name"
                label="Role Name *"
                placeholder="Enter role name"
                defaultValue={name}
                onBlur={this.handleInputChange}
                error={errorMessage && errorMessage.name ? errorMessage.name : undefined}
                fullWidth
              />
              <MInput
                classNameFC={classes.formControl}
                multiline
                rowsMax={5}
                name="description"
                label="Description"
                placeholder="Enter role description"
                defaultValue={description}
                onBlur={this.handleInputChange}
                error={errorMessage && errorMessage.description ? errorMessage.description : undefined}
                fullWidth
              />
            </DialogContent>
            <DialogActions className={classes.dialogFooter}>
              <MButton
                className={classes.cancelBtn}
                label='Cancel'
                size='small'
                onClick={() => this.handleFormToggle('close')}
              />
              <MButton
                className={classes.defaultBtn}
                label={titleDialog}
                color="primary"
                size='small'
                type="submit"
                icon={<Send />}
                loading={isLoading}
              />
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
    );
  };

  render() {
    const {classes, isLoading, roleData, roleMeta} = this.props;
    const columns = [
      {name: 'id', label: 'id', display: false},
      {name: 'name', label: 'Role Name', display: true},
      {name: 'description', label: 'Description', display: true},
    ];

    return (
      <div className={classes.root}>
        <Datatable
          searchable
          title={'Role List'}
          loading={isLoading}
          dataSource={roleData}
          total={roleMeta ? roleMeta.total : 0}
          page={roleMeta ? roleMeta.page : 1}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleCreate={() => this.handleFormToggle('add')}
          handleEdit={(data) => this.handleFormToggle('edit', data)}
          handleDelete={(data) => this.handleConfirmDelete(data)}
          handleSearch={(params) => this.handleSearch(params)}
        />
        {this.renderModalForm()}
      </div>
    );
  }
}

const stateToProps = ({auth, roleManagement}) => ({
  isSuperAdmin: checkSuperadmin(auth),
  isLoading: roleManagement.isLoading,
  isError: roleManagement.isError,
  isSuccess: roleManagement.isSuccess,
  message: roleManagement.message,
  trigger: roleManagement.trigger,
  roleData: roleManagement.roleData,
  roleMeta: roleManagement.roleMeta,
});

const dispatchToProps = (dispatch) => ({
  getRole: (params) => dispatch(getRole(params)),
  createRole: (params) => dispatch(createRole(params)),
  updateRole: (id, params) => dispatch(updateRole(id, params)),
  deleteRole: (params) => dispatch(deleteRole(params)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(RoleManagement));

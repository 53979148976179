/* eslint-disable max-len */
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {convDate} from '../../../../helpers';
import {useHistory} from 'react-router-dom';
import DOMPurify from 'dompurify';

export default function EventCardList({events}) {
    const history = useHistory();
    const handleDetail = (id) => {
        if (!id) return;
        history.push('/event-management/detail', {id});
      };
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'flex-start',
      }}
    >
      {events.length > 0 ? (
        events.map((event) => (
          <Card key={event.id}
          sx={{
            width: 200,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
            <CardMedia
              sx={{height: 100}}
              image={event.photo || ''}
              title={event.title}
            />
            <CardContent style={{padding: '10px 8px 5px 8px'}}>
              <Typography gutterBottom sx={{
                fontSize: '14px',
                fontWeight: 700,
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3}}
                component="div">
                {event.title}
              </Typography>
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(event.description)}}
                style={{color: 'text.secondary',
                  fontSize: '12px',
                  fontWeight: 400,
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3}}/>
              <Typography sx={{color: 'text.secondary', fontSize: '12px', fontWeight: 400}}>
                {convDate(event?.date_start, 'DD MMMM YYYY') || '-'}
              </Typography>
            </CardContent>
            <CardActions style={{display: 'flex', alignItems: 'center'}}>
              <Button onClick={()=> handleDetail(event.id)} style={{backgroundColor: '#00AFF0', borderRadius: '20px', color: '#FFF', fontSize: '10px', fontWeight: 700, padding: '8px 10px'}}>See Event</Button>
            </CardActions>
          </Card>
        ))
      ) : (
        <Typography variant="body1" sx={{textAlign: 'center', width: '100%'}}>
          No events available.
        </Typography>
      )}
    </Box>
  );
}

export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  btnActionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'end !important',
  },
  btnAction: {
    'backgroundColor': '#00AFF0',
    'color': '#FFF',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
  },
  searchAndFilter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start !important',
    alignItems: 'end',
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  searchInput: {
    width: '350px',
    marginBottom: '8px',
  },
  filterType: {
    marginLeft: 10,
    marginBottom: '8px',
    marginRight: 12,
    width: '150px',
  },
  filterCheckbox: {
    // display: "flex !important",
    height: '100% !important',
    marginBottom: '8px',
  },
  filter: {
    marginRight: 25,
  },
});

export const initialState = {
  dtLoading: false,
  loading: false,
  error: null,
  success: null,
  message: null,
  trigger: null,
  permitData: [],
  permitMeta: {page: 1, total: 0},

  isLoadingPut: false,
  isSuccessPut: false,
  isErrorPut: false,
  messagePut: '',
  status: '',
  exportLoading: false,
  exportData: {file: null},
};

export const PERMIT_STATE = {
  GET_PERMIT_HISTORY: 'GET_PERMIT_HISTORY',
  GET_PERMIT_HISTORY_SUCCESS: 'GET_PERMIT_HISTORY_SUCCESS',
  GET_PERMIT_HISTORY_FAILURE: 'GET_PERMIT_HISTORY_FAILURE',

  PUT_PERMIT_CHANGE_STATUS: 'PUT_PERMIT_CHANGE_STATUS',
  PUT_PERMIT_CHANGE_STATUS_SUCCESS: 'PUT_PERMIT_CHANGE_STATUS_SUCCESS',
  PUT_PERMIT_CHANGE_STATUS_FAILURE: 'PUT_PERMIT_CHANGE_STATUS_FAILURE',

  EXPORT: 'PREMIT_EXPORT',
  EXPORT_SUCCESS: 'PREMIT_EXPORT_SUCCESS',
  EXPORT_FAILED: 'PREMIT_EXPORT_FAILED',
};

/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import styles from './styles';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Button,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Switch,
  Box,
  Tab,
  Divider,
  CardMedia,
} from '@material-ui/core';
import {
  Search,
  Refresh,
  Send,
  Edit,
  Delete,
  ArrowBack,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import {toast, convDate, checkSuperadmin, showPopup} from '../../../helpers';
import {
  MInput,
  MButton,
  ModalTitle,
  MDatepicker,
  MCheckboxItem,
  MSelect,
  MCheckboxGroup,
} from '../../../components/form';
import {Datatable} from '../../../components';
import {checkStatus} from '../../../redux/organization/organization.actions';
import {ReactComponent as Remove} from '../../../assets/icon/partner/remove.svg';
import Confirmation from '../../../assets/icon/partner/confirmation.svg';
import {Link} from 'react-router-dom';
import {
  getOnePartner,
  getMember,
  clearState,
} from '../../../redux/partner/partner.actions';
import {
  getOrganizationById,
  clearState as orgClearState,
} from '../../../redux/organization/organization.actions';
import {
  ORGANIZATION_STATE,
} from '../../../redux/organization/organization.types';

import {PARTNER_STATE as STATE} from '../../../redux/partner/partner.types';
import _ from 'lodash';
import {TabContext, TabList, TabPanel} from '@material-ui/lab';

class PartnerDetail extends Component {
  constructor(props) {
    super(props);

    this.initialForm = {
      id: null,
      name: null,
      description: null,
      address: null,
      logo: null,
      logoURL: null,
      organization_type_id: '',
      role_id: '',
      country_code: '',
      user_ids: null,
      users: [],
      pic_name: null,
      pic_number: null,
      pic_email: null,
      pic_name_invoice: null,
      pic_number_invoice: null,
      price: null,
      invoice: null,
      remark: null,
      feature: {
        remote: false,
        office: false,
        business: false,
        overtime: false,
        paidleave: false,
        advantech: false,
        payslip: false,
        tenant: false,
      },
      status: null,
      business_limit: null,
      latitude: null,
      longitude: null,
      radius: null,
      user_limit: null,
      start_period: null,
      end_period: null,
      due_date: null,
      invoice_date: null,
      amount: 0,
      expired_date: null,
      organization_id: null,
      organization_name: null,
      organization_address: null,
      parent_name: null,
      adv_key: null,
      adv_value: null,
      adv_url: null,
      location: [],
      adv_url_local: null,
      odoo_url: null,
      odoo_key: null,
      payslip_limit: null,
      odooStatus: null,
      odooMessage: null,
      prevOdooUrl: null,
      prevOdooKey: null,
    };

    this.state = {
      page: 1,
      limit: 10,
      order: 'desc',
      query: null,
      subscription_date: null,
      resetPage: null,
      partnerDetail: {
        id: null,
        partner_name: null,
        email: null,
        phone_number: null,
        last_activity: null,
        address: null,
        company_partners: null,
        bank_name: null,
        bank_account_number: null,
        bank_account_name: null,
      },
      resetDatatable: null,


      formData: this.initialForm,
      userFeature: null,
      modalEditShow: false,
      showPasswor: false,
      isEdit: false,
      errorMessage: null,
      errorImageSize: null,
      showBusinessLimit: false,
      locationsTab: 0,
      featuresTab: 0,
    };
  }

  switchTheme = () =>
    createTheme({
      palette: {
        primary: {
          main: '#00AFF0',
        },
      },
    });

  componentDidMount() {
    const detailData = this.props.location.state?.detailData;

    if (detailData !== undefined || detailData !== null) {
      this.props.getOnePartner(detailData[1]);
      this.fetchMember(detailData[1]);
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.isSuperAdmin) {
      toast('error', `You don't have permission to access the page`);
      this.props.history.push('/dashboard');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message} = nextProps;
    const {triggerOrg, isSuccessOrg, isErrorOrg, messageOrg, isLoadingOrg, organizationDataById} = nextProps;

    if (isSuccessOrg) {
      if (triggerOrg === ORGANIZATION_STATE.FETCH_BY_ID_SUCCESS) {
        this.setState({

          // dataOrg =

          isEdit: true,
          modalEditShow: true,
        });
      }
    }

    if (trigger === STATE.FETCH_BY_ID_SUCCCESS) {
      const {partnerDataById} = nextProps;
      // console.log(partnerDataById);
      this.setState({
        isEdit: true,
        modalFormShow: !this.state.modalFormShow,
        partnerDetail: {
          id: partnerDataById?.id,
          partner_name: partnerDataById?.partner_name,
          email: partnerDataById?.email,
          phone_number: partnerDataById?.number_phone,
          last_activity: partnerDataById?.last_activity,
          address: partnerDataById?.address,
          company_partners: partnerDataById?.company_partner,
          bank_name: partnerDataById?.bank_name,
          bank_account_number: partnerDataById?.bank_account_number,
          bank_account_name: partnerDataById?.bank_account_name,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  fetchMember = (id) => {
    const {isSuperAdmin} = this.props;

    if (isSuperAdmin) {
      const {page, limit, sortBy, order, query, subscription_date} =
        this.state;
      this.props.getMember({
        id: id ? id : this.state.partnerDetail.id,
        page,
        limit,
        query,
        order,
        sortBy,
        subscription_date: convDate(subscription_date),
      });
    }
  };

  handleResetFilter = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      sortBy: null,
      order: 'desc',
      subscription_date: null,
    };
    this.setState(state, () => this.fetchMember());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  handleReload = (params) => {
    this.setState(params, () => this.fetchMember());
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  };

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: 1}, () => this.fetchMember());
  }, 1000);

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState({[name]: value, page: null}, () => this.fetchMember());
    this.toggleResetPage();
    this.toggleResetDatatable(true);
  };

  renderPartnerDetail = () => {
    const {classes} = this.props;

    const {
      id,
      partner_name,
      email,
      phone_number,
      last_activity,
      address,
      company_partners,
      bank_name,
      bank_account_number,
      bank_account_name,
    } = this.state.partnerDetail;

    return (
      <Paper variant="outlined" className={classes.paper}>
        <div className="d-flex">
          <Link to="/partner" className={classes.btnBack}>
            <ArrowBack />
          </Link>
          <Typography
            style={{marginBottom: 18}}
            className={classes.paperTitle}
          >
            Partner Detail
          </Typography>
        </div>
        <Box sx={{flexGrow: 1}} style={{marginBottom: 8}}>
          <Grid container spacing={4}>
            <Grid item style={{display: 'flex'}}>
              <div>
                <Typography className={classes.paperText}>
                  Partner Name
                </Typography>
                <Typography className={classes.paperText}>Email</Typography>
                <Typography className={classes.paperText}>
                  Phone Number
                </Typography>
                {/* <Typography className={classes.paperText}>
                  Last Activity
                </Typography> */}
                <Typography className={classes.paperText}>Address</Typography>
              </div>
              <div style={{marginLeft: 72, marginRight: 8}}>
                <Typography className={classes.paperText}>:&ensp;</Typography>
                <Typography className={classes.paperText}>:&ensp;</Typography>
                <Typography className={classes.paperText}>:&ensp;</Typography>
                {/* <Typography className={classes.paperText}>:&ensp;</Typography> */}
                <Typography className={classes.paperText}>:&ensp;</Typography>
              </div>
              <div style={{width: 350}}>
                <Typography className={classes.paperText}>
                  {partner_name ? partner_name : '-'}
                </Typography>
                <Typography className={classes.paperText}>
                  {email ? email : '-'}
                </Typography>
                <Typography className={classes.paperText}>
                  {phone_number ? phone_number : '-'}
                </Typography>
                {/* <Typography className={classes.paperText}>
                  {last_activity ? last_activity : '-'}
                </Typography> */}
                <Typography className={classes.paperText}>
                  {address ? address : '-'}
                </Typography>
              </div>
            </Grid>
            <Grid item style={{display: 'flex'}}>
              <div>
                <Typography className={classes.paperText}>Bank Name</Typography>
                <Typography className={classes.paperText}>
                  Bank Account Number
                </Typography>
                <Typography className={classes.paperText}>
                  Bank Account Name
                </Typography>
              </div>
              <div style={{marginLeft: 20, marginRight: 8}}>
                <Typography className={classes.paperText}>:&ensp;</Typography>
                <Typography className={classes.paperText}>:&ensp;</Typography>
                <Typography className={classes.paperText}>:&ensp;</Typography>
              </div>
              <div style={{width: 350}}>
                <Typography className={classes.paperText}>
                  {bank_name ? bank_name : '-'}
                </Typography>
                <Typography className={classes.paperText}>
                  {bank_account_number ? bank_account_number : '-'}
                </Typography>
                <Typography className={classes.paperText}>
                  {bank_account_name ? bank_account_name : '-'}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item style={{display: 'flex'}}>
              <div>
                <Typography className={classes.paperText}>
                  Company Partners
                </Typography>
              </div>
              <div style={{marginLeft: 44, marginRight: 8}}>
                <Typography className={classes.paperText}>:&ensp;</Typography>
              </div>
              <div style={{width: 350}}>
                <Typography className={classes.paperText}>
                  {company_partners} Company
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  };

  renderFilter = () => {
    const {classes, message} = this.props;
    const {query, subscription_date} = this.state;

    return (
      <Paper variant="outlined" className={classes.paper}>
        <Grid container xs={12} spacing={2}>
          <MInput
            autoFocus
            className={`${classes.filter} ${classes.searchInput}`}
            fullWidth={false}
            variant="outlined"
            name="query"
            label="Search"
            placeholder="Enter Search Keyword"
            value={query}
            onChange={this.handleSearch}
            InputProps={{
              endAdornment: (
                <IconButton className={classes.searchIcon} position="end">
                  {' '}
                  <Search />
                </IconButton>
              ),
            }}
          />
          <MDatepicker
            fullWidth={false}
            className={`${classes.filter} ${classes.formControl} ${classes.searchDate}`}
            inputVariant="outlined"
            name="subscription_date"
            label="Subsctripion Date"
            value={subscription_date}
            onDateChange={this.handleFilterDateChange}
            placeholder="dd-mm-yyyy"
            maxDate={
              subscription_date ? new Date(subscription_date) : undefined
            }
            error={
              message && message.subscription_date ?
                message.subscription_date :
                undefined
            }
          />
          <div className={classes.btnFilterContainer}>
            <MButton
              className={classes.defaultBtn}
              label="Reset"
              icon={<Refresh />}
              onClick={this.handleResetFilter}
            />
          </div>
        </Grid>
      </Paper>
    );
  };

  handleEditDialogToggle = async (data) => {
    const {modalEditShow, isEdit} = this.state;

    this.props.getOrganizationById(data[1]);
  };

  renderActions = (params) => {
    const {classes, isSuperAdmin} = this.props;

    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        {isSuperAdmin ? (
          <Tooltip title="Edit" className={classes.childCustomActions}>
            <Button
              variant="contained"
              // color="primary"
              size="small"
              style={{
                'backgroundColor': '#00AFF0',
                'color': '#fff',
                'boxShadow': 'none',
                '&:hover': {
                  backgroundColor: '#00A2DE',
                  boxShadow: 'none',
                },
                'minWidth': '24px',
                'paddingRight': '0',
                'marginRight': 5,
              }}
              onClick={() => this.handleEditDialogToggle(params)}
              startIcon={<Edit />}
            ></Button>
          </Tooltip>
        ) : null}
        {isSuperAdmin ? (
          <Tooltip title="Delete" className={classes.childCustomActions}>
            <Button
              variant="contained"
              // color="primary"
              size="small"
              style={{
                'backgroundColor': '#ef9c02',
                'color': '#fff',
                'boxShadow': 'none',
                '&:hover': {
                  backgroundColor: '#c98200',
                  boxShadow: 'none',
                },
                'minWidth': '24px',
                'paddingRight': '0',
                'marginRight': 5,
              }}
              // onClick={() => this.handleUserList(params)}
              startIcon={<Delete />}
            ></Button>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  renderForm = () => {
    const {classes, isSuperAdmin, isLoading, orgTypeData, modalLoading} =
      this.props;
    const {
      isEdit,
      errorMessage,
      errorImageSize,
      showBusinessLimit,
      locationsTab,
      featuresTab,
      odooStatus,
      odooMessage,
    } = this.state;
    const {
      name,
      parent_name,
      organization_type_id,
      logoURL,
      feature,
      business_limit,
      user_limit,
      pic_name,
      pic_number,
      expired_date,
      pic_email,
      price,
      adv_key,
      adv_value,
      adv_url,
      location,
      adv_url_local,
      odoo_key,
      odoo_url,
      payslip_limit,
      prevOdooKey,
      prevOdooUrl,
      status,
    } = this.state.formData;

    const titleDialog = isEdit ? 'Update Company' : 'Create Company';
    // const merged = [...orgAdminData, ...users];

    const featureArray = Object.keys(feature).map((key) => {
      return {name: key, value: feature[key]};
    });

    const advanceFeat =
      featureArray &&
      featureArray.filter((value) => {
        return (
          (value.name === 'business' ||
            value.name === 'advantech' ||
            value.name === 'payslip') &&
          value.value === true
        );
      });

    const disabled =
      (!odoo_key ||
        !odoo_url ||
        prevOdooKey !== odoo_key ||
        prevOdooUrl !== odoo_url) &&
      feature &&
      feature.payslip === true &&
      odooStatus !== true ?
        true :
        false;

    const statusDisabled = () => {
      if (isSuperAdmin) {
        return false;
      } else {
        if (isEdit) {
          return true;
        } else {
          return false;
        }
      }
    };

    const statusExpiredDisabled = () => {
      if (isSuperAdmin) {
        if (isEdit) {
          if (parent_name) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        if (isEdit) {
          return true;
        } else {
          return false;
        }
      }
    };

    const renderMethodAdminOptions = () => {
      const options = [
        {id: 'paid', name: 'Paid'},
        {id: 'unpaid', name: 'Unpaid'},
      ];
      return options;
    };

    return (
      <Dialog
        open={this.state.modalEditShow}
        onClose={(event, reason) =>
          this.setState({
            isEdit: false,
            modalEditShow: false,
          })
        }
        maxWidth="md"
      >
        <ModalTitle
          title={titleDialog}
          onClose={(event, reason) =>
            this.setState({
              isEdit: false,
              modalEditShow: false,
            })
          }
        />
        <form onSubmit={this.handleSubmit}>
          <DialogContent className={classes.dialogContent}>
            <MInput
              name="name"
              label="Company Name"
              placeholder="Enter company name"
              autoComplete="off"
              disabled={statusDisabled()}
              defaultValue={name}
              onBlur={(event) => {
                this.handleChangeAddress(event, 0);
                this.handleInputChange(event);
              }}
              error={
                errorMessage && errorMessage.name ?
                  errorMessage.name :
                  undefined
              }
            />
            <MSelect
              classNameFC={classes.formControl}
              name="organization_type_id"
              label="Type / Business Industry"
              placeholder="Select company type"
              keyPair={['id', 'name']}
              disabled={statusDisabled()}
              options={orgTypeData ? orgTypeData : []}
              value={organization_type_id}
              onChange={this.handleInputChange}
              error={
                errorMessage && errorMessage.organization_type_id ?
                  errorMessage.organization_type_id :
                  undefined
              }
              loading={modalLoading}
            />
            <MInput
              name="pic_name"
              label="PIC Name"
              placeholder="Enter PIC Name"
              autoComplete="off"
              disabled={statusDisabled()}
              defaultValue={pic_name}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.pic_name ?
                  errorMessage.pic_name :
                  undefined
              }
            />
            <MInput
              name="pic_number"
              label="PIC Phone"
              placeholder="Enter PIC Number"
              autoComplete="off"
              disabled={statusDisabled()}
              defaultValue={pic_number}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.pic_number ?
                  errorMessage.pic_number :
                  undefined
              }
              type={'number'}
            />
            <MInput
              name="pic_email"
              label="PIC Email"
              placeholder="Enter PIC email"
              autoComplete="off"
              disabled={statusDisabled()}
              defaultValue={pic_email}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.pic_email ?
                  errorMessage.pic_email :
                  undefined
              }
              type={'email'}
            />
            {isSuperAdmin ? (
              <MInput
                classNameFC={`${classes.formControl}`}
                name="user_limit"
                label="User Limit *"
                placeholder="User Limit"
                type="number"
                disabled={statusExpiredDisabled()}
                defaultValue={statusExpiredDisabled() ? null : user_limit}
                onBlur={this.handleInputChange}
                error={
                  errorMessage && errorMessage.user_limit ?
                    errorMessage.user_limit :
                    undefined
                }
                inputProps={{step: 'any'}}
              />
            ) : null}
            {/* {isSuperAdmin ? (
              !isEdit ? (
                <MInput
                  classNameFC={`${classes.formControl}`}
                  name="user_limit"
                  label="User Limit"
                  placeholder="User Limit"
                  type="number"
                  disabled={statusDisabled()}
                  defaultValue={user_limit}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.user_limit ? errorMessage.user_limit : undefined}
                  inputProps={{step: 'any'}}
                />
              ) : (
                user_limit ? (
                  <MInput
                    classNameFC={`${classes.formControl}`}
                    name="user_limit"
                    label="User Limit"
                    placeholder="User Limit"
                    type="number"
                    disabled={statusDisabled()}
                    defaultValue={user_limit}
                    onBlur={this.handleInputChange}
                    error={errorMessage && errorMessage.user_limit ? errorMessage.user_limit : undefined}
                    inputProps={{step: 'any'}}
                  />
                ) : null
              )
            ) : null} */}
            {isSuperAdmin ? (
              isEdit ? (
                <Grid container>
                  <Box
                    sx={{width: '100%', typography: 'body1'}}
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: '1px solid rgb(0 0 0 / 30%)',
                      borderRadius: '4px',
                      marginTop: '16px',
                      marginBottom: '8px',
                    }}
                  >
                    <TabContext value={locationsTab}>
                      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList
                          onChange={(event, newValue) =>
                            this.handleChangeTab(newValue, 'locationsTab')
                          }
                          aria-label="location"
                          variant="scrollable"
                          scrollButtons="auto"
                          allowScrollButtonsMobile
                          indicatorColor="primary"
                          disabled={statusDisabled()}
                        >
                          {location &&
                            (location ?
                              location.map((value, idx) => {
                                return (
                                  <Tab
                                    key={idx}
                                    label={
                                        value.name ?
                                          value.name
                                              .split(' ')
                                              .slice(0, 4)
                                              .join(' ') +
                                            (value.name.split(' ').length > 4 ?
                                              '..' :
                                              '') :
                                          'Branch ' + (idx + 1)
                                    }
                                    value={idx}
                                  />
                                );
                              }) :
                              null)}
                        </TabList>
                      </Box>
                      {location &&
                        (location ?
                          location.map((value, idx) => {
                            return (
                              <>
                                <TabPanel value={idx} style={{padding: 0}}>
                                  <Grid container>
                                    <Grid xs={12}>
                                      <MInput
                                        name="address"
                                        label="Address"
                                        disabled={statusDisabled()}
                                        placeholder="Company address"
                                        defaultValue={value.address}
                                        onBlur={(event) =>
                                          this.handleChangeAddress(event, idx)
                                        }
                                        error={
                                            errorMessage && errorMessage.address ?
                                              errorMessage.address :
                                              undefined
                                        }
                                        multiline
                                        rows={2}
                                        rowsMin={2}
                                        rowsMax={5}
                                      />
                                    </Grid>
                                    <Grid xs={4} spacing={2}>
                                      <MInput
                                        classNameFC={`${classes.formControl} ${classes.pr15}`}
                                        name="lat"
                                        label="Latitude"
                                        disabled={statusDisabled()}
                                        placeholder="Input latitude"
                                        type="number"
                                        defaultValue={value.lat}
                                        onBlur={(event) =>
                                          this.handleChangeAddress(event, idx)
                                        }
                                        error={
                                            errorMessage &&
                                            errorMessage.latitude ?
                                              errorMessage.latitude :
                                              undefined
                                        }
                                        inputProps={{step: 'any'}}
                                      />
                                    </Grid>
                                    <Grid xs={4} spacing={2}>
                                      <MInput
                                        classNameFC={`${classes.formControl} ${classes.pr15}`}
                                        name="long"
                                        disabled={statusDisabled()}
                                        label="Longitude"
                                        placeholder="Input longitude"
                                        type="number"
                                        defaultValue={value.long}
                                        onBlur={(event) =>
                                          this.handleChangeAddress(event, idx)
                                        }
                                        error={
                                            errorMessage &&
                                            errorMessage.longitude ?
                                              errorMessage.longitude :
                                              undefined
                                        }
                                        inputProps={{step: 'any'}}
                                      />
                                    </Grid>
                                    <Grid xs={4} spacing={2}>
                                      <MInput
                                        name="radius"
                                        label="GPS Radius (meter)"
                                        disabled={statusDisabled()}
                                        placeholder="Max GPS radius"
                                        type="number"
                                        defaultValue={value.radius}
                                        onBlur={(event) =>
                                          this.handleChangeAddress(event, idx)
                                        }
                                        error={
                                            errorMessage && errorMessage.radius ?
                                              errorMessage.radius :
                                              undefined
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </TabPanel>
                              </>
                            );
                          }) :
                          null)}
                    </TabContext>
                  </Box>
                </Grid>
              ) : null
            ) : null}
            {isSuperAdmin ? (
              <MInput
                name="price"
                type="text"
                label="Amount"
                placeholder="Enter Amount"
                disabled={statusDisabled()}
                autoComplete="off"
                value={price}
                onChange={this.handleInputChangeCurrency}
                error={
                  errorMessage && errorMessage.price ?
                    errorMessage.price :
                    undefined
                }
              />
            ) : null}
            {isSuperAdmin && isEdit ? (
              <MSelect
                classNameFC={classes.formControl}
                name="status"
                label="Status"
                placeholder="Select status"
                keyPair={['id', 'name']}
                disabled={statusDisabled()}
                options={
                  renderMethodAdminOptions() ? renderMethodAdminOptions() : []
                }
                value={status}
                onChange={this.handleInputChange}
                error={
                  errorMessage && errorMessage.status ?
                    errorMessage.status :
                    undefined
                }
                loading={modalLoading}
              />
            ) : null}
            {isSuperAdmin ? (
              <MDatepicker
                className={classes.formControl}
                name="expired_date"
                label="Expired Date"
                disabled={statusExpiredDisabled()}
                value={expired_date}
                onDateChange={(params) => this.handleInvoiceDateChange(params)}
                error={
                  errorMessage && errorMessage.expired_date ?
                    errorMessage.expired_date :
                    undefined
                }
                minDate={new Date()}
                placeholder="dd-mm-yyyy"
                inputVariant="outlined"
                views={['year', 'month', 'date']}
                fullWidth
              />
            ) : null}
            {isSuperAdmin ? (
              <MCheckboxGroup
                label="Select Active Features"
                error={
                  errorMessage && errorMessage.feature ?
                    errorMessage.feature :
                    undefined
                }
                row
              >
                <MCheckboxItem
                  name="office"
                  label="Office Working"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.office : undefined}
                />
                <MCheckboxItem
                  name="remote"
                  label="Remote Working"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.remote : undefined}
                />
                <MCheckboxItem
                  name="business"
                  label="Business Trip"
                  disabled={statusDisabled()}
                  onChange={(event) =>
                    this.handleCheckboxChange(event, advanceFeat)
                  }
                  checked={feature ? feature.business : undefined}
                />
                <MCheckboxItem
                  name="overtime"
                  label="Overtime"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.overtime : undefined}
                />
                <MCheckboxItem
                  name="paidleave"
                  label="Leave"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.paidleave : undefined}
                />
                <MCheckboxItem
                  name="permit"
                  label="Permit"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.permit : undefined}
                />
                <MCheckboxItem
                  name="break"
                  label="Break"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.break : undefined}
                />
                <MCheckboxItem
                  name="shift"
                  label="Shift"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.shift : undefined}
                />
                <MCheckboxItem
                  name="reimburse"
                  label="Reimburse"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.reimburse : undefined}
                />
                <MCheckboxItem
                  name="advantech"
                  label="Advantech"
                  disabled={statusDisabled()}
                  onChange={(event) =>
                    this.handleCheckboxChange(event, advanceFeat)
                  }
                  checked={feature ? feature.advantech : undefined}
                />
                <MCheckboxItem
                  name="payslip"
                  label="Odoo Connect"
                  disabled={statusDisabled()}
                  onChange={(event) =>
                    this.handleCheckboxChange(event, advanceFeat)
                  }
                  checked={feature ? feature.payslip : undefined}
                />
                {/* <MCheckboxItem
                  name="tenant_management"
                  label="Tenant Management"
                  disabled={statusDisabled()}
                  onChange={(event) =>
                    this.handleCheckboxChange(event, advanceFeat)
                  }
                  checked={feature ? feature.tenant_management : undefined}
                /> */}
              </MCheckboxGroup>
            ) : null}
            {/* <p className={classes.featureNote}>*Required (select at least 1 type)<br/>**Optional</p> */}
            {isSuperAdmin ?
              advanceFeat &&
                advanceFeat.length > 0 && (
                <Grid container>
                  <Box
                    sx={{width: '100%', typography: 'body1'}}
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: '1px solid rgb(0 0 0 / 30%)',
                      borderRadius: '4px',
                      marginTop: '16px',
                      marginBottom: '8px',
                    }}
                  >
                    <TabContext value={featuresTab}>
                      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList
                          onChange={(event, newValue) =>
                            this.handleChangeTab(newValue, 'featuresTab')
                          }
                          aria-label="location"
                          variant="scrollable"
                          scrollButtons="auto"
                          allowScrollButtonsMobile
                          indicatorColor="primary"
                          disabled={statusDisabled()}
                        >
                          {advanceFeat &&
                              (advanceFeat ?
                                advanceFeat.map((value, idx) => {
                                  return (
                                    <Tab
                                      key={idx}
                                      label={
                                          value.name === 'payslip' ?
                                            'Odoo Connect' :
                                            value.name
                                      }
                                      value={idx}
                                    />
                                  );
                                }) :
                                null)}
                        </TabList>
                      </Box>
                      {advanceFeat &&
                          (advanceFeat ?
                            advanceFeat.map((value, idx) => {
                              return (
                                <>
                                  {!modalLoading &&
                                      showBusinessLimit &&
                                      value.name === 'business' && (
                                    <TabPanel
                                      value={idx}
                                      style={{padding: 0}}
                                    >
                                      <Grid container>
                                        <MInput
                                          name="business_limit"
                                          label="Business Trip Limit"
                                          placeholder="Input maximum number of business trip check-in count"
                                          value={business_limit}
                                          onChange={this.handleInputChange}
                                          error={
                                                errorMessage &&
                                                errorMessage.business_limit ?
                                                  errorMessage.business_limit :
                                                  undefined
                                          }
                                          type="number"
                                          disabled={statusDisabled()}
                                        />
                                      </Grid>
                                    </TabPanel>
                                  )}
                                  {value.name === 'advantech' && (
                                    <TabPanel
                                      value={idx}
                                      style={{padding: 0}}
                                    >
                                      <Grid container>
                                        <MInput
                                          name="adv_key"
                                          label="Advantech Username"
                                          disabled={statusDisabled()}
                                          placeholder="Username"
                                          autoComplete="off"
                                          defaultValue={adv_key}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.adv_key ?
                                                errorMessage.adv_key :
                                                undefined
                                          }
                                          type="none"
                                        />
                                        <MInput
                                          name="adv_value"
                                          label="Advantech Password"
                                          disabled={statusDisabled()}
                                          placeholder="Password"
                                          autoComplete="off"
                                          defaultValue={adv_value}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.adv_value ?
                                                errorMessage.adv_value :
                                                undefined
                                          }
                                          type={
                                              this.state.showPassword ?
                                                'none' :
                                                'password'
                                          }
                                          InputProps={{
                                            endAdornment: (
                                              <IconButton
                                                position="end"
                                                onClick={
                                                  this.handleTogglePassword
                                                }
                                              >
                                                {!this.state.showPassword ? (
                                                    <Visibility />
                                                  ) : (
                                                    <VisibilityOff />
                                                  )}
                                              </IconButton>
                                            ),
                                          }}
                                        />
                                        <MInput
                                          name="adv_url"
                                          label="Advantech URL Public"
                                          disabled={statusDisabled()}
                                          placeholder="URL"
                                          autoComplete="off"
                                          defaultValue={adv_url}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.adv_url ?
                                                errorMessage.adv_url :
                                                undefined
                                          }
                                          type="url"
                                        />
                                        <MInput
                                          name="adv_url_local"
                                          label="Advantech URL Local"
                                          disabled={statusDisabled()}
                                          placeholder="URL"
                                          autoComplete="off"
                                          defaultValue={adv_url_local}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.adv_url_local ?
                                                errorMessage.adv_url_local :
                                                undefined
                                          }
                                          type="url"
                                        />
                                      </Grid>
                                    </TabPanel>
                                  )}
                                  {value.name === 'payslip' && (
                                    <TabPanel
                                      value={idx}
                                      style={{padding: 0}}
                                    >
                                      <Grid container direction="column">
                                        <MInput
                                          name="odoo_key"
                                          label="Odoo Api Key"
                                          disabled={statusDisabled()}
                                          placeholder="Odoo Api Key"
                                          autoComplete="off"
                                          defaultValue={odoo_key}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.odoo_key ?
                                                errorMessage.odoo_key :
                                                undefined
                                          }
                                          type="none"
                                        />
                                        <MInput
                                          name="odoo_url"
                                          label="Odoo URL"
                                          disabled={statusDisabled()}
                                          placeholder="Odoo URL"
                                          autoComplete="off"
                                          defaultValue={odoo_url}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.odoo_url ?
                                                errorMessage.odoo_url :
                                                undefined
                                          }
                                          type="url"
                                        />
                                        <Grid container alignItems="center">
                                          <MButton
                                            className={classes.defaultBtn}
                                            label={'Check Odoo'}
                                            icon={<Refresh />}
                                            onClick={this.checkOdoo}
                                            disabled={!odoo_key && !odoo_url}
                                            loading={isLoading ? true : false}
                                            style={{
                                              textTransform: 'none',
                                              margin: '5px 0px 5px 0px',
                                            }}
                                          />
                                          <Typography
                                            style={{
                                              color: odooStatus ?
                                                  'green' :
                                                  'red',
                                              marginLeft: 5,
                                            }}
                                          >
                                            {odooMessage}
                                          </Typography>
                                        </Grid>
                                        <Divider
                                          style={{
                                            marginTop: '16px',
                                            marginBottom: '5px',
                                          }}
                                        />
                                        <MInput
                                          name="payslip_limit"
                                          label="Payslip Limit"
                                          disabled={statusDisabled()}
                                          placeholder="Payslip Limit"
                                          autoComplete="off"
                                          defaultValue={payslip_limit}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.payslip_limit ?
                                                errorMessage.payslip_limit :
                                                undefined
                                          }
                                          type="number"
                                        />
                                      </Grid>
                                    </TabPanel>
                                  )}
                                </>
                              );
                            }) :
                            null)}
                    </TabContext>
                  </Box>
                </Grid>
              ) :
              null}
            {isSuperAdmin ? (
              <MInput
                name="logo"
                label="Logo"
                type="file"
                disabled={statusDisabled()}
                onChange={this.handleImageChange}
                error={
                  errorImageSize ?
                    errorImageSize :
                    errorMessage && errorMessage.logo ?
                    errorMessage.logo :
                    undefined
                }
              />
            ) : null}
            {logoURL && (
              <CardMedia
                title="Photo"
                image={logoURL}
                className={classes.logo}
              />
            )}
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            {disabled && (
              <span>
                <i>*Please make sure the Odoo connection is succeed</i>
              </span>
            )}
            <MButton
              label="Cancel"
              onClick={() => this.handleFormToggle('close')}
            />
            {statusDisabled() ? null : (
              <MButton
                className={classes.defaultBtn}
                label={titleDialog}
                icon={<Send />}
                type="submit"
                loading={isLoading ? true : false}
                disabled={disabled}
              />
            )}
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  render() {
    const {classes, isLoading, memberData, memberMeta, isLoadingMember} =
      this.props;
    const columns = [
      {
        name: 'no',
        label: 'No',
        display: true,
        width: '300px',
      },
      {
        name: 'id',
        label: 'id',
        display: false,
      },
      {
        name: 'name',
        label: 'Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'pic_email',
        label: 'PIC Email',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'user_limit',
        label: 'User Limit',
        display: true,
      },
      {
        name: 'total_user',
        label: 'Total User',
        display: true,
      },
      {
        name: 'subscription_date',
        label: 'Subscription Date',
        display: true,
        customBodyRender: (value) => {
          return value ? (
            <Typography style={{whiteSpace: 'nowrap', fontSize: '14px'}}>
              {convDate(value, 'DD-MM-YYYY')}
            </Typography>
          ) : (
            '-'
          );
        },
      },
      {
        name: 'plan',
        label: 'Current Plan',
        display: true,
        customBodyRender: (value) => (value ? `${value} Month(s)` : '-'),
      },
      {
        name: 'expired_date',
        label: 'Expired Date',
        display: true,
        customBodyRender: (value) => {
          return value ? (
            <Typography style={{whiteSpace: 'nowrap', fontSize: '14px'}}>
              {convDate(value, 'DD-MM-YYYY')}
            </Typography>
          ) : (
            '-'
          );
        },
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          return value === 'unpaid' ? (
            <Typography
              style={{
                whiteSpace: 'nowrap',
                fontSize: '14px',
                color: '#656464',
                fontWeight: 700,
              }}
            >
              {value}
            </Typography>
          ) : value === 'paid' ? (
            <Typography
              style={{
                whiteSpace: 'nowrap',
                fontSize: '14px',
                color: '#008000',
                fontWeight: 700,
              }}
            >
              {value}
            </Typography>
          ) : (
            '-'
          );
        },
      },
      {
        name: 'stage',
        label: 'Stage',
        display: true,
        customBodyRender: (value) => {
          return value === 'prospect' ? (
            <Typography
              style={{
                whiteSpace: 'nowrap',
                fontSize: '14px',
                color: '#E5AF5A',
                fontWeight: 700,
              }}
            >
              {value}
            </Typography>
          ) : value === 'account' ? (
            <Typography
              style={{
                whiteSpace: 'nowrap',
                fontSize: '14px',
                color: '#008000',
                fontWeight: 700,
              }}
            >
              {value}
            </Typography>
          ) : (
            '-'
          );
        },
      },
    ];

    return (
      <div className={classes.root}>
        {this.renderPartnerDetail()}
        {this.renderFilter()}
        <Datatable
          title={'Member List'}
          loading={isLoading}
          dataSource={memberData}
          total={memberMeta ? memberMeta.total : 0}
          page={memberMeta ? memberMeta.page : 1}
          columns={columns}
          // customActions={(data) => this.renderActions(data)}
          hideActions={true}
          creatable={false}
          handleReload={(params) => this.handleReload(params)}
          // handleCreate={() => this.handleFormToggle('add')}
          // toggleResetPage={resetPage}
          manualNumbering={true}
        />
        {this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = ({auth, partner, organization}) => {
  return {
    auth: auth,
    isSuperAdmin: checkSuperadmin(auth),
    isLoading: partner.isLoading,
    isError: partner.isError,
    isSuccess: partner.isSuccess,
    message: partner.message,
    trigger: partner.trigger,
    partnerData: partner.partnerData,
    partnerDataById: partner.partnerDataById,
    partnerMeta: partner.partnerMeta,

    isLoadingMember: partner.isLoadingMember,
    isSuccessMember: partner.isSuccessMember,
    messageMember: partner.messageMember,
    triggerMember: partner.triggerMember,
    memberData: partner.memberData,
    memberMeta: partner.memberMeta,

    organizationDataById: organization.organizationDataById,
    isLoadingOrg: organization.isLoading,
    isSuccessOrg: organization.isSuccess,
    messageOrg: organization.message,
    triggerOrg: organization.trigger,
    isErrorOrg: organization.isError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkStatus: () => dispatch(checkStatus()),
  getOnePartner: (id, params) => dispatch(getOnePartner(id, params)),
  getMember: (params) => dispatch(getMember(params)),
  getOrganizationById: (id, params) => dispatch(getOrganizationById(id, params)),
  clearState: () => dispatch(clearState()),
  orgClearState: () => dispatch(orgClearState()),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(PartnerDetail));

/* eslint-disable max-len */
import {ORGANIZATION} from './../../services';
import {ORGANIZATION_STATE as STATE} from './organization.types';
import {transformObject, isArray, toast} from '../../helpers';

export const getAllOrganization = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    ORGANIZATION.getOrganization(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getOrganizationList = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    ORGANIZATION.getOrganizationList(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_LIST_ORGANIZATION_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_LIST_ORGANIZATION_FAILED, response.data.message));
            },
        );
  };
};

export const assignOrganizationHR = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.ASSIGN_HR));
    ORGANIZATION.assignOrganizationHR(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.ASSIGN_HR_SUCCESS, response?.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.ASSIGN_HR_FAILED, response?.data?.message));
            },
        );
  };
};

export const getOrganizationById = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_BY_ID));
    ORGANIZATION.getOrganizationById(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_BY_ID_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_BY_ID_FAILED, response.data.message));
            },
        );
  };
};

export const getChildOrganizationForHR = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    ORGANIZATION.getChildOrganizationForHR(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getChildsOrganizationForAdmin = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    ORGANIZATION.getChildsOrganizationForAdmin(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getAdmins = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_ADMIN, true));
    ORGANIZATION.getUsersWithoutOrg(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_ADMIN_SUCCESS, response.data));
            },
            () => {
              dispatch(requestFailed(STATE.FETCH_ADMIN_FAILED, null));
            },
        );
  };
};

export const getOrganizationDetail = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_DETAIL, true));
    ORGANIZATION.getDetail(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_DETAIL_SUCCESS, response.data));
            },
            () => {
              dispatch(fetchDetailFailed(STATE.FETCH_DETAIL_FAILED, null)); // special dispatch for revert detailData to null
            },
        );
  };
};

export const createOrganization = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE));
    ORGANIZATION.addOrganization(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CREATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CREATE_FAILED, message));
            },
        );
  };
};

export const createChildOrganization = (id, params) => {
  return (dispatch) => {
    dispatch(request());
    ORGANIZATION.addChildOrganization(id, params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.CREATE_CHILD_SUCCESS, response.data.data.message));
        },
        (error) => {
          const errors = error.response.data.data.errors;
          const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
          dispatch(requestFailed(STATE.CREATE_CHILD_FAILED, message));
        },
      );
  };
};

export const updateOrganization = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    ORGANIZATION.updateOrganization(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const acceptTermsAndCondition = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.ACCEPT_TERMS));
    ORGANIZATION.acceptTermsAndCondition(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.ACCEPT_TERMS_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.ACCEPT_TERMS_FAILED, message));
            },
        );
  };
};

export const deleteOrganization = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.DROP));
    ORGANIZATION.dropOrganization(id)
      .then(
          (response) => {
            dispatch(requestSuccess(STATE.DROP_SUCCESS, response.data.data.message));
          },
          (error) => {
            const errors = error.response.data.data.errors;
            const message = errors ? errors[0].value : error.response.data.data.message;
            dispatch(requestFailed(STATE.DROP_FAILED, message));
          },
      );
  };
};

export const deleteChildOrganization = (params) => {
  return (dispatch) => {
    dispatch(request());
    ORGANIZATION.dropChildOrganization(params)
      .then(
          (response) => {
            dispatch(requestSuccess(STATE.DROP_CHILD_SUCCESS, response.data.data.message));
          },
          (error) => {
            const errors = error.response.data.data.errors;
            const message = errors ? errors[0].value : error.response.data.data.message;
            dispatch(requestFailed(STATE.DROP_CHILD_FAILED, message));
          },
      );
  };
};

export const getOrganizationType = (params) => {
  return (dispatch) => {
    ORGANIZATION.getOrganizationType(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_TYPE_SUCCESS, response.data));
            },
            () => {
              dispatch(requestFailed(STATE.FETCH_TYPE_FAILED));
            },
        );
  };
};

export const getOrganizationTypeWithoutAuth = (params) => {
  return (dispatch) => {
    ORGANIZATION.getOrganizationTypeWithoutAuth(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_TYPE_SUCCESS, response.data));
            },
            () => {
              dispatch(requestFailed(STATE.FETCH_TYPE_FAILED));
            },
        );
  };
};

export const invitationOrganization = (params) => {
  return (dispatch) => {
    ORGANIZATION.invitationOrganization(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.INVITATION_SUCCESS, response.data));
            },
            (err) => {
              dispatch(requestFailed(STATE.INVITATION_FAILED, err.response.data.data.message));
            },
        );
  };
};

export const activateParentOrganization = (params) => {
  return (dispatch) => {
    ORGANIZATION.activateInvitationOrganization(params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.PARENT_SUCCESS, response.data));
        },
        () => {
          dispatch(requestFailed(STATE.PARENT_FAILED));
        },
      );
  };
};

export const activateInvitationOrganization = (params) => {
  return (dispatch) => {
    ORGANIZATION.activateInvitationOrganization(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.ACTIVATE_INVITATION_SUCCESS, response.data));
            },
            (err) => {
              const errors = err.response.data.data.errors;
              const message = errors ? errors[0].value : err.response.data.data.message;
              dispatch(requestFailed(STATE.ACTIVATE_INVITATION_FAILED, message));
            },
        );
  };
};

export const activateParentInvitationOrganization = (params) => {
  return (dispatch) => {
    ORGANIZATION.activateParentInvitationOrganization(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.ACTIVATE_INVITATION_SUCCESS, response.data));
            },
            (err) => {
              const errors = err.response.data.data.errors;
              const message = errors ? errors[0].value : err.response.data.data.message;
              dispatch(requestFailed(STATE.ACTIVATE_INVITATION_FAILED, message));
            },
        );
  };
};

export const confirmInvitationChildOrganization = (params) => {
  return (dispatch) => {
    ORGANIZATION.confirmInvitationChildOrganization(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CONFIRM_INVITATION_SUCCESS, response.data));
            },
            () => {
              dispatch(requestFailed(STATE.CONFIRM_INVITATION_FAILED));
            },
        );
  };
};

export const getMyOrganizationProfile = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_ORG_PROFILE, true));
    ORGANIZATION.getMyOrganizationProfile(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_ORG_PROFILE_SUCCESS, response.data));
            },
            () => {
              dispatch(requestFailed(STATE.FETCH_ORG_PROFILE_FAILED, null));
            },
        );
  };
};

export const updateMyProfile = (params) => {
  return (dispatch) => dispatch(requestSuccess(STATE.UPDATE_MY_PROFILE, params));
};

export const checkStatus = () => {
  return (dispatch) => {
    dispatch(request(STATE.CHECK_STATUS, true));
    ORGANIZATION.checkStatus()
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CHECK_STATUS_SUCCESS, response.data.data));
            },
            () => {
              dispatch(requestFailed(STATE.CHECK_STATUS_FAILED, null));
            },
        );
  };
};

export const getOrganizationAnalytics = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.ORG_ANALYTICS));
    ORGANIZATION.getOrganizationAnalytics(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.ORG_ANALYTICS_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.ORG_ANALYTICS_FAILED, response.data.message));
            },
        );
  };
};

export const getOrgAnalyticsDetails = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_ANALYTICS_DETAILS));
    ORGANIZATION.getOrgAnalyticsDetails(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_ANALYTICS_DETAILS_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_ANALYTICS_DETAILS_FAILED, response.data.message));
            },
        );
  };
};

export const checkOdoo = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CHECK_ODOO));
    ORGANIZATION.checkOdoo(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CHECK_ODOO_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CHECK_ODOO_FAILED, message));
            },
        );
  };
};

export const exportExcel = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT, true));
    ORGANIZATION.exportExcel(params)
        .then(
            (response) => {
              const file = response.data.data.file;
              dispatch(requestSuccess(STATE.EXPORT_SUCCESS, response.data.data));
              window.location.href = file; // alternative : window.open(file);
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(exportFailed(STATE.EXPORT_FAILED, message));
            },
        );
  };
};

export const updateMobileFeatureSettings = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    ORGANIZATION.updateMobileFeatureSettings(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
              toast('success', 'Your changes has been saved');
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
              toast('success', message);
            },
        );
  };
};

export const organizationHrInvitation = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.ORG_HR_INVITATION));
    ORGANIZATION.orgHrInvitation(params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.ORG_HR_INVITATION_SUCCESS, response.data.data));
        },
        (error) => {
          const message = error.response.data.data;
          dispatch(requestFailed(STATE.ORG_HR_INVITATION_FAILED, message));
        },
      );
  };
};

export const getListOrgInvitedByHr = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_ORG_LIST_INVITED_BY_HR));
    ORGANIZATION.getListOrgInvitedByHr(params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.FETCH_ORG_LIST_INVITED_BY_HR_SUCCESS, response.data.data));
        },
        (error) => {
          const response = error.response.data;
          dispatch(requestFailed(STATE.FETCH_LIST_ORGANIZATION_FAILED, response.data.message));
        },
      );
  };
};

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

export const clearMessageOdoo = () => {
  return (dispatch) => dispatch(resetMessageOdoo());
};

const request = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => (
  {
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
  }
);

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const fetchDetailFailed = (requestType, response) => ({
  type: requestType,
  requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

const resetMessageOdoo = () => ({
  type: STATE.CLEAR_MESSAGE_ODOO,
});

export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  data: [],
  qr: null,
  qrPageLink: null,
  autoRefresh: false,
  token: null,
  company: {
    name: null,
    address: null,
    logo: null,
  },
  isLoadingUploadBanner: false,
  isSuccessUploadBanner: false,
  banner: [],
  bannerLogin: [],
};

export const GENERAL_SETTING_STATE = {
  REQUEST: 'GENERAL_SETTING_REQUEST',
  REQUEST_FAILED: 'GENERAL_SETTING_REQUEST_FAILED',
  CLEAR_STATE: 'GENERAL_SETTING_CLEAR_STATE',
  FETCH_ALL: 'GENERAL_SETTING_FETCH_ALL',
  FETCH_ALL_SUCCCESS: 'GENERAL_SETTING_FETCH_ALL_SUCCCESS',
  FETCH_ALL_FAILED: 'GENERAL_SETTING_FETCH_ALL_FAILED',
  UPDATE: 'GENERAL_SETTING_UPDATE',
  UPDATE_SUCCESS: 'GENERAL_SETTING_UPDATE_SUCCESS',
  UPDATE_FAILED: 'GENERAL_SETTING_UPDATE_FAILED',
  PUBLISH: 'GENERAL_SETTING_PUBLISH',
  PUBLISH_SUCCESS: 'GENERAL_SETTING_PUBLISH_SUCCESS',
  PUBLISH_FAILED: 'GENERAL_SETTING_PUBLISH_FAILED',
  FETCH_QR_SETTINGS: 'GENERAL_SETTING_FETCH_QR_SETTINGS',
  FETCH_QR_SETTINGS_SUCCESS: 'GENERAL_SETTING_FETCH_QR_SETTINGS_SUCCESS',
  FETCH_QR_SETTINGS_FAILED: 'GENERAL_SETTING_FETCH_QR_SETTINGS_FAILED',
  GENERATE_QR: 'GENERAL_SETTING_GENERATE_QR',
  GENERATE_QR_SUCCESS: 'GENERAL_SETTING_GENERATE_QR_SUCCESS',
  GENERATE_QR_FAILED: 'GENERAL_SETTING_GENERATE_QR_FAILED',
  GENERATE_LINK: 'GENERAL_SETTING_GENERATE_LINK',
  GENERATE_LINK_SUCCESS: 'GENERAL_SETTING_GENERATE_LINK_SUCCESS',
  GENERATE_LINK_FAILED: 'GENERAL_SETTING_GENERATE_LINK_FAILED',
  UPDATE_AUTO_REFRESH: 'GENERAL_SETTING_UPDATE_AUTO_REFRESH',
  UPDATE_AUTO_REFRESH_SUCCESS: 'GENERAL_SETTING_UPDATE_AUTO_REFRESH_SUCCESS',
  UPDATE_AUTO_REFRESH_FAILED: 'GENERAL_SETTING_UPDATE_AUTO_REFRESH_FAILED',
  CHECK_TOKEN: 'GENERAL_SETTING_CHECK_TOKEN',
  CHECK_TOKEN_SUCCESS: 'GENERAL_SETTING_CHECK_TOKEN_SUCCESS',
  CHECK_TOKEN_FAILED: 'GENERAL_SETTING_CHECK_TOKEN_FAILED',
  POST_BANNER: 'GENERAL_SETTING_POST_BANNER',
  POST_BANNER_SUCCESS: 'GENERAL_SETTING_POST_BANNER_SUCCESS',
  POST_BANNER_FAILED: 'GENERAL_SETTING_POST_BANNER_FAILED',
  FETCH_BANNER: 'GENERAL_SETTING_FETCH_BANNER',
  FETCH_BANNER_SUCCESS: 'GENERAL_SETTING_FETCH_BANNER_SUCCESS',
  FETCH_BANNER_FAILED: 'GENERAL_SETTING_FETCH_BANNER_FAILED',
};

export default (theme) => ({
  navbar: {
    position: 'absolute',
    fontFamily: 'Poppins',
    background: 'transparent',
    color: 'black',
    boxShadow: 'none',
  },
  navToolbar: {
    padding: 15,
  },
  navLogo: {
    padding: '5px 0',
    cursor: 'pointer',
    width: 180,
  },
  btnNavContainer: {
    marginLeft: 'auto !important',
  },
  btnLogin: {
    'padding': '10px 30px',
    'textTransform': 'lowercase',
    'fontSize': 15,
    'letterSpacing': '1px',
    'transition': '0.3s all',
    'border': '1px solid #455A64',
    'color': '#455A64 !important',
    'marginRight': '0.5em',
    '&:hover': {
      background: '#fff',
      transform: 'scale(0.95)',
    },
  },
  btnRegister: {
    'backgroundColor': '#f5a742',
    'padding': '10px 30px',
    'textTransform': 'lowercase',
    'fontSize': 15,
    'letterSpacing': '1px',
    'transition': '0.3s all',
    'color': '#fff',
    '&:hover': {
      background: '#f5a742',
      transform: 'scale(0.95)',
    },
  },
  expansionPanel: {
    position: 'absolute',
    width: '100%',
    zIndex: 999,
    boxShadow: 'none',
  },
  expansionPanelSummary: {
    'boxShadow': 'none',
  },
  expansionPanelSummaryContent: {
    margin: '10px 0px',
  },
  expansionPanelDetails: {
    display: 'block',
    padding: '0px 20px 20px',
  },
  navLogoMobile: {
    marginLeft: 0,
    marginBottom: 12,
    width: 180,
  },
  menuButton: {
    color: 'rgba(0,0,0,.5)',
    borderColor: 'rgba(0,0,0,.1)',
    border: '1px #d2d0d0 solid',
    borderRadius: '5px',
    padding: '.25rem .50rem',
  },
});

/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {Button, IconButton, CircularProgress, Typography} from '@material-ui/core';
import {Add, Edit, Delete, Search} from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/SettingsApplications';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';

class Datatable extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      sortBy: null,
      order: 'desc',
      searchKeyword: null,
      resetPage: false,
      defOrder: null,
      defSortBy: null,
    };
    this.initialState = this.state;
  }

  getMuiTheme = () => createTheme({
    overrides: {
      MUIDataTableSearch: {
        main: {
          marginTop: 20,
        },
      },
      MUIDataTable: {
        responsiveBase: {
          marginTop: -4,
        },
      },
      MuiToolbar: {
        regular: {
          minHeight: 48,
        },
      },
      MuiPaper: {
        elevation4: {
          boxShadow: 'none',
          marginBottom: 0,
        },
        rounded: {
          borderRadius: 0,
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          borderTop: '1px solid rgba(224, 224, 224, 1)',
          borderLeft: '1px solid rgba(224, 224, 224, 1)',
          borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
      },
      MUIDataTableJumpToPage: {
        root: {
          color: '#656464',
        },
        select: {
          color: '#656464',
        },
      },
      MuiTablePagination: {
        caption: {
          color: '#656464',
        },
        input: {
          color: '#656464',
        },
      },
      MuiTypography: {
        h6: {
          color: '#656464',
          fontSize: '18px',
          fontWeight: '700',
          marginBottom: '0px',
          marginTop: '-8px',
          marginLeft: '-8px',
        },
        body2: {
          color: '#656464',
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '6px',
          paddingBottom: '6px',
        },
      },
      MuiIconButton: {
        root: {
          padding: '0px',
        },
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: '#333',
          color: '#656464',
          fontWeight: '500',
          fontSize: '13px',
          fontStyle: 'normal',
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
      },
      MuiButton: {
        containedSizeSmall: {
          paddingLeft: '4px',
          paddingRight: '4px',
          paddingTop: '3px',
          paddingBottom: '3px',
        },
        startIcon: {
          marginRight: '3px',
          marginLeft: '-1px',
        },
        iconSizeSmall: {
          marginLeft: '2px',
        },
        containedPrimary: {
          'color': '#FFF',
          'backgroundColor': '#00AFF0',
          'boxShadow': 'none',
          '&:hover': {
            backgroundColor: '#00A2DE',
            boxShadow: 'none',
          },
        },
        containedSecondary: {
          'color': '#FFF',
          'backgroundColor': '#E5AF5A',
          'boxShadow': 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#D09F53',
          },
        },
      },
      MUIDataTableBodyCell: {
        root: {
          color: '#656464',
          fontWeight: '400',
          fontSize: '13px',
          fontStyle: 'normal',
          paddingLeft: '8px',
          paddingRight: '6px',
          paddingTop: '5px',
          paddingBottom: '5px',
        },
      },
      MUIDataTableBodyRow: {
        root: {
          borderBottom: '1px solid black',
        },
      },
      MuiTableFooter: {
        root: {
          borderBottom: '2px solid rgba(0, 0, 0, 0)',
        },
      },
      MUIDataTableToolbar: {
        backgroundColor: '#333',
        color: '#656464',
        marginRight: '-8px',
        actions: {
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
      ...(!this.props.title && !this.props.searchable && !this.props.creatable && {
        MuiToolbar: {
          root: {
            display: 'none',
          },
        },
      }),
      ...(this.props.cellStyle && {
        MuiTableCell: this.props.cellStyle,
      }),
    },
  });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {toggleResetAll, toggleResetPage, defOrder, defSortBy} = nextProps;
    if (!this.props.toggleResetAll && toggleResetAll && this.props.toggleResetAll !== toggleResetAll) {
      this.handleReset();
    }

    if ((defOrder !== null && defOrder !== this.state.defOrder) || (defSortBy !== null && defSortBy !== this.state.defSortBy)) {
      this.setState({order: defOrder, defOrder: defOrder, sortBy: defSortBy, defSortBy: defSortBy});
    }

    if (toggleResetPage) this.setState({resetPage: true}); // Optional props to FORCE RESET page to 0
  }

  componentWillUnmount() {
    this.handleReset();
  }

  refreshDatatable = () => {
    // DEVNOTE: MUI datatable package pagination are started with 0, and API pagination are started with 1, please note!
    const {page, limit, sortBy, order} = this.state;
    this.props.handleReload({page, limit, sortBy, order});
  }

  handleReset = () => {
    this.setState(this.initialState);
  }

  handleSearch = (keyword) => {
    this.handleDebounceSearch(keyword);
  }

  handleDebounceSearch = _.debounce((keyword) => {
    this.props.handleSearch(keyword);
    this.setState({
      searchKeyword: keyword,
      page: 1,
      resetPage: true,
    }, () => this.setState({resetPage: null}));
  }, 400);

  handleSort = (params) => {
    const {sortBy, order} = this.state;
    let {changedColumn, direction} = params;

    if (changedColumn === sortBy) {
      if (order === 'asc') direction = 'desc';
      if (order === 'desc') direction = 'asc';
    } else {
      direction = 'asc';
    }

    this.setState(
        {page: 1, sortBy: changedColumn, order: direction, resetPage: true},
        () => this.refreshDatatable(), this.setState({resetPage: null}),
    );
  }

  renderColumns = () => {
    const {columns, handleEdit, handleDelete, handleDetail, customActions, hideActions, manualNumbering} = this.props;
    const {sortBy, order} = this.state;
    const indexColumn = {
      name: 'number',
      label: 'No',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const {page, limit} = this.state;
          return (tableMeta.rowIndex + ((page-1) * limit)) + 1;
        },
      },
    };

    const actionColumns = {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{display: 'flex', justifyContent: 'flex-end', position: 'relative', right: 14}}>
              {
                handleDetail ?
                (
                  <div style={{display: 'inline', marginRight: '5px'}}>
                    <Button
                      variant="contained"
                      size='small'
                      style={{
                        'backgroundColor': '#00AFF0',
                        'boxShadow': 'none',
                        'color': '#fff',
                        '&:hover': {
                          boxShadow: 'none',
                          backgroundColor: '#00A2DE',
                        },
                        'minWidth': '10px',
                        'paddingRight': '0px',
                      }}
                      startIcon={<Search style={{width: 14, height: 16}}/>}
                      onClick={() => handleDetail(tableMeta)}
                    >
                    </Button>
                  </div>
                ) : ''
              }
              {
                handleEdit ?
                (
                  <div style={{display: 'inline', marginRight: '5px'}}>
                    <Button
                      variant="contained"
                      color="primary"
                      size='small'
                      onClick={() => handleEdit(tableMeta.rowData)}
                      startIcon={<Edit />}
                      style={{
                        'minWidth': '10px',
                        'paddingRight': '0px',
                      }}
                    >
                    </Button>
                  </div>
                ) : ''
              }
              {
                handleDelete ?
                (
                  <div style={{display: 'inline', marginRight: '5px'}}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size='small'
                      onClick={() => handleDelete(tableMeta.rowData)}
                      startIcon={<Delete />}
                      style={{
                        'minWidth': '10px',
                        'paddingRight': '0px',
                      }}
                    >
                    </Button>
                  </div>
                ) : ''
              }
              {customActions ? customActions(tableMeta.rowData) : ''}
            </div>
          );
        },
      },
    };

    const dataColumns = columns.map((value) => {
      const customBodyRender = () => {
        return value.customBodyRender ? value.customBodyRender : undefined;
      };

      return {
        name: value.name,
        label: value.label,
        options: {
          filter: value.name === 'no' ? false : true,
          sort: value.name === 'no' ? false : value.sort === false ? value.sort : true,
          display: value.display,
          sortOrder: value.name === sortBy ? order : 'none',
          customBodyRender: customBodyRender(),
        },
      };
    });

    return [
      ...(manualNumbering !== true ? [indexColumn] : []),
      ...dataColumns,
      ...(hideActions !== true ? [actionColumns] : []),
    ];
  }

  render() {
    const {searchKeyword} = this.state;
    const {title, loading, dataSource, creatable, total, shift, download, searchable, handleCreate, paging, customTools} = this.props;

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <MUIDataTable
          title={title}
          data={loading ? [] : dataSource}
          columns={this.renderColumns()}
          key={title}
          options={{
            filterType: 'dropdown',
            responsive: 'standard',
            search: searchable === true ? true : false,
            print: false,
            download: download === true? true: false,
            serverSide: true,
            filter: false,
            sortFilterList: true,
            count: total,
            pagination: paging === false ? false : true,
            selectableRows: 'none',
            viewColumns: false,
            jumpToPage: true,
            rowsPerPageOptions: [10, 25, 50, 100],
            textLabels: {
              body: {
                noMatch:
                  loading ?
                  (
                    <>
                      <CircularProgress key={title} color="inherit" size={24} style={{marginRight: 10, top: 7, position: 'relative'}}/>
                      Loading...
                    </>
                  ) : 'Sorry, no matching records found',
              },
              toolbar: {
                search: 'Search',
              },
            },
            customToolbar: () => {
              return (
                <div style={{overflow: 'hidden'}}>
                  {
                    creatable === false ? undefined : (
                      <div style={{float: 'right'}}>
                        <IconButton
                          onClick={() => handleCreate()}
                          disabled={loading ? true : false}
                          >
                          {shift ? <SettingsIcon /> : <Add /> }
                        </IconButton>
                      </div>
                    )
                  }
                  {
                    !customTools ? undefined : (
                      customTools
                    )
                  }
                </div>
              );
            },
            searchText: searchKeyword,
            searchPlaceholder: 'Enter search keyword',
            onSearchChange: (keyword) => {
              this.handleSearch(keyword);
            },
            onChangePage: (currentPage) => {
              this.setState({page: currentPage + 1}, () => this.refreshDatatable());
            },
            onChangeRowsPerPage: (numberOfRows) => {
              this.setState({page: 1, limit: numberOfRows}, () => this.refreshDatatable());
            },
            onColumnSortChange: (changedColumn, direction) => {
              this.handleSort({changedColumn, direction});
            },
            onTableChange: (action, tableState) => {
              switch (action) {
                case 'propsUpdate':
                  // Special handler, to force-reset tableState to page 0 when receiving search params from external
                  // eg: (attendance history page)
                  if (this.state.resetPage) {
                    tableState.page = 0;
                    this.setState({resetPage: false});
                  }
                  break;
                default:
                  break;
              }
            },
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default Datatable;

import {ENDPOINTS, apiService} from '../config';

export const postRegister = (payload, params) => {
  return apiService.post(
      ENDPOINTS.REGISTER_USER.CREATE,
      payload,
      {params},
  );
};

export const postApproval = (params) => {
  return apiService.post(
      ENDPOINTS._USER.APPROVAL,
      params,
  );
};

export const postApprovalUser = (params) => {
  return apiService.post(
      ENDPOINTS.REGISTER_USER.APPROVAL_USER,
      params,
  );
};

export const getRegisterAnalytics = (params) => {
  return apiService.get(
      ENDPOINTS.REGISTER_USER.ANALYTICS,
      {params},
  );
};

export const getRegisterAnalyticsDetails = (params) => {
  return apiService.get(
      ENDPOINTS.REGISTER_USER.ANALYTICS_DETAILS,
      {params},
  );
};

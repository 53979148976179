/* eslint-disable max-len */
import {DIVISIONMANAGEMENT} from '../../services';
import {DIVISION_STATE as STATE} from './divisionManagement.types';
import {transformObject, isArray} from '../../helpers';

export const getDivision = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    DIVISIONMANAGEMENT.getDivision(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getDivisionByOrg = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_BY_ORG));
    DIVISIONMANAGEMENT.getDivisionByOrg(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_BY_ORG_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_BY_ORG_FAILED, response.data.message));
            },
        );
  };
};

export const getApprovalDivision = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_ON_APPROVAL));
    DIVISIONMANAGEMENT.getApprovalDivision(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_ON_APPROVAL_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_ON_APPROVAL_FAILED, response.data.message));
            },
        );
  };
};

export const createDivision = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE));
    DIVISIONMANAGEMENT.postDivision(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CREATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CREATE_FAILED, message));
            },
        );
  };
};

export const updateDivision = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    DIVISIONMANAGEMENT.updateDivision(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const deleteDivision = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.DROP));
    DIVISIONMANAGEMENT.dropDivision(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DROP_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = errors ? errors[0].value : error.response.data.data.message;
              dispatch(requestFailed(STATE.DROP_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

// DISPATCH

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

/* eslint-disable max-len */
import {PERMIT} from '../../services';
import {APPROVAL_STATE as STATE} from './approvalPermit.types';

export const getApprovalPermitByToken = (params) => {
  return (dispatch) => {
    dispatch(fetchApprovalPermitHistory());
    PERMIT.getPermitHistoryByToken(params).then((response) => {
      const data = response.data;
      if (!data.error) {
          dispatch(fetchApprovalPermitSuccess(data));
        } else {
        dispatch(fetchApprovalPermitFailed(data));
      }
    }).catch((error) => {
      const message = error?.response?.data?.data?.errors && error?.response?.data?.data?.errors[0]?.value ? error?.response?.data?.data?.errors[0]?.value : 'Something went wrong';
      dispatch(fetchApprovalPermitFailed({message}));
    });
  };
};

export const confirmApprovalPermit = (params) => {
  return (dispatch) => {
    dispatch(submitApprovalPermit());
    PERMIT.confirmApprovalPermit(params).then((response) => {
      const data = response.data;
      if (!data.error) {
        dispatch(submitApprovalPermitSuccess(data));
      } else {
        dispatch(submitApprovalPermitFailed(data));
      }
    }).catch((error) => {
      const message = error?.response?.data?.data?.errors && error?.response?.data?.data?.errors[0]?.value ? error?.response?.data?.data?.errors[0]?.value : 'Something went wrong';
      dispatch(submitApprovalPermitFailed({message}));
    });
  };
};

export const resetLoader = () => ({
  type: STATE.RESET_LOADER,
});

const fetchApprovalPermitHistory = () => ({
  type: STATE.GET_APPROVAL_PERMIT_BY_TOKEN,
});

const fetchApprovalPermitSuccess = (data) => ({
  type: STATE.GET_APPROVAL_PERMIT_BY_TOKEN_SUCCESS,
  data,
});

const fetchApprovalPermitFailed = (data) => ({
  type: STATE.GET_APPROVAL_PERMIT_BY_TOKEN_FAILURE,
  data,
});

const submitApprovalPermit = () => ({
  type: STATE.SUBMIT_APPROVAL_PERMIT,
});

const submitApprovalPermitSuccess = (data) => ({
  type: STATE.SUBMIT_APPROVAL_PERMIT_SUCCESS,
  data,
});

const submitApprovalPermitFailed = (data) => ({
  type: STATE.SUBMIT_APPROVAL_PERMIT_FAILURE,
  data,
});

/* eslint-disable max-len */
import {ENDPOINTS, apiService} from '../config';

export const getPermitHistory = (params) => {
  return apiService.get(ENDPOINTS.PERMIT.HISTORY, {
    params,
  });
};

export const putPermitOvertime = (params, id) => {
  return apiService.put(ENDPOINTS.PERMIT.CHANGE_STATUS + id, params);
};

export const getPermitHistoryByToken = (token) => {
  return apiService.get(ENDPOINTS.APPROVAL.PERMIT_DETAIL + token);
};

export const confirmApprovalPermit = (params) => {
  return apiService.put(ENDPOINTS.APPROVAL.PERMIT_CONFIRM + params.status + '/' + params.token, {
    rejected_reason: params.rejected_reason,
  });
};

export const exportExcel = (params) => {
  return apiService.post(
      ENDPOINTS.PERMIT.EXPORT,
      params,
  );
};

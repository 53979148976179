export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  companyTypeData: [],
  companyTypeMeta: {page: 1, total: 0},
  message: {
    name: '',
    description: '',
    job_titles: '',
  },
};

export const COMPANY_TYPE_STATE = {
  REQUEST: 'COMPANY_TYPE_REQUEST',
  REQUEST_FAILED: 'COMPANY_TYPE_REQUEST_FAILED',
  CLEAR_STATE: 'COMPANY_TYPE_CLEAR_STATE',
  FETCH: 'COMPANY_TYPE_FETCH',
  FETCH_SUCCCESS: 'COMPANY_TYPE_FETCH_SUCCCESS',
  FETCH_FAILED: 'COMPANY_TYPE_FETCH_FAILED',
  CREATE: 'COMPANY_TYPE_CREATE',
  CREATE_FAILED: 'COMPANY_TYPE_CREATE_FAILED',
  CREATE_SUCCESS: 'COMPANY_TYPE_CREATE_SUCCESS',
  UPDATE: 'COMPANY_TYPE_UPDATE',
  UPDATE_SUCCESS: 'COMPANY_TYPE_UPDATE_SUCCESS',
  UPDATE_FAILED: 'COMPANY_TYPE_UPDATE_FAILED',
  DROP: 'COMPANY_TYPE_DROP',
  DROP_SUCCESS: 'COMPANY_TYPE_DROP_SUCCESS',
  DROP_FAILED: 'COMPANY_TYPE_DROP_FAILED',
};

/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {checkSuperadmin} from '../../helpers';
import AdminSetting from './superAdmin';
import HRSetting from './hrAdmin';

class GeneralSetting extends Component {
  render() {
    const {isSuperAdmin} = this.props;
    return (
      <div>
        {/* general setting divided into two access for easier development if the number of settings increased */}
        {
          isSuperAdmin ?
          <AdminSetting/> :
          <HRSetting/>
        }
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => ({
  isSuperAdmin: checkSuperadmin(auth),
});

export default connect(mapStateToProps)(GeneralSetting);


/* eslint-disable max-len */
import {ENDPOINTS, apiService} from '../config';
export const getReimburseHistory = (params) => {
  return apiService.get(ENDPOINTS.REIMBURSE.HISTORY, {
    params,
  });
};

export const exportExcel = (params) => {
  return apiService.post(
      ENDPOINTS.REIMBURSE.EXPORT,
      params,
  );
};

export const putReimburseStatus = (id, params) => {
  return apiService.put(
      ENDPOINTS.REIMBURSE.CHANGE_STATUS + id,
      params,
  );
};

export const getReimburseHistoryByToken = (token) => {
  return apiService.get(ENDPOINTS.APPROVAL.REIMBURSE_DETAIL + token);
};

export const confirmApprovalReimburse = (params) => {
  return apiService.put(ENDPOINTS.APPROVAL.REIMBURSE_CONFIRM + params.status + '/' + params.token, {
    rejected_reason: params.rejected_reason,
  });
};

/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, MuiThemeProvider} from '@material-ui/core/styles';
import styles from './styles';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  AppBar,
  Tabs,
  Tab,
  createTheme,
  Button,
  Tooltip,
} from '@material-ui/core';
import {ContactSupportOutlined, Delete, Send} from '@material-ui/icons';
import {checkPremium} from '../../../redux/upgradeAccount/upgradeAccount.actions';
import {USER_STATE} from '../../../redux/userManagement/userManagement.types';
import {SHIFT_MANAGEMENT_STATE} from '../../../redux/shiftManagement/shiftManagement.types';
import {GENERAL_SETTING_STATE} from '../../../redux/generalSetting/generalSetting.types';
import {
  isObject,
  toast,
  showPopup,
  checkSuperadmin,
  isArray,
} from '../../../helpers';
import {
  MButton,
  ModalTitle,
  SelectMultiple,
} from '../../../components/form';
import {ShiftDatatable} from '../../../components';
import {getJobTitleByOrg} from '../../../redux/jobTitle/jobTitle.actions';
import {
  getAllUser,
  getAllRole,
  postUser,
  dropUser,
  updateUser,
  clearState,
  clearLoaderState,
  getUserByShift,
} from '../../../redux/userManagement/userManagement.actions';
import {
  getShiftManagement,
  updateShiftManagement,
} from '../../../redux/shiftManagement/shiftManagement.actions';
import {checkStatus as checkStatusUser} from '../../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../../redux/organization/organization.actions';
import {
  getGeneralSetting,
  updateGeneralSetting,
} from './../../../redux/generalSetting/generalSetting.actions';
import _ from 'lodash';

class UserManagement extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      formData: {
        id: null,
        name: null,
        email: null,
        nik: null,
        dob: null,
        phone: null,
        address: null,
        password: null,
        user_img: null,
        imageURL: null,
        role_id: '',
        job_title_id: '',
      },
      formDataUpdate: [],
      employeeList: [],
      modalFormShow: false,
      showUpgradeModal: false,
      nameFilter: null,
      nikFilter: null,
      searchFilter: null,
      searchData: null,
      errorMessage: null,
      tabIndex: 0,
      activePanel: 1,
      shifts: [],
      userDataSource: [],
      userGet: [],
      updateUserId: [],
    };
    this.resetForm = this.state.formData;
  }
  getModalTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiChip: {
        root: {
          backgroundColor: '#00AFF0',
        },
        label: {
          color: '#FFFF',
        },
        deleteIcon: {
          color: '#FFFFFF',
        },
      },
      MuiButton: {
        contained: {
          boxShadow: 'none',
        },
      },
    },
  })
  getTableTheme = () => createTheme({
    palette: {
      default: {
        main: '#F00B38',
      },
      primary: {
        main: '#00AFF0',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#F00B38',
      },
      regular: {
        main: '#0198D0',
      },
    },
    overrides: {
      MuiToolbar: {
        regular: {
          marginTop: '40px',
        },
      },
      MuiPaper: {
        elevation4: {
          paddingTop: 22,
        },
      },
      MuiIconButton: {
        root: {
          marginRight: 12,
        },
      },
      MuiButtonBase: {
        root: {
          height: 35,
        },
      },
    },
  })
  getMuiTheme = () => createTheme({
    overrides: {
      MuiToolbar: {
        regular: {
          marginTop: '12px',
          marginBotton: '14px',
        },
      },
      MuiPaper: {
        elevation4: {
          paddingTop: 22,
        },
      },
      MuiAppBar: {
        colorPrimary: {
          color: '#FFF',
          backgroundColor: '#00AFF0',
          position: 'relative',
          top: '18px',
          marginTop: '-9px',
          boxShadow: 'none',
          padding: '0px 5px 0px 6px',
        },
        colorSecondary: {
          backgroundColor: '#FFF',
        },
      },
      MuiIconButton: {
        label: {
          marginRight: '0px !important',
        },
      },
      MuiTabs: {
        indicator: {
          color: 'white',
          backgroundColor: 'white',
          height: '4px',
          borderRadius: '2px',
          marginBottom: '0.5px',
        },
      },
    },
  });

  UNSAFE_componentWillMount() {
    // this.props.getAllRole();
    // this.props.getJobTitleByOrg();
    this.props.checkStatusUser();
    // this.props.getGeneralSetting({key: 'check_in'});
    this.props.getShiftManagement({
      shift: 1,
    });

    const {userData, generalSettingData, isLoading} = this.props;
    this.setState({userDataSource: userData});
    const userNik = [];
    userData.map((data, i) => {
      userNik.push(data.id.toString());
    });
    this.setState({
      userGet: userNik,
      updateUserId: userNik,
    });
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      trigger,
      isSuccess,
      isError,
      message,
      triggerShiftManagement,
      isShiftManagementError,
      isShiftManagementSuccess,
      messageShiftManagement,
      generalSettingData,
      generalSettingIsError,
      generalSettingIsSuccess,
      generalSettingMessage,
      generalSettingTrigger,
      userDataByShift,
    } = nextProps;

    if (isSuccess) {
      if (trigger === USER_STATE.CREATE_SUCCESS) {
        this.handleReset();
        this.fetchUser();
        toast('success', message);
      }

      if (trigger === USER_STATE.DROP_SUCCESS) {
        this.toggleResetPage();
        this.fetchUser();
        toast('success', message);
      }

      if (trigger === USER_STATE.UPDATE_SUCCESS) {
        this.handleReset();
        setTimeout(() => {
          this.fetchUser(this.state.activePanel);
        }, 500);
        toast('success', message);
      }
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }

    if (isShiftManagementError) {
      if (messageShiftManagement && messageShiftManagement.employee_id) toast('error', messageShiftManagement.employee_id);
      if (messageShiftManagement && messageShiftManagement.shift_id) toast('error', messageShiftManagement.shift_id);
    }

    if (isShiftManagementSuccess) {
      if (triggerShiftManagement === SHIFT_MANAGEMENT_STATE.FETCH_ALL_SUCCESS) {
        // let shiftList;
        // for (let i = 0; i < generalSettingData.length; i++) {
        //   if (generalSettingData[i].key === 'check_in') {
        //     shiftList = generalSettingData[i].value;
        //   }
        // }
        // this.setState({
        //   shifts: shiftList,
        // });
      }

      if (triggerShiftManagement === SHIFT_MANAGEMENT_STATE.UPDATE_SUCCESS) {
        this.toggleResetPage();
        toast('success', messageShiftManagement);
      }
    }

    console.log(generalSettingIsSuccess);
    console.log(generalSettingTrigger);
    console.log(generalSettingData);

    if (generalSettingIsSuccess) {
      if (generalSettingTrigger === GENERAL_SETTING_STATE.FETCH_ALL_SUCCCESS) {
        const userNik = [];
        userDataByShift.map((data, i) => {
          userNik.push(data.id.toString());
        });
        this.setState({
          userGet: userNik,
        });
      }
    }

    if (generalSettingIsSuccess) {
      if (generalSettingTrigger === GENERAL_SETTING_STATE.FETCH_ALL_SUCCCESS) {
        if (isArray(generalSettingData) && generalSettingData.length > 0) {
          generalSettingData.map((data) => {
            if (data.key === 'check_in') {
                console.log(data.value);
                return this.setState({
                  shifts: data.value,
                });
            }
          });
        } else {
          return this.setState({
            shifts: generalSettingData.value,
          });
        }

        // let shiftList;
        // for (let i = 0; i < generalSettingData.length; i++) {
        //   if (generalSettingData[i].key === 'check_in') {
        //     shiftList = generalSettingData[i].value;
        //   }
        // }
        // this.setState({
        //   shifts: shiftList,
        // });
      }
    }
  }

  componentDidMount() {
    const {userDataByShift} = this.props;
    const {activePanel} = this.state;
    this.props.getGeneralSetting({key: 'check_in'});
    this.handleTabChange(activePanel);
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  fetchUser = (id = this.state.activePanel) => {
    let idShift; let queryParams;
    const {isSuperAdmin, userDataByShift} = this.props;
    const {page, limit, searchFilter} = this.state;
    if (id) {
      idShift = id;
      queryParams = {
        ...(page && {page}),
        ...(limit && {limit}),
        ...(searchFilter && {query: searchFilter}),
        shift: idShift,
      };
    } else {
      queryParams = {
        ...(page && {page}),
        ...(limit && {limit}),
        ...(searchFilter && {query: searchFilter}),
      };
    }
    if (!isSuperAdmin) this.props.checkPremium();
    this.props.getShiftManagement({
      shift: this.state.tabIndex + 1,
    });
    this.props.getUserByShift(queryParams);
  };

  fetchAllUser = () => {
    const {isSuperAdmin, userData} = this.props;
    const {page, limit, searchData} = this.state;
    const queryParams = {
      page: 1,
      limit: 10,
      ...(searchData && {query: searchData}),
    };
    if (!isSuperAdmin) this.props.checkPremium();
    this.props.getAllUser(queryParams);
  };

  handleReload = (params) => {
    this.setState(params, () => this.fetchUser());
  };

  handleReset = () => {
    this.setState({
      formData: {
        id: null,
        name: null,
        email: null,
        nik: null,
        dob: null,
        phone: null,
        address: null,
        password: null,
        user_img: null,
        imageURL: null,
        role_id: '',
        job_title_id: '',
      },
      formDataUpdate: [],
      modalFormShow: false,
      errorMessage: null,
    });
  };

  handleFormToggle = () => {
    const {dataShiftManagement} = this.props;
    this.setState({
      modalFormShow: !this.state.modalFormShow,
      employeeList: !this.state.modalFormShow ? dataShiftManagement : [],
      formDataUpdate: !this.state.modalFormShow ? this.state.employeeList : [],
    }, () => this.state.modalFormShow ? this.fetchAllUser() : this.fetchUser());
  };

  handleConfirmDelete = (data) => {
    showPopup(
        'confirm',
        'Are you sure you want to delete this data ?',
        'Yes',
        () => this.props.dropUser(data[1]),
    );
  };

  handleSearch = (keyword) => {
    this.toggleResetPage();
    this.setState({searchFilter: keyword, page: null}, () =>
      setTimeout(() => {
        this.fetchUser(this.state.activePanel);
      }, 500),
    );
  };

  handleChange = (event, newValue) => {
    this.toggleResetPage();
    this.setState({tabIndex: newValue});
  };

  handleTabChange = (id) => {
    this.toggleResetPage();
    const {isLoadingShift} = this.props;
    this.setState({activePanel: id});
    if (!isLoadingShift) {
      setTimeout(() => {
        this.fetchUser(id);
      }, 500);
    }
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleModalSearch = (data, event) => {
    const keyword = event && event.target && event.target.value;
    const {userDataByShift} = this.props;
    const {userDataSource, userGet} = this.state;
    const valueArr = [];
    const userFilter = userDataByShift;
    let idUser;
    if (data && data.length > 0 && keyword && keyword.length > 0) {
      this.setState({searchData: keyword, page: null}, () =>
        this.handleEventSearchAllUser(),
      );

      const filterData = userDataByShift.filter((value) => {
        return value['employees.name'].toLowerCase().indexOf(keyword.trim()) > -1;
      });

      this.setState({userDataSource: filterData.filter((value) => !userGet.includes(value.id))});
    } else {
      this.setState({searchData: null}, () => this.handleEventSearchAllUser());
    }
  };

  handleEventSearchAllUser = _.debounce(() => {
    this.fetchAllUser();
  }, 500)

  handleModalChange = (value, id) => {
    this.setState({
      userGet: value,
      updateUserId: value,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const {message, userDataByShift} = this.props;
    const {activePanel, userGet, updateUserId} = this.state;
    const params = {
      'shift_id': activePanel,
      'user_id': updateUserId.length === 0 ? userGet : updateUserId,
    };
    this.props.updateShiftManagement(params);
    this.setState({modalFormShow: false});
    setTimeout(() => {
      this.fetchUser(activePanel);
    }, 500);
  }

  renderTabs = () => {
    const {shifts} = this.state;
    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <AppBar position="static">
          <Tabs theme value={this.state.tabIndex} variant="scrollable" onChange={this.handleChange} aria-label="Shifts" scrollButtons="auto" allowScrollButtonsMobile>
            {
              shifts && shifts.length > 0 ?
              shifts.map((data, index) => {
                return (
                  <Tab
                    key={index.toString()}
                    label={data.label + ' ( ' + data.start_time + ' - ' + data.end_time + ' )'}
                    onClick={() => this.handleTabChange(data.id)}
                  />
                );
              }) : null
            }
          </Tabs>
        </AppBar>
      </MuiThemeProvider>

    );
  }

  renderModalForm = () => {
    try {
      const titleDialog = 'Manage Employee';
      const {classes, userDataByShift, userData, isLoading, isLoadingShift, dataShiftManagement} = this.props;
      const {formDataUpdate, modalFormShow, userDataSource, userGet} = this.state;
      const newUserData = [];
      dataShiftManagement.map((val, index) => {
        newUserData.push({id: val.id, label: val.employees.name});
      });

      return (
        <MuiThemeProvider theme={this.getModalTheme()}>
          <Dialog
            open={modalFormShow}
            onClose={this.handleFormToggle}
          >
            <ModalTitle
              title={titleDialog}
              onClose={this.handleFormToggle}
            />
            <form
              onSubmit={this.handleSubmit}
              autoComplete="off"
            >
              <DialogContent className={classes.dialogContent}>
                <input type="hidden" value="no-autocomplete" />
                <SelectMultiple
                  className={classes.formControl}
                  label="Select one or more employees for this shift"
                  name="input_employees"
                  placeholder="Type to search employee by name"
                  keyPair={['id', 'employees.name']}
                  data={userData.filter((value) => (value['employees.shift'] === null || value['employees.shift'] === this.state.activePanel))}
                  loading={isLoading}
                  value={newUserData}
                  style={{width: 500}}
                  onInputChange={(event) => this.handleModalSearch(userData, event)}
                  onChange={(value, id) => this.handleModalChange(value, id)}
                />
              </DialogContent>
              <DialogActions className={classes.dialogFooter}>
                <MButton className={classes.cancelBtn}
                  label="Cancel"
                  onClick={this.handleFormToggle}
                />
                <MButton
                  className={classes.defaultBtn}
                  label="Save"
                  type="submit"
                  icon={<Send />}
                  loading={isLoading ? true : false}
                />
              </DialogActions>
            </form>
          </Dialog>
        </MuiThemeProvider>
      );
    } catch (error) {
      console.log(error);
    }
  };

  renderTableUser = (selectedPanel) => {
    const {classes, isLoading, isLoadingShift, userDataByShift, userMeta, isSuperAdmin} = this.props;
    const {userGet, resetPage} = this.state;
    const displayJobTitle = isSuperAdmin ? false : true;
    const columns = [
      {name: 'no', label: 'No', display: true},
      {name: 'id', label: 'id', display: false},
      {name: 'employees.name', label: 'Name', display: true},
      {name: 'employees.employee_no', label: 'NIK', display: true},
      {name: 'employees.job_titles.name', label: 'Job Type', display: displayJobTitle},
      {name: 'employees.email', label: 'Email', display: true},
      {name: 'employees.dob', label: 'Date of Birth', display: false},
      {name: 'employees.phone', label: 'Phone', display: false},
      // {name: 'roles.name', label: 'Role', display: true},
    ];

    return (
      <Grid
        container
        spacing={3}
        role="tabpanel"
      >
        <Grid item xs={12} style={{paddingTop: 18}}>
          <MuiThemeProvider theme={this.getTableTheme()}>
            <ShiftDatatable
              shift={true}
              title={'Employee List'}
              loading={isLoadingShift}
              dataSource={userDataByShift}
              total={userMeta.total}
              page={userMeta.page}
              columns={columns}
              download={true}
              searchable
              customActions={(data) => this.renderActions(data)}
              handleReload={(params) => this.handleReload(params)}
              handleCreate={this.handleFormToggle}
              handleSearch={(params) => this.handleSearch(params)}
              manualNumbering={true}
              toggleResetPage={resetPage}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    );
  }

  handleConfirmDelete = (data) => {
    const {activePanel, userGet} = this.state;

    const filtered = [];
    for (let i=0; i < userGet.length; i++) {
      if (userGet[i] !== data[1].toString()) {
        filtered.push(userGet[i]);
      }
    }

    const params = {
      'shift_id': activePanel,
      'user_id': filtered,
    };

    showPopup(
        'confirm',
        'Are you sure you want to remove this user ?',
        'Yes',
        () => this.handleDeleteUserShift(params),
    );
  };

  handleDeleteUserShift= (data) => {
    const {activePanel} = this.state;
    this.props.updateShiftManagement(data);
    setTimeout(() => {
      this.fetchUser(activePanel);
    }, 500);
  }

  renderActions = (params) => {
    return (
      <Grid container wrap='nowrap' direction='row'>
        <Grid item>
          <Tooltip title="Delete">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color="secondary"
                size='small'
                onClick={() => this.handleConfirmDelete(params)}
                startIcon={<Delete />}
                style={{
                  minWidth: 10,
                  paddingRight: 0,
                }}
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {classes, isSuperAdmin} = this.props;
    const displayJobTitle = isSuperAdmin ? false : true;
    return (
      <div className={[classes.root, classes.tabBar]}>
        {this.renderTabs()}
        {this.renderTableUser(this.state.activePanel)}
        {this.renderModalForm()}
      </div>
    );
  }
}

const stateToProps = ({userManagement, jobTitle, upgradeAccount, auth, organization, shiftManagement, generalSetting}) => ({
  token: auth.data.authorization,
  isSuperAdmin: checkSuperadmin(auth),
  isLoading: userManagement.isLoading,
  isLoadingShift: userManagement.isLoadingShift,
  isError: userManagement.isError,
  isSuccess: userManagement.isSuccess,
  message: userManagement.message,
  trigger: userManagement.trigger,
  premium: upgradeAccount.premium,
  creatable: upgradeAccount.creatable,
  creatableMessage: upgradeAccount.creatableMessage,
  userData: userManagement.userData,
  userDataByShift: userManagement.userDataByShift,
  userMeta: userManagement.userMeta,
  roleLoading: userManagement.roleLoading,
  roleData: userManagement.roleData,
  jobLoading: jobTitle.jobLoading,
  jobData: jobTitle.jobDataByOrg,
  orgStatusData: organization.orgStatusData,
  isShiftManagementLoading: shiftManagement.isShiftManagementLoading,
  isShiftManagementError: shiftManagement.isShiftManagementError,
  isShiftManagementSuccess: shiftManagement.isShiftManagementSuccess,
  messageShiftManagement: shiftManagement.messageShiftManagement,
  triggerShiftManagement: shiftManagement.triggerShiftManagement,
  dataShiftManagement: shiftManagement.dataShiftManagement,
  generalSettingData: generalSetting.data,
  generalSettingIsLoading: generalSetting.isLoading,
  generalSettingIsSuccess: generalSetting.isSuccess,
  generalSettingIsError: generalSetting.isError,
  generalSettingMessage: generalSetting.message,
  generalSettingTrigger: generalSetting.trigger,
});

const dispatchToProps = (dispatch) => ({
  getShiftManagement: (params) => dispatch(getShiftManagement(params)),
  updateShiftManagement: (params) => dispatch(updateShiftManagement(params)),
  clearState: () => dispatch(clearState()),
  clearLoaderState: () => dispatch(clearLoaderState()),
  getAllUser: (params) => dispatch(getAllUser(params)),
  getAllRole: () => dispatch(getAllRole()),
  createUser: (data) => dispatch(postUser(data)),
  updateUser: (id, data) => dispatch(updateUser(id, data)),
  dropUser: (id) => dispatch(dropUser(id)),
  checkPremium: () => dispatch(checkPremium()),
  getJobTitleByOrg: (params) => dispatch(getJobTitleByOrg(params)),
  checkStatus: () => dispatch(checkStatus()),
  getGeneralSetting: (key) => dispatch(getGeneralSetting(key)),
  getUserByShift: (params) => dispatch(getUserByShift(params)),
  checkStatusUser: () => dispatch(checkStatusUser()),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(UserManagement));

import React, {useEffect, useState, useCallback, useRef} from 'react';
import {
    CircularProgress,
    TextField,
    FormControl,
    FormHelperText,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import {useDispatch, useSelector} from 'react-redux';
import {getListLocationEvent} from '../../../../../redux/eventManagement/eventManagement.actions';
import Paper from '@mui/material/Paper';
import debounce from 'lodash.debounce';
import './styles.css';

export default function SelectedLocation({
    initialOptions = [],
    handleChange,
    city,
    province,
    error,
    clearError,
    disabled}) {
    const dispatch = useDispatch();
    const {locationDataEvent} = useSelector((state) => state.eventManagement);

    const [options, setOptions] = useState(initialOptions);
    const [selectedOption, setSelectedOption] = useState(null);
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({
        page: 1,
        pagination: true,
        keyword: '', // Keyword for searching
    });

    const paperRef = useRef(null); // Reference to Paper component for scroll control
    const scrollPosition = useRef(0); // Keeps track of scroll position

    const fetchMoreOptions = useCallback(async (param) => {
        setLoading(true);
        const filteredParams = Object.fromEntries(
            Object.entries(param).filter(([_, value]) => value !== ''),
        );
        try {
            await dispatch(getListLocationEvent(filteredParams));
        } catch (error) {
            console.error('Error fetching locations:', error);
        } finally {
            setLoading(false);
        }
    }, [dispatch]); // Now stable due to useCallback

    useEffect(() => {
        fetchMoreOptions(params);
    }, [params, fetchMoreOptions]);

    useEffect(() => {
        if (locationDataEvent.areas) {
            setOptions((prev) => [...prev, ...locationDataEvent.areas]);

            // Restore previous scroll position after loading more data
            if (paperRef.current) {
                paperRef.current.scrollTop = scrollPosition.current;
            }
        }
    }, [locationDataEvent]);

    useEffect(() => {
        if (city && province) {
            setSelectedOption({
                city_name: city,
                province_name: province,
            });
        }
    }, [city, province]);

    const handleScroll = (event) => {
        const target = event.currentTarget;
        const bottom = target.scrollHeight === target.scrollTop + target.clientHeight;

        // Update the scroll position ref to retain it on re-render
        scrollPosition.current = target.scrollTop;

        if (bottom && !loading) {
            setParams((prev) => ({
                ...prev,
                page: prev.page + 1, // Increment page
            }));
        }
    };

    const handleInputChange = useCallback(
        debounce((event, newValue) => {
            setParams({
                ...params,
                keyword: newValue, // Set the keyword to the input value
                page: 1, // Reset page to 1 if keyword changes
            });
            setOptions([]); // Clear previous options to avoid mixing
            fetchMoreOptions({...params, keyword: newValue});
        }, 300),
        [params],
    );

    const handleSelectionChange = (event, value) => {
        setSelectedOption(value);
        handleChange(value);
        if (value) {
            clearError('province');
        }
    };


    return (
        <FormControl fullWidth variant="outlined" error={error}>
            <Autocomplete
                options={options}
                value={selectedOption} // Set the selected value
                getOptionLabel={(option) => `${option.city_name}, ${option.province_name}`}
                onInputChange={handleInputChange}
                name="location *"
                onChange={handleSelectionChange}
                disablePortal
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Select Location *"
                        className="location-field"
                        fullWidth
                        disabled={disabled}
                        error={error}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
                        }}
                        slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                        }}
                        InputLabelProps={{shrink: true}}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} key={`${option.city_name}-${option.province_name}`}>
                        {`${option.city_name}, ${option.province_name}`}
                    </li>
                )}
                PaperComponent={(props) => (
                    <Paper
                        {...props}
                        ref={paperRef} // Attach ref to Paper component
                        onScroll={handleScroll}
                        style={{maxHeight: 253, overflowY: 'auto'}}
                    >
                        {props.children}
                    </Paper>
                )}
                classes={{
                    listbox: 'custom-listbox',
                }}
            />
            {error && <FormHelperText>Location is a required field</FormHelperText>}
        </FormControl>
    );
}


/* eslint-disable max-len */
import {EXPORT_ACTIVITY} from '../../services';
import {EXPORT_ACTIVITY_STATE as STATE} from './exportActivity.types';

export const getExportActivity = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    EXPORT_ACTIVITY.getExportActivity(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const updateExportNotification = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_EXPORT_NOTIF));
    dispatch(requestSuccess(STATE.UPDATE_EXPORT_NOTIF_SUCCESS, params));
  };
};

export const updateExportNotificationStatus = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_EXPORT_NOTIF));
    dispatch(requestSuccess(STATE.UPDATE_EXPORT_NOTIF_STATUS, params));
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

// DISPATCH

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

import {ENDPOINTS, apiService} from '../config';

export const postRegister = (params) => {
  return apiService.post(
      ENDPOINTS.REGISTER.CREATE,
      params,
  );
};

export const registerSSO = (params) => {
  return apiService.post(
      ENDPOINTS.REGISTER.REGISTER_SSO,
      params,
  );
};

export const postApproval = (params) => {
  return apiService.post(
      ENDPOINTS.REGISTER.APPROVAL,
      params,
  );
};
export const ssoApproval = (params) => {
  return apiService.post(
      ENDPOINTS.REGISTER.APPROVAL_SSO,
      params,
  );
};

export const postApprovalUser = (params) => {
  return apiService.post(
      ENDPOINTS.REGISTER.APPROVAL_USER,
      params,
  );
};

export const getRegisterAnalytics = (params) => {
  return apiService.get(
      ENDPOINTS.REGISTER.ANALYTICS,
      {params},
  );
};

export const getRegisterAnalyticsDetails = (params) => {
  return apiService.get(
      ENDPOINTS.REGISTER.ANALYTICS_DETAILS,
      {params},
  );
};

/* eslint-disable react/display-name */
import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  IconButton,
  withStyles,
  Button,
  Paper,
  Tooltip,
} from '@material-ui/core';
import {convDate} from '../../../helpers';
import {useHistory} from 'react-router-dom';
import {Search, Cancel, CheckCircle} from '@material-ui/icons';
import {
  MButton,
  MCheckboxItem,
  MInput,
  MKeyBoardDatepicker,
  MSelect,
} from '../../../components/form';
import {ReactComponent as ResetIcon} from '../../../assets/icon/event-management/reset.svg';
import {showPopup} from '../../../helpers';
import styles from './styles';
import {Datatable} from '../../../components';
import {useDispatch, useSelector} from 'react-redux';
import {
  blockEvent,
  cancelApprovalEvent,
  getEvents,
  togglePopupBlockEvent,
  togglePopupCancelApproval,
} from '../../../redux/eventManagement/eventManagement.actions';
import BlockEventPopup from './blockEventPopup';
import CancelApprovalPopup from './cancelApprovalPopup';

const eventTypeFilterOptions = [
  {id: 'private', name: 'Private'},
  {id: 'public', name: 'Public'},
  {id: 'all', name: 'All'},
];

const initialFilter = {
  pagination: true,
  page: 1,
  limit: 10,
  keyword: null,
  is_cancel_request: null,
  type: null,
  start_date: null,
  end_date: null,
};

function EventList({classes}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filter, setFilter] = useState({...initialFilter});

  const {
    isLoading,
    events,
    showPopupBlockEvent,
    showPopupCancelApproval,
  } = useSelector((state) => state.eventManagement);

  const [approvalState, setApprovalState] = useState({
    id: null,
    cancelId: null,
  });

  const [blockEventId, setBlockEventId] = useState();

  const toggleBlockPopup = (id = null) => {
    dispatch(togglePopupBlockEvent());
    setBlockEventId(typeof id === 'string' ? id : null);
  };

  const toggleApprovalPopup = (id = null, rowData = null) => {
    let cancelId = null;
    const history = rowData && rowData[9];

    if (id && history?.length) {
      const _findHistory = history.find((item) => item?.type.toLowerCase() === 'canceled' && !item?.approval_reason);

      if (_findHistory) cancelId = _findHistory?._id;
    }

    dispatch(togglePopupCancelApproval());

    setApprovalState((prevState) => ({
      ...prevState,
      id: typeof id === 'string' ? id : null,
      cancelId,
    }));
  };

  const handleFilterChange = (e) => {
    const {name, value} = e?.target || e;

    setFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchList = useCallback((params = initialFilter) => {
    const newParams = {
      ...initialFilter,
      ...params,
      limit: params?.limit !== initialFilter?.limit ? params?.limit : initialFilter?.limit,
    };

    if (params?.sortBy) {
      delete newParams.sortBy;
      newParams.order = params.sortBy;
      newParams.sort = params.order?.toUpperCase();
    }

    setFilter({...newParams});
    dispatch(getEvents(newParams));
  }, [dispatch]);

  const handleResetFilter = () => {
    if (
      !filter?.keyword && !filter?.type &&
      !filter?.end_date && !filter?.start_date &&
      !filter?.is_cancel_request
    ) return;

    const newFilter = {
      ...filter,
      ...initialFilter,
      page: filter?.page || initialFilter?.page,
      limit: filter?.limit || initialFilter?.limit,
    };

    fetchList(newFilter);
    setFilter({...newFilter});
  };

  const handleDateChange = (name, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [name]: convDate(value, 'YYYY-MM-DD'),
    }));
  };

  const renderFilter = () => {
    return (
      <Paper variant="outlined" className={classes.paper}>
        <Box className={classes.searchAndFilter}>
          <MInput
            autoFocus
            classNameFC={`${classes.filter} ${classes.searchInput}`}
            fullWidth={false}
            variant="standard"
            name="keyword"
            label="Search"
            placeholder="Search keyword"
            InputProps={{
              endAdornment: (
                <IconButton className={classes.searchIcon} position="end">
                  <Search />
                </IconButton>
              ),
            }}
            value={filter?.keyword || ''}
            onChange={handleFilterChange}
          />

          <MCheckboxItem
            classNameFC={classes.filterCheckbox}
            label={'Cancel Requests'}
            onChange={(e, checked) => setFilter((prevState) => ({...prevState, is_cancel_request: checked}))}
            checked={filter?.is_cancel_request}
          />

          <MSelect
            shrink={true}
            fullWidth={false}
            classNameFC={classes.filterType}
            variant="standard"
            name="type"
            label="Type"
            keyPair={['id', 'name']}
            value={filter?.type}
            onChange={handleFilterChange}
            options={eventTypeFilterOptions}
          />

          <Box className={classes.dateInput}>
            <MKeyBoardDatepicker
              label="Start Date"
              value={filter?.start_date}
              onChange={(value) => handleDateChange('start_date', value)}
            />
          </Box>

          <Box sx={{marginLeft: '12px'}} className={classes.dateInput}>
            <MKeyBoardDatepicker
              label="End Date"
              value={filter?.end_date}
              onChange={(value) => handleDateChange('end_date', value)}
            />
          </Box>
        </Box>

        <Box className={classes.btnActionWrapper}>
          <MButton
            className={classes.btnAction}
            label={'Apply'}
            onClick={() => fetchList(filter)}
          />

          <MButton
            className={classes.btnAction}
            label={'Reset'}
            icon={<ResetIcon />}
            onClick={handleResetFilter}
          />
        </Box>
      </Paper>
    );
  };

  const handleDetail = (id) => {
    history.push('/event-management/detail', {id});
  };

  const handleApproval = (type, reason) => {
    if (!approvalState?.id || !type || !reason || !approvalState?.cancelId) return;

    let label = 'Are you sure want to approve the cancellation for this event?';

    if (type === 'reject') {
      label = 'Are you sure want to reject the cancellation for this event?';
    }

    showPopup('confirm', label, 'Yes', () => {
      dispatch(cancelApprovalEvent(approvalState?.id, {
        id: approvalState?.cancelId,
        is_approve: type === 'approve',
        reason,
      }, filter));
    });
  };

  const handleBlockEvent = (reason) => {
    if (!reason) return;

    showPopup('confirm', 'Are you sure want to block this event', 'Yes', () => {
      dispatch(blockEvent(blockEventId, {reason}, filter));
    });
  };

  const renderActions = (rowData) => {
    const id = rowData[1];
    const status = rowData[8];

    return (
      <>
        <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
          <Tooltip title="Detail">
            <Button
              variant="contained"
              size="small"
              style={{
                'backgroundColor': '#00AFF0',
                'boxShadow': 'none',
                'color': '#fff',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: '#00A2DE',
                },
                'minWidth': '10px',
                'paddingRight': '0px',
              }}
              startIcon={<Search />}
              onClick={() => handleDetail(rowData[1])}
            />
          </Tooltip>

        {status !== 'on_review' ? null :
          <Tooltip title="View Cancel Request">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => toggleApprovalPopup(id, rowData)}
              startIcon={<CheckCircle />}
              style={{
                backgroundColor: '#008000',
                minWidth: '10px',
                paddingRight: '0px',
              }}
            />
          </Tooltip>}

          {status !== 'active' ? null : <Tooltip title={`Block`}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => toggleBlockPopup(id)}
              startIcon={<Cancel />}
              style={{
                'backgroundColor': '#f44336',
                'boxShadow': 'none',
                'color': '#fff',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: '#ba000d',
                },
                'minWidth': '10px',
                'paddingRight': '0px',
              }}
            />
          </Tooltip>}
        </div>
      </>
    );
  };

  const columns = [
    {name: 'id', display: false},
    {name: 'title', label: 'Event Name', display: true},
    {name: 'organization_name', label: 'Organization Name', display: true},
    {
      name: 'date_start',
      label: 'Start Date',
      display: true,
      customBodyRender: (value) => {
        return value ? (
          <Button
            variant="outlined"
            size="small"
            style={{whiteSpace: 'nowrap'}}
          >
            {convDate(value, 'DD-MM-YYYY')}
          </Button>
        ) : (
          '-'
        );
      },
    },
    {
      name: 'date_end',
      label: 'End Date',
      display: true,
      customBodyRender: (value) => {
        return value ? (
          <Button
            variant="outlined"
            size="small"
            style={{whiteSpace: 'nowrap'}}
          >
            {convDate(value, 'DD-MM-YYYY')}
          </Button>
        ) : (
          '-'
        );
      },
    },
    {
      name: 'type',
      label: 'Type',
      display: true,
      customBodyRender: (value) => {
        return <span style={{textTransform: 'capitalize'}}>{value}</span>;
      },
    },
    {
      name: 'payment_type',
      label: 'Payment',
      display: true,
      customBodyRender: (value) => {
        return value === 'free' ? (
          <Box
            sx={{
              backgroundColor: '#008000',
              padding: '2px 0px',
              color: '#FFF',
              borderRadius: '20px',
              textAlign: 'center',
              width: '80px',
              textTransform: 'capitalize',
            }}
          >
            {value}
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#00AFF0',
              padding: '2px 0px',
              color: '#FFF',
              borderRadius: '20px',
              textAlign: 'center',
              width: '80px',
              textTransform: 'capitalize',
            }}
          >
            {value}
          </Box>
        );
      },
    },
    {
      name: 'status',
      label: 'Status',
      display: true,
      customBodyRender: (value) => {
        return value === 'active' ? (
          <span
            style={{
              color: '#008000',
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}
          >
            published
          </span>
        ) : value === 'inactive' || value === 'on_review' ? (
          <span
            style={{
              color: '#E5AF5A',
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}
          >
            {value === 'inactive' ? 'draft' : 'Cancel Requested'}
          </span>
        ) : value === 'ended' ? (
          <span
            style={{
              color: '#777777',
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}
          >
            {value?.replace('_', ' ')}
          </span>
        ) : (
          <span
            style={{
              color: '#F22951',
              fontWeight: 'bold',
              textTransform: 'capitalize',
            }}
          >
            {value?.replace('_', ' ')}
          </span>
        );
      },
    },
    {name: 'history', display: false},
  ];

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <>
      {renderFilter()}

      <BlockEventPopup
        open={showPopupBlockEvent}
        toggle={toggleBlockPopup}
        handleSubmit={handleBlockEvent}
      />

      <CancelApprovalPopup
        open={showPopupCancelApproval}
        toggle={toggleApprovalPopup}
        handleSubmit={handleApproval}
      />

      <Datatable
        title={'Event List'}
        creatable={false}
        loading={isLoading}
        dataSource={events?.events || []}
        total={events?.paginator?.itemCount}
        page={events?.paginator?.page}
        columns={columns}
        customActions={(data) => renderActions(data)}
        handleReload={(params) => fetchList({...filter, ...params})}
      />
    </>
  );
}

export default withStyles(styles)(EventList);

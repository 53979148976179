/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {
  withStyles,
} from '@material-ui/core';
import styles from './styles';
import PagiiLogo from '../../assets/img/pagii-simple-attendance-solution-logo.png';
import SmooetsLogo from '../../assets/img/main-logo-smooets-horizontal-sm.png';

class PrintQRPage extends Component {
  render() {
    const {classes, companyDetail, qrURL} = this.props;
    return (
      <div>
        <div className={classes.printPage}>
          <div className="print-title">
            <h1 className={classes.printTitle}>Working Office Attendance QR</h1>
          </div>
          <div className="company-info">
            <div className={`row ${classes.printCompanyContainer}`}>
              <div className="">
                <img
                  className={`company-logo ${classes.printLogo}`}
                  src={companyDetail.logo}
                  alt="company-logo"
                />
              </div>
              <div className="">
                <div className="info-group">
                  <label className={`company-label ${classes.printCompanyName}`}>{companyDetail.name}</label>
                </div>
                <div className="info-group">
                  <label className={`company-label ${classes.printCompanyAddress}`}>{companyDetail.address}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="print-qr">
            <img
              className={classes.printQRImg}
              src={qrURL}
              onLoad={() => this.setState({initialLoading: false})}
              alt="img"
            />
            <h3 className={classes.printSubTitle}>Scan Me for Checkin / Checkout</h3>
          </div>
          <div className={classes.printFooter}>
            <div><img src={PagiiLogo} className={classes.pagiiLogo} alt="img"/></div>
            <h5 className={classes.printFooterText}>Powered By:</h5>
            <div><img src={SmooetsLogo} className={classes.smooetsLogo} alt="img"/></div>
          </div>
        </div>
      </div>
    );
  }
}

export default (withStyles(styles)(PrintQRPage));


export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  detailLoading: false,
  detailError: false,
  detailSuccess: false,
  detailData: '',
  exportLoading: false,
  exportData: {file: null},
  trigger: null,
  message: null,
  fraudData: [],
  fraudMeta: {page: 1, total: 0},
  isLoadingPost: false,
  isSuccessPost: false,
  isErrorPost: false,
  messagePost: '',
  status: null,
  is_edited: null,
};

export const FRAUD_STATE = {
  REQUEST: 'FRAUD_REQUEST',
  REQUEST_FAILED: 'FRAUD_REQUEST_FAILED',
  FETCH: 'FRAUD_FETCH',
  FETCH_SUCCESS: 'FRAUD_FETCH_SUCCESS',
  FETCH_FAILED: 'FRAUD_FETCH_FAILED',
  FETCH_DETAIL: 'FETCH_DETAIL',
  FETCH_DETAIL_SUCCESS: 'FETCH_DETAIL_SUCCESS',
  FETCH_DETAIL_FAILED: 'FETCH_DETAIL_FAILED',
  EXPORT: 'FRAUD_EXPORT',
  EXPORT_SUCCESS: 'FRAUD_EXPORT_SUCCESS',
  EXPORT_FAILED: 'FRAUD_EXPORT_FAILED',
  CHANGE_STATUS_FRAUD: 'CHANGE_STATUS_FRAUD',
  CHANGE_STATUS_FRAUD_SUCCESS: 'CHANGE_STATUS_FRAUD_SUCCESS',
  CHANGE_STATUS_FRAUD_FAILURE: 'CHANGE_STATUS_FRAUD_FAILURE',
};

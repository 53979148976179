import {ENDPOINTS, apiService} from '../config';

export const getRole = (params) => {
  return apiService.get(
      ENDPOINTS.ROLE.ALL,
      {params},
  );
};

export const postRole = (params) => {
  return apiService.post(
      ENDPOINTS.ROLE.ADD,
      params,
  );
};

export const updateRole = (id, params) => {
  return apiService.put(
      ENDPOINTS.ROLE.UPDATE + id,
      params,
  );
};

export const dropRole = (id) => {
  return apiService.delete(
      ENDPOINTS.ROLE.DELETE + id,
  );
};



/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import styles from './styles';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Button,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Switch,
} from '@material-ui/core';
import {
  Search,
  Refresh,
  Send,
  Edit,
  Delete,
  Sync,
} from '@material-ui/icons';
import {toast, convDate, checkSuperadmin, showPopup, isObject} from '../../helpers';
import {
  MInput,
  MButton,
  ModalTitle,
  MAutoComplete,
  MSelect,
  MInputMask,
} from '../../components/form';
import {Datatable} from '../../components';
import {checkStatus} from '../../redux/organization/organization.actions';
import {
  getSSOWhitelist,
  getSSODetail,
  createSSOWhitelist,
  deleteSSOWhitelist,
  updateSSOWhitelist,
  toggleSSOWhitelist,
  regenerateSSOWhitelist,
  clearState,
} from '../../redux/ssoWhitelist/ssoWhitelist.actions';
import {SSO_WHITELIST_STATE as STATE} from '../../redux/ssoWhitelist/ssoWhitelist.types';

import _ from 'lodash';

class SSOWhitelist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      modalFormShow: false,
      detailFormShow: false,
      query: null,
      status: 'all',
      showDetail: false,
      resetDatatable: null,
      detailData: null,
      resetPage: null,
      selected: null,
      inputValue: null,
      errorMessage: null,
      order: 'desc',
      sortBy: null,
      isEdit: false,

      formData: {
        vendor: null,
        name: null,
        phone: null,
        email: null,
        domain: null,
        callback_url: null,
        billing_id: null,
        payment_callback_url: null,
      },
      detailSSO: {
        vendor: null,
        name: null,
        phone: null,
        email: null,
        appID: null,
        secret_key: null,
        domain: null,
        callback_url: null,
        billing_id: null,
        payment_callback_url: null,
      },
    };
  }

  switchTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  });


  UNSAFE_componentWillMount() {
    this.redirectDashboardIfChild();
    this.fetchWhitelist();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message} = nextProps;

    if (isSuccess) {
      if (
        trigger === STATE.CREATE_SUCCESS ||
        trigger === STATE.UPDATE_SUCCESS ||
        trigger === STATE.DROP_SUCCESS ||
        trigger === STATE.TOGGLE_SUCCESS
      ) {
        this.handleResetFilter();
        this.setState({
          modalFormShow: false,
        });
        toast('success', message);
      }

      if (isSuccess === false) {
        if (
          trigger === STATE.TOGGLE_FAILED
        ) {
          // console.log(message);
          toast('success', message);
          // toast('success', message);
        }
      }

      if (trigger === STATE.FETCH_BY_ID_SUCCCESS) {
        const {partnerDataById} = nextProps;
        this.handleReset();
        this.setState({
          isEdit: true,
          modalFormShow: !this.state.modalFormShow,
          formData: {
            id: partnerDataById?.id,
            partner_name: partnerDataById?.partner_name,
            email: partnerDataById?.email,
            bank_name: partnerDataById?.bank_name,
            number_phone: partnerDataById?.number_phone,
            bank_account_number: partnerDataById?.bank_account_number,
            bank_account_name: partnerDataById?.bank_account_name,
            address: partnerDataById?.address,
            partner_fee: partnerDataById?.partner_fee,
            callback_url: partnerDataById?.callback_url,
            billing_id: partnerDataById?.billing_id,
            payment_callback_url: partnerDataById?.payment_callback_url,
          },
        });
      }
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }


  handleResetForm = (closeModal) => {
    this.setState({
      formData: {
        id: null,
        partner_name: null,
        pic_name: null,
        phone_number: null,
        email: null,
        domain: null,
        payment_callback_url: null,
      },
      errorMessage: null,
    });
    if (closeModal) this.setState({showModal: false});
  };


  redirectDashboardIfChild = () => {
    const {isSuperAdmin, auth} = this.props;

    if (!isSuperAdmin) {
      if (auth.data.organization.is_child) {
        this.props.history.push('/dashboard');
      } else {
        // if(!userFeature.tenant_management){
        //   this.props.history.push('/dashboard');
        // }
      }
    }
  };

  fetchWhitelist = () => {
    const {isSuperAdmin} = this.props;

    if (isSuperAdmin) {
      const {
        page,
        limit,
        query,
        status,
        order,
        sortBy,
      } = this.state;
      this.props.getSSOWhitelist({
        ...(page && {page}),
        ...(limit && {limit}),
        ...(query && {query}),
        ...(status && status !== 'all' && {status}),
        ...(order && {order}),
        ...(sortBy && {sort_by: sortBy}),
      });
    }
  }

  handleReload = (params) => {
    this.setState(params, () => this.fetchWhitelist());
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null}, () => this.fetchWhitelist());
    this.toggleResetPage();
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  };

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: 1}, () => this.fetchWhitelist());
  }, 1000);


  handleResetFilter = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      status: 'all',
      order: 'desc',
      sortBy: null,
    };
    this.setState(state, () => this.fetchWhitelist());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  };

  renderFilter = () => {
    const {classes, message} = this.props;
    const {query, status} = this.state;

    const renderMethodOptions = () => {
      const options = [
        {id: 'all', name: 'All'},
        {id: 'active', name: 'Active'},
        {id: 'inactive', name: 'Inactive'},
      ];
      return options;
    };

    return (
      <Paper variant='outlined' className={classes.paper}>
        <Grid container xs={12} spacing={2}>
          <MInput
            autoFocus
            className={`${classes.filter} ${classes.searchInput}`}
            fullWidth={false}
            variant="outlined"
            name="query"
            label="Search"
            placeholder="Enter search keyword"
            value={query}
            onChange={this.handleSearch}
            InputProps={{
              endAdornment: (
                <IconButton className={classes.searchIcon} position="end">
                  {' '}
                  <Search />
                </IconButton>
              ),
            }}
          />
          <MSelect
            shrink={true}
            fullWidth={false}
            className={`${classes.filter} ${classes.selectInput}`}
            variant="outlined"
            name="status"
            label="Status"
            keyPair={['id', 'name']}
            options={renderMethodOptions()}
            value={status}
            onChange={this.handleFilterChange}
          />
          <div className={classes.btnFilterContainer}>
            <MButton
              className={classes.defaultBtn}
              label="Reset"
              icon={<Refresh />}
              onClick={this.handleResetFilter}
            />
          </div>
        </Grid>
      </Paper>
    );
  };

  handleConfirmRegenerate = (data) => {
    showPopup(
        'confirm',
        'Are you sure you want to regenerate appId and Secret Key on this SSO Whitelist ?',
        'Yes',
        () => this.props.regenerateSSOWhitelist(data[1]),
    );
  };

  handleConfirmDelete = (data) => {
    if (data[10] === 'active') {
      showPopup(
          'confirm',
          'This whitelist is currently active. Are you sure you want to delete this data??',
          'Yes',
          () => this.props.deleteSSOWhitelist(data[1]),
      );
    } else {
      showPopup(
          'confirm',
          'Are you sure you want to delete this data?',
          'Yes',
          () => this.props.deleteSSOWhitelist(data[1]),
      );
    }
  };

  handleActivateSSO = (data) => {
    const params = {
      status: true,
    };
    showPopup(
        'confirm',
        'Are you sure want to activate this SSO Whitelist?',
        'Yes',
        () => this.props.toggleSSOWhitelist(data[1], params),
    );
  };

  handleDeactivateSSO = (data) => {
    const params = {
      status: false,
    };
    showPopup(
        'confirm',
        'Are you sure want to deactivate this SSO Whitelist? if it’s deactivated, the partner cannot authenticate into the system.',
        'Yes',
        () => this.props.toggleSSOWhitelist(data[1], params),
    );
  };

  renderActions = (params) => {
    return (
      <Grid container wrap='nowrap' direction='row'>
        <Grid item>
          <Tooltip title="Detail">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color="primary"
                size='small'
                onClick={() => this.handleDetailToggle('open', params)}
                startIcon={<Search />}
                style={{
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Edit">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color="primary"
                size='small'
                onClick={() => {
                  // this.props.get(params[1]);
                  this.handleEditDialogToggle(params);
                }}
                startIcon={<Edit />}
                style={{
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Regenerate App ID and Secret Key">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color="primary"
                size='small'
                onClick={() => {
                  this.handleConfirmRegenerate(params);
                }}
                startIcon={<Sync />}
                style={{
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Delete">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color="secondary"
                size='small'
                onClick={() => this.handleConfirmDelete(params)}
                startIcon={<Delete />}
                style={{
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}

              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <MuiThemeProvider theme={this.switchTheme()}>
            <Tooltip title={this.state.accountActivation ? 'Deactivate Account' : 'Activate Account'}>
              <Switch
                size='small'
                color='primary'
                checked={params[10] === 'active' ? true : false}
                onChange={() => params[10] === 'active' ? this.handleDeactivateSSO(params) : this.handleActivateSSO(params) }
                inputProps={{'aria-label': 'controlled'}}
              />
            </Tooltip>
          </MuiThemeProvider>
        </Grid>
      </Grid>
    );
  };

  handleFormToggle = (type) => {
    if (type === 'add') {
      this.handleReset();
      this.setState({
        isEdit: false,
        modalFormShow: !this.state.modalFormShow,
      });
    } else {
      this.handleReset();
      this.setState({
        modalFormShow: !this.state.modalFormShow,
      });
    }
  };

  handleDetailToggle = (type, params) => {
    if (type === 'open') {
      this.setState({
        detailFormShow: !this.state.detailFormShow,
        detailSSO: {
          vendor: params[2],
          name: params[3],
          phone: params[4],
          email: params[5],
          appID: params[6],
          secret_key: params[7],
          domain: params[8],
          callback_url: params[9],
          billing_id: params[11],
          payment_callback_url: params[12],
        },
      });
    } else {
      this.setState({
        detailFormShow: !this.state.detailFormShow,
      });
    }
  };

  handleEditDialogToggle = (data) => {
    this.setState({
      modalFormShow: !this.state.modalFormShow,
      isEdit: true,
      formData: {
        id: data[1],
        vendor: data[2],
        name: data[3],
        phone: data[4],
        email: data[5],
        domain: data[8],
        callback_url: data[9],
        billing_id: data[11],
        payment_callback_url: data[12],
      },
    });
  };

  handleReset = () => {
    this.setState({
      formData: {
        id: null,
        partner_name: null,
        pic_name: null,
        phone_number: null,
        email: null,
        domain: null,
        callback_url: null,
        billing_id: null,
        payment_callback_url: null,
      },
      modalImportShow: false,
      isEdit: false,
      errorMessage: null,
    });
    // this.toggleResetPage()
  };

  handleInputChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      formData: {...this.state.formData, [name]: value ? value : null},
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {isEdit} = this.state;
    const {id} = this.state.formData;
    const {
      vendor,
      name,
      phone,
      email,
      domain,
      callback_url,
      billing_id,
      payment_callback_url,
    } = this.state.formData;

    const {createSSOWhitelist, updateSSOWhitelist} = this.props;
    const params = {
      ...(vendor && {vendor}),
      ...(name && {name}),
      ...(phone && {phone}),
      ...(email && {email}),
      ...(domain && {domain}),
      ...(callback_url && {callback_url}),
      ...(billing_id?.toString() && {billing_id}),
      ...(payment_callback_url && {payment_callback_url}),
    };

    // console.log(params);
    return !isEdit ? createSSOWhitelist(params) : updateSSOWhitelist(id, params);
  }

  renderModalForm = () => {
    const {
      classes,
      isLoading,
    } = this.props;
    const {
      isEdit,
      modalFormShow,
      errorMessage,
    } = this.state;
    const {
      vendor,
      name,
      phone,
      email,
      domain,
      callback_url,
      billing_id,
      payment_callback_url,
    } = this.state.formData;

    const titleDialog = isEdit ? 'Update SSO' : 'Create SSO';
    return (
      <Dialog
        open={modalFormShow}
        onClose={() => this.handleFormToggle('close')}
      >
        <ModalTitle
          title={titleDialog}
          onClose={() => this.handleFormToggle('close')}
        />
        <form onSubmit={this.handleSubmit}>
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MInput
                  fullWidth
                  classNameFC={classes.formControl}
                  name="vendor"
                  label="Partner/Vendor *"
                  placeholder="Enter partner/vendor"
                  defaultValue={vendor}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.vendor ?
                      errorMessage.vendor :
                      undefined
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  name="name"
                  label="PIC Name *"
                  placeholder="Enter pic name"
                  defaultValue={name}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.name ?
                      errorMessage.name :
                      undefined
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <MInputMask
                  classNameFC={classes.formControl}
                  name="phone"
                  label="Phone Number *"
                  placeholder="Phone"
                  type="number"
                  defaultValue={phone}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.phone ?
                      errorMessage.phone.split(':')[0] :
                      undefined
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  name="email"
                  label="Email *"
                  placeholder="Enter email"
                  type="email"
                  defaultValue={email}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.email ?
                      errorMessage.email :
                      undefined
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  name="domain"
                  label="Domain *"
                  placeholder="Enter domain"
                  helper= "Example: yourwebsite.com"
                  defaultValue={domain}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.domain ?
                      errorMessage.domain :
                      undefined
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  name="callback_url"
                  label="Callback URL *"
                  placeholder="Enter callback url"
                  helper= "Example: yourwebsite.com/callback"
                  defaultValue={callback_url}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.callback_url ?
                      errorMessage.callback_url :
                      undefined
                  }
                  validate={false}
                />
              </Grid>
              <Grid item xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  name="billing_id"
                  label="Billing ID"
                  placeholder="Enter Billing ID"
                  type="number"
                  defaultValue={billing_id}
                  onBlur={this.handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  name="payment_callback_url"
                  label="Payment Callback URL"
                  placeholder="Enter Payment Callback URL"
                  defaultValue={payment_callback_url}
                  onBlur={this.handleInputChange}
                  validate={false}
                  error={
                    errorMessage && errorMessage.payment_callback_url ?
                      errorMessage.payment_callback_url :
                      undefined
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.handleFormToggle('close')}
            />
            <MButton
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              loading={isLoading ? true : false}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  renderDetailForm = () => {
    const {
      classes,
    } = this.props;
    const {
      detailFormShow,
    } = this.state;
    const {
      vendor,
      name,
      phone,
      email,
      appID,
      secret_key,
      domain,
      callback_url,
      billing_id,
      payment_callback_url,
    } = this.state.detailSSO;

    const titleDialog ='SSO Detail';
    return (
      <Dialog
        open={detailFormShow}
        onClose={() => this.handleDetailToggle('close')}
      >
        <ModalTitle
          style={{display: 'flex', justifyContent: 'center'}}
          title={titleDialog}
          onClose={() => this.handleDetailToggle('close')}
        />
        <DialogContent className={classes.dialogDetailContent}>
          <Grid container spacing={2}>
            <Grid item xs={4} >
              <Typography>Partner / Vendor</Typography>
            </Grid>
            <Grid item>
              <Typography>&nbsp;:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{vendor}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} >
              <Typography>PIC Name</Typography>
            </Grid>
            <Grid item>
              <Typography>&nbsp;:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{name}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} >
              <Typography>Phone Number</Typography>
            </Grid>
            <Grid item>
              <Typography>&nbsp;:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{phone}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} >
              <Typography>Email</Typography>
            </Grid>
            <Grid item>
              <Typography>&nbsp;:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{email}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} >
              <Typography>App ID</Typography>
            </Grid>
            <Grid item>
              <Typography>&nbsp;:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{appID}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} >
              <Typography>Secret Key</Typography>
            </Grid>
            <Grid item>
              <Typography>&nbsp;:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{secret_key}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} >
              <Typography>Domain</Typography>
            </Grid>
            <Grid item>
              <Typography>&nbsp;:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{domain}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} >
              <Typography>Callback URL</Typography>
            </Grid>
            <Grid item>
              <Typography>&nbsp;:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{callback_url ? callback_url : '-'}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} >
              <Typography>Billing ID</Typography>
            </Grid>
            <Grid item>
              <Typography>&nbsp;:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{billing_id ? billing_id : '-'}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} >
              <Typography>Payment Callback URL</Typography>
            </Grid>
            <Grid item>
              <Typography>&nbsp;:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{payment_callback_url ? payment_callback_url : '-'}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <MButton
            className={classes.cancelBtn}
            label="Close"
            onClick={() => this.handleDetailToggle('close')}
          />
        </DialogActions>
      </Dialog>
    );
  };


  render() {
    const {
      classes,
      isLoading,
      ssoWhitelistData,
      ssoWhitelistMeta,
    } = this.props;
    const {resetDatatable, resetPage} =
      this.state;

    const columns = [
      {
        name: 'id', // params[1]
        label: 'id',
        display: false,
      },
      {
        name: 'vendor', // params[2]
        label: 'Partner/Vendor',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'name', // params[3]
        label: 'PIC Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),

      },
      {
        name: 'phone', // params[4]
        label: 'Phone Number',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),

      },
      {
        name: 'email', // params[5]
        label: 'Email',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),

      },
      {
        name: 'appId', // params[6]
        label: 'APP ID',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'secret_key', // params[7]
        label: 'Secret Key',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'domain', // params[8]
        label: 'Domain',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'callback_url', // params[9]
        label: 'Callback Url',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'status', // params[10]
        label: 'Status',
        display: true,
        customBodyRender: (value) => (value && value === 'active' ? 'Active' : 'Inactive'),
      },
      {
        name: 'billing_id', // params[11]
        label: 'BillingID',
        display: false,
      },
      {
        name: 'payment_callback_url', // params[8]
        label: 'Payment Callback URL',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Datatable
          title={'SSO List'}
          loading={isLoading}
          dataSource={ssoWhitelistData}
          total={ssoWhitelistMeta ? ssoWhitelistMeta.total : 0}
          page={ssoWhitelistMeta ? ssoWhitelistMeta.page : 1}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleCreate={() => this.handleFormToggle('add')}
          customActions={(params) => this.renderActions(params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          // manualNumbering={true}
        />
        {this.renderModalForm()}
        {this.renderDetailForm()}
      </div>
    );
  }
}

const mapStateToProps = ({auth, ssoWhitelist}) => {
  return {
    auth: auth,
    isSuperAdmin: checkSuperadmin(auth),
    isLoading: ssoWhitelist.isLoading,
    isError: ssoWhitelist.isError,
    isSuccess: ssoWhitelist.isSuccess,
    message: ssoWhitelist.message,
    trigger: ssoWhitelist.trigger,
    ssoWhitelistData: ssoWhitelist.ssoWhitelistData,
    ssoWhitelistDataById: ssoWhitelist.ssoWhitelistDataById,
    ssoWhitelistMeta: ssoWhitelist.ssoWhitelistMeta,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkStatus: () => dispatch(checkStatus()),
  getSSOWhitelist: (params) => dispatch(getSSOWhitelist(params)),
  getSSODetail: (id, params) => dispatch(getSSODetail(id, params)),
  createSSOWhitelist: (params) => dispatch(createSSOWhitelist(params)),
  regenerateSSOWhitelist: (id, params) => dispatch(regenerateSSOWhitelist(id, params)),
  updateSSOWhitelist: (id, params) => dispatch(updateSSOWhitelist(id, params)),
  deleteSSOWhitelist: (id) => dispatch(deleteSSOWhitelist(id)),
  toggleSSOWhitelist: (id, params) => dispatch(toggleSSOWhitelist(id, params)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(SSOWhitelist));

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {AppContainer} from '../components';
import {withRouter} from 'react-router-dom';
import ReactGA from 'react-ga';

const trackPageView = (page) => {
  ReactGA.set({
    page,
  });
  ReactGA.pageview(page);
};

class Private extends Component {
  componentDidMount() {
    const page = this.props.location.pathname + this.props.location.search;
    trackPageView(page);
  }

  componentDidUpdate(prevProps) {
    const currentPage = prevProps.location.pathname + prevProps.location.search;
    const page = this.props.location.pathname + this.props.location.search;
    if (currentPage !== page) {
      window.scrollTo(0, 0);
      if (!page.includes('/preview')) trackPageView(page);
    }
  }

  render() {
    return (
      <AppContainer>
        {this.props.children}
      </AppContainer>
    );
  }
}

const mapStateToProps = ({auth}) => ({
  token: auth.data.authorization,
});

export default compose(
    connect(mapStateToProps), withRouter,
)(Private);

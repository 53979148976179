/* eslint-disable padded-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  CardMedia,
  CircularProgress,
} from '@material-ui/core';
import styles from './styles';
import {GENERAL_SETTING_STATE as STATE} from '../../redux/generalSetting/generalSetting.types';
import {isArray} from '../../helpers';
import {
  generateQR,
  checkTokenQR,
  getGeneralSetting,
  fetchQRSettings,
} from '../../redux/generalSetting/generalSetting.actions';
import '../../assets/style/style.css';
import '../../assets/style/mobile.css';

const QR_INTERVAL = process.env.REACT_APP_REFRESH_QR_INTERVAL ? process.env.REACT_APP_REFRESH_QR_INTERVAL : 0; // in miliseconds

class QRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qrURL: null,
      initialLoading: true,
      loadingLogo: true,
      token: null,
      formData: {
        auto_refresh_qr: null,
      },
      settings: [],
      message: false,
    };
    this.intervalId = null;
  }

  UNSAFE_componentWillMount() {
    this.props.getGeneralSetting();
  }

  componentDidMount() {
    this.checkToken();
    this.props.fetchQRSettings();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, data, qr, autoRefresh} = nextProps;

    if (trigger === STATE.CHECK_TOKEN_SUCCESS) {
      this.setState({qrURL: qr});
      this.checkQRTimer(autoRefresh);
    }

    if (trigger === STATE.FETCH_ALL_SUCCCESS) {
      const filtered = this.filterSetting(data);
      const qrSetting = filtered && filtered[0] ? filtered[0] : null;

      this.setState({
        settings: filtered,
        formData: {
          auto_refresh_qr: qrSetting,
        },
      });
    }

    if (isSuccess && trigger === STATE.GENERATE_QR_SUCCESS) {
      this.setState({qrURL: qr});
      this.checkQRTimer(autoRefresh);
    }

    if (trigger === STATE.CHECK_TOKEN_FAILED || trigger === STATE.GENERATE_QR_FAILED) {
      this.props.history.push('/419');
    }

  }

  filterSetting = (data) => {
    const filter = (data, key) => {
      const result = data.filter((value) => value.key === key);
      return isArray(result) ? result[0] : null;
    };

    const qrSetting = filter(data, 'auto_refresh_qr');
    return [qrSetting];
  }

  componentWillUnmount() {
    this.stopQRTimer();
  }

  checkToken = () => {
    const location = this.props.location.pathname;
    const segment = location.substring(location.lastIndexOf('/') + 1);
    const token = segment !== 'qrcode' ? segment : null;

    if (token) {
      this.setState({token});
      this.props.checkTokenQR({id: token});
    } else {
      this.props.history.push('/419');
    }
  }

  checkQRTimer = (autoRefreshStatus) => {
    if (autoRefreshStatus) this.startQRTimer();
    if (!autoRefreshStatus) this.stopQRTimer();
  }

  startQRTimer = () => {
    const {auto_refresh_qr} = this.state.formData;
    clearInterval(this.intervalId);
    this.intervalId = setInterval(this.handleGenerateQR.bind(this), auto_refresh_qr ? auto_refresh_qr.value * 1000 : 180 * 1000 );
  }

  handleGenerateQR = () => {
    const {token} = this.state;
    this.props.generateQR({id: token});
  }

  stopQRTimer = () => {
    clearInterval(this.intervalId);
  }

  renderForm = () => {
    const {classes, companyDetail, isLoading} = this.props;
    const {qrURL, initialLoading, loadingLogo} = this.state;

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light navbar-transparent navbar-absolute">
          {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mars-navbar" aria-controls="mars-navbar" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}
          {/* <div className="collapse navbar-collapse"> */}
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <a className="navbar-brand mr-5" href="#">
                    <span className={classes.title}>{companyDetail.name}</span>
                  </a>
                </div>
              </div>
            </div>
          {/* </div> */}
        </nav>
        <section id="section-qr" className={classes.qrSection}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="qr-wrapper">
                  <span className="caption-badge">WORKING OFFICE</span>
                  <div className="row">
                    <div className="col-md-4">
                        {
                          isLoading ?
                          (
                            <div className={classes.loaderContainer}>
                              <div className={classes.loader}>
                                <CircularProgress color="primary" size={24} />
                                <div className={classes.loaderText}>{'Loading QR...'}</div>
                              </div>
                            </div>
                          ) : (
                            <img
                              className={`img-qr ${initialLoading && classes.hidden} ${classes.qrCode}`}
                              src={qrURL ? qrURL : null}
                              onLoad={() => this.setState({initialLoading: false})}
                              alt="QRUrl"
                            />
                          )
                        }
                    </div>
                    <div className={`col-md-8 ${classes.companyDetail}`}>
                      <h1 className={`${classes.titleFont}`}>Scan Attendance QR</h1>
                      <div className="company-info">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="info-group">
                              {
                                companyDetail && companyDetail.logo &&
                                (
                                  <img
                                    src={companyDetail.logo}
                                    className={`company-logo ${loadingLogo && classes.hidden}`}
                                    alt="company-logo"
                                    onLoad={() => this.setState({loadingLogo: false})}
                                  />
                                )
                              }
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="info-group">
                              <label className="company-label">Company Name</label>
                              <span className="company-span">{companyDetail.name}</span>
                            </div>
                            <div className="info-group">
                              <label className="company-label">Company Address</label>
                              <span className="company-span">{companyDetail.address}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12 attendance-info">
                <h2>Attendance Table</h2>
                <a href="#print" className="btn btn-main btn-print"><i className="fas fa-print"></i> Print</a>
                <table className="table table-striped tbl-attendance">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Photo</th>
                      <th scope="col">Date</th>
                      <th scope="col">Check In</th>
                      <th scope="col">Check Out</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark Hoppus</td>
                      <td><img src="assets/img/testimony/user-1.jpg" className="img-user"/></td>
                      <td>31-07-2020</td>
                      <td>08:01</td>
                      <td>17:02</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Ferdinand Sinaga</td>
                      <td><img src="assets/img/testimony/user-2.jpg" className="img-user"/></td>
                      <td>31-07-2020</td>
                      <td>08:11</td>
                      <td>18:12</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Ruud Van Gaal</td>
                      <td><img src="assets/img/testimony/user-1.jpg" className="img-user"/></td>
                      <td>31-07-2020</td>
                      <td>08:11</td>
                      <td>18:12</td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>Mario Sancho</td>
                      <td><img src="assets/img/testimony/user-2.jpg" className="img-user"/></td>
                      <td>31-07-2020</td>
                      <td>08:11</td>
                      <td>18:12</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = ({generalSetting}) => ({
  data: generalSetting.data,
  isLoading: generalSetting.isLoading,
  isSuccess: generalSetting.isSuccess,
  isError: generalSetting.isError,
  message: generalSetting.message,
  trigger: generalSetting.trigger,
  qr: generalSetting.qr,
  autoRefresh: generalSetting.autoRefresh,
  companyDetail: generalSetting.company,
});

const mapDispatchToProps = (dispatch) => ({
  getGeneralSetting: (key) => dispatch(getGeneralSetting(key)),
  fetchQRSettings: (params) => dispatch(fetchQRSettings(params)),
  generateQR: (params) => dispatch(generateQR(params)),
  checkTokenQR: (params) => dispatch(checkTokenQR(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(QRPage));


export const initialState = {
  data: {
    accesToken: null,
    refreshToken: null,
    authorization: null,
  },
  trigger: '',
};


export const REFRESH_TOKEN_STATE = {
  REQUEST: 'REFRESH_TOKEN_REQUEST',
  REQUEST_FAILED: 'REFRESH_TOKEN_REQUEST_FAILED',
  CLEAR_STATE: 'REFRESH_TOKEN_CLEAR_STATE',

  UPDATE_REFRESH_TOKEN: 'UPDATE_REFRESH_TOKEN',
  UPDATE_REFRESH_TOKEN_SUCCESS: 'UPDATE_REFRESH_TOKEN_SUCCESS',
  UPDATE_REFRESH_TOKEN_FAILED: 'UPDATE_REFRESH_TOKEN_FAILED',
};

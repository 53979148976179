/* eslint-disable max-len */
import {UPGRADE, ORGANIZATION} from '../../services';
import {UPGRADE_STATE as STATE} from './upgradeAccount.types';
import {transformObject, isArray} from '../../helpers';

export function createBankPayment(params) {
  return async (dispatch) => {
    dispatch(request(STATE.BANK_PAYMENT));
    UPGRADE.createBankPayment(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.BANK_PAYMENT_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.BANK_PAYMENT_FAILED, message));
            },
        );
  };
}


export function checkPremium() {
  return async (dispatch) => {
    dispatch(request(STATE.CHECK));
    ORGANIZATION.checkStatus()
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CHECK_SUCCESS, response.data.data));
            },
            () => {
              dispatch(requestFailed(STATE.CHECK_FAILED, 'Failed to check Account status'));
            },
        );
  };
}


export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};


const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

/* eslint-disable max-len */
import {REIMBURSE} from '../../services';
import {APPROVAL_STATE as STATE} from './approvalReimburse.types';

export const getApprovalReimburseByToken = (params) => {
  return (dispatch) => {
    dispatch(fetchApprovalReimburseHistory());
    REIMBURSE.getReimburseHistoryByToken(params).then((response) => {
      const data = response.data;
      if (!data.error) {
          dispatch(fetchApprovalReimburseSuccess(data));
        } else {
        dispatch(fetchApprovalReimburseFailed(data));
      }
    }).catch((error) => {
      const message = error?.response?.data?.data?.errors && error?.response?.data?.data?.errors[0]?.value ? error?.response?.data?.data?.errors[0]?.value : 'Something went wrong';
      dispatch(fetchApprovalReimburseFailed({message}));
    });
  };
};

export const confirmApprovalReimburse = (params) => {
  return (dispatch) => {
    dispatch(submitApprovalReimburse());
    REIMBURSE.confirmApprovalReimburse(params).then((response) => {
      const data = response.data;
      if (!data.error) {
        dispatch(submitApprovalReimburseSuccess(data));
      } else {
        dispatch(submitApprovalReimburseFailed(data));
      }
    }).catch((error) => {
      const message = error?.response?.data?.data?.errors && error?.response?.data?.data?.errors[0]?.value ? error?.response?.data?.data?.errors[0]?.value : 'Something went wrong';
      dispatch(submitApprovalReimburseFailed({message}));
    });
  };
};

export const resetLoader = () => ({
  type: STATE.RESET_LOADER,
});

const fetchApprovalReimburseHistory = () => ({
  type: STATE.GET_APPROVAL_REIMBURSE_BY_TOKEN,
});

const fetchApprovalReimburseSuccess = (data) => ({
  type: STATE.GET_APPROVAL_REIMBURSE_BY_TOKEN_SUCCESS,
  data,
});

const fetchApprovalReimburseFailed = (data) => ({
  type: STATE.GET_APPROVAL_REIMBURSE_BY_TOKEN_FAILURE,
  data,
});

const submitApprovalReimburse = () => ({
  type: STATE.SUBMIT_APPROVAL_REIMBURSE,
});

const submitApprovalReimburseSuccess = (data) => ({
  type: STATE.SUBMIT_APPROVAL_REIMBURSE_SUCCESS,
  data,
});

const submitApprovalReimburseFailed = (data) => ({
  type: STATE.SUBMIT_APPROVAL_REIMBURSE_FAILURE,
  data,
});

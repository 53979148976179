/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {Link, Grid, Typography} from '@material-ui/core';
import {REGISTER_STATE as STATE} from '../../redux/register/register.types';
import {toast, isObject, checkMobile} from '../../helpers';
import {MInput, MSelect, MButton, MCheckboxGroup, MCheckboxItem, MInputMask} from '../../components/form';
import styles from './styles';
import {getOrganizationTypeWithoutAuth} from '../../redux/organization/organization.actions';
import {submitRegister, clearState} from '../../redux/register/register.actions';
import Recaptcha from 'react-recaptcha';
import registerBackground from '../../../src/assets/img/login/registerBG.png';
import pagiiLogo from '../../../src/assets/img/login/pagiiLogo.png';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const REGISTER_LINK = '/register';

class RegisterCompany extends React.Component {
  constructor() {
    super();
    this.state = {
      id: null,
      name: null,
      email: null,
      phone: null,
      purpose: null,
      organization_name: null,
      organization_addres: null,
      pic_name: null,
      pic_email: null,
      pic_phone: null,
      organization_type_id: null,
      number_of_employees: null,
      feature: {remote: false, office: false, business: false},
      referral_id: null,
      disabled_reff_id: false,
      showOthers: false,
      errorMessage: null,
      isVerified: true,
      tnc: false,
    };
    this.initialState = this.state;
  }

  muiSelectTheme = () => createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
      },
      secondary: {
        main: '#FFFFFF',
      },
    },
    overrides: {
      MuiTypography: {
        body1: {
          color: '#FFFFFF',
          fontSize: '13px',
        },
        colorPrimary: {
          color: '#FFBD56',
        },
      },
      MuiButton: {
        label: {
          fontWeight: 'bold',
        },
      },
      MuiIconButton: {
        label: {
          color: '#FFFFFF',
          marginLeft: '4px',
        },
      },
      MuiFormLabel: {
        root: {
          color: '#FFFFFF !important',
          fontSize: '16px !important',
          fontFamily: 'Poppins !important',
          marginBottom: '18px !important',
        },
      },
      MuiInput: {
        formControl: {
          marginTop: '18px !important',
        },
        underline: {
          '&:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 1) !important',
          },
        },
      },
      MuiInputBase: {
        input: {
          color: '#C6F0FF !important',
          fontSize: '13px! important',
          fontFamily: 'Poppins !important',
        },
      },
      MuiInputLabel: {
        formControl: {
          fontSize: '18px !important',
          left: 'inherit',
        },
      },
    },
  });


  getMuiTheme = () => createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
      },
      secondary: {
        main: '#FFFFFF',
      },
    },
    overrides: {
      MuiTypography: {
        body1: {
          color: '#FFFFFF',
          fontSize: '13px',
        },
        colorPrimary: {
          color: '#FFBD56',
        },
      },
      MuiButton: {
        label: {
          fontWeight: 'bold',
        },
      },
      MuiIconButton: {
        label: {
          color: '#FFFFFF',
          marginLeft: '4px',
        },
      },
      MuiFormLabel: {
        root: {
          color: '#FFFFFF !important',
          fontSize: '18px !important',
          fontFamily: 'Poppins !important',
          marginBottom: '18px !important',
        },
      },
      MuiInput: {
        formControl: {
          marginTop: '18px !important',
        },
        underline: {
          '&:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 1) !important',
          },
        },
      },
      MuiInputBase: {
        input: {
          color: '#C6F0FF !important',
          fontSize: '14px! important',
          fontFamily: 'Poppins !important',
        },
      },
      MuiInputLabel: {
        formControl: {
          fontSize: '18px !important',
          left: 'inherit',
        },
      },
    },
  });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message} = nextProps;

    if (isSuccess) {
      if (trigger === STATE.REGISTER_SUCCESS) {
        this.handleResetForm(true);
      }
    }

    if (isError && message && isObject(message)) this.setState({errorMessage: message});
    if (isError && message && !isObject(message)) toast('error', message);
  }

  componentDidMount() {
    const isMobile = checkMobile();
    if (!isMobile) window.location.href = 'https://pagii.co/#section-cta';
    this.props.getOrganizationTypeWithoutAuth();
    // this.props.history.push(REGISTER_LINK);
  }

  componentWillUnmount() {
    this.props.clearState();
    // this.props.history.push(REGISTER_LINK);
  }

  checkSelectedFeature = () => {
    const {errorMessage} = this.state;
    const {remote, office, business} = this.state.feature;
    const validate = () => {
      if (!remote && !office && !business) return false;
      return true;
    };

    const isValid = validate();
    const message = 'Please select at least 1 (one) attendance type';
    if (!isValid) this.setState({errorMessage: {...errorMessage, feature: message}});
    return isValid;
  }

  handleResetForm = () => {
    this.setState(this.initialState);
  }

  handleInputChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      [name]: value,
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  }

  handleTypeChange = (event) => {
    const {name, value} = event.target;
    const showOthers = (value === 0) ? true : false;
    this.setState({
      showOthers,
      [name]: value,
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  }

  handleBackToNative = (e) => {
    const payload = {type: 'backToNative'};
    const json = (JSON.stringify(payload));
    window.postMessage(json, '*');
  }

  handleBackToWeb = (e) => {
    e.preventDefault();
    const baseURL = window.location.origin;
    window.location.href = baseURL + '/login';
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      name, email, phone, organization_name,
      pic_name, pic_email, pic_phone,
      organization_addres, organization_type_id, number_of_employees,
      purpose, referral_id, isVerified,
    } = this.state;

    if (isVerified) {
      const params = {
        ...(name && {name}),
        ...(email && {email}),
        ...(phone && {phone}),
        ...(purpose && {purpose}),
        ...(organization_name && {organization_name}),
        // ...(pic_name && {pic_name}),
        // ...(pic_email && {pic_email}),
        // ...(pic_phone && {pic_phone}),
        ...(organization_type_id && {organization_type_id}),
        ...(organization_addres && {organization_addres}),
        ...(number_of_employees && {number_of_employees}),
        ...(referral_id && {referral_id}),
      };
      this.props.submitRegister(params);
    } else {
      toast('error', 'Please verify captcha');
    }
  }

  verifyCallback = (response) => {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  }
  onloadCallback = () => {
    this.setState({
      isVerified: false,
    });
  }
  expiredCallback = (response) => {
    if (!response) {
      this.setState({
        isVerified: false,
      });
    }
  }

  handleCheckboxChange = (event) => {
    const {errorMessage} = this.state;
    const {name, checked} = event.target;
    this.setState({
      errorMessage: {...errorMessage, [name]: null},
      [name]: checked,
    });
  }

  renderForm = () => {
    const {classes, isLoading, orgTypeData} = this.props;
    const {
      name, email, phone, organization_name, organization_addres, organization_type_id,
      pic_name, pic_email, pic_phone,
      errorMessage, showOthers, tnc,
    } = this.state;

    const companyWorkers = [
      {id: '< 50 peoples', name: '< 50 peoples'},
      {id: '50 - 100 peoples', name: '50 - 100 peoples'},
      {id: '100 - 250 peoples', name: '100 - 250 peoples'},
      {id: '250 - 500 peoples', name: '250 - 500 peoples'},
      {id: '> 500 peoples', name: '> 500 peoples'},
    ];

    const isMobile = checkMobile();

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <div className={classes.formWrapper}>
          <form onSubmit={this.handleSubmit}>
            {/* <Grid container>
              <Grid item xs={12} style={{marginLeft: '2px', marginBottom: '20px', marginTop: '8px'}}>
                <a href="/" >
                  <img src={buttonBack} alt="back" />
                </a>
              </Grid>
            </Grid> */}
            <Grid container>
              <Grid item xs={12} style={{marginTop: '12px'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <img src={pagiiLogo}
                    style={{
                      marginBottom: '36px',
                    }}
                    alt="pagiiLogo"
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: '22px',
                    fontWeight: 'bold',
                    fontFamily: 'Poppins !important',
                    marginLeft: '4px',
                  }}>
                  Company Register
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'regular',
                    fontFamily: 'Poppins !important',
                    marginLeft: '5px',
                    marginTop: '6px',
                    marginBottom: '6px',
                  }}>
                  Create account to start using Pagii
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  id="full-name"
                  name="name"
                  label="Name"
                  placeholder="Your fullname"
                  defaultValue={name}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.name ? errorMessage.name : undefined}
                  autoFocus
                  fullWidth
                  variant={isMobile ? 'standard' : 'outlined'}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  id="email"
                  name="email"
                  label="Email"
                  placeholder="Your email address"
                  defaultValue={email}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.email ? errorMessage.email : undefined}
                  fullWidth
                  type="email"
                  variant={isMobile ? 'standard' : 'outlined'}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  name="phone"
                  label="Phone Number"
                  placeholder="Phone"
                  type="number"
                  defaultValue={phone}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.phone ?
                      errorMessage.phone.split(':')[0] :
                      undefined
                  }
                  variant={isMobile ? 'standard' : 'outlined'}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  id="organization_name"
                  name="organization_name"
                  label="Company Name"
                  placeholder="Your company name"
                  defaultValue={organization_name}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.organization_name ? errorMessage.organization_name : undefined}
                  fullWidth
                  variant={isMobile ? 'standard' : 'outlined'}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  id="organization_address"
                  name="organization_addres"
                  label="Company Address"
                  placeholder="Your company address"
                  defaultValue={organization_addres}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.organization_addres ? errorMessage.organization_addres : undefined}
                  fullWidth
                  multiline
                  rows={2}
                  maxRows={3}
                  variant={isMobile ? 'standard' : 'outlined'}
                />
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  id="pic_name"
                  name="pic_name"
                  label="PIC Name"
                  placeholder="Your PIC name"
                  defaultValue={pic_name}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.pic_name ? errorMessage.pic_name : undefined}
                  fullWidth
                  type="text"
                  variant={isMobile ? 'standard' : 'outlined'}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  id="pic_email"
                  name="pic_email"
                  label="PIC Email"
                  placeholder="Your PIC email"
                  defaultValue={pic_email}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.pic_email ? errorMessage.pic_email : undefined}
                  fullWidth
                  type="email"
                  variant={isMobile ? 'standard' : 'outlined'}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MInput
                  classNameFC={classes.formControl}
                  id="pic_phone"
                  name="pic_phone"
                  label="PIC Phone Number"
                  placeholder="Your PIC Phone Number"
                  defaultValue={pic_phone}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.pic_phone ? errorMessage.pic_phone : undefined}
                  fullWidth
                  type="number"
                  variant={isMobile ? 'standard' : 'outlined'}
                />
              </Grid> */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MuiThemeProvider theme={this.muiSelectTheme()}>
                  <MSelect
                    classNameFC={classes.formControl}
                    id="organization_type"
                    name="organization_type_id"
                    label="Sector / Business Industry"
                    placeholder="Select company sector"
                    keyPair={['id', 'name']}
                    options={orgTypeData ? orgTypeData : []}
                    value={organization_type_id}
                    onChange={this.handleTypeChange}
                    error={errorMessage && errorMessage.organization_type_id ? errorMessage.organization_type_id : undefined}
                    variant={isMobile ? 'standard' : 'outlined'}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    shrink={true}
                    notched={true}
                  />
                </MuiThemeProvider>
                {
                  showOthers ?
                  (
                    <MInput
                      classNameFC={classes.formControl}
                      name="others"
                      id="others_type"
                      placeholder="Input your company type / business industry"
                      onBlur={this.handleInputChange}
                      fullWidth
                    />
                  ) : ''
                }
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <MuiThemeProvider theme={this.muiSelectTheme()}>
                  <MSelect
                    classNameFC={classes.formControl}
                    id="number_of_employees"
                    name="number_of_employees"
                    label="Number of employees"
                    placeholder="You company approximate total employees"
                    keyPair={['id', 'name']}
                    options={companyWorkers}
                    onBlur={this.handleInputChange}
                    error={errorMessage && errorMessage.number_of_employees ? errorMessage.number_of_employees : undefined}
                    variant={isMobile ? 'standard' : 'outlined'}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    shrink={true}
                    notched={true}
                  />
                </MuiThemeProvider>
              </Grid>
            </Grid>
            <div className={classes.btnContainer} style={{marginTop: 12}}>
              <Recaptcha
                sitekey={RECAPTCHA_SITE_KEY}
                render="explicit"
                elementID="a1"
                onloadCallback={this.onloadCallback}
                expiredCallback={this.expiredCallback}
                verifyCallback={this.verifyCallback}
                // onloadCallback={true}
                // expiredCallback={true}
                // verifyCallback={true}
              />
            </div>
            <div className={classes.btnContainer} style={{marginBottom: '18px'}}>
              <MButton
                className={classes.registerBtn}
                id="submit-register"
                label="Submit"
                color="primary"
                type="submit"
                loading={isLoading}
                // disabled={!tnc}
              />
            </div>
            <Grid item xs={12}>
            <div style={{fontSize: 13, marginBottom: 18, textAlign: 'center'}}>
                <span style={{color: 'white'}}>By registering, you are agree to our </span>
                <Link href="https://pagii.co/kebijakan-privasi" target="_blank" rel="noopener noreferrer">
                  <span>Privacy Policy </span>
                </Link>
                <span style={{color: 'white'}}>also </span>
                <Link href="https://pagii.co/ketentuan-pengguna/" target="_blank" rel="noopener noreferrer">
                  <span>Terms and Conditions</span>
                </Link>
              </div>
            </Grid>
          </form>
        </div>
      </MuiThemeProvider>
    );
  };

  renderThankYou = () => {
    const {classes} = this.props;
    const isMobile = checkMobile();
    return (
      <div className={classes.center}>
        <h5 className={`${classes.formTitle} ${classes.center}`}>Thank You for Registering in Pagii</h5>
        <h7 className={classes.center}>
          Please check your E-mail for the confirmation.
        </h7>
        <div className={classes.btnThankYouContainer}>
          <Link
            href={'/finish-register'}
            className={classes.registerBtn}
            onClick={isMobile ? this.handleBackToNative : this.handleBackToWeb}
          >
            Back to Login
          </Link>
        </div>
      </div>
    );
  }

  render() {
    const {classes, isSuccess} = this.props;
    const isMobile = checkMobile();
    return (
      <div className={`${classes.root} ${classes.adjustHeight}`}
        style={{
          backgroundImage: `url(${registerBackground})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={classes.paper}>
          {
              isSuccess ?
              this.renderThankYou() : isMobile ?
              this.renderForm() : undefined
          }
        </div>
      </div>
    );
  }
}

const stateToProps = ({auth, organization, register}) => ({
  token: auth.data.authorization,
  isLoading: register.isLoading,
  isError: register.isError,
  isSuccess: register.isSuccess,
  message: register.message,
  trigger: register.trigger,
  orgTypeData: organization.orgTypeData,
});

const dispatchToProps = (dispatch) => ({
  getOrganizationTypeWithoutAuth: (params) => dispatch(getOrganizationTypeWithoutAuth(params)),
  submitRegister: (params) => dispatch(submitRegister(params)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(RegisterCompany));

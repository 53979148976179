export default (theme) => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '60vh',
    backgroundColor: '#fff',
  },
  paper: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    maxWidth: 400,
    margin: 'auto',
    opacity: '.7',
  },
  formTitle: {
    color: '#00AFF0',
    fontWeight: 600,
    margin: '15px 0',
    textalign: 'center',
  },
  formText: {
    fontSize: '0.85em',
    margin: '15px 0',
    textalign: 'center',
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: 300,
  },
  btnSubmit: {
    'padding': '10px 30px',
    'borderRadius': 40,
    'backgroundColor': '#3f51b5',
    '&:hover': {
      backgroundColor: '#3f51b5',
      textDecoration: 'none',
      color: '#fff',
    },
    'color': '#fff',
  },
  hasError: {
    color: 'red',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
    paddingBottom: 0,
  },
  header: {
    display: 'flex',
    marginBottom: 40,
  },
  headerText: {
    fontWeight: 600,
    margin: 'auto',
    fontSize: 'larger',
    paddingTop: 10,
  },
  btnBack: {
    left: 15,
    position: 'absolute',
  },
  btnThankYouContainer: {
    marginTop: 25,
    textalign: 'center',
  },
  center: {
    textalign: 'center',
  },
  loaderContainer: {
    display: 'flex',
  },
  loaderText: {
    marginLeft: 15,
    paddingBottom: 8,
  },
});

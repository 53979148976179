export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  qrTitle: {
    marginTop: 10,
    fontSize: 18,
    fontWeight: '700',
  },
  paper: {
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
    border: '',
    paddingTop: '12px',
    paddingRight: '16px',
    paddingBottom: '12px',
    paddingLeft: '16px',
  },
  formControlQR: {
    marginBottom: 16,
    marginTop: 16,
    minWidth: 120,
    marginRight: 16,
  },
  btnGenerateContainer: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'flex-start',
    marginBottom: '42px',
  },
  defaultBtnQR: {
    'height': 38,
    'marginRight': 14,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'paddingLeft': '16px',
    'paddingRight': '16px',
  },
  btnPrint: {
    'height': 40,
    'width': 100,
    'marginBottom': 8,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    'color': '#fff',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#00A2DE',
    },
  },
});

import {ENDPOINTS, apiService} from '../config';

export const getJobTitle = (params) => {
  return apiService.get(
      ENDPOINTS.JOB_TITLE.ALL,
      {params},
  );
};

export const getJobTitleByOrg = (params) => {
  return apiService.get(
      ENDPOINTS.JOB_TITLE.BY_ORG,
      {params},
  );
};

export const addJob = (params) => {
  return apiService.post(
      ENDPOINTS.JOB_TITLE.ADD,
      params,
  );
};

export const updateJob = (id, params) => {
  return apiService.put(
      ENDPOINTS.JOB_TITLE.UPDATE + id,
      params,
  );
};

export const dropJob = (id) => {
  return apiService.delete(
      ENDPOINTS.JOB_TITLE.DELETE + id,
  );
};

export const exportExcel = (params) => {
  return apiService.post(
      ENDPOINTS.JOB_TITLE.EXPORT,
      params,
  );
};



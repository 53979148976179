import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {Box} from '@material-ui/core';
import EventList from './list';
import EventListHR from './list/hr';
import {useSelector} from 'react-redux';
import {checkSuperadmin} from '../../helpers';

function EventManagement({classes}) {
  const {auth} = useSelector((state) => state) || {};

  return (
    <div className={classes.root}>
      <Box>
        {checkSuperadmin(auth) ? (
          <EventList />
        ) : <EventListHR />}
      </Box>
    </div>
  );
}

export default withStyles(styles)(EventManagement);

/* eslint-disable max-len */
import {APPROVAL_ORDER} from '../../services';
import {APPROVAL_ORDER_STATE as STATE} from './approvalOrder.types';
import {transformObject, isArray} from '../../helpers';

export const getApprovalOrder = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    APPROVAL_ORDER.getApprovalOrder(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getDetailApproval = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_DETAIL));
    APPROVAL_ORDER.getDetailApproval(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_DETAIL_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_DETAIL_FAILED, response.data.message));
            },
        );
  };
};

export const getMasterApproval = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_MASTER));
    APPROVAL_ORDER.getMasterApproval(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_MASTER_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_MASTER_FAILED, response.data.message));
            },
        );
  };
};

export const getUserApproval = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_APPROVAL));
    APPROVAL_ORDER.getUserApproval(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_APPROVAL_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_APPROVAL_FAILED, response.data.message));
            },
        );
  };
};

export const updateApprovalOrder = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    APPROVAL_ORDER.updateApprovalOrder(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

// DISPATCH

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import {MInput, MSelect, MButton, ModalTitle, MCheckboxGroup, MCheckboxItem, MDatepicker} from '../form';
import OutboxIcon from '@mui/icons-material/Outbox';
import {
    Dialog,
    DialogActions,
    DialogContent,
    CardMedia,
    Grid,
    Divider,
} from '@material-ui/core';

class InvitationOrganizationModal extends Component {
    render() {
        const {
            classes, isSuperAdmin, isLoading,
            handleSubmit, handleInputChange,
        } = this.props;
        const {isEdit, errorMessage, errorImageSize, odooStatus} = this.props;
        const {
          feature, pic_email, odoo_key, odoo_url, prevOdooKey, prevOdooUrl,
        } = this.props.formData;

        const titleDialog = isEdit ? 'Update Company' : 'Create Company';

        const featureArray = Object.keys(feature).map((key) => {
return {name: key, value: feature[key]};
});

        const advanceFeat = featureArray && featureArray.filter((value) => {
          return (value.name === 'business' || value.name === 'advantech' || value.name === 'payslip') && value.value === true;
        });

        const disabled = ((!odoo_key || !odoo_url) || (prevOdooKey !== odoo_key || prevOdooUrl !== odoo_url)) && feature && feature.payslip === true && odooStatus !== true ? true : false;

        return (
            <Dialog
                open={this.props.showModal}
                onClose={() => this.props.props.setState({showInvitationModal: false})}
                maxWidth='lg'
            >
            <ModalTitle
                title={'Invitation Company'}
                onClose={() => this.props.props.setState({showInvitationModal: false})}
            />
            <form onSubmit={handleSubmit}>
                <DialogContent className={classes.dialogContent}>
                    <MInput
                        style={{
                            minWidth: '400px',
                        }}
                        name="pic_email"
                        label="PIC Email"
                        placeholder="Enter PIC email"
                        autoComplete="off"
                        defaultValue={pic_email}
                        onBlur={handleInputChange}
                        error={errorMessage && errorMessage.name ? errorMessage.name : undefined}
                        type={'email'}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogFooter}>
                    <MButton
                        className={classes.defaultBtn}
                        label={'Invitation'}
                        icon={<OutboxIcon />}
                        type="submit"
                        loading={isLoading ? true : false}
                        disabled={disabled}
                    />
                </DialogActions>
            </form>
            </Dialog>
        );
    }
}

export default InvitationOrganizationModal;

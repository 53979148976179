export default (theme) => ({
  paper: {
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
    border: '',
    paddingTop: '12px',
    paddingRight: '16px',
    paddingBottom: '12px',
    paddingLeft: '16px',
  },
  topBox: {
    borderRadius: 18,
    boxShadow: 'none',
    border: '',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    backgroundColor: '#FFF',
    padding: '16px, 24px, 16px, 24px',
    marginBottom: '16px',
  },
});

import {ENDPOINTS, apiService} from '../config';

export const getMasterInventory = (params) => {
  return apiService.get(
      ENDPOINTS.MASTER_INVENTORY.ALL,
      {params},
  );
};

export const getMasterInventoryDetail = (id) => {
  return apiService.get(
      ENDPOINTS.MASTER_INVENTORY.DETAIL + id,
  );
};

export const getMasterInventoryCategory = (params) => {
  return apiService.get(
      ENDPOINTS.MASTER_INVENTORY.CATEGORY,
      {params},
  );
};

export const createMasterInventory = (params) => {
  return apiService.post(
      ENDPOINTS.MASTER_INVENTORY.CREATE,
      params,
  );
};

export const updateMasterInventory = (id, params) => {
  return apiService.put(
      ENDPOINTS.MASTER_INVENTORY.UPDATE + id,
      params,
  );
};

export const deleteMasterInventory = (id, params) => {
  return apiService.delete(
      ENDPOINTS.MASTER_INVENTORY.DELETE + id,
      params,
  );
};


export const exportExcel = (params) => {
  return apiService.post(
      ENDPOINTS.MASTER_INVENTORY.EXPORT,
      params,
  );
};

export const getConditionHistory = (params) => {
  return apiService.get(
      ENDPOINTS.MASTER_INVENTORY.CONDITION_HISTORY,
      {params},
  );
};

export const getMovementHistory = (params) => {
  return apiService.get(
      ENDPOINTS.MASTER_INVENTORY.MOVEMENT_HISTORY,
      {params},
  );
};

export const getConditionHistoryDetail = (id) => {
  return apiService.get(
      ENDPOINTS.MASTER_INVENTORY.CONDITION_HISTORY_DETAIL + id,
  );
};

export const createConditionHistory = (params) => {
  return apiService.post(
      ENDPOINTS.MASTER_INVENTORY.CREATE_CONDITION_HISTORY,
      params,
  );
};

export const updateConditionHistory = (id, params) => {
  return apiService.put(
      ENDPOINTS.MASTER_INVENTORY.UPDATE_CONDITION_HISTORY + id,
      params,
  );
};

export const deleteConditionHistory = (id, params) => {
  return apiService.delete(
      ENDPOINTS.MASTER_INVENTORY.DELETE_CONDITION_HISTORY + id,
      params,
  );
};

export const deleteImage = (id, params) => {
  return apiService.put(
      ENDPOINTS.MASTER_INVENTORY.DELETE_IMAGE + id,
      params,
  );
};

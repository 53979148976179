export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
  },
  checkBox: {
    width: 70,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#656464',
  },
  dialogContent: {
    'paddingTop': -20,
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#d5d5d5',
    },
    'marginBottom': 8,
    'marginTop': 8,
    'height': '40px',
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'marginBottom': 8,
    'marginTop': 8,
    'marginRight': 16,
    'height': '40px',
  },
});

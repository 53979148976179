import {ENDPOINTS, apiService} from '../config';

export const getNotification = (params) => {
  return apiService.get(
      ENDPOINTS.NOTIFICATION.GET,
      {params},
  );
};

export const readNotification = (id, params) => {
  return apiService.put(
    ENDPOINTS.NOTIFICATION.READ + id,
    params,
  );
};


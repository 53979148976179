import {ENDPOINTS, apiService} from '../config';

export const getSSOWhitelist = (params) => {
  return apiService.get(
      ENDPOINTS.SSO_WHITELIST.GET,
      {params},
  );
};

export const getSSODetail = (id, params) => {
  return apiService.get(
      ENDPOINTS.SSO_WHITELIST.GET_ONE, + id,
      {params},
  );
};

export const createSSOWhitelist = (params) => {
  return apiService.post(
      ENDPOINTS.SSO_WHITELIST.ADD,
      params,
  );
};

export const regenerateSSOWhitelist = (id, params) => {
  return apiService.put(
      ENDPOINTS.SSO_WHITELIST.REGENERATE + id,
      params,
  );
};

export const deleteSSOWhitelist = (id, params) => {
  return apiService.delete(
      ENDPOINTS.SSO_WHITELIST.DELETE + id,
      params,
  );
};

export const updateSSOWhitelist = (id, params) => {
  return apiService.put(
      ENDPOINTS.SSO_WHITELIST.UPDATE + id,
      params,
  );
};

export const toggleSSOWhitelist = (id, params) => {
  return apiService.put(
      ENDPOINTS.SSO_WHITELIST.TOGGLE + id,
      params,
  );
};

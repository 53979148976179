import {ENDPOINTS, apiService} from '../config';

export const getDivision = (params) => {
  return apiService.get(
      ENDPOINTS.DIVISION.ALL,
      {params},
  );
};

export const getDivisionByOrg = (params) => {
  return apiService.get(
      ENDPOINTS.DIVISION.BY_ORG,
      {params},
  );
};

export const getApprovalDivision = (params) => {
  return apiService.get(
      ENDPOINTS.DIVISION.APPROVAL,
      {params},
  );
};

export const postDivision = (params) => {
  return apiService.post(
      ENDPOINTS.DIVISION.ADD,
      params,
  );
};

export const updateDivision = (id, params) => {
  return apiService.put(
      ENDPOINTS.DIVISION.UPDATE + id,
      params,
  );
};

export const dropDivision = (id) => {
  return apiService.delete(
      ENDPOINTS.DIVISION.DELETE + id,
  );
};



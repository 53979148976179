import {initialState, XENDIT_STATE} from './xendit.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case XENDIT_STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case XENDIT_STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload.message,
        trigger: action.type,
      };
    case XENDIT_STATE.GET_TOKEN_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isError: false,
        isLoading: false,
        trigger: action.type,
        message: action.payload.message,
        data: action.payload,
      };
    case XENDIT_STATE.GET_TOKEN_FAILED:
      return {
        ...state,
        isSuccess: false,
        isError: true,
        isLoading: false,
        trigger: action.type,
        message: action.payload.message,
      };
    case XENDIT_STATE.CHECK_XENDIT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isError: false,
        isLoading: false,
        trigger: action.type,
        message: action.payload,
        xenditStatus: action.payload,
      };
    case XENDIT_STATE.CHECK_XENDIT_FAILED:
      return {
        ...state,
        isSuccess: false,
        isError: true,
        isLoading: false,
        trigger: action.type,
        message: action.payload.message,
      };
    case XENDIT_STATE.CLEAR_STATE_XENDIT:
      return {
        ...state,
        ...initialState,
      };
    case XENDIT_STATE.CLEAR_STATE_XENDIT_GENERATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

import {ENDPOINTS, apiService} from '../config';

export const getInvoice = (params) => {
  return apiService.get(
      ENDPOINTS.INVOICE.ALL,
      {params},
  );
};

export const generateInvoice = (params) => {
  return apiService.post(
      ENDPOINTS.INVOICE.GENERATE,
      params,
  );
};

export const selectPaymentPlan = (params) => {
  return apiService.post(
      ENDPOINTS.INVOICE.SELECT,
      params,
  );
};

export const getInvoiceDetail = (id, params) => {
  return apiService.get(
      ENDPOINTS.INVOICE.DETAIL + id,
      params,
  );
};

export const getInvoiceHistory = (params) => {
  return apiService.get(
      ENDPOINTS.INVOICE.HISTORY,
      {params},
  );
};

export const getInvoicePrice = (params) => {
  return apiService.get(
      ENDPOINTS.INVOICE.PRICE,
      {params},
  );
};

export const updateInvoice = (id, params) => {
  return apiService.put(
      ENDPOINTS.INVOICE.UPDATE + id,
      params,
      {headers: {'Content-Type': 'multipart/form-data'}},
  );
};
export const checkInvoice = () => {
  return apiService.get(
      ENDPOINTS.INVOICE.CHECK_AMOUNT,
  );
};

export const updateInvoiceStatus = (id, params, status) => {
  if (status === 'paid') {
    return apiService.put(
        ENDPOINTS.INVOICE.UPDATE_INVOICE + id,
        params,
        {headers: {'Content-Type': 'multipart/form-data'}},
    );
  } else {
    return apiService.put(
        ENDPOINTS.INVOICE.UPDATE_INVOICE + id,
        params,
    );
  }
};

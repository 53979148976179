import {ENDPOINTS, apiService} from '../config';

export const getLeaderboardToday = (params) => {
  return apiService.get(
      ENDPOINTS.LEADERBOARD.GET_TODAY,
      {params},
  );
};

export const getLeaderboardMonth = (params) => {
  return apiService.get(
      ENDPOINTS.LEADERBOARD.GET_MONTH,
      {params},
  );
};

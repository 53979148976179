import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Typography,
  Box,
  IconButton,
  Paper,
  withStyles,
  Tabs,
  Tab,
} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom';
import {ArrowBack, Edit as EditIcon} from '@material-ui/icons';
import {useDispatch, useSelector} from 'react-redux';
import {checkSuperadmin} from '../../../../helpers';
import {getEventDetail, getEventQR} from '../../../../redux/eventManagement/eventManagement.actions';
import EventParticipants from '../participants';
import {MButton} from '../../../../components/form';
import EventVoucher from './voucher';
import EventDetailSummary from './eventDetailSummary';
import styles from './styles';

const CustomTabs = withStyles({
  root: {
    borderBottom: 'none',
  },
  indicator: {
    display: 'none',
  },
})(Tabs);

const CustomTab = withStyles({
  selected: {
    backgroundColor: '#fff',
  },
  root: {
    padding: '12px 3px',
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '18px',
    color: '#656464',
    backgroundColor: '#fff',
    borderTopLeftRadius: 18,
    borderTopRightRadius: 18,
  },
  wrapper: {
    fontKerning: 'normal',
  },
})(Tab);

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      style={{backgroundColor: '#FFF', borderRadius: 18, borderTopLeftRadius: 0, padding: '8px'}}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value !== index ? null : (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function EventDetail({classes}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {state} = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const {auth, eventManagement: {eventDetail, isLoading}} = useSelector((state) => state) || {};
  const eventId = useMemo(() => eventDetail?.id, [eventDetail]);

  const fetchDetail = useCallback((_eventId) => {
    dispatch(getEventDetail(_eventId));
    dispatch(getEventQR(_eventId));
  }, [dispatch]);

  const handleEdit = () => {
    if (!checkSuperadmin(auth) && eventDetail?.id) {
      history.push('/event-management/update', {isEdit: true, id: eventDetail?.id});
    }
  };

  useEffect(() => {
    if (!state?.id) {
      history.push('/event-management');
    } else if (eventId !== state.id) {
      fetchDetail(state.id);
    }
  }, [history, state.id, fetchDetail, eventId]);

  return (
    <>
      <Paper variant="outlined" className={classes.topBox}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <IconButton onClick={() => history.push('/event-management')}>
              <ArrowBack />
            </IconButton>

            <Typography
              style={{
                fontWeight: 700,
                fontSize: '18px',
                color: '#656464',
              }}
              >
              Event List
            </Typography>
          </div>

          <div style={{marginRight: '16px'}}>
          {checkSuperadmin(auth) || ['on_review', 'blocked', 'canceled'].includes(eventDetail?.status) ? null :
            <MButton
              label="Update"
              color="primary"
              onClick={handleEdit}
              icon={<EditIcon />}
              loading={isLoading}
            />}
          </div>
        </div>
      </Paper>

      <CustomTabs
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
      >
        <CustomTab label="Event Detail"/>
        <CustomTab label="Participants" />
        {eventDetail?.payment_type !== 'paid' ? null : <CustomTab label="Vouchers" />}
      </CustomTabs>

      <TabPanel value={activeTab} index={0}>
        <EventDetailSummary classes={classes} eventDetail={eventDetail} />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <EventParticipants />
      </TabPanel>

      {eventDetail?.payment_type !== 'paid' ? null : (
        <TabPanel value={activeTab} index={2}>
          <EventVoucher />
        </TabPanel>
      )}
    </>
  );
}

export default withStyles(styles)(EventDetail);

/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {
  IconButton,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  CardMedia,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Delete,
  Send,
  Visibility,
  VisibilityOff,
  Search,
  Crop,
  Refresh,
  Sync,
  CheckCircleOutline,
  WarningOutlined,
  CancelOutlined,
  HourglassEmpty,
  SaveAlt,
  GetApp,
} from '@material-ui/icons';
import {checkPremium} from '../../../redux/upgradeAccount/upgradeAccount.actions';
import {USER_STATE} from '../../../redux/userManagement/userManagement.types';
import {
  isObject,
  toast,
  showPopup,
  convDate,
  checkSuperadmin,
  checkFileUploadSize,
  blobToFile,
  getTimeDiff,
  isArray,
} from '../../../helpers';
import {
  MInput,
  MSelect,
  MButton,
  ModalTitle,
  MDatepicker,
  MInputMask,
  MSelectRole,
} from '../../../components/form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Datatable} from '../../../components';
import ImageCropper from '../../../components/cropper';
import {getJobTitleByOrg} from '../../../redux/jobTitle/jobTitle.actions';
import {
  getAllUser,
  getAllManager,
  getAllRole,
  postUser,
  dropUser,
  updateUser,
  clearState,
  getOneManager,
  cancelAdvSync,
  exportExcelUser,
  updateEmployee,
  postNewUser,
} from '../../../redux/userManagement/userManagement.actions';
import {checkStatus, getOrganizationAnalytics, getOrganizationList} from '../../../redux/organization/organization.actions';
import _ from 'lodash';
import {XENDIT_STATE} from '../../../redux/xendit/xendit.types';
import {checkStatus as checkStatusUser} from '../../../redux/userManagement/userManagement.actions';
import moment from 'moment';

const INVOICE_PERIOD = process.env.REACT_APP_API_INVOICE_PERIOD;

class UserList extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      formData: {
        id: null,
        name: null,
        email: null,
        nik: null,
        dob: null,
        phone: null,
        address: null,
        password: null,
        user_img: null,
        imageURL: null,
        manager: '',
        join_date: null,
        role_id: '',
        job_title_id: '',
        organization_id: null,
      },
      isEdit: false,
      resetPage: null,
      resetDatatable: null,
      modalFormShow: false,
      showPassword: false,
      showUpgradeModal: false,
      nameFilter: null,
      nikFilter: null,
      searchFilterManager: null,
      errorMessage: null,
      errorImageSize: null,
      showCropResult: false,
      isCropping: false,
      showMethod: null,
      invoiceUrl: null,
      query: '',
      sortBy: 'id',
      order: 'asc',
      id: null,
      startDate: null,
      status: null,
      activeUser: [],
    };
    this.resetForm = this.state.formData;
  }

  UNSAFE_componentWillMount() {
    this.props.getAllRole();
    this.handleCheckQueryParam();
    this.props.checkStatusUser();
    this.props.isSuperAdmin && this.props.getOrganizationList();
  }

  componentDidMount() {
    const {isSuperAdmin} = this.props;

    if (!isSuperAdmin) this.props.getJobTitleByOrg();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message, data, isSuperAdmin, orgStatusData, userData} = nextProps;
    const {activeUser, status} = this.state;
    if (userData && activeUser.length === 0 && status === 'unpaid') {
      this.setState({
        activeUser: userData.slice(0, 5),
      });
    }
    if (isSuccess) {
      if (trigger === USER_STATE.FETCH_USER_SUCCCESS) {
        this.fetchUser();
      }

      if (trigger === USER_STATE.FETCH_MANAGER_SUCCCESS) {
        this.fetchManager();
      }
    }

    if ((trigger === USER_STATE.CREATE_SUCCESS) || (trigger === USER_STATE.UPDATE_EMPLOYEE_SUCCESS)) {
      this.setState({
        modalFormShow: false,
      });
      this.getEmployeeList();
    }

    if (trigger === USER_STATE.DROP_SUCCESS) {
      this.getEmployeeList();
      toast('success', message);
    }

    if (trigger === USER_STATE.DROP_FAILED) {
      toast('error', message);
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  handleCheckQueryParam = () => {
    const queryParam = this.props.location.search.substring(1);
    if (queryParam) {
      const searchParam = new URLSearchParams(queryParam);
      const id = searchParam.get('id');
      const startDate = searchParam.get('date');
      const status = searchParam.get('status');

      this.setState({
        ...(id && {id}),
        ...(startDate && {startDate}),
        ...(status && {status}),
      },
      () => {
        this.fetchUser();
        this.fetchOrganizationAnalytics();
      });
    } else {
      this.fetchUser();
      this.fetchOrganizationAnalytics();
    }
  }

  fetchOrganizationAnalytics = () => {
    const {id, startDate} = this.state;

    this.props.getOrganizationAnalytics(
      id,
      {
        ...(startDate && {date: startDate}),
      },
      );
  }

  fetchUser = () => {
    const {isSuperAdmin} = this.props;
    const {page, limit, query, sortBy, order, id} = this.state;
    this.props.getAllUser({page, limit, query, sort_by: sortBy, order, org_id: id});
    if (!isSuperAdmin) this.props.checkPremium();
  };

  fetchManager = () => {
    const {page, limit, all, searchFilterManager} = this.state;
    const queryParams = {
      query: searchFilterManager,
      // all: true
    };
    this.props.getAllManager(queryParams);
  }
  handleReload = (params) => {
    this.setState(params, () => this.fetchUser());
  };

  handleReset = () => {
    this.setState({
      formData: {
        id: null,
        name: null,
        email: null,
        nik: null,
        dob: null,
        phone: null,
        address: null,
        password: null,
        user_img: null,
        imageURL: null,
        manager: null,
        join_date: null,
        role_id: '',
        job_title_id: '',
      },
      searchFilter: null,
      modalFormShow: false,
      isEdit: false,
      errorMessage: null,
      errorImageSize: null,
      showCropResult: false,
      isCropping: false,
      id: null,
    });
    // this.toggleResetPage()
    // this.handleReload()
  };

  handleResetFilter = () => {
    this.setState({
      page: 1,
      limit: 10,
      query: '',
      sortBy: 'id',
      order: 'asc',
    }, () => this.fetchUser());
    this.toggleResetPage();
    // this.handleReload()
  };

  handleExport = () => {
    const {page, limit, query, order, sortBy} = this.state;
    this.props.exportExcelUser({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(order && {order}),
      ...(sortBy && {sort_by: sortBy}),
    });
  }

  handleInputChange = (event) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = event.target;
    if (name === 'user_img') {
      const image = event.target.files[0];
      const imageSizeIsAllowed = checkFileUploadSize(image);
      if (imageSizeIsAllowed) {
        this.setState({
          isCropping: true,
          errorImageSize: null,
          errorMessage: {...errorMessage, user_img: null},
          formData: {
            ...formData,
            imageURL: URL.createObjectURL(image),
            user_img: image,
          },
        });
      } else {
        let message;
        if (imageSizeIsAllowed === false) {
          message =
            'Maximum file size is ' +
            process.env.REACT_APP_MAX_UPLOAD_SIZE_MB +
            ' MB';
        }
        if (imageSizeIsAllowed === null) message = 'Please select image';
        this.setState({
          errorImageSize: message,
          imageURL: null,
          user_img: null,
        });
      }
    } else if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value.match(emailRegex)) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            email: 'Email must be a valid email',
          },
        });
      } else {
        this.setState({
          formData: {...formData, [name]: value},
          errorMessage: {
            ...errorMessage,
            email: null,
          },
        });
      }
    } else if (name === 'phone') {
      if ((value.length < 9) || (value.length > 14)) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            phone: 'phone number must be at least 9-14 character',
          },
        });
      } else {
        this.setState({
          formData: {...formData, [name]: value},
          errorMessage: {
            ...errorMessage,
            phone: null,
          },
        });
      }
    } else {
      this.setState({
        formData: {...formData, [name]: value},
        errorMessage: {...errorMessage, [name]: null},
      });
    }
  };

  handleDateChange = (params) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = params;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  handleFormToggle = (type) => {
    const {
      creatable,
      creatableMessage,
      isSuperAdmin,
      orgStatusData,
    } = this.props;
    if (creatable === false && type === 'add') {
      if (orgStatusData.premium && orgStatusData.message_2) {
        showPopup('info', 'Info', orgStatusData.message_2);
      } else {
        showPopup(
          'confirm',
          creatableMessage,
          'Yes, Upgrade',
          () => this.toggleModalUpgrade(),
        );
      }
    } else {
      const tempAdminJobTitle = isSuperAdmin ? 1 : null; // superadmin handler
      this.handleReset();
      this.setState({
        modalFormShow: !this.state.modalFormShow,
        formData: {...this.resetForm, job_title_id: tempAdminJobTitle},
      });
    }
  };

  handleGetRoleInfo = (roleName) => {
    return this.props.roleData.filter((item) => item.name === roleName);
  };

  handleGetJobInfo = (jobType) => {
    const job = this.props.jobData.filter((item) => item.name === jobType);
    return job.length ? job : null;
  };

  handleGetManagerInfo = async (id) => {

  }

  handleEditDialogToggle = async (data) => {
    const {isSuperAdmin} = this.props;
    let job = this.handleGetJobInfo(data[4]);
    const role = this.handleGetRoleInfo(data[8]);
    const manager = await this.props.getOneManager(data[1]);
    if (isSuperAdmin) job = 1; // superadmin handler
    if (!isSuperAdmin) job = job ? job[0].id : null;
    this.handleReset();
    this.setState({
      isEdit: true,
      modalFormShow: !this.state.modalFormShow,
      formData: {
        id: data[1],
        name: data[2],
        nik: data[3],
        role_id: role[0].id,
        job_title_id: job,
        manager: manager,
        join_date: data[10],
        email: data[5],
        dob: data[6] ? convDate(data[6]) : null,
        phone: data[7],
        imageURL: data[11],
      },
    });
  };

  handleConfirmDelete = (data) => {
    showPopup(
        'confirm',
        'Are you sure you want to delete this data ?',
        'Yes',
        () => this.props.dropUser(data[1]),
    );
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearchQuery(value);
  }

  handleTogglePassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  };

  getOrganizationId = () => {
    const queryParam = this.props.location.search.substring(1);
    const searchParam = new URLSearchParams(queryParam);
    const organizationId = searchParam.get('id');

    return parseInt(organizationId);
  }

  getEmployeeList = () => {
    const {page, limit, query, sortBy, order, id} = this.state;
    const orgId = this.getOrganizationId();
    this.props.getAllUser({page, limit, query, sort_by: sortBy, order, org_id: orgId});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {createNewUser, updateEmployee, isSuperAdmin} = this.props;
    const {isEdit} = this.state;
    const {
      id,
      name,
      nik,
      job_title_id,
      email,
      dob,
      phone,
      join_date,
      role_id,
      manager,
      password,
      cropped_user_img,
      organization_id,
    } = this.state.formData;

    const payload = {
      name: name,
      email: email,
      phone: phone,
      role_id: role_id,
      organization_id: this.getOrganizationId(),
    };

    const requiredFields = [
      {
        name: 'name',
        message: 'Full name',
      },
      {
        name: 'email',
        message: 'Email',
      },
      {
        name: 'phone',
        message: 'Phone Number',
      },
      {
        name: 'role_id',
        message: 'Role',
      },
    ];
    const missingFields = requiredFields.filter((field) => !this.state.formData[field.name]);

    missingFields.forEach((field) => {
      this.setState((prevState) => ({
        errorMessage: {
          ...prevState.errorMessage,
          [field.name]: `${field.message} is required`,
        },
      }));
    });

    if ((missingFields.length > 0)) {
      return;
    }

    return !isEdit ? createNewUser(payload) : updateEmployee(id, payload);
  };

  toggleCropperModal = (status) => {
    this.setState({isCropping: status});
  };

  handleCrop = (result) => {
    this.setState({
      formData: {
        ...this.state.formData,
        cropped_user_img: result.blob,
        imageURL: result.imageURL,
      },
    });
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleDebounceSearch = _.debounce((query) => {
    this.setState({searchFilterManager: query},
      () => {
        this.fetchManager();
      });
  }, 500);

  handleDebounceSearchQuery = _.debounce((query) => {
    this.toggleResetPage();
    this.setState(
        {page: null},
        () => this.fetchUser(),
    );
  }, 500);

  selectHandler = (event, value) => {
    if (value) {
      value.manager_id = value.nik;
      value.manager_name = value.name;
    }
    this.setState({
      formData: {
        ...this.state.formData,
        manager: value ? value : null,
      },
    });
  }

  resetManager = () => {
    this.setState({searchFilterManager: null});
  }

  searchCombo = (event = null, value) => {
    this.handleDebounceSearch(event.target.value);
  }

  renderModalForm = () => {
    const {
      classes,
      isLoading,
      isLoadingManager,
      jobData,
      roleData,
      isSuperAdmin,
      roleLoading,
      jobLoading,
      userData,
      managerData,
      organizationData,
    } = this.props;
    const {
      name,
      nik,
      job_title_id,
      email,
      dob,
      join_date,
      phone,
      role_id,
      password,
      imageURL,
      manager,
      user_img,
      organization_id,
    } = this.state.formData;
    const {
      isEdit,
      modalFormShow,
      showPassword,
      errorMessage,
      errorImageSize,
      showCropResult,
      isCropping,
    } = this.state;
    const titleDialog = isEdit ? 'Update User' : 'Create User';
    const ManagerData = [];
    userData.map((value) => {
      return ManagerData.push(value.id);
    });
    const manager_id = userData.filter((value) => {
      return value.nik === manager;
    });
    const optionEdit = managerData.filter((value) => {
      return value.name !== name;
    });
    const OrganizationOption = [];

    organizationData.map((value) => {
      OrganizationOption.push({id: value.id, name: value.name, isDisabled: false});
    });

    const RoleOption = [];

    roleData.map((value) => {
      if (value.name !== 'Partner' && value.name !== 'Super Admin') {
        RoleOption.push({id: value.id, name: value.name, isDisabled: false});
      }
    });

    return (
      <Dialog
        open={modalFormShow}
        onClose={() => this.handleFormToggle('close')}
      >
        <ModalTitle
          title={titleDialog}
          onClose={() => this.handleFormToggle('close')}
        />
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />
            <MInput
              classNameFC={classes.formControl}
              name="name"
              label="Full Name"
              placeholder="Full Name"
              defaultValue={name}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.name ?
                  errorMessage.name :
                  undefined
              }
              autoFocus
            />
            {!isSuperAdmin ? (
              <MSelect
                classNameFC={classes.formControl}
                name="job_title_id"
                value={job_title_id}
                label="Job Title"
                onChange={this.handleInputChange}
                error={
                  errorMessage && errorMessage.job_title_id ?
                    errorMessage.job_title_id :
                    undefined
                }
                keyPair={['id', 'name']}
                options={jobData ? jobData : []}
                loading={jobLoading}
                disabled
              />
            ) : (
              ''
            )}
            {!isSuperAdmin ? (

              <Autocomplete
                className={classes.formControl}
                id="free-solo-demo"
                freeSolo
                options={isEdit ? optionEdit : managerData}
                value={manager ? {nik: manager.manager_id, name: manager.manager_name} : ''}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => this.selectHandler(event, value)}
                style={{position: 'relative'}}
                disabled
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      label="Manager"
                      variant="outlined"
                      defaultValue={'manager'}
                      fullWidth
                      onChange={(event, value) => this.searchCombo(event, value)}
                      // onClick={() => this.searchCombo()}
                    />
                    {isLoadingManager ? <CircularProgress color="inherit" size={24} style={{position: 'absolute', right: '35px', top: '15px'}}/> : null}
                  </>
                )}
              />
              ) : ''
            }
            <MInput
              classNameFC={classes.formControl}
              name="email"
              label="Email Address"
              placeholder="Email"
              type="email"
              defaultValue={email}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.email ?
                  errorMessage.email.split(':')[0] :
                  undefined
              }
              autoComplete="new-email"
            />
            <MInputMask
              classNameFC={classes.formControl}
              name="phone"
              label="Phone Number *"
              placeholder="Phone"
              type="number"
              defaultValue={phone}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.phone ?
                  errorMessage.phone.split(':')[0] :
                  undefined
              }
            />
            {isSuperAdmin ? (
            <MSelect
              classNameFC={classes.formControl}
              name="role_id"
              label="Role"
              value={role_id}
              onChange={this.handleInputChange}
              error={
                errorMessage && errorMessage.role_id ?
                  errorMessage.role_id :
                  undefined
              }
              keyPair={['id', 'name']}
              options={RoleOption ?? []}
              loading={roleLoading}
            />) : null }
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              label="Close"
              onClick={() => this.handleFormToggle('close')}
            />
            <MButton
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              loading={isLoading ? true : false}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  renderFilter = () => {
    const {classes, attendanceTypes, exportLoading, isSuperAdmin, analyticsOrg} = this.props;
    const {query, startDate} = this.state;
    const date = startDate && moment(startDate).format('DD MMM YYYY');
    return (
      <Paper variant='outlined' className={classes.paper}>
        <Grid container spacing={2} alignItems='center' direction='row' wrap='nowrap'>
          <Grid item>
            <MInput
              autoFocus
              className={`${classes.searchInput}`}
              fullWidth={false}
              variant="standard"
              name="query"
              label="Search"
              placeholder="Search keyword"
              value={query}
              onChange={this.handleSearch}
              InputProps={{endAdornment: <IconButton className={classes.searchIcon} position="end"> <Search/></IconButton>}}
            />
          </Grid>
          <Grid item>
            <MButton
              className={classes.filterBtn}
              label="Reset"
              color="primary"
              icon={<Refresh/>}
              onClick={this.handleResetFilter}
            />
          </Grid>
          {
            !isSuperAdmin && (
              <Grid item>
                  <MButton
                    className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                    label="Export"
                    icon={<GetApp/>}
                    onClick={this.handleExport}
                    loading={exportLoading}
                  />
              </Grid>
            )
          }
          {
            isSuperAdmin && (
              <Grid item xs={8} container justify='flex-end'>
                <Typography style={{fontWeight: 700, marginTop: 20}}>{'Active Users: '}{analyticsOrg && analyticsOrg.total_user ? analyticsOrg.total_user : '0' }{' / '}{analyticsOrg && analyticsOrg?.quota_users ? analyticsOrg?.quota_users : '0' }</Typography>
              </Grid>
            )
          }
        </Grid>
      </Paper>
    );
  }

  render() {
    const {classes, isLoading, userData, userMeta, isLoadingOrg} = this.props;
    const displayJobTitle = this.props.isSuperAdmin ? false : true;
    const {resetDatatable, resetPage, status, sortBy, order, activeUser} = this.state;

    const activeUserId = activeUser.length > 0 ? activeUser.map((val) => val.id) : [];
    const columns = [
      {name: 'no', label: 'No', display: true},
      {name: 'id', label: 'id', display: false},
      {name: 'employees.name', label: 'Name', display: true},
      {name: 'employees.employee_no', label: 'NIK', display: false},
      {
        name: 'employees.job_titles.name',
        label: 'Job Title',
        display: displayJobTitle,
      },
      {name: 'employees.email', label: 'Email', display: true},
      {name: 'employees.dob', label: 'Date of Birth', display: false},
      {name: 'employees.phone', label: 'Phone', display: true},
      {name: 'roles.name', label: 'Role', display: true},
      {
        name: 'employees.status',
        label: 'Employment Status',
        display: true,
        customBodyRender: (value) => {
          const status = value === 'active' ? 'Active' : 'Inactive';
          const color = value === 'active' ? '#008000' : '#dc3545';

          return (
            <Typography style={{color: color, fontSize: '14px', fontWeight: 'bold'}}>
              {status}
            </Typography>
          );
        },
      },
      {
        name: 'employees.manager.name',
        label: 'Manager',
        display: false,
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
      {
        name: 'employees.join_date',
        label: 'Join Date',
        display: false,
        customBodyRender: (value) => {
          return value ? convDate(value, 'D MMMM YYYY') : '-';
        },
      },
      {name: 'employees.image', label: 'Photo', display: false},
      {name: 'logs.log', label: 'Log', display: false},
      {
        name: 'last_activity',
        label: 'Last Activity',
        display: false,
        customBodyRender: (value, rowData) => {
          const color =
            activeUserId.length === 0 ?
              '#28a745' :
              activeUserId.includes(rowData.rowData[1]) ?
              '#28a745' :
              '#dc3545';

          return value ? (
            <Typography style={{color: color, fontSize: '14px'}}>
              {convDate(value, 'D MMMM YYYY')}
            </Typography>
          ) : (
            '-'
          );
        },
      },
    ];
    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Datatable
              title={'Employee List'}
              loading={isLoading || isLoadingOrg}
              dataSource={userData}
              total={userMeta ? userMeta.total : 0}
              page={userMeta ? userMeta.page : 1}
              columns={columns}
              handleReload={(params) => this.handleReload(params)}
              handleCreate={() => this.handleFormToggle('add')}
              handleEdit={(data) => this.handleEditDialogToggle(data)}
              handleDelete={(data) => this.handleConfirmDelete(data)}
              toggleResetPage={resetPage}
              manualNumbering={true}
              creatable={true}
              defOrder={'asc'}
              defSortBy={'id'}
            />
          </Grid>
        </Grid>
        {this.renderModalForm()}
      </div>
    );
  }
}

const stateToProps = ({userManagement, jobTitle, upgradeAccount, auth, organization, xendit, generalSetting}) => ({
  token: auth.data.authorization,
  isSuperAdmin: checkSuperadmin(auth),
  isLoading: userManagement.isLoading,
  isLoadingManager: userManagement.isLoadingManager,
  isError: userManagement.isError,
  isSuccess: userManagement.isSuccess,
  message: userManagement.message,
  trigger: userManagement.trigger,
  premium: upgradeAccount.premium,
  creatable: upgradeAccount.creatable,
  creatableMessage: upgradeAccount.creatableMessage,
  userData: userManagement.userData,
  managerData: userManagement.managerData,
  userMeta: userManagement.userMeta,
  roleLoading: userManagement.roleLoading,
  roleData: userManagement.roleData,
  jobLoading: jobTitle.jobLoading,
  jobData: jobTitle.jobDataByOrg,
  orgStatusData: organization.orgStatusData,
  data: generalSetting.data,
  isLoadingOrg: upgradeAccount.isLoading,
  menusData: auth.data.menus,
  analyticsOrg: organization.analyticsOrg,
  organizationData: organization.organizationData,
});

const dispatchToProps = (dispatch) => ({
  getAllUser: (params) => dispatch(getAllUser(params)),
  getAllManager: (params) => dispatch(getAllManager(params)),
  getAllRole: () => dispatch(getAllRole()),
  createUser: (data) => dispatch(postUser(data)),
  createNewUser: (data) => dispatch(postNewUser(data)),
  getOneManager: (id) => dispatch(getOneManager(id)),
  updateUser: (id, data) => dispatch(updateUser(id, data)),
  updateEmployee: (id, data) => dispatch(updateEmployee(id, data)),
  dropUser: (id) => dispatch(dropUser(id)),
  checkPremium: () => dispatch(checkPremium()),
  clearState: () => dispatch(clearState()),
  getJobTitleByOrg: (params) => dispatch(getJobTitleByOrg(params)),
  checkStatus: () => dispatch(checkStatus()),
  cancelAdvSync: () => dispatch(cancelAdvSync()),
  exportExcelUser: (params) => dispatch(exportExcelUser(params)),
  checkStatusUser: () => dispatch(checkStatusUser()),
  getOrganizationAnalytics: (id, params) => dispatch(getOrganizationAnalytics(id, params)),
  getOrganizationList: (params) => dispatch(getOrganizationList(params)),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(UserList));

/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  Paper,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import moment from 'moment';
import {Send} from '@material-ui/icons';
import styles from './styles';
import {MButton} from '../../../components/form';
import {toast, isArray, isObject, checkSuperadmin} from '../../../helpers';
import {GENERAL_SETTING_STATE as STATE} from '../../../redux/generalSetting/generalSetting.types';
import {
  getGeneralSetting,
  updateGeneralSetting,
} from './../../../redux/generalSetting/generalSetting.actions';
import {checkStatus as checkStatusUser} from '../../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../../redux/organization/organization.actions';
import _ from 'lodash';

class AdvantechSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adv_radio: null,
      formData: {
        advantech: {value: null},
      },
      formDataUpdate: {
        advantech: {value: null},
      },
      settings: [],
      message: false,
      showModalJobTitle: false,
      showModalGroupEmail: false,
      errorMessage: null,
    };
  }

  getMuiTheme = () => createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
  })

  UNSAFE_componentWillMount() {
    this.props.getGeneralSetting();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {message, trigger, data, jobTrigger, jobMessage, isError, menusData, triggerUser} = nextProps;

    if (trigger === STATE.UPDATE_SUCCESS) {
      toast('success', message);
      this.props.getGeneralSetting();
      this.props.checkStatus();
    }

    if (trigger === STATE.FETCH_ALL_SUCCCESS) {
      const filtered = this.filterSetting(data);
      const advantech = filtered && filtered[0] ? filtered[0] : null;
      // if (checkInSetting && checkInSetting.value) checkInSetting.value = moment(checkInSetting.value, 'hh:mm');
      this.setState({
        settings: filtered,
        adv_radio: advantech.value ? 'true' : 'false',
        formData: {
          advantech: advantech,
        },
        formDataUpdate: {
          advantech: advantech,
        },
      });
    }

    if (this.props.trigger !== trigger && trigger === STATE.UPDATE_FAILED && !isObject(message)) {
      toast('error', message);
      this.props.getGeneralSetting();
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  filterSetting = (data) => {
    const filter = (data, key) => {
      const result = data.filter((value) => value.key === key);
      return isArray(result) ? result[0] : null;
    };
    const advantech = filter(data, 'advantech');

    return [advantech];
  }

  handleSubmit = () => {
    const {formDataUpdate} = this.state;
    const {menusData} = this.props;
    const converted = [];
    let newVal;

    if (!menusData.shift) {
      const newFormDataUpdate = [
        formDataUpdate.advantech,
      ];

      const newDataUpdate = newFormDataUpdate.filter((values) => {
        return values !==null;
      });
      newDataUpdate.map((val, key) => {
        if (val.key === 'advantech') {
          newVal = val.value.toString();
        }

        converted.push({
          key: val.key,
          label: val.label,
          value: newVal,
          group: val.group,
        });
      });
    } else {
      const newFormDataUpdate = [
        formDataUpdate.advantech,
      ];

      const newDataUpdate = newFormDataUpdate.filter((values) => {
        return values !==null;
      });
      newDataUpdate.map((val, key) => {
        if (val.key === 'advantech') {
          newVal = val.value.toString();
        }

        converted.push({
          key: val.key,
          label: val.label,
          value: newVal,
          group: val.group,
        });
      });
    }


    const params = {data: converted};
    this.props.updateGeneralSetting(params);
  }

  handleRadioChange = (event) => {
    const {value, name} = event.target;
    const button = name === 'advantech' ? 'adv_radio' : name === 'leaderboard' ? 'leaderboard_radio' : name === 'payslip' ? 'payslip_radio' : null;
    this.setState({
      [button]: value,
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          value,
        },
      },
      formDataUpdate: {
        ...this.state.formDataUpdate,
        [name]: {
          ...this.state.formDataUpdate[name],
          value,
        },
      },
    });
  };

  renderForm = (settingArray) => {
    const {classes, menusData} = this.props;
    const {adv_radio} = this.state;
    const {job_title, leaderboard_job_title} = this.state.formData;
    const settingArr = _.remove(settingArray, {id: undefined});
    const result = settingArray.map((setting) => {
      if (setting) {
        const {id, key, label} = setting;
        if (!menusData.shift) {
          if (key === 'advantech' && menusData.advantech) {
            return (
              <div key={id}>
                <h5 className={classes.subTitle}>Advantech</h5>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                <Grid container>
                  <Grid xs={2}>
                    <RadioGroup aria-label={key} name={key} value={adv_radio} onChange={this.handleRadioChange}>
                      <FormControlLabel value={'true'} control={<Radio />} label="On" />
                      <FormControlLabel value={'false'} control={<Radio />} label="Off" />
                    </RadioGroup>
                  </Grid>
                </Grid>
                </MuiThemeProvider>
              </div>
            );
          }
        } else {
          if (key === 'advantech' && menusData.advantech) {
            return (
              <div key={id}>
                <h5 className={classes.subTitle}>Advantech</h5>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                <Grid container>
                  <Grid xs={2}>
                    <RadioGroup aria-label={'Advantech'} name={key} value={adv_radio} onChange={this.handleRadioChange}>
                      <FormControlLabel value={'true'} control={<Radio />} label="On" />
                      <FormControlLabel value={'false'} control={<Radio />} label="Off" />
                    </RadioGroup>
                  </Grid>
                </Grid>
                </MuiThemeProvider>
              </div>
            );
          }
        }
        // else if (key === 'group_email') {
        //   const {formData} = this.state;
        //   const {group_email} = formData;
        //   const email_values = group_email.value.length > 0 ? group_email.value.map((val) => {
        //     return val.email;
        //   }) : [];
        //   return (
        //     <div key={id}>
        //       <h5>Group Emails</h5>
        //       <Grid container>
        //         <Grid item xs={10}>
        //           <SelectMultiple
        //             className={classes.formControl}
        //             label={label}
        //             name={key}
        //             placeholder="Select emails for your company"
        //             keyPair={['email', 'email']}
        //             data={group_email.value}
        //             value={email_values}
        //             onChange={(event) => {
        //               const newValue = [];
        //               if (event.length > 0) {
        //                 event.map((val) => {
        //                   return newValue.push({email: val});
        //                 });
        //               }
        //               this.setState({
        //                 formData: {
        //                   ...this.state.formData,
        //                   group_email: {
        //                     ...this.state.formData.group_email,
        //                     value: newValue,
        //                   },
        //                 },
        //                 formDataUpdate: {
        //                   ...this.state.formDataUpdate,
        //                   group_email: {
        //                     ...this.state.formDataUpdate.group_email,
        //                     value: newValue,
        //                   },
        //                 },
        //               });
        //             }}
        //           />
        //         </Grid>
        //         <Grid item xs={2} className={classes.btnAddContainer}>
        //           <MButton
        //             className={classes.btnAdd}
        //             label="Add New"
        //             color="primary"
        //             size='small'
        //             icon={<Add />}
        //             loading={jobLoading}
        //             onClick={this.toggleModalGroupEmail}
        //           />
        //         </Grid>
        //         {this.renderModalGroupEmail()}
        //       </Grid>
        //     </div>
        //   );
        // } else if (key === 'notification') {
        //   const dataNotif = this.state.formData.notification.value;
        //   return (
        //     <div key={id}>
        //       <h5 className={classes.subTitle}>Notification</h5>
        //       <Grid container>
        //       {dataNotif.map((valNotif) => {
        //         const checkedNotifPush = valNotif.push === 'true' ? true : valNotif.push === 'false' ? false : valNotif.push;
        //         const checkedNotifEmail = valNotif.email === 'true' ? true : valNotif.email === 'false' ? false : valNotif.email;
        //         return (
        //             <Grid xs={4} spacing={2}>
        //               <MCheckboxGroup
        //                 label={`Feature ${valNotif.feature.replace(/\b\w/g, (c) => c.toUpperCase())}`}
        //               >
        //                 <MCheckboxItem
        //                   name={`push_${valNotif.feature}`}
        //                   label={`Push`}
        //                   checked={checkedNotifPush}
        //                   onChange={this.handleCheckBoxNotif}
        //                 />
        //                 <MCheckboxItem
        //                   name={`email_${valNotif.feature}`}
        //                   label={`Email`}
        //                   checked={checkedNotifEmail}
        //                   onChange={this.handleCheckBoxNotif}
        //                 />
        //               </MCheckboxGroup>
        //             </Grid>
        //         );
        //       })}
        //       </Grid>
        //     </div>
        //   );
        // }
      }
    });
    return result;
  }

  render() {
    const {classes, data, isLoading} = this.props;
    const {settings} = this.state;
    return (
      <div className={classes.root}>
        <Paper variant='outlined' className={classes.paper}>
          <form>
            {
              settings.length > 0 ?
              this.renderForm(settings) : ''
            }
            <div className={classes.btnContainer}>
              <MButton
                className={classes.defaultBtn}
                label="Update Setting"
                icon={<Send/>}
                onClick={() => this.handleSubmit()}
                loading={isLoading && data.length > 0 ? true : false}
              />
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = ({auth, generalSetting, jobTitle, userManagement}) => {
  return ({
    isSuperAdmin: checkSuperadmin(auth),
    organization: auth.data.organization,
    data: generalSetting.data,
    isLoading: generalSetting.isLoading,
    isSuccess: generalSetting.isSuccess,
    isError: generalSetting.isError,
    menusData: auth.data.menus,
    message: generalSetting.message,
    trigger: generalSetting.trigger,
  });
};

const mapDispatchToProps = (dispatch) => ({
  getGeneralSetting: (key) => dispatch(getGeneralSetting(key)),
  updateGeneralSetting: (params) => dispatch(updateGeneralSetting(params)),
  checkStatus: (params) => dispatch(checkStatus(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdvantechSettings));

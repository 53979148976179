import {ENDPOINTS, apiService} from '../config';

export const viaXendit = (params) => {
  return apiService.post(
      ENDPOINTS.XENDIT.GET_TOKEN,
      params,
  );
};

export const checkXenditStatus = (params) => {
  return apiService.post(
      ENDPOINTS.XENDIT.CHECK_TOKEN,
      params,
  );
};

/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {
  Button,
  Grid,
  Avatar,
  Divider,
  Paper,
  CardMedia,
  Typography,
  Link,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {
  CheckCircleOutline,
  Search,
  SaveAlt,
  Refresh,
  Send,
  Cancel,
} from '@material-ui/icons';
import {toast, isObject, convDate} from '../../helpers';
import {MInput, MKeyBoardDatepicker, MButton, ModalTitle, MSelect, MKeyboardTimePicker, MDatepicker} from '../../components/form';
import {Datatable} from '../../components';
import _ from 'lodash';
import moment from 'moment';
import {FRAUD_STATE as STATE} from '../../redux/fraudDetection/fraud.types';
import {
  getFraudDetection,
  getFraudDetail,
  putChangeFraudStatus,
} from './../../redux/fraudDetection/fraud.actions';
import {checkStatus} from './../../redux/userManagement/userManagement.actions';

class FraudDetection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
      method: 'all',
      is_edited: false,
      completed: null,
      showDetail: false,
      resetDatatable: null,
      detailData: null,
      resetPage: null,
      formData: {
        date: null,
        todo: null,
        check_in: null,
        check_out: null,
        status: '',
        reason: '',
      },
      errorMessage: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.checkStatus(); // check company feature status availability {remote, business, office}
    this.fetchFraud();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isError,
      message,
      isLoadingPost,
      isSuccessPost,
      status,
      trigger,
    } = nextProps;

    if (isError && message && !isObject(message)) toast('error', message);

    if (trigger === STATE.CHANGE_STATUS_FRAUD_SUCCESS && isSuccessPost) {
      toast('success', 'Successfully Update Fraud Data');
      this.handleResetForm(true);
      this.fetchFraud();
      this.props.checkStatus();
    }
  }

  componentDidMount() {
    this.handleCheckQueryParam();
  }

  fetchFraud = () => {
    const {page, limit, startDate, endDate, query, sortBy, order, method, completed, is_edited} = this.state;
    this.props.getFraudDetection({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(method && method !== 'all' && {method}),
      ...(is_edited && is_edited !== false && {is_edited}),
      ...(completed !== null && completed !== undefined && {completed}),
    });
  }

  handleCheckQueryParam = () => {
    const queryParam = this.props.location.search.substring(1);
    if (queryParam) {
      const searchParam = new URLSearchParams(queryParam);
      const range = searchParam.get('range');
      const method = searchParam.get('method');
      const is_edited = searchParam.get('is_edited');
      const completed = searchParam.get('completed');
      let startDate;
      let endDate;

      switch (range) {
        case 'today':
          startDate = moment();
          endDate = moment();
          break;
        case 'yesterday':
          startDate = moment().add(-1, 'days');
          endDate = moment().add(-1, 'days');
          break;
        case 'lastWeek':
          // startDate = moment().subtract(1, 'weeks').startOf('isoWeek'); // last week (mon - sun)
          // endDate = moment().subtract(1, 'weeks').endOf('isoWeek');

          startDate = moment().add(-7, 'days'); // last 7 days
          endDate = moment().add(-1, 'days');
          break;
        case 'lastMonth':
          startDate = moment().subtract(1, 'months').startOf('month');
          endDate = moment().subtract(1, 'months').endOf('month');
          break;
        default:
          break;
      }

      this.setState({
        startDate,
        endDate,
        ...(completed && {completed}),
        ...(method && {method}),
        ...(is_edited && {is_edited}),
      },
      () => {
        this.fetchFraud();
      });
    } else {
      this.fetchFraud();
    }
  }

  toggleModal = (type, data) => {
    const {fraudData} = this.props;
    if (type === 'detail') {
      const numberId = data.rowData[0]; // get detail fraud from datatable response, based on index
      const selected = fraudData.filter((data) => data.no === numberId);
      const detailData = selected && selected.length ? selected[0] : null;
      const check_in = detailData.check_in ? detailData.check_in.split(':') : null;
      const check_out = detailData.check_out ? detailData.check_out.split(':') : null;
      this.setState({
        formData: {
          date: new Date(detailData.date),
          check_in: check_in ? check_in[0]+':'+check_in[1] : null,
          check_out: check_out ? check_out[0]+':'+check_out[1] : null,
          status: detailData.status,
          reason: detailData.rejected_reason,
        },
        showDetail: true,
        detailData,
        errorMessage: {},
        selectedStatus: detailData.status,
      });
      this.setState({});
    } else if (type === 'close') {
      this.setState({showDetail: false, detailData: null});
    }
  };

  toggleResetDatatable = (status) => {
    this.setState(
        {resetDatatable: status},
        () => this.setState({resetDatatable: null}),
    );
  }

  toggleResetPage = () => {
    this.setState(
        {resetPage: true},
        () => this.setState({resetPage: false}),
    );
  }

  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
      method: 'all',
      is_edited: false,
      complete: null,
      detailData: null,
    };
    this.setState(state, () => this.fetchFraud());
    this.toggleResetDatatable(true);
  }

  handleReload = (params) => {
    this.setState(
        params,
        () => this.fetchFraud(),
    );
  }

  handleExport = () => {
    const {page, limit, startDate, endDate, query, sortBy, order, method, completed} = this.state;
    this.props.exportExcel({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(method && method !== 'all' && {method}),
      ...(completed !== null && completed !== undefined && {completed}),
      status: 'approved',
    });
  }

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState(
        {[name]: value, page: null},
        () => this.fetchFraud(),
    );
  }

  handleStatusChange = (event) => {
    const {value, name} = event.target;
    this.setState(
        {[name]: value, page: null},
        () => this.fetchFraud(),
    );
  }

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState(
        {[name]: value, page: null},
        () => this.fetchFraud(),
    );
  }

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  }

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState(
        {page: null},
        () => this.fetchFraud(),
    );
  }, 500);

  renderFilter = () => {
    const {classes, attendanceTypes, message, isSuccess} = this.props;
    const {query, startDate, endDate, method, is_edited} = this.state;
    const renderMethodOptions = () => {
      const options = [
        {id: 'all', name: 'All'},
      ];
      if (attendanceTypes && attendanceTypes.remote) options.push({id: 'remote', name: 'Remote Working'});
      if (attendanceTypes && attendanceTypes.office) options.push({id: 'office', name: 'Office Working'});
      if (attendanceTypes && attendanceTypes.business) options.push({id: 'business', name: 'Business Trip'});
      if (attendanceTypes && attendanceTypes.overtime) options.push({id: 'overtime', name: 'Overtime'});
      return options;
    };
    const renderStatusOptions = () => {
      const options = [
        {id: true, name: 'Fixed'},
        {id: false, name: 'Fraud'},
      ];
      return options;
    };

    return (
      <Paper className={classes.paper}>
        <MInput
          autoFocus
          className={`${classes.filter} ${classes.searchInput}`}
          fullWidth={false}
          variant="standard"
          name="query"
          label="Search"
          placeholder="Search keyword"
          value={query}
          onChange={this.handleSearch}
          InputProps={{endAdornment: <IconButton className={classes.searchIcon} position="end"> <Search/></IconButton>}}
        />
        <MSelect
          shrink={true}
          fullWidth={false}
          classNameFC={`${classes.formControl} ${classes.filter}`}
          variant="standard"
          name="method"
          label="Type"
          keyPair={['id', 'name']}
          options={renderMethodOptions()}
          value={method}
          onChange={this.handleFilterChange}
        />
        <MSelect
          shrink={true}
          fullWidth={false}
          classNameFC={`${classes.formControl} ${classes.filter}`}
          variant="standard"
          name="is_edited"
          label="Status"
          keyPair={['id', 'name']}
          options={renderStatusOptions()}
          value={is_edited}
          onChange={this.handleStatusChange}
        />
        <MKeyBoardDatepicker
          className={classes.filterDatePicker}
          name="startDate"
          label="Start Date"
          value={startDate}
          onDateChange={this.handleFilterDateChange}
          placeholder="dd-mm-yyyy"
          maxDate={endDate ? new Date(endDate) : undefined}
          error={message && message.start_date ? message.start_date : undefined}
        />
        <MKeyBoardDatepicker
          className={classes.filterDatePicker}
          name="endDate"
          label="End Date"
          value={endDate}
          onDateChange={this.handleFilterDateChange}
          placeholder="dd-mm-yyyy"
          minDate={new Date(startDate)}
          error={message && message.end_date ? message.end_date : undefined}
        />
        <div className={classes.btnFilterContainer}>
          <MButton
            className={classes.filterBtn}
            label="Reset"
            color="primary"
            icon={<Refresh/>}
            onClick={this.handleReset}
          />
        </div>
      </Paper>
    );
  }

  handleCheckInChange = (params) => {
    const {value} = params;
    const selectedTime = moment(value).format('HH:mm');
    const {formData, errorMessage} = this.state;
    const {check_out} = formData;
    if (selectedTime > check_out) {
      this.setState({
        formData: {
          ...this.state.formData,
          check_in: selectedTime,
          check_out: selectedTime,
        },
        errorMessage: errorMessage,
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          check_in: selectedTime,
        },
        errorMessage: errorMessage,
      });
    }
  }

  handleDateChange = (params) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = params;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  handleCheckOutChange = (params) => {
    const {value} = params;
    const selectedTime = moment(value).format('HH:mm');
    const {formData, errorMessage} = this.state;
    const {check_in} = formData;
    if (selectedTime <= check_in) {
      this.setState({
        formData: {
          ...this.state.formData,
          check_in: selectedTime,
          check_out: selectedTime,
        },
        errorMessage: errorMessage,
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          check_out: selectedTime,
        },
        errorMessage: errorMessage,
      });
    }
  }

  handleResetForm = (closeModal) => {
    this.setState({
      errorMessage: null,
      formData: {
        date: null,
        todo: null,
        check_in: null,
        check_out: null,
        status: '',
        reason: '',
      },
    });
    if (closeModal) this.setState({showDetail: false});
  };

  changeFraudStatus = () => {
    const {formData, detailData, showReject, showDetail, selectedStatus} = this.state;
    const {date, check_in, check_out, reason} = formData;
    const {check_in_id, check_out_id} = detailData;
    const errors = {};
    const params = {};
    if (
      showDetail &&
      (
        // !date ||
        (!check_in) ||
        moment(check_in, 'HH:mm') > moment(check_out, 'HH:mm') ||
        moment(check_out, 'HH:mm') < moment(check_in, 'HH:mm')
      )
    ) {
      // if (!date) errors.date = 'Date is required';
      if (!check_in) errors.check_in = 'Check in time is required';
      if (moment(check_in, 'HH:mm') > moment(check_out, 'HH:mm')) errors.check_in = 'Check in time must be lower then check out time';
      // if (!check_out) errors.check_out = 'Check out time is required';
      if (moment(check_out, 'HH:mm') < moment(check_in, 'HH:mm')) errors.check_out = 'Check out time must be greater then check in time';
      this.setState({errorMessage: errors});
    } else {
      params.check_in_id = check_in_id;
      params.check_out_id = check_out_id ? check_out_id : null;
      params.check_in_time = showDetail ? check_in : detailData.check_in_time;
      params.check_out_time = showDetail ? check_out : detailData.check_out_time ? detailData.check_out_time : null;
      // params.date = showDetail ? date ? moment(date).format('YYYY-MM-DD') : null : detailData.date ? moment(detailData.date).format('YYYY-MM-DD') : null;
      this.setState({errorMessage: {}});
      this.props.putChangeFraudStatus(params);
    }
  };

  renderModalDetail = () => {
    const {classes, isLoadingPost} = this.props;
    const {showDetail, detailData, errorMessage, formData} = this.state;
    const name = detailData && detailData.name ? detailData.name : '-';
    const date =
      detailData && detailData.date ?
        convDate(detailData.date, 'D MMMM YYYY') :
        '-';
    const createdIn =
      detailData && detailData.check_in_created_at ? detailData.check_in_created_at : '-';
    const diffIn =
      detailData && detailData.check_in_diff ? detailData.check_in_diff : '-';
    const checkInImg =
      detailData && detailData.check_in_img ? (
        detailData.check_in_img
      ) : (
        <Avatar alt="user avatar" />
      );
    const createdOut =
      detailData && detailData.check_out_created_at ? detailData.check_out_created_at : '-';
    const diffOut =
      detailData && detailData.check_out_created_at ? detailData.check_out_diff : '-';
    const checkOutImg =
      detailData && detailData.check_out_img ? (
        detailData.check_out_img
      ) : (
        <Avatar alt="user avatar" />
      );
    const checkInAddress =
      detailData && detailData.check_in_address ?
        detailData.check_in_address :
        '-';
    const checkOutAddress =
      detailData && detailData.check_out_address ?
        detailData.check_out_address :
        '-';
    const todo = detailData && detailData.todo ? detailData.todo : '-';
    const day = detailData && detailData.day ? detailData.day : '-';

    const method = detailData && detailData.method ? detailData.method : null;
    const columns = ['Check In', 'Address', 'Todo', 'Photo'];

    const approvedBy =
      detailData && detailData.approved_by ? detailData.approved_by : '-';
    const rejected_reason =
      detailData && detailData.rejected_reason ?
        detailData.rejected_reason :
        '';

    const pic_name = detailData && detailData.approve ? detailData.approve : '-';
    const status = detailData && detailData.status ? detailData.status : null;
    const id =
      detailData && detailData.check_out_id ? detailData.check_out_id : null;
    const options = [
      {id: 'rejected', name: 'Rejected'},
      {id: 'approved', name: 'Approved'},
    ];
    return (
      <Dialog
        open={showDetail}
        onClose={() => this.toggleModal('close')}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Fraud Detail"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={2}>
              <Typography>Name </Typography>
            </Grid>
            <Grid item>
              <Typography> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography>{name}</Typography>
            </Grid>
          </Grid>
          <div>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs={2}>
                <Typography>Address Checkin</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{checkInAddress}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2} mb={5}>
              <Grid item xs={2}>
                <Typography>Address Checkout</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{checkOutAddress}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2} mb={5}>
              <Grid item xs={2}>
                <Typography>PIC</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{pic_name}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2} mb={5}>
              <Grid item xs={2}>
                <Typography>Date</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{date}</Typography>
              </Grid>
            </Grid>
            {/* <Grid container wrap="nowrap" spacing={2}>
              <MDatepicker
                className={classes.formControl}
                name="date"
                label="Date"
                value={convDate(formData.date)}
                onDateChange={this.handleDateChange}
                error={
                  errorMessage && errorMessage.date ? errorMessage.date : undefined
                }
                placeholder="dd-mm-yyyy"
                maxDate={new Date()}
                inputVariant="outlined"
                disableFuture
                views={['year', 'month', 'date']}
                fullWidth
              />
            </Grid> */}
          </div>
          <br />
          <Divider />
          <Grid container spacing={3} align="center">
            <Grid item xs={6}>
              <Typography className={classes.modalItemSpacing}>
                Check In Differences : {diffIn}
              </Typography>
              <Typography className={classes.modalItemSpacing}>
                Created at : {createdIn}
              </Typography>
              <Link href={checkInImg} target="_blank" rel="noopener noreferrer">
                <CardMedia
                  className={[classes.modalAvatar, classes.imgSize]}
                  alt="check in picture"
                  image={checkInImg}
                  title="Check In"
                  component="image"
                />
              </Link>
              <br />
              <Grid container wrap="nowrap" spacing={2} align="center">
                <Grid item xs={12}>
                  <MKeyboardTimePicker
                    inputVariant="outlined"
                    className={classes.formControl}
                    label={'Check In'}
                    name={'check_in'}
                    value={moment(formData.check_in, 'HH:mm')}
                    onTimeChange={(event) => this.handleCheckInChange(event)}
                    ampm={false}
                    error={
                      errorMessage && errorMessage.check_in ?
                        errorMessage.check_in :
                        undefined
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.modalItemSpacing}>
                Check Out Differences : {diffOut}
              </Typography>
              <Typography className={classes.modalItemSpacing}>
                Created at : {createdOut}
              </Typography>
              <Link
                href={checkOutImg}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardMedia
                  className={[classes.modalAvatar, classes.imgSize]}
                  alt="check out picture"
                  image={checkOutImg}
                  title="Check Out"
                  component="image"
                />
              </Link>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MKeyboardTimePicker
                    inputVariant="outlined"
                    className={classes.formControl}
                    label={'Check Out'}
                    name={'check_out'}
                    value={moment(formData.check_out, 'HH:mm')}
                    onTimeChange={(event) => this.handleCheckOutChange(event)}
                    ampm={false}
                    error={
                      errorMessage && errorMessage.check_out ?
                        errorMessage.check_out :
                        undefined
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </DialogContent>
        <DialogActions>
          <MButton
            label="Cancel"
            onClick={() => this.toggleModal('close')}
          />
          <MButton
            className={classes.defaultBtn}
            label="Update"
            icon={<Send />}
            onClick={() => this.changeFraudStatus()}
            loading={isLoadingPost}
          />
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    const {classes, fraudData, fraudMeta, isLoading} = this.props;
    const {resetDatatable, resetPage} = this.state;
    const columns = [
      {name: 'no', label: 'No', display: true},
      {name: 'name', label: 'Name', display: true, customBodyRender: (value) => value ? value : '-'},
      {
        name: 'method',
        label: 'Type',
        display: true,
        customBodyRender: (value) => {
          if (value === 'business') value = 'Business Trip';
          if (value === 'office') value = 'Office Working';
          if (value === 'remote') value = 'Remote Working';
          return (<div style={{textTransform: 'capitalize'}}>{value}</div>);
        },
      },
      {
        name: 'is_edited',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          if (value === true) value = 'Fixed';
          if (value === false) value = 'Fraud';
          return (<div style={{textTransform: 'capitalize'}}>{value}</div>);
        },
      },
      {name: 'date', label: 'Date', display: true, customBodyRender: (value) => value ? convDate(value, 'D MMMM YYYY') : '-'},
      // {name: 'day', label: 'Day', display: true, customBodyRender: (value) => value ? value : '-'},
      {
        name: 'check_in',
        label: 'Check In',
        display: true,
        customBodyRender: (value) => {
          return value ?
            (
              <Button
                variant='outlined'
                size="small"
                startIcon={<CheckCircleOutline className={classes.checkInTrue} />}
                onClick={this.handleModal}
              >
                {value}
              </Button>
            ) : '-';
        },
      },
      {
        name: 'check_in_created_at',
        label: 'Created at',
        display: true,
        customBodyRender: (value) => {
          return value ?
            (
              <Button
                variant='outlined'
                size="small"
                startIcon={<CheckCircleOutline className={classes.checkInTrue} />}
                onClick={this.handleModal}
              >
                {value}
              </Button>
            ) : '-';
        },
      },
      {
        name: 'check_out',
        label: 'Check Out',
        display: true,
        customBodyRender: (value) => {
          return value ?
            (
              <Button
                variant='outlined'
                size="small"
                startIcon={<CheckCircleOutline className={classes.checkInTrue} />}
                onClick={this.handleModal}
              >
                {value}
              </Button>
            ) : '-';
        },
      },
      {
        name: 'check_out_created_at',
        label: 'Created at',
        display: true,
        customBodyRender: (value) => {
          return value ?
            (
              <Button
                variant='outlined'
                size="small"
                startIcon={<CheckCircleOutline className={classes.checkInTrue} />}
                onClick={this.handleModal}
              >
                {value}
              </Button>
            ) : '-';
        },
      },
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Datatable
          creatable={false}
          title={'Fraud List'}
          loading={isLoading}
          dataSource={fraudData}
          total={fraudMeta.total}
          page={fraudMeta.page}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleDetail={(params) => this.toggleModal('detail', params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
        />
        {this.renderModalDetail()}
      </div>
    );
  }
}

const mapStateToProps = ({fraudDetection, userManagement}) => ({
  isLoading: fraudDetection.isLoading,
  isSuccess: fraudDetection.isSuccess,
  isError: fraudDetection.isError,
  message: fraudDetection.message,
  fraudData: fraudDetection.fraudData,
  fraudMeta: fraudDetection.fraudMeta,
  detailLoading: fraudDetection.detailLoading,
  detailData: fraudDetection.detailData,
  trigger: fraudDetection.trigger,
  attendanceTypes: userManagement.attendanceTypes,
  isLoadingPost: fraudDetection.isLoadingPost,
  isSuccessPost: fraudDetection.isSuccessPost,
  isErrorPost: fraudDetection.isErrorPost,
  messagePost: fraudDetection.messagePost,
  is_edited: fraudDetection.is_edited,
});

const mapDispatchToProps = (dispatch) => ({
  getFraudDetection: (params) => dispatch(getFraudDetection(params)),
  getFraudDetail: (params) => dispatch(getFraudDetail(params)),
  putChangeFraudStatus: (params) =>
    dispatch(putChangeFraudStatus(params)),
  checkStatus: (params) => dispatch(checkStatus(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FraudDetection));

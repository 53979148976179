/* eslint-disable max-len */
import {SHIFT_MANAGEMENT} from '../../services';
import {SHIFT_MANAGEMENT_STATE as STATE} from './shiftManagement.types';
import {transformObject, isArray} from '../../helpers';

export const getShiftManagement = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_ALL));
    SHIFT_MANAGEMENT.getAll(params)
        .then((response) => {
          dispatch(requestSuccess(STATE.FETCH_ALL_SUCCCESS, response.data));
        })
        .catch((error) => {
          // console.log(error)
          const response = error.response.data;
          dispatch(requestFailed(STATE.FETCH_ALL_FAILED, response.data.message));
        });
  };
};

export const updateShiftManagement = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    SHIFT_MANAGEMENT.update(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import ReactGA from 'react-ga';

const trackPageView = (page) => {
  ReactGA.set({
    page,
  });
  ReactGA.pageview(page);
};


class Public extends Component {
  componentDidMount() {
    const page = this.props.location.pathname + this.props.location.search;
    trackPageView(page);
  }

  componentDidUpdate(prevProps) {
    const currentPage = prevProps.location.pathname + prevProps.location.search;
    const page = this.props.location.pathname + this.props.location.search;
    if (currentPage !== page) {
      window.scrollTo(0, 0);
      if (!page.includes('/preview')) trackPageView(page);
    }
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(Public);

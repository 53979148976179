export default (theme) => ({
  paper: {
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    border: 'none',
    paddingTop: '12px',
    paddingRight: '16px',
    paddingBottom: '12px',
    paddingLeft: '16px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
  },
  voucherItemPaper: {
    color: theme.palette.text.secondary,
    borderRadius: 8,
    boxShadow: 'none',
    padding: '16px',
  },
  eventDashboard: {
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
    border: '',
    paddingTop: '12px',
    paddingRight: '16px',
    paddingBottom: '12px',
    paddingLeft: '16px',
  },
  categoryText: {
    fontSize: 16,
    fontWeight: 600,
    color: '#656464',
    marginTop: 16,
    marginBottom: 16,
  },
  titleText: {
    fontSize: 14,
    fontWeight: 600,
    color: '#656464',
  },
  valueText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
    textTransform: 'capitalize',
  },
  topBox: {
    borderRadius: 18,
    boxShadow: 'none',
    border: '',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    backgroundColor: '#FFF',
    padding: '16px, 24px, 16px, 24px',
    marginBottom: '16px',
  },
  dashboardItem: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '12px',
  },
  dashboardValue: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
  },
  loaderImg: {
    borderRadius: '6px',
    height: '190px',
    width: '190px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '16px',
    paddingLeft: '24px',
  },
  formTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '14px',
  },
});

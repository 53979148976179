/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  Paper,
  Grid,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import {
  FileCopy, Refresh, Print, Link, Dashboard,
} from '@material-ui/icons';
import styles from './styles';
import {Send} from '@material-ui/icons';
import {toast, isArray, isObject} from '../../../helpers';
import {MInput, MButton} from '../../../components/form';
import {GENERAL_SETTING_STATE as STATE} from '../../../redux/generalSetting/generalSetting.types';
import {
  generateLink,
  updateAutoRefreshQR,
  checkTokenQR,
  generateQR,
  fetchQRSettings,
  getGeneralSetting,
  updateGeneralSetting,
} from '../../../redux/generalSetting/generalSetting.actions';
import ReactToPrint from 'react-to-print';
import QRPrintPage from '../../qrCode/printQR';
import Swal from 'sweetalert2';
// const QR_INTERVAL = process.env.REACT_APP_REFRESH_QR_INTERVAL ? process.env.REACT_APP_REFRESH_QR_INTERVAL : 0;

class GenerateQR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qrURL: null,
      initialLoading: true,
      urlCopied: false,
      formData: {
        auto_refresh_qr: null,
      },
      settings: [],
      message: false,
    };
    this.intervalId = null;
    this.linkURL = React.createRef();
    this.printable = React.createRef();
  }

  UNSAFE_componentWillMount() {
    this.props.getGeneralSetting();
  }

  componentDidMount() {
    this.props.fetchQRSettings();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {message, trigger, data, isSuccess, isError, qrToken, qrPageLink} = nextProps;

    if (trigger === STATE.UPDATE_SUCCESS) {
      toast('success', message);
      this.props.getGeneralSetting();
    }

    if (trigger === STATE.FETCH_ALL_SUCCCESS) {
      const filtered = this.filterSetting(data);
      const qrSetting = filtered && filtered[0] ? filtered[0] : null;

      this.setState({
        settings: filtered,
        formData: {
          auto_refresh_qr: qrSetting,
        },
      });
    }

    if (this.props.trigger !== trigger && trigger === STATE.UPDATE_FAILED && !isObject(message)) {
      toast('error', message);
      this.props.getGeneralSetting();
    }

    if (isSuccess && trigger === STATE.FETCH_QR_SETTINGS_SUCCESS) {
      if (!qrPageLink) this.props.generateLink(); // auto generate link if company doesnt have any
    }

    if (isSuccess && trigger === STATE.GENERATE_LINK_SUCCESS) {
      this.props.generateQR({id: qrToken}); // if new link is generated, new QR also generated
    }

    if (isError && !isObject(message)) toast('error', message);
  }

  filterSetting = (data) => {
    const filter = (data, key) => {
      const result = data.filter((value) => value.key === key);
      return isArray(result) ? result[0] : null;
    };

    const qrSetting = filter(data, 'auto_refresh_qr');
    return [qrSetting];
  }

  // isEmptyOrSpaces(str) {
  //   return (
  //     str === null || str.match(/^ *$/) !== null || str.length < 7
  //   );
  // }

  handleSubmit = () => {
    const {formData} = this.state;
    const converted = [];
    Object.entries(formData).forEach(([key, val]) => {
      // if (val.key === 'job_title') { // must be array, then stringify / toString
      //   val.value = isArray(val.value) ? (val.value).toString() : val.value; // alt : val.value = JSON.stringify(val.value);
      // }
      // const empty = this.isEmptyOrSpaces(val.value);

      if ((val.value === null || val.value === undefined || val.value === '')) {
        return toast('error', 'Please input QR refresh time');
      }
      if (val.value < 180) {
        return toast('error', 'Minimum QR refresh time is 180 Seconds');
      }
      if (val.value !== null && val.value >= 180) {
        converted.push({
          key: val.key,
          label: val.label,
          value: val.value,
          group: val.group,
        });
        const params = {data: converted};
        this.props.updateGeneralSetting(params);
        // toast('success', 'QR time settings updated');
      }
    });
  }

  handlePublish = () => {
    const text = this.state.formData.auto_refresh_qr;
    const params = {
      title: 'Auto Refresh Time (Second)',
      body: text.value,
    };
    this.props.publishAnnouncements(params);
  }

  handleInputChangeSetting = (type, params) => {
    let name;
    let value;
    if (type === 'text' || type === 'number') {
      name = params.target.name;
      value = params.target.value;
    } else if (type === 'multiple' || type === 'datetime') {
      name = params.name;
      value = params.value;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          value,
        },
      },
    });
  };

  componentDidUpdate() {
    if (this.state.urlCopied) {
      toast('success', 'Link copied to clipboard');
      this.setState({urlCopied: false});
    }
  }

  handleGenerateLink = () => {
    Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure to re-generate link ? ',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#00AFF0',
      cancelButtonColor: '#E5AF5A',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.props.generateLink();
      }
    });
  }

  handleCopyLink = () => {
    this.linkURL.current.select();
    document.execCommand('copy');
    this.setState({urlCopied: true});
  }

  handleOpenLink = () => {
    const {qrPageLink} = this.props;
    window.open(qrPageLink);
  }

  handleOpenLeaderboard = () => {
    const {qrToken} = this.props;
    const location = window.location.origin;
    window.open(location+'/leaderboard/'+qrToken);
  }

  handlePrintIframe = (id) => {
    const iframe = document.frames ? document.frames[id] : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;
    iframe.focus();
    iframeWindow.print();
    return false;
  }

  toggleAutoRefresh = () => {
    const {autoRefresh} = this.props;
    this.props.updateAutoRefreshQR({auto_refresh: !autoRefresh});
  }

  renderForm = (settingArray) => {
    const {classes, qrPageLink, isLoading, autoRefresh, qrCompanyDetail, qr, menusData} = this.props;
    const {auto_refresh_qr} = this.state.formData;

    const result = settingArray.map((setting) => {
      const {id, key} = setting;
      if (id) {
        if (key === 'auto_refresh_qr') {
          return (
            <div key={id}>
              {autoRefresh ? (

                <MInput style={{paddingRight: 20}}
                  inputVariant="outlined"
                  classNameFC={classes.formControlQR}
                  name={key}
                  label="Time (Seconds)"
                  placeholder="Set automatic refresh QR time"
                  defaultValue={auto_refresh_qr ? auto_refresh_qr.value : null}
                  onBlur={(event) => this.handleInputChangeSetting('number', event)}
                  type="number"
                  validate={false}
                />
              ) : null}
            </div>
          );
        }
      }
    });
    return result;
  }


  render() {
    const {auto_refresh_qr} = this.state.formData;
    const {settings} = this.state;
    const {classes, data, qrPageLink, isLoading, autoRefresh, qrCompanyDetail, qr, menusData} = this.props;
    return (
      <div className={classes.root}>
        <Paper variant="outlined" className={classes.paper}>
          <form>
            <div>
              <h5 className={classes.qrTitle}>QR Link</h5>
              <Grid container>
                <Grid item xs={12} style={{paddingRight: 20}}>
                  <MInput
                    inputProps={{ref: this.linkURL}}
                    classNameFC={classes.formControlQR}
                    name="qrLink"
                    label="Link"
                    placeholder="Click generate to create your company attendance link"
                    validate={false}
                    value={qrPageLink}
                  />
                </Grid>
                <Grid item xs={12} className={classes.btnGenerateContainer}>
                  <MButton
                    className={classes.defaultBtnQR}
                    label="Generate Link"
                    color="primary"
                    size="small"
                    icon={<Refresh />}
                    loading={isLoading}
                    onClick={this.handleGenerateLink}
                  />
                  <MButton
                    className={classes.defaultBtnQR}
                    label="Copy Link"
                    color="primary"
                    size="small"
                    icon={<FileCopy />}
                    onClick={this.handleCopyLink}
                  />
                  <MButton
                    className={classes.defaultBtnQR}
                    label="Open Link"
                    color="primary"
                    size="small"
                    icon={<Link />}
                    onClick={this.handleOpenLink}
                  />
                  <MButton
                    className={classes.defaultBtnQR}
                    label="Leaderboard"
                    color="primary"
                    size="small"
                    icon={<Dashboard />}
                    disabled={menusData.leaderboard ? false : true}
                    onClick={this.handleOpenLeaderboard}
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <h5 className={classes.subTitleQR}>Attendance QR</h5>
              <FormControlLabel
                className={classes.switch}
                control={
                  <Switch
                    checked={autoRefresh}
                    onChange={this.toggleAutoRefresh}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={`Auto Refresh QR (every ${auto_refresh_qr ? auto_refresh_qr.value : null} seconds)`}
                disabled={isLoading}
              />
              <Grid Container>
                <Grid item xs={4}>
                  {settings.length > 0 ?
                      this.renderForm(settings) : ''
                  }
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {autoRefresh ? (
                <Grid item>
                  <div className={classes.btnContainer}>
                    <MButton
                      className={classes.defaultBtn}
                      label="Update Setting"
                      icon={<Send />}
                      onClick={this.handleSubmit}
                      loading={isLoading && data.length > 0 ? true : false}
                    />
                  </div>
                </Grid>
                ) : null}
                {autoRefresh ? null : (
                <Grid item>
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <MButton
                          className={classes.btnPrint}
                          label="Print"
                          color="primary"
                          size="small"
                          icon={<Print />}
                          disabled={autoRefresh ? true : false}
                        />
                      );
                    }}
                    content={() => this.componentRef}
                  />
                </Grid>
                )}
                <div style={{display: 'none'}}>
                  <QRPrintPage
                    ref={(el) => (this.componentRef = el)}
                    qrURL={qr}
                    companyDetail={{
                      name: qrCompanyDetail.name,
                      address: qrCompanyDetail.address,
                      logo: qrCompanyDetail.logo,
                    }}
                  />
                </div>
              </Grid>
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = ({auth, generalSetting, upgradeAccount}) => ({
  data: generalSetting.data,
  isLoading: generalSetting.isLoading,
  isSuccess: generalSetting.isSuccess,
  isError: generalSetting.isError,
  message: generalSetting.message,
  trigger: generalSetting.trigger,
  qr: generalSetting.qr,
  qrPageLink: generalSetting.qrPageLink,
  autoRefresh: generalSetting.autoRefresh,
  upgradeTrigger: upgradeAccount.trigger,
  qrToken: generalSetting.token,
  qrCompanyDetail: generalSetting.company,
  menusData: auth.data.menus,
});

const mapDispatchToProps = (dispatch) => ({
  getGeneralSetting: (key) => dispatch(getGeneralSetting(key)),
  updateGeneralSetting: (params) => dispatch(updateGeneralSetting(params)),
  fetchQRSettings: (params) => dispatch(fetchQRSettings(params)),
  generateQR: (params) => dispatch(generateQR(params)),
  generateLink: (params) => dispatch(generateLink(params)),
  updateAutoRefreshQR: (params) => dispatch(updateAutoRefreshQR(params)),
  checkTokenQR: (params) => dispatch(checkTokenQR(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GenerateQR));



import {ENDPOINTS, partnerService} from '../config';

export const getPartner = (params) => {
  return partnerService.get(
      ENDPOINTS.PARTNER.GET,
      {params},
  );
};

export const getPartnerList = (params) => {
  return partnerService.get(
      ENDPOINTS.PARTNER.LIST,
      {params},
  );
};

export const getMember = (params) => {
  return partnerService.get(
      ENDPOINTS.PARTNER.GET_MEMBER,
      {params},
  );
};

export const getOnePartner = (id, params) => {
  return partnerService.get(
      ENDPOINTS.PARTNER.GET_ONE + id,
      {params},
  );
};

export const resendEmail = (id) => {
  return partnerService.get(
      ENDPOINTS.PARTNER.RESEND + id,
  );
};

export const getPartnerDetail = (params) => {
  return partnerService.get(
      ENDPOINTS.PARTNER.GET_PROFILE,
      {params},
  );
};


export const createPartner = (params) => {
  return partnerService.post(
      ENDPOINTS.PARTNER.ADD,
      params,
  );
};

export const togglePartner = (id, params) => {
  return partnerService.put(
      ENDPOINTS.PARTNER.TOGGLE + id,
      params,
  );
};

export const deletePartner = (id) => {
  return partnerService.delete(
      ENDPOINTS.PARTNER.DELETE + id,
  );
};

export const updatePartner = (id, params) => {
  return partnerService.put(
      ENDPOINTS.PARTNER.UPDATE + id,
      params,
  );
};

export const rejectPartner = (params) => {
  return partnerService.put(
      ENDPOINTS.PARTNER.REJECT + '/rejected',
      params,
  );
};

export const approvePartner = (params) => {
  return partnerService.put(
      ENDPOINTS.PARTNER.APPROVE + '/approved',
      params,
  );
};



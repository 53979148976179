import {ENDPOINTS, apiService} from '../config';

export const getEvents = (params) => {
  return apiService.get(
      ENDPOINTS.EVENT_MANAGEMENT.GET,
      {params},
  );
};

export const getEventDetail = (params) => {
  return apiService.get(ENDPOINTS.EVENT_MANAGEMENT.GET_DETAIL + '/' + params);
};

export const getEventQR = (params) => {
  return apiService.get(ENDPOINTS.EVENT_MANAGEMENT.GET_QR, {params: {event_id: params}});
};

export const generateEventQR = (params) => {
  return apiService.get(ENDPOINTS.EVENT_MANAGEMENT.GENERATE_QR, {params: {event_id: params}});
};

export const createEvent = (params) => {
  return apiService.post(
      ENDPOINTS.EVENT_MANAGEMENT.CREATE,
      params,
  );
};

export const updateEvent = (id, params) => {
  return apiService.put(
      ENDPOINTS.EVENT_MANAGEMENT.UPDATE.replace(':id', id),
      params,
  );
};

export const requestCancelEvent = (params, body) => {
  return apiService.put(
      ENDPOINTS.EVENT_MANAGEMENT.REQUEST_CANCEL.replace(':id', params),
      body,
  );
};

export const cancelApprovalEvent = (id, params) => {
  return apiService.put(
      ENDPOINTS.EVENT_MANAGEMENT.CANCEL_APPROVAL.replace(':id', id),
      params,
  );
};

export const blockEvent = (id, body) => {
  return apiService.put(
    ENDPOINTS.EVENT_MANAGEMENT.BLOCK.replace(':id', id),
    body,
  );
};

export const getParticipants = (params) => {
  return apiService.get(
      ENDPOINTS.EVENT_MANAGEMENT.GET_PARTICIPANTS,
      {params},
  );
};

export const sendAnnouncement = (params) => {
  return apiService.post(
      ENDPOINTS.EVENT_MANAGEMENT.ANNOUNCEMENT,
      params,
  );
};

export const updateEventVoucher = (id, params) => {
  return apiService.put(
      ENDPOINTS.EVENT_MANAGEMENT.UPDATE_VOUCHER.replace(':id', id),
      params,
  );
};

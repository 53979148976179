export default (theme) => ({
  eventPaymentPackageHeader: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#00AFF0',
  },
  btnAddPackage: {
    'backgroundColor': '#00AFF0',
    'color': '#FFF',
    '&:hover': {
      backgroundColor: '#00AFF0',
    },
  },
  btnDelPackage: {
    'color': '#FFF',
    'backgroundColor': '#E5AF5A',
    '&:hover': {
      backgroundColor: '#E5AF5A',
    },
  },
});

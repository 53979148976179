/* eslint-disable max-len */
import {LEAVE_MANAGEMENT_DETAIL} from '../../services';
import {LEAVE_MANAGEMENT_DETAIL_STATE as STATE} from './leaveManagementDetail.types';

export const getLeaveManagementDetail = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_DETAIL));
    LEAVE_MANAGEMENT_DETAIL.getLeaveManagementDetail(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_DETAIL_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_DETAIL_FAILED, response.data.message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

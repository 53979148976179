/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {BANK} from '../../services';
import {BANK_STATE as STATE} from './bank.types';
import {transformObject, isArray} from '../../helpers';

export const getBankList = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GET));
    BANK.getBankList(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.GET_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.GET_FAILED, response.data.message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

// DISPATCH

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: null,
  message: null,
  xenditStatus: null,
  data: null,
};

export const XENDIT_STATE = {
  REQUEST: 'XENDIT_REQUEST',
  REQUEST_FAILED: 'XENDIT_REQUEST_FAILED',
  GET_TOKEN: 'GET_TOKEN',
  GET_TOKEN_SUCCESS: 'GET_TOKEN_SUCCESS',
  GET_TOKEN_FAILED: 'GET_TOKEN_FAILED',
  CHECK_XENDIT: 'CHECK_TOKEN',
  CHECK_XENDIT_SUCCESS: 'CHECK_TOKEN_SUCCESS',
  CHECK_XENDIT_FAILED: 'CHECK_TOKEN_FAILED',
  CLEAR_STATE_XENDIT: 'XENDIT_CLEAR_STATE',
  CLEAR_STATE_XENDIT_GENERATE: 'INVOICE_GENERATE_CLEAR_STATE',
};

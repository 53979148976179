import React, {useMemo} from 'react';
import {
  Box,
  createTheme,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  ThemeProvider,
  Typography,
  withStyles,
} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';
import moment from 'moment';
import DOMPurify from 'dompurify';
import EventPrice from '../event-price';
import {convDate, getImageUrl} from '../../../../../helpers';
import QrGenerator from '../../../components/qr-generator';
import LocationPicker from '../../../components/LocationPicker';
import styles from './styles.js';

const initialMarker = {
  lat: 51.505,
  lng: -0.09,
  label: null,
};

const EventDetailSummary = ({
  classes,
  eventDetail,
  isLoading,
}) => {
  const eventDate = useMemo(() => {
    const startDate = moment(eventDetail?.date_start).startOf('day');
    const endDate = moment(eventDetail?.date_end).startOf('day');
    const diff = startDate.diff(endDate, 'days');
    const startDateStr = convDate(eventDetail?.date_start, 'DD MMMM YYYY');

    if (diff === 0) {
      return startDateStr;
    } else {
      const endDateStr = convDate(eventDetail?.date_end, 'DD MMMM YYYY');
      return `${startDateStr} - ${endDateStr}`;
    }
  }, [eventDetail]);

  return (
    <>
      <Paper variant="outlined" className={classes.eventDashboard}>
        <Box>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: '18px',
              marginBottom: '20px',
            }}
          >
            Event Information
          </Typography>
        </Box>
        <Grid container>
          <Grid item lg={5} md={5} xl={5} style={{display: 'flex', alignItems: 'center'}}>
            {isLoading ?
              <ThemeProvider theme={createTheme({palette: {primary: grey}})}>
                <LinearProgress className={classes?.loaderImg} color="primary" />
              </ThemeProvider> :
              <img
                loading="lazy"
                src={getImageUrl(eventDetail?.photo)}
                alt="Event Banner"
                style={{borderRadius: '6px', width: '100%', height: 'auto', objectFit: 'contain'}}
              />
            }
          </Grid>
          <Grid item lg={7} md={7} xl={7} style={{display: 'flex'}}>
            <Box
              style={{
                marginLeft: '30px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Box>
                <Typography className={classes.dashboardItem}>
                  Event Name
                </Typography>

                <Typography className={classes.dashboardValue}>
                  {eventDetail?.title || '-'}
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.dashboardItem}>
                  Event Type
                </Typography>

                <Typography className={`${classes.dashboardValue} text-capitalize`}>
                  {eventDetail?.type || '-'}
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.dashboardItem}>
                  Event Maximum Registration
                </Typography>

                <Typography className={classes.dashboardValue}>
                  {eventDetail ? convDate(eventDetail?.expired_registration, 'DD MMMM YYYY') : '-'}
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.dashboardItem}>
                  Event Date
                </Typography>

                <Typography className={classes.dashboardValue}>
                  {eventDetail ? eventDate : '-'}
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.dashboardItem}>
                  Event Status
                </Typography>

                <Typography className={`${classes.dashboardValue} text-capitalize`}>
                  {eventDetail?.status ? getStatusLabel(eventDetail?.status) : '-'}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                marginLeft: '20px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Box>
                <Typography className={classes.dashboardItem}>
                  Payment Type
                </Typography>

                <Typography className={`${classes.dashboardValue} text-capitalize`}>
                  {eventDetail?.payment_type || '-'}
                </Typography>
              </Box>

              <Box>
                <Typography className={classes.dashboardItem}>
                  Maximum Participants
                </Typography>

                <Typography className={classes.dashboardValue}>
                  {eventDetail?.maximum_participants || 0} Participant
                </Typography>
              </Box>

              {eventDetail?.payment_type !== 'free' ? null : <Box>
                <Typography className={classes.dashboardItem}>
                  Registered
                </Typography>

                <Typography className={classes.dashboardValue}>
                  {eventDetail?.package_count?.all || 0} Participant
                </Typography>
              </Box>}

              {eventDetail?.payment_type !== 'paid' ? null : <>
                <Box>
                  <Typography className={classes.dashboardItem}>Paid</Typography>
                  <Typography className={classes.dashboardValue}>
                    {eventDetail?.participant_count?.paid || 0} Participant
                  </Typography>
                </Box>

                <Box>
                  <Typography className={classes.dashboardItem}>
                    Waiting For Payment
                  </Typography>
                  <Typography className={classes.dashboardValue}>
                    {eventDetail?.participant_count?.waiting || 0} Participant
                  </Typography>
                </Box>
              </>}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <QrGenerator />

      <Paper variant="outlined" className={classes.paper}>
        <Box>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: '18px',
              marginBottom: '20px',
            }}
          >
            Event Detail
          </Typography>
        </Box>

        <Box>
          <Typography style={{fontWeight: 600, fontSize: '16px', marginBottom: '16px'}}>
            Location
          </Typography>

          <Grid container spacing={2} style={{marginTop: 4, marginBottom: 4}}>
            <Grid item xs={12}>
              <LocationPicker
                position={eventDetail ?
                    {
                      lat: eventDetail?.location?.lat,
                      lng: eventDetail?.location?.long,
                      label: eventDetail?.location?.address,
                    } :
                    initialMarker
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{marginTop: 4, marginBottom: 4}}>
            <Grid item xs={3}>
              <Typography className={classes.titleText}>Address</Typography>
            </Grid>

            <Grid item xs={9}>
              <Typography className={classes.valueText}>
                {eventDetail?.location?.address}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{marginBottom: 4}}>
            <Grid item xs={3}>
              <Typography className={classes.titleText}>Longitude</Typography>
            </Grid>

            <Grid item xs={9}>
              <Typography className={classes.valueText}>{eventDetail?.location?.long}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{marginBottom: 4}}>
            <Grid item xs={3}>
              <Typography className={classes.titleText}>Latitude</Typography>
            </Grid>

            <Grid item xs={9}>
              <Typography className={classes.valueText}>{eventDetail?.location?.lat}</Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider style={{marginBottom: '16px'}} />

        <Box>
          <Typography style={{fontWeight: 600, fontSize: '16px'}}>
            Description
          </Typography>

          <div
            style={{fontSize: '14px', fontWeight: 400, minHeight: 'auto'}}
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(eventDetail?.description)}}
          />
        </Box>

        {eventDetail?.payment_type !== 'paid' ? null :
          <>
            <Divider style={{margin: '16px 0'}} />

            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Box sx={{marginTop: '24px'}}>
                <Typography style={{fontWeight: 600, fontSize: '16px'}}>
                  Event Price
                </Typography>

                {eventDetail?.package?.map((p, index) => (
                  <EventPrice
                    key={index}
                    idx={index + 1}
                    name={p?.name}
                    type={eventDetail?.payment_type}
                    maxParticipant={p?.maximum_participants}
                    price={p?.price}
                    description={p?.description}
                  />
                ))}
              </Box>
            </Box>
          </>}
      </Paper>
    </>
  );
};

export default withStyles(styles)(EventDetailSummary);

const getStatusLabel = (status) => {
  return status === 'active' ? (
    <span
      style={{
        color: '#008000',
        fontWeight: 'bold',
        textTransform: 'capitalize',
      }}
    >
      published
    </span>
  ) : status === 'inactive' ? (
    <span
      style={{
        color: '#E5AF5A',
        fontWeight: 'bold',
        textTransform: 'capitalize',
      }}
    >
      draft
    </span>
  ) : status === 'ended' ? (
    <span
      style={{
        color: '#777777',
        fontWeight: 'bold',
        textTransform: 'capitalize',
      }}
    >
      {status?.replace('_', ' ')}
    </span>
  ) : (
    <span
      style={{
        color: '#F22951',
        fontWeight: 'bold',
        textTransform: 'capitalize',
      }}
    >
      {status?.replace('_', ' ')}
    </span>
  );
};

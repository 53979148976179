/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  Crop,
  Refresh,
  Check,
  Photo,
} from '@material-ui/icons';
import {
  ModalTitle,
  MButton,
} from '../form';

class ImageCropper extends PureComponent {
  constructor() {
    super();
    this.state = {
      crop: {
        unit: '%',
        width: 100,
        height: 100,
        // aspect: 3/4,
      },
      showModal: false,
      src: null,
      showResult: false,
      croppedImageBlob: null,
      croppedImageUrl: null,
    };
    this.initialState = this.state;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {src, showModal} = nextProps;
    if (src) this.onSelectFile(src);
    if (showModal === true || showModal === false) this.setState({showModal});
  }

  onSelectFile = (src) => {
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      this.setState({src: reader.result}),
    );
    reader.readAsDataURL(src);
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({crop});
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
          this.imageRef,
          crop,
          'newCropFile.png',
      );
      this.setState({croppedImageUrl}); // put croppedImageURL to temporary state
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.handleResizedBlob(blob); // put resized blob to temporary state
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/png');
    });
  }

  handleReset = () => {
    this.setState(this.initialState);
  }

  handleCloseModal = () => {
    this.props.onClose();
    this.handleReset();
  }

  handleCrop = (status) => {
    this.setState({showResult: status});
  }

  handleResizedBlob = (blob) => {
    this.setState({croppedImageBlob: blob});
  }

  handleSubmitCropResult = () => {
    const {croppedImageBlob, croppedImageUrl} = this.state;
    this.props.onComplete({ // send Blob & imageURL result to parent element
      blob: croppedImageBlob,
      imageURL: croppedImageUrl,
    });
    this.handleCloseModal();
  }

  handleSubmitOriginal = () => {
    this.props.onComplete({ // send ORIGINAL Blob & imageURL to parent element
      blob: this.props.src,
      imageURL: this.state.src,
    });
    this.handleCloseModal();
  }

  render() {
    const {classes, maxPopupHeight} = this.props;
    const {crop, croppedImageUrl, showResult, src, showModal} = this.state;

    return (
      <Dialog
        open={showModal}
        onClose={this.handleCloseModal}
      >
        <ModalTitle
          title="Crop Image"
          onClose={this.handleCloseModal}
        />
        <DialogContent className={classes.dialogContent}>
          {
            showResult ?
            (
              croppedImageUrl &&
              <img
                alt="Crop"
                style={{
                  height: maxPopupHeight ? maxPopupHeight : '64vh',
                  width: 'auto',
                }}
                src={croppedImageUrl}
              />
            ) :
            (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )
          }
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {
            showResult ?
            (
              <div>
                <MButton
                  className={`${classes.cancelBtn} ${classes.btnReset}`}
                  label="Reset"
                  icon={<Refresh />}
                  onClick={() => this.handleCrop(false)}
                />
                <MButton
                  className={`${classes.defaultBtn} ${classes.btnCrop}`}
                  label="Done"
                  icon={<Check />}
                  onClick={this.handleSubmitCropResult}
                />
              </div>
            ) :
            (
              <div>
                <MButton
                  className={`${classes.cancelBtn} ${classes.btnReset}`}
                  label="Use Original"
                  icon={<Photo />}
                  onClick={this.handleSubmitOriginal}
                />
                <MButton
                  className={`${classes.defaultBtn} ${classes.btnCrop}`}
                  label="Crop"
                  icon={<Crop />}
                  onClick={() => this.handleCrop(true)}
                />
              </div>
            )
          }
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ImageCropper);

/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {
  withStyles,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Box,
  CircularProgress,
} from '@material-ui/core';
import {
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import {
  Send,
  LocalAtm,
  CreditCard,
} from '@material-ui/icons';
import {
  checkToken,
} from '../../redux/confirmPayment/confirmPayment.actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {UPGRADE_STATE as STATE} from '../../redux/upgradeAccount/upgradeAccount.types';
import {isObject, toast, checkSuperadmin, checkFileUploadSize, showPopup, getTimeDiff, convDate, getDay} from '../../helpers';
import {MInput, MButton, MKeyBoardDatepicker, ModalTitle, MCheckboxItem} from '../../components/form';
import moment from 'moment';
import styles from './styles';
import {INVOICE_STATE as STATE_INVOICE} from '../../redux/invoice/invoice.types';
import {ORGANIZATION_STATE} from '../../redux/organization/organization.types';
import {XENDIT_STATE} from '../../redux/xendit/xendit.types';
import {checkPremium} from '../../redux/upgradeAccount/upgradeAccount.actions';
import {
  createBankPayment,
  clearState,
} from '../../redux/upgradeAccount/upgradeAccount.actions';
import {
  checkAmount,
  getInvoicePrice,
  selectPaymentPlan,
} from '../../redux/invoice/invoice.actions';
import {
  getMyOrganizationProfile,
  updateOrganization,
} from '../../redux/organization/organization.actions';
import {
  getGeneralSetting,
  updateGeneralSetting,
} from './../../redux/generalSetting/generalSetting.actions';
import {
  getPaymentByOrg,
} from '../../redux/confirmPayment/confirmPayment.actions';
import invoiceReducer from '../../redux/invoice/invoice.reducer';
import meetingCalendar from '../../assets/img/meetingCalendar.png';
import {checkXenditStatus, viaXendit, clearState as clearStateXendit} from '../../redux/xendit/xendit.actions';

const INVOICE_PERIOD = process.env.REACT_APP_API_INVOICE_PERIOD;
const MEETING_LINK = 'https://calendly.com/pagii/meeting-online';

class UpgradeAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      title: null,
      bank: null,
      description: null,
      payment_img: null,
      payment_date: null,
      imageURL: null,
      reason: null,
      showMethod: null,
      tncChecked: false,
      resizing: false,
      errorMessage: null,
      errorImageSize: null,
      currentPlan: null,
      nextPlan: null,
      formId: null,
      schedule: true,
      invoiceUrl: null,
      invoiceStatus: null,
      checkSuccess: false,
      regenerateInvoice: false,
      companyData: [],
    };
  }

  getMuiTheme = () => createTheme({
    overrides: {
      MuiTypography: {
        h6: {
          fontSize: 20,
          fontWeight: 600,
          fontFamily: 'Poppins !important',
        },
      },
      MuiDialogTitle: {
        root: {
          padding: '14px 24px 0px 18px',
        },
      },
      MuiPaper: {
        elevation24: {
          boxShadow: 'none',
        },
      },
      MuiDialogContent: {
        root: {
          padding: '8px 4px 8px 4px',
        },
      },
    },
  })


  UNSAFE_componentWillMount() {
    const {orgProfile, statusData} = this.props;
    if (orgProfile) {
      this.setState({
        formId: orgProfile.id,
        currentPlan: orgProfile.plan,
      });
    }
    if (statusData) {
      this.setState({
        schedule: statusData.schedule ? true : false,
      });
    }
    this.props.getGeneralSetting();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {checkSuccess, regenerateInvoice} = this.state;
    const {trigger, isSuccess, isError, message, isOpen, isSuccessSelect, triggerSelect, messageSelect, orgTrigger, orgIsSuccess, orgIsError, orgMessage, orgProfile, showMethod, orgStatusData} = nextProps;
    const {isSuccessXendit, triggerXendit, messageXendit, isErrorXendit, xenditData, xenditStatus} = nextProps;

    // console.log(xenditStatus)
    // console.log(isSuccessXendit)
    // console.log(xenditData)

    if (orgIsSuccess) {
      if (orgTrigger === ORGANIZATION_STATE.CHECK_STATUS_SUCCESS) {
        this.setState({
          companyData: orgStatusData,
        });
      }
    }

    if (isOpen && isOpen !== this.state.isOpen) {
      setTimeout(() => {
        this.setState({isOpen});
      }, 1500);
    }
    if (showMethod && showMethod !== this.state.showMethod) {
      this.setState({showMethod});
    }

    if (xenditStatus && xenditStatus.status === 'EXPIRED') {
      if (xenditData !== null) {
        this.setState({invoiceUrl: xenditData?.invoice_url});
      }
    } else if (xenditStatus && xenditStatus.invoice_url !== this.state.invoiceUrl) {
      this.setState({invoiceUrl: xenditData?.invoice_url});
    }


    if (xenditStatus && xenditStatus.status !== this.state.invoiceStatus) {
      this.setState({invoiceStatus: xenditStatus.status});
    }

    if (isOpen && isSuccess && !isObject(message)) {
      if (
        trigger === STATE.BANK_PAYMENT_SUCCESS
      ) {
        this.toggleModal(false);
        setTimeout(() => {
          this.props.getPaymentByOrg({
            page: 1,
            lmit: 10,
            order: 'desc',
          });
          this.props.checkPremium();
          this.props.checkAmount();
        }, 2500);
        toast('success', message);
      }
    }
    if (isOpen && isSuccessSelect) {
      if (triggerSelect === STATE_INVOICE.SELECT_PLAN_SUCCESS) {
        this.toggleModal(false);
        // showPopup(
        //   'info',
        //   'Please check your email to see the invoice',
        // );
        // this.props.checkPremium();
        // this.props.viaXendit();
        this.props.checkAmount();
        this.props.history.push('/payment-history');
        window.location.reload();
        toast('success', messageSelect);
      }
    }


    if (checkSuccess === false) {
      if (isOpen && isSuccessXendit) {
        if (triggerXendit === XENDIT_STATE.GET_TOKEN_SUCCESS && this.state.invoiceUrl !== xenditData.invoice_url) {
          this.setState({
            invoiceUrl: xenditData.invoice_url,
            checkSuccess: true,
          });
          setTimeout(() => {
            this.toggleMethods('xendit');
            this.toggleModal(false);
            this.toggleMethods('xendit');
          }, 1500);
        }
      }
    }


    if (isOpen && orgIsSuccess && orgTrigger === ORGANIZATION_STATE.UPDATE_SUCCESS) {
      this.props.getMyOrganizationProfile();
      toast('success', orgMessage);
      this.props.checkPremium();
      this.props.checkAmount();
      this.toggleModal(false);
      this.props.getPaymentByOrg({
        page: 1,
        lmit: 10,
        order: 'desc',
      });
    }

    if (isOpen && isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) {
        if (trigger === STATE.BANK_PAYMENT_FAILED) toast('error', message);
      }
    }

    if (isOpen && isErrorXendit && messageXendit) {
      if (isObject(messageXendit)) this.setState({errorMessage: messageXendit});
      if (!isObject(messageXendit)) {
        if (triggerXendit === XENDIT_STATE.GET_TOKEN_FAILED) toast('error', messageXendit);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearState();
    this.props.clearStateXendit();
  }

  toggleModal = (status) => {
    if (!status) status = !this.state.isOpen;
    if (this.props.handleParentToggle) this.props.handleParentToggle(status);
    this.props.getPaymentByOrg({
      page: 1,
      lmit: 10,
      order: 'desc',
    });
    this.setState(
        {isOpen: status},
        () => {
          if (this.state.showMethod === 'xendit') this.props.checkPremium();
          if (!status) this.props.clearState();
          if (!status) this.handleReset();
        },
    );
  }

  checkCurrentPlan() {
    const {lastPlan} = this.props;
    this.setState({currentPlan: lastPlan});
  }
  checkNextPlan() {
    const {plan} = this.props;
    this.setState({nextPlan: plan});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {title, payment_img, bank, payment_date, description} = this.state;
    const dataForm = new FormData();

    // if (title) dataForm.append('title', title);
    if (bank) dataForm.append('bank', bank);
    if (description) dataForm.append('description', description);
    if (payment_img) dataForm.append('payment_img', payment_img);
    if (payment_date) dataForm.append('payment_date', moment(payment_date).format('YYYY-MM-DD'));
    this.props.createBankPayment(dataForm);
  }

  handleChangePlan= (param) => {
    const {formId} = this.state;
    const {
      isPremium,
      isTrial,
      expiredDate,
    } = this.props;

    const dataForm = new FormData();
    const dayRemain = (getTimeDiff(expiredDate)+1);
    const new_plan = {new_plan: param};
    (dayRemain > INVOICE_PERIOD) && !isTrial ? showPopup(
        'confirm',
        'Are you sure you want to change your packet plan?',
        'Yes',
        () => {
          this.setState({nextPlan: param});
          if (param) dataForm.append('plan', param);
          this.props.updateOrganization(formId, dataForm);
        },
    ) :
    showPopup(
        'warning',
        'You can not change the plan after this if you do not finish the payment or the invoice is still valid. Want to proceed?',
        'Yes',
        () => {
          this.setState({currentPlan: param});
          this.props.selectPaymentPlan(new_plan);
        },
    );
  }

  handleSubmitCancel = (e) => {
    e.preventDefault();
    const {reason, errorMessage} = this.state;
    if (!reason) { // frontend validation if reason is empty
      this.setState({errorMessage: {...errorMessage, reason: 'Please fill in your cancellation reason'}});
      return false;
    }
    const params = {reason};
  }

  handleChange = (event) => {
    const {value, name, type} = event.target;
    const {errorMessage} = this.state;

    if (type === 'file') {
      const image = event.target.files[0];
      const imageSizeIsAllowed = checkFileUploadSize(image);
      if (imageSizeIsAllowed) {
        this.setState({
          errorImageSize: null,
          errorMessage: {...errorMessage, logo: null},
          payment_img: image,
          imageURL: URL.createObjectURL(image),
        });
      } else {
        let message;
        if (imageSizeIsAllowed === false) message = 'Maximum file size is ' + process.env.REACT_APP_MAX_UPLOAD_SIZE_MB + ' MB';
        if (imageSizeIsAllowed === null) message = 'Please select image';
        this.setState({
          errorImageSize: message,
          payment_img: null,
          imageURL: null,
        });
      }
    } else {
      this.setState({
        errorMessage: {...errorMessage, [name]: null},
        [name]: value,
      });
    }
  };

  handleDateChange = (params) => {
    const {name, value} = params;
    const {errorMessage} = this.state;
    this.setState({
      errorMessage: {...errorMessage, [name]: null},
      [name]: value,
    });
  }

  handleChangeTNC = (event) => {
    this.setState({tncChecked: event.target.checked});
  }

  handleReset = () => {
    this.setState({
      title: null,
      bank: null,
      payment_date: null,
      description: null,
      payment_img: null,
      imageURL: null,
      reason: null,
      showMethod: null,
      tncChecked: false,
      errorImageSize: null,
      errorMessage: null,
    });
  }

  toggleMethods = (params) => {
    this.setState(
        {showMethod: params},
        () => {
          if (!params) this.props.clearState();
          if (!params) this.handleReset();
        },
    );
  };

  xenditCheckout = () => {
    const {invoiceUrl, invoiceStatus}= this.state;
    const {checkSuccess} = this.state;
    // console.log(invoiceUrl);
    // console.log(invoiceStatus);
    // console.log(checkSuccess);
    if (checkSuccess === false) {
      this.setState({
        checkSuccess: true,
      });
      if (invoiceUrl && invoiceStatus !== 'EXPIRED') {
        this.toggleMethods('xendit');
        // console.log('masuk 1');
      } else {
        // console.log('masuk 2');
        this.props.viaXendit();
      }
    }
  };

  renderPaymentMethods = () => {
    const {classes, isLoading, price, isLoadingXendit} = this.props;
    return (
      <div>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <DialogContent className={classes.content}>
            <List className={classes.root}>
              <ListItem className={classes.paymentList}>
                <ListItemText
                  primary={<strong>{`1. Bank Transfer (Manual Verification)`}</strong>}
                  secondary="Transfer to the bank account listed on the invoice, then upload the proof of payment in the next screen"
                />
                <MButton
                  className={classes.btnMethods}
                  label="Bank Transfer"
                  color="primary"
                  icon={<LocalAtm />}
                  loading={isLoading}
                  onClick={() => this.toggleMethods('bank-transfer')}
                />
              </ListItem>
              <ListItem className={classes.paymentList}>
                <ListItemText
                  primary={<strong>{`2. Xendit Payment`}</strong>}
                  secondary={'Transfer via other payment method provided by Xendit'}
                />
                <MButton
                  className={classes.btnSubs}
                  label="Xendit Payment"
                  color="primary"
                  icon={<CreditCard />}
                  loading={isLoadingXendit}
                  onClick={() => this.xenditCheckout()}
                />
              </ListItem>
            </List>
          </DialogContent>
        </MuiThemeProvider>
      </div>
    );
  }

  renderLoader = (size = 15) => {
    const {classes} = this.props;
    return <CircularProgress className={classes.loaderColor} size={size} />;
  };

  renderXendit = () => {
    const {classes, dueDate, isLoadingXendit, xenditData} = this.props;
    const {invoiceStatus} = this.state;
    if ((!this.state.invoiceUrl && (getTimeDiff(dueDate)+1) >= 0 && !isLoadingXendit) || invoiceStatus === 'EXPIRED') {
      this.xenditCheckout();
    }
    return (
      <div>
        {
          isLoadingXendit ?
          (
            this.renderLoader()
          ) :
          (
            <DialogContent className={classes.content}>
              <Grid container wrap='nowrap' justify="center" alignItems="center">
                <iframe id={'xenditFrame'} title='xendit' src={xenditData?.invoice_url} height="700" width="100%" frameBorder="0"/>
              </Grid>
            </DialogContent>
          )
        }
      </div>
    );
  }

  renderSubPlan = () => {
    const {
      isLoading,
      classes,
      priceList,
      plan,
      dueDate,
      lastPlan,
    } = this.props;
    const {
      currentPlan,
      nextPlan,
    } = this.state;

    if (currentPlan !== lastPlan) this.checkCurrentPlan();
    if (nextPlan !== plan) this.checkNextPlan();
    const price = priceList.prices.map((a) => a.amount);
    return (
      <div>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <DialogContent className={classes.content} style={{overflowY: 'hidden'}}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid container spacing={2} justify='center'>
                <Paper variant='outlined' className={classes.card} elevation={3}>
                  <Grid Container>
                    <Grid item container justify="center">
                      {
                        currentPlan === 1 ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#3CA1C1', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>CURRENT</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                      {
                        nextPlan === 1 && currentPlan !== nextPlan ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#3CA1C1', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>NEXT PLAN</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                    </Grid>
                    <Grid item container justify="center">
                      <Typography className={classes.monthFont} style={{color: '#3CA1C1'}}>1 MONTH</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.idrFont}>IDR</Typography>
                      <Typography className={classes.priceFont}>{price[0]}</Typography>
                    </Grid>
                    <Grid item container justify="center">
                      <MButton
                        className={classes.btn1}
                        label="Select"
                        type='submit'
                        size='small'
                        disabled={nextPlan === 1 && (getTimeDiff(dueDate)+1)>0 ? true : false}
                        loading={isLoading}
                        onClick={() => this.handleChangePlan(1)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid container spacing={2} justify='center'>
                <Paper variant='outlined' className={classes.card} elevation={3}>
                  <Grid Container>
                    <Grid item container justify="center">
                      {
                        currentPlan === 3 ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#F07300', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>CURRENT</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                      {
                        nextPlan === 3 && currentPlan !== nextPlan ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#F07300', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>NEXT PLAN</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                    </Grid>
                    <Grid item container justify="center">
                      <Typography className={classes.monthFont} style={{color: '#F07300'}}>3 MONTHS</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.idrFont}>IDR</Typography>
                      <Typography className={classes.priceFont}>{price[1]}</Typography>
                    </Grid>
                    <Grid item container justify="center">
                      <MButton
                        className={classes.btn3}
                        label="Select"
                        type='submit'
                        disabled={nextPlan === 3 && (getTimeDiff(dueDate)+1)>0 ? true : false}
                        size='small'
                        loading={isLoading}
                        onClick={() => this.handleChangePlan(3)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid container spacing={2} justify='center'>
                <Paper variant='outlined' className={classes.card} elevation={3}>
                  <Grid Container>
                    <Grid item container justify="center">
                      {
                        currentPlan === 6 ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#4EC13C', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>CURRENT</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                      {
                        nextPlan === 6 && currentPlan !== nextPlan ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#4EC13C', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>NEXT PLAN</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                    </Grid>
                    <Grid item container justify="center">
                      <Typography className={classes.monthFont} style={{color: '#4EC13C'}}>6 MONTHS</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.idrFont}>IDR</Typography>
                      <Typography className={classes.priceFont}>{price[2]}</Typography>
                    </Grid>
                    <Grid item container justify="center">
                      <MButton
                        className={classes.btn6}
                        label="Select"
                        type='submit'
                        disabled={nextPlan === 6 && (getTimeDiff(dueDate)+1)>0 ? true : false}
                        size='small'
                        loading={isLoading}
                        onClick={() => this.handleChangePlan(6)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid container spacing={2} justify='center'>
                <Paper variant='outlined' className={classes.card} elevation={3}>
                  <Grid Container>
                    <Grid item container justify="center">
                      {
                        currentPlan === 12 ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#EEAC03', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>CURRENT</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                      {
                        nextPlan === 12 && currentPlan !== nextPlan ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#EEAC03', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>NEXT PLAN</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                    </Grid>
                    <Grid item container justify="center">
                      <Typography className={classes.monthFont} style={{color: '#EEAC03'}}>12 MONTHS</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.idrFont}>IDR</Typography>
                      <Typography className={classes.priceFont}>{price[3]}</Typography>
                    </Grid>
                    <Grid item container justify="center">
                      <MButton
                        className={classes.btn12}
                        label="Select"
                        type='submit'
                        disabled={nextPlan === 12 && (getTimeDiff(dueDate)+1)>0 ? true : false}
                        size='small'
                        loading={isLoading}
                        onClick={() => this.handleChangePlan(12)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Typography className={classes.textNote}>Note: {priceList.tax}% tax excluded (PPN)</Typography>
          </DialogContent>
        </MuiThemeProvider>
      </div>
    );
  }

  renderPartnerPlan = () => {
    const {
      isLoading,
      classes,
      priceList,
      plan,
      dueDate,
      lastPlan,
    } = this.props;
    const {
      currentPlan,
      nextPlan,
    } = this.state;

    if (currentPlan !== lastPlan) this.checkCurrentPlan();
    if (nextPlan !== plan) this.checkNextPlan();
    const price = priceList?.prices.map((a) => a.amount);
    const actualPrice = priceList?.prices.map((a) => a.price);
    const valid_until = priceList?.prices.map((a) => a.valid_until);
    const discount_amount = priceList?.prices.map((a) => a.discount_amount);
    return (
      <div>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <DialogContent className={classes.content} style={{overflowY: 'hidden'}}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid container spacing={2} justify='center'>
                <Paper variant='outlined' className={classes.cardPartner} elevation={3}>
                  <Grid Container>
                    <Grid item container justify="center">
                      {
                        currentPlan === 1 ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#3CA1C1', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>CURRENT</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                      {
                        nextPlan === 1 && currentPlan !== nextPlan ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#3CA1C1', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>NEXT PLAN</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                    </Grid>
                    <Grid item container justify="center">
                      <Typography className={classes.monthFont} style={{color: '#3CA1C1'}}>1 MONTH</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.idrFont}>IDR</Typography>
                      <Typography className={classes.priceFont}>{price[0]}</Typography>
                    </Grid>
                    <Grid item>
                      {discount_amount[0] ? (
                        <Typography style={{fontSize: 12, fontWeight: 500, color: '#A0A0A0', textDecoration: 'line-through', textalign: 'center'}}>IDR {actualPrice[0]}</Typography>
                      ) : (
                        <Typography style={{fontSize: 12, fontWeight: 500, color: '#A0A0A0', textDecoration: 'line-through', textalign: 'center', opacity: 0}}>IDR {actualPrice[0]}</Typography>
                      )}
                    </Grid>
                    <Grid item>
                      {discount_amount[0] ? (
                        <Typography style={{fontSize: 9, fontWeight: 400, color: '#A0A0A0', marginBottom: 18, marginTop: 9, textalign: 'center'}}>*Valid Until: {moment(valid_until[0], 'MM/YYYY').format('MMMM YYYY')} </Typography>
                      ) : (
                        <Typography style={{opacity: 0, fontSize: 10, fontWeight: 400, color: '#A0A0A0', marginBottom: 18, marginTop: 9, textalign: 'center'}}>*Valid Until: {moment(valid_until[0], 'MM/YYYY').format('MMMM YYYY')} </Typography>
                      )}
                    </Grid>
                    <Grid item container justify="center">
                      <MButton
                        className={classes.btn1}
                        label="Select"
                        type='submit'
                        size='small'
                        disabled={nextPlan === 1 && (getTimeDiff(dueDate)+1)>0 ? true : false}
                        loading={isLoading}
                        onClick={() => this.handleChangePlan(1)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid container spacing={2} justify='center'>
                <Paper variant='outlined' className={classes.card} elevation={3}>
                  <Grid Container>
                    <Grid item container justify="center">
                      {
                        currentPlan === 3 ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#F07300', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>CURRENT</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                      {
                        nextPlan === 3 && currentPlan !== nextPlan ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#F07300', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>NEXT PLAN</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                    </Grid>
                    <Grid item container justify="center">
                      <Typography className={classes.monthFont} style={{color: '#F07300'}}>3 MONTHS</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.idrFont}>IDR</Typography>
                      <Typography className={classes.priceFont}>{price[1]}</Typography>
                    </Grid>
                    <Grid item>
                      {discount_amount[1] ? (
                        <Typography style={{fontSize: 12, fontWeight: 500, color: '#A0A0A0', textDecoration: 'line-through', textalign: 'center'}}>IDR {actualPrice[1]}</Typography>
                      ) : (
                        <Typography style={{opacity: 0, fontSize: 12, fontWeight: 500, color: '#A0A0A0', textDecoration: 'line-through', textalign: 'center', opacity: 0}}>IDR {actualPrice[1]}</Typography>
                      )}
                    </Grid>
                    <Grid item>
                      {discount_amount[1] ? (
                        <Typography style={{fontSize: 9, fontWeight: 400, color: '#A0A0A0', marginBottom: 18, marginTop: 9, textalign: 'center'}}>*Valid Until: {moment(valid_until[1], 'MM/YYYY').format('MMMM YYYY')} </Typography>
                      ) : (
                        <Typography style={{opacity: 0, fontSize: 9, fontWeight: 400, color: '#A0A0A0', marginBottom: 18, marginTop: 9, textalign: 'center', opacity: 0}}>*Valid Until: {moment(valid_until[1], 'MM/YYYY').format('MMMM YYYY')} </Typography>
                      )}
                    </Grid>
                    <Grid item container justify="center">
                      <MButton
                        className={classes.btn3}
                        label="Select"
                        type='submit'
                        disabled={nextPlan === 3 && (getTimeDiff(dueDate)+1)>0 ? true : false}
                        size='small'
                        loading={isLoading}
                        onClick={() => this.handleChangePlan(3)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid container spacing={2} justify='center'>
                <Paper variant='outlined' className={classes.card} elevation={3}>
                  <Grid Container>
                    <Grid item container justify="center">
                      {
                        currentPlan === 6 ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#4EC13C', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>CURRENT</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                      {
                        nextPlan === 6 && currentPlan !== nextPlan ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#4EC13C', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>NEXT PLAN</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                    </Grid>
                    <Grid item container justify="center">
                      <Typography className={classes.monthFont} style={{color: '#4EC13C'}}>6 MONTHS</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.idrFont}>IDR</Typography>
                      <Typography className={classes.priceFont}>{price[2]}</Typography>
                    </Grid>
                    <Grid item>
                      {discount_amount[2] ? (
                        <Typography style={{fontSize: 12, fontWeight: 500, color: '#A0A0A0', textDecoration: 'line-through', textalign: 'center'}}>IDR {actualPrice[2]}</Typography>
                      ) : (
                        <Typography style={{opacity: 0, fontSize: 12, fontWeight: 500, color: '#A0A0A0', textDecoration: 'line-through', textalign: 'center', opacity: 0}}>IDR {actualPrice[2]}</Typography>
                      )}
                    </Grid>
                    <Grid item>
                      {discount_amount[2] ? (
                        <Typography style={{fontSize: 9, fontWeight: 400, color: '#A0A0A0', marginBottom: 18, marginTop: 9, textalign: 'center'}}>*Valid Until: {moment(valid_until[2], 'MM/YYYY').format('MMMM YYYY')} </Typography>
                      ) : (
                        <Typography style={{opacity: 0, fontSize: 9, fontWeight: 400, color: '#A0A0A0', marginBottom: 18, marginTop: 9, textalign: 'center, opacity: 0,'}}>*Valid Until: {moment(valid_until[2], 'MM/YYYY').format('MMMM YYYY')} </Typography>
                      )}
                    </Grid>
                    <Grid item container justify="center">
                      <MButton
                        className={classes.btn6}
                        label="Select"
                        type='submit'
                        disabled={nextPlan === 6 && (getTimeDiff(dueDate)+1)>0 ? true : false}
                        size='small'
                        loading={isLoading}
                        onClick={() => this.handleChangePlan(6)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid container spacing={2} justify='center'>
                <Paper variant='outlined' className={classes.card} elevation={3}>
                  <Grid Container>
                    <Grid item container justify="center">
                      {
                        currentPlan === 12 ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#EEAC03', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>CURRENT</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                      {
                        nextPlan === 12 && currentPlan !== nextPlan ?
                          <Paper className={classes.currentPaper} style={{backgroundColor: '#EEAC03', color: '#fff', boxShadow: 'none'}}>
                            <Grid item container justify="center" alignItems="center">
                              <Typography className={classes.currentFont}>NEXT PLAN</Typography>
                            </Grid>
                          </Paper> : <div style={{marginTop: '14px'}}/>
                      }
                    </Grid>
                    <Grid item container justify="center">
                      <Typography className={classes.monthFont} style={{color: '#EEAC03'}}>12 MONTHS</Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.idrFont}>IDR</Typography>
                      <Typography className={classes.priceFont}>{price[3]}</Typography>
                    </Grid>
                    <Grid item>
                      {discount_amount[3] ? (
                        <Typography style={{fontSize: 12, fontWeight: 500, color: '#A0A0A0', textDecoration: 'line-through', textalign: 'center'}}>IDR {actualPrice[3]}</Typography>
                      ) : (
                        <Typography style={{opacity: 0, fontSize: 12, fontWeight: 500, color: '#A0A0A0', textDecoration: 'line-through', textalign: 'center', opacity: 0}}>IDR {actualPrice[3]}</Typography>
                      )}
                    </Grid>
                    <Grid item>
                      {discount_amount[3] ? (
                        <Typography style={{fontSize: 9, fontWeight: 400, color: '#A0A0A0', marginBottom: 18, marginTop: 9, textalign: 'center'}}>*Valid Until: {moment(valid_until[3], 'MM/YYYY').format('MMMM YYYY')} </Typography>
                      ) : (
                        <Typography style={{opacity: 0, fontSize: 9, fontWeight: 400, color: '#A0A0A0', marginBottom: 18, marginTop: 9, textalign: 'center', opacity: 0}}>*Valid Until: {moment(valid_until[3], 'MM/YYYY').format('MMMM YYYY')} </Typography>
                      )}
                    </Grid>
                    <Grid item container justify="center">
                      <MButton
                        className={classes.btn12}
                        label="Select"
                        type='submit'
                        disabled={nextPlan === 12 && (getTimeDiff(dueDate)+1)>0 ? true : false}
                        size='small'
                        loading={isLoading}
                        onClick={() => this.handleChangePlan(12)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Typography className={classes.textNote}>Note: {priceList.tax}% tax excluded (PPN)</Typography>
          </DialogContent>
        </MuiThemeProvider>
      </div>
    );
  }

  handleCheckboxChange = (event) => {
    const {name, checked} = event.target;
    this.setState({
      [name]: checked,
    });
  }

  handleChangeSchedule = (event) => {
    const {formId, schedule} = this.state;
    const {statusData} = this.props;
    if (schedule !== statusData.schedule) {
      const params = {data: [{
        key: 'schedule_notif',
        label: 'Schedule Notif',
        value: schedule.toString(),
        group: 'notification',
      }]};
      this.props.updateGeneralSetting(params);
    }
    this.toggleModal(false);
  }

  handleMeetingLink = () => {
    window.open(MEETING_LINK);
    this.toggleModal(false);
  }

  renderMeetingPlan = () => {
    const {
      statusData,
      profile,
      classes,
    } = this.props;

    const {
      schedule,
    } = this.state;

    return (
      <div style={{padding: 24}}>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <DialogContent className={classes.content} style={{overflowY: 'hidden'}}>
            <Grid container wrap='nowrap'>
              <Grid item>
                <img className={classes.meetingPlan} alt="img" src={meetingCalendar}/>
              </Grid>
              <Grid item container>
                <Grid item>
                  <Typography className={classes.meetingCalendar} style={{fontSize: '1.5rem', fontWeight: '750'}}>
                    Halo, {profile.full_name}
                  </Typography>
                  <Typography component="div" className={classes.meetingCalendar}>
                    Masa percobaan <Box display="inline" fontWeight="fontWeightBold">Uji Coba Gratis Selama 14 Hari</Box> akan berakhir pada
                    <Box display="inline" fontWeight="fontWeightBold"> {getDay(statusData.expired_date)}, {convDate(statusData.expired_date, 'DD MMMM YYYY')}.</Box>
                  </Typography>
                  <Typography component="div" className={classes.meetingCalendar}>
                    Yuk buat schedule atau agenda untuk meeting dan konsultasi secara
                    gratis dengan Tim <Box display="inline" fontWeight="fontWeightBold">Pagii</Box> melalui Zoom Meeting selama 45 menit untuk
                    mendapatkan penjelasan fitur dan tentunya penawaran harga yang menarik.
                  </Typography>
                </Grid>
                <Grid item>
                  <MCheckboxItem
                    style={{marginLeft: '2.5vh'}}
                    name="schedule"
                    label="Jangan tampilkan lagi"
                    onChange={this.handleCheckboxChange}
                    checked={schedule ? schedule : undefined}
                  />
                </Grid>
                <Grid item container wrap='nowrap' justify="center" alignItems="center">
                  <MButton
                    className={classes.meetingBtn}
                    label='OK'
                    size='small'
                    onClick={() => this.handleChangeSchedule()}
                  />
                  <MButton
                    style={{border: '2px solid #3F51B5', minWidth: '14vw', minHeight: '2.5vw', marginLeft: '2.5vw', borderRadius: '10px'}}
                    label="JADWALKAN MEETING SEKARANG"
                    color="primary"
                    size='small'
                    onClick={this.handleMeetingLink}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </MuiThemeProvider>
      </div>
    );
  }

  renderBankTransferForm = () => {
    const {classes, isLoading, price, plan} = this.props;
    const {title, bank, payment_date, description, imageURL, errorMessage, errorImageSize} = this.state;

    return (
      <div>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <DialogContent className={classes.paymentHeader}>
            <Typography variant="h6">Payment Account :</Typography>
            <Typography variant="h7" className={classes.paymentText}>Beneficiary Bank : <strong>BCA</strong></Typography>
            <Typography variant="h7" className={classes.paymentText}>Beneficiary Name : <strong>PT. Smooets Teknologi Outsourcing</strong></Typography>
            <Typography variant="h7" className={classes.paymentText}>Beneficiary Account : <strong>7771645252</strong></Typography>
            <Typography variant="h7" className={classes.paymentText}>Amount : <strong>IDR {price}</strong></Typography>
            <Typography variant="h7" className={classes.paymentText}>Subscription Plan : <strong>{plan} Month(s)</strong></Typography>
          </DialogContent>
          <DialogContent>
            <Typography variant="h6" component="h6">Submit Payment :</Typography>
            {/* <MInput
              classNameFC={classes.formControl}
              autoFocus
              name="title"
              label="Title"
              placeholder="Payment title"
              defaultValue={title}
              onBlur={this.handleChange}
              error={errorMessage && errorMessage.title ? errorMessage.title : undefined}
            /> */}
            <MInput
              classNameFC={classes.formControl}
              name="bank"
              label="Bank"
              placeholder="Bank Name"
              defaultValue={bank}
              onBlur={this.handleChange}
              error={errorMessage && errorMessage.bank ? errorMessage.bank : undefined}
            />
            <MInput
              classNameFC={classes.formControl}
              name="description"
              label="Description"
              placeholder="Description"
              defaultValue={description}
              onBlur={this.handleChange}
              error={errorMessage && errorMessage.description ? errorMessage.description : undefined}
              multiline
              rows={2}
              rowsMax={4}
            />
            <MKeyBoardDatepicker
              className={classes.formControl}
              inputVariant="outlined"
              name="payment_date"
              label="Payment Date"
              value={payment_date}
              onDateChange={this.handleDateChange}
              placeholder="dd-mm-yyyy"
              error={errorMessage && errorMessage.payment_date ? errorMessage.payment_date : undefined}
              maxDate={new Date()}
              autoOk
              fullWidth
            />
            <MInput
              classNameFC={classes.formControl}
              name="payment_img"
              label="Upload Payment"
              type="file"
              onChange={this.handleChange}
              error={
                errorImageSize ?
                errorImageSize :
                (
                  errorMessage && errorMessage.payment_img ?
                  errorMessage.payment_img :
                  undefined
                )
              }
            />
            {
              imageURL &&
              <CardMedia
                className={classes.photo}
                title="Payment"
                image={imageURL}
              />
            }
          </DialogContent>
          <DialogActions>
            <MButton
              className={classes.defaultBtn}
              label="Submit Payment"
              color="primary"
              type='submit'
              size='small'
              icon={<Send />}
              loading={isLoading}
              onClick={this.handleSubmit}
            />
            <MButton
              label='Back'
              size='small'
              onClick={() => this.toggleModal(false)}
            />
          </DialogActions>
        </MuiThemeProvider>
      </div>
    );
  }

  renderCancelForm = () => {
    const {classes, isLoading} = this.props;
    const {reason, errorMessage} = this.state;
    const reasonMessage = `* By submitting this form your account will be reverted to Free Version after this
    month subscription has ended, you can subscribe again later by using Upgrade Now button`;

    return (
      <div>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <DialogContent className={classes.dialog}>
            <MInput
              classNameFC={classes.formControl}
              name="reason"
              label="Reason"
              placeholder="Please fill in why you want to cancel your subscription"
              defaultValue={reason}
              onBlur={this.handleChange}
              multiline
              rows={2}
              rowsMax={4}
              error={errorMessage && errorMessage.reason ? errorMessage.reason : undefined}
            />
            {
              !(errorMessage && errorMessage.reason) &&
              (<Typography className={classes.reasonText}>{reasonMessage}</Typography>)
            }
          </DialogContent>
          <DialogActions>
            <MButton
              className={classes.defaultBtn}
              label="Submit Cancellation"
              color="primary"
              type='submit'
              size='small'
              icon={<Send />}
              loading={isLoading}
              onClick={this.handleSubmitCancel}
            />
            <MButton
              label='Cancel'
              size='small'
              onClick={() => this.toggleModal(false)}
            />
          </DialogActions>
        </MuiThemeProvider>
      </div>
    );
  }

  render() {
    const {classes, isPremium, isTrial, expiredDate, dueDate, isPaid} = this.props;
    const {isOpen, currentPlan, showMethod, companyData} = this.state;
    let disablePlan;
    let disablePay;
    const title = showMethod === 'plan' ? 'Change Plan' : showMethod === 'bank-transfer' ? 'Payment Confirmation' : null;
    // if (isPremium) title = 'Cancel Subscription Form';
    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <Dialog
          open={isOpen}
          onClose={() => this.toggleModal(false)}
          fullWidth={ false } maxWidth={'md'}
          PaperProps={{
            style: {borderRadius: 8, paddingTop: 0, paddingBottom: 0},
          }}
        >
          {(showMethod !== 'xendit' && showMethod !== 'meeting') && <ModalTitle
            className={classes.modalTitle}
            title={title}
            onClose={() => this.toggleModal(false)}
          />}
          {showMethod === 'meeting' && this.renderMeetingPlan()}
          {showMethod === 'plan' && companyData?.discount_status === null && this.renderSubPlan()}
          {showMethod === 'plan' && companyData?.discount_status === 'active' && this.renderPartnerPlan()}
          {showMethod === 'xendit' && this.renderXendit()}
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({auth, upgradeAccount, confirmPayment, invoice, organization, xendit}) => ({
  isSuperAdmin: checkSuperadmin(auth),
  isPremium: upgradeAccount.premium,
  isPaid: upgradeAccount.payment,
  isTrial: upgradeAccount.trial,
  isLoading: upgradeAccount.isLoading,
  isError: upgradeAccount.isError,
  isSuccess: upgradeAccount.isSuccess,
  message: upgradeAccount.message,
  trigger: upgradeAccount.trigger,
  paymentMethod: upgradeAccount.paymentMethod,
  plan: upgradeAccount.plan,
  lastPlan: upgradeAccount.lastPlan,
  expiredDate: upgradeAccount.expiredDate,
  dueDate: upgradeAccount.dueDate,
  price: invoice.total,
  priceList: invoice.invoicePriceData,
  isSuccessSelect: invoice.isSuccess,
  messageSelect: invoice.message,
  triggerSelect: invoice.trigger,
  orgIsLoading: organization.isLoading,
  orgIsSuccess: organization.isSuccess,
  orgIsError: organization.isError,
  orgMessage: organization.message,
  orgTrigger: organization.trigger,
  orgStatusData: organization.orgStatusData,
  orgProfile: auth.data.organization,
  statusData: upgradeAccount.data,
  profile: auth.data.profile,
  xenditData: xendit.data,
  isSuccessXendit: xendit.isSuccess,
  triggerXendit: xendit.trigger,
  messageXendit: xendit.message,
  isErrorXendit: xendit.isError,
  isLoadingXendit: xendit.isLoading,
  xenditStatus: xendit.xenditStatus,
  paymentData: confirmPayment.paymentData,
});

const mapDispatchToProps = (dispatch) => ({
  getMyOrganizationProfile: () => dispatch(getMyOrganizationProfile()),
  createBankPayment: (params) => dispatch(createBankPayment(params)),
  getPaymentByOrg: (params) => dispatch(getPaymentByOrg(params)),
  checkPremium: () => dispatch(checkPremium()),
  clearState: () => dispatch(clearState()),
  checkToken: (id) => dispatch(checkToken(id)),
  checkAmount: () => dispatch(checkAmount()),
  getInvoicePrice: () => dispatch(getInvoicePrice()),
  selectPaymentPlan: (params) => dispatch(selectPaymentPlan(params)),
  updateOrganization: (id, params) => dispatch(updateOrganization(id, params)),
  updateGeneralSetting: (params) => dispatch(updateGeneralSetting(params)),
  getGeneralSetting: (key) => dispatch(getGeneralSetting(key)),
  viaXendit: (params) => dispatch(viaXendit(params)),
  checkXenditStatus: (params) =>dispatch(checkXenditStatus(params)),
  clearStateXendit: () => dispatch(clearStateXendit()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(UpgradeAccountModal)));


import React from 'react';
import {withStyles, Typography, Box, Grid} from '@material-ui/core';
import {formatCurrency2} from '../../../../helpers';

const Title = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 600,
    color: '#656464',
  },
})(Typography);

const Value = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
    textTransform: 'capitalize',
  },
})(Typography);

function EventPrice({idx, type, name, maxParticipant, price, description}) {
  return (
    <Box
      sx={{
        marginTop: '16px',
      }}
    >
      <span
        style={{
          fontWeight: 600,
          fontSize: '14px',
          color: '#00AFF0',
        }}
      >
        Package {idx}
      </span>
      <Grid container spacing={2} style={{marginTop: 4}}>
        <Grid item xs={3}>
          <Title>Payment Type</Title>
        </Grid>
        <Grid item xs={9}>
          <Value>
            {type === 'paid' ? (
              <span
                style={{fontWeight: 700, fontSize: '14px', color: '#008000'}}
              >
                Paid
              </span>
            ) : (
              <span
                style={{fontWeight: 700, fontSize: '14px', color: '##008000'}}
              >
                Free
              </span>
            )}
          </Value>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop: 4}}>
        <Grid item xs={3}>
          <Title>Package Name</Title>
        </Grid>
        <Grid item xs={9}>
          <Value>{name}</Value>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop: 4}}>
        <Grid item xs={3}>
          <Title>Maximum Participant</Title>
        </Grid>
        <Grid item xs={9}>
          <Value>{maxParticipant}</Value>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop: 4}}>
        <Grid item xs={3}>
          <Title>Price</Title>
        </Grid>
        <Grid item xs={9}>
          <Value>{formatCurrency2(price || 0)}</Value>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop: 4}}>
        <Grid item xs={3}>
          <Title>Description</Title>
        </Grid>
        <Grid item xs={9}>
          <Value>{description}</Value>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EventPrice;

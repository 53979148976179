/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import React, {Component, Fragment} from 'react';
import {
  Paper,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  CardMedia,
  Icon,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Box,
  Tabs,
  Tab,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import {
  Search,
  Refresh,
  CheckCircle,
  Cancel,
  Send,
  SaveAlt,
  Delete,
  Edit,
  Add,
  ArrowBack,
  ArrowForwardIos,
  ArrowBackIos,
} from '@material-ui/icons';
import {
  MInput,
  MKeyBoardDatepicker,
  MButton,
  ModalTitle,
  MSelect,
  MDatepicker,
} from '../../../../components/form';
import ImageCropper from '../../../../components/cropper';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {InventoryDatatable} from '../../../../components';
import {toast, isObject, convDate, convText, showPopup, checkFileUploadSize, blobToFile} from '../../../../helpers';
import {checkStatus as checkStatusUser} from '../../../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../../../redux/organization/organization.actions';
import _ from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';
import {MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import {Badge, Checkbox, FormControlLabel, Stack, fabClasses} from '@mui/material';
import {Verified} from '@mui/icons-material';
import Slider from 'react-slick';
import confirmationIcon from '../../../../assets/icon/partner/confirm.svg';
import completedIcon from '../../../../assets/icon/employeeFacilities/completed.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import {
  getAllManager,
  getAllUser,
  getOneManager,
} from '../../../../redux/userManagement/userManagement.actions';
import {
  getConditionHistory,
  getMovementHistory,
  getConditionHistoryDetail,
  getMasterInventoryDetail,
  createConditionHistory,
  updateConditionHistory,
  deleteConditionHistory,
  deleteImage,
  exportExcel,
  clearState,
} from '../../../../redux/masterInventory/masterInventory.actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {MASTER_INVENTORY_STATE as STATE} from '../../../../redux/masterInventory/masterInventory.types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandMore';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import PropTypes from 'prop-types';
import Datatable from '../../../../components/datatable';
import {create} from 'eslint-plugin-babel/rules/camelcase';


class MasterInventoryDetail extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      sortBy: null,
      order: 'desc',
      query: '',
      created_date: null,
      request_date: null,
      completed_date: null,
      resetDatatableCondition: null,
      resetDatatableMovement: null,
      resetPageCondition: null,
      resetPageMovement: null,
      errorMessage: null,
      showMoreDesc: false,
      modalFormShow: false,
      isEdit: false,
      formData: {
        id: null,
        inventory_id: null,
        date: null,
        notes: null,
        customer_name: null,
        job_title: null,
        employee_id: null,
        createImages: [],
        updateImages: [],
        deletedImages: [],
      },
      informationData: {
        status: null,
        created_date: null,
        inventory_no: null,
        inventory_category: null,
        inventory_name: null,
        description: null,
      },
      ref: null,
      tabValue: false,
      errorUpload: false,
      modalImageShow: false,
      imageDetail: [],
      searchFilterCustomer: null,
      editedImage: false,
    };
  }

  checkboxTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  tableThemeMaster = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: '0px 18px 18px 18px !important',
          },
        },
      },
    },
  })

  modalTitleTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h6: {
            fontSize: 20,
            fontWeight: 600,
            color: '#656464',
          },
        },
      },
    },
  });

  imageListTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
      secondary: {
        main: '#000',
      },
    },
    components: {
      MuiBadge: {
        styleOverrides: {
          badge: {
            color: '#FFF',
            backgroundColor: '#00AFF0',
          },
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    this.fetchDetail();
    this.fetchCondition();
    this.fetchMovement();
    this.props.checkStatus();
    this.props.checkStatusUser();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isLoading, message, messageDelete, isError, isSuccessDelete, isSuccessPost, exportData, deleteTrigger, masterInventoryDataById, isLoadingManager, isSuccesUser, triggerUser, movementHistoryData} = nextProps;

    if (trigger === STATE.EXPORT_SUCCESS ) {
      toast('success', 'File successfully exported & downloaded');
      this.fetchCondition();
      this.fetchMovement();
      this.props.clearState();
    }

    if (isSuccess) {
      if (trigger === STATE.FETCH_DETAIL_SUCCCESS) {
        this.setState({
          informationData: {
            status: masterInventoryDataById.status,
            created_date: masterInventoryDataById.created_date,
            inventory_no: masterInventoryDataById.inventory_no,
            inventory_category: masterInventoryDataById.inventory_category,
            inventory_name: masterInventoryDataById.inventory_name,
            description: masterInventoryDataById.description,
          },
        });
      }
      if (trigger === STATE.CREATE_CONDITION_HISTORY_SUCCCESS ||
            trigger === STATE.UPDATE_CONDITION_HISTORY_SUCCCESS ||
              trigger === STATE.DELETE_CONDITION_HISTORY_SUCCCESS
      ) {
        this.handleResetCondition();
        this.fetchCondition();
        this.setState({
          modalFormShow: false,
        });
        toast('success', message);
        this.props.clearState();
      }
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (this.state.formData.createImages.length + this.state.formData.updateImages.length === 0) this.setState({errorUpload: 'Photo of condition is required'});
      if (!isObject(message)) toast('error', trigger === STATE.DELETE_IMAGE_FAILED ? 'An Error Occured' : message);
    }
  }

  fetchDetail() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    const ref = url.searchParams.get('ref');
    const {page, limit} = this.state;
    this.setState({ref: ref});
    this.props.getMasterInventoryDetail(id);
  }

  handleResetForm = (closeModal) => {
    this.setState({
      errorMessage: null,
      formData: {
        id: null,
        inventory_id: null,
        date: null,
        notes: null,
        customer_name: null,
        job_title: null,
        employee_id: null,
        updateImages: [],
        createImages: [],
        deletedImages: [],
      },
    });
    if (closeModal) {
      this.setState({
        modalDetailShow: false,
      });
    }
  };

  handleSearchCondition = (event) => {
    const {value} = event.target;
    this.setState({query: value}, () => {
      this.handleDebounceSearchCondition(value);
    });
  };

  handleSearchMovement = (event) => {
    const {value} = event.target;
    this.setState({query: value}, () => {
      this.handleDebounceSearchMovement(value);
    });
  };

  toggleResetPageCondition = () => {
    this.setState({resetPageCondition: true, page: 1, limit: 10}, () =>
      this.setState({resetPageCondition: null}),
    );
  };
  toggleResetPageMovement = () => {
    this.setState({resetPageMovement: true, page: 1, limit: 10}, () =>
      this.setState({resetPageMovement: null}),
    );
  };

  handleButtonFilter = () => {
    const {
      page,
      limit,
      sortBy,
      order,
      query,
      created_date,
      request_date,
      complete_date,
      inventory_category_id,
      status,
      method,
    } = this.state;

    // if (start_date === null || end_date === null) {
    //   return this.setState({
    //     errorFilterDate: true,
    //   });
    // }

    this.props.getConditionHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      // ...(query && {query}),
      // ...(inventory_category_id && inventory_category_id !== 'all' && {inventory_category_id}),
      // ...(status && status !== 'all' && {status}),
    });
  }

  handleResetCondition = () => {
    const state = {
      page: 1,
      limit: 10,
      sortBy: null,
      order: 'desc',
      query: '',
      created_date: null,
      request_date: null,
      completed_date: null,
      inventory_category_id: null,
      status: 'all',
      resetDatatableCondition: null,
      resetDatatableMovement: null,
      resetPageCondition: null,
      resetPageMovement: null,
      errorMessage: null,
      errorFilterDate: false,
      checkboxFilter: false,
      modalDetailShow: false,
      formData: {
        id: null,
        inventory_id: null,
        date: null,
        notes: null,
        customer_name: null,
        job_title: null,
        employee_id: null,
        updateImages: [],
        createImages: [],
        deletedImages: [],
      },
    };
    this.setState(state, () => {
      this.fetchCondition();
    });
    this.toggleResetDatatableCondition(true);
    this.toggleResetPageCondition();
  };

  handleResetMovement = () => {
    const state = {
      page: 1,
      limit: 10,
      sortBy: null,
      order: 'desc',
      query: '',
      created_date: null,
      request_date: null,
      completed_date: null,
      inventory_category_id: null,
      status: 'all',
      resetDatatableCondition: null,
      resetDatatableMovement: null,
      resetPageCondition: null,
      resetPageMovement: null,
      errorMessage: null,
      errorFilterDate: false,
      checkboxFilter: false,
      modalDetailShow: false,
      formData: {
        id: null,
        inventory_id: null,
        date: null,
        notes: null,
        customer_name: null,
        job_title: null,
        employee_id: null,
        updateImages: [],
        createImages: [],
        deletedImages: [],
      },
    };
    this.setState(state, () => {
      this.fetchMovement();
    });
    this.toggleResetDatatableMovement(true);
    this.toggleResetPageMovement();
  };

  toggleResetDatatableCondition = (status) => {
    this.setState({resetDatatableCondition: status}, () =>
      this.setState({resetDatatableCondition: null}),
    );
  };
  toggleResetDatatableMovement = (status) => {
    this.setState({resetDatatableMovement: status}, () =>
      this.setState({resetDatatableMovement: null}),
    );
  };

  handleDebounceSearchCondition = _.debounce((query) => {
    this.toggleResetPageCondition();
    this.setState({page: null}, () => {
      this.fetchCondition();
    });
  }, 500);

  handleDebounceSearchMovement = _.debounce((query) => {
    this.toggleResetPageMovement();
    this.setState({page: null}, () => {
      this.fetchMovement();
    });
  }, 500);

  handleDebounceSearchField = _.debounce((query) => {
    this.setState({searchFilterManager: query},
        () => {
          this.fetchManager();
        });
  }, 500);


  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null});
  };

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState(
        {[name]: value, page: null},
    );
  };

  handleFilterCheckbox = (event) => {
    const {checked} = event.target;

    if (checked) {
      this.setState({
        filterCheckbox: checked,
      });

      const state = {
        page: 1,
        limit: 10,
        query: '',
        sortBy: null,
        order: 'desc',
        method: 'all',
        inventory_category_id: 'all',
        status: 'all',
        errorFilterDate: false,
        created_date: convDate(moment()),
        request_date: convDate(moment()),
        completed_date: convDate(moment()),
      };
      this.setState(state, () => {
        this.fetchMovement();
        this.fetchCondition();
      });
      this.toggleResetDatatableCondition(true);
      this.toggleResetPageCondition();
    } else {
      this.setState({
        filterCheckbox: checked,
      });

      const state = {
        page: 1,
        limit: 10,
        query: '',
        sortBy: null,
        order: 'desc',
        method: 'all',
        inventory_category_id: 'all',
        status: 'all',
        created_date: null,
        request_date: null,
        completed_date: null,
      };
      this.setState(state, () => {
        this.fetchMovement();
        this.fetchCondition();
      });
      this.toggleResetDatatableCondition(true);
      this.toggleResetPageCondition();
    }
  }

  handleExport = () => {
    const {
      page,
      limit,
      sortBy,
      order,
      query,
      status,
      inventory_category_id,
    } = this.state;
    const inventory_category = inventory_category_id;
    this.props.exportExcel({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(query && {query}),
      ...(status && status !== 'all' && {status}),
      ...(inventory_category && inventory_category !== 'all' && {inventory_category}),
    });
  }


  fetchManager = () => {
    const {page, limit, all, searchFilterManager} = this.state;
    const queryParams = {
      query: searchFilterManager,
      // all: true
    };
    this.props.getAllManager(queryParams);
  }

  fetchCondition = (tabs) => {
    this.props.checkStatus();
    this.props.checkStatusUser();
    const {
      page,
      limit,
      sortBy,
      order,
      query,
      created_date,
      inventory_category_id,
    } = this.state;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    this.props.getConditionHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(id && {inventory_id: id}),
      ...(query && {query}),
      ...(created_date && {created_date: convDate(created_date)}),
      // ...(inventory_category_id && inventory_category_id !== 'all' && {inventory_category_id}),
    });
  };

  fetchMovement = (tabs) => {
    this.props.checkStatus();
    this.props.checkStatusUser();
    const {
      page,
      limit,
      sortBy,
      order,
      query,
      request_date,
      completed_date,
    } = this.state;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    this.props.getMovementHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(id && {inventory_id: id}),
      ...(query && {query}),
      ...(request_date && {request_date: convDate(request_date)}),
      ...(completed_date && {return_date: convDate(completed_date)}),
      // ...(inventory_category_id && inventory_category_id !== 'all' && {inventory_category_id}),
    });
  };

  handleReloadCondition = (params, tabs) => {
    this.setState(params, () => this.fetchCondition(tabs));
  };

  handleReloadMovement = (params, tabs) => {
    this.setState(params, () => this.fetchMovement(tabs));
  };

  handleConfirmDelete = (data) => {
    showPopup(
        'confirm',
        'Are you sure you want to delete this data ?',
        'Yes',
        () => this.props.deleteConditionHistory(data[1]),
    );
  };

  renderActions = (params) => {
    const svgIcon = (
      <Icon>
        <img style={{display: 'flex', padding: 4, width: 19}} alt="completed" src={completedIcon} />
      </Icon>
    );
    return (
      <Grid container wrap='nowrap' direction='row'>
        <Grid item>
          <Tooltip title="Edit">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color="primary"
                size='small'
                onClick={() => this.handleFormToggle('edit', params)}
                startIcon={<Edit />}
                style={{
                  'backgroundColor': '#00AFF0',
                  'boxShadow': 'none',
                  'color': '#fff',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#00A2DE',
                  },
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Delete">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color="secondary"
                size='small'
                onClick={() => this.handleConfirmDelete(params)}
                startIcon={<Delete />}
                style={{
                  'backgroundColor': '#E5AF5A',
                  'boxShadow': 'none',
                  'color': '#fff',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#b78c48',
                  },
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  toggleTimelineAccordion = () => {
    this.setState({
      timelineAccordion: !this.state.timelineAccordion,
    });
  }

  handleInputChange = (event) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = event.target;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  handleFormToggle = async (type, data) => {
    const {
      creatable,
      creatableMessage,
      isSuperAdmin,
      orgStatusData,
    } = this.props;


    if (type === 'add') {
      // this.handleReset();
      this.setState({
        isEdit: false,
        errorMessage: false,
        errorUpload: false,
        modalFormShow: !this.state.modalFormShow,
        editedImage: false,
      });
    } else if (type === 'edit') {
      const imagesData = [];
      const listImages = data[7] ? data[7] : [];
      for (let i = 0; i < data[7].length; i++) {
        imagesData.push(listImages[i].img);
      }

      if (data[8] === null) {
        this.setState({
          isEdit: true,
          editedImage: false,
          errorMessage: false,
          errorUpload: false,
          modalFormShow: !this.state.modalFormShow,
          formData: {
            id: data[1],
            inventory_id: data[2],
            date: data[3],
            notes: data[4],
            customer_name: null,
            job_title: data[6],
            employee_id: null,
            createImages: [],
            updateImages: imagesData,
            deletedImages: [],
          },
        });
      } else {
        const manager = {
          id: data[8], name: data[5],
        };
        this.setState({
          isEdit: true,
          editedImage: false,
          errorMessage: false,
          errorUpload: false,
          modalFormShow: !this.state.modalFormShow,
          formData: {
            id: data[1],
            inventory_id: data[2],
            date: data[3],
            notes: data[4],
            customer_name: manager,
            job_title: data[6],
            employee_id: manager,
            createImages: [],
            updateImages: imagesData,
            deletedImages: [],
          },
        });
      }
      // this.handleReset();
    } else {
      // this.handleReset();
      this.setState({
        isEdit: false,
        editedImage: false,
        errorMessage: false,
        errorUpload: false,
        formData: {
          id: null,
          inventory_id: null,
          date: null,
          notes: null,
          customer_name: null,
          job_title: null,
          employee_id: null,
          updateImages: [],
          createImages: [],
          deletedImages: [],
        },
        modalFormShow: !this.state.modalFormShow,
      });
    }
  };

  handleNavigationBack = (ref) => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const urlRef = url.searchParams.get('ref');

    if (urlRef * 1 === 1) {
      this.props.history.push('/master-inventory');
    } else if (urlRef * 1 === 2) {
      this.props.history.push('/office-inventory');
    }
  }

  renderNavigation = () => {
    const {classes} = this.props;

    const {ref} = this.state;

    return (
      <Paper variant='outlined' className={classes.paperNavigation}>
        <div className="d-flex" style={{display: 'flex', alignItems: 'center'}}>
          <Link className={classes.btnBack}>
            <ArrowBack style={{cursor: 'pointer'}} onClick={(ref) => this.handleNavigationBack(ref)} />
          </Link>
          <Typography
            className={classes.paperTitle}
          >
            Inventory Detail
          </Typography>
        </div>
      </Paper>
    );
  }

  renderInventoryInformation = () => {
    const {classes} = this.props;
    const {
      status,
      created_date,
      inventory_no,
      inventory_category,
      inventory_name,
      description,
    } = this.state.informationData;
    const {showMoreDesc} = this.state;

    return (
      <Paper variant='outlined' className={classes.paperInformation}>
        <Typography className={classes.inventoryInformation}>Inventory Information</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.statusPaper} variant='outlined'>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography className={classes.statusText}>Status</Typography>
                <Typography className={status === 'available' ? classes.statusValueAvailable :
                  status === 'unavailable' ? classes.statusValueUnavailable : null}>
                  {status ? status : '-'}
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2} style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>Created Date</Typography>
          </Grid>
          <Grid item style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>:</Typography>
          </Grid>
          <Grid item style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>{created_date ? convDate(created_date, 'DD-MM-YYYY') : '-'}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2} style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>No. Inventory</Typography>
          </Grid>
          <Grid item style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>:</Typography>
          </Grid>
          <Grid item style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>{inventory_no ? inventory_no : '-'}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2} style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>Inventory Category</Typography>
          </Grid>
          <Grid item style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>:</Typography>
          </Grid>
          <Grid item style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>{inventory_category ? inventory_category : '-'}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2} style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>Inventory Name</Typography>
          </Grid>
          <Grid item style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>:</Typography>
          </Grid>
          <Grid item style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>{inventory_name ? inventory_name : '-'}</Typography>
          </Grid>
        </Grid>

        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={2} style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>Description</Typography>
          </Grid>
          <Grid item style={{marginBottom: 4}}>
            <Typography className={classes.informationSubtitle}>:</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography className={classes.informationDetail}>
              {
                description && showMoreDesc ? description :
                    description && description.length <= 70 && !showMoreDesc ? `${description.substring(0, 70)}` :
                      description && description.length > 70 && !showMoreDesc ? `${description.substring(0, 70)}...` : '-'
              }
            </Typography>
            {description && description.length > 50? (
              <Link underline='hover' onClick={() => this.setState({showMoreDesc: !this.state.showMoreDesc})}>
                <Typography className={classes.showMore} >
                  {!showMoreDesc ? 'Show More' : 'Show Less'}
                </Typography>
              </Link>
            ) : (<></>)}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {isEdit, editedImage} = this.state;
    const {
      id,
      date,
      notes,
      job_title,
      customer_name,
      createImages,
      updateImages,
      deletedImages,
      employee_id,
    } = this.state.formData;


    const totalImage = createImages?.length + updateImages?.length;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const inventory_id = url.searchParams.get('id') * 1;

    if (totalImage === 0 && date && notes) {
      return this.setState({
        errorUpload: 'Photo of condition is required',
      });
    }


    const dataForm = new FormData();
    for (let i = 0; i < createImages?.length; i++) {
      dataForm.append('history_inventory_img', (blobToFile(createImages[i])));
    }
    if (date) {
      dataForm.append('date', convDate(date, 'YYYY-MM-DD'));
    }
    if (notes) {
      dataForm.append('notes', notes);
    }
    if (customer_name) {
      dataForm.append('employee_id', customer_name.id);
    }

    if (!isEdit) {
      if (inventory_id) {
        dataForm.append('inventory_id', inventory_id);
      }
    }
    !isEdit ? this.props.createConditionHistory(dataForm) : this.props.updateConditionHistory(id, dataForm);
    if (deletedImages?.length > 0) {
      const params = [];
      for (let i = 0; i < deletedImages?.length; i++) {
        params.push(deletedImages[i]);
      }
      this.props.deleteImage(id, {img_url: params});
    }
  };

  handleConditionDate = (params) => {
    const {value, name} = params;
    this.toggleResetPageCondition();
    this.setState({[name]: value, page: null}, () => {
      this.fetchCondition();
    });
  };

  handleMovementDate = (params) => {
    const {value, name} = params;
    this.toggleResetPageMovement();
    this.setState({[name]: value, page: null}, () => {
      this.fetchMovement();
    });
  };

  handleDateChange = (params) => {
    const {formData, errorMessage, isEdit} = this.state;
    const {name, value} = params;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  selectHandler = (event, value) => {
    if (value) {
      value.id = value.employee_id;
    }
    this.setState({
      formData: {
        ...this.state.formData,
        customer_name: value ? value : null,
      },
    });
  }

  searchCombo = (event = null, value) => {
    this.handleDebounceSearchField(event.target.value);
  }

  handleRemoveImage = (data, type) => {
    const {createImages, updateImages, deletedImages} = this.state.formData;
    const {isEdit} = this.state;

    if (type === 'create') {
      const filterCreatedImage = createImages.filter(function(e) {
        return e.name !== data.name;
      });
      this.setState({
        formData: {
          ...this.state.formData,
          createImages: filterCreatedImage,
        },
      });
    }

    if (type === 'update') {
      const filterUpdatedImage = updateImages.filter(function(e) {
        return e !== data;
      });

      deletedImages.push(data);
      this.setState({
        formData: {
          ...this.state.formData,
          updateImages: filterUpdatedImage,
          deletedImages: deletedImages,
        },
      });
    }
  }

  handleUploadMultiple = (e) => {
    const {createImages} = this.state.formData;
    const listImage = [];
    const data = e.target.files;
    const {isEdit} = this.state;

    for (let i = 0; i < data?.length; i++) {
      const imageSizeIsAllowed = checkFileUploadSize(data[i]);
      let message;
      if (imageSizeIsAllowed === false) {
        message =
          'Maximum size each file is ' +
          process.env.REACT_APP_MAX_UPLOAD_SIZE_MB +
            ' MB';
        return this.setState({
          errorUpload: message,
        });
      }
      if (imageSizeIsAllowed === null) {
        message = 'Please select image';
        return this.setState({
          errorUpload: message,
        });
      }
    }

    // For Display
    for (let i = 0; i < createImages?.length; i++) {
      listImage.push(createImages[i]);
    }

    for (let i = 0; i < data?.length; i++) {
      listImage.push(data[i]);
    }


    if (listImage?.length > 10) {
      return this.setState({
        errorUpload: 'Maximum upload size exceeded, max 10 photo',
      });
    }
    this.setState({
      editedImage: true,
      formData: {
        ...this.state.formData,
        createImages: listImage,
      },
      errorUpload: false,
    });
  }

  renderModalForm = () => {
    const {
      classes,
      isLoading,
      conditionHistoryData,
      managerData,
      isLoadingManager,
      userData,
    } = this.props;
    const {
      id,
      date,
      notes,
      job_title,
      customer_name,
      employee_id,
      createImages,
      updateImages,
    } = this.state.formData;
    const {
      modalFormShow,
      isEdit,
      errorMessage,
      errorUpload,
    } = this.state;

    const listImages = [];

    for (let i = 0; i < createImages.length; i++) {
      listImages.push(URL.createObjectURL(createImages[i]));
    }
    for (let i = 0; i < updateImages.length; i++) {
      listImages.push(updateImages[i]);
    }


    const ManagerData = [];
    userData.map((value) => {
      return ManagerData.push(value.id);
    });
    const manager_id = userData?.filter((value) => {
      return value.id === id;
    });
    const optionEdit = managerData?.filter((value) => {
      return value.name !== customer_name;
    });

    const titleDialog = isEdit ? 'Update Inventory Condition' : 'Create Inventory Condition';

    return (
      <Dialog
        open={modalFormShow}
        onClose={() => this.handleFormToggle('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <MuiThemeProvider theme={this.modalTitleTheme()}>
          <ModalTitle
            style={{fontSize: 20, fontWeight: 600, color: '#656464'}}
            title={titleDialog}
            onClose={() => this.handleFormToggle('close')}
          />
        </MuiThemeProvider>
        <form autoComplete='off'>
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDatepicker
                  className={classes.formControlModal}
                  name="date"
                  label="Date *"
                  value={date}
                  onDateChange={this.handleDateChange}
                  error={
                    errorMessage && errorMessage.date ? errorMessage.date : undefined
                  }
                  placeholder="dd-mm-yyyy"
                  maxDate={new Date()}
                  inputVariant="outlined"
                  disableFuture
                  openTo="year"
                  views={['year', 'month', 'date']}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MInput
                  classNameFC={classes.formControlModal}
                  name="notes"
                  label="Condition *"
                  placeholder="Enter inventory condition"
                  defaultValue={notes}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.notes ?
                      errorMessage.notes :
                      undefined
                  }
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  className={classes.formControl}
                  id="free-solo-demo"
                  freeSolo
                  options={isEdit ? optionEdit : managerData}
                  value={customer_name ? {id: customer_name.id, name: customer_name.name} : null}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => this.selectHandler(event, value)}
                  style={{position: 'relative'}}
                  disabled={isEdit ? true : false}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Customer Name"
                        placeholder="Enter Customer Name"
                        variant="outlined"
                        fullWidth
                        onChange={(event, value) => this.searchCombo(event, value)}
                        disabled={isEdit ? true : false}
                        // onClick={() => this.searchCombo()}
                      />
                      {isLoadingManager ? <CircularProgress color="inherit" size={24} style={{position: 'absolute', right: '35px', top: '15px'}}/> : null}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Typography className={errorUpload === false ? classes.photoTitle : classes.photoTitleError}>
                    Photo of Condition*
                  </Typography>
                  <Button
                    variant="contained"
                    component="label"
                    endIcon={<Add />}
                    className={classes.buttonUpload}
                    disabled={listImages?.length >= 10 ? true : false}
                  >
                    Upload Image
                    <input
                      type="file"
                      id='fileUpload'
                      multiple
                      hidden
                      accept="image/*"
                      onChange= {(e) => this.handleUploadMultiple(e)}
                    />
                  </Button>
                  <Typography style={{marginBottom: 4, marginTop: 2}} className={errorUpload === false ? classes.photoSubTitle : classes.photoSubTitleError}>
                    {errorUpload === false ? `Must be .jpg/.jpeg with max size ${process.env.REACT_APP_MAX_UPLOAD_SIZE_MB} MB & max 10 photo` : errorUpload}
                  </Typography>
                </div>
              </Grid>
              {updateImages && updateImages?.length > 0 ?
                updateImages.map((value, index) => {
                  return (
                    <Grid item xs={2} key={index}>
                      <Stack direction="row" sx={{color: 'action.active'}}>
                        <MuiThemeProvider theme={this.imageListTheme()}>
                          <Badge style={{cursor: 'pointer'}} onClick={() => this.handleRemoveImage(value, 'update')} color='primary' badgeContent={'x'}>
                            <img
                              src={value}
                              srcSet={value}
                              alt={`img-${index + 1}`}
                              loading="lazy"
                              style={{width: 72, height: 72, borderRadius: 8}}
                            />
                          </Badge>
                        </MuiThemeProvider>
                      </Stack>
                    </Grid>
                  );
                }) : null}
              {createImages && createImages?.length > 0 ?
                createImages.map((value, index) => {
                  return (
                    <Grid item xs={2} key={index}>
                      <Stack direction="row" sx={{color: 'action.active'}}>
                        <MuiThemeProvider theme={this.imageListTheme()}>
                          <Badge style={{cursor: 'pointer'}} onClick={() => this.handleRemoveImage(value, 'create')} color='primary' badgeContent={'x'}>
                            <img
                              src={URL.createObjectURL(value)}
                              srcSet={URL.createObjectURL(value)}
                              alt={`img-${index + 1}`}
                              loading="lazy"
                              style={{width: 72, height: 72, borderRadius: 8}}
                            />
                          </Badge>
                        </MuiThemeProvider>
                      </Stack>
                    </Grid>
                  );
                }) : null}
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.handleFormToggle('close')}
            />
            <MButton
              className={classes.defaultBtn}
              onClick={this.handleSubmit}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              loading={isLoading ? true : false}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  renderModalImage = () => {
    const {classes} = this.props;
    const {modalImageShow, imageDetail} = this.state;
    const titleDialog = 'Photo of Condition';

    return (
      <Dialog
        open={modalImageShow}
        onClose={() => this.toggleModalImage('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <MuiThemeProvider theme={this.modalTitleTheme()}>
          <ModalTitle
            className={classes.modalTitle}
            title={titleDialog}
            onClose={() => this.toggleModalImage('close')}
          />
        </MuiThemeProvider>
        <form autoComplete='off'>
          <DialogContent className={classes.dialogContent}>
            <Carousel
              showArrows={true}
              showIndicators={false}
              useKeyboardArrows={true}
              dynamicHeight={true}
              renderArrowNext={
                (onClickHandler, hasNext, label) =>
                  hasNext && (
                    <IconButton
                      onClick={onClickHandler}
                      title={label}
                      className={classes.btnNavForward}
                    >
                      <ArrowForwardIos style={{position: 'relative', left: 2}}/>
                    </IconButton>
                  )
              }
              renderArrowPrev={
                (onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <IconButton
                      onClick={onClickHandler}
                      title={label}
                      className={classes.btnNavBack}
                    >
                      <ArrowBackIos style={{position: 'relative', left: 4}}/>
                    </IconButton>
                  )
              }
            >
              {imageDetail.map((value, index) => {
                return (
                  <div key={index}>
                    <img src={value.original} alt="imageDetail"/>
                  </div>
                );
              })}
            </Carousel>
          </DialogContent>
        </form>
      </Dialog>
    );
  }

  renderTabs = () => {
    const {classes} = this.props;
    const {tabValue, query, created_date, request_date, completed_date} = this.state;

    return (
      <>
        <div style={{display: 'flex', position: 'relative', top: 1}}>
          <Button
            className={tabValue === false ? classes.btnTabActive : classes.btnTabInactive1}
            onClick={() => {
              this.setState({tabValue: false, query: ''});
              this.handleResetCondition();
            }}
          >
            Condition History
          </Button>
          <Button
            onClick={() => {
              this.setState({tabValue: true, query: ''});
              this.handleResetMovement();
            }}
            style={{marginLeft: 1}}
            className={tabValue === true ? classes.btnTabActive : classes.btnTabInactive2}
          >
          Movement Item History
          </Button>
        </div>
        <Paper variant='outlined' className={classes.paperTableFilter}>
          {tabValue === false ? (
            <>
              <MInput
                autoFocus
                className={`${classes.filter} ${classes.formControlSearch}`}
                fullWidth={false}
                variant="outlined"
                name="query"
                label="Search"
                placeholder="Search keyword"
                value={query}
                onChange={this.handleSearchCondition}
                InputProps={{
                  endAdornment: (
                    <IconButton position="end">
                      <Search className={classes.searchIcon} />
                    </IconButton>
                  ),
                }}
              />
              <MDatepicker
                className={`${classes.filter} ${classes.formControlDate}`}
                name="created_date"
                  label="Created Date"
                  value={created_date}
                  onDateChange={this.handleConditionDate}
                  placeholder="dd-mm-yyyy"
                  maxDate={new Date()}
                  inputVariant="outlined"
                  disableFuture
                  openTo="year"
                  views={['year', 'month', 'date']}
                  fullWidth
                />
                <MButton
                  className={classes.filterBtnReset}
                  label="Reset"
                  color="primary"
                  icon={<Refresh />}
                  onClick={this.handleResetCondition}
                />
            </>
          ) : (
            <>
              <MInput
                autoFocus
                className={`${classes.filter} ${classes.formControlSearch}`}
                fullWidth={false}
                variant="outlined"
                name="query"
                label="Search"
                placeholder="Search keyword"
                value={query}
                onChange={this.handleSearchMovement}
                InputProps={{
                  endAdornment: (
                    <IconButton position="end">
                      <Search className={classes.searchIcon} />
                    </IconButton>
                  ),
                }}
              />
              <MDatepicker
                className={`${classes.filter} ${classes.formControlDate}`}
                name="request_date"
                  label="Request Date"
                  value={request_date}
                  onDateChange={this.handleMovementDate}
                  placeholder="dd-mm-yyyy"
                  maxDate={new Date()}
                  inputVariant="outlined"
                  disableFuture
                  openTo="date"
                  views={['date', 'month', 'year']}
                  fullWidth
                />
              <MDatepicker
                className={`${classes.filter} ${classes.formControlDate}`}
                name="completed_date"
                  label="Completed Date"
                  value={completed_date}
                  onDateChange={this.handleMovementDate}
                  placeholder="dd-mm-yyyy"
                  maxDate={new Date()}
                  inputVariant="outlined"
                  disableFuture
                  openTo="date"
                  views={['date', 'month', 'year']}
                  fullWidth
                />
                <MButton
                  className={classes.filterBtnReset}
                  label="Reset"
                  color="primary"
                  icon={<Refresh />}
                  onClick={this.handleResetMovement}
                />
            </>
          )}

        </Paper>
      </>
    );
  }

  toggleModalImage = (type, data) => {
    const images = [];
    if (type === 'open') {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          images.push({
            original: data[i].img,
            thumbnail: data[i].img,
          });
        }
      }
      this.setState({
        modalImageShow: !this.state.modalImageShow,
        imageDetail: images,
      });
    } else {
      this.setState({
        modalImageShow: false,
        imageDetail: [],
      });
    }
  }


  render() {
    const {classes, conditionHistoryData, conditionHistoryMeta, movementHistoryData, movementHistoryMeta, isLoading, isLoadingMovement} = this.props;
    const {resetDatatableCondition, resetDatatableMovement, resetPageCondition, resetPageMovement, tabValue} = this.state;

    const columns = [
      {name: 'id', label: 'Id', display: false},
      {name: 'inventory_id', label: 'Inventory Id', display: false},
      {
        name: 'date',
        label: 'Date',
        display: true,
        customBodyRender: (value) => (value ? convDate(value, 'DD-MM-YYYY') : '-'),
      },
      {name: 'notes', label: 'Description', display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {name: 'customer_name', label: 'Customer Name', display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'job_title',
        label: 'Job Title', display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'image',
        label: 'Photo Of Condition',
        display: true,
        customBodyRender: (value) => (value ? (
          <Stack direction="row" sx={{color: 'action.active'}}>
            <MuiThemeProvider theme={this.imageListTheme()}>
              {value.length > 1 ? (
                <Badge style={{cursor: 'pointer'}} onClick={() => this.toggleModalImage('open', value)} color='primary' badgeContent={`+${value.length -1}`}>
                  <img style={{
                    width: 54,
                    height: 48,
                    cursor: 'pointer',
                  }} src={value[0]?.img} alt="photoCondition"/>
                </Badge>
              ) : (
                <img onClick={() => this.toggleModalImage('open', value)} style={{
                  width: 54,
                  height: 48,
                  cursor: 'pointer',
                }} src={value[0]?.img} alt="photoCondition"/>
              )
              }
            </MuiThemeProvider>
          </Stack>
        ) : ('-')),

      },
      {
        name: 'employee_id', // Data [8]
        label: 'Employee Id', display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
    ];

    const columnsMovement = [
      {name: 'id', label: 'Id', display: false},
      {name: 'employee_id', label: 'Employee Id', display: false},
      {name: 'employee_name', label: 'Customer Name', display: true},
      {
        name: 'job_title',
        label: 'Job Title',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'request_date',
        label: 'Request Date',
        display: true,
        customBodyRender: (value) => (value ? convDate(value, 'DD-MM-YYYY') : '-'),
      },
      {
        name: 'return_date',
        label: 'Completed Date',
        display: true,
        customBodyRender: (value) => (value ? convDate(value, 'DD-MM-YYYY') : '-'),
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          if (value === 'request') {
            return <strong style={{color: '#00A2DE'}}>{'Request'}</strong>;
          } else if (value === 'on_going') {
            return <strong style={{color: '#FFC226'}}>{'On Going'}</strong>;
          } else if (value === 'completed') {
            return <strong style={{color: '#008000'}}>{'Completed'}</strong>;
          } else if (value === 'rejected') {
            return <strong style={{color: '#F00B38'}}>{'Rejected'}</strong>;
          } else {
            return '-';
          }
        },
      }];

    const dummyMovement = [
      {
        id: 1,
        customer_name: 'Cayena Hill',
        job_title: 'Web Designer',
        request_date: '05-05-2023',
        completed_date: null,
        status: 'on_going',
      },
      {
        id: 2,
        customer_name: 'Roxana Agriche',
        job_title: 'Quality Assurance',
        request_date: '04-04-2023',
        completed_date: '05-05-2023',
        status: 'completed',
      },
    ];

    return (
      <div className={classes.root}>
        {this.renderNavigation()}
        {this.renderInventoryInformation()}
        {this.renderTabs()}
        {tabValue === false ? (
          <MuiThemeProvider theme={this.tableThemeMaster()}>
            <InventoryDatatable
              title={'Condition History'}
              loading={isLoading}

              // Comment this if using API Data
              // dataSource={dummyData}
              // total={dummyData.length}
              // page={1}
              //
              // Comment this if using Dummy Data
              dataSource={conditionHistoryData}
              total={conditionHistoryMeta ? conditionHistoryMeta.total : 0}
              page={conditionHistoryMeta ? conditionHistoryMeta.page : 1}
              //
              columns={columns}
              handleReload={(params) => this.handleReloadCondition(params, 'condition')}
              handleCreate={() => this.handleFormToggle('add')}
              // handleDetail={(params) => this.toggleModalDetail('open', params)}
              customActions={(params) => this.renderActions(params)}
              toggleResetAll={resetDatatableCondition}
              toggleResetPage={resetPageCondition}
              manualNumbering={false}
            />
          </MuiThemeProvider>
        ) : (
          <InventoryDatatable
            title={'Movement Item History List'}
            loading={isLoadingMovement}

            // Comment this if using API Data
            // dataSource={dummyMovement}
            // total={dummyMovement.length}
            // page={1}
            //
            // Comment this if using Dummy Data
            dataSource={movementHistoryData}
            total={movementHistoryMeta ? movementHistoryMeta.total : 0}
            page={movementHistoryMeta ? movementHistoryMeta.page : 1}
            //
            columns={columnsMovement}
            handleReload={(params) => this.handleReloadMovement(params, 'movement')}
            handleCreate={() => this.handleFormToggle('add')}
            // handleDetail={(params) => this.toggleModalDetail('open', params)}
            toggleResetAll={resetDatatableMovement}
            toggleResetPage={resetPageMovement}
            manualNumbering={false}
            creatable={false}
            hideActions={true}
          />
        )}
        {this.renderModalForm()}
        {this.renderModalImage()}
      </div>
    );
  }
}

const mapStateToProps = ({masterInventory, userManagement}) => {
  return {

    isLoadingManager: userManagement.isLoadingManager,
    isLoadingUser: userManagement.isLoading,
    isErrorUser: userManagement.isError,
    isSuccessUser: userManagement.isSuccess,
    messageUser: userManagement.message,
    triggerUser: userManagement.trigger,
    managerData: userManagement.managerData,
    userData: userManagement.userData,


    isLoading: masterInventory.isLoading,
    isLoadingMovement: masterInventory.isLoadingMovement,
    isLoadingDelete: masterInventory.isLoadingDelete,
    isError: masterInventory.isError,
    isErrorMovement: masterInventory.isErrorMovement,
    isSuccess: masterInventory.isSuccess,
    isSuccessMovement: masterInventory.isSuccessMovement,
    isSuccessDelete: masterInventory.isSuccessDelete,
    message: masterInventory.message,
    messageMovement: masterInventory.messageMovement,
    trigger: masterInventory.trigger,
    triggerMovement: masterInventory.triggerMovement,
    triggerDelete: masterInventory.triggerDelete,
    conditionHistoryData: masterInventory.conditionHistoryData,
    conditionHistoryMeta: masterInventory.conditionHistoryMeta,
    movementHistoryData: masterInventory.movementHistoryData,
    movementHistoryMeta: masterInventory.movementHistoryMeta,
    conditionHistoryDataById: masterInventory.conditionHistoryDataById,
    masterInventoryDataById: masterInventory.masterInventoryDataById,

    isLoadingPut: masterInventory.isLoadingPut,
    isSuccessPut: masterInventory.isSuccessPut,
    isFailurePut: masterInventory.isFailurePut,
    isErrorPut: masterInventory.isErrorPut,
    messagePut: masterInventory.messagePut,
    status: masterInventory.status,
    exportLoading: masterInventory.exportLoading,
  };
};
const mapDispatchToProps = (dispatch) => ({
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),

  getConditionHistory: (params) => dispatch(getConditionHistory(params)),
  getMovementHistory: (params) => dispatch(getMovementHistory(params)),
  getConditionHistoryDetail: (id) => dispatch(getConditionHistoryDetail(id)),
  getMasterInventoryDetail: (id) => dispatch(getMasterInventoryDetail(id)),
  createConditionHistory: (params) => dispatch(createConditionHistory(params)),
  updateConditionHistory: (id, params) => dispatch(updateConditionHistory(id, params)),
  deleteConditionHistory: (id, params) => dispatch(deleteConditionHistory(id, params)),
  deleteImage: (id, params) => dispatch(deleteImage(id, params)),
  exportExcel: (params) => dispatch(exportExcel(params)),

  getAllManager: (params) => dispatch(getAllManager(params)),
  getAllUser: (params) => dispatch(getAllUser(params)),
  getOneManager: (id) => dispatch(getOneManager(id)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(MasterInventoryDetail));

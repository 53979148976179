export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  message: null,
};

export const ACCOUNT_STATE = {
  REQUEST: 'ACCOUNT_REQUEST',
  REQUEST_FAILED: 'ACCOUNT_REQUEST_FAILED',
  CLEAR_STATE: 'ACCOUNT_CLEAR_STATE',
  CHECK_TOKEN: 'ACCOUNT_CHECK_TOKEN',
  CHECK_TOKEN_SUCCESS: 'ACCOUNT_CHECK_TOKEN_SUCCESS',
  CHECK_TOKEN_FAILED: 'ACCOUNT_CHECK_TOKEN_FAILED',
  CHANGE_PASSWORD: 'ACCOUNT_CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'ACCOUNT_CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: 'ACCOUNT_CHANGE_PASSWORD_FAILED',
  FORGOT_PASSWORD: 'ACCOUNT_FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'ACCOUNT_FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILED: 'ACCOUNT_FORGOT_PASSWORD_FAILED',

};

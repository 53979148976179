/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import bgApprove from '../../assets/img/bg.png';
import {Card} from 'reactstrap';
import './styles.css';
class PageNotFound extends React.Component {
  render() {
    return (
    <div className="container-wrap" style={{
        width: '100%',
        height: '100vh',
        padding: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${bgApprove})`,
        backgroundSize: 'cover',
        }}>
        <Card className="card-box" style={{
            width: '70%',
            height: 'auto',
            padding: '2em 5em',
            backgroundColor: 'rgb(255 255 255 / 75%)',
        }}>
            <h1 style={{textalign: 'center'}}>419 Page expired.</h1>
        </Card>
        </div>
    );
  }
}
export default PageNotFound;

export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  detailLoading: false,
  detailError: false,
  detailSuccess: false,
  detailData: '',
  exportLoading: false,
  exportData: {file: null},
  trigger: null,
  message: null,
  attendanceData: [],
  attendanceMeta: {page: 1, total: 0},
};

export const ATTENDANCE_STATE = {
  REQUEST: 'ATTENDANCE_REQUEST',
  REQUEST_FAILED: 'ATTENDANCE_REQUEST_FAILED',
  FETCH: 'ATTENDANCE_FETCH',
  FETCH_SUCCESS: 'ATTENDANCE_FETCH_SUCCESS',
  FETCH_FAILED: 'ATTENDANCE_FETCH_FAILED',
  FETCH_DETAIL: 'FETCH_DETAIL',
  FETCH_DETAIL_SUCCESS: 'FETCH_DETAIL_SUCCESS',
  FETCH_DETAIL_FAILED: 'FETCH_DETAIL_FAILED',
  EXPORT: 'ATTENDANCE_EXPORT',
  EXPORT_SUCCESS: 'ATTENDANCE_EXPORT_SUCCESS',
  EXPORT_FAILED: 'ATTENDANCE_EXPORT_FAILED',
};

export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: theme.palette.text.secondary,
    marginBottom: 10,
  },
  datatable: {
    marginTop: 25,
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  subTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#656464',
    marginBottom: 8,
  },
  checkBox: {
    width: 70,
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#0198D0',
    },
    'color': '#fff',
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow: 'none',
    },
  },
  hasError: {
    color: 'red',
  },
  photo: {
    height: 200,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
  filterBtn: {
    marginTop: 25,
    marginRight: 15,
    marginLeft: 10,
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 0,
  },
  filter: {
    marginRight: 25,
  },
  filterSearch: {
    minWidth: 300,
    marginRight: 25,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  dialogFooter: {
    padding: 20,
  },
  dialogContent: {
    paddingTop: '0 !important',
    minWidth: 600,
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
});

export default (theme) => ({
    btnHeader: {
        borderRadius: '4px',
        paddingTop: '4px !important',
        paddingRight: '16px',
        paddingBottom: '4px',
        paddingLeft: '16px',
    },
    btnSaveDraft: {
        'color': '#FFF',
        'backgroundColor': '#E5AF5A',
        'marginRight': '10px',
        '&:hover': {
            backgroundColor: '#E5AF5A',
        },
    },
    fileInput: {
        marginTop: '16px',
        marginBottom: '4px',
    },
    btnPublish: {
        'color': '#FFF',
        'backgroundColor': '#00AFF0',
        '&:hover': {
            backgroundColor: '#00AFF0',
        },
    },
    topBox: {
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        backgroundColor: '#FFF',
        padding: '16px, 24px, 16px, 24px',
        marginBottom: '16px',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '24px',
        paddingRight: '24px',
        paddingBottom: '16px',
        paddingLeft: '24px',
    },
    formTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '14px',
    },
    subTitle: {
        fontSize: '16px',
        fontWeight: 600,
    },
    eventPaymentPackageHeader: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#00AFF0',
    },
    eventPaymentPackageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    formControl: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        minWidth: 100,
    },
    imgPreviewLabel: {
        fontStyle: 'italic',
        fontSize: '12px',
        color: '#656464',
    },
    editorWrapper: {
        'border': '1px solid rgba(0, 0, 0, 0.23)',
        'borderRadius': '4px',
        'padding': '8px',
        'minHeight': '200px',
        'display': 'flex',
        'alignItems': 'center',
        '&:hover': {
            border: '1px solid rgba(0, 0, 0, 0.87)',
        },
    },
    editorWrapperError: {
        'border': '1px solid #f44336',
        'borderRadius': '4px',
        'padding': '8px',
        'minHeight': '200px',
        'display': 'flex',
        'alignItems': 'center',
        '&:hover': {
            border: '1px solid #f44336',
        },
    },
});

/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {
  Paper,
  CircularProgress,
  Link,
} from '@material-ui/core';
import queryString from 'query-string';
import {Refresh} from '@material-ui/icons';
import {MButton, MInput} from '../../../components/form';
import {ORGANIZATION_STATE as STATE} from '../../../redux/organization/organization.types';
import {
  confirmInvitationChildOrganization,
  activateParentInvitationOrganization,
} from '../../../redux/organization/organization.actions';
import {REGISTER_STATE} from '../../../redux/register/register.types';
import {toast, isObject, checkSuperadmin, checkMobile, resetLoader} from '../../../helpers';
import {submitApproval} from '../../../redux/register/register.actions';
import WebTemplate from '../../../components/template';

class EmailVerification extends React.Component {
  constructor() {
    super();
    this.state = {
        status: null,
        message: null,
        email: null,
        token: null,
        new_password: null,
        new_password_confirm: null,
        errorMessage: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {isTrigger, message} = nextProps;

    if (isTrigger === 'CONFIRM_INVITATION_SUCCESS') {
      toast('success', message.data.message);
      this.props.history.push('/login');
    }

    // if (registerTrigger === REGISTER_STATE.APPROVAL_SUCCESS && this.props.registerMessage !== registerMessage) {
    //   this.setState({status: 'success', message: registerMessage});
    // }

    // if (registerTrigger === REGISTER_STATE.APPROVAL_FAILED && this.props.registerMessage !== registerMessage) {
    //   if (!isObject(registerMessage)) {
    //     this.setState({status: 'error', message: registerMessage});
    //   }
    //   if (message) {
    //     toast('error', message)
    //   }
    //   this.props.history.replace('/419')
    //   setTimeout(() => {
    //     this.props.history.replace('/')
    //   }, 2000);

    // }

    // if (isError && message) {
    //   if (isObject(message)) this.setState({errorMessage: message});
    //   if (!isObject(message)) toast('error', message);
    // }
  }

  componentDidMount() {

    // this.checkApproval();
  }

//   checkApproval = () => {
//     const location = this.props.location.pathname;
//     const segment = location.substring(location.lastIndexOf('/') + 1);
//     const token = segment !== 'account-activation' ? segment : null;
//     console.log(token);
//     if (token) {
//       this.props.submitApproval({
//         ...(token && {token}),
//       });
//     } else {
//       this.props.history.push('/dashboard');
//     }
//   }

  handleReset = () => {
    this.setState({
      status: null,
      message: null,
    });
  }

  renderLoading = () => {
    const {classes} = this.props;
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress className={classes.loader} color="inherit" size={24} />
        <div className={classes.loaderText}>{'Processing...'}</div>
      </div>
    );
  }

  renderResponse = () => {
    const {message} = this.state;
    const {classes} = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.message}>{message}</div>
        <MButton
          className={classes.btnBack}
          label="Go Back"
          icon={<Refresh/>}
          onClick={() => this.props.history.push('/company-management')}
        />
      </div>
    );
  }

  handleBackToNative = (e) => {
    const payload = {type: 'backToNative'};
    const json = (JSON.stringify(payload));
    window.postMessage(json, '*');
  }

  handleBackToWeb = (e) => {
    e.preventDefault();
    const baseURL = window.location.origin;
    window.location.href = baseURL + '/login';
  }

  onClickSendConfirmation = () => {
    const location = this.props.location.pathname;
    const segment = location.substring(location.lastIndexOf('/') + 1);

    const payload = {
      token: segment,
    };

    this.props.confirmInvitationChildOrganization(payload);
  }

  renderButton = () => {
    const {classes} = this.props;
    const {pic_name} = queryString.parse(this.props.location.search);

    return (
        <div className={`${classes.height}`}>
            <h5 className={`${classes.formTitle} ${classes.center}`}>Hello {pic_name}</h5>
            <h5 className={`${classes.formTitle} ${classes.center}`}>Your account has been activated</h5>
            <div className={classes.btnContainer}>
                <MButton
                  className={classes.registerBtn}
                  onClick={this.onClickSendConfirmation}
                  label="Accepted"
                  color="primary"
                />
            </div>
        </div>
    );
  };

  render() {
    const {classes, isSuccess} = this.props;
    return (
        <WebTemplate
            header
            footer
        >
            <div className={classes.center}>
                {this.renderButton()}
            </div>
        </WebTemplate>
    );
  }
}

const stateToProps = ({organization, auth}) => ({
  auth: auth,
  organization: organization,
  message: organization.message,
  isTrigger: organization.trigger,
});

const dispatchToProps = (dispatch) => ({
  confirmInvitationChildOrganization: (params) => dispatch(confirmInvitationChildOrganization(params)),
});

export default connect(
  stateToProps,
  dispatchToProps,
)(withStyles(styles)(EmailVerification));

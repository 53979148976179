/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {NOTIFICATION} from '../../services';
import {NOTIFICATION_STATE as STATE} from './notification.types';
import {transformObject, isArray} from '../../helpers';

export const getNotification = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GET));
    NOTIFICATION.getNotification(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.GET_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.GET_FAILED, response.data.message));
            },
        );
  };
};

export const readNotification = (id, params) => {
  return (dispatch) => {
    dispatch(requestRead(STATE.READ));
    NOTIFICATION.readNotification(id, params)
        .then(
            (response) => {
              dispatch(requestReadSuccess(STATE.READ_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestReadFailed(STATE.READ_FAILED, response.data.message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

// DISPATCH

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const requestRead = (requestType) => ({
  type: STATE.REQUEST_READ,
  requestType,
});

const requestReadFailed = (requestType, response) => ({
  type: STATE.REQUEST_READ_FAILED,
  requestType,
  payload: response,
});

const requestReadSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetStateRead = () => ({
  type: STATE.CLEAR_STATE_READ,
});
const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

import {initialState, FORGOT_PASSWORD_STATE} from './forgotPassword.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_STATE.SUBMIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case FORGOT_PASSWORD_STATE.SUBMIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case FORGOT_PASSWORD_STATE.SUBMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.payload.data,
        trigger: action.type,
      };
    default:
      return state;
  }
};

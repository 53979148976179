import {ENDPOINTS, apiService} from '../config';

export const getAll = (params) => {
  return apiService.get(
      ENDPOINTS.GENERAL_SETTING.ALL,
      {params},
  );
};

export const update = (params) => {
  return apiService.put(
      ENDPOINTS.GENERAL_SETTING.UPDATE,
      params,
  );
};

export const publish = (params) => {
  return apiService.post(
      ENDPOINTS.GENERAL_SETTING.PUBLISH,
      params,
  );
};

export const getQRSettings = (params) => {
  return apiService.get(
      ENDPOINTS.GENERAL_SETTING.FETCH_QR_SETTINGS,
      {params},
  );
};


export const generateQR = (params) => {
  return apiService.get(
      ENDPOINTS.GENERAL_SETTING.GENERATE_QR,
      {params},
  );
};

export const generateLink = (params) => {
  return apiService.get(
      ENDPOINTS.GENERAL_SETTING.GENERATE_LINK,
      {params},
  );
};

export const updateAutoRefreshQR = (params) => {
  return apiService.post(
      ENDPOINTS.GENERAL_SETTING.UPDATE_AUTO_REFRESH,
      params,
  );
};

export const checkToken = (params) => {
  return apiService.get(
      ENDPOINTS.GENERAL_SETTING.CHECK_QR_TOKEN,
      {params},
  );
};

export const getBannerLogin = () => {
  return apiService.get(
      ENDPOINTS.GENERAL_SETTING.FETCH_BANNER,
  );
};

export const uploadBanner = (params) => {
  return apiService.post(
      ENDPOINTS.GENERAL_SETTING.UPLOAD_BANNER,
      params,
  );
};


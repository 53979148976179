/* eslint-disable no-duplicate-case */
import {initialState, INVOICE_STATE} from './invoice.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case INVOICE_STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case INVOICE_STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case INVOICE_STATE.CHECK_AMOUNT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        total: action.payload.total,
      };
    case INVOICE_STATE.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        invoiceData: action.payload.data,
        invoiceMeta: action.payload.meta,
        trigger: action.type,
      };
    case INVOICE_STATE.FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        invoiceDetailData: action.payload.data,
        invoiceDetailMeta: action.payload.meta,
        trigger: action.type,
      };
    case INVOICE_STATE.FETCH_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        invoiceHistoryData: action.payload.data,
        invoiceHistoryMeta: action.payload.meta,
        trigger: action.type,
      };
    case INVOICE_STATE.FETCH_PRICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        invoicePriceData: action.payload.data,
        trigger: action.type,
      };
    case INVOICE_STATE.FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        invoiceData: [],
        message: action.payload,
        trigger: action.type,
      };
    case INVOICE_STATE.FETCH_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        invoiceDetailData: [],
        message: action.payload,
        trigger: action.type,
      };
    case INVOICE_STATE.FETCH_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        invoiceHistoryData: action.payload.data,
        invoiceHistoryMeta: action.payload.meta,
        trigger: action.type,
      };
    case INVOICE_STATE.GENERATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isError: false,
        isLoading: false,
        trigger: action.type,
        message: action.payload,
      };
    case INVOICE_STATE.GENERATE_FAILED:
      return {
        ...state,
        isSuccess: false,
        isError: true,
        isLoading: false,
        trigger: action.type,
        message: action.payload,
      };
    case INVOICE_STATE.SELECT_PLAN_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isError: false,
        isLoading: false,
        trigger: action.type,
        message: action.payload,
      };
    case INVOICE_STATE.SELECT_PLAN_FAILED:
      return {
        ...state,
        isSuccess: false,
        isError: true,
        isLoading: false,
        trigger: action.type,
        message: action.payload,
      };
    case INVOICE_STATE.UPDATE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isError: false,
        isLoading: false,
        trigger: action.type,
        message: action.payload,
      };
    case INVOICE_STATE.UPDATE_FAILED:
      return {
        ...state,
        isSuccess: false,
        isError: true,
        isLoading: false,
        trigger: action.type,
        message: action.payload,
      };
    case INVOICE_STATE.UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isError: false,
        isLoading: false,
        trigger: action.type,
        message: action.payload,
      };
    case INVOICE_STATE.UPDATE_INVOICE_FAILED:
      return {
        ...state,
        isSuccess: false,
        isError: true,
        isLoading: false,
        trigger: action.type,
        message: action.payload,
      };
    case INVOICE_STATE.CLEAR_STATE_INVOICE:
      return {
        ...state,
        ...initialState,
      };
    case INVOICE_STATE.CLEAR_STATE_INVOICE_GENERATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default (theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    background: '#fff',
  },
  adjustHeight: {
    minHeight: '100vh',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    margin: 'auto',
    maxWidth: '100vw',
  },
  formTitle: {
    color: '#00aff0',
    fontWeight: 600,
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 100,
    padding: '0px 5px',
  },
  registerBtn: {
    'padding': '10px 30px',
    'borderRadius': 40,
    'backgroundColor': '#FFFFFF',
    '&:hover': {
      backgroundColor: '#E4E4E4',
      textDecoration: 'none',
      color: '#00AFF0',
      boxShadow: 'none',
    },
    'boxShadow': 'none',
    'color': '#00AFF0',
    'width': '100%',
  },
  hasError: {
    color: 'red',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '4px 0px 20px 0px',
  },
  header: {
    display: 'flex',
    marginBottom: 40,
  },
  headerText: {
    fontWeight: 600,
    margin: 'auto',
    fontSize: 'larger',
    paddingTop: 10,
  },
  btnBack: {
    left: 15,
    position: 'absolute',
  },
  btnThankYouContainer: {
    marginTop: 20,
    textalign: 'center',
  },
  center: {
    textalign: 'center',
  },
  checkboxGroup: {
    margin: '10px 5px',
  },
});

import React from 'react';
import {Dialog, withStyles, DialogActions, Grid, Box, DialogContent} from '@material-ui/core';
import styles from './styles';
import {ModalTitle, MButton} from '../../../../components/form';
import {toast} from '../../../../helpers';

function PopUpShareEvent({open, toggle, classes, link}) {
  const handleCopyLink= async ()=>{
    await navigator.clipboard.writeText(link);
    toast('success', 'Link Copied to Clipboard!');
  };

  return (
    <Dialog
      open={open}
      onClose={toggle}
      maxWidth='md'
    >
      <ModalTitle
        title={'Share Event Link'}
        onClose={toggle}
      />
      <DialogContent className={classes.dialogContent}>
        {link}
      </DialogContent>
      <DialogActions className={classes.dialogFooter}>
        <Grid container direction='row' justifyContent='center'>
        <Box>
          <MButton
            className={classes.btnPublish}
            label="Copy Link"
            onClick={handleCopyLink}
          />
        </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(PopUpShareEvent);

export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: 18,
    paddingBottom: 16,
    paddingLeft: 28,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  logo: {
    height: 150,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
  searchDate: {
    maxWidth: '200px',
  },
  checkBoxFilter: {
    marginRight: 25,
    marginLeft: -9,
    marginTop: 8,
    marginBottom: 0,
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
    marginRight: '-8px',
  },
  formControlModalFullWidth: {
    width: 550,
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  checkInTrue: {
    color: '#89d300',
    fontSize: 20,
  },
  checkInFalse: {
    color: '#d30000',
    fontSize: 20,
  },
  tableHeaderIcon: {
    fontSize: 30,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    fontSize: 20,
    marginBottom: 30,
    textalign: 'center',
    fontWeight: 600,
    color: '#656464',
  },
  fade: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    width: 600,
  },
  modalItemSpacing: {
    margin: theme.spacing(1),
  },
  bold: {
    fontWeight: 'bold',
    color: 'black',
  },
  modalAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  modalCloseBtn: {
    float: 'right',
    marginBottom: 10,
  },
  dialogContent: {
    padding: 20,
  },
  dialogFooter: {
    padding: 20,
  },
  cardColor: {
    backgroundColor: '#ffffff',
    width: '70%',
  },
  imgSize: {
    width: 128,
    height: 128,
  },
  modalStyle1: {
    overflow: 'scroll',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow: 'none',
    },
  },
  filterBtn: {
    'marginTop': 24,
    'backgroundColor': '#00AFF0',
    'marginBottom': 16,
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
  },
  btnClose: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#d5d5d5',
    },
    'marginRight': 8,
    'marginBottom': 8,
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 0,
  },
  filter: {
    marginRight: 16,
  },
  btnFilterContainer: {
    marginTop: 22,
    marginBottom: 18,
    display: 'inline-flex',
  },
  filterDatePicker: {
    marginRight: 25,
    maxWidth: 175,
  },
  detailBtn: {
    'backgroundColor': '#ef9c02',
    'color': '#fff',
    '&:hover': {
      backgroundColor: '#c98200',
    },
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  searchInput: {
    width: '360px',
  },
  selectInput: {
    width: '240px',
  },
  btnExport: {
    'backgroundColor': '#009688',
    '&:hover': {
      backgroundColor: '#00796b',
    },
    'color': '#fff',
  },
  tableImg: {
    height: 50,
    width: 50,
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  primaryBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  secondaryBtn: {
    'backgroundColor': '#E5AF5A',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#D09F53',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
});

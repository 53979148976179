/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {LEAVE_MANAGEMENT} from '../../services';
import {LEAVE_MANAGEMENT_STATE as STATE} from './leaveManagement.types';
import {transformObject, isArray} from '../../helpers';

export const getLeaveManagement = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    LEAVE_MANAGEMENT.getLeaveManagement(params)
      .then(
          (response) => {
            dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
          },
          (error) => {
            const response = error.response.data;
            dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
          },
      );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

import {initialState} from '../refreshToken/refreshToken.types';
import {REFRESH_TOKEN_STATE} from './refreshToken.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_TOKEN_STATE.UPDATE_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        trigger: action.type,
      };
    case REFRESH_TOKEN_STATE.REQUEST_FAILED:
      return {
        ...state,
        trigger: action.type,
      };
    case REFRESH_TOKEN_STATE.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

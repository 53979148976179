/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {
  Link,
} from '@material-ui/core';
import {ACCOUNT_STATE as STATE} from '../../redux/account/account.types';
import {toast, isObject, checkMobile} from '../../helpers';
import {MInput, MButton} from '../../components/form';
import styles from './styles';
import WebTemplate from '../../components/template';
import {
  forgotPassword,
  clearState,
} from '../../redux/account/account.actions';


class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      errorMessage: null,
      error: false,
    };
    this.initialState = this.state;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message} = nextProps;

    if (isSuccess) {
      if (trigger === STATE.CHECK_TOKEN_SUCCESS) {
        this.setState({status: 'token-valid'});
      }

      if (trigger === STATE.FORGOT_PASSWORD_SUCCESS) {
        this.setState({status: 'update-success'});
        this.handleResetForm(true);
      }
    }

    if (isError) {
      if (trigger === STATE.FORGOT_PASSWORD_FAILED) {
        this.setState({error: true});
        if (message && !isObject(message)) toast('error', message);
      }
    }
  }


  componentWillUnmount() {
    this.props.clearState();
  }

  handleResetForm = () => {
    this.setState({
      token: null,
      email: null,
      new_password: null,
      new_password_confirm: null,
      errorMessage: null,
    });
  }

  handleInputChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      [name]: value,
      error: false,
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {email} = this.state;
    this.props.forgotPassword({
      email: email !== null ? email : null,
    });
  }

  renderForm = () => {
    const {classes, isLoading} = this.props;
    const {email, error} = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <h5 className={classes.formTitle}>Change Your Password</h5>
        <MInput
          classNameFC={classes.formControl}
          name="email"
          label="Email"
          placeholder="Enter your valid email"
          defaultValue={email}
          onChange={this.handleInputChange}
          error={error && this.props.message ? this.props.message : false}
          variant="standard"
          type="email"
          autoFocus
          fullWidth
        />
        <div className={classes.btnContainer}>
          <MButton
            className={classes.btnSubmit}
            label="Submit"
            color="primary"
            type="submit"
            loading={isLoading}
          />
        </div>
      </form>
    );
  };

  renderSuccess = () => {
    const {classes} = this.props;
    const isMobile = checkMobile();
    return (
      <div>
        <h5 className={`${classes.formTitle} ${classes.center}`}>Success</h5>
        <h6 className={classes.formText}>
          Please check your email to reset your password
        </h6>
        <div className={classes.btnThankYouContainer}>
          <Link
            href={'/finish-change-password'}
            className={classes.registerBtn}
            onClick={isMobile ? this.handleBackToNative : this.handleBackToWeb}
          >
            <span style={{color: '#3f51b5 !important'}}>Login</span>
          </Link>
        </div>
      </div>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <WebTemplate
        header
        footer
      >
        <div className={classes.root} style={{backgroundColor: '#ffffff91'}}>
          <div className={classes.paper}>
            {this.renderForm()}
            {/* {status === 'update-success' && this.renderSuccess()} */}
            {/* {status === 'token-valid' && this.renderForm()} */}
            {/* {status === 'token-invalid' && this.renderInvalid()} */}
            {/* {!status && this.renderLoading()} */}
          </div>
        </div>
      </WebTemplate>
    );
  }
}

const stateToProps = ({account}) => ({
  isLoading: account.isLoading,
  isError: account.isError,
  isSuccess: account.isSuccess,
  message: account.message,
  trigger: account.trigger,
});

const dispatchToProps = (dispatch) => ({
  forgotPassword: (params) => dispatch(forgotPassword(params)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(ChangePassword));

export default (theme) => ({
  dialogFooter: {
    padding: 20,
  },
  btnCancel: {
    'color': '#FFF',
    'backgroundColor': '#E5AF5A',
    'marginRight': '10px',
    '&:hover': {
      backgroundColor: '#E5AF5A',
    },
  },
  dialogContent: {
    padding: 20,
  width: '450px',
  height: '200px',
  },
  announcementText: {
    display: 'block',
    border: 0,
  },
  btnPublish: {
    'color': '#FFF',
    'backgroundColor': '#00AFF0',
    '&:hover': {
      backgroundColor: '#00AFF0',
    },
  },
});

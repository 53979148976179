import React from 'react';
import {Dialog, withStyles, DialogActions, Grid, Box, DialogContent, Typography} from '@material-ui/core';
import styles from './styles';
import {ModalTitle, MButton} from '../../../../components/form';
import {toast} from '../../../../helpers';

function PopUpShareEvent({open, toggle, classes, link, data}) {
  const handleCopyLink= async ()=>{
    await navigator.clipboard.writeText(link);
    toast('success', 'Link Copied to Clipboard!');
  };
  const details = data[0];
  return (
    <Dialog
      open={open}
      onClose={toggle}
      maxWidth="sm"
      fullWidth
      scroll="paper"
    >
      <ModalTitle
        title={'Share Event Group Link'}
        onClose={toggle}
      />
      <Box>
      <DialogContent className={classes.dialogContent}>
      <Box style={{display: 'flex'}}>
      <img src={details.photo ?? ''}
      alt="Event Group Banner"
      style={{borderRadius: '6px',
        width: '128px',
        height: '124px',
        objectFit: 'cover',
        }}/>
      <Box style={{margin: '0px 15px'}}>
        <Typography
          style={{
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '16px',
            paddingBottom: '10px',
            }}>
          {details.title ?? '-'}
          </Typography>
        <Typography
          style={{
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            maxHeight: '48px',
          }}
        >
          {details.description.replace(/<[^>]+>/g, '') ?? '-'}
        </Typography>
        <Typography
          style={{
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            paddingTop: '10px'}}>
              {link}
        </Typography>
      </Box>
      </Box>
      </DialogContent>

      <DialogActions className={classes.dialogFooter}>
        <Grid container direction='row' justifyContent='center'>
        <Box>
          <MButton
            className={classes.btnPublish}
            label="Copy Link"
            onClick={handleCopyLink}
          />
        </Box>
        </Grid>
      </DialogActions>
      </Box>
    </Dialog>
  );
}

export default withStyles(styles)(PopUpShareEvent);

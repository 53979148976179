/* eslint-disable array-callback-return */
/* eslint-disable no-empty */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import {Send} from '@material-ui/icons';
import styles from './styles';
import {MButton, MInput} from '../../../components/form';
import {toast, isArray, isObject, checkSuperadmin} from '../../../helpers';
import {GENERAL_SETTING_STATE as STATE} from '../../../redux/generalSetting/generalSetting.types';
import {
  getGeneralSetting,
  updateGeneralSetting,
  uploadBanner,
} from './../../../redux/generalSetting/generalSetting.actions';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

class AdminSetting extends Component {
  constructor(props) {
    super(props);
    this.refBanner = React.createRef();
    this.state = {
      formData: {
        withdrawal_limit: null,
        app_version: null,
        app_log: null,
        foss_billing: null,
        register_mobile: null,
        banner: [],
        banner_title: null,
        banner_description: null,
        url_iframe: null,
      },
      settings: [],
      message: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getGeneralSetting();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {message, trigger, data} = nextProps;

    if (trigger === STATE.UPDATE_SUCCESS) {
      toast('success', message);
      this.props.getGeneralSetting();
    }

    if (trigger === STATE.FETCH_ALL_SUCCCESS) {
      const filtered = this.filterSetting(data);
      const withdrawalLimit = filtered && filtered[0] ? filtered[0] : null;
      const appVersionSetting = filtered && filtered[1] ? filtered[1] : null;
      const urlIframe = filtered && filtered[2] ? filtered[2] : null;
      const appLogSetting = filtered && filtered[3] ? filtered[3] : null;
      const registerMobile = filtered && filtered[4] ? filtered[4] : null;
      const fossBilling = filtered && filtered[5] ? filtered[5] : null;
      const banner = filtered && filtered[6] ? filtered[6] : [];
      const bannerTitle = filtered && filtered[7] ? filtered[7] : null;
      const bannerDescription = filtered && filtered[8] ? filtered[8] : null;
      this.setState({
        settings: filtered,
        formData: {
          withdrawal_limit: withdrawalLimit,
          app_version: appVersionSetting,
          url_iframe: urlIframe,
          app_log: appLogSetting,
          foss_billing: fossBilling,
          register_mobile: registerMobile,
          banner,
          banner_title: bannerTitle,
          banner_description: bannerDescription,
        },
      });
    }

    if (trigger === STATE.POST_BANNER_SUCCESS) {
      this.setState((prevState) => {
        return {
          formData: {
            ...prevState.formData,
            banner: {
              ...prevState.formData.banner,
              value: [...prevState.formData.banner.value, ...nextProps.banner.map((banner) => ({img: banner}))],
            },
          },
        };
      });
    }

    if (this.props.trigger !== trigger && trigger === STATE.UPDATE_FAILED && !isObject(message)) {
      toast('error', message);
      // this.props.getGeneralSetting();
    }
  }

  filterSetting = (data) => {
    const filter = (data, key) => {
      const result = data.filter((value) => value.key === key);
      return isArray(result) ? result[0] : null;
    };
    const appVersionSetting = filter(data, 'app_version');
    const appLogSetting = filter(data, 'app_log');
    const withdrawalSetting = filter(data, 'withdrawal_limit');
    const registerMobile = filter(data, 'register_mobile');
    const fossBilling = filter(data, 'foss_billing');
    const banner = filter(data, 'banner');
    const bannerTitle = filter(data, 'banner_title');
    const bannerDescription = filter(data, 'banner_description');
    const urlIframe = filter(data, 'url_iframe');
    return [withdrawalSetting, appVersionSetting, urlIframe, appLogSetting, registerMobile, fossBilling, banner, bannerTitle, bannerDescription];
  }

  handleSubmit = () => {
    const {formData} = this.state;
    const converted = [];
    Object.entries(formData).forEach(([key, val]) => {
      if (isObject(val)) {
        converted.push({
          key: val?.key,
          label: val?.label,
          value: val?.key === 'banner' ? JSON.stringify(val?.value) : val?.value,
          group: val?.group,
        });
        }
    });

    const params = {data: converted};
    this.props.updateGeneralSetting(params);
  }

  handleInputChangeSetting = (type, params) => {
    let name;
    let value;
    if (type === 'text') {
      name = params.target.name;
      value = params.target.value;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          value,
        },
      },
    });
  }

  removeBannerItem = (index) => {
    try {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          banner: {
            ...prevState.formData.banner,
            value: prevState.formData.banner.value.filter((banner, key) => key !== index),
          },
        },
      }));
    } catch (error) {

    }
  }

  onSelectFile = async (event) => {
    try {
      const formData = new FormData();
      const files = event.target.files;
      if (files.length > 0) {
        Array.from(files).map((file) => {
          if (
            file.type.includes('jpg') ||
            file.type.includes('jpeg') ||
            file.type.includes('png')
          ) {
            formData.append('banner_login_img', file);
          } else {
          }
        });
        this.props.uploadBanner(formData);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  renderForm = (settingArray) => {
    const {classes} = this.props;
    const {app_version, app_log, withdrawal_limit, register_mobile, foss_billing, banner, banner_title, banner_description, url_iframe} = this.state.formData;

    const result = settingArray.map((setting) => {
      if (!setting) return null;
      const {id, key, label} = setting;
      if (key === 'withdrawal_limit') {
        return (
          <div key={id}>
            <h5 className={classes.subTitle}>Withdrawal</h5>
            <MInput
              classNameFC={classes.formControl}
              name={key}
              label={label}
              placeholder="Current active application version"
              defaultValue={withdrawal_limit ? withdrawal_limit.value : null}
              onBlur={(event) => this.handleInputChangeSetting('text', event)}
              autoFocus
              autoComplete="off"
            />
          </div>
        );
      } else if (key === 'app_version') {
        return (
          <div key={id}>
            <h5 className={classes.subTitle} style={{marginTop: 8}}>Application Version</h5>
            <MInput
              classNameFC={classes.formControl}
              name={key}
              label={label}
              placeholder="Current active application version"
              defaultValue={app_version ? app_version.value : null}
              onBlur={(event) => this.handleInputChangeSetting('text', event)}
              autoFocus
              autoComplete="off"
            />
          </div>
        );
      } else if (key === 'url_iframe') {
        return (
          <div key={id}>
            <h5 className={classes.subTitle} style={{marginTop: 8}}>Dasboard URL iframe</h5>
            <MInput
              classNameFC={classes.formControl}
              name={key}
              label="Dashboard URL iframe"
              placeholder="Fill dashboard url iframe"
              defaultValue={url_iframe ? url_iframe.value : null}
              onBlur={(event) => this.handleInputChangeSetting('text', event)}
              autoFocus
              autoComplete="off"
            />
            <Typography style={{fontSize: 12}}>
              Menampilkan komponen dari laman web lain yang dapat berupa video, file, atau media di halaman dashboard
            </Typography>
          </div>
        );
      } else if (key === 'app_log') {
        return (
          <div key={id}>
            <h5 className={classes.versionTitle}>Version Description</h5>
            <MInput
              classNameFC={classes.formControl}
              name={key}
              label="Version Description"
              placeholder="Current version description"
              onBlur={(event) => this.handleInputChangeSetting('text', event)}
              defaultValue={app_log ? app_log.value : null}
              autoFocus
              autoComplete="off"
              multiline
              rows={5}
              type="none"
            />
          </div>

        );
      } else if (key === 'register_mobile') {
        return (
          <div key={id}>
            <h5 className={classes.versionTitle} style={{marginTop: 24}}>Registration On Mobile </h5>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event) => this.handleInputChangeSetting('text', event)}
              value={register_mobile ? register_mobile.value : null}
            >
              <FormControlLabel name='register_mobile' value={1} control={<Radio color='primary'
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 28,
                  },
                }}
              />} label="Enable" />
              <FormControlLabel name='register_mobile' value={0} control={<Radio color='primary'
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 28,
                  },
                }}
              />} label="Disable" />
            </RadioGroup>
          </div>
        );
      } else if (key === 'foss_billing') {
        return (
          <div key={id}>
            <h5 className={classes.versionTitle} style={{marginTop: 24}}>Pagii Billing </h5>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event) => this.handleInputChangeSetting('text', event)}
              value={foss_billing ? foss_billing.value : null}
            >
              <FormControlLabel name='foss_billing' value={1} control={<Radio color='primary'
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 28,
                  },
                }}
              />} label="Enable" />
              <FormControlLabel name='foss_billing' value={0} control={<Radio color='primary'
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 28,
                  },
                }}
              />} label="Disable" />
            </RadioGroup>
          </div>
        );
      } else if (key === 'banner') {
        return (
          <div key={id} style={{paddingBottom: 12}}>
            <h5 className={classes.subTitle}>Banner</h5>
            <div className={classes.bannerFlex}>
              <div onClick={() => this.refBanner.current.click()} className={classes.bannerCreateContainer}>
                <p>Upload Files</p>
              </div>
              <input
                ref={this.refBanner}
                autoComplete="none"
                accept="image/png, image/jpg, image/jpeg"
                type="file"
                multiple={true}
                onChange={this.onSelectFile}
                onClick={(event) => {
                  event.target.value = null;
                }}
                className={classes.inputBanner}
              />
              {banner && banner.value.length > 0 ? banner.value.map((banner_data, key) => {
                return (
                  <div key={key} className={classes.bannerItem}>
                    <CancelIcon className={classes.bannerItemIcon} onClick={() => this.removeBannerItem(key)}/>
                    <img src={banner_data.img} alt="" className={classes.bannerImg}/>
                  </div>
                );
              }) : null}
            </div>
          </div>
        );
      } else if (key === 'banner_title') {
        return (
          <div key={id}>
            <h5 className={classes.subTitle}>Banner Title</h5>
            <MInput
              classNameFC={classes.formControl}
              name={key}
              label={label}
              placeholder=""
              defaultValue={banner_title ? banner_title.value : null}
              onBlur={(event) => this.handleInputChangeSetting('text', event)}
              autoFocus
              autoComplete="off"
            />
          </div>
        );
      } else if (key === 'banner_description') {
        return (
          <div key={id}>
            <h5 className={classes.subTitle}>Banner Description</h5>
            <MInput
              classNameFC={classes.formControl}
              name={key}
              label={label}
              placeholder=""
              defaultValue={banner_description ? banner_description.value : null}
              onBlur={(event) => this.handleInputChangeSetting('text', event)}
              autoFocus
              autoComplete="off"
            />
          </div>
        );
      }
    });
    return result;
  }

  render() {
    const {classes, data, isLoading} = this.props;
    const {settings} = this.state;

    return (
      <div className={classes.root}>
        <Paper variant='outlined' className={classes.paper}>
          <form>
            {
              settings.length > 0 ?
              this.renderForm(settings) : ''
            }
            <div className={classes.btnContainer}>
              <MButton
                className={classes.defaultBtn}
                label="Update Setting"
                icon={<Send/>}
                onClick={this.handleSubmit}
                loading={isLoading && data.length > 0 ? true : false}
              />
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = ({auth, generalSetting, organization}) => ({
  isSuperAdmin: checkSuperadmin(auth),
  organization: auth.data.organization,
  data: generalSetting.data,
  banner: generalSetting.banner,
  isLoading: generalSetting.isLoading,
  isSuccess: generalSetting.isSuccess,
  isError: generalSetting.isError,
  message: generalSetting.message,
  trigger: generalSetting.trigger,
  // orgData: organization.orgData,
  orgData: [
    {id: 1, name: 'WGS'},
    {id: 2, name: 'SMOOETS'},
  ],
});

const mapDispatchToProps = (dispatch) => ({
  getGeneralSetting: (key) => dispatch(getGeneralSetting(key)),
  updateGeneralSetting: (params) => dispatch(updateGeneralSetting(params)),
  uploadBanner: (params) => dispatch(uploadBanner(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminSetting));


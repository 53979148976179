/* eslint-disable no-dupe-keys */
export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: 2,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  tablePaper: {
    color: theme.palette.text.secondary,
    borderRadius: 18,
    boxShadow: 'none',
  },
  filterDatePicker: {
    marginRight: 25,
    maxWidth: 140,
  },
  formControlSearchBy: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: 150,
  },
  formControlStatus: {
    marginBottom: theme.spacing(1),
    marginTop: 14,
    width: 200,
    marginLeft: 24,
  },
  tablePaper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: theme.palette.text.secondary,
    marginBottom: 10,
    borderRadius: 18,
    boxShadow: 'none',
  },
  datatable: {
    marginTop: 25,
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  checkBox: {
    width: 70,
  },

  parentFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '16px !important',
    color: '#656464',
  },
  rowFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '450 !important',
    fontSize: '14px !important',
  },
  createUserTitle: {
    color: '#656464',
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  hidden: {
    display: 'none',
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  hasError: {
    color: 'red',
  },
  photo: {
    height: 150,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
  filterBtn: {
    marginTop: 25,
    marginRight: 15,
    marginLeft: 10,
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 15,
  },
  filter: {
    marginRight: 25,
  },
  filterSearch: {
    minWidth: 300,
    marginRight: 25,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  dialogFooter: {
    padding: 20,
  },
  dialogContent: {
    paddingTop: '0 !important',
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  btnCrop: {
    marginTop: 10,
  },
  searchInput: {
    width: '350px',
  },
  syncTrue: {
    color: '#89d300',
    fontSize: 20,
  },
  syncFalse: {
    color: '#34a3ed',
    fontSize: 20,
  },
  syncWarn: {
    color: '#FFCC00',
    fontSize: 20,
  },
  textLink: {
    'color': '#00AFF0',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#00A2DE',
    },
  },
  btnReset: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  btnExport: {
    'backgroundColor': '#00AFF0',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow: 'none',
    },
  },
  btnStyle: {
    width: '2px',
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
  dropZoneArea: {
    padding: 15,
    minHeight: 160,
    minWidth: 500,
  },
  dropZoneText: {
    color: '#8D8D97',
    fontSize: '1.4em',
  },
  dropZoneIcon: {
    color: '#8D8D97',
  },
  hidden: {
    minWidth: 500,
    visibility: 'hidden',
    height: 0,
    minHeight: 0,
  },
  primary: {
    color: '#00AFF0',
  },
  informationDetail: {
    fontSize: 13,
    fontWeight: 400,
    color: '#656464',
  },
});

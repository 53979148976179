export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  detailLoading: false,
  detailError: false,
  detailSuccess: false,
  detailData: '',
  trigger: null,
  message: null,
  overtimeData: [],
  overtimeMeta: {page: 1, total: 0},
  exportLoading: false,
  exportData: {file: null},
  isLoadingPost: false,
  isSuccessPost: false,
  isErrorPost: false,
  messagePost: '',
  status: null,
};

export const OVERTIME_STATE = {
  REQUEST: 'OVERTIME_REQUEST',
  REQUEST_FAILED: 'OVERTIME_REQUEST_FAILED',
  FETCH: 'OVERTIME_FETCH',
  FETCH_SUCCESS: 'OVERTIME_FETCH_SUCCESS',
  FETCH_FAILED: 'OVERTIME_FETCH_FAILED',
  CHANGE_STATUS_OVERTIME: 'CHANGE_STATUS_OVERTIME',
  CHANGE_STATUS_OVERTIME_SUCCESS: 'CHANGE_STATUS_OVERTIME_SUCCESS',
  CHANGE_STATUS_OVERTIME_FAILURE: 'CHANGE_STATUS_OVERTIME_FAILURE',
  EXPORT: 'OVERTIME_EXPORT',
  EXPORT_SUCCESS: 'OVERTIME_EXPORT_SUCCESS',
  EXPORT_FAILED: 'OVERTIME_EXPORT_FAILED',
};

/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Tooltip,
  Button,
  Grid,
  Paper,
} from '@material-ui/core';
import {Send, Delete, Refresh, Search} from '@material-ui/icons';
import styles from './styles';
import {showPopup, toast, isObject, checkSuperadmin} from '../../helpers';
import _ from 'lodash';

import {Datatable} from '../../components';
import {MInput, MButton, ModalTitle} from '../../components/form';
import {DIVISION_STATE as STATE} from '../../redux/divisionManagement/divisionManagement.types';
import {
  getDivision,
  createDivision,
  updateDivision,
  deleteDivision,
  clearState,
} from './../../redux/divisionManagement/divisionManagement.actions';

class DivisionManagement extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      editing: false,
      showModal: false,
      formData: {
        id: null,
        division_name: null,
        description: null,
        created_by: null,
      },
      query: '',
      errorMessage: null,
      sortBy: null,
      order: 'desc',
      showMoreDesc: false,
    };
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiDialog: {
        paperWidthSm: {
          width: 575,
        },
      },
      MuiDialogContent: {
        root: {
          '&:first-child': {
            paddingTop: 0,
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  UNSAFE_componentWillMount() {
    this.fetchDatatable();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message} = nextProps;

    if (isSuccess) {
      if (trigger === STATE.CREATE_SUCCESS || trigger === STATE.UPDATE_SUCCESS || trigger === STATE.DROP_SUCCESS) {
        this.handleResetForm(true);
        this.fetchDatatable();
        toast('success', message);
        this.setState({
          modalDivisionShow: false,
        });
      }
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  fetchDatatable = () => {
    const {page, limit, query, order, sortBy} = this.state;
    this.props.getDivision({page, limit, query, order, sort_by: sortBy});
  }

  handleReload = (params) => {
    this.setState(
        params,
        () => this.fetchDatatable(),
    );
  }

  handleResetForm = (closeModal) => {
    this.setState({
      errorMessage: null,
      formData: {
        id: null,
        division_name: null,
        description: null,
        created_by: null,
      },
      errorMessage: null,
    });
    if (closeModal) this.setState({showModal: false, isSubmit: false});
  }

  handleInputChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      formData: {...this.state.formData, [name]: value ? value : null},
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  };

  handleSubmitDelete = (id) => {
    this.props.deleteDivision(id);
  }

  handleFormToggle = (type, data = null) => {
    if (type === 'edit') {
      this.setState({
        showModal: !this.state.showModal,
        editing: true,
        formData: {
          id: data[1],
          division_name: data[2],
          description: data[3],
          created_by: data[4].name,
        },
      });
    } else {
      this.handleResetForm();
      this.setState({
        editing: false,
        showModal: !this.state.showModal,
      });
    }
  }

  handleConfirmDelete = (data) => {
    showPopup(
        'confirm',
        'Are you sure you want to delete this data ?',
        'Yes',
        () => this.props.deleteDivision(data[1]),
    );
  }

  handleSearch = (keyword) => {
    this.setState({searchFilter: keyword, page: 1}, () => this.fetchDatatable());
  }

  handleSearchs = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearchQuery(value);
  }

  handleDebounceSearchQuery = _.debounce((query) => {
    this.toggleResetPage();
    this.setState(
        {page: null},
        () => this.fetchDatatable(),
    );
  }, 500);

  handleSubmit = (e) => {
    e.preventDefault();
    const {createDivision, updateDivision} = this.props;
    const {editing} = this.state;
    const {id, division_name, description, created_by} = this.state.formData;
    const params = {
      ...(division_name && {division_name}),
      ...(description && {description}),
      ...(created_by && {created_by}),
    };
    return (!editing) ? createDivision(params) : updateDivision(id, params);
  }

  renderModalForm = () => {
    const {classes, isLoading} = this.props;
    const {showModal, editing, errorMessage} = this.state;
    const {division_name, description, created_by} = this.state.formData;
    const titleDialog = editing ? 'Update Division' : 'Create Division';

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <Dialog
          className={classes.dialogContent}
          open={showModal}
          onClose={() => this.handleFormToggle('close')}
        >
          <ModalTitle
            title={<Typography className={classes.modalTitle}>{titleDialog}</Typography>}
            onClose={() => this.handleFormToggle('close')}
          />
          <form onSubmit={this.handleSubmit}>
            <DialogContent className={classes.dialogContent}>
              <MInput
                // disabled={editing ? true : false}
                classNameFC={classes.formControl}
                autoFocus
                name="division_name"
                label="Division Name *"
                placeholder="Enter division name"
                defaultValue={division_name}
                onBlur={this.handleInputChange}
                error={errorMessage && errorMessage.division_name ? errorMessage.division_name : undefined}
                fullWidth
                readonly="readonly"
              />
              <MInput
                classNameFC={classes.formControl}
                multiline
                rowsMax={5}
                name="description"
                label="Description"
                placeholder="Enter division description"
                defaultValue={description}
                onBlur={this.handleInputChange}
                error={errorMessage && errorMessage.description ? errorMessage.description : undefined}
                fullWidth
              />
              {/* <MInput style={{display: 'none'}}
                classNameFC={classes.formControl}
                multiline
                rowsMax={5}
                name="created_by"
                label="Company Name"
                placeholder="Enter Company Name"
                defaultValue={created_by}
                onBlur={this.handleInputChange}
                error={errorMessage && errorMessage.created_by ? errorMessage.created_by : undefined}
                fullWidth
              /> */}
            </DialogContent>
            <DialogActions className={classes.dialogFooter}>
              <MButton
                id="cancel"
                className={classes.cancelBtn}
                label='Cancel'
                size='small'
                onClick={() => this.handleFormToggle('close')}
              />
              <MButton
                id="submit"
                className={classes.defaultBtn}
                label={titleDialog}
                color="primary"
                size='small'
                type="submit"
                icon={<Send />}
                loading={isLoading}
              />
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleResetFilter = () => {
    this.setState({
      page: 1,
      limit: 10,
      query: '',
      sortBy: null,
      order: 'desc',
    }, () => this.fetchDatatable());
    this.toggleResetPage();
    // this.handleReload()
  };

  renderFilter = () => {
    const {classes} = this.props;
    const {query} = this.state;

    return (
      <Paper variant='outlined' className={classes.paper}>
        <MInput
          id="search"
          autoFocus
          className={`${classes.filter} ${classes.searchInput}`}
          fullWidth={false}
          variant="standard"
          name="query"
          label="Search"
          placeholder="Search keyword"
          value={query}
          onChange={this.handleSearchs}
          InputProps={{endAdornment: <IconButton className={classes.searchIcon} position="end"> <Search/></IconButton>}}
        />
        <div className={classes.btnFilterContainer}>
          <MButton
            id="reset"
            className={`${classes.btnExport} ${classes.filterBtnEnd}`}
            label="Reset"
            icon={<Refresh/>}
            onClick={this.handleResetFilter}
          />
        </div>
      </Paper>
    );
  }

  renderActions = (params) => {
    const {orgStatusData, isSuperAdmin} = this.props;
    const rowDataDisable = 12;
    const disableCondition = orgStatusData && orgStatusData.adv_sync;
    return (
      <Grid container wrap='nowrap' direction='row'>
        <Grid item>
          <Tooltip title="Delete">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                id="delete"
                variant="contained"
                color="secondary"
                size='small'
                onClick={() => this.handleConfirmDelete(params)}
                startIcon={<Delete />}
                style={{
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
                disabled={
                  (!rowDataDisable ? false : !disableCondition === null ? !params[rowDataDisable] : disableCondition && !params[rowDataDisable]) ||
                  (orgStatusData && orgStatusData.import_users)
                }
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {classes, isLoading, divisionData, divisionMeta, isSuperAdmin} = this.props;
    const columns = [
      {name: 'id', label: 'id', display: false},
      {name: 'division_name', label: 'Division', display: true},
      {name: 'description', label: 'Description', display: true,
      customBodyRender: (value) => {
        return value ? <Typography className={classes.informationDetail}>
        {
            value && value.length <= 150 ? `${value.substring(0, 150)}` :
                value && value.length > 150 ? `${value.substring(0, 150)}...` : '-'
        }
      </Typography> : '-';
      }},

      {name: 'created_by', label: 'Created By', display: isSuperAdmin ? true : false},
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}

        <Datatable
          hideActions={isSuperAdmin ? true : false }
          title={'Division List'}
          loading={isLoading}
          dataSource={divisionData}
          total={divisionMeta ? divisionMeta.total : 0}
          page={divisionMeta ? divisionMeta.page : 1}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleCreate={
            isSuperAdmin ? null : () => this.handleFormToggle('add')
          }
          handleEdit={ isSuperAdmin ? null : (data) => this.handleFormToggle('edit', data)}
          // handleSearch={(params) => this.handleSearch(params)}
          customActions={(data) => this.renderActions(data)}
          creatable={isSuperAdmin ? false : true}
        />
        {this.renderModalForm()}
      </div>
    );
  }
}

const mapStateToProps = ({auth, divisionManagement}) => {
  return {
    isSuperAdmin: checkSuperadmin(auth),
    isLoading: divisionManagement.isLoading,
    isError: divisionManagement.isError,
    isSuccess: divisionManagement.isSuccess,
    message: divisionManagement.message,
    trigger: divisionManagement.trigger,
    divisionData: divisionManagement.divisionData,
    divisionMeta: divisionManagement.divisionMeta,
  };
};

const dispatchToProps = (dispatch) => ({
  getDivision: (params) => dispatch(getDivision(params)),
  createDivision: (params) => dispatch(createDivision(params)),
  updateDivision: (id, params) => dispatch(updateDivision(id, params)),
  deleteDivision: (params) => dispatch(deleteDivision(params)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    mapStateToProps,
    dispatchToProps,
)(withStyles(styles)(DivisionManagement));

/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import Settings from '@material-ui/icons/Settings';
import AccessAlarm from '@material-ui/icons/AccessAlarm';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AlarmOn from '@material-ui/icons/AlarmOn';
import {ReactComponent as DashboardIcon} from '../../assets/icon/dashboard/inactive.svg';
import {ReactComponent as CompanyTypeIcon} from '../../assets/icon/Company-Type/inactive.svg';
import {ReactComponent as JobTitleIcon} from '../../assets/icon/Job-Title/inactive.svg';
import {ReactComponent as RoleManagementIcon} from '../../assets/icon/role-management/inactive.svg';
import {ReactComponent as AdminManagementIcon} from '../../assets/icon/admin-management/inactive.svg';
import {ReactComponent as UserManagementIcon} from '../../assets/icon/user-management/inactive.svg';
import {ReactComponent as CompanyManagementIcon} from '../../assets/icon/company-management/company.svg';
import {ReactComponent as ShiftManagementIcon} from '../../assets/icon/shift-management/inactive.svg';
import {ReactComponent as AttendanceIcon} from '../../assets/icon/attendance/inactive.svg';
import {ReactComponent as LeaveManagementIcon} from '../../assets/icon/leave-management/inactive.svg';
import {ReactComponent as PaymentIcon} from '../../assets/icon/Payment/inactive.svg';
import {ReactComponent as EmployeeFacilitiesIcon} from '../../assets/icon/employeeFacilities/employeeFacilities.svg';
import {ReactComponent as PermitIcon} from '../../assets/icon/Permit/inactive.svg';
import {ReactComponent as ReimburseIcon} from '../../assets/icon/Reimburse/inactive.svg';
import {ReactComponent as GeneralSettingIcon} from '../../assets/icon/GeneralSetting/inactive.svg';
import {ReactComponent as CompanyProfileIcon} from '../../assets/icon/Company-Profile/inactive.svg';
import {ReactComponent as PaymentHistoryIcon} from '../../assets/icon/Payment-history/inactive.svg';
import {ReactComponent as InvoiceIcon} from '../../assets/icon/invoice/inactive.svg';
import {ReactComponent as RegistrationManagementIcon} from '../../assets/icon/registration-management/inactive.svg';
import {ReactComponent as AnalyticsIcon} from '../../assets/icon/analytics/inactive.svg';
import {ReactComponent as OvertimeIcon} from '../../assets/icon/overtime/overtimeIcon.svg';
import {ReactComponent as SSOWhiteList} from '../../assets/icon/sso/sso-icon.svg';
import {SvgIcon} from '@material-ui/core';
import {ContactMail, PollOutlined} from '@material-ui/icons';
import {ReactComponent as PartnerIcon} from '../../assets/icon/partner/partner-menu.svg';
import {ReactComponent as DivisionManagementIcon} from '../../assets/icon/division-management/division-management.svg';
import {ReactComponent as ExportActivityIcon} from '../../assets/icon/export-activity/export-activity.svg';
import {ReactComponent as NonOrganizationIcon} from '../../assets/icon/non-organization/non-organization.svg';
import {ReactComponent as EventIcon} from '../../assets/icon/event-management/Event.svg';

export default (role, orgId = null) => {
  const menus = [
    {
      title: 'Dashboard',
      navigation: '/dashboard',
      icon: (
        <SvgIcon>
          <DashboardIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['hr admin', 'super admin', 'super admin hr'],
    },
    {
      title: 'Company Type',
      navigation: '/company-type',
      icon: (
        <SvgIcon>
          <CompanyTypeIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['super admin'],
    },
    // {
    //   title: 'Registration Management',
    //   navigation: '/registration-management',
    //   icon: <SvgIcon><RegistrationManagementIcon height={24} width={24}/></SvgIcon>,
    //   role: ['super admin'],
    // },
    // {
    //   title: 'Job Title Management',
    //   navigation: '/job-title',
    //   icon: <SvgIcon>
    //     <JobTitleIcon height={24} width={24}/>
    //   </SvgIcon>,
    //   role: ['super admin'],
    // },
    // {
    //   title: 'Role Management',
    //   navigation: '/role-management',
    //   icon: <SvgIcon>
    //     <RoleManagementIcon height={24} width={24}/>
    //   </SvgIcon>,
    //   role: ['super admin'],
    // },
    {
      title: 'User Management',
      navigation: '/user-management',
      icon: (
        <SvgIcon>
          <UserManagementIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['hr admin', 'super admin hr'],
    },
    {
      title: 'Non Organization Management',
      navigation: '/non-organization-management',
      icon: (
        <SvgIcon>
          <NonOrganizationIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['super admin'],
    },
    {
      title: 'Event Management',
      navigation: '/event-management',
      name: 'pagiiEvent',
      icon: (
        <SvgIcon>
          <EventIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['super admin', 'hr admin', 'super admin hr'],
    },
    {
      title: 'Organization Management',
      navigation: '/organization-management',
      icon: (
        <SvgIcon>
          <CompanyProfileIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['super admin', 'super admin hr'],
    },
    {
      title: 'Attendance History',
      navigation: '/attendance-history',
      icon: <AttendanceIcon height={24} width={24} />,
      role: ['hr admin', 'super admin hr'],
    },
    {
      title: 'Overtime History',
      navigation: '/overtime-history',
      icon: <OvertimeIcon height={24} width={24} />,
      role: ['hr admin', 'super admin hr'],
      name: 'overtime',
    },
    {
      title: 'Leave',
      navigation: '/leave-config',
      icon: (
        <SvgIcon>
          <LeaveManagementIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['hr admin', 'super admin hr'],
      expandable: true,
      refKey: 'toggleLeave',
      name: 'paidleave',
      children: [
        {
          title: 'Leave Config',
          navigation: '/leave-config',
          icon: <LocalAtmIcon />,
          role: ['hr admin', 'super admin hr'],
        },
        {
          title: 'Leave History',
          navigation: '/leave-history',
          icon: <LocalAtmIcon />,
          role: ['hr admin', 'super admin hr'],
        },
        {
          title: 'Leave Management',
          navigation: '/leave-management',
          icon: <LocalAtmIcon />,
          role: ['hr admin', 'super admin hr'],
        },
      ],
    },
    {
      title: 'Permit',
      navigation: '/permit',
      icon: (
        <SvgIcon>
          <PermitIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['hr admin', 'super admin hr'],
      name: 'permit',
    },
    {
      title: 'Reimburse',
      navigation: '/reimburse',
      icon: (
        <SvgIcon>
          <ReimburseIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['hr admin', 'super admin hr'],
      name: 'reimburse',
    },
    {
      title: 'Shift',
      navigation: '/shift-management',
      icon: (
        <SvgIcon>
          <ShiftManagementIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['hr admin', 'super admin hr'],
      expandable: true,
      refKey: 'toggleShift',
      name: 'shift',
      children: [
        {
          title: 'Shift Management',
          navigation: '/shift-management',
          icon: <AlarmOn />,
          role: ['hr admin', 'super admin', 'super admin hr'],
        },
        {
          title: 'Shift Setting',
          navigation: '/shift-setting',
          icon: <AlarmOn />,
          role: ['hr admin', 'super admin', 'super admin hr'],
        },
      ],
    },

    // New Billing

    // {
    //   title: 'Subscriptions',
    //   navigation: '/subscriptions',
    //   icon: <SvgIcon>
    //     <PaymentIcon height={24} width={24}/>
    //   </SvgIcon>,
    //   role: ['hr admin'],
    // },

    // {
    //   title: 'Subscriptions History',
    //   navigation: '/subscriptions-history',
    //   icon: <SvgIcon>
    //     <PaymentIcon height={24} width={24}/>
    //   </SvgIcon>,
    //   role: ['hr admin'],
    // },

    // Old Subscription
    {
      title: 'Subscription',
      navigation: '/payment-history',
      role: ['hr admin', 'super admin hr'],
      icon: (
        <SvgIcon>
          <PaymentIcon height={24} width={24} />
        </SvgIcon>
      ),
      expandable: true,
      refKey: 'togglePayment',
      children: [
        {
          name: 'subscription',
          title: 'Invoice',
          navigation: '/invoice',
          icon: <LocalAtmIcon />,
          role: ['hr admin', 'super admin hr'],
        },
        {
          name: 'subscription-history',
          title: 'History',
          navigation: '/payment-history',
          role: ['hr admin', 'super admin hr'],
        },
        {
          name: 'old-invoice',
          title: 'Old Invoice',
          navigation: '/old-invoice',
          role: ['hr admin', 'super admin hr'],
        },
      ],
    },

    {
      title: 'Employee Facilities',
      navigation: '/employee-facilities',
      name: 'employee_facilities',
      role: ['hr admin', 'super admin hr'],
      icon: (
        <SvgIcon>
          <EmployeeFacilitiesIcon height={24} width={24} />
        </SvgIcon>
      ),
      expandable: true,
      refKey: 'toggleLoan',
      children: [
        {
          title: 'Cash Advance',
          navigation: '/cash-advance',
          name: 'cash_advance',
          icon: <LocalAtmIcon />,
          role: ['hr admin', 'super admin hr'],
        },
        {
          title: 'Office Inventory',
          navigation: '/office-inventory',
          name: 'office_inventory',
          icon: <LocalAtmIcon />,
          role: ['hr admin', 'super admin hr'],
        },
        {
          title: 'Master Inventory',
          navigation: '/master-inventory',
          name: 'master_inventory',
          icon: <LocalAtmIcon />,
          role: ['hr admin', 'super admin hr'],
        },
      ],
    },
    {
      title: 'Account Representative Management',
      icon: (
        <SvgIcon>
          <PartnerIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['super admin'],
      expandable: true,
      refKey: 'togglePartner',
      refKey: 'withdrawal',
      children: [
        {
          title: 'Account Representative',
          navigation: '/account-representative',
          role: ['super admin'],
        },
        {
          title: 'Withdraw Request',
          navigation: '/withdraw-request',
          role: ['super admin'],
        },
      ],
    },
    {
      title: 'Admin Management',
      navigation: '/admin-management',
      icon: (
        <SvgIcon>
          <AdminManagementIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['super admin'],
    },
    {
      title: 'SSO Management',
      navigation: '/sso-management',
      icon: (
        <SvgIcon>
          <SSOWhiteList height={24} width={24} />
        </SvgIcon>
      ),
      role: ['super admin'],
    },
    // {
    //   title: 'Paid Leave',
    //   navigation: '/paid-leave',
    //   icon: <LocalAtmIcon />,
    //   role: ['hr admin'],
    // },
    {
      title: 'Fraud Detection',
      navigation: '/fraud-detection',
      icon: <Settings />,
      role: ['hr admin', 'super admin hr'],
      name: 'frauddetection',
    },
    {
      title: 'Invoice',
      icon: (
        <SvgIcon>
          <InvoiceIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['super admin'],
      expandable: true,
      children: [
        {
          title: 'Invoice List',
          navigation: '/invoice',
          role: ['super admin'],
        },
        {
          title: 'Payment History',
          navigation: '/payment-history',
          role: ['super admin'],
        },
      ],
    },
    // {
    //   title: 'Leaderboard',
    //   navigation: '/leaderboard',
    //   icon: <SportsEsportsIcon />,
    //   role: ['hr admin', 'super admin'],
    // },
    // {
    //   title: 'Analytics',
    //   icon: <SvgIcon><AnalyticsIcon height={24} width={24}/></SvgIcon>,
    //   role: ['super admin'],
    //   expandable: true,
    //   refKey: 'toggleAnalytics',
    //   children: [
    //     {
    //       title: 'Registration',
    //       navigation: '/registration-analytics',
    //       role: ['super admin'],
    //     },
    //     {
    //       title: 'Payment',
    //       navigation: '/payment-analytics',
    //       role: ['super admin'],
    //     },
    //   ],
    // },
    {
      title: 'General Setting',
      navigation: '/general-setting',
      icon: (
        <SvgIcon>
          <GeneralSettingIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['super admin'],
    },
    {
      title: 'Export Activity',
      navigation: '/export-activity',
      icon: (
        <SvgIcon>
          <ExportActivityIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['hr admin', 'super admin hr'],
    },
    {
      title: 'General Setting',
      navigation: '/general-setting',
      icon: (
        <SvgIcon>
          <GeneralSettingIcon height={24} width={24} />
        </SvgIcon>
      ),
      role: ['hr admin', 'super admin hr'],
      expandable: true,
      refKey: 'toggleGeneralSetting',
      children: [
        {
          title: 'Announcements',
          navigation: '/announcements',
          role: ['hr admin', 'super admin hr'],
        },
        {
          title: 'Company Setting',
          navigation: '/general-setting',
          role: ['hr admin', 'super admin hr'],
          expandable: true,
          refKey: 'toggleCompanySetting',
          children: [
            {
              title: 'Job Settings',
              navigation: '/job-titles',
              role: ['hr admin', 'super admin hr'],
            },
            {
              title: 'Division Management',
              navigation: '/division-management',
              icon: (
                <SvgIcon>
                  <DivisionManagementIcon height={24} width={24} />
                </SvgIcon>
              ),
              role: ['hr admin', 'super admin hr'],
            },
            {
              title: 'Working Time',
              navigation: '/working-time',
              role: ['hr admin', 'super admin hr'],
            },
            {
              title: 'Leaderboard Settings',
              navigation: '/leaderboard-settings',
              role: ['hr admin', 'super admin hr'],
            },
            {
              title: 'Payslip Settings',
              navigation: '/payslip-settings',
              role: ['hr admin', 'super admin hr'],
              name: 'payslip_feature',
            },
            {
              title: 'Advantech Settings',
              navigation: '/advantech-settings',
              role: ['hr admin', 'super admin hr'],
              name: 'advantech',
            },
            {
              title: 'Mobile Feature Setting',
              navigation: '/mobile-feature-setting',
              role: ['hr admin', 'super admin hr'],
            },
            {
              title: 'Approval Order Setting',
              navigation: '/approval-order-setting',
              role: ['hr admin', 'super admin hr'],
              name: 'approval',
            },
          ],
        },
        {
          title: 'Company Profile',
          navigation: '/company-profile',
          role: ['hr admin', 'super admin hr'],
        },
        {
          title: 'Generate QR',
          navigation: '/generate',
          role: ['hr admin', 'super admin hr'],
        },
      ],
    },
  ];

  if (!orgId) {
    menus.filter((data) => data?.navigation !== '/subscriptions');
  }

  return menus.filter((data) => {
    return data.role.includes(role.toLowerCase());
  });
};

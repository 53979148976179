export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: 2,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  datatable: {
    marginTop: 25,
    MuiToolbar: {
      root: {
        display: 'none',
      },
    },
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  tableTitle: {
    fontSize: '18px',
    fontWeight: '700',
    color: '#656464',
    fontFamily: 'Poppins !important',
  },
  checkBox: {
    width: 70,
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  hasError: {
    color: 'red',
  },
  photo: {
    height: 150,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
  filterBtn: {
    'marginTop': 25,
    'marginRight': 15,
    'marginLeft': 10,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 0,
  },
  filter: {
    marginRight: 50,
    width: 320,
  },
  filterSearch: {
    minWidth: 320,
    marginRight: 25,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  dialogFooter: {
    padding: 20,
  },
  dialogContent: {
    padding: '8px 24px 0px 24px',
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  btnCrop: {
    marginTop: 10,
  },
});

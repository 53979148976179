/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {
  Link,
  CircularProgress,
} from '@material-ui/core';
import {ACCOUNT_STATE as STATE} from '../../redux/account/account.types';
import {toast, isObject, checkMobile} from '../../helpers';
import {MInput, MButton} from '../../components/form';
import styles from './styles';
import WebTemplate from '../../components/template';
import {
  checkToken,
  updatePassword,
  clearState,
} from '../../redux/account/account.actions';

class ChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: null,
      token: null,
      new_password: null,
      new_password_confirm: null,
      errorMessage: null,
      status: null,
    };
    this.initialState = this.state;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message} = nextProps;

    if (isSuccess) {
      if (trigger === STATE.CHECK_TOKEN_SUCCESS) {
        this.setState({status: 'token-valid'});
      }

      if (trigger === STATE.CHANGE_PASSWORD_SUCCESS) {
        this.setState({status: 'update-success'});
        this.handleResetForm(true);
      }
    }

    if (isError) {
      if (trigger === STATE.CHECK_TOKEN_FAILED) {
        this.setState({status: 'token-invalid'});
      } else {
        if (message && isObject(message)) this.setState({errorMessage: message});
        if (message && !isObject(message)) toast('error', message);
      }
    }
  }

  componentDidMount() {
    this.handleCheckToken();
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  handleCheckToken = () => {
    const queryParam = this.props.location.search.substring(1); // remove the "?" at the start
    if (!queryParam) {
      this.props.history.push('/login');
    } else {
      const searchParams = new URLSearchParams(queryParam);
      const token = searchParams.get('token');
      const email = searchParams.get('email');

      this.setState({token, email});
      this.props.checkToken({
        ...(token && {token}),
        ...(email && {email}),
      });
    }
  }

  handleResetForm = () => {
    this.setState({
      token: null,
      email: null,
      new_password: null,
      new_password_confirm: null,
      errorMessage: null,
    });
  }

  handleInputChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      [name]: value,
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  }

  handleBackToWeb = (e) => {
    e.preventDefault();
    this.props.history.push('/login');
  }

  handleBackToNative = () => {
    const payload = {type: 'backToNative'};
    const json = (JSON.stringify(payload));
    window.postMessage(json, '*');
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {token, email, new_password, new_password_confirm} = this.state;
    const params = {
      ...(token && {token}),
      ...(email && {email}),
      ...(new_password && {new_password}),
      ...(new_password_confirm && {new_password_confirm}),
    };
    this.props.updatePassword(params);
  }

  renderForm = () => {
    const {classes, isLoading} = this.props;
    const {new_password, new_password_confirm, errorMessage} = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <h5 className={classes.formTitle}>Change Your Password</h5>
        <MInput
          classNameFC={classes.formControl}
          name="new_password"
          label="New Password"
          placeholder="Enter your new password"
          defaultValue={new_password}
          onBlur={this.handleInputChange}
          error={errorMessage && errorMessage.new_password ? errorMessage.new_password : undefined}
          variant="standard"
          type="password"
          autoFocus
          fullWidth
        />
        <MInput
          classNameFC={classes.formControl}
          name="new_password_confirm"
          label="Confirm New Password"
          placeholder="Enter your new password again"
          defaultValue={new_password_confirm}
          onBlur={this.handleInputChange}
          error={errorMessage && errorMessage.new_password_confirm ? errorMessage.new_password_confirm : undefined}
          variant="standard"
          type="password"
          fullWidth
        />
        <div className={classes.btnContainer}>
          <MButton
            className={classes.btnSubmit}
            label="Submit"
            color="primary"
            type="submit"
            loading={isLoading}
          />
        </div>
      </form>
    );
  };

  renderSuccess = () => {
    const {classes} = this.props;
    const isMobile = checkMobile();
    return (
      <div>
        <h5 className={`${classes.formTitle} ${classes.center}`}>Password Changed</h5>
        <h6 className={classes.formText}>
          Your password has been successfully changed
        </h6>
        <div className={classes.btnThankYouContainer}>
          <Link
            href={'/finish-change-password'}
            className={classes.registerBtn}
            onClick={isMobile ? this.handleBackToNative : this.handleBackToWeb}
          >
            <span style={{color: '#3f51b5 !important'}}>Login</span>
          </Link>
        </div>
      </div>
    );
  }

  renderInvalid = () => {
    const {classes} = this.props;
    const isMobile = checkMobile();
    return (
      <div>
        <h5 className={`${classes.formTitle} ${classes.center}`}>Oops...</h5>
        <h6 className={classes.formText}>
          Looks like this link already expired or not valid, please submit again your request
        </h6>
        <div className={classes.btnThankYouContainer}>
          <Link
            href={'/finish-change-password'}
            className={classes.btnSubmit}
            onClick={isMobile ? this.handleBackToNative : this.handleBackToWeb}
          >
            Back to Login
          </Link>
        </div>
      </div>
    );
  }

  renderLoading = () => {
    const {classes} = this.props;
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress className={classes.loader} color="inherit" size={24} />
        <div className={classes.loaderText}>{'Please wait...'}</div>
      </div>
    );
  }

  render() {
    const {status} = this.state;
    const {classes} = this.props;
    return (
      <WebTemplate
        header
        footer
      >
        <div className={classes.root} style={{backgroundColor: '#ffffff91'}}>
          <div className={classes.paper}>
            {status === 'update-success' && this.renderSuccess()}
            {status === 'token-valid' && this.renderForm()}
            {status === 'token-invalid' && this.renderInvalid()}
            {!status && this.renderLoading()}
          </div>
        </div>
      </WebTemplate>
    );
  }
}

const stateToProps = ({account}) => ({
  isLoading: account.isLoading,
  isError: account.isError,
  isSuccess: account.isSuccess,
  message: account.message,
  trigger: account.trigger,
});

const dispatchToProps = (dispatch) => ({
  checkToken: (params) => dispatch(checkToken(params)),
  updatePassword: (params) => dispatch(updatePassword(params)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(ChangePassword));

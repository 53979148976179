/* eslint-disable max-len */
import {OVERTIME} from '../../services';
import {APPROVAL_STATE as STATE} from './approvalOvertime.types';

export const getApprovalOvertimeByToken = (params) => {
  return (dispatch) => {
    dispatch(fetchApprovalOvertimeHistory());
    OVERTIME.getOvertimeHistoryByToken(params).then((response) => {
      const data = response.data;
      if (!data.error) {
          dispatch(fetchApprovalOvertimeSuccess(data));
        } else {
        dispatch(fetchApprovalOvertimeFailed(data));
      }
    }).catch((error) => {
      const message = error?.response?.data?.data?.errors && error?.response?.data?.data?.errors[0]?.value ? error?.response?.data?.data?.errors[0]?.value : 'Something went wrong';
      dispatch(fetchApprovalOvertimeFailed({message}));
    });
  };
};

export const confirmApprovalOvertime = (params) => {
  return (dispatch) => {
    dispatch(submitApprovalOvertime());
    OVERTIME.confirmApprovalOvertime(params).then((response) => {
      const data = response.data;
      if (!data.error) {
        dispatch(submitApprovalOvertimeSuccess(data));
      } else {
        dispatch(submitApprovalOvertimeFailed(data));
      }
    }).catch((error) => {
      const message = error?.response?.data?.data?.errors && error?.response?.data?.data?.errors[0]?.value ? error?.response?.data?.data?.errors[0]?.value : 'Something went wrong';
      dispatch(submitApprovalOvertimeFailed({message}));
    });
  };
};

export const resetLoader = () => ({
  type: STATE.RESET_LOADER,
});

const fetchApprovalOvertimeHistory = () => ({
  type: STATE.GET_APPROVAL_OVERTIME_BY_TOKEN,
});

const fetchApprovalOvertimeSuccess = (data) => ({
  type: STATE.GET_APPROVAL_OVERTIME_BY_TOKEN_SUCCESS,
  data,
});

const fetchApprovalOvertimeFailed = (data) => ({
  type: STATE.GET_APPROVAL_OVERTIME_BY_TOKEN_FAILURE,
  data,
});

const submitApprovalOvertime = () => ({
  type: STATE.SUBMIT_APPROVAL_OVERTIME,
});

const submitApprovalOvertimeSuccess = (data) => ({
  type: STATE.SUBMIT_APPROVAL_OVERTIME_SUCCESS,
  data,
});

const submitApprovalOvertimeFailed = (data) => ({
  type: STATE.SUBMIT_APPROVAL_OVERTIME_FAILURE,
  data,
});

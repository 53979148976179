/* eslint-disable max-len */
import {persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from './auth/auth.reducer';
import login from './login/login.reducer';
import userManagement from './userManagement/userManagement.reducer';
import roleManagement from './roleManagement/rolemanagement.reducer';
import attendanceHistory from './attendanceHistory/attendance.reducer';
import fraudDetection from './fraudDetection/fraud.reducer';
import overtimeHistory from './overtimeHistory/overtime.reducer';
import generalSetting from './generalSetting/generalSetting.reducer';
import organization from './organization/organization.reducer';
import confirmPayment from './confirmPayment/confirmPayment.reducer';
import upgradeAccount from './upgradeAccount/upgradeAccount.reducer';
import paidLeave from './paidLeave/paidLeave.reducer';
import reimburse from './reimburse/reimburse.reducer';
import jobTitle from './jobTitle/jobTitle.reducer';
import leaveConfig from './leaveConfig/leaveConfig.reducer';
import leaveManagement from './leaveManagement/leaveManagement.reducer';
import leaveManagementDetail from './leaveManagementDetail/leaveManagementDetail.reducer';
import masterHoliday from './masterHoliday/masterHoliday.reducer';
import companyType from './companyType/companyType.reducer';
import register from './register/register.reducer';
import registration from './registrationManagement/registration.reducer';
import account from './account/account.reducer';
import global from './global/global.reducer';
import dashboard from './dashboard/dashboard.reducer';
import permit from './permit/permit.reducer';
import shiftManagement from './shiftManagement/shiftManagement.reducer';
import approval from './approval/approval.reducer';
import approvalOvertime from './approvalOvertime/approvalOvertime.reducer';
import approvalPermit from './approvalPermit/approvalPermit.reducer';
import approvalReimburse from './approvalReimburse/approvalReimburse.reducer';
import approvalPayment from './approvalPayment/approvalPayment.reducer';
import invoice from './invoice/invoice.reducer';
import leaderboard from './leaderboard/leaderboard.reducer';
import xendit from './xendit/xendit.reducer';
import forgotPassword from './forgotPassword/forgotPassword.reducer';
import partner from './partner/partner.reducer';
import withdraw from './withdraw/withdraw.reducer';
import ssoWhitelist from './ssoWhitelist/ssoWhitelist.reducer';
import officeInventory from './officeInventory/officeInventory.reducer';
import cashAdvance from './cashAdvance/cashAdvance.reducer';
import masterInventory from './masterInventory/masterInventory.reducer';
import divisionManagement from './divisionManagement/divisionManagement.reducer';
import approvalOrder from './approvalOrder/approvalOrder.reducer';
import bank from './bank/bank.reducer';
import notification from './notification/notification.reducer';
import exportActivity from './exportActivity/exportActivity.reducer';
import refreshToken from './refreshToken/refreshToken.reducer';
import eventManagement from './eventManagement/eventManagement.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'global', 'refreshToken'],
};

const allReducer = {
  auth,
  login,
  userManagement,
  roleManagement,
  attendanceHistory,
  fraudDetection,
  overtimeHistory,
  generalSetting,
  organization,
  confirmPayment,
  upgradeAccount,
  paidLeave,
  reimburse,
  jobTitle,
  leaveConfig,
  leaveManagement,
  leaveManagementDetail,
  masterHoliday,
  companyType,
  register,
  registration,
  account,
  global,
  dashboard,
  permit,
  shiftManagement,
  approval,
  approvalOvertime,
  approvalPermit,
  approvalReimburse,
  approvalPayment,
  invoice,
  leaderboard,
  xendit,
  forgotPassword,
  partner,
  withdraw,
  ssoWhitelist,
  officeInventory,
  cashAdvance,
  masterInventory,
  divisionManagement,
  approvalOrder,
  bank,
  notification,
  exportActivity,
  refreshToken,
  eventManagement,
};

export default persistCombineReducers(persistConfig, allReducer);

// *** DEVNOTE : replace with this code to reset persist storage on logout ***

// const appReducer = persistCombineReducers(persistConfig, allReducer);
// const rootReducer = (state, action) => {
//     if (action.type === 'LOGOUT_SUCCESS') {
//         storage.removeItem('persist:root');
//         state = undefined;
//     }
//     return appReducer(state, action);
// }

// export default rootReducer;

export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginBottom: 16,
    borderRadius: 16,
    boxShadow: 'none',
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  shiftFormControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: '28.5%',
    marginRight: 18,
  },
  nameFormControl: {
    width: '108%',
    marginRight: 18,
  },
  defaultBtn: {
    'marginTop': 10,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'fontSize': '14px',
    'color': '#fff',
    'fontWeight': '400',
  },
  infoBtn: {
    'marginTop': 20,
    'backgroundColor': '#4051B6',
    '&:hover': {
      backgroundColor: '#344193',
    },
    'color': '#fff',
  },
  btnAddContainer: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'flex-start',
    padding: '0px 20px',
  },
  btnAdd: {
    'marginTop': 12,
    'fontSize': '14px',
    'height': 36,
    'width': 120,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    'color': '#fff',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'marginRight': 10,
    'fontWeight': '400',
  },
  btnDelete: {
    'color': '#fff',
    'width': '100px',
    'height': '38px',
    'margin-top': '24px',
    'font-weight': '400',
    'font-size': '14px',
    'background-color': '#E5AF5A',
    '&:hover': {
      backgroundColor: '#D09F53',
    },
    'marginLeft': '40px',
  },
  subTitle: {
    marginTop: 10,
    fontSize: 18,
    fontWeight: 700,
  },
  my10: {
    marginBottom: 10,
    marginTop: 10,
  },
  myAuto: {
    'marginBottom': 'auto',
    'marginTop': 'auto',
  },
});

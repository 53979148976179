/* eslint-disable no-dupe-keys */
export default (theme) => ({
  paper: {
    display: 'inherit',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '18px 36px 18px 36px',
  },
  paperImage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '26px',
    backgroundSize: 'contain',
    borderRadius: '18px 0px 0px 18px',
    backgroundRepeat: 'repeat',
  },
  papersRight: {
    boxShadow: 'none',
    marginTop: 128,
    borderRadius: '0px 18px 18px 0px',
    borderRadius: 18,
  },
  papersLeft: {
    boxShadow: 'none',
    marginTop: 128,
    borderRadius: '18px 0px 0px 18px',
  },
  recaptcha: {
    width: '20px !important',
    height: '2px !important',
  },
  loginTitle: {
    fontSize: '48px',
    fontWeight: '700',
    fontFamily: 'Poppins !important',
    color: '#333346',
    marginBottom: '32px',
  },
  imagePaperTitle: {
    fontSize: '19px',
    fontWeight: '700',
    fontFamily: 'Poppins !important',
    color: '#333346',
    marginBottom: '8px',
    marginTop: '440px',
  },
  formLabel: {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Poppins !important',
    color: '#333346',
  },
  imagePaperSubTitle: {
    fontSize: '13.4px',
    fontWeight: '400',
    fontFamily: 'Poppins !important',
    color: '#333346',
    marginBottom: '10px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    'borderRadius': 8,
    'margin': theme.spacing(3, 0, 2),
    'boxShadow': 'none',
    'backgroundColor': '#00AFF0',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#FFFFFF',
    'height': 48,
    'textTransform': 'inherit',
  },
  forgotPasswordText: {
    'boxShadow': 'none',
    'color': '#00AFF0',
    '&:hover': {
      color: '#00A2DE',
      boxShadow: 'none',
    },
    'fontSize': 12,
    'marginTop': 10,
  },
  forgotPassword: {
    'boxShadow': 'none',
    'color': '##444B55',
    'fontSize': 12,
    'marginTop': 10,
  },
  copyright: {
    fontFamily: 'Poppins !important',
    fontSize: '12px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#808C92',
    marginBottom: '56px',
    marginTop: '12px',
  },
});

import {initialState, DASHBOARD_STATE as STATE} from './dashboard.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.FETCH:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
      };
    case STATE.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: null,
        totalEmployees: action.payload.data.totalEmployees,
        today: action.payload.data.today,
        yesterday: action.payload.data.yesterday,
        lastWeek: action.payload.data.lastWeek,
        lastMonth: action.payload.data.lastMonth,
        dashboardTime: action.payload.data.dashboardTime,
      };
    case STATE.FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: null,
      };
    case STATE.GET_NO_RECORD:
      return {
        ...state,
        isLoadingNoRecord: true,
        isSuccessNoRecord: false,
        isFailureNoRecord: false,
        dataNoRecord: [],
        metaNoRecord: [],
      };
    case STATE.GET_NO_RECORD_SUCCESS:
      return {
        ...state,
        isLoadingNoRecord: false,
        isSuccessNoRecord: true,
        isFailureNoRecord: false,
        dataNoRecord: action.payload.data,
        metaNoRecord: action.payload.meta,
      };
    case STATE.GET_NO_RECORD_FAILURE:
      return {
        ...state,
        isLoadingNoRecord: false,
        isSuccessNoRecord: false,
        isFailureNoRecord: true,
        dataNoRecord: [],
        metaNoRecord: [],
      };
    case STATE.FETCH_TODAY:
      return {
        ...state,
        isTodayLoading: true,
        isTodayError: false,
        isTodaySuccess: false,
        todayMessage: null,
      };
    case STATE.FETCH_TODAY_SUCCESS:
      return {
        ...state,
        trigger: action.type,
        isTodayLoading: false,
        isTodayError: false,
        isTodaySuccess: true,
        todayMessage: null,
        totalEmployees: action.payload.data.totalEmployees,
        today: action.payload.data.today,
      };
    case STATE.FETCH_TODAY_FAILED:
      return {
        ...state,
        trigger: action.type,
        isTodayLoading: false,
        isTodayError: true,
        isTodaySuccess: false,
        todayMessage: action.payload,
      };
    case STATE.GET_TODAY_NO_RECORD:
      return {
        ...state,
        isTodayLoadingNoRecord: true,
        isTodaySuccessNoRecord: false,
        isTodayFailureNoRecord: false,
        todayDataNoRecord: [],
        todayMetaNoRecord: [],
      };
    case STATE.GET_TODAY_NO_RECORD_SUCCESS:
      return {
        ...state,
        isTodayLoadingNoRecord: false,
        isTodaySuccessNoRecord: true,
        isTodayFailureNoRecord: false,
        todayDataNoRecord: action.payload.data,
        todayMetaNoRecord: action.payload.meta,
      };
    case STATE.GET_TODAY_NO_RECORD_FAILURE:
      return {
        ...state,
        isTodayLoadingNoRecord: false,
        isTodaySuccessNoRecord: false,
        isTodayFailureNoRecord: true,
        todayDataNoRecord: [],
        todayMetaNoRecord: [],
      };
    case STATE.FETCH_YESTERDAY:
      return {
        ...state,
        isYesterdayLoading: true,
        isYesterdayError: false,
        isYesterdaySuccess: false,
        yesterdayMessage: null,
      };
    case STATE.FETCH_YESTERDAY_SUCCESS:
      return {
        ...state,
        trigger: action.type,
        isYesterdayLoading: false,
        isYesterdayError: false,
        isYesterdaySuccess: true,
        yesterdayMessage: null,
        yesterday: action.payload.data.yesterday,
      };
    case STATE.FETCH_YESTERDAY_FAILED:
      return {
        ...state,
        trigger: action.type,
        isYesterdayLoading: false,
        isYesterdayError: true,
        isYesterdaySuccess: false,
        yesterdayMessage: action.payload,
      };
    case STATE.GET_YESTERDAY_NO_RECORD:
      return {
        ...state,
        isYesterdayLoadingNoRecord: true,
        isYesterdaySuccessNoRecord: false,
        isYesterdayFailureNoRecord: false,
        yesterdayDataNoRecord: [],
        yesterdayMetaNoRecord: [],
      };
    case STATE.GET_YESTERDAY_NO_RECORD_SUCCESS:
      return {
        ...state,
        isYesterdayLoadingNoRecord: false,
        isYesterdaySuccessNoRecord: true,
        isYesterdayFailureNoRecord: false,
        yesterdayDataNoRecord: action.payload.data,
        yesterdayMetaNoRecord: action.payload.meta,
      };
    case STATE.GET_YESTERDAY_NO_RECORD_FAILURE:
      return {
        ...state,
        isYesterdayLoadingNoRecord: false,
        isYesterdaySuccessNoRecord: false,
        isYesterdayFailureNoRecord: true,
        yesterdayDataNoRecord: [],
        yesterdayMetaNoRecord: [],
      };
    case STATE.FETCH_LAST_WEEK:
      return {
        ...state,
        isLastWeekLoading: true,
        isLastWeekError: false,
        isLastWeekSuccess: false,
        lastWeekMessage: null,
      };
    case STATE.FETCH_LAST_WEEK_SUCCESS:
      return {
        ...state,
        trigger: action.type,
        isLastWeekLoading: false,
        isLastWeekError: false,
        isLastWeekSuccess: true,
        lastWeekMessage: null,
        lastWeek: action.payload.data.lastWeek,
      };
    case STATE.FETCH_LAST_WEEK_FAILED:
      return {
        ...state,
        trigger: action.type,
        isLastWeekLoading: false,
        isLastWeekError: true,
        isLastWeekSuccess: false,
        lastWeekMessage: action.payload,
      };
    case STATE.GET_LAST_WEEK_NO_RECORD:
      return {
        ...state,
        isLastWeekLoadingNoRecord: true,
        isLastWeekSuccessNoRecord: false,
        isLastWeekFailureNoRecord: false,
        lastWeekDataNoRecord: [],
        lastWeekMetaNoRecord: [],
      };
    case STATE.GET_LAST_WEEK_NO_RECORD_SUCCESS:
      return {
        ...state,
        isLastWeekLoadingNoRecord: false,
        isLastWeekSuccessNoRecord: true,
        isLastWeekFailureNoRecord: false,
        lastWeekDataNoRecord: action.payload.data,
        lastWeekMetaNoRecord: action.payload.meta,
      };
    case STATE.GET_LAST_WEEK_NO_RECORD_FAILURE:
      return {
        ...state,
        isLastWeekLoadingNoRecord: false,
        isLastWeekSuccessNoRecord: false,
        isLastWeekFailureNoRecord: true,
        lastWeekDataNoRecord: [],
        lastWeekMetaNoRecord: [],
      };
    case STATE.FETCH_LAST_MONTH:
      return {
        ...state,
        isLastMonthLoading: true,
        isLastMonthError: false,
        isLastMonthSuccess: false,
        lastMonthMessage: null,
      };
    case STATE.FETCH_LAST_MONTH_SUCCESS:
      return {
        ...state,
        trigger: action.type,
        isLastMonthLoading: false,
        isLastMonthError: false,
        isLastMonthSuccess: true,
        lastMonthMessage: null,
        lastMonth: action.payload.data.lastMonth,
      };
    case STATE.FETCH_LAST_MONTH_FAILED:
      return {
        ...state,
        trigger: action.type,
        isLastMonthLoading: false,
        isLastMonthError: true,
        isLastMonthSuccess: false,
        lastMonthMessage: action.payload,
      };
    case STATE.GET_LAST_MONTH_NO_RECORD:
      return {
        ...state,
        isLastMonthLoadingNoRecord: true,
        isLastMonthSuccessNoRecord: false,
        isLastMonthFailureNoRecord: false,
        lastMonthDataNoRecord: [],
        lastMonthMetaNoRecord: [],
      };
    case STATE.GET_LAST_MONTH_NO_RECORD_SUCCESS:
      return {
        ...state,
        isLastMonthLoadingNoRecord: false,
        isLastMonthSuccessNoRecord: true,
        isLastMonthFailureNoRecord: false,
        lastMonthDataNoRecord: action.payload.data,
        lastMonthMetaNoRecord: action.payload.meta,
      };
    case STATE.GET_LAST_MONTH_NO_RECORD_FAILURE:
      return {
        ...state,
        isLastMonthLoadingNoRecord: false,
        isLastMonthSuccessNoRecord: false,
        isLastMonthFailureNoRecord: true,
        lastMonthDataNoRecord: [],
        lastMonthMetaNoRecord: [],
      };

    default:
      return state;
  }
};

/* eslint-disable max-len */
import {JOB_TITLE} from '../../services';
import {JOB_TITLE_STATE as STATE} from './jobTitle.types';
import {transformObject, isArray} from '../../helpers';

export const getJobTitle = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    JOB_TITLE.getJobTitle(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getJobTitleByOrg = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_BY_ORG));
    JOB_TITLE.getJobTitleByOrg(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_BY_ORG_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_BY_ORG_FAILED, response.data.message));
            },
        );
  };
};

export const createJob = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE));
    JOB_TITLE.addJob(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CREATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CREATE_FAILED, message));
            },
        );
  };
};

export const updateJob = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    JOB_TITLE.updateJob(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const deleteJob = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.DROP));
    JOB_TITLE.dropJob(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DROP_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.DROP_FAILED, message));
            },
        );
  };
};

export const exportExcel = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT, true));
    JOB_TITLE.exportExcel(params)
        .then(
            (response) => {
              const file = response.data.data.file;
              dispatch(requestSuccess(STATE.EXPORT_SUCCESS, response.data.data));
              window.location.href = file; // alternative : window.open(file);
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(exportFailed(STATE.EXPORT_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});

export const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    trigger: '',
    leaveManagementData: [],
    leaveManagementMeta: {page: 1, total: 0},
    message: {
      name: '',
    },
  };

  export const LEAVE_MANAGEMENT_STATE = {
    REQUEST: 'LEAVE_MANAGEMENT_REQUEST',
    REQUEST_FAILED: 'LEAVE_MANAGEMENT_REQUEST_FAILED',
    CLEAR_STATE: 'LEAVE_MANAGEMENT_CLEAR_STATE',
    FETCH: 'LEAVE_MANAGEMENT_FETCH',
    FETCH_SUCCCESS: 'LEAVE_MANAGEMENT_FETCH_SUCCCESS',
    FETCH_FAILED: 'LEAVE_MANAGEMENT_FETCH_FAILED',
  };


/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import './style.css';
import {parseJwt} from '../../helpers';

const Subscriptions = () => {
  const auth = useSelector(({auth}) => auth);

  const [appId, setAppId] = useState('');
  const [token, setToken] = useState('');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth?.data?.authorization) {
      setAppId(parseJwt(auth?.data?.authorization)?.appId ?? '');
      setToken(auth?.data?.authorization?.replace?.('Bearer ', '') ?? '');
    }
  }, [auth?.data?.authorization]);

  return <div className={loading ? 'container-loading' : ''}>
    {loading && <div className="wrapper-loader">
      <p>Loading</p>
      <div className="state-changed"></div>
    </div>}
    <iframe
      id={`subscriptions`}
      className={`${!loading && 'bg-subscriptions'}`}
      // eslint-disable-next-line max-len
      src={`https://pbapi.smtapps.net/products/${appId}?${auth?.data?.authorization ? `token=${token}` : ''}`}
      frameBorder="0"
      onLoad={() => setLoading(false)}
      onError={() => setLoading(false)}
      sandbox="allow-same-origin allow-scripts allow-top-navigation"
      allowpaymentrequest="true"
      view="fullScreenPreview"
      title="subscriptions"
    >
    </iframe>
  </div>;
};

export default Subscriptions;

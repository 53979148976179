/* eslint-disable max-len */
import {createStore, applyMiddleware} from 'redux';
import {persistStore} from 'redux-persist';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from '../redux/rootReducers';

const middlewareArray = [thunk];
const middlewares = (process.env.REACT_APP_MODE === 'DEV') ? composeWithDevTools(applyMiddleware(...middlewareArray)) : applyMiddleware(...middlewareArray);

export const store = createStore(rootReducer, middlewares);
export const persistor = persistStore(store);

/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {
  Paper,
  CircularProgress,
  Link,
  IconButton,
} from '@material-ui/core';
import {
  Refresh,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import {ORGANIZATION_STATE} from '../../../redux/organization/organization.types';
import {
  activateInvitationOrganization,
  activateParentInvitationOrganization,
} from '../../../redux/organization/organization.actions';
import {MButton, MInput} from '../../../components/form';
import {REGISTER_STATE as STATE} from '../../../redux/register/register.types';
import {toast, isObject, checkSuperadmin, decrypt, checkMobile, resetLoader} from '../../../helpers';
import {submitApproval} from '../../../redux/register/register.actions';
import WebTemplate from '../../../components/template';

class EmailInvitationActivation extends React.Component {
  constructor() {
    super();
    this.state = {
        status: null,
        message: null,
        email: null,
        token: null,
        newPassword: null,
        newPasswordConfirm: null,
        errorMessage: null,
        showPassword: false,
        showConfirmPassword: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {isTrigger, message, organization} = nextProps;
    const {newPassword, newPasswordConfirm} = this.state;

    if (isTrigger === 'CONFIRM_INVITATION_SUCCESS') {
      toast('success', message.data.message);
      this.props.history.push('/login');
    }

    if (isTrigger === 'ACTIVATE_INVITATION_FAILED') {
      toast('error', message);
    }

    if (isTrigger === 'ACTIVATE_INVITATION_FAILED' && (!newPassword || !newPasswordConfirm)) {
      toast('error', 'Please finishing your password and confirm password');
    }
    // if (registerTrigger === STATE.APPROVAL_SUCCESS && this.props.registerMessage !== registerMessage) {
    //   this.setState({status: 'success', message: registerMessage});
    // }

    // if (registerTrigger === STATE.APPROVAL_FAILED && this.props.registerMessage !== registerMessage) {
    //   if (!isObject(registerMessage)) {
    //     this.setState({status: 'error', message: registerMessage});
    //   }
    //   if (message) {
    //     toast('error', message)
    //   }
    //   this.props.history.replace('/419')
    //   setTimeout(() => {
    //     this.props.history.replace('/')
    //   }, 2000);

    // }

    // if (isError && message) {
    //   if (isObject(message)) this.setState({errorMessage: message});
    //   if (!isObject(message)) toast('error', message);
    // }
  }

  componentDidMount() {
    const location = this.props.location.pathname;
    const segment = location.substring(location.lastIndexOf('/') + 1);

    // this.checkApproval();
  }

  handleInputChange = (e) => {
    const {name, value} = e.target;

    this.setState({
      [name]: value,
    });
  }

  onClickSendConfirmation = () => {
    const location = this.props.location.pathname;
    const segment = location.substring(location.lastIndexOf('/') + 1);
    const {newPassword, newPasswordConfirm} = this.state;

    const payload = {
      token: segment,
    };

    // if(this.props.location.pathname.includes('/activation-account-company')){
    //   this.props.activateInvitationOrganization(payload);
    // }
    // else{
    //   this.props.activateParentInvitationOrganization(payload);
    // }

    if (this.props.location.pathname.includes('/activation-account-company')) {
      this.props.activateInvitationOrganization(payload);
    } else {
      this.props.activateParentInvitationOrganization(payload);
    }
  }

  handleReset = () => {
    this.setState({
      status: null,
      message: null,
    });
  }

  renderLoading = () => {
    const {classes} = this.props;
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress className={classes.loader} color="inherit" size={24} />
        <div className={classes.loaderText}>{'Processing...'}</div>
      </div>
    );
  }

  renderResponse = () => {
    const {message} = this.state;
    const {classes} = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.message}>{message}</div>
        <MButton
          className={classes.btnBack}
          label="Go Back"
          icon={<Refresh/>}
          onClick={() => this.props.history.push('/company-management')}
        />
      </div>
    );
  }

  renderButton = () => {
    const {classes} = this.props;

    return (
        <div className={`${classes.height}`}>
            <h5 className={`${classes.formTitle} ${classes.center}`}>Hello</h5>
            <h5 className={`${classes.formTitle} ${classes.center}`}>Your account has been activated now</h5>
            <div className={classes.btnContainer}>
                <MButton
                  className={classes.registerBtn}
                  onClick={this.onClickSendConfirmation}
                  label="Login"
                  color="primary"
                />
            </div>
        </div>
    );
  };

  renderForm = () => {
    const {classes, isLoading} = this.props;
    const {newPassword, newPasswordConfirm, errorMessage} = this.state;

    return (
      <form>
        <MInput
          classNameFC={classes.formControl}
          name="newPassword"
          label="Password"
          placeholder="Enter your password"
          defaultValue={newPassword}
          onBlur={this.handleInputChange}
          error={errorMessage && errorMessage.newPassword ? errorMessage.newPassword : undefined}
          variant="standard"
          type={this.state.showPassword ? 'none' : 'password'}
          InputProps={{
            endAdornment:
              <IconButton position="end" onClick={() => this.setState({showPassword: !this.state.showPassword})}>
                {!this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>,
          }}
          autoFocus
          fullWidth
        />
        <MInput
          classNameFC={classes.formControl}
          name="newPasswordConfirm"
          label="Confirm Password"
          placeholder="Enter your password again"
          defaultValue={newPasswordConfirm}
          onBlur={this.handleInputChange}
          error={errorMessage && errorMessage.newPasswordConfirm ? errorMessage.newPasswordConfirm : undefined}
          variant="standard"
          type={this.state.showConfirmPassword ? 'none' : 'password'}
          InputProps={{
            endAdornment:
              <IconButton position="end" onClick={() => this.setState({showConfirmPassword: !this.state.showConfirmPassword})}>
                {!this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>,
          }}
          fullWidth
        />
        <div className={classes.btnContainer}>
          <MButton
            onClick={this.onClickSendPassword}
            className={classes.registerBtn}
            label="Submit"
            color="primary"
            loading={isLoading}
          />
        </div>
      </form>
    );
  };

  render() {
    const {classes, isSuccess} = this.props;
    return (
        <WebTemplate
            header
            footer
        >
            <div className={classes.center}>
                {this.renderButton()}
            </div>
        </WebTemplate>
    );
  }
}

const stateToProps = ({organization, auth}) => ({
  auth: auth,
  organization: organization,
  isTrigger: organization.trigger,
  message: organization.message,
});

const dispatchToProps = (dispatch) => ({
  activateInvitationOrganization: (params) => dispatch(activateInvitationOrganization(params)),
  activateParentInvitationOrganization: (params) => dispatch(activateParentInvitationOrganization(params)),
});

export default connect(
  stateToProps,
  dispatchToProps,
)(withStyles(styles)(EmailInvitationActivation));

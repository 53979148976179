import {ENDPOINTS, apiService} from '../config';
import {apiServiceV2} from '../config/interceptors';

export const login = (params) => {
  return apiServiceV2.post(
      ENDPOINTS.AUTH.LOGIN,
      params,
  );
};

export const loginSSO = (params) => {
  return apiServiceV2.post(
      ENDPOINTS.AUTH.LOGIN_SSO,
      params,
  );
};

export const ssoValidation = (params) => {
  return apiService.get(
      ENDPOINTS.AUTH.VALIDATE_SSO,
      {params},
  );
};

export const generateRefreshToken = (params) => {
  return apiServiceV2.post(
    ENDPOINTS.AUTH.GENERATE_TOKEN,
    params,
  );
};


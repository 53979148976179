export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: null,
  message: null,
  totalEmployees: 0,
  todayIsLoading: false,
  todayIsError: false,
  todayIsSuccess: false,
  todayTrigger: null,
  todayMessage: null,
  yesterdayIsLoading: false,
  yesterdayIsError: false,
  yesterdayIsSuccess: false,
  yesterdayTrigger: null,
  yesterdayMessage: null,
  lastWeekIsLoading: false,
  lastWeekIsError: false,
  lastWeekIsSuccess: false,
  lastWeekTrigger: null,
  lastWeekMessage: null,
  lastMonthIsLoading: false,
  lastMonthIsError: false,
  lastMonthIsSuccess: false,
  lastMonthTrigger: null,
  lastMonthMessage: null,
  dashboardTime: null,
  today: {
    businessCheckIn: 0,
    noRecord: 0,
    officeCheckIn: 0,
    remoteCheckIn: 0,
  },
  yesterday: {
    businessCheckIn: 0,
    noRecord: 0,
    officeCompleted: 0,
    officeNoCheckOut: 0,
    remoteCompleted: 0,
    remoteNoCheckOut: 0,
  },
  lastWeek: {
    businessCheckIn: 0,
    noRecord: 0,
    officeCompleted: 0,
    officeNoCheckOut: 0,
    remoteCompleted: 0,
    remoteNoCheckOut: 0,
  },
  lastMonth: {
    businessCheckIn: 0,
    noRecord: 0,
    officeCompleted: 0,
    officeNoCheckOut: 0,
    remoteCompleted: 0,
    remoteNoCheckOut: 0,
  },
  isLoadingNoRecord: false,
  isSuccessNoRecord: false,
  isFailureNoRecord: false,
  dataNoRecord: [],
  metaNoRecord: [],
  isTodayLoadingNoRecord: false,
  isTodaySuccessNoRecord: false,
  isTodayFailureNoRecord: false,
  todayDataNoRecord: [],
  todayMetaNoRecord: [],
  isYesterdayLoadingNoRecord: false,
  isYesterdaySuccessNoRecord: false,
  isYesterdayFailureNoRecord: false,
  yesterdayDataNoRecord: [],
  yesterdayMetaNoRecord: [],
  isLastWeekLoadingNoRecord: false,
  isLastWeekSuccessNoRecord: false,
  isLastWeekFailureNoRecord: false,
  lastWeekDataNoRecord: [],
  lastWeekMetaNoRecord: [],
  isLastMonthLoadingNoRecord: false,
  isLastMonthSuccessNoRecord: false,
  isLastMonthFailureNoRecord: false,
  lastMonthDataNoRecord: [],
  lastMonthMetaNoRecord: [],
};

export const DASHBOARD_STATE = {
  REQUEST: 'DASHBOARD_REQUEST',
  REQUEST_FAILED: 'DASHBOARD_REQUEST_FAILED',
  CLEAR_STATE: 'DASHBOARD_CLEAR_STATE',
  FETCH: 'DASHBOARD_FETCH',
  FETCH_SUCCESS: 'DASHBOARD_FETCH_SUCCESS',
  FETCH_FAILED: 'DASHBOARD_FETCH_FAILED',
  GET_NO_RECORD: 'GET_NO_RECORD',
  GET_NO_RECORD_SUCCESS: 'GET_NO_RECORD_SUCCESS',
  GET_NO_RECORD_FAILURE: 'GET_NO_RECORD_FAILURE',
  REQUEST_TODAY: 'DASHBOARD_REQUEST_TODAY',
  REQUEST_TODAY_FAILED: 'DASHBOARD_REQUEST_TODAY_FAILED',
  CLEAR_TODAY_STATE: 'DASHBOARD_CLEAR_TODAY_STATE',
  FETCH_TODAY: 'DASHBOARD_FETCH_TODAY',
  FETCH_TODAY_SUCCESS: 'DASHBOARD_FETCH_TODAY_SUCCESS',
  FETCH_TODAY_FAILED: 'DASHBOARD_FETCH_TODAY_FAILED',
  GET_TODAY_NO_RECORD: 'GET_TODAY_NO_RECORD',
  GET_TODAY_NO_RECORD_SUCCESS: 'GET_TODAY_NO_RECORD_SUCCESS',
  GET_TODAY_NO_RECORD_FAILURE: 'GET_TODAY_NO_RECORD_FAILURE',
  REQUEST_YESTERDAY: 'DASHBOARD_REQUEST_YESTERDAY',
  REQUEST_YESTERDAY_FAILED: 'DASHBOARD_REQUEST_YESTERDAY_FAILED',
  CLEAR_YESTERDAY_STATE: 'DASHBOARD_CLEAR_YESTERDAY_STATE',
  FETCH_YESTERDAY: 'DASHBOARD_FETCH_YESTERDAY',
  FETCH_YESTERDAY_SUCCESS: 'DASHBOARD_FETCH_YESTERDAY_SUCCESS',
  FETCH_YESTERDAY_FAILED: 'DASHBOARD_FETCH_YESTERDAY_FAILED',
  GET_YESTERDAY_NO_RECORD: 'GET_YESTERDAY_NO_RECORD',
  GET_YESTERDAY_NO_RECORD_SUCCESS: 'GET_YESTERDAY_NO_RECORD_SUCCESS',
  GET_YESTERDAY_NO_RECORD_FAILURE: 'GET_YESTERDAY_NO_RECORD_FAILURE',
  REQUEST_LAST_WEEK: 'DASHBOARD_REQUEST_LAST_WEEK',
  REQUEST_LAST_WEEK_FAILED: 'DASHBOARD_REQUEST_LAST_WEEK_FAILED',
  CLEAR_LAST_WEEK_STATE: 'DASHBOARD_CLEAR_LAST_WEEK_STATE',
  FETCH_LAST_WEEK: 'DASHBOARD_FETCH_LAST_WEEK',
  FETCH_LAST_WEEK_SUCCESS: 'DASHBOARD_FETCH_LAST_WEEK_SUCCESS',
  FETCH_LAST_WEEK_FAILED: 'DASHBOARD_FETCH_LAST_WEEK_FAILED',
  GET_LAST_WEEK_NO_RECORD: 'GET_LAST_WEEK_NO_RECORD',
  GET_LAST_WEEK_NO_RECORD_SUCCESS: 'GET_LAST_WEEK_NO_RECORD_SUCCESS',
  GET_LAST_WEEK_NO_RECORD_FAILURE: 'GET_LAST_WEEK_NO_RECORD_FAILURE',
  REQUEST_LAST_MONTH: 'DASHBOARD_REQUEST_LAST_MONTH',
  REQUEST_LAST_MONTH_FAILED: 'DASHBOARD_REQUEST_LAST_MONTH_FAILED',
  CLEAR_LAST_MONTH_STATE: 'DASHBOARD_CLEAR_LAST_MONTH_STATE',
  FETCH_LAST_MONTH: 'DASHBOARD_FETCH_LAST_MONTH',
  FETCH_LAST_MONTH_SUCCESS: 'DASHBOARD_FETCH_LAST_MONTH_SUCCESS',
  FETCH_LAST_MONTH_FAILED: 'DASHBOARD_FETCH_LAST_MONTH_FAILED',
  GET_LAST_MONTH_NO_RECORD: 'GET_LAST_MONTH_NO_RECORD',
  GET_LAST_MONTH_NO_RECORD_SUCCESS: 'GET_LAST_MONTH_NO_RECORD_SUCCESS',
  GET_LAST_MONTH_NO_RECORD_FAILURE: 'GET_LAST_MONTH_NO_RECORD_FAILURE',
};

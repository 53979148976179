import {Dialog, DialogActions, DialogContent, withStyles} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {MButton, MInput, ModalTitle} from '../../../../components/form';
import styles from './styles';
import {Send} from '@material-ui/icons';
import {useSelector} from 'react-redux';

const initialState = {
  reason: null,
  error: null,
};

const RequestCancelApprovalPopup = ({
  classes,
  open,
  toggle,
  handleSubmit,
}) => {
  const {isLoadingCancelApproval} = useSelector((state) => state?.eventManagement);

  const [state, setState] = useState(initialState);

  const handleChange = (event) => {
    const {name, value} = event?.target || {};

    setState((prevState) => ({
      ...prevState,
      [name]: value,
      error: value ? null : 'Reason is required',
    }));
  };

  const onSubmit = () => {
    if (!state?.reason) {
      setState((prevState) => ({...prevState, error: 'Reason is required'}));
      return;
    }

    handleSubmit(state?.reason);
  };

  useEffect(() => {
    if (!open) setState(initialState);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={toggle}
      maxWidth="sm"
      fullWidth={true}
      scroll="paper"
    >
      <ModalTitle
        className={classes.modalTitle}
        title="Cancel Request Approval"
        onClick={toggle}
      />
      <DialogContent className={classes.dialogContent}>
        <MInput
          classNameFC={classes.formControl}
          multiline
          rows={2}
          rowsMax={3}
          name="reason"
          label="Reason"
          placeholder="Fill in the reason"
          onChange={handleChange}
          value={state?.reason}
          error={state?.error}
          readOnly={isLoadingCancelApproval}
        />
      </DialogContent>

      <DialogActions style={{gap: '16px', margin: '8px'}}>
        <MButton
          label="Cancel"
          onClick={toggle}
          loading={isLoadingCancelApproval}
          disabled={isLoadingCancelApproval}
          />

        <MButton
          label="Request"
          icon={<Send />}
          loading={isLoadingCancelApproval}
          disabled={isLoadingCancelApproval}
          onClick={onSubmit}
          color="primary"
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(RequestCancelApprovalPopup);

import {ENDPOINTS, apiService} from '../config';

export const getAll = (params) => {
  return apiService.get(
      ENDPOINTS.DASHBOARD.ALL,
      {params},
  );
};

export const getToday = (params) => {
  return apiService.get(
      ENDPOINTS.DASHBOARD.TODAY,
      {params},
  );
};

export const getYesterday = (params) => {
  return apiService.get(
      ENDPOINTS.DASHBOARD.YESTERDAY,
      {params},
  );
};

export const getLastWeek = (params) => {
  return apiService.get(
      ENDPOINTS.DASHBOARD.LAST_WEEK,
      {params},
  );
};

export const getLastMonth = (params) => {
  return apiService.get(
      ENDPOINTS.DASHBOARD.LAST_MONTH,
      {params},
  );
};

import React, {useMemo, useRef} from 'react';
import {withStyles, Paper, Grid} from '@material-ui/core';
import {MInput, MButton} from '../../../../components/form';
import {FileCopy, Print, Link} from '@material-ui/icons';
import styles from './styles';
import ReactToPrint from 'react-to-print';
import {toast} from '../../../../helpers';
import QRPrintPage from '../QREventPage/printQREventPage';
import {useSelector} from 'react-redux';

function QRGenerator({classes}) {
  const componentRef = useRef();
  const {eventDetail, isLoading, eventQR} = useSelector((state) => state?.eventManagement || {});

  const qrURL = useMemo(() => {
    return window.location.origin + '/event/qrcode/' + eventDetail?.id;
  }, [eventDetail]);

  const handleCopy = () => {
    navigator.clipboard.writeText(qrURL);
    toast('success', 'QR Link has been copied to your clipboard');
  };

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paper}>
        <div>
          <h5 className={classes.qrTitle}>QR Link</h5>
          <Grid container>
            <Grid item xs={12} style={{paddingRight: 20}}>
              <MInput
                classNameFC={classes.formControlQR}
                name="qrLink"
                label="Link"
                placeholder={isLoading ? 'Loading...' : 'Link to your QR Code page'}
                value={isLoading ? '' : qrURL}
                readOnly
              />
            </Grid>

            <Grid item xs={12} className={classes.btnGenerateContainer}>
              <MButton
                className={classes.defaultBtnQR}
                label="Copy Link"
                color="primary"
                size="small"
                icon={<FileCopy />}
                onClick={handleCopy}
                loading={isLoading}
                />

              <MButton
                className={classes.defaultBtnQR}
                label="Open Link"
                color="primary"
                size="small"
                icon={<Link />}
                type="link"
                onClick={() => window.open(qrURL, '_blank')}
                loading={isLoading}
              />
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid container spacing={2}>
            <Grid item>
              <ReactToPrint
                trigger={() => {
                  return (
                    <MButton
                      className={classes.btnPrint}
                      label="Print"
                      color="primary"
                      size="small"
                      icon={<Print />}
                      loading={isLoading}
                    />
                  );
                }}
                content={() => componentRef.current}
              />
            </Grid>

            <div style={{display: 'none'}}>
              <QRPrintPage
                ref={componentRef}
                qrURL={qrURL}
                data={{
                  event: {
                    name: eventQR?.event_title,
                    address: eventQR?.event_address,
                    startDate: eventQR?.event_date_start,
                    endDate: eventQR?.event_date_end,
                  },
                  organization: {
                    name: eventQR?.organization_name,
                    address: eventQR?.organization_address,
                    logo: eventQR?.organization_logo,
                  },
                  qr: eventQR?.file,
                }}
              />
            </div>
          </Grid>
        </div>
      </Paper>
    </div>
  );
}

export default (withStyles(styles)(QRGenerator));

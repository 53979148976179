/* eslint-disable no-useless-catch */
/* eslint-disable max-len */

import axios from 'axios';
import moment from 'moment';
import {ENDPOINTS, persistor, store} from './index';
import {parseJwt, showPopup} from '../helpers';
import {clearRefreshToken, updateRefreshToken} from '../redux/refreshToken/refreshToken.actions';
import {clearState, logout} from '../redux/login/login.actions';

const {dispatch, getState} = store;

let isUpdatingToken = false;

const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + process.env.REACT_APP_API_VERSION,
  timeout: process.env.REACT_APP_API_TIMEOUT,
});

export const apiServiceV2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + 'v2',
  timeout: process.env.REACT_APP_API_TIMEOUT,
});

const forceLogout = () => {
  const role = getState()?.auth?.data?.profile?.role?.toLowerCase();
  persistor.pause();
  persistor.flush();
  persistor.purge().then(() => {
    clearState();
    logout();
    clearRefreshToken();
    localStorage.removeItem('refreshToken');
  }).then(() => {
    let url = '/login';

    if (role === 'super admin') {
      url = '/sysadm-login';
    }

    window.location.href = url;
  });
};

const validateAccessToken = (config, accessToken) => {
  const isRefreshTokenURL = config?.url === ENDPOINTS.AUTH.GENERATE_TOKEN;

  if (accessToken && !isRefreshTokenURL) {
    const parsed = parseJwt(accessToken);
    const timeDiff = moment(parsed.exp * 1000).diff(moment(), 'minutes', true);
    const isAMinuteBeforeExpired = Math.ceil(timeDiff) === 1;
    const isExpired = timeDiff < 0;

    if (isAMinuteBeforeExpired) {
      if (!isUpdatingToken) {
        dispatch(updateRefreshToken({
          refreshToken: localStorage.getItem('refreshToken'),
        }));

        isUpdatingToken = true;
      }
    } else if (isExpired) {
      forceLogout();
    }
  }
};

// set Global Request Header
apiService.interceptors.request.use((config) => {
  const refreshToken = getState().refreshToken.data.authorization;
  const accesToken = getState().auth.data.authorization;

  validateAccessToken(config, accesToken);

  const token = refreshToken ?? accesToken;
  if (token) config.headers.Authorization = token;
  config.headers.Platform = 'web';
  return config;
});

apiServiceV2.interceptors.request.use((config) => {
  const refreshToken = getState().refreshToken.data.authorization;
  const accesToken = getState().auth.data.authorization;

  validateAccessToken(config, accesToken);

  const token = refreshToken ?? accesToken;
  if (token) config.headers.Authorization = token;
  config.headers.Platform = 'web';
  return config;
});

// set Global 401 / 500 Response handler
apiService.interceptors.response.use(
    (response) => {
      if (response?.config?.url === ENDPOINTS.AUTH.GENERATE_TOKEN) isUpdatingToken = false;
      return response;
    },
    (error) => {
      const {response} = error;
      let errMsg = 'Something is wrong, please try again later';

      if (error.response) {
        const errCode = error.response.status;
        console.log('interceptors-error-response : ', error.response);

        if (!response.data.data && errCode !== 403) {
          // * handle default error from server / axios (eg: timeout, 404/500 errors)
          if (process.env.REACT_APP_MODE === 'DEV') {
            errMsg = error.response.data.message ? error.response.data.data.message : error.response.statusText;
          }
          showPopup('error', errMsg);
        }
      } else {
        // * handle other global error (connection, api, etc)
        console.log('interceptors-xhr-error : ', error.toJSON());
        errMsg = (error.code === 'ECONNABORTED') ? 'Warning! There was a problem with your network connection' : errMsg;
        error = {response: {data: {meta: {code: 400}, data: {message: null}}}}; // imitate backend standard error response
        showPopup('error', errMsg);
      }
      return Promise.reject(error); // return all response as promise error
    },
);

apiServiceV2.interceptors.response.use(
    (response) => {
      if (response?.config?.url === ENDPOINTS.AUTH.GENERATE_TOKEN) isUpdatingToken = false;
      return response;
    },
    (error) => error,
);

export default apiService;

export const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    trigger: '',
    masterHolidayData: [],
    masterHolidayMeta: {page: 1, total: 0},
    message: {
      name: '',
    },
  };

  export const MASTER_HOLIDAY_STATE = {
    REQUEST: 'MASTER_HOLIDAY_REQUEST',
    REQUEST_FAILED: 'MASTER_HOLIDAY_REQUEST_FAILED',
    CLEAR_STATE: 'MASTER_HOLIDAY_CLEAR_STATE',
    FETCH: 'MASTER_HOLIDAY_FETCH',
    FETCH_SUCCCESS: 'MASTER_HOLIDAY_FETCH_SUCCCESS',
    FETCH_FAILED: 'MASTER_HOLIDAY_FETCH_FAILED',
    CREATE: 'MASTER_HOLIDAY_CREATE',
    CREATE_FAILED: 'MASTER_HOLIDAY_CREATE_FAILED',
    CREATE_SUCCESS: 'MASTER_HOLIDAY_CREATE_SUCCESS',
    UPDATE: 'LEAVE_CONFIG_UPDATE',
    UPDATE_SUCCESS: 'LEAVE_CONFIG_UPDATE_SUCCESS',
    UPDATE_FAILED: 'LEAVE_CONFIG_UPDATE_FAILED',
    DROP: 'LEAVE_CONFIG_DROP',
    DROP_SUCCESS: 'LEAVE_CONFIG_DROP_SUCCESS',
    DROP_FAILED: 'LEAVE_CONFIG_DROP_FAILED',
  };


/* eslint-disable max-len */
import {initialState, UPGRADE_STATE as STATE} from './upgradeAccount.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
        message: null,
        trigger: action.requestType,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: action.payload,
        trigger: action.requestType,
      };
      case STATE.CHECK_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          isSuccess: true,
          trigger: action.requestType,
          price: action.payload.price,
          plan: action.payload.plan,
          lastPlan: action.payload.last_plan,
          creatable: action.payload.add_employee,
          premium: action.payload.premium,
          trial: action.payload.free_trial,
          payment: action.payload.payment,
          expiredMessage: action.payload.premium && action.payload.message ? action.payload.message : null,
          creatableMessage: action.payload.message ? action.payload.message : null,
          paymentMethod: action.payload.payment_method,
          expiredDate: action.payload.expired_date,
          dueDate: action.payload.due_date,
          data: action.payload,
      };
    case STATE.BANK_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.CLEAR_STATE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: null,
      };
    default:
      return state;
  }
};

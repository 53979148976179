/* eslint-disable max-len */
import {FRAUD} from '../../services';
import {FRAUD_STATE as STATE} from './fraud.types';
import {transformObject, isArray} from '../../helpers';

export const getFraudDetection = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    FRAUD.getFraudDetection(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCESS, response.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.FETCH_FAILED, message));
            },
        );
  };
};

export const putChangeFraudStatus = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CHANGE_STATUS_FRAUD, true));
    FRAUD.putChangeStatusFraud(params)
        .then(
            (response)=> {
              dispatch(requestSuccess(STATE.CHANGE_STATUS_FRAUD_SUCCESS, response.data, params.status));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CHANGE_STATUS_FRAUD_FAILURE, message));
            },
        );
  };
};

export const getFraudDetail = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_DETAIL, true));
    FRAUD.getFraudDetail(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_DETAIL_SUCCESS, response.data.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_DETAIL_FAILED, response.data.message));
            },
        );
  };
};

export const exportExcel = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT, true));
    FRAUD.exportExcel(params)
        .then(
            (response) => {
              const file = response.data.data.file;
              dispatch(requestSuccess(STATE.EXPORT_SUCCESS, response.data.data));
              window.location.href = file; // alternative : window.open(file);
            },
            (error) => {
              const message = error.response.data.data.message;
              dispatch(exportFailed(STATE.EXPORT_FAILED, message));
            },
        );
  };
};

const request = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response, status = null) => ({
  type: requestType,
  payload: response,
  status: status,
});

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});

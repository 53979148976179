/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import buttonTheme from '../../components/theme/theme';
import {
  IconButton,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  CardMedia,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Delete,
  Send,
  Visibility,
  VisibilityOff,
  Search,
  Crop,
  Refresh,
  Sync,
  CheckCircleOutline,
  WarningOutlined,
  CancelOutlined,
  HourglassEmpty,
  GetApp,
  Publish,
  Edit,
} from '@material-ui/icons';
import {checkPremium} from '../../redux/upgradeAccount/upgradeAccount.actions';
import {USER_STATE} from '../../redux/userManagement/userManagement.types';
import {ORGANIZATION_STATE} from '../../redux/organization/organization.types';
import {
  isObject,
  toast,
  showPopup,
  convDate,
  checkSuperadmin,
  checkFileUploadSize,
  blobToFile,
  getTimeDiff,
  isArray,
} from '../../helpers';
import {
  MInput,
  MInputCurrency,
  MSelect,
  MButton,
  ModalTitle,
  MDatepicker,
} from '../../components/form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Datatable} from '../../components';
import ImageCropper from '../../components/cropper';
import UpgradeAccountModal from '../upgradeAccount';
import {getJobTitleByOrg} from '../../redux/jobTitle/jobTitle.actions';
import {
  getNewUsers,
  getAllUser,
  getAllManager,
  getAllRole,
  postUser,
  postNewUser,
  dropUser,
  updateUser,
  updateNewUser,
  clearState,
  getOneManager,
  syncAdvantech,
  cancelAdvSync,
  exportExcelUser,
  importExcelUser,
  cancelImportUser,
} from '../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../redux/organization/organization.actions';
import _ from 'lodash';
import {XENDIT_STATE} from '../../redux/xendit/xendit.types';
import {DropzoneArea} from 'material-ui-dropzone';
import {checkStatus as checkStatusUser} from '../../redux/userManagement/userManagement.actions';

const INVOICE_PERIOD = process.env.REACT_APP_API_INVOICE_PERIOD;

class UserManagement extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      type: '',
      formData: {
        id: null,
        name: null,
        email: null,
        nik: null,
        dob: null,
        phone: null,
        address: null,
        password: null,
        user_img: null,
        imageURL: null,
        manager: '',
        join_date: null,
        role_id: '',
        job_title_id: '',
      },
      isEdit: false,
      resetPage: null,
      resetDatatable: null,
      modalFormShow: false,
      showPassword: false,
      showUpgradeModal: false,
      nameFilter: null,
      nikFilter: null,
      searchFilterManager: null,
      errorMessage: null,
      errorImageSize: null,
      showCropResult: false,
      isCropping: false,
      showMethod: null,
      invoiceUrl: null,
      query: '',
      sortBy: null,
      order: 'desc',
      advantech: null,
      modalImportShow: false,
      file: null,
      import_users: false,
    };
    this.resetForm = this.state.formData;
  }

  UNSAFE_componentWillMount() {
    this.fetchUser();
    this.props.getAllRole();
    this.props.checkStatus();
    this.props.checkStatusUser();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message, data, isSuperAdmin, orgStatusData, triggerOrg, isSuccessOrg} = nextProps;

    if (data.length !== 0 && this.state.advantech === null && !isSuperAdmin) {
      const filtered = this.filterSetting(data);
      const advantech = filtered && filtered[0] ? filtered[0] : false;
      // if (checkInSetting && checkInSetting.value) checkInSetting.value = moment(checkInSetting.value, 'hh:mm');
      this.setState({
        advantech: advantech && advantech.value === false ? false : advantech.value === true ? true : null,
      });
    }

    if (isSuccessOrg) {
      if (triggerOrg === ORGANIZATION_STATE.CHECK_STATUS_SUCCESS && orgStatusData && orgStatusData.import_users !== this.state.import_users) {
        this.setState({
          import_users: orgStatusData.import_users,
        }, () => this.fetchUser());
      }
    }

    if (isSuccess) {
      if (trigger === USER_STATE.FETCH_USER_SUCCCESS) {
        this.fetchUser();
      }

      if (trigger === USER_STATE.FETCH_MANAGER_SUCCCESS) {
        this.fetchManager();
      }

      if (trigger === USER_STATE.CREATE_SUCCESS) {
        this.props.clearState();
        this.handleReset();
        this.fetchUser();
        toast('success', message);
      }

      if (trigger === USER_STATE.IMPORT_SUCCESS) {
        this.handleReset();
        this.fetchUser();
        toast('success', message);
      }

      if (trigger === USER_STATE.DROP_SUCCESS) {
        this.fetchUser();
        toast('success', message);
      }

      if (trigger === USER_STATE.UPDATE_SUCCESS) {
        this.props.clearState();
        this.handleReset();
        this.fetchUser();
        toast('success', message);
      }

      if (trigger === USER_STATE.IMPORT_SUCCESS && orgStatusData && !orgStatusData.import_users) {
        showPopup(
          'info',
          'Feel free to continue your task. This process going to take awhile at the background.',
        );
        this.props.checkStatus();
        toast('success', message);
      }

      if (trigger === USER_STATE.CANCEL_IMPORT_SUCCESS && orgStatusData && !orgStatusData.import_users) {
        this.props.checkStatus();
        toast('success', message);
      }

      if (trigger === USER_STATE.SYNC_ADVANTECH_SUCCESS && orgStatusData && !orgStatusData.adv_sync) {
        showPopup(
          'info',
          'Feel free to continue your task. This process going to take awhile at the background.',
        );
        this.props.checkStatus();
        toast('success', message);
      }
      if (trigger === USER_STATE.CANCEL_SYNC_SUCCESS && orgStatusData && orgStatusData.adv_sync) {
        this.props.checkStatus();
        toast('success', message);
      }
    }

    const {isSuccessXendit, triggerXendit, xenditStatus} = nextProps;

    if (xenditStatus && xenditStatus.invoice_url !== this.state.invoiceUrl) {
      this.setState({invoiceUrl: xenditStatus.invoice_url});
    }


    if (isSuccessXendit) {
      if (
        triggerXendit === XENDIT_STATE.CHECK_XENDIT_SUCCESS && this.state.invoiceUrl !== xenditStatus.invoice_url
      ) {
        this.setState({
          invoiceUrl: xenditStatus.invoice_url,
        });
      }
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  componentDidMount() {
    const state = this.props.location.state;
    const {isSuperAdmin} = this.props;

    if (!isSuperAdmin) this.props.getJobTitleByOrg();
    if (state?.editProfileHR === true) {
      this.handleReset();
      this.setState({
        isEdit: true,
        modalFormShow: !this.state.modalFormShow,
        formData: {
          id: state.profile.user_id,
          name: state.profile.full_name,
          nik: state.profile.nik,
          email: state.profile.email,
          address: state.profile.address ? state.profile.address : null,
          role_id: state.profile.role_id,
          job_title_id: state.profile.job_title_id,
          manager: null,
          join_date: state.profile.join_date,
          dob: state.profile.date_of_birth,
          phone: state.profile.phone,
          imageURL: state.profile.avatar,
        },
      });
    }
  }

  fetchUser = () => {
    const {isSuperAdmin} = this.props;
    const {page, limit, query, sortBy, order, type} = this.state;

    if (isSuperAdmin) {
      this.props.getNewUsers({page, limit, query, sort_by: sortBy, order, type: 'guest'});
    } else {
      // this.props.getAllUser({page, limit, query, sort_by: sortBy, order});
      this.props.getNewUsers({page, limit, query, sort_by: sortBy, order});
    }

    if (!isSuperAdmin) this.props.checkPremium();
  };

  fetchManager = () => {
    const {page, limit, all, searchFilterManager} = this.state;
    const queryParams = {
      query: searchFilterManager,
      // all: true
    };
    this.props.getAllManager(queryParams);
  }
  handleReload = (params) => {
    this.setState(params, () => this.fetchUser());
  };

  handleReset = () => {
    this.setState({
      formData: {
        id: null,
        name: null,
        email: null,
        nik: null,
        dob: null,
        phone: null,
        address: null,
        password: null,
        user_img: null,
        imageURL: null,
        manager: null,
        join_date: null,
        role_id: '',
        job_title_id: '',
      },
      searchFilter: null,
      modalFormShow: false,
      isEdit: false,
      errorMessage: null,
      errorImageSize: null,
      showCropResult: false,
      isCropping: false,
      modalImportShow: false,
    });
    // this.toggleResetPage()
    // this.handleReload()
  };

  filterSetting = (data) => {
    if (isArray(data)) {
      const filter = (data, key) => {
        const result = data.filter((value) => value.key === key);
        return isArray(result) ? result[0] : null;
      };

      const advantech = filter(data, 'advantech');

      return [advantech];
    }
  }

  handleResetFilter = () => {
    this.setState({
      page: 1,
      limit: 10,
      query: '',
      sortBy: null,
      order: 'desc',
    }, () => this.fetchUser());
    this.toggleResetPage();
    // this.handleReload()
  };

  handleExport = () => {
    const {page, limit, query, order, sortBy} = this.state;
    this.props.exportExcelUser({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(order && {order}),
      ...(sortBy && {sort_by: sortBy}),
    });
  }

  handleInputChange = (event) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = event.target;
    if (name === 'user_img') {
      const image = event.target.files[0];
      const imageSizeIsAllowed = checkFileUploadSize(image);
      if (imageSizeIsAllowed) {
        this.setState({
          isCropping: true,
          errorImageSize: null,
          errorMessage: {...errorMessage, user_img: null},
          formData: {
            ...formData,
            imageURL: URL.createObjectURL(image),
            user_img: image,
          },
        });
      } else {
        let message;
        if (imageSizeIsAllowed === false) {
          message =
            'Maximum file size is ' +
            process.env.REACT_APP_MAX_UPLOAD_SIZE_MB +
            ' MB';
        }
        if (imageSizeIsAllowed === null) message = 'Please select image';
        this.setState({
          errorImageSize: message,
          imageURL: null,
          user_img: null,
        });
      }
    } else {
      this.setState({
        formData: {...formData, [name]: value},
        errorMessage: {...errorMessage, [name]: null},
      });
    }
  };

  handleDateChange = (params) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = params;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  handleFormToggle = (type) => {
    const {
      creatable,
      creatableMessage,
      isSuperAdmin,
      orgStatusData,
    } = this.props;
    if (creatable === false && type === 'add') {
      if (orgStatusData.premium && orgStatusData.message_2) {
        showPopup('info', 'Info', orgStatusData.message_2);
      } else {
        showPopup(
          'confirm',
          creatableMessage,
          'Yes, Upgrade',
          () => this.toggleModalUpgrade(),
        );
      }
    } else {
      const tempAdminJobTitle = isSuperAdmin ? 1 : null; // superadmin handler
      this.handleReset();
      this.setState({
        modalFormShow: !this.state.modalFormShow,
        formData: {...this.resetForm, job_title_id: tempAdminJobTitle},
      });
    }
  };

  handleModalImport = () => {
    const {orgStatusData} = this.props;
    this.setState({
      modalImportShow: !this.state.modalImportShow,
      import_users: orgStatusData && orgStatusData.import_users,
    });
  };

  handleGetRoleInfo = (roleName) => {
    return this.props.roleData.filter((item) => item.name === roleName);
  };

  handleGetJobInfo = (jobType) => {
    const job = this.props.jobData.filter((item) => item.name === jobType);
    return job.length ? job : null;
  };

  handleGetManagerInfo = async (id) => {

  }


handleEditDialogToggle = async (data) => {
    const {isSuperAdmin} = this.props;
    let job = this.handleGetJobInfo(data[4]);
    const role = this.handleGetRoleInfo(data[9]);
    const manager = await this.props.getOneManager(data[1]);
    if (isSuperAdmin) job = 1; // superadmin handler
    if (!isSuperAdmin) job = job ? job[0].id : null;
    this.handleReset();
    this.setState({
      isEdit: true,
      modalFormShow: !this.state.modalFormShow,
      formData: {
        id: data[1],
        name: data[2],
        nik: data[3],
        email: data[5],
        address: data[6],
        role_id: role.length ? role[0].id : null,
        job_title_id: job,
        manager: manager,
        join_date: data[11],
        dob: data[7] ? convDate(data[7]) : null,
        phone: data[8],
        imageURL: data[12],
      },
    });
  };

  handleConfirmDelete = (data) => {
    showPopup(
        'confirm',
        'Are you sure you want to delete this data ?',
        'Yes',
        () => this.props.dropUser(data[1]),
    );
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearchQuery(value);
  }

  handleTogglePassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {createUser, createNewUser, updateUser, updateNewUser, isSuperAdmin} = this.props;
    const {isEdit} = this.state;
    const {
      id,
      name,
      address,
      nik,
      job_title_id,
      email,
      dob,
      phone,
      join_date,
      role_id,
      manager,
      password,
      cropped_user_img,
    } = this.state.formData;

    const payload = {
      name: name,
      email: email,
      phone: phone,
    };

    const dataForm = new FormData();
    if (name) {
      dataForm.append('name', name);
    }
    if (nik) dataForm.append('nik', nik);
    if (!isSuperAdmin && manager) {
      if (manager.manager_id !=='null' && manager.manager_id !==null) {
        dataForm.append('manager', manager.manager_id);
      } else {
        dataForm.append('manager', null);
      }
    } else {
      dataForm.append('manager', null);
    }
    if (!isSuperAdmin && job_title_id) dataForm.append('job_title_id', job_title_id);
    if (email) dataForm.append('email', email);
    if (address) dataForm.append('address', address);
    if (dob) dataForm.append('dob', convDate(dob));
    if (join_date) dataForm.append('join_date', convDate(join_date));
    if (phone) dataForm.append('phone', phone);
    if (isEdit) {
      if (role_id) dataForm.append('role_id', role_id);
    } else {
      if (!isSuperAdmin) dataForm.append('role_id', isSuperAdmin ? role_id : 3);
    }
    if (password) dataForm.append('password', password);
    if (cropped_user_img) {
      dataForm.append('user_img', blobToFile(cropped_user_img));
    }

    return !isEdit ? ( !isSuperAdmin ? createUser(dataForm) : createNewUser(payload) ) : (!isSuperAdmin ? updateUser(id, dataForm) : updateNewUser(id, payload));
  };

  handleSubmitAdvantech = () => {
    const {createUser, updateUser, isSuperAdmin} = this.props;
    const {isEdit} = this.state;
    const {
      id,
      name,
      nik,
      address,
      job_title_id,
      email,
      dob,
      phone,
      join_date,
      role_id,
      manager,
      password,
      cropped_user_img,
    } = this.state.formData;

    const dataForm = new FormData();
    if (name) dataForm.append('name', name);
    if (nik) dataForm.append('nik', nik);
    if (manager) {
      if (manager.manager_id !=='null' && manager.manager_id !==null) {
        dataForm.append('manager', manager.manager_id);
      } else {
        dataForm.append('manager', null);
      }
    } else {
      dataForm.append('manager', null);
    }
    if (job_title_id) dataForm.append('job_title_id', job_title_id);
    if (email) dataForm.append('email', email);
    if (address) dataForm.append('address', address);
    if (dob) dataForm.append('dob', convDate(dob));
    if (join_date) dataForm.append('join_date', convDate(join_date));
    if (phone) dataForm.append('phone', phone);
    if (isEdit) {
      if (role_id) dataForm.append('role_id', role_id);
    } else {
      dataForm.append('role_id', isSuperAdmin ? role_id : 3);
    }
    if (password) dataForm.append('password', password);
    if (cropped_user_img) {
      dataForm.append('user_img', blobToFile(cropped_user_img));
    }
    return !isEdit ? createUser(dataForm) : updateUser(id, dataForm);
  };

  handleSubmitImport = (e) => {
    e.preventDefault();
    const {importExcelUser} = this.props;
    const {file} = this.state;

    const excel = file.length > 0 && file[0] ? file[0] : null;
    const dataForm = new FormData();

    if (excel) dataForm.append('files', excel);

    importExcelUser(dataForm);
  };

  toggleModalUpgrade = (status, method) => {
    const {isPremium, expiredDate, isPaid, dueDate} = this.props;
    if (!status) status = !this.state.showUpgradeModal;
    if (!method) method = null;
    if ((isPremium && (getTimeDiff(expiredDate)+1) >= INVOICE_PERIOD && method !== 'meeting') || (isPaid && method !== 'meeting')) status = false;
    if ((!method && !isPremium && (getTimeDiff(dueDate)+1) < 0) || (!method && !dueDate)) method = 'plan';
    if (!method && !isPremium && (getTimeDiff(dueDate)+1) >= 0) method = 'xendit';
    if (!method && isPremium && (getTimeDiff(expiredDate)+1) <= INVOICE_PERIOD) method = 'xendit';
    this.setState({showUpgradeModal: status, showMethod: method});
  }

  renderModalUpgrade = () => {
    const {showUpgradeModal, showMethod, invoiceUrl} = this.state;
    return (
      <UpgradeAccountModal
        isOpen={showUpgradeModal}
        showMethod={showMethod}
        handleParentToggle={this.toggleModalUpgrade}
        invoiceUrl={invoiceUrl}
      />
    );
  };

  toggleCropperModal = (status) => {
    this.setState({isCropping: status});
  };

  handleCrop = (result) => {
    this.setState({
      formData: {
        ...this.state.formData,
        cropped_user_img: result.blob,
        imageURL: result.imageURL,
      },
    });
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleDebounceSearch = _.debounce((query) => {
    this.setState({searchFilterManager: query},
      () => {
        this.fetchManager();
      });
  }, 500);

  handleDebounceSearchQuery = _.debounce((query) => {
    this.toggleResetPage();
    this.setState(
        {page: null},
        () => this.fetchUser(),
    );
  }, 500);

  selectHandler = (event, value) => {
    if (value) {
      value.manager_id = value.nik;
      value.manager_name = value.name;
    }
    this.setState({
      formData: {
        ...this.state.formData,
        manager: value ? value : null,
      },
    });
  }

  resetManager = () => {
    this.setState({searchFilterManager: null});
  }

  handleImportFile = (files) => {
    this.setState({file: files}, () => console.log(this.state.file));
  }


  searchCombo = (event = null, value) => {
    this.handleDebounceSearch(event.target.value);
  }

  renderModalForm = () => {
    const {
      classes,
      isLoading,
      isLoadingManager,
      jobData,
      roleData,
      isSuperAdmin,
      roleLoading,
      jobLoading,
      userData,
      managerData,
    } = this.props;
    const {
      name,
      nik,
      address,
      job_title_id,
      email,
      dob,
      join_date,
      phone,
      role_id,
      password,
      imageURL,
      manager,
      user_img,
    } = this.state.formData;
    const {
      isEdit,
      modalFormShow,
      showPassword,
      errorMessage,
      errorImageSize,
      showCropResult,
      isCropping,
    } = this.state;
    const titleDialog = isEdit ? 'Update User' : 'Create User';
    const ManagerData = [];
    userData.map((value) => {
      return ManagerData.push(value.id);
    });
    const manager_id = userData.filter((value) => {
      return value.nik === manager;
    });
    const optionEdit = managerData.filter((value) => {
      return value.name !== name;
    });
    return (
      <Dialog
        open={modalFormShow}
        onClose={() => this.handleFormToggle('close')}
      >
        <ModalTitle
          title={titleDialog}
          onClose={() => this.handleFormToggle('close')}
        />
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />
            <MInput
              classNameFC={classes.formControl}
              name="name"
              label="Full Name"
              placeholder="Full Name"
              defaultValue={name}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.name ?
                  errorMessage.name :
                  undefined
              }
              autoFocus
            />
            {!isSuperAdmin ? (
              <MInput
                classNameFC={classes.formControl}
                name="nik"
                label="NIK / Employee ID"
                placeholder="NIK"
                defaultValue={nik}
                onBlur={this.handleInputChange}
                error={
                  errorMessage && errorMessage.nik ? errorMessage.nik : undefined
                }
              />
            ) : null}
            {!isSuperAdmin ? (
              <MSelect
                classNameFC={classes.formControl}
                name="job_title_id"
                value={job_title_id}
                label="Job Title"
                onChange={this.handleInputChange}
                error={
                  errorMessage && errorMessage.job_title_id ?
                    errorMessage.job_title_id :
                    undefined
                }
                keyPair={['id', 'name']}
                options={jobData ? jobData : []}
                loading={jobLoading}
              />
            ) : (
              ''
            )}
            {!isSuperAdmin ? (
              <Autocomplete
                className={classes.formControl}
                id="free-solo-demo"
                freeSolo
                options={isEdit ? optionEdit : managerData}
                value={manager ? {nik: manager.manager_id, name: manager.manager_name} : null}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => this.selectHandler(event, value)}
                style={{position: 'relative'}}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      label="Manager"
                      variant="outlined"
                      fullWidth
                      onChange={(event, value) => this.searchCombo(event, value)}
                      // onClick={() => this.searchCombo()}
                    />
                    {isLoadingManager ? <CircularProgress color="inherit" size={24} style={{position: 'absolute', right: '35px', top: '15px'}}/> : null}
                  </>
                )}
              />
              ) : ''
            }
            <MInput
              classNameFC={classes.formControl}
              name="email"
              label="Email Address"
              placeholder="Email"
              type="email"
              defaultValue={email}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.email ?
                  errorMessage.email.split(':')[0] :
                  undefined
              }
              autoComplete="new-email"
            />
            {!isSuperAdmin ? (
              <MInput
                classNameFC={classes.formControl}
                name="address"
                label="Address"
                placeholder="Address"
                type="text"
                defaultValue={address}
                onBlur={this.handleInputChange}
                error={
                  errorMessage && errorMessage.address ?
                    errorMessage.address.split(':')[0] :
                    undefined
                }
                autoComplete="new-address"
              />
            ) : null}
            {!isSuperAdmin ? (
              <MDatepicker
                className={classes.formControl}
                name="dob"
                label="Date Of Birth"
                value={dob}
                onDateChange={this.handleDateChange}
                error={
                  errorMessage && errorMessage.dob ? errorMessage.dob : undefined
                }
                placeholder="dd-mm-yyyy"
                maxDate={new Date()}
                inputVariant="outlined"
                disableFuture
                openTo="year"
                views={['year', 'month', 'date']}
                fullWidth
              />
            ) : null}
            <MInput
              classNameFC={classes.formControl}
              name="phone"
              label="Phone Number"
              placeholder="Fill in user phone number"
              type="number"
              defaultValue={phone}
              onBlur={this.handleInputChange}
              error={

                errorMessage && errorMessage.phone ?
                  errorMessage.phone.split(':')[0] :
                  null
              }
              autoComplete="new-phone"
            />
            {!isSuperAdmin ? (
              <MInput
                classNameFC={classes.formControl}
                name="password"
                label="Password"
                placeholder="***********"
                autoComplete="new-password"
                type={showPassword ? 'none' : 'password'}
                defaultValue={!isEdit ? password : undefined}
                onBlur={this.handleInputChange}
                error={
                  errorMessage && errorMessage.password ?
                    errorMessage.password :
                    undefined
                }
                InputProps={{
                  endAdornment: (
                    <IconButton
                      position="end"
                      onClick={this.handleTogglePassword}
                    >
                      {!showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
            ) : null}
            {!isSuperAdmin ? (
              <MDatepicker
                className={classes.formControl}
                name="join_date"
                label="Register Date"
                value={join_date}
                onDateChange={this.handleDateChange}
                error={
                  errorMessage && errorMessage.join_date ? errorMessage.join_date : undefined
                }
                placeholder="dd-mm-yyyy"
                maxDate={new Date()}
                inputVariant="outlined"
                disableFuture
                openTo="year"
                views={['year', 'month', 'date']}
                fullWidth
              />
            ) : null}

            {/* {isSuperAdmin ? (
            <MSelect
              classNameFC={classes.formControl}
              name="role_id"
              label="Role"
              value={role_id}
              onChange={this.handleInputChange}
              error={
                errorMessage && errorMessage.role_id ?
                  errorMessage.role_id :
                  undefined
              }
              keyPair={['id', 'name']}
              options={roleData ? roleData : []}
              loading={roleLoading}
            />) : null } */}
            {!isSuperAdmin ? (
              <>
              <MInput
                classNameFC={classes.formControl}
                name="user_img"
                label="Photo"
                placeholder="Photo"
                type="file"
                onChange={this.handleInputChange}
                inputProps={{accept: 'image/*'}}
                error={
                  errorImageSize ?
                    errorImageSize :
                    errorMessage && errorMessage.user_img ?
                    errorMessage.user_img :
                    undefined
                }
              />
              <span><i>Must be .jpg/.jpeg with recommended resolution 200 x 200 px</i></span>
              {!isCropping && imageURL && (
                <div>
                  <CardMedia
                    className={classes.photo}
                    title="Photo"
                    image={imageURL}
                  />
                  {user_img && (
                    <MButton
                      className={`${classes.defaultBtn} ${classes.btnCrop}`}
                      label="Retry"
                      icon={<Crop />}
                      onClick={() => this.toggleCropperModal(true)}
                    />
                  )}
                </div>
              )}
              <ImageCropper
                showModal={isCropping}
                src={user_img}
                showResult={showCropResult}
                onClose={() => this.toggleCropperModal(false)}
                onComplete={this.handleCrop}
              />
              </>
            ) : null}
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.handleFormToggle('close')}
            />
            <MButton
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              loading={isLoading ? true : false}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  renderModalImport = () => {
    const {classes, importLoading} = this.props;
    const { } = this.state.formData;
    const {modalImportShow, file} = this.state;

    const template = window.location.origin+'/download/users-import-template.xlsx';

    return (
      <Dialog
        open={modalImportShow}
        onClose={() => this.handleModalImport('close')}
        maxWidth='md'
      >
        <ModalTitle
          title={'Import User'}
          onClose={() => this.handleModalImport('close')}
        />
        <form onSubmit={this.handleSubmitImport} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <DropzoneArea
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{container: {spacing: 1, direction: 'row'}}}
              previewChipProps={{classes: {root: classes.previewChip}}}
              previewText="Selected files"
              dropzoneText='Drag and drop an Excel (.csv/.xls/.xlsx) file here or click'
              filesLimit={1}
              classes={{root: `${file && file.length > 0 ? classes.hidden : classes.dropZoneArea}`, text: classes.dropZoneText, icon: classes.dropZoneIcon}}
              acceptedFiles={['.csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
              onChange={(files) => this.handleImportFile(files)}
            />
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <Grid container justify='space-between'>
              <Grid item>
                <a href={template} download target="_blank" rel="noopener noreferrer">
                  <Typography color='primary' style={{fontWeight: 'bold'}}>Download Excel Template</Typography>
                </a>
              </Grid>
              <Grid item spacing={2}>
                <MButton
                  label="Cancel"
                  style={{marginRight: 15}}
                  onClick={() => this.handleModalImport('close')}
                />
                <MButton
                  className={classes.defaultBtn}
                  label={'Import'}
                  type="submit"
                  icon={<Send />}
                  loading={importLoading ? true : false}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  handleSyncAdvantech = () => {
    const {orgStatusData} = this.props;
    if (orgStatusData && orgStatusData.adv_sync) {
      showPopup(
        'confirm',
        'Are you sure you want to stop the sync process?',
        'Yes',
        () => this.props.cancelAdvSync(),
      );
    } else if (orgStatusData && !orgStatusData.adv_sync) {
      showPopup(
        'confirm',
        'Are you sure you want to sync users data to advantech?, it will take a while',
        'Yes',
        () => this.props.syncAdvantech(),
      );
    }
  }

  handleSyncAdvantechSingle = async (data) => {
    const {isSuperAdmin} = this.props;
    let job = this.handleGetJobInfo(data[4]);
    const role = this.handleGetRoleInfo(data[8]);
    const manager = await this.props.getOneManager(data[1]);
    if (isSuperAdmin) job = 1; // superadmin handler
    if (!isSuperAdmin) job = job ? job[0].id : null;
    this.handleReset();
    this.setState({
      isEdit: true,
      formData: {
        id: data[1],
        name: data[2],
        nik: data[3],
        role_id: role[0].id,
        job_title_id: job,
        manager: manager,
        join_date: data[10],
        email: data[5],
        dob: data[6] ? convDate(data[6]) : null,
        phone: data[7],
        imageURL: data[11],
      },
    }, () => this.handleSubmitAdvantech());
  }

  renderFilter = () => {
    const {classes, attendanceTypes, exportLoading, isSuperAdmin, orgStatusData, userData} = this.props;
    const {query} = this.state;

    return (
      <Paper variant='outlined' className={classes.paper}>
        <MInput
          autoFocus
          className={`${classes.filter} ${classes.searchInput}`}
          fullWidth={false}
          variant="standard"
          name="query"
          label="Search"
          placeholder="Search keyword"
          value={query}
          onChange={this.handleSearch}
          InputProps={{endAdornment: <IconButton className={classes.searchIcon} position="end"> <Search/></IconButton>}}
        />
        <div className={classes.btnFilterContainer}>
          <MButton
            className={`${classes.btnExport} ${classes.filterBtnEnd}`}
            label="Reset"
            icon={<Refresh/>}
            onClick={this.handleResetFilter}
          />
        </div>
        {
          !isSuperAdmin && (
            <>
              <div className={classes.btnFilterContainer}>
                <MButton
                  className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                  label="Export"
                  icon={<GetApp/>}
                  onClick={this.handleExport}
                  loading={exportLoading}
                  disabled={userData ? true : false}
                />
              </div>
              <div className={classes.btnFilterContainer}>
                <MButton
                  className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                  label={orgStatusData && orgStatusData.import_users ? 'Cancel Import' : 'Import'}
                  icon={orgStatusData && orgStatusData.import_users ? <CancelOutlined/> : <Publish/>}
                  onClick={() => {
orgStatusData && orgStatusData.import_users ? this.props.cancelImportUser() : this.handleModalImport();
}}
                  loading={exportLoading}
                />
              </div>
            </>
          )
        }
      </Paper>
    );
  }

  renderActions = (params) => {
    const {orgStatusData, isSuperAdmin} = this.props;
    const rowDataDisable = 12;
    const disableCondition = orgStatusData && orgStatusData.adv_sync;
    return (
      <Grid container wrap='nowrap' direction='row'>
        <Grid item>
          <Tooltip title="Edit">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color="primary"
                size='small'
                onClick={() => this.handleEditDialogToggle(params)}
                startIcon={<Edit />}
                style={{
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
                disabled={
                  (!rowDataDisable ? false : !disableCondition === null ? !params[rowDataDisable] : disableCondition && !params[rowDataDisable]) ||
                  (orgStatusData && orgStatusData.import_users)
                }
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
        {params[9] !== 'HR Admin' ? (
          <Grid item>
            <Tooltip title="Delete">
              <div style={{display: 'inline', marginRight: '5px'}}>
                <Button
                  variant="contained"
                  color="secondary"
                  size='small'
                  onClick={() => this.handleConfirmDelete(params)}
                  startIcon={<Delete />}
                  style={{
                    'minWidth': '10px',
                    'paddingRight': '0px',
                  }}
                  disabled={
                    (!rowDataDisable ? false : !disableCondition === null ? !params[rowDataDisable] : disableCondition && !params[rowDataDisable]) ||
                    (orgStatusData && orgStatusData.import_users)
                  }
                >
                </Button>
              </div>
            </Tooltip>
          </Grid>
      ) : (null)}
      </Grid>
    );
  };

  render() {
    const {classes, isLoading, userData, userMeta, orgStatusData, isLoadingOrg, isSuperAdmin, menusData} = this.props;
    const displayJobTitle = this.props.isSuperAdmin ? false : true;
    const {resetDatatable, resetPage, advantech} = this.state;
    const columns = [
      {name: 'no', label: 'No', display: true},
      {name: 'id', label: 'id', display: false},
      {name: 'name', label: 'Name', display: true},
      {name: 'nik', label: 'NIK', display: isSuperAdmin ? false : true},
      {name: 'job_title', label: 'Job Title', display: isSuperAdmin ? false : true},
      {name: 'email', label: 'Email', display: true},
      {name: 'address', label: 'Address', display: false},
      {
        name: 'dob',
        label: 'Date of Birth',
        display: isSuperAdmin ? false : true,
        customBodyRender: (value) => {
          return (
            <div style={{whiteSpace: 'nowrap'}}>
              {value ? convDate(value, 'DD-MM-YYYY') : '-'}
            </div>
          );
        },
      },
      {name: 'phone', label: 'Phone', display: false},
      {name: 'role', label: 'Role', display: isSuperAdmin ? false : true},
      {
        name: 'manager_name',
        label: 'Manager',
        display: isSuperAdmin ? false : true,
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
      {
        name: isSuperAdmin ? 'register_date' : 'join_date',
        label: 'Register Date',
        display: true,
        customBodyRender: (value) => {
          return (
            <div style={{whiteSpace: 'nowrap'}}>
              {value ? convDate(value, 'DD-MM-YYYY') : '-'}
            </div>
          );
        },
      },
      {name: 'employees.image', label: 'Photo', display: false},
      {
        name: 'advantech',
        label: 'Advantech Sync',
        // display: advantech && menusData.advantech ? true : false,
        display: false,
        customBodyRender: (value, userData) => {
          return (
            <Grid container justify="center" wrap="nowrap">
              <Tooltip
                title={
                  value ?
                    'synchronized' :
                    orgStatusData && orgStatusData.adv_sync ?
                    'Sync in progress' :
                    'click to sync user'
                }
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    orgStatusData && !orgStatusData.adv_sync && !value ?
                      this.handleSyncAdvantechSingle(userData.rowData) :
                      undefined
                  }
                  style={{
                    maxWidth: '35px',
                    maxHeight: '35px',
                    minWidth: '35px',
                    minHeight: '35px',
                  }}
                  disabled={orgStatusData && orgStatusData.import_users}
                >
                  {value ? (
                    <CheckCircleOutline className={classes.syncTrue} />
                  ) : orgStatusData && orgStatusData.adv_sync ? (
                    <HourglassEmpty className={classes.syncFalse} />
                  ) : (
                    <Sync className={classes.syncFalse} />
                  )}
                </Button>
              </Tooltip>
              {!userData.rowData[13] ? undefined : (
                <Tooltip
                  title={userData && userData.rowData && userData.rowData[13]}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      maxWidth: '35px',
                      maxHeight: '35px',
                      minWidth: '35px',
                      minHeight: '35px',
                      marginLeft: '5px',
                    }}
                  >
                    {userData && userData.rowData && userData.rowData[13] && (
                      <WarningOutlined className={classes.syncWarn} />
                    )}
                  </Button>
                </Tooltip>
              )}
            </Grid>
          );
        },
      },
      {name: 'logs.log', label: 'Log', display: false},
    ];
    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Datatable
              title={'Employee List'}
              loading={isLoading || isLoadingOrg}
              dataSource={userData}
              total={userMeta ? userMeta.total : 0}
              page={userMeta ? userMeta.page : 1}
              columns={columns}
              handleReload={(params) => this.handleReload(params)}
              handleCreate={() => this.handleFormToggle('add')}
              toggleResetPage={resetPage}
              manualNumbering={true}
              customActions={(data) => this.renderActions(data)}
              customTools={
                isSuperAdmin || (menusData && menusData.advantech === false) || advantech === false ? undefined :
                (
                <div style={{float: 'right'}}>
                  <Grid container justify='center'>
                    <Tooltip title="Advantech Sync">
                      <IconButton
                        onClick={() => this.handleSyncAdvantech()}
                        disabled={isLoadingOrg || (orgStatusData && orgStatusData.import_users) ? true : false}
                      >
                        {isLoadingOrg ? <CircularProgress color="inherit" size={20}/> : orgStatusData && orgStatusData.adv_sync ? <CancelOutlined /> : <Sync />}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <p style={{fontSize: 12, marginBottom: 0, marginTop: -8}}>
                    {isLoadingOrg ? null : orgStatusData && orgStatusData.adv_sync ? 'Stop Sync' : 'Advantech Sync'}
                  </p>
                </div>
                )
              }
            />
          </Grid>
        </Grid>
        {this.renderModalForm()}
        {this.renderModalImport()}
        {this.renderModalUpgrade()}
      </div>
    );
  }
}

const stateToProps = ({userManagement, jobTitle, upgradeAccount, auth, organization, xendit, generalSetting}) => ({
  token: auth.data.authorization,
  isSuperAdmin: checkSuperadmin(auth),
  isLoading: userManagement.isLoading,
  isLoadingManager: userManagement.isLoadingManager,
  isError: userManagement.isError,
  isSuccess: userManagement.isSuccess,
  message: userManagement.message,
  trigger: userManagement.trigger,
  premium: upgradeAccount.premium,
  creatable: upgradeAccount.creatable,
  creatableMessage: upgradeAccount.creatableMessage,
  userData: userManagement.userData,
  managerData: userManagement.managerData,
  userMeta: userManagement.userMeta,
  roleLoading: userManagement.roleLoading,
  roleData: userManagement.roleData,
  jobLoading: jobTitle.jobLoading,
  jobData: jobTitle.jobDataByOrg,
  orgStatusData: organization.orgStatusData,
  isPremium: upgradeAccount.premium,
  isPaid: upgradeAccount.payment,
  expiredDate: upgradeAccount.expiredDate,
  dueDate: upgradeAccount.dueDate,
  xenditStatus: xendit.xenditStatus,
  isSuccessXendit: xendit.isSuccess,
  triggerXendit: xendit.trigger,
  messageXendit: xendit.message,
  isErrorXendit: xendit.isError,
  isLoadingXendit: xendit.isLoading,
  data: generalSetting.data,
  isLoadingOrg: upgradeAccount.isLoading,
  menusData: auth.data.menus,
  exportLoading: userManagement.exportLoading,
  exportData: userManagement.exportData.file,
  importLoading: userManagement.importLoading,
  isSuccessOrg: organization.isSuccess,
  triggerOrg: organization.trigger,
});

const dispatchToProps = (dispatch) => ({
  getAllUser: (params) => dispatch(getAllUser(params)),
  getNewUsers: (params) => dispatch(getNewUsers(params)),
  getAllManager: (params) => dispatch(getAllManager(params)),
  getAllRole: () => dispatch(getAllRole()),
  createUser: (data) => dispatch(postUser(data)),
  createNewUser: (data) => dispatch(postNewUser(data)),
  getOneManager: (id) => dispatch(getOneManager(id)),
  updateUser: (id, data) => dispatch(updateUser(id, data)),
  updateNewUser: (id, data) => dispatch(updateNewUser(id, data)),
  dropUser: (id) => dispatch(dropUser(id)),
  checkPremium: () => dispatch(checkPremium()),
  clearState: () => dispatch(clearState()),
  getJobTitleByOrg: (params) => dispatch(getJobTitleByOrg(params)),
  checkStatus: () => dispatch(checkStatus()),
  syncAdvantech: () => dispatch(syncAdvantech()),
  cancelAdvSync: () => dispatch(cancelAdvSync()),
  cancelImportUser: () => dispatch(cancelImportUser()),
  exportExcelUser: (params) => dispatch(exportExcelUser(params)),
  importExcelUser: (data) => dispatch(importExcelUser(data)),
  checkStatusUser: () => dispatch(checkStatusUser()),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(UserManagement));

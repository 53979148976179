import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import {store, persistor} from './config';
import App from './app';

const render = <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <App />
  </PersistGate>
</Provider>;

ReactDOM.render(render, document.getElementById('root'));
serviceWorker.unregister();

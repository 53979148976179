/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, ThemeProvider} from '@material-ui/core/styles';
import styles from './styles';
import {
  Button,
  Grid,
  Avatar,
  Divider,
  Paper,
  CardMedia,
  Typography,
  Link,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from '@material-ui/core';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {
  CheckCircleOutline,
  Search,
  SaveAlt,
  Refresh,
  WarningOutlined,
} from '@material-ui/icons';
import {toast, isObject, convDate} from '../../../helpers';
import {MInput, MKeyBoardDatepicker, MButton, ModalTitle, MSelect} from '../../../components/form';
import {Datatable} from '../../../components';
import _ from 'lodash';
import moment from 'moment';
import {ATTENDANCE_STATE as STATE} from '../../../redux/attendanceHistory/attendance.types';
import {getRegisterAnalyticsDetails} from '../../../redux/register/register.actions';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const yearPickerTheme = createTheme({
  palette: {
    primary: {
      main: '#00AFF0',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F00B38',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: 14,
      },
    },
    MuiFormControl: {
      root: {
        maxWidth: 90,
      },
    },
  },
});

class RegistrationAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      year: moment().format('YYYY'),
      month: moment().format('MM'),
      sortBy: null,
      order: 'desc',
      showDetail: false,
      resetDatatable: null,
      detailData: null,
      resetPage: null,
      errorMessage: null,
      status: 'all',
    };
  }

  UNSAFE_componentWillMount() {
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, exportData, message} = nextProps;

    if (trigger === STATE.EXPORT_SUCCESS && isSuccess && exportData) {
      toast('success', 'File successfully exported & downloaded');
    }

    if (isError && message && isObject(message)) this.setState({errorMessage: message});
    if (isError && message && !isObject(message)) toast('error', message);
  }

  componentDidMount() {
    this.handleCheckQueryParam();
  }

  fetchRegisterAnalytics = () => {
    const {page, limit, startDate, endDate, query, sortBy, order, method, completed, year, month, status} = this.state;
    this.props.getRegisterAnalyticsDetails({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(year && {year}),
      ...(month && {month}),
      // ...(query && {query}),
      // ...(startDate && {start_date: convDate(startDate)}),
      // ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(status && status !== 'all' && {status}),
    });
  }

  handleCheckQueryParam = () => {
    const queryParam = this.props.location.search.substring(1);
    if (queryParam) {
      const searchParam = new URLSearchParams(queryParam);
      const year = searchParam.get('year');
      const month = searchParam.get('month');

      this.setState({
        ...(year && {year}),
        ...(month && {month}),
      },
      () => {
        this.fetchRegisterAnalytics();
      });
    } else {
      this.fetchRegisterAnalytics();
    }
  }

  toggleModal = (type, data) => {
    const {attendanceData} = this.props;
    if (type === 'detail') {
      const numberId = data.rowData[0]; // get detail attendance from datatable response, based on index
      const selected = attendanceData.filter((data) => data.no === numberId);
      const detailData = selected && selected.length ? selected[0] : null;
      this.setState({showDetail: true, detailData});
    } else if (type === 'close') {
      this.setState({showDetail: false, detailData: null});
    }
  };

  toggleResetDatatable = (status) => {
    this.setState(
        {resetDatatable: status},
        () => this.setState({resetDatatable: null}),
    );
  }

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };


  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      sortBy: null,
      order: 'desc',
      detailData: null,
      year: moment().format('YYYY'),
      month: moment().format('MM'),
      status: 'all',
    };
    this.setState(state, () => this.fetchRegisterAnalytics());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  }

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null}, () => this.fetchRegisterAnalytics());
    this.toggleResetPage();
  };

  handleReload = (params) => {
    this.setState(
        params,
        () => this.fetchRegisterAnalytics(),
    );
  }

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  }

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState(
        {page: null},
        () => this.fetchRegisterAnalytics(),
    );
  }, 500);

  handleYearChange = (params) => {
    this.setState({
      year: moment(params).format('YYYY'),
    }, () => this.fetchRegisterAnalytics());
  }

  handleMonthChange = (params) => {
    this.setState({
      month: moment(params).format('MM'),
    }, () => this.fetchRegisterAnalytics());
  }

  renderFilter = () => {
    const {classes, exportLoading, attendanceTypes, message, exportData, isSuccess} = this.props;
    const {query, year, month, status} = this.state;

    const renderMethodOptions = () => {
      const options = [
        {id: 'all', name: 'All'},
        {id: 'waiting', name: 'Waiting'},
        {id: 'approved', name: 'Approved'},
        {id: 'rejected', name: 'Rejected'},
      ];
      return options;
    };

    return (
      <Paper variant='outlined' className={classes.paper}>
        <Grid container spacing={2} alignItems='center' direction='row'>
          <Grid item>
            <ThemeProvider theme={yearPickerTheme}>
              <MSelect
                shrink={true}
                fullWidth={false}
                classNameFC={`${classes.formControl} ${classes.filter}`}
                variant="outlined"
                name="status"
                label="Status"
                keyPair={['id', 'name']}
                options={renderMethodOptions()}
                value={status}
                onChange={this.handleFilterChange}
              />
            </ThemeProvider>
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={yearPickerTheme}>
                <DatePicker
                  className={classes.formControl}
                  format='MMM'
                  inputVariant="outlined"
                  openTo="month"
                  views={['month']}
                  label="Month"
                  value={year + '-' + month}
                  autoOk={true}
                  onChange={(params) => this.handleMonthChange(params)}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={yearPickerTheme}>
                <DatePicker
                  inputVariant="outlined"
                  className={classes.formControl}
                  openTo="year"
                  views={['year']}
                  label="Year"
                  value={year}
                  autoOk={true}
                  onChange={(params) => this.handleYearChange(params)}
                  minDate={'2018'}
                  maxDate={moment().format('YYYY')}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <div className={classes.btnFilterContainer}>
              <MButton
                className={classes.filterBtn}
                label="Reset"
                color="primary"
                icon={<Refresh/>}
                onClick={this.handleReset}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  renderModalDetail = () => {
    const {classes} = this.props;
    const {showDetail, detailData} = this.state;

    const name = detailData && detailData.name ? detailData.name : '-';
    const date = detailData && detailData.date ? convDate(detailData.date, 'D MMMM YYYY') : '-';
    const checkIn = detailData && detailData.check_in ? detailData.check_in : '-';
    const checkInImg = detailData && detailData.check_in_img ? detailData.check_in_img : <Avatar alt='user avatar' />;
    const checkOut = detailData && detailData.check_out ? detailData.check_out : '-';
    const checkOutImg = detailData && detailData.check_out_img ? detailData.check_out_img : <Avatar alt='user avatar' />;
    const checkInAddress = detailData && detailData.check_in_address ? detailData.check_in_address : '-';
    const checkOutAddress = detailData && detailData.check_out_address ? detailData.check_out_address : '-';
    const todo = detailData && detailData.todo ? detailData.todo : '-';
    const day = detailData && detailData.day ? detailData.day : '-';
    const method = detailData && detailData.method ? detailData.method : null;
    const break_in = detailData && detailData.break_in ? detailData.break_in : null;
    const break_out = detailData && detailData.break_out ? detailData.break_out : null;
    const approvedBy =
      detailData && detailData.approved_by ? detailData.approved_by : '-';
    const columns = ['Check In', 'Address', 'Todo', 'Photo'];
    const checkInLocation = detailData && detailData.check_in_location ? detailData.check_in_location : null;
    const checkOutLocation = detailData && detailData.check_out_location ? detailData.check_out_location : null;

    return (
      <Dialog
        open={showDetail}
        onClose={() => this.toggleModal('close')}
        maxWidth='md'
        fullWidth={true}
        scroll='paper'
        PaperProps={{
          style: {borderRadius: 15, paddingTop: 20, paddingBottom: 20},
        }}
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Attendance Detail"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={2}><Typography>Name </Typography></Grid>
            <Grid item><Typography> : </Typography></Grid>
            <Grid item xs zeroMinWidth><Typography>{name}</Typography></Grid>
          </Grid>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={2}><Typography>Day </Typography></Grid>
            <Grid item><Typography> : </Typography></Grid>
            <Grid item xs zeroMinWidth><Typography>{day}</Typography></Grid>
          </Grid>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={2}><Typography>Date </Typography></Grid>
            <Grid item><Typography> : </Typography></Grid>
            <Grid item xs zeroMinWidth><Typography>{convDate(date, 'D MMMM YYYY')} </Typography></Grid>
          </Grid>

          {
            method !== 'business' ?
            (
              <div>
                {method === 'remote' ? (<Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs={2}><Typography>Todo</Typography></Grid>
                  <Grid item><Typography> : </Typography></Grid>
                  <Grid item xs zeroMinWidth><Typography> <pre style={{margin: 0}}>{todo}</pre></Typography></Grid>
                </Grid>) : null}
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs={2}><Typography>Checkin Location</Typography></Grid>
                  <Grid item><Typography> : </Typography></Grid>
                  {checkInLocation ? <Grid item><Typography>{checkInLocation}</Typography></Grid> : null}
                  <Grid item xs zeroMinWidth><Typography>{checkInAddress}</Typography></Grid>
                </Grid>
                <Grid container wrap="nowrap" spacing={2} mb={5}>
                  <Grid item xs={2}><Typography>Checkout Location</Typography></Grid>
                  <Grid item><Typography> : </Typography></Grid>
                  {checkOutLocation ? <Grid item><Typography>{checkOutLocation}</Typography></Grid> : null}
                  <Grid item xs zeroMinWidth><Typography>{checkOutAddress}</Typography></Grid>
                </Grid>
                {method === 'office' ? (
                  <>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item xs={2}><Typography>Check In</Typography></Grid>
                      <Grid item><Typography> : </Typography></Grid>
                      <Grid item xs zeroMinWidth><Typography>{checkIn}</Typography></Grid>
                    </Grid>
                    <Grid container wrap="nowrap" spacing={2} mb={5}>
                      <Grid item xs={2}><Typography>Check Out</Typography></Grid>
                      <Grid item><Typography> : </Typography></Grid>
                      <Grid item xs zeroMinWidth><Typography>{checkOut}</Typography></Grid>
                    </Grid>
                  </>
                ) : null}
                {method !== 'overtime' ? (
                <>
                  {
                    break_in || break_out ? (
                      <>
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid item xs={2}><Typography>Break In</Typography></Grid>
                          <Grid item><Typography> : </Typography></Grid>
                          <Grid item xs zeroMinWidth><Typography>{break_in ? break_in : '-'}</Typography></Grid>
                        </Grid>
                        <Grid container wrap="nowrap" spacing={2} mb={5}>
                          <Grid item xs={2}><Typography>Break Out</Typography></Grid>
                          <Grid item><Typography> : </Typography></Grid>
                          <Grid item xs zeroMinWidth><Typography>{break_out ? break_out : '-'}</Typography></Grid>
                        </Grid>
                      </>
                    ) : null
                  }
                </>
                ): (
                  <Grid container wrap="nowrap" spacing={2} mb={5}>
                    <Grid item xs={2}><Typography>Approved By</Typography></Grid>
                    <Grid item><Typography> : </Typography></Grid>
                    <Grid item xs zeroMinWidth><Typography>{approvedBy}</Typography></Grid>
                  </Grid>
                )}

              </div>
            ) : ''
          }
          <br />
          <Divider />
          {
            method === 'business' ?
            (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {
                        columns.map((name, index) => (
                          <TableCell key={index}>{name}</TableCell>
                        ))
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      detailData.data.map((row) => (
                        <TableRow key={row.check_in_id}>
                          <TableCell component="th" scope="row">{row.check_in}</TableCell>
                          <TableCell component="th" scope="row">{row.check_in_address}</TableCell>
                          <TableCell component="th" scope="row">{row.todo}</TableCell>
                          <TableCell align="right">
                            <Link href={row.check_in_img} target="_blank" rel="noopener noreferrer">
                              <CardMedia
                                className={classes.tableImg}
                                alt="check in picture"
                                image={row.check_in_img}
                                component='image'
                              />
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null }
          { method === 'remote' || method ==='overtime' ?
            (
              <Grid container spacing={3} align="center">
                <Grid item xs={6}>
                  <Typography
                    className={classes.modalItemSpacing}
                  >
                    Check In
                  </Typography>
                  <Link href={checkInImg} target="_blank" rel="noopener noreferrer">
                    <CardMedia
                      className={[classes.modalAvatar, classes.imgSize]}
                      alt="check in picture"
                      image={checkInImg}
                      title='Check In'
                      component='image'
                    />
                  </Link>
                  <br />
                  <Grid container wrap="nowrap" spacing={2} align="center">
                    <Grid item xs={12}>
                      <Typography>Time &nbsp; : &nbsp; {checkIn ? checkIn : '-'} </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={classes.modalItemSpacing}
                  >
                    Check Out
                  </Typography>
                  <Link href={checkOutImg} target="_blank" rel="noopener noreferrer">
                    <CardMedia
                      className={[classes.modalAvatar, classes.imgSize]}
                      alt="check out picture"
                      image={checkOutImg}
                      title='Check Out'
                      component='image'
                    />
                  </Link>
                  <br />
                  <Grid container spacing={2} >
                    <Grid item xs={12}>
                      <Typography>Time &nbsp; : &nbsp; {checkOut ? checkOut : '-'} </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : null
          }
        </DialogContent>
      </Dialog>
    );
  }

  render() {
    const {classes, analyticsDetails, analyticsMeta, isLoading} = this.props;
    const {resetDatatable, resetPage} = this.state;
    const columns = [
      {name: 'no', label: 'No', display: true},
      {name: 'name', label: 'Account Name', display: true},
      {name: 'organization_name', label: 'Organization Name', display: true, customBodyRender: (value) => value ? value : '-'},
      {name: 'organization_addres', label: 'Organization Address', display: true, customBodyRender: (value) => value ? value : '-'},
      {name: 'email', label: 'Email', display: true, customBodyRender: (value) => value ? value : '-'},
      {name: 'phone', label: 'Phone Number', display: true, customBodyRender: (value) => value ? value : '-'},
      {
        name: 'created_at',
        label: 'Register Date',
        display: true,
        customBodyRender: (value) => {
          if (value) {
            return <div style={{whiteSpace: 'nowrap'}}>{moment(value).format('DD-MM-YYYY')}</div>;
          } else {
            return '-';
          }
        },
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          let color;
          switch (value) {
            case 'approved':
              color = 'green';
              break;
            case 'waiting':
              color = 'orange';
              break;
            case 'rejected':
            case 'cancelled':
              color = 'red';
              break;
            default:
              color = 'grey';
              break;
          }
          return <strong style={{color: color, textTransform: 'capitalize'}}>{value}</strong>;
        },
      },
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Datatable
          creatable={false}
          title={'Register Analytics Details'}
          loading={isLoading}
          dataSource={analyticsDetails}
          total={analyticsMeta.total}
          page={analyticsMeta.page}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          // handleDetail={(params) => this.toggleModal('detail', params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
          hideActions={true}
        />
        {this.renderModalDetail()}
      </div>
    );
  }
}

const mapStateToProps = ({register, userManagement}) => ({
  isLoading: register.isLoading,
  isSuccess: register.isSuccess,
  isError: register.isError,
  message: register.message,
  analyticsDetails: register.analyticsDetails,
  analyticsMeta: register.analyticsMeta,
});

const mapDispatchToProps = (dispatch) => ({
  getRegisterAnalyticsDetails: (params) => dispatch(getRegisterAnalyticsDetails(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegistrationAnalytics));

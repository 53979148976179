/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import styles from './styles';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Button,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Switch,
  CardMedia,
} from '@material-ui/core';
import {
  Search,
  Refresh,
  Send,
  Edit,
  Delete,
  Cancel,
} from '@material-ui/icons';
import {toast, convDate, checkSuperadmin, showPopup, isObject, checkFileUploadSize} from '../../../helpers';
import {
  MInput,
  MButton,
  ModalTitle,
  MDatepicker,
  MCheckboxItem,
  MSelect,
  MAutoComplete,
  MInputMask,
} from '../../../components/form';
import {Datatable} from '../../../components';
import {checkStatus} from '../../../redux/organization/organization.actions';
import {ReactComponent as ResendEmail} from '../../../assets/icon/partner/resend.svg';
import _ from 'lodash';
import confirmationIcon from '../../../assets/icon/partner/confirm.svg';
import {
  getWithdrawal,
  getOneWithdrawal,
  rejectWithdrawal,
  updateWithdrawal,
  clearState,
} from '../../../redux/withdraw/withdraw.actions';
import {WITHDRAW_STATE as STATE, WITHDRAW_STATE} from '../../../redux/withdraw/withdraw.types';

const numberSeparator = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
class WithdrawRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      sortBy: null,
      order: 'desc',
      request_date: null,
      resetDatatable: null,
      resetPage: null,

      modalFormShow: false,
      rejectModalShow: false,
      showDetail: false,
      showReject: false,
      errorImageSize: null,

      id: null,
      notes: null,
      errorNotes: null,
      inputValue: null,
      formData: {
        id: null,
        partner_name: null,
        status: null,
        currentStatus: null,
        partner_email: null,
        partner_phone: null,
        bank_name: null,
        bank_account_number: null,
        bank_account_name: null,
        address: null,
        withdrawal_img: null,
        withdrawal_img_url: null,
      },
      errorMessage: null,
      isEdit: false,
    };
  }

  switchTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  });


  UNSAFE_componentWillMount() {
    this.redirectDashboardIfChild();
    this.fetchWithdrawal();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isLoading, message, isError} = nextProps;

    if (isSuccess) {
      if (
        trigger === WITHDRAW_STATE.UPDATE_SUCCESS ||
        trigger === WITHDRAW_STATE.REJECT_SUCCESS
      ) {
        toast('success', message);
        this.fetchWithdrawal();
        this.setState({
          rejectModalShow: false,
          modalFormShow: false,
        });
        this.props.clearState();
      }
    }

    if (isError && message) {
      if (!isObject(message)) toast('error', message);
    }
  }

  componentWillUnmount() {
    // this.props.clearState();
  }

  redirectDashboardIfChild = () => {
    const {isSuperAdmin, auth} = this.props;

    if (!isSuperAdmin) {
      if (auth.data.organization.is_child) {
        this.props.history.push('/dashboard');
      } else {
        // if(!userFeature.tenant_management){
        //   this.props.history.push('/dashboard');
        // }
      }
    }
  };

  fetchWithdrawal = () => {
    const {isSuperAdmin} = this.props;

    if (isSuperAdmin) {
      const {
        page,
        limit,
        query,
        sortBy,
        order,
        request_date,
      } = this.state;
      this.props.getWithdrawal({
        page,
        limit,
        query,
        sortBy,
        order,
        request_date: convDate(request_date),
      });
    }
  }

  handleReload = (params) => {
    this.setState(params, () => this.fetchWithdrawal());
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  };

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: 1}, () => this.fetchWithdrawal());
  }, 1000);

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState({[name]: value, page: null}, () =>
      this.fetchWithdrawal(),
    );
    this.toggleResetPage();
    this.toggleResetDatatable(true);
  };

  handleResetFilter = () => {
    const state = {
      page: 1,
      limit: 10,
      query: null,
      sortBy: null,
      order: 'desc',
      request_date: null,
    };
    this.setState(state, () => this.fetchWithdrawal());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  };

  renderFilter = () => {
    const {classes, message} = this.props;
    const {query, request_date} = this.state;

    return (
      <Paper variant='outlined' className={classes.paper}>
        <Grid container xs={12} spacing={2}>
          <MInput
            autoFocus
            className={`${classes.filter} ${classes.searchInput}`}
            fullWidth={false}
            variant="outlined"
            name="query"
            label="Partner Name"
            placeholder="Search Partner Name"
            value={query}
            onChange={this.handleSearch}
            InputProps={{
              endAdornment: (
                <IconButton className={classes.searchIcon} position="end">
                  {' '}
                  <Search />
                </IconButton>
              ),
            }}
          />
          <MDatepicker
            fullWidth={false}
            className={`${classes.filter} ${classes.formControl} ${classes.searchDate}`}
            inputVariant="outlined"
            name="request_date"
            label="Request Date"
            value={request_date}
            onDateChange={this.handleFilterDateChange}
            placeholder="dd-mm-yyyy"
            error={
              message && message.start_date ? message.start_date : undefined
            }
          />
          <div className={classes.btnFilterContainer}>
            <MButton
              className={classes.defaultBtn}
              label="Reset"
              icon={<Refresh />}
              onClick={this.handleResetFilter}
            />
          </div>
        </Grid>
      </Paper>
    );
  };

  renderActions = (params) => {
    return (
      <Grid container wrap='nowrap' direction='row'>
        {params[7] !== 'rejected' ? (
          <>
            <Grid item>
              <Tooltip title="Edit">
                <div style={{display: 'inline', marginRight: '5px'}}>
                  <Button
                    variant="contained"
                    color="primary"
                    size='small'
                    onClick={() => {
                      this.handleFormToggle(params, 'open');
                    }}
                    startIcon={<Edit />}
                    style={{
                      'minWidth': '10px',
                      'paddingRight': '0px',
                    }}
                  >
                  </Button>
                </div>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Abort">
                <div style={{display: 'inline', marginRight: '5px'}}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size='small'
                    onClick={() => this.toggleRejectWithdrawal(params)}
                    startIcon={<Cancel />}
                    style={{
                      'minWidth': '10px',
                      'paddingRight': '0px',
                    }}
                  >
                  </Button>
                </div>
              </Tooltip>
            </Grid>
          </>
        ) : (null)}
      </Grid>
    );
  };

  handleFormToggle = (params, type) => {
    if (type === 'open') {
      this.handleReset();
      this.setState({
        isEdit: false,
        modalFormShow: !this.state.modalFormShow,
      });

      this.setState({
        formData: {
          ...this.state.formData,
          id: params[1],
          partner_name: params[2],
          status: params[7],
          currentStatus: params[7],
          partner_email: params[8],
          partner_phone: params[9],
          bank_name: params[10],
          bank_account_name: params[11],
          bank_account_number: params[12],
          address: params[13],
          withdrawal_img: null,
          withdrawal_img_url: params[6],
        },
        errorMessage: {...this.state.errorMessage, errorMessage: null},
      });
    } else {
      this.handleReset();
      this.setState({
        isEdit: false,
        modalFormShow: !this.state.modalFormShow,
      });

      this.setState({
        formData: {
          ...this.state.formData,
          partner_name: null,
          status: null,
          currentStatus: null,
          partner_email: null,
          partner_phone: null,
          bank_name: null,
          bank_account_name: null,
          bank_account_number: null,
          address: null,
          withdrawal_img: null,
          withdrawal_img_url: null,
        },
        errorMessage: {...this.state.errorMessage, errorMessage: null},
      });
    }
  };

  handleReset = () => {
    this.setState({
      formData: {
        id: null,
        partner_name: null,
        email: null,
        partner_phone: null,
        bank_name: null,
        bank_account_number: null,
        bank_account_name: null,
        address: null,
        withdrawal_img: null,
        withdrawal_img_url: null,
      },
      modalImportShow: false,
      isEdit: false,
      errorMessage: null,
    });
    // this.toggleResetPage()
  };

  handleInputChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      formData: {...this.state.formData, [name]: value ? value : null},
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {isEdit} = this.state;
    const {
      id,
      status,
      withdrawal_img,
    } = this.state.formData;
    const {updateWithdrawal} = this.props;

    const dataForm = new FormData();
    if (status) {
      dataForm.append('status', status);
    }
    if (withdrawal_img) {
      dataForm.append('withdrawal_img', withdrawal_img);
    }

    const params = {
      ...(status && {status}),
      ...(withdrawal_img && {withdrawal_img}),
    };

    // console.log(params);
    return this.props.updateWithdrawal(id, dataForm);
  }

  renderModalForm = () => {
    const {
      classes,
      isLoading,
      isSuperAdmin,
    } = this.props;
    const {
      isEdit,
      modalFormShow,
      errorMessage,
      errorImageSize,
    } = this.state;
    const {
      partner_name,
      status,
      partner_email,
      partner_phone,
      bank_name,
      bank_account_number,
      bank_account_name,
      address,
      withdrawal_img,
      withdrawal_img_url,
    } = this.state.formData;

    const renderStatusOptions = () => {
      const options = [
        {id: 'waiting', name: 'Waiting'},
        {id: 'onprogress', name: 'On Progress'},
        {id: 'paid', name: 'Paid'},
      ];
      return options;
    };

    const options = [
      'BANK BCA',
      'BANK BENGKULU',
      'BANK BII',
      'BANK BINTANG MANUNGGAL',
      'BANK BISNIS INTERNASIONAL',
      'BANK BJB SYARIAH',
      'BANK BNI',
      'BANK BNI SYARIAH',
      'BANK BNP PARIBAS INDONESIA',
      'BANK BRI',
      'BANK BUANA IND',
      'BANK BUKOPIN',
      'BANK BUMI ARTA',
      'BANK BUMIPUTERA',
      'BANK CAPITAL INDONESIA, TBK.',
      'BANK CENTURY, TBK.',
      'BANK CHINA TRUST INDONESIA',
      'BANK COMMONWEALTH',
      'BANK CREDIT AGRICOLE INDOSUEZ',
      'BANK DANAMON',
      'BANK DBS INDONESIA',
      'BANK DIPO INTERNATIONAL',
      'BANK DKI',
      'BANK EKONOMI',
      'BANK EKSEKUTIF',
      'BANK EKSPOR INDONESIA',
      'BANK FAMA INTERNASIONAL',
      'BANK FINCONESIA',
      'BANK GANESHA',
      'BANK HAGA',
      'BANK HAGAKITA',
      'BANK HARDA',
      'BANK HARFA',
      'BANK HARMONI INTERNATIONAL',
      'BANK HIMPUNAN SAUDARA 1906',
      'BANK IFI',
      'BANK INA PERDANA',
      'BANK INDEX SELINDO',
      'BANK INDOMONEX',
      'BANK JABAR',
      'BANK JASA ARTA',
      'BANK JASA JAKARTA',
      'BANK JATENG',
      'BANK JATIM',
      'BANK KEPPEL TATLEE BUANA',
      'BANK KESAWAN',
      'BANK KESEJAHTERAAN EKONOMI',
      'BANK LAMPUNG',
      'BANK LIPPO',
      'BANK MALUKU',
      'BANK MANDIRI',
      'BANK MASPION',
      'BANK MAYAPADA',
      'BANK MAYBANK INDOCORP',
      'BANK MAYORA',
      'BANK MEGA',
      'BANK MERINCORP',
      'BANK MESTIKA',
      'BANK METRO EXPRESS',
      'BANK MITRANIAGA',
      'BANK MIZUHO INDONESIA',
      'BANK MUAMALAT',
      'BANK MULTI ARTA SENTOSA',
      'BANK MULTICOR TBK.',
      'BANK NAGARI',
      'BANK NIAGA',
      'BANK NISP',
      'BANK NTT',
      'BANK NUSANTARA PARAHYANGAN',
      'BANK OCBC – INDONESIA',
      'BANK OF CHINA LIMITED',
      'BANK PANIN',
      'BANK PERSYARIKATAN INDONESIA',
      'BANK PURBA DANARTA',
      'BANK RESONA PERDANIA',
      'BANK RIAU',
      'BANK ROYAL INDONESIA',
      'BANK SHINTA INDONESIA',
      'BANK SINAR HARAPAN BALI',
      'BANK SRI PARTHA',
      'BANK SULTRA',
      'BANK SULUT',
      'BANK SUMITOMO MITSUI INDONESIA',
      'BANK SUMSEL',
      'BANK SUMUT',
      'BANK SWADESI',
      'BANK SWAGUNA',
      'BANK SYARIAH MANDIRI',
      'BANK SYARIAH MEGA',
      'BANK TABUNGAN NEGARA (PERSERO)',
      'BANK TABUNGAN PENSIUNAN NASIONAL',
      'BANK UIB',
      'BANK UOB INDONESIA',
      'BANK VICTORIA INTERNATIONAL',
      'BANK WINDU KENTJANA',
      'BANK WOORI INDONESIA',
      'BANK YUDHA BHAKTI',
      'BPD ACEH',
      'BPD BALI',
      'BPD DIY',
      'BPD JAMBI',
      'BPD KALIMANTAN BARAT',
      'BPD KALSEL',
      'BPD KALTENG',
      'BPD NTB',
      'BPD NTB',
      'BPD PAPUA',
      'BPD SULAWESI TENGAH',
      'BPD SULSEL',
      'BPR KS (KARYAJATNIKA SEDAYA)',
      'CENTRATAMA NASIONAL BANK',
      'CITIBANK N.A.',
      'DEUTSCHE BANK AG.',
      'HALIM INDONESIA BANK',
      'ING INDONESIA BANK',
      'KOREA EXCHANGE BANK DANAMON',
      'LIMAN INTERNATIONAL BANK',
      'PERMATA BANK',
      'PRIMA MASTER BANK',
      'RABOBANK INTERNASIONAL INDONESIA',
      'STANDARD CHARTERED BANK',
      'THE BANGKOK BANK COMP. LTD',
      'THE BANK OF TOKYO MITSUBISHI UFJ LTD',
      'THE HONGKONG & SHANGHAI B.C.',
    ];

    options.sort(function(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    const titleDialog = isEdit ? 'Update Withdrawal Request' : 'Update Withdrawal Request';
    return (
      <Dialog
        open={modalFormShow}
        onClose={() => this.handleFormToggle(null, 'close')}
      >
        <ModalTitle
          title={titleDialog}
          onClose={() => this.handleFormToggle(null, 'close')}
        />
        <form onSubmit={this.handleSubmit}>
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MInput
                  disabled={true}
                  classNameFC={classes.formControlModalFullWidth}
                  name="partner_name"
                  label="Partner Name"
                  placeholder="Enter partner full name"
                  defaultValue={partner_name}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.partner_name ?
                      errorMessage.partner_name :
                      undefined
                  }
                  autoFocus
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{marginTop: 10}}>
                <MSelect
                  fullWidth
                  classNameFC={classes.formControlModalFullWidth}
                  name="status"
                  label="Status"
                  keyPair={['id', 'name']}
                  options={renderStatusOptions()}
                  value={status}
                  onChange={this.handleInputChange}
                  disabled={this.state.formData.currentStatus === 'paid' || this.state.formData.currentStatus === 'Paid' ? true : false}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MInput
                  disabled={true}
                  name="email"
                  label="Email"
                  placeholder="Email address"
                  defaultValue={partner_email}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.partner_email ?
                      errorMessage.partner_email :
                      undefined
                  }
                  type="email"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <MInputMask
                  classNameFC={classes.formControl}
                  name="partner_phone"
                  label="Phone Number *"
                  placeholder="Enter phone number"
                  type="number"
                  defaultValue={partner_phone}
                  onBlur={this.handleInputPersonal}
                  error={
                    errorMessage && errorMessage.partner_phone ?
                      errorMessage.partner_phone.split(':')[0] :
                      undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* <Grid item xs={6}>
                <MInput
                  disabled={true}
                  name="bank_name"
                  label="Bank Name"
                  placeholder="Enter bank name"
                  defaultValue={bank_name}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.bank_name ?
                      errorMessage.bank_name :
                      undefined
                  }
                  autoFocus
                  input
                />
              </Grid> */}
              <Grid item xs={6}>
                <MAutoComplete
                  disabled={true}
                  label={'Bank Name'}
                  name={'bank_name'}
                  value={bank_name}
                  onChange={(event, newValue) => {
                    this.setState({bank_name: newValue});
                  }}
                  inputValue={this.state.inputValue}
                  onInputChange={(event, newInputValue) => {
                    this.setState({inputValue: newInputValue});
                  }}
                  options={options}
                />
              </Grid>
              <Grid item xs={6}>
                <MInput
                  disabled={true}
                  name="bank_account_number"
                  label="Bank Account Number"
                  placeholder="Enter bank account number"
                  type="number"
                  defaultValue={bank_account_number}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.bank_account_number ?
                      errorMessage.bank_account_number :
                      undefined
                  }
                  autoFocus
                  input
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MInput
                  disabled={true}
                  name="bank_account_name"
                  label="Bank Account Name"
                  placeholder="Enter bank account name"
                  defaultValue={bank_account_name}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.bank_account_name ?
                      errorMessage.bank_account_name :
                      undefined
                  }
                  autoFocus
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MInput
                  disabled={true}
                  name="address"
                  label="Address"
                  placeholder="Enter address"
                  defaultValue={address}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.address ?
                      errorMessage.address :
                      undefined
                  }
                  autoFocus
                  multiline={true}
                  rows={3}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {isSuperAdmin ? (
                <MInput
                  name="withdrawal_img"
                  label="Bukti Transfer"
                  type="file"
                  // disabled={statusDisabled()}
                  onChange={this.handleImageChange}
                  error={
                    errorImageSize ?
                      errorImageSize :
                      errorMessage && errorMessage.withdrawal_img ?
                      errorMessage.withdrawal_img :
                      undefined
                  }
                />
              ) : null}
                {withdrawal_img_url && (
                  <CardMedia
                    title="Photo"
                    image={withdrawal_img_url}
                    className={classes.logo}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.handleFormToggle(null, 'close')}
            />
            <MButton
              className={classes.defaultBtn}
              label="Submit"
              type="submit"
              icon={<Send />}
              loading={isLoading ? true : false}
              onClick={(e) => this.handleSubmit(e)}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };


  toggleRejectWithdrawal = (params) => {
    this.setState({
      rejectModalShow: !this.state.rejectModalShow,
      id: params[1],
      notes: null,
    });
  }

  handleReasonChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      notes: value,
      errorNotes: null,
    });
  }

  handleRejectWithdrawal = () => {
    const {notes, errorNotes, id} = this.state;

    if (notes === null || notes === '' || notes === undefined) {
      return this.setState({errorNotes: true});
    } else {
      const params = {
        notes,
      };
      // console.log(this.props.profile);
      // console.log(id);
      // console.log(params);
      this.props.rejectWithdrawal(id, params);
    }
  }

  handleImageChange = (event) => {
    const {formData, errorMessage} = this.state;
    const logo = event.target.files[0];
    const imageSizeIsAllowed = checkFileUploadSize(logo);
    if (imageSizeIsAllowed) {
      this.setState({
        errorImageSize: null,
        errorMessage: {...errorMessage, withdrawal_img: null},
        formData: {
          ...formData,
          withdrawal_img: logo,
          withdrawal_img_url: URL.createObjectURL(logo),
        },
      });
    } else {
      let message;
      if (imageSizeIsAllowed === false) {
        message =
          'Maximum file size is ' +
          process.env.REACT_APP_MAX_UPLOAD_SIZE_MB +
          ' MB';
      }
      if (imageSizeIsAllowed === null) message = 'Please select image';
      this.setState({
        errorImageSize: message,
        withdrawal_img: null,
        withdrawal_img_url: null,
      });
    }
  };

  renderModalReject = () => {
    const {classes, isSuperAdmin} = this.props;
    const {rejectModalShow, notes, errorNotes} = this.state;

    return (
      <Dialog
        open={rejectModalShow}
        onClose={() => this.setState({
          rejectModalShow: !this.state.rejectModalShow,
        })}
      >
        <form autoComplete="off" style={{width: 460}}>
          <DialogContent style={{paddingTop: '0 !important'}}>
            <input type="hidden" value="no-autocomplete" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginTop: 8, marginBottom: 24}}>
                  <img src={confirmationIcon} alt="img" style={{width: '56px'}} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginBottom: 8}}>
                  <Typography style={{
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#656464',
                    fontFamily: 'Poppins !important',
                    marginTop: -16,
                    marginBottom: -10,
                  }}>Confirmation</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="logo" style={{display: 'flex', justifyContent: 'center', marginBottom: 8, paddingTop: '0px !important', paddingBottom: '0px !important'}}>
                  <Typography style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#656464',
                    fontFamily: 'Poppins !important',
                    textalign: 'center',
                  }}>{`Are you sure you want to reject this data?`}</Typography>
                </div>
              </Grid>
              <Grid item>
                <MInput
                  style={{width: 420}}
                  name="notes"
                  label="Reason"
                  placeholder="Enter your reason"
                  // value={notes}
                  onChange={(event) => this.handleReasonChange(event)}
                  // onClick={() => this.setState({errorNotes: false})}
                  multiline={true}
                  rows={3}
                  // notched={true}
                  shrink={true}
                  error={
                    errorNotes ? 'Reason is required' : false
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{
            padding: '0px 20px 20px 20px',
            placeContent: 'center',
          }}>
            <MButton
              className={classes.primaryBtn}
              label="Yes"
              onClick={() => this.handleRejectWithdrawal()}
            />
            <MButton
              style={{marginRight: 8}}
              className={classes.secondaryBtn}
              label="No"
              onClick={() => this.setState({
                rejectModalShow: false,
              })}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  render() {
    const {
      classes,
      isLoading,
      withdrawData,
      withdrawMeta,
    } = this.props;
    const {resetDatatable, resetPage} =
      this.state;
    const columns = [
      {
        name: 'id',
        label: 'id',
        display: false,
      },
      {
        name: 'partner_name',
        label: 'Partner Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'available_balance',
        label: 'Avalailable Balance',
        display: true,
        customBodyRender: (value) => (value ? 'IDR ' + numberSeparator(value) : '0'),
      },
      {
        name: 'transaction_date',
        label: 'Request Date',
        display: true,
        customBodyRender: (value) => {
          return value ?
          (
            <Typography
              style={{whiteSpace: 'nowrap', fontSize: '14px'}}
            >
              {convDate(value, 'DD-MM-YYYY')}
            </Typography>
          ) :
          '-';
        },
      },
      {
        name: 'amount',
        label: 'Withdrawal Amount',
        display: true,
        customBodyRender: (value) => (value ? 'IDR ' + value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'),
      },
      {
        name: 'image',
        label: 'Image',
        display: true,
        customBodyRender: (value) => {
          return value ?
          (
            <img src={value} alt="img" style={{width: '32px'}} />
          ) :
          '-';
        },
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          return value === 'waiting' ?
            <Typography style={{whiteSpace: 'nowrap', fontSize: '14px', color: '#656464', fontWeight: 700}} >{value}</Typography> :
              value === 'onprogress' ?
                <Typography style={{whiteSpace: 'nowrap', fontSize: '14px', color: '#FFC226', fontWeight: 700}} >{value}</Typography> :
                  value === 'paid' ?
                  <Typography style={{whiteSpace: 'nowrap', fontSize: '14px', color: '#008000', fontWeight: 700}} >{value}</Typography> :
                    value === 'rejected' ?
                      <Typography style={{whiteSpace: 'nowrap', fontSize: '14px', color: '#F44336', fontWeight: 700}} >{value}</Typography> :
                    '-';
        },
      },
      {
        name: 'partner_email',
        label: 'Partner Email',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'partner_phone',
        label: 'Partner Phone Number',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'bank_name',
        label: 'Bank Name',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'bank_account_name',
        label: 'Bank Account Name',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'bank_account_number',
        label: 'Bank Account Number',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'address',
        label: 'Address',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Datatable
          title={'Withdrawal Request List'}
          loading={isLoading}
          dataSource={withdrawData}
          total={withdrawMeta ? withdrawMeta.total : 0}
          page={withdrawMeta ? withdrawMeta.page : 1}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          creatable={false}
          customActions={(params) => this.renderActions(params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={false}
        />
        {this.renderModalForm()}
        {this.renderModalReject()}
      </div>
    );
  }
}

const mapStateToProps = ({auth, withdraw}) => {
  return {
    auth: auth,
    isSuperAdmin: checkSuperadmin(auth),

    isLoading: withdraw.isLoading,
    isError: withdraw.isError,
    isSuccess: withdraw.isSuccess,
    message: withdraw.message,
    trigger: withdraw.trigger,
    withdrawData: withdraw.withdrawData,
    withdrawDataById: withdraw.withdrawDataById,
    withdrawMeta: withdraw.withdrawMeta,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkStatus: () => dispatch(checkStatus()),
  getWithdrawal: (params) => dispatch(getWithdrawal(params)),
  getOneWithdrawal: (id, params) => dispatch(getOneWithdrawal(id, params)),
  updateWithdrawal: (id, params) => dispatch(updateWithdrawal(id, params)),
  rejectWithdrawal: (id, params) => dispatch(rejectWithdrawal(id, params)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(WithdrawRequest));

import * as AUTH from './auth';
import * as USER from './user';
import * as NOTIFICATION from './notification';
import * as ROLEMANAGEMENT from './roleManagement';
import * as ATTENDANCE from './attendanceHistory';
import * as FRAUD from './fraudDetection';
import * as OVERTIME from './overtimeHistory';
import * as GENERAL_SETTING from './generalSetting';
import * as ORGANIZATION from './organization';
import * as PAYMENT from './confirmPayment';
import * as UPGRADE from './upgrade';
import * as PAID_LEAVE from './paidLeave';
import * as REIMBURSE from './reimburse';
import * as INVOICE from './invoice';
import * as JOB_TITLE from './jobTitle';
import * as LEAVE_CONFIG from './leaveConfig';
import * as LEAVE_MANAGEMENT from './leaveManagement';
import * as LEAVE_MANAGEMENT_DETAIL from './leaveManagementDetail';
import * as MASTER_HOLIDAY from './masterHoliday';
import * as COMPANY_TYPE from './companyType';
import * as REGISTER from './register';
import * as REGISTER_USER from './registerUser';
import * as REGISTRATION from './registrationManagement';
import * as ACCOUNT from './account';
import * as DASHBOARD from './dashboard';
import * as PERMIT from './permit';
import * as SHIFT_MANAGEMENT from './shiftManagement';
import * as LEADERBOARD from './leaderboard';
import * as XENDIT from './xendit';
import * as FORGOT_PASSWORD from './forgotPassword';
import * as PARTNER from './partner';
import * as WITHDRAW from './withdraw';
import * as SSO_WHITELIST from './ssoWhitelist';
import * as OFFICE_INVENTORY from './officeInventory';
import * as CASH_ADVANCE from './cashAdvance';
import * as MASTER_INVENTORY from './masterInventory';
import * as DIVISIONMANAGEMENT from './divisionManagement';
import * as APPROVAL_ORDER from './approvalOrderSetting';
import * as BANK from './bank';
import * as EXPORT_ACTIVITY from './exportActivity';
import * as EVENT_MANAGEMENT from './eventManagement';

export {
  AUTH,
  USER,
  ROLEMANAGEMENT,
  ATTENDANCE,
  FRAUD,
  OVERTIME,
  GENERAL_SETTING,
  ORGANIZATION,
  PAYMENT,
  UPGRADE,
  PAID_LEAVE,
  REIMBURSE,
  JOB_TITLE,
  LEAVE_CONFIG,
  LEAVE_MANAGEMENT,
  LEAVE_MANAGEMENT_DETAIL,
  MASTER_HOLIDAY,
  COMPANY_TYPE,
  REGISTER,
  REGISTER_USER,
  REGISTRATION,
  ACCOUNT,
  DASHBOARD,
  PERMIT,
  SHIFT_MANAGEMENT,
  INVOICE,
  LEADERBOARD,
  XENDIT,
  FORGOT_PASSWORD,
  PARTNER,
  WITHDRAW,
  SSO_WHITELIST,
  OFFICE_INVENTORY,
  CASH_ADVANCE,
  MASTER_INVENTORY,
  DIVISIONMANAGEMENT,
  APPROVAL_ORDER,
  BANK,
  NOTIFICATION,
  EXPORT_ACTIVITY,
  EVENT_MANAGEMENT,
};

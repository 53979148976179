/* eslint-disable no-dupe-keys */
export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: 2,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
  },
  modalSubTitle: {
    fontSize: 16,
    marginTop: 16,
  },
  textDetail: {
    fontSize: 14,
    fontWeight: '400',
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
    maxWidth: '100%',
    lineHeight: 3,
  },
  modalAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  imgSize: {
    width: 50,
    height: 50,
  },
  filterBtn: {
    'marginTop': 8,
    'marginLeft': -2,
    'marginRight': 0,
    'height': 40,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  dialogContent: {
    paddingTop: '0 !important',
    minWidth: 600,
  },
  detailTitle: {
    marginTop: theme.spacing(2),
    fontSize: 16,
    fontWeight: '600',
    marginTop: 24,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  updateBtn: {
    'marginRight': '10px',
    'backgroundColor': '#E5AF5A',
    '&:hover': {
      backgroundColor: '#bd904a',
    },
    'color': '#fff',
  },
  infoBtn: {
    'marginLeft': '10px',
    'min-width': '48px',
    'width': '24px',
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  textInfo: {
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    fontWeight: '400',
    color: '#7D7C7C',
  },
  textInfoBold: {
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    fontWeight: '600',
    color: '#7D7C7C',
  },
  formFilter: {
    minWidth: 300,
    marginTop: 14,
    marginRight: 0,
    marginBottom: 4,
  },
  submitCancelBtn: {
    'backgroundColor': '#4CAF50',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
    'color': '#fff',
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    '&:onClick': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onChange': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    '&:onSubmit': {
      backgroundColor: '#0198D0',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  submitBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
    'marginBottom': 8,
    'marginRight': 14,
  },
  cardTitle: {
    color: '#656464',
    fontSize: '18px',
    fontWeight: '600',
    marginTop: '12px',
  },
  subContainer: {
    position: 'relative',
    display: 'flex',
    marginTop: '8px',
    top: '6px',
    right: '2px',
  },
  subStatus: {
    fontWeight: '600',
    marginLeft: '8px',
    fontSize: '14px',
    marginTop: '2px',
  },
  green: {
    color: 'green',
  },
  yellow: {
    color: 'orange',
  },
  red: {
    color: 'red',
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow: 'none',
    },
    'marginBottom': 8,
  },
  cancelIcon: {
    marginRight: 10,
  },
  cancelLink: {
    color: 'white',
    cursor: 'pointer',
    position: 'absolute',
    right: 10,
    bottom: 0,
  },
  renewSubscription: {
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
  searchInput: {
    maxWidth: 300,
  },
  paymentHeader: {
    paddingTop: '0 !important',
  },
  paymentText: {
    display: 'block',
  },
  photo: {
    height: 200,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
});

/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  Grid,
  Link,
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  CssBaseline,
  IconButton,
  Box,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
  Input,
} from '@material-ui/icons';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Recaptcha from 'react-recaptcha';
import LoginIcons from './../../assets/img/pagii-simple-attendance-solution-logo.png';
import styles from './styles';
import {LOGIN_STATE as STATE} from '../../redux/login/login.types';
import {MButton, MCheckboxGroup, MCheckboxItem} from '../../components/form';
import {toast, isObject, parseJwt} from '../../helpers';
import {loginSSO, setSSORedirectURL, clearState, ssoValidation, logout} from '../../redux/login/login.actions';
import {getBannerLogin} from '../../redux/generalSetting/generalSetting.actions';
import loginImage from '../../assets/img/login/loginImg.png'; // Import using relative path
import playStoreDownload from '../../assets/img/login/googlePlay.png'; // Import using relative path
import appStoreDownload from '../../assets/img/login/appStore.png'; // Import using relative path
import {Button} from '@mui/material';
import {Carousel} from 'react-responsive-carousel';
import {persistor} from '../../config';

import './style.css';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const APP_ID = process.env.REACT_APP_ID;

class LoginSSO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      isVerified: false,
      tnc: false,
    };
  }

  getMuiTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiContainer: {
        maxWidthXs: {
          maxWidth: '75%',
        },
        maxWidthLg: {
          maxWidth: '1080px !important',
          maxheight: '680px !important',
        },
      },
      MuiFormControl: {
        marginNormal: {
          marginTop: '8px !important',
          marginBottom: '14px !important',
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 8,
        },
        input: {
          padding: '13.5px 14px !important',
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    let appId = url.searchParams.get('appId');
    if (!appId) {
      appId = APP_ID;
    }
    this.props.ssoValidation({
      ...(appId && {appId}),
    });

    const {token, redirectURL, ssoData} = this.props;
    // if (token) {
    //   toast('info', `You are already logged in.`);
    //   const url = `https://${ssoData?.data?.data?.callback_url}?token=${token}`;
    //   console.log(url, ssoData);
    //   window.location.href = url;
    // }

    if (redirectURL) {
      toast('info', `You are not logged in. Please log in to continue`);
      return this.props.history.push('/login');
    }
  }

  componentDidMount() {
    this.props.getBannerLogin();
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const {message, isSuccess, isError, trigger, profile, redirectURL, ssoData, token, authorization, loginData} = nextProps;
    if (trigger === STATE.SSO_VALIDATE_SUCCESS) {
      if (token) {
        toast('info', `You are already logged in.`);
        const url = `https://${ssoData?.data?.data?.callback_url}?token=${token}`;
        window.location.href = url;
      }
      if (authorization) {
        const parsed = parseJwt(authorization);

        if (!parsed || (parsed?.iat && (parsed.iat * 1000 < new Date()))) {
          persistor.purge();
          persistor.flush();

          if (localStorage.getItem('persist:root')) {
            localStorage.removeItem('persist:root');
          } else if (sessionStorage.getItem('persist:root')) {
            sessionStorage.removeItem('persist:root');
          }

          toast('error', `Your session has expired, login again to continue.`);
          this.props.logout();
          this.props.history.push('/login');
        }

        toast('info', `You are already logged in.`);
        const url = `https://${ssoData?.data?.data?.callback_url}?token=${token}`;
        window.location.href = url;
      }
    }

    if (trigger === STATE.SSO_VALIDATE_FAILED) {
      if (token || authorization) {
        toast('info', `You are already logged in.`);
        this.props.history.push('/dashboard');
      }
    }

    if (this.props.trigger !== trigger && trigger === STATE.LOGIN_SSO_SUCCESS && isSuccess) {
      const URL = redirectURL ? redirectURL : '/dashboard'; // check if Private-route component is setting redirectURL props on redux
      localStorage.setItem('refreshToken', loginData.refreshToken);
      toast('success', `Welcome ${profile && profile.full_name ? profile.full_name : 'user'}`);
      if (redirectURL) this.props.setSSORedirectURL(null); // cleanup redirect URL to null after redirecting
      if (ssoData) {
        const url = `https://${ssoData?.data?.data?.callback_url}?token=${loginData.accessToken}`;
        window.location.href = url;
      } else {
        this.props.history.push({pathname: URL, state: {prevURL: window.location.pathname}});
      }
      this.props.clearState();
    }

    if (isError && message && !isObject(message) && trigger === STATE.LOGIN_SSO_FAILED) toast('error', message);
  }

  verifyCallback = (response) => {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  }
  onloadCallback = () => {
    this.setState({
      isVerified: false,
    });
  }
  expiredCallback = (response) => {
    if (!response) {
      this.setState({
        isVerified: false,
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const url_string = window.location.href;
    const url = new URL(url_string);
    let appId = url.searchParams.get('appId');
    // check exist appId
    if (!appId) {
      appId = APP_ID;
    }
    if (this.state.isVerified===true) {
      const {email, password} = this.state;
      const fcmToken = localStorage?.getItem('fcmToken');
      const payload = {email, password, appId: appId ? appId : APP_ID};

      if (fcmToken) {
        this.props.loginSSO({...payload, fcmtoken: fcmToken});
      } else {
        this.props.loginSSO(payload);
      }
    } else {
      toast('error', `Please verify the CAPTCHA`);
    }
  };

  handleChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value});
  };

  handleTogglePassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  }

  handleCheckboxChange = (event) => {
    const {errorMessage} = this.state;
    const {name, checked} = event.target;
    this.setState({
      errorMessage: {...errorMessage, [name]: null},
      [name]: checked,
    });
  }

  renderLogin = () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    let appId = url.searchParams.get('appId');
    // check exist appId
    if (!appId) {
      appId = APP_ID;
    }
    const {classes, isLoading} = this.props;
    const {tnc} = this.state;
    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <Container component="main" className='containerBanner'>
          <Paper className="paperBanner">
            {this?.props?.bannerLogin && this?.props?.bannerLogin?.banner?.length > 0 ? <div style={{position: 'relative'}}>
              <Carousel
                dynamicHeight={true}
                showIndicators={false}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={2500}
                infiniteLoop={true}
              >
                {this?.props?.bannerLogin?.banner?.length > 0 ? this?.props?.bannerLogin?.banner?.map((banner_login, key) => (
                  <img key={key} alt="bannerLogin" src={banner_login?.img} style={{height: '100%', objectFit: 'cover'}}/>
                )) : null}
              </Carousel>
              <div className='carousel-content-wrapper'>
                <Typography className={classes.imagePaperTitle}>{this?.props?.bannerLogin?.title??''}</Typography>
                <Typography className={classes.imagePaperSubTitle}>{this?.props?.bannerLogin?.description??''}</Typography>
              </div>
            </div> : null}
            <Box p={2} className={classes.paper}>
              <div style={{width: '100%'}}>
                <Typography className={classes.loginTitle} tex>LOGIN</Typography>
              </div>
              <form className={classes.form} noValidate method="POST" onSubmit={this.handleSubmit}>
                <Typography className={classes.formLabel}>Email Address</Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  placeholder='name@email.com'
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={this.handleChange}
                />
                <Typography className={classes.formLabel}>Password</Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  placeholder="password"
                  type={this.state.showPassword ? 'none' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  onChange={this.handleChange}
                  InputProps={{
                    endAdornment:
                      <IconButton position="end" onClick={this.handleTogglePassword}>
                        {!this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>,
                  }}
                />
                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: -14}}>
                  <Link href={`/forgot-password?appId=${appId}`} underline='none'>
                    <Typography className={classes.forgotPasswordText}>
                      Forgot Password ?
                    </Typography>
                  </Link>
                </div>

                {/* <Grid item xs={12}>
                    <div style={{fontSize: 14, marginTop: 10}}>
                      <span>By logging in, you are aggree to our </span>
                      <Link href="https://pagii.co/kebijakan-privasi" target="_blank" rel="noopener noreferrer">
                        <span>Privacy Policy </span>
                      </Link>
                      <span>also </span>
                      <Link href="https://pagii.co/ketentuan-pengguna/" target="_blank" rel="noopener noreferrer">
                        <span>Terms and Condition</span>
                      </Link>
                    </div>
                </Grid> */}

                <Box align="center" style={{marginTop: 12, marginBottom: 24}}>
                  <Recaptcha
                    fullWidth
                    sitekey={RECAPTCHA_SITE_KEY}
                    render="explicit"
                    elementID="a1"
                    onloadCallback={this.onloadCallback}
                    expiredCallback={this.expiredCallback}
                    verifyCallback={this.verifyCallback}
                    // onloadCallback={true}
                    // expiredCallback={true}
                    // verifyCallback={true}
                  />
                </Box>
                <MButton
                  fullWidth
                  className={classes.submit}
                  label="Sign In"
                  color="primary"
                  type='submit'
                  loading={isLoading}
                  onClick={this.handleSubmitJob}
                  // disabled={tnc ? false : true}
                />
                <Grid item xs={12}>
                    <div style={{fontSize: 12, marginTop: 18, textAlign: 'center'}}>
                      <span>By logging in, you are agree to our </span>
                      <Link href="https://pagii.co/kebijakan-privasi" target="_blank" rel="noopener noreferrer">
                        <span>Privacy Policy </span>
                      </Link>
                      <span>also </span>
                      <Link href="https://pagii.co/ketentuan-pengguna/" target="_blank" rel="noopener noreferrer">
                        <span>Terms and Conditions</span>
                      </Link>
                    </div>
                </Grid>
                <Grid container style={{justifyContent: 'center'}} spacing={1}>
                  <Grid item>
                    <Typography className={classes.forgotPassword}>
                      {`Don't have a company account?`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Link href={`/register-sso?appId=${appId}`} underline='none'>
                      <Typography className={classes.forgotPasswordText}>
                        Register
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </form>
              <Typography className={classes.copyright}>Copyright 2020 ©  PT Smooets Teknologi Outsourcing</Typography>
            </Box>
          </Paper>
        </Container>
      </MuiThemeProvider>
    );
  }

  render() {
    return (
      <div>
        {this.props.token ?
          <></> : this.renderLogin()
        }
      </div>
    );
  }
}

const mapStateToProps = ({auth, login, generalSetting}) => ({
  loginData: auth.data,
  authorization: auth.data.userToken,
  token: auth.data.accessToken,
  profile: auth.data.profile,
  isLoading: login.isLoading,
  isSuccess: login.isSuccess,
  isError: login.isError,
  message: login.message,
  trigger: login.trigger,
  redirectURL: login.redirectURL,
  ssoData: login.ssoData,
  bannerLogin: generalSetting.bannerLogin,
});

const mapDispatchToProps = (dispatch) => ({
  loginSSO: (params) => dispatch(loginSSO(params)),
  ssoValidation: (id) => dispatch(ssoValidation(id)),
  setSSORedirectURL: (params) => dispatch(setSSORedirectURL(params)),
  clearState: () => dispatch(clearState()),
  getBannerLogin: () => dispatch(getBannerLogin()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(LoginSSO));

export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  withdrawData: [],
  withdrawDataById: [],
  withdrawMeta: {page: 1, total: 0},
  message: {
    name: '',
  },

  memberData: [],
  memberMeta: {page: 1, total: 0},
  isLoadingmember: false,
  isErrorMember: false,
  isSuccessMember: false,
  triggerMember: '',
};

export const WITHDRAW_STATE = {
  REQUEST: 'WITHDRAW_STATE_REQUEST',
  REQUEST_BY_ID: 'WITHDRAW_STATE_REQUEST_ID',
  REQUEST_FAILED: 'WITHDRAW_STATE_REQUEST_FAILED',
  CLEAR_STATE: 'WITHDRAW_STATE_CLEAR_STATE',

  FETCH: 'WITHDRAW_STATE_FETCH',
  FETCH_SUCCCESS: 'WITHDRAW_STATE_FETCH_SUCCCESS',
  FETCH_FAILED: 'WITHDRAW_STATE_FETCH_FAILED',

  FETCH_BY_ID: 'WITHDRAW_STATE_FETCH_BY_ID',
  FETCH_BY_ID_SUCCCESS: 'WITHDRAW_STATE_FETCH_BY_ID_SUCCCESS',
  FETCH_BY_ID_FAILED: 'WITHDRAW_STATE_FETCH_BY_ID_FAILED',

  UPDATE: 'WITHDRAW_STATE_UPDATE',
  UPDATE_SUCCESS: 'WITHDRAW_STATE_UPDATE_SUCCESS',
  UPDATE_FAILED: 'WITHDRAW_STATE_UPDATE_FAILED',

  REJECT: 'WITHDRAW_STATE_REJECT',
  REJECT_SUCCESS: 'WITHDRAW_STATE_REJECT_SUCCESS',
  REJECT_FAILED: 'WITHDRAW_STATE_REJECT_FAILED',

};

/* eslint-disable no-dupe-keys */
export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  datatable: {
    marginTop: 25,
  },
  dialogContent: {
    paddingTop: '0px !important',
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
    maxWidth: '100%',
    lineHeight: 3,
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow: 'none',
    },
  },
  logo: {
    height: 150,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
  boxTitle: {
    backgroundColor: '#4CAF50',
    color: '#ffffff',
  },
  modalTitle: {
    fontFamily: 'Poppins !important',
    fontSize: 20,
    fontWeight: 700,
    color: '#656464',
    marginBottom: 8,
  },
  filterBtn: {
    'marginTop': 0,
    'marginRight': 0,
    'marginLeft': 0,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  searchInput: {
    width: '300px',
  },
  searchSelect: {
    width: '200px',
  },
  searchDate: {
    maxWidth: '200px',
  },
  dialogFooter: {
    padding: 20,
  },
  pr15: {
    paddingRight: 15,
  },
  featureNote: {
    fontSize: '0.75rem',
    color: 'grey',
  },
  filterBtn: {
    marginTop: 0,
    marginRight: 0,
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 0,
  },
  filter: {
    marginRight: 25,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  filterDatePicker: {
    marginRight: 25,
    maxWidth: 175,
  },
  checkBoxFilter: {
    marginRight: 25,
    marginLeft: -9,
    marginTop: 0,
    marginBottom: 0,
  },

  badgePaid: {
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#005900',
    },
    'backgroundColor': '#008000',
    'color': 'white',
    'boxShadow': 'none',
    'borderRadius': 8,
    'height': 24,
    'fontSize': 12,
    'textTransform': 'capitalize',
  },
  badgeUnpaid: {
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#D09F53',
    },
    'backgroundColor': '#E5AF5A',
    'color': 'white',
    'boxShadow': 'none',
    'borderRadius': 8,
    'height': 24,
    'fontSize': 12,
    'textTransform': 'capitalize',
  },
  badgeInvalid: {
    'backgroundColor': '#F00B38',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#8E0721',
    },
    'color': 'white',
    'boxShadow': 'none',
    'borderRadius': 8,
    'height': 24,
    'fontSize': 12,
    'textTransform': 'capitalize',
  },

  formControlInvoices: {
    marginTop: '8px !important',
  },
  formControlAmount: {
    marginTop: '18px !important',
  },
  formControlDatePicker: {
    marginTop: '22px !important',
  },
  photo: {
    height: 150,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
});


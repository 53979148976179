/* eslint-disable max-len */
import {SSO_WHITELIST} from '../../services';
import {SSO_WHITELIST_STATE as STATE} from './ssoWhitelist.types';
import {transformObject, isArray} from '../../helpers';

export const getSSOWhitelist = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    SSO_WHITELIST.getSSOWhitelist(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getSSODetail = (id, params) => {
  return (dispatch) => {
    dispatch(requestId(STATE.FETCH_BY_ID));
    SSO_WHITELIST.getSSODetail(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_BY_ID_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_BY_ID_FAILED, response.data.message));
            },
        );
  };
};

export const createSSOWhitelist = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE));
    SSO_WHITELIST.createSSOWhitelist(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CREATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CREATE_FAILED, message));
            },
        );
  };
};


export const regenerateSSOWhitelist = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.DROP));
    SSO_WHITELIST.regenerateSSOWhitelist(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DROP_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.DROP_FAILED, message));
            },
        );
  };
};

export const deleteSSOWhitelist = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.DROP));
    SSO_WHITELIST.deleteSSOWhitelist(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DROP_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.DROP_FAILED, message));
            },
        );
  };
};

export const updateSSOWhitelist = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    SSO_WHITELIST.updateSSOWhitelist(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const toggleSSOWhitelist = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.TOGGLE));
    SSO_WHITELIST.toggleSSOWhitelist(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.TOGGLE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.TOGGLE_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestId = (requestType) => ({
  type: STATE.REQUEST_BY_ID,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

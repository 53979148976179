/* eslint-disable max-len */
import {PAID_LEAVE} from '../../services';
import {APPROVAL_STATE as STATE} from './approval.types';

export const getPaidLeaveByToken = (params) => {
  return (dispatch) => {
    dispatch(fetchPaidLeaveHistory());
    PAID_LEAVE.getPaidLeaveByToken(params).then((response) => {
      const data = response.data;
      if (!data.error) {
        dispatch(fetchPaidLeaveHistorySuccess(data));
      } else {
        dispatch(fetchPaidLeaveHistoryFailed(data));
      }
    }).catch((error) => {
      const message = error?.response?.data?.data?.errors && error?.response?.data?.data?.errors[0]?.value ? error?.response?.data?.data?.errors[0]?.value : 'Something went wrong';
      dispatch(fetchPaidLeaveHistoryFailed({message}));
    });
  };
};

export const confirmApproval = (params) => {
  return (dispatch) => {
    dispatch(submitApproval());
    PAID_LEAVE.confirmApproval(params).then((response) => {
      const data = response.data;
      if (!data.error) {
        dispatch(submitApprovalSuccess(data));
      } else {
        dispatch(submitApprovalFailed(data));
      }
    }).catch((error) => {
      const message = error?.response?.data?.data?.errors && error?.response?.data?.data?.errors[0]?.value ? error?.response?.data?.data?.errors[0]?.value : 'Something went wrong';
      dispatch(submitApprovalFailed({message}));
    });
  };
};

export const resetLoader = () => ({
  type: STATE.RESET_LOADER,
});

const fetchPaidLeaveHistory = () => ({
  type: STATE.GET_APPROVAL_BY_TOKEN,
});

const fetchPaidLeaveHistorySuccess = (data) => ({
  type: STATE.GET_APPROVAL_BY_TOKEN_SUCCESS,
  data,
});

const fetchPaidLeaveHistoryFailed = (data) => ({
  type: STATE.GET_APPROVAL_BY_TOKEN_FAILURE,
  data,
});

const submitApproval = () => ({
  type: STATE.SUBMIT_APPROVAL,
});

const submitApprovalSuccess = (data) => ({
  type: STATE.SUBMIT_APPROVAL_SUCCESS,
  data,
});

const submitApprovalFailed = (data) => ({
  type: STATE.SUBMIT_APPROVAL_FAILURE,
  data,
});

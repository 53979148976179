export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  exportActivityData: [],
  exportActivityDataByOrg: [],
  exportActivityMeta: {page: 1, total: 0},
  message: {},
  exportNotificationData: [],
};

export const EXPORT_ACTIVITY_STATE = {
  REQUEST: 'EXPORT_ACTIVITY_REQUEST',
  REQUEST_FAILED: 'EXPORT_ACTIVITY_REQUEST_FAILED',
  CLEAR_STATE: 'EXPORT_ACTIVITY_CLEAR_STATE',
  FETCH: 'EXPORT_ACTIVITY_FETCH',
  FETCH_SUCCCESS: 'EXPORT_ACTIVITY_FETCH_SUCCCESS',
  FETCH_FAILED: 'EXPORT_ACTIVITY_FETCH_FAILED',
  UPDATE_EXPORT_NOTIF: 'UPDATE_EXPORT_NOTIF',
  UPDATE_EXPORT_NOTIF_FAILED: 'UPDATE_EXPORT_NOTIF_FAILED',
  UPDATE_EXPORT_NOTIF_SUCCESS: 'UPDATE_EXPORT_NOTIF_SUCCESS',
  UPDATE_EXPORT_NOTIF_STATUS: 'UPDATE_EXPORT_NOTIF_STATUS',
};

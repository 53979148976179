/* eslint-disable no-dupe-keys */

export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginBottom: 10,
    borderRadius: 15,
    boxShadow: 'none',
  },
  textNote: {
    marginTop: 8,
    fontSize: '10px',
    fontWeight: '500',
    color: '#656464',
    // marginBottom: 14,
  },
  itemPaper: {
    color: theme.palette.text.secondary,
    height: 100,
    display: 'flex',
  },
  iconContainer: {
    display: 'flex',
    textalign: 'center',
    width: 100,
    borderRadius: '4px 0 0 4px',
  },
  icon: {
    width: 48,
    height: 48,
    fontSize: 50,
    lineHeight: 80,
    margin: 'auto',
    color: 'white',
    marginRight: 10,
  },
  iconEmp: {
    width: 38,
    height: 38,
    fontSize: 50,
    lineHeight: 80,
    margin: 'auto',
    color: 'white',
    marginRight: 10,
  },
  content: {
    width: '100%',
    display: 'inline-block',
    padding: '7px 10px',
  },
  contentTitle: {
    fontSize: '1em',
    marginTop: 11,
  },
  contentNumber: {
    fontWeight: 'normal',
    fontSize: 26,
    marginTop: -4,
  },
  filter: {
    marginLeft: 25,
    marginBottom: 4,
  },
  filterBtn: {
    marginTop: 25,
    marginRight: 20,
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 0,
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: 0,
    minWidth: 120,
  },
  filterDatePicker: {
    marginRight: 25,
    maxWidth: 175,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  modalAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  imgSize: {
    width: 128,
    height: 128,
  },
  marginTop: {
    marginTop: 8,
  },
  marginTopNoCheckout: {
    marginTop: 23,
  },
  marginTop14: {
    marginTop: 14.5,
  },
  marginTopToday: {
    marginTop: 31,
  },
  marginLeft: {
    marginLeft: 58,
    marginBottom: 8,
  },
  marginLeftEmp: {
    marginLeft: 18,
    marginTop: -2,
  },
  border: {
    borderLeft: '1px solid #DCDCDC',
    height: 50,
    position: 'relative',
    left: 28,
    top: 38,
  },
  parentFontAttend: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '21px !important',
    color: '#00AFF0',
  },
  parentFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '16px !important',
    color: '#656464',
  },
  childFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    color: '#656464',
    marginBottom: 8,
    paddingTop: 4,
  },
  childTitleFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    color: '#BCBCBC',
    marginBottom: 4,
  },
  childFontTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    color: '#A7A7A7',
  },
  childFontCompleted: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    color: '#777777',
  },
  resultFont: {
    'fontFamily': 'Poppins',
    'fontStyle': 'normal',
    'fontWeight': '750 !important',
    'fontSize': '30px !important',
    'color': '#00AFF0',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#00A2DE',
      textDecoration: 'none',
    },
    'marginTop': '-8px',
  },
  resultFontContainer: {
    marginTop: '-10px',
  },
  titleEmp: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    color: '#656464',
  },
  resultFontEmp: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '750 !important',
    fontSize: '40px !important',
    color: '#00AFF0',
    marginTop: '-8px',
  },
  resultFontPerson: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '750 !important',
    fontSize: '15px !important',
    color: '#00AFF0',
    marginTop: '-4px',
  },
  resultFontAtt: {
    'fontFamily': 'Poppins',
    'fontStyle': 'normal',
    'fontWeight': '750 !important',
    'fontSize': '30px !important',
    'color': '#00AFF0',
    'marginTop': '-5px',
    'marginLeft': '4px',
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#00A2DE',
      textDecoration: 'none',
    },
  },
  resultFontAtts: {
    'fontFamily': 'Poppins',
    'fontStyle': 'normal',
    'fontWeight': '750 !important',
    'fontSize': '30px !important',
    'color': '#00AFF0',
    'marginTop': '-5px',
    'marginLeft': '4px',
  },
  resultFontPersonAtt: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '750 !important',
    fontSize: '15px !important',
    color: '#00AFF0',
    marginTop: 5,
  },
  resultFontPersonAttMulti: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '750 !important',
    fontSize: '15px !important',
    color: '#00AFF0',
    marginTop: 5,
    marginLeft: 5,
  },
  fontTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '24px !important',
    color: '#656464',
    paddingBottom: 12,
  },
  fontTitleUpdate: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '14px !important',
    color: '#656464',
    paddingBottom: 12,
  },
  iconPaddingMulti: {
    paddingTop: 8,
    paddingLeft: -2,
  },
  iconPaddingSingle: {
    paddingTop: 8,
    paddingLeft: 2,
  },
  loaderColor: {
    margin: 5,
    color: '#00AFF0',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    marginBottom: 30,
  },
  dialogContent: {
    padding: 20,
  },
  personFont: {
    fontSize: 18,
    fontWeight: '750px !important',
    verticalAlign: 'middle',
    marginLeft: 6,
    verticalAlign: 'top',
    top: 8,
    position: 'relative',
  },
  invitationTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: '#656464',
  },
  invitationSubTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  primaryBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  secondaryBtn: {
    'backgroundColor': '#E5AF5A',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#D09F53',
      boxShadow: 'none',
    },
    'color': '#fff',
    'boxShadow': 'none',
  },
  expandBtn: {
    'backgroundColor': 'transparent',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    'color': '#00AFF0',
    'boxShadow': 'none',
    'textTransform': 'inherit',
  },
});

import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 100,
  },
  formControlAutoComplete: {
    marginBottom: theme.spacing(1),
    marginTop: 6,
    minWidth: 100,
  },
  hasError: {
    color: 'red',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  checkboxLabel: {
    fontSize: '0.8rem',
  },
  checkboxGroup: {
    margin: '10px 0px',
  },
  wrapperOnButtonCustom: {
    width: 50,
    height: 50,
    backgroundColor: '#fff',
    position: 'absolute',
    right: 0,
    justifyContent: 'centr',
    alignItems: 'center',
    marginTop: 2,
    marginRight: 2,
  },
  onButtonCustom: {
    backgroundColor: 'red',
    width: 20,
    height: 30,
    borderRadius: 20,
    marginRight: 20,
    paddingRight: 20,
    position: 'absolute',
    bottom: 0,
  },
}));

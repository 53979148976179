// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Poppins-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"Poppins\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n::-webkit-scrollbar {\n  height: 5px;\n  width: 10px;\n}\n\n::-webkit-scrollbar-track {\n  background-color: rgba(0, 0, 0, 0.1);\n}\n\n::-webkit-scrollbar-thumb {\n  border-radius: 8px;\n  background-color: rgba(0, 0, 0, 0.2);\n}\n\n* {\n  font-family: \"Poppins\";\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\n#menu-auth.MuiPaper-root {\n  top: 0 !important;\n}\n\n/* CUSTOM SCROLLBAR  */\n\n.MuiDialog-paper::-webkit-scrollbar {\n  width: 0.7em;\n}\n\n.MuiDialog-paper::-webkit-scrollbar-track {\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\n}\n\n.MuiDialog-paper::-webkit-scrollbar-thumb {\n  background-color: darkgrey;\n  outline: 1px solid slategrey;\n}\n\n.MuiButton-containedPrimary {\n  background-color: #00aff0;\n  color: #fff;\n}\n\n/* SWAL */\n.swal2-popup {\n  font-size: 0.7rem !important;\n}\n\n.swal2-container {\n  z-index: 1300;\n}\n\n/* PRE */\npre {\n  white-space: pre-wrap;\n  white-space: -moz-pre-wrap;\n  white-space: -pre-wrap;\n  white-space: -o-pre-wrap;\n  word-wrap: break-word;\n}\n\n/* INPUT Remove spinner on input numbers */\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type=\"number\"] {\n  -moz-appearance: textfield;\n}\n\n/* REACT CROPPER */\n\n/* override react cropper showing image with max-height, so image will not be higher as screen */\n.ReactCrop__image {\n  /* max-height: 250px; */\n  max-height: 64vh;\n}\n\n/* QR PAGE OVERRIDE */\n#section-qr {\n  min-height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n", ""]);
// Exports
module.exports = exports;

import {initialState, OVERTIME_STATE as STATE} from './overtime.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        overtimeData: action.payload.data,
        overtimeMeta: action.payload.meta,
        trigger: action.type,
      };
    case STATE.FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        overtimeData: [],
        message: action.payload,
        trigger: action.type,
      };
    case STATE.CHANGE_STATUS_OVERTIME:
      return {
        ...state,
        isLoadingPost: true,
        isSuccessPost: false,
        isErrorPost: false,
        messagePost: '',
        trigger: action.type,
      };
    case STATE.CHANGE_STATUS_OVERTIME_SUCCESS:
      return {
        ...state,
        isLoadingPost: false,
        isSuccessPost: true,
        isErrorPost: false,
        messagePost: action.payload,
        trigger: action.type,
        status: action.status,
      };
    case STATE.CHANGE_STATUS_OVERTIME_FAILURE:
      return {
        ...state,
        isLoadingPost: false,
        isSuccessPost: false,
        isErrorPost: true,
        messagePost: action.payload,
        trigger: action.type,
        status: action.status,
      };
    case STATE.EXPORT:
      return {
        ...state,
        exportLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case STATE.EXPORT_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        isError: false,
        isSuccess: true,
        exportData: action.payload,
        trigger: action.type,
      };
    case STATE.EXPORT_FAILED:
      return {
        ...state,
        exportLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };

    default:
      return state;
  }
};

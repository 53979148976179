/* eslint-disable max-len */
import {PAID_LEAVE} from '../../services';
import {PAID_LEAVE_STATE as STATE} from './paidLeave.types';
import {transformObject, isArray} from '../../helpers';
export const getPaidLeaveHistory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GET_PAID_LEAVE_HISTORY, true));
    PAID_LEAVE.getPaidLeaveHistory(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.GET_PAID_LEAVE_HISTORY_SUCCESS, response.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.GET_PAID_LEAVE_HISTORY_FAILURE, message));
            },
        );
  };
};

export const putPaidLeaveStatus = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.PUT_CHANGE_STATUS, true));
    PAID_LEAVE.putPaidLeaveOvertime(id, params)
        .then(
            (response)=> {
              dispatch(requestSuccess(STATE.PUT_CHANGE_STATUS_SUCCESS, response.data, params.status));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.PUT_CHANGE_STATUS_FAILURE, message));
            },
        );
  };
};

export const exportExcel = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.EXPORT, true));
    PAID_LEAVE.exportExcel(params)
        .then(
            (response) => {
              const file = response.data.data.file;
              dispatch(requestSuccess(STATE.EXPORT_SUCCESS, response.data.data));
              window.location.href = file; // alternative : window.open(file);
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(exportFailed(STATE.EXPORT_FAILED, message));
            },
        );
  };
};

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});

export const resetLeaveStatus = () => {
  return (dispatch) => {
    dispatch(resetLeave(STATE.RESET_LEAVE));
  };
};

export const clearFormMessage = () => {
  return (dispatch) => {
    dispatch(clearForm(STATE.CLEAR_FORM_LEAVE));
  };
};

// DISPATCH
const request = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: requestType,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response, status = null) => ({
  type: requestType,
  payload: response,
  status: status,
});

const resetLeave = (requestType) => ({type: requestType});

const clearForm = (requestType) => ({type: requestType});

export const initialState = {
  isLoadingDetail: false,
  isErrorDetail: false,
  isSuccessDetail: false,
  triggerDetail: '',
  leaveManagementDetailData: [],
  leaveManagementDetailMeta: {page: 1, total: 0},
  messageDetail: {
    name: '',
  },
};

export const LEAVE_MANAGEMENT_DETAIL_STATE = {
  REQUEST: 'LEAVE_MANAGEMENT_DETAIL_REQUEST',
  REQUEST_FAILED: 'LEAVE_MANAGEMENT_DETAIL_REQUEST_FAILED',
  CLEAR_STATE: 'LEAVE_MANAGEMENT_DETAIL_CLEAR_STATE',
  FETCH_DETAIL: 'LEAVE_MANAGEMENT_DETAIL_FETCH',
  FETCH_DETAIL_SUCCCESS: 'LEAVE_MANAGEMENT_DETAIL_FETCH_SUCCCESS',
  FETCH_DETAIL_FAILED: 'LEAVE_MANAGEMENT_DETAIL_FETCH_FAILED',
};


export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'Poppins !important',
    color: '#656464',
  },
  currentPaper: {
    height: '20px',
    width: '89px',
    borderRadius: '5px',
    marginTop: '-18px',
    marginBottom: '12px',
  },
  currentFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    align: 'center',
  },
  card: {
    width: '100%',
    margin: '42px 8px 32px 25px',
    padding: '32px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
  },
  cardPartner: {
    width: '100%',
    margin: '42px 8px 32px 25px',
    padding: '32px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
    borderRadius: '15px',
  },
  priceFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '24px !important',
    color: '#656464',
    align: 'center',
    margin: '0px 10px',
    marginBottom: '12px',
  },
  idrFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    color: '#656464',
    marginTop: '18px',
  },
  monthFont: {
    align: 'center',
    fontSize: '18px !important',
    fontStyle: 'normal',
    fontFamily: 'Poppins !important',
    fontWeight: '800 !important',
    marginTop: '10px',
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  defaultBtn: {
    'backgroundColor': '#4CAF50',
    'boxShadow': 'none',
    'color': '#fff',
    '&::hover': {
      backgroundColor: '#388e3c',
      boxShadow: 'none',
    },
  },
  btn1: {
    'backgroundColor': '#3CA1C1',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#3A92AD',
      boxShadow: 'none',
    },
    'color': '#fff',
    'paddingLeft': '24px',
    'paddingRight': '24px',
  },
  btn3: {
    'backgroundColor': '#F07300',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#D96800',
      boxShadow: 'none',
    },
    'color': '#fff',
    'paddingLeft': '24px',
    'paddingRight': '24px',
  },
  btn6: {
    'backgroundColor': '#4EC13C',
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#4BB03B',
    },
    'color': '#fff',
    'paddingLeft': '24px',
    'paddingRight': '24px',
  },
  btn12: {
    'backgroundColor': '#EEAC03',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#DB9D00',
      boxShadow: 'none',
    },
    'color': '#fff',
    'paddingLeft': '24px',
    'paddingRight': '24px',
  },
  textNote: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#656464',
    marginBottom: 14,
    marginLeft: 12,
  },
  photo: {
    height: 200,
    width: 'auto',
    backgroundPosition: 'left',
    backgroundSize: 'contain',
  },
  paymentHeader: {
    paddingTop: '0 !important',
  },
  paymentText: {
    display: 'block',
  },
  content: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    minWidth: 600,
    // marginLeft: -14,
    paddingLeft: 8,
    paddingRight: 24,
  },
  paymentList: {
    padding: 0,
    paddingLeft: 10,
    display: 'block',
  },
  closeButton: {
    position: 'absolute',
    right: 2,
    top: 6,
  },
  subsTNC: {
    marginBottom: 10,
  },
  btnMethods: {
    'width': '100%',
    'minHeight': 40,
    'margin': '15px auto',
    'display': 'flex',
  },
  btnSubs: {
    'width': '100%',
    'minHeight': 40,
    'margin': '15px auto',
    'display': 'flex',
  },
  reasonText: {
    fontSize: '0.75rem',
  },
  meetingCalendar: {
    color: '#656464',
    fontFamily: 'Poppins',
    marginBottom: '1.81vh',
    marginLeft: '2.5vh',
  },
  meetingBtn: {
    'marginLeft': '2.5vh',
    'minWidth': '250px',
    'width': '14vw',
    'minHeight': '2.5vw',
    'backgroundColor': '#fff',
    'color': '#3F51B5',
    'border': '2px solid',
    'borderRadius': '10px',
    '&::hover': {
      backgroundColor: '#e8e8e8',
    },
  },
  loaderColor: {
    margin: 5,
    color: '#00AFF0',
  },
});

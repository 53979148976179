/* eslint-disable max-len */
import {ENDPOINTS, apiService} from '../config';

export const getAll = (params) => {
  return apiService.get(
      ENDPOINTS.PAYMENT.ALL,
      {params},
  );
};

export const postPayment = (params) => {
  return apiService.post(
      ENDPOINTS.PAYMENT.ADD,
      params,
  );
};

export const updatePayment = (id, params) => {
  return apiService.put(
      ENDPOINTS.PAYMENT.UPDATE + id,
      params,
  );
};

export const dropPayment = (id) => {
  return apiService.delete(
      ENDPOINTS.PAYMENT.DELETE + id,
  );
};

export const getAllByOrg = (params) => {
  return apiService.get(
      ENDPOINTS.PAYMENT.BY_ORG,
      {params},
  );
};

export const getPaymentHistory = (params) => {
  return apiService.get(ENDPOINTS.PAYMENT.ALL, {
    params,
  });
};

export const putPaymentStatus = (id, params) => {
  return apiService.put(
      ENDPOINTS.PAYMENT.UPDATE + id,
      params,
  );
};

export const getPaymentHistoryByToken = (token) => {
  return apiService.get(ENDPOINTS.APPROVAL.PAYMENT_DETAIL + token);
};

export const confirmApprovalPayment = (params) => {
  return apiService.put(ENDPOINTS.APPROVAL.PAYMENT_CONFIRM + params.status + '/' + params.token, {
    rejected_reason: params.rejected_reason,
  });
};

export const getPaymentAnalytics = (params) => {
  return apiService.get(
      ENDPOINTS.PAYMENT.ANALYTICS,
      {params},
  );
};

export const getPaymentAnalyticsDetails = (params) => {
  return apiService.get(
      ENDPOINTS.PAYMENT.ANALYTICS_DETAILS,
      {params},
  );
};

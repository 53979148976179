/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  Send,
  VolumeUp,
} from '@material-ui/icons';
import styles from './styles';
import {MInput, MButton} from '../../../components/form';
import {toast, isArray, isObject} from '../../../helpers';
import moment from 'moment';
import {GLOBAL_STATE} from '../../../redux/global/global.types';
import {
  saveAnnouncementDraft,
  resetAnnouncementDraft,
} from './../../../redux/global/global.actions';
import {GENERAL_SETTING_STATE as STATE} from '../../../redux/generalSetting/generalSetting.types';
import {
  getGeneralSetting,
  updateGeneralSetting,
  publishAnnouncements,
} from './../../../redux/generalSetting/generalSetting.actions';

class Announcements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      announcements: null,
      formData: {
        term_and_condition: null,
      },
      settings: [],
      message: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getGeneralSetting();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {message, trigger, data, globalTrigger} = nextProps;

    if (trigger === STATE.UPDATE_SUCCESS) {
      toast('success', message);
      this.props.getGeneralSetting();
      this.props.saveAnnouncementDraft();
    }

    if (trigger === STATE.FETCH_ALL_SUCCCESS) {
      const filtered = this.filterSetting(data);
      const tncSetting = filtered && filtered[0] ? filtered[0] : null;

      this.setState({
        settings: filtered,
        formData: {
          term_and_condition: tncSetting,
        },
      });
    }

    // if (trigger === STATE.PUBLISH_SUCCESS && globalTrigger !== GLOBAL_STATE.ANNOUNCEMENT_RESET_DRAFT) {
    //   toast('success', 'Announcement successfuly published');
    //   this.props.resetAnnouncementDraft();
    // }

    if (this.props.trigger !== trigger && trigger === STATE.UPDATE_FAILED && !isObject(message)) {
      toast('error', message);
      this.props.getGeneralSetting();
    }
  }

  filterSetting = (data) => {
    const filter = (data, key) => {
      const result = data.filter((value) => value.key === key);
      return isArray(result) ? result[0] : null;
    };

    const tncSetting = filter(data, 'term_and_condition');
    return [tncSetting];
  }

  isEmptyOrSpaces(str) {
    return (
      str === null || str.match(/^ *$/) !== null || str.length < 7
    );
  }

  handleSubmit = () => {
    const {formData} = this.state;
    let newVal;
    const converted = [];
    Object.entries(formData).forEach(([key, val]) => {
      // if (val.key === 'job_title') { // must be array, then stringify / toString
      //   val.value = isArray(val.value) ? (val.value).toString() : val.value; // alt : val.value = JSON.stringify(val.value);
      // }
      const empty = this.isEmptyOrSpaces(val.value);
      if (/\d|[0-9A-Za-z]/.test(val.value) && val.value.length > 7) {
        converted.push({
          key: val.key,
          label: val.label,
          value: val.value,
          group: val.group,
        });
        const params = {data: converted};
        this.props.updateGeneralSetting(params);
        this.props.resetAnnouncementDraft();
        // toast('success', 'Announcement successfuly published');
      } else {
        toast('error', 'The announcement must consist of alphanumeric and more than 8 characters');
      }
    });
  }

  handlePublish = () => {
    const text = this.state.formData.term_and_condition;
    const params = {
      title: 'Announcement',
      body: text.value,
    };
    this.props.publishAnnouncements(params);
  }

  handleInputChangeSetting = (type, params) => {
    let name;
    let value;
    if (type === 'text') {
      name = params.target.name;
      value = params.target.value;
    } else if (type === 'multiple' || type === 'datetime') {
      name = params.name;
      value = params.value;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          value,
        },
      },
    });
  };

  handleGetJobTitle = (array) => {
    const {jobData} = this.props;
    const filter = isArray(jobData) ? jobData.filter((item) => array.includes(item.id)) : [];
    return filter.map((value) => value.id);
  }

  renderForm = (settingArray) => {
    const {classes} = this.props;
    const {term_and_condition} = this.state.formData;

    const result = settingArray.map((setting) => {
      const {id, key} = setting;
      if (id) {
        if (key === 'term_and_condition') {
          return (
          <div key={id}>
            <Typography className={classes.paperTitle}>Announcements</Typography>
            <MInput
              classNameFC={classes.formControl}
              name={key}
              label="Company Announcements *"
              placeholder="Fill in announcement message (min. 8 alphanumeric characters)"
              defaultValue={term_and_condition ? term_and_condition.value : null}
              onBlur={(event) => this.handleInputChangeSetting('text', event)}
              autoFocus
              autoComplete="off"
              multiline
              validate={false}
              rows={5}
              rowsMin={5}
              rowsMax={20}
            />
          </div>
        );
      }
    }
    });
    return result;
  }

  render() {
    const {classes, data, isLoading, isDraftSaved} = this.props;
    const {settings} = this.state;
    return (
      <div className={classes.root}>
        <Paper variant='outlined' className={classes.paper}>
          <form>
            {
              settings.length > 0 ?
              this.renderForm(settings) : ''
            }
            <div className={classes.btnContainer}>
              <MButton
                className={`${classes.defaultBtn} ${classes.mr}`}
                label="Update"
                icon={<Send/>}
                onClick={this.handleSubmit}
                loading={isLoading && !isDraftSaved && data.length > 0 ? true : false}
              />
              {/* <MButton
                className={classes.defaultBtn}
                label="Publish"
                icon={<VolumeUp/>}
                onClick={this.handlePublish}
                disabled={isLoading || !isDraftSaved ? true : false}
                loading={isLoading && isDraftSaved ? true : false}
              /> */}
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = ({auth, global, generalSetting}) => {
  return ({
    organization: auth.data.organization,
    data: generalSetting.data,
    isLoading: generalSetting.isLoading,
    isSuccess: generalSetting.isSuccess,
    isError: generalSetting.isError,
    message: generalSetting.message,
    trigger: generalSetting.trigger,
    globalTrigger: global.trigger,
    isDraftSaved: global.isDraftSaved,
  });
};

const mapDispatchToProps = (dispatch) => ({
  getGeneralSetting: (key) => dispatch(getGeneralSetting(key)),
  updateGeneralSetting: (params) => dispatch(updateGeneralSetting(params)),
  publishAnnouncements: (params) => dispatch(publishAnnouncements(params)),
  saveAnnouncementDraft: (params) => dispatch(saveAnnouncementDraft(params)),
  resetAnnouncementDraft: (params) => dispatch(resetAnnouncementDraft(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Announcements));


export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  bankData: [],
  bankMeta: {page: 1, total: 0},
  message: {
    bank_name: '',
  },
};

export const BANK_STATE = {
  REQUEST: 'BANK_REQUEST',
  REQUEST_FAILED: 'BANK_REQUEST_FAILED',
  CLEAR_STATE: 'BANK_CLEAR_STATE',
  GET: 'BANK_GET',
  GET_SUCCESS: 'BANK_GET_SUCCESS',
  GET_FAILED: 'BANK_GET_FAILED',
};

/* eslint-disable max-len */
import {PAYMENT} from '../../services';
import {APPROVAL_STATE as STATE} from './approvalPayment.types';

export const getApprovalPaymentByToken = (params) => {
  return (dispatch) => {
    dispatch(fetchApprovalPaymentHistory());
    PAYMENT.getPaymentHistoryByToken(params).then((response) => {
      const data = response.data;
      if (!data.error) {
          dispatch(fetchApprovalPaymentSuccess(data));
        } else {
        dispatch(fetchApprovalPaymentFailed(data));
      }
    }).catch((error) => {
      const message = error?.response?.data?.data?.errors && error?.response?.data?.data?.errors[0]?.value ? error?.response?.data?.data?.errors[0]?.value : 'Something went wrong';
      dispatch(fetchApprovalPaymentFailed({message}));
    });
  };
};

export const confirmApprovalPayment = (params) => {
  return (dispatch) => {
    dispatch(submitApprovalPayment());
    PAYMENT.confirmApprovalPayment(params).then((response) => {
      const data = response.data;
      if (!data.error) {
        dispatch(submitApprovalPaymentSuccess(data));
      } else {
        dispatch(submitApprovalPaymentFailed(data));
      }
    }).catch((error) => {
      const message = error?.response?.data?.data?.errors && error?.response?.data?.data?.errors[0]?.value ? error?.response?.data?.data?.errors[0]?.value : 'Something went wrong';
      dispatch(submitApprovalPaymentFailed({message}));
    });
  };
};

export const resetLoader = () => ({
  type: STATE.RESET_LOADER,
});

const fetchApprovalPaymentHistory = () => ({
  type: STATE.GET_APPROVAL_PAYMENT_BY_TOKEN,
});

const fetchApprovalPaymentSuccess = (data) => ({
  type: STATE.GET_APPROVAL_PAYMENT_BY_TOKEN_SUCCESS,
  data,
});

const fetchApprovalPaymentFailed = (data) => ({
  type: STATE.GET_APPROVAL_PAYMENT_BY_TOKEN_FAILURE,
  data,
});

const submitApprovalPayment = () => ({
  type: STATE.SUBMIT_APPROVAL_PAYMENT,
});

const submitApprovalPaymentSuccess = (data) => ({
  type: STATE.SUBMIT_APPROVAL_PAYMENT_SUCCESS,
  data,
});

const submitApprovalPaymentFailed = (data) => ({
  type: STATE.SUBMIT_APPROVAL_PAYMENT_FAILURE,
  data,
});

import {initialState, PAID_LEAVE_STATE} from './paidLeave.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case PAID_LEAVE_STATE.GET_PAID_LEAVE_HISTORY:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case PAID_LEAVE_STATE.GET_PAID_LEAVE_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        paidLeaveData: action.payload.data,
        paidLeaveMeta: action.payload.meta,
        trigger: action.type,
      };
    case PAID_LEAVE_STATE.GET_PAID_LEAVE_HISTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case PAID_LEAVE_STATE.PUT_CHANGE_STATUS:
      return {
        ...state,
        isLoadingPut: true,
        isSuccessPut: false,
        isErrorPut: false,
        messagePut: '',
        trigger: action.type,
      };
    case PAID_LEAVE_STATE.PUT_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingPut: false,
        isSuccessPut: true,
        isErrorPut: false,
        messagePut: action.payload,
        trigger: action.type,
        status: action.status,
      };
    case PAID_LEAVE_STATE.PUT_CHANGE_STATUS_FAILURE:
      return {
        ...state,
        isLoadingPut: false,
        isSuccessPut: false,
        isErrorPut: true,
        messagePut: action.payload,
        trigger: action.type,
        status: action.status,
      };
    case PAID_LEAVE_STATE.RESET_LEAVE:
      return {
        ...state,
        isLoadingPut: false,
        isSuccessPut: false,
        isErrorPut: false,
        trigger: action.type,
      };
    case PAID_LEAVE_STATE.CLEAR_FORM_LEAVE:
      return {
        ...state,
        messagePut: '',
        trigger: action.type,
      };
    case PAID_LEAVE_STATE.EXPORT:
      return {
        ...state,
        exportLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case PAID_LEAVE_STATE.EXPORT_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        isError: false,
        isSuccess: true,
        exportData: action.payload,
        trigger: action.type,
      };
    case PAID_LEAVE_STATE.EXPORT_FAILED:
      return {
        ...state,
        exportLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    default:
      return state;
  }
};

/* eslint-disable max-len */
import {initialState, REGISTER_STATE as STATE} from './register.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.requestType,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.requestType,
      };
    case STATE.REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.REGISTER_SSO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.APPROVAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.APPROVAL_SSO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.APPROVAL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.APPROVAL_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.APPROVAL_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.ANALYTICS_REGISTER_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        analyticsRegister: action.payload.data,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.ANALYTICS_REGISTER_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.FETCH_ANALYTICS_DETAILS_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.FETCH_ANALYTICS_DETAILS_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        analyticsDetails: action.payload.data,
        analyticsMeta: action.payload.meta,
        trigger: action.type,
      };
    case STATE.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

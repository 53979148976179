/* eslint-disable no-dupe-keys */
export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: '12px',
    fontWeight: 'medium',
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
  },
  icon: {
    marginRight: '6px',
  },
  success: {
    fontWeight: '700',
    fontSize: '12px',
    color: '#09BF63',
  },
  waiting: {
    fontWeight: '700',
    fontSize: '12px',
    color: '#FFC226',
  },
  cardWrapper: {
    position: 'fixed',
    bottom: 0,
    right: 20,
    zIndex: 1000,
    width: '400px',
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
});


/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Paper,
  Switch,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Send, ArrowBack, HelpOutline, DragIndicator, Close} from '@material-ui/icons';
import styles from './styles';
import {toast, isObject} from '../../../helpers';
import _ from 'lodash';

import {MButton, ModalTitle} from '../../../components/form';
import {APPROVAL_ORDER_STATE as STATE} from '../../../redux/approvalOrder/approvalOrder.types';
import {
  getDetailApproval,
  updateApprovalOrder,
  getMasterApproval,
  getUserApproval,
} from '../../../redux/approvalOrder/approvalOrder.actions';
import Accordion from '@mui/material/Accordion';
import IconButton from '@mui/material/IconButton';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Autocomplete, Checkbox, Divider, FormControlLabel, FormGroup, TextField} from '@mui/material';
import {Container, Draggable} from 'react-smooth-dnd';
import {arrayMoveImmutable} from 'array-move';
import {
  getProfile,
  clearState as clearStateUser,
} from '../../../redux/userManagement/userManagement.actions';
import {USER_STATE} from '../../../redux/userManagement/userManagement.types';


class EditApproval extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      editing: false,
      showModal: false,
      formData: {
        id: null,
        organization_id: null,
        division_id: null,
        division_name: null,
      },
      overtime: {
        feature_name: 'Overtime',
        status: false,
        master_approval: [],
        user_approval: [],
        expanded: false,
      },
      permit: {
        feature_name: 'Permit',
        status: false,
        master_approval: [],
        user_approval: [],
        expanded: false,
      },
      leave: {
        feature_name: 'Leave',
        status: false,
        master_approval: [],
        user_approval: [],
        expanded: false,
      },
      reimburse: {
        feature_name: 'Reimburse',
        status: false,
        master_approval: [],
        user_approval: [],
        expanded: false,
      },
      employee_facilities: {
        feature_name: 'Employee Facilities',
        status: false,
        master_approval: [],
        user_approval: [],
        expanded: false,
      },
      query: '',
      errorMessage: null,
      sortBy: null,
      order: 'desc',
      modalGuideShow: false,
    };
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiDialog: {
        paperWidthSm: {
          width: 575,
        },
      },
      MuiDialogContent: {
        root: {
          '&:first-child': {
            paddingTop: 0,
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  tooltipTheme = () => createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: 16,
          marginTop: 1,
        },
      },
      MuiIconButton: {
        root: {
          padding: 0,
        },
      },
    },
  })

  accordionTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiChip: {
        styleOverrides: {
          deleteIcon: {
            display: 'none',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            'marginTop': '18px !important',
            'borderRadius': '18px !important',
            '&::before': {
              backgroundColor: 'transparent !important',
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '4px !important',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '4px !important',
            margin: '0px 12px 0px 0px',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: 6,
          },
        },
      },
    },
  })

  removeTheme = () => createMuiTheme({
    overrides: {
      MuiSvgIcon: {
        root: {
          color: '#F00B38',
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const division_id = url.searchParams.get('division_id') * 1;
    this.props.getProfile(this.props.profile.user_id);
    this.props.getMasterApproval();
    this.props.getUserApproval({
      division_id: division_id,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message, approvalOrderDetail, masterApprovalData, userApprovalData, profile} = nextProps;
    const {overtime, permit, leave, reimburse, employee_facilities} = this.state;


    if (isSuccess) {
      if (trigger === STATE.CREATE_SUCCESS || trigger === STATE.UPDATE_SUCCESS || trigger === STATE.DROP_SUCCESS) {
        toast('success', message);
        this.props.history.push('/approval-order-setting');
      }
      if (trigger === STATE.FETCH_MASTER_SUCCCESS) {
        const newDataOvertime = [];
        const newDataPermit = [];
        const newDataLeave = [];
        const newDataReimburse = [];
        const newDataEmployeeFacilities = [];

        masterApprovalData.map((item, index) => {
          newDataOvertime.push({
            ...item,
            checked: false,
            feature: 'overtime_master',
          });
        });
        this.setState({
          overtime: {
            ...this.state.overtime,
            master_approval: this.state.overtime.master_approval.length !== 0 ? this.state.overtime.master_approval.length : newDataOvertime,
          },
        });


        masterApprovalData.map((item) => {
          newDataPermit.push({
            ...item,
            checked: false,
            feature: 'permit_master',
          });
        });
        this.setState({
          permit: {
            ...this.state.permit,
            master_approval: this.state.permit.master_approval.length !== 0 ? this.state.permit.master_approval.length : newDataPermit,
          },
        });

        masterApprovalData.map((item) => {
          newDataLeave.push({
            ...item,
            checked: false,
            feature: 'leave_master',
          });
        });
        this.setState({
          leave: {
            ...this.state.leave,
            master_approval: this.state.leave.master_approval.length !== 0 ? this.state.leave.master_approval.length : newDataLeave,
          },
        });

        masterApprovalData.map((item) => {
          newDataReimburse.push({
            ...item,
            checked: false,
            feature: 'reimburse_master',
          });
        });
        this.setState({
          reimburse: {
            ...this.state.reimburse,
            master_approval: this.state.reimburse.master_approval.length !== 0 ? this.state.reimburse.master_approval.length : newDataReimburse,
          },
        });

        masterApprovalData.map((item) => {
          newDataEmployeeFacilities.push({
            ...item,
            checked: false,
            feature: 'employee_facilities_master',
          });
        });
        this.setState({
          employee_facilities: {
            ...this.state.employee_facilities,
            master_approval: this.state.employee_facilities.master_approval.length !== 0 ? this.state.employee_facilities.master_approval.length : newDataEmployeeFacilities,
          },
        });

        const url_string = window.location.href;
        const url = new URL(url_string);
        const id = url.searchParams.get('id') * 1;
        this.props.getDetailApproval(id);
      }
    }

    if (trigger === STATE.FETCH_DETAIL_SUCCCESS) {
      const newOvertimeMaster = [];
      const getDataOvertime = approvalOrderDetail.feature.filter(function(data) {
        return data.feature_name === 'overtime';
      });


      for (let i=0; i<overtime.master_approval.length; i++) {
        let checked = false;
        for (let j=0; j<getDataOvertime[0].master_approval.length; j++) {
          if (overtime.master_approval[i].name === getDataOvertime[0].master_approval[j].name) {
            checked = true;
          }
        }
        newOvertimeMaster.push({
          ...overtime.master_approval[i],
          checked: checked,
          feature: 'overtime_master',
        });
      }

      const newLeaveMaster = [];
      const getDataLeave = approvalOrderDetail.feature.filter(function(data) {
        return data.feature_name === 'leave';
      });

      for (let i=0; i<leave.master_approval.length; i++) {
        let checked = false;
        for (let j=0; j<getDataLeave[0].master_approval.length; j++) {
          if (leave.master_approval[i].name === getDataLeave[0].master_approval[j].name) {
            checked = true;
          }
        }
        newLeaveMaster.push({
          ...leave.master_approval[i],
          checked: checked,
          feature: 'leave_master',
        });
      }

      const newPermitMaster = [];
      const getDataPermit = approvalOrderDetail.feature.filter(function(data) {
        return data.feature_name === 'permit';
      });

      for (let i=0; i<permit.master_approval.length; i++) {
        let checked = false;
        for (let j=0; j<getDataPermit[0].master_approval.length; j++) {
          if (permit.master_approval[i].name === getDataPermit[0].master_approval[j].name) {
            checked = true;
          }
        }
        newPermitMaster.push({
          ...permit.master_approval[i],
          checked: checked,
          feature: 'permit_master',
        });
      }

      const newReimburseMaster = [];
      const getDataReimburse = approvalOrderDetail.feature.filter(function(data) {
        return data.feature_name === 'reimburse';
      });

      for (let i=0; i<reimburse.master_approval.length; i++) {
        let checked = false;
        for (let j=0; j<getDataReimburse[0].master_approval.length; j++) {
          if (reimburse.master_approval[i].name === getDataReimburse[0].master_approval[j].name) {
            checked = true;
          }
        }
        newReimburseMaster.push({
          ...reimburse.master_approval[i],
          checked: checked,
          feature: 'reimburse_master',
        });
      }

      const newEmployeeFacilitieMaster = [];
      const getDataEmployeeFacilities = approvalOrderDetail.feature.filter(function(data) {
        return data.feature_name === 'employee_facilitie';
      });

      for (let i=0; i<employee_facilities.master_approval.length; i++) {
        let checked = false;
        for (let j=0; j<getDataEmployeeFacilities[0].master_approval.length; j++) {
          if (employee_facilities.master_approval[i].name === getDataEmployeeFacilities[0].master_approval[j].name) {
            checked = true;
          }
        }
        newEmployeeFacilitieMaster.push({
          ...employee_facilities.master_approval[i],
          checked: checked,
          feature: 'employee_facilities_master',
        });
      }

      const filteredOvertime = [];
      newOvertimeMaster.map((item) => {
        if (item.id === profile.user_id) {
          filteredOvertime.push({
            id: item.id,
            name: item.name,
            email: item.email,
            feature: item.feature,
            approval_type: item.approval_type,
            checked: true,
          });
        } else {
          filteredOvertime.push(item);
        }
      });

      const filteredPermit = [];
      newPermitMaster.map((item) => {
        if (item.id === profile.user_id) {
          filteredPermit.push({
            id: item.id,
            name: item.name,
            email: item.email,
            feature: item.feature,
            approval_type: item.approval_type,
            checked: true,
          });
        } else {
          filteredPermit.push(item);
        }
      });

      const filteredLeave = [];
      newLeaveMaster.map((item) => {
        if (item.id === profile.user_id) {
          filteredLeave.push({
            id: item.id,
            name: item.name,
            email: item.email,
            feature: item.feature,
            approval_type: item.approval_type,
            checked: true,
          });
        } else {
          filteredLeave.push(item);
        }
      });

      const filteredReimburse = [];
      newReimburseMaster.map((item) => {
        if (item.id === profile.user_id) {
          filteredReimburse.push({
            id: item.id,
            name: item.name,
            email: item.email,
            feature: item.feature,
            approval_type: item.approval_type,
            checked: true,
          });
        } else {
          filteredReimburse.push(item);
        }
      });

      const filteredEmployeeFacilitieMaster = [];
      newEmployeeFacilitieMaster.map((item) => {
        if (item.id === profile.user_id) {
          filteredEmployeeFacilitieMaster.push({
            id: item.id,
            name: item.name,
            email: item.email,
            feature: item.feature,
            approval_type: item.approval_type,
            checked: true,
          });
        } else {
          filteredEmployeeFacilitieMaster.push(item);
        }
      });


        this.setState({
          overtime: {
            ...this.state.overtime,
            feature_name: 'Overtime',
            expanded: false,
            status: getDataOvertime[0].status === 'active' ? true : false,
            master_approval: filteredOvertime,
            user_approval: _.map(getDataOvertime[0].user_approval, (data) => ({
              id: data.id,
              name: data.name,
              email: data.email,
            })),
          },
          leave: {
            ...this.state.leave,
            feature_name: 'Leave',
            expanded: false,
            status: getDataLeave[0].status === 'active' ? true : false,
            master_approval: filteredLeave,
            user_approval: _.map(getDataLeave[0].user_approval, (data) => ({
              id: data.id,
              name: data.name,
              email: data.email,
            })),
          },
          permit: {
            ...this.state.permit,
            feature_name: 'Permit',
            expanded: false,
            status: getDataPermit[0].status === 'active' ? true : false,
            master_approval: filteredPermit,
            user_approval: _.map(getDataPermit[0].user_approval, (data) => ({
              id: data.id,
              name: data.name,
              email: data.email,
            })),
          },
          reimburse: {
            ...this.state.reimburse,
            feature_name: 'Reimburse',
            expanded: false,
            status: getDataReimburse[0].status === 'active' ? true : false,
            master_approval: filteredReimburse,
            user_approval: _.map(getDataReimburse[0].user_approval, (data) => ({
              id: data.id,
              name: data.name,
              email: data.email,
            })),
          },
          employee_facilities: {
            ...this.state.employee_facilities,
            feature_name: 'Employee Facilities',
            expanded: false,
            status: getDataEmployeeFacilities[0].status === 'active' ? true : false,
            master_approval: filteredEmployeeFacilitieMaster,
            user_approval: _.map(getDataEmployeeFacilities[0].user_approval, (data) => ({
              id: data.id,
              name: data.name,
              email: data.email,
            })),
          },
        });


      this.setState({
        formData: {
          ...this.state.formData,
          division_id: approvalOrderDetail.division_id,
          division_name: approvalOrderDetail.division_name,
          id: approvalOrderDetail.id,
          organization_id: approvalOrderDetail.organization_id,
        },
      });
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }


  handleSubmitApprovalEdit = (e) => {
    const {overtime, permit, leave, reimburse, employee_facilities} = this.state;
    const {id} = this.state.formData;

    const params = {
      feature: [],
    };

    const overtimeMaster = [];
    const overtimeApproval = [];
    overtime.master_approval.map((item) => {
      if (item.checked === true) {
        overtimeMaster.push({
          id: item.id,
          name: item.name,
          email: item.email,
        });
      }
    });
    overtime.user_approval.map((item) => {
      overtimeApproval.push({
        id: item.id,
        name: item.name,
        email: item.email,
      });
    });
    params.feature.push({
      feature_name: 'overtime',
      status: overtime.status === true ? 'active' : 'inactive',
      master_approval: overtimeMaster,
      user_approval: overtimeApproval,
    });

    const permitMaster = [];
    const permitApproval = [];
    permit.master_approval.map((item) => {
      if (item.checked === true) {
        permitMaster.push({
          id: item.id,
          name: item.name,
          email: item.email,
        });
      }
    });
    permit.user_approval.map((item) => {
      permitApproval.push({
        id: item.id,
        name: item.name,
        email: item.email,
      });
    });
    params.feature.push({
      feature_name: 'permit',
      status: permit.status === true ? 'active' : 'inactive',
      master_approval: permitMaster,
      user_approval: permitApproval,
    });

    const leaveMaster = [];
    const leaveApproval = [];
    leave.master_approval.map((item) => {
      if (item.checked === true) {
        leaveMaster.push({
          id: item.id,
          name: item.name,
          email: item.email,
        });
      }
    });
    leave.user_approval.map((item) => {
      leaveApproval.push({
        id: item.id,
        name: item.name,
        email: item.email,
      });
    });
    params.feature.push({
      feature_name: 'leave',
      status: leave.status === true ? 'active' : 'inactive',
      master_approval: leaveMaster,
      user_approval: leaveApproval,
    });

    const reimburseMaster = [];
    const reimburseApproval = [];
    reimburse.master_approval.map((item) => {
      if (item.checked === true) {
        reimburseMaster.push({
          id: item.id,
          name: item.name,
          email: item.email,
        });
      }
    });
    reimburse.user_approval.map((item) => {
      reimburseApproval.push({
        id: item.id,
        name: item.name,
        email: item.email,
      });
    });
    params.feature.push({
      feature_name: 'reimburse',
      status: reimburse.status === true ? 'active' : 'inactive',
      master_approval: reimburseMaster,
      user_approval: reimburseApproval,
    });

    const employeeFacilitiesMaster = [];
    const employeeFacilitiesApproval = [];

    employee_facilities.master_approval.map((item) => {
      if (item.checked === true) {
        employeeFacilitiesMaster.push({
          id: item.id,
          name: item.name,
          email: item.email,
        });
      }
    });
    employee_facilities.user_approval.map((item) => {
      employeeFacilitiesApproval.push({
        id: item.id,
        name: item.name,
        email: item.email,
      });
    });
    params.feature.push({
      feature_name: 'employee_facilitie',
      status: employee_facilities.status === true ? 'active' : 'inactive',
      master_approval: employeeFacilitiesMaster,
      user_approval: employeeFacilitiesApproval,
    });

    this.props.updateApprovalOrder(id, params);
  }

  renderNavigation = () => {
    const {classes, isLoading} = this.props;

    return (
      <div className="d-flex" style={{justifyContent: 'space-between'}}>
        <div className="d-flex">
          <Link to="/approval-order-setting" className={classes.btnBack}>
            <ArrowBack/>
          </Link>
          <Typography className={classes.backTitle}>Back to list</Typography>
        </div>
        <MButton
          id="edit-approval-order"
          className={classes.defaultBtn}
          label={'UPDATE ORDER'}
          color="primary"
          type="submit"
          icon={<Send />}
          loading={isLoading}
          onClick={() => {
this.handleSubmitApprovalEdit();
}}
        />
      </div>
    );
  }

  renderDivisionCard = () => {
    const {classes} = this.props;
    const {division_name} = this.state.formData;
    return (
      <Paper variant='outlined' className={classes.additionalPaper} style={{marginTop: 40}}>
        <Typography className={classes.divisionTitle}>Division </Typography>
        <Typography className={classes.divisionName}>{division_name}</Typography>
      </Paper>
    );
  }

  handleCheckboxChange = (event, index) => {
    const {feature} = this.state.formData;
    feature[index].status = event.target.checked;
    this.setState({
      formData: {
        ...this.state.formData,
        feature,
      },
    });
  }

  renderEditOrderOvertime = () => {
    const {classes, userApprovalData} = this.props;
    const {overtime} = this.state;
    return (
      <>
          <ThemeProvider theme={this.accordionTheme()}>
            <Accordion variant='outlined' expanded={overtime.expanded}>
              <AccordionSummary
                expandIcon={
                  <IconButton
                    onClick={() => this.setState({
                      overtime: {
                        ...this.state.overtime,
                        expanded: !overtime.expanded,
                      },
                    })}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography className={classes.cardTitle}>{overtime.feature_name}</Typography>
                    <Switch
                      size='small'
                      color='primary'
                      checked={overtime.status}
                      onChange={() => {
                        this.setState({
                          overtime: {
                            ...overtime,
                            status: !overtime.status,
                          },
                        });
                      }}
                    />
                  </div>
                  <div style={{display: 'flex', marginTop: !overtime.expanded ? 8 : null, marginBottom: !overtime.expanded ? 32 : null}}>
                    {overtime?.master_approval.map((list, index) => {
                      if (!overtime.expanded) {
                        if (list.checked) {
                          if (overtime.master_approval.length > 1) {
                            return (
                              <Typography key={index} className={classes.approvalList}>{list.name},&ensp;</Typography>
                            );
                          } else {
                            return (
                              <Typography key={index} className={classes.approvalList}>{`${list.name},`}&ensp;</Typography>
                            );
                          }
                        }
                      } else {
                        return <></>;
                      }
                    })}
                    {overtime.user_approval?.map((list, index) => {
                      if (!overtime.expanded) {
                        if (overtime.user_approval.length > 1) {
                          return (
                            <Typography key={index} className={classes.approvalList}>{list.name},&ensp;</Typography>
                          );
                        } else {
                          return (
                            <Typography key={index} className={classes.approvalList}>{`${list.name}`}</Typography>
                          );
                        }
                      } else {
                        return <></>;
                      }
                    })}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {overtime.master_approval.map((data, index) => {
                  return (
                    <Paper key={index} className={classes.paperCheckbox} variant='outlined'>
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <FormGroup>
                          <FormControlLabel
                            label={data.name}
                            control={
                              <Checkbox
                                checked={data.checked}
                                disabled={data.id === this.props.profile.user_id ? true : false}
                                onChange={(event) => {
                                  const {checked} = event.target;
                                  const {master_approval} = overtime;
                                  master_approval[index].checked = checked;
                                  this.setState({
                                    overtime: {
                                      ...overtime,
                                      master_approval,
                                    },
                                  });
                                }}
                                size='small'
                              />
                            }
                          />
                        </FormGroup>
                        <Typography className={classes.approvalType}>{'Master'}</Typography>
                      </div>
                    </Paper>
                  );
                })}
                <Divider style={{marginTop: 18, marginBottom: 16}}/>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 18}}>
                  <Typography className={classes.approvalTooltip}>Approval List</Typography>
                  <MuiThemeProvider theme={this.tooltipTheme()}>
                    <IconButton onClick={() => this.setState({modalGuideShow: !this.state.modalGuideShow})} style={{marginLeft: 4, bottom: 1}}>
                      <HelpOutline />
                    </IconButton>
                  </MuiThemeProvider>
                </div>
                <Autocomplete
                  style={{}}
                  multiple
                  onChange={(event, newValue) => {
                    if (overtime.user_approval.length < 1) {
                      const newData = [];
                      newData.push(newValue[0]);
                      this.setState({
                        overtime: {
                          ...overtime,
                          user_approval: newData,
                        },
                      });
                    } else {
                      const newData = overtime.user_approval;
                      overtime.user_approval.map((data) => {
                        if (data.id === newValue[0].id) {
                          toast('error', 'User already added');
                        } else {
                          newData.push(newValue[0]);
                        }
                      });

                      const filtered = newData.filter(
                        (obj, index) =>
                        newData.findIndex((item) => item.id === obj.id) === index,
                      );
                      this.setState({
                        overtime: {
                          ...overtime,
                          user_approval: filtered,
                        },
                      });
                    }
                  }}
                  id="controllable-states-demo"
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Search"
                      placeholder="Search keyword"
                    />
                  )}
                  options={userApprovalData}
                  getOptionLabel={(option) => option.name}
                  value={[]}
                  // isOptionEqualToValue={(option, value) => option.name === value.name}
                  disableClearable
                />
                <Typography className={classes.dragTextHelper}>Drag the items into the order you prefer</Typography>
                <div>
                  <Container
                    onDrop={(dropResult) => {
                      // set state the new list order
                      const {addedIndex, removedIndex} = dropResult;
                      const {user_approval} = overtime;
                      const ordered = arrayMoveImmutable(user_approval, removedIndex, addedIndex);
                      this.setState({
                        overtime: {
                          ...overtime,
                          user_approval: ordered,
                        },
                      });
                    }}
                  >
                    {overtime.user_approval.map((item, index) => {
                      return (
                        <Draggable key={index}>
                          <Paper className={classes.paperDrag} variant='outlined'>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <IconButton style={{marginRight: 8}}>
                                  <DragIndicator />
                                </IconButton>
                                <Typography className={classes.approvalUser}>{item.name} (Order {index+1})</Typography>
                              </div>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography className={classes.approvalType}>Approval</Typography>
                                <MuiThemeProvider theme={this.removeTheme()}>
                                  <IconButton onClick={() => {
                                    const {user_approval} = overtime;
                                    user_approval.splice(index, 1);
                                    this.setState({
                                      overtime: {
                                        ...overtime,
                                        user_approval,
                                      },
                                    });
                                  }} style={{marginLeft: 8}}>
                                    <Close color='danger'/>
                                  </IconButton>
                                </MuiThemeProvider>
                              </div>
                            </div>
                          </Paper>
                        </Draggable>
                      );
                    })}

                  </Container>
                </div>
              </AccordionDetails>
            </Accordion>
          </ThemeProvider>
      </>
    );
  }

  renderEditOrderPermit = () => {
    const {classes, userApprovalData} = this.props;
    const {permit} = this.state;

    return (
      <>
        <ThemeProvider theme={this.accordionTheme()}>
          <Accordion variant='outlined' expanded={permit.expanded}>
            <AccordionSummary
              expandIcon={
                <IconButton
                  onClick={() => this.setState({
                    permit: {
                      ...permit,
                      expanded: !permit.expanded,
                    },
                  })}
                >
                  <ExpandMoreIcon />
                </IconButton>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Typography className={classes.cardTitle}>{permit.feature_name}</Typography>
                  <Switch
                    size='small'
                    color='primary'
                    checked={permit.status}
                    onChange={() => {
                      this.setState({
                        permit: {
                          ...permit,
                          status: !permit.status,
                        },
                      });
                    }}
                  />
                </div>
                <div style={{display: 'flex', marginTop: !permit.expanded ? 8 : null, marginBottom: !permit.expanded ? 32 : null}}>
                  {permit.master_approval?.map((list, index) => {
                    if (!permit.expanded) {
                      if (list.checked) {
                        if (permit.master_approval.length > 1) {
                          return (
                            <Typography key={index} className={classes.approvalList}>{list.name},&ensp;</Typography>
                          );
                        } else {
                          return (
                            <Typography key={index} className={classes.approvalList}>{`${list.name},`}&ensp;</Typography>
                          );
                        }
                      }
                    } else {
                      return <></>;
                    }
                  })}
                  {permit.user_approval?.map((list, index) => {
                    if (!permit.expanded) {
                      if (permit.user_approval.length > 1) {
                        return (
                          <Typography key={index} className={classes.approvalList}>{list.name},&ensp;</Typography>
                        );
                      } else {
                        return (
                          <Typography key={index} className={classes.approvalList}>{`${list.name}`}</Typography>
                        );
                      }
                    } else {
                      return <></>;
                    }
                  })}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {permit.master_approval.map((data, index) => {
                return (
                  <Paper key={index} className={classes.paperCheckbox} variant='outlined'>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <FormGroup>
                        <FormControlLabel
                          label={data.name}
                          control={
                            <Checkbox
                              checked={data.checked}
                              disabled={data.id === this.props.profile.user_id ? true : false}
                              onChange={(event) => {
                                const {checked} = event.target;
                                const {master_approval} = permit;
                                master_approval[index].checked = checked;
                                this.setState({
                                  permit: {
                                    ...permit,
                                    master_approval,
                                  },
                                });
                              }}
                              size='small'
                            />
                          }
                        />
                      </FormGroup>
                      <Typography className={classes.approvalType}>{'Master'}</Typography>
                    </div>
                  </Paper>
                );
              })}
              <Divider style={{marginTop: 18, marginBottom: 16}}/>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: 18}}>
                <Typography className={classes.approvalTooltip}>Approval List</Typography>
                <MuiThemeProvider theme={this.tooltipTheme()}>
                  <IconButton onClick={() => this.setState({modalGuideShow: !this.state.modalGuideShow})} style={{marginLeft: 4, bottom: 1}}>
                    <HelpOutline />
                  </IconButton>
                </MuiThemeProvider>
              </div>
              <Autocomplete
                  style={{}}
                  multiple
                  onChange={(event, newValue) => {
                    if (permit.user_approval.length < 1) {
                      const newData = [];
                      newData.push(newValue[0]);
                      this.setState({
                        permit: {
                          ...permit,
                          user_approval: newData,
                        },
                      });
                    } else {
                      const newData = permit.user_approval;
                      permit.user_approval.map((data) => {
                        if (data.id === newValue[0].id) {
                          toast('error', 'User already added');
                        } else {
                          newData.push(newValue[0]);
                        }
                      });

                      const filtered = newData.filter(
                        (obj, index) =>
                        newData.findIndex((item) => item.id === obj.id) === index,
                      );
                      this.setState({
                        permit: {
                          ...permit,
                          user_approval: filtered,
                        },
                      });
                    }
                  }}
                  id="controllable-states-demo"
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Search"
                      placeholder="Search keyword"
                    />
                  )}
                  options={userApprovalData}
                  getOptionLabel={(option) => option.name}
                  value={[]}
                  // isOptionEqualToValue={(option, value) => option.name === value.name}
                  disableClearable
                />
              <Typography className={classes.dragTextHelper}>Drag the items into the order you prefer</Typography>
              <div>
                <Container
                  onDrop={(dropResult) => {
                    // set state the new list order
                    const {addedIndex, removedIndex} = dropResult;
                    const {user_approval} = permit;
                    const ordered = arrayMoveImmutable(user_approval, removedIndex, addedIndex);
                    this.setState({
                      permit: {
                        ...permit,
                        user_approval: ordered,
                      },
                    });
                  }}
                >
                  {permit.user_approval.map((item, index) => {
                    return (
                      <Draggable key={index}>
                        <Paper className={classes.paperDrag} variant='outlined'>
                          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <IconButton style={{marginRight: 8}}>
                                <DragIndicator />
                              </IconButton>
                              <Typography className={classes.approvalUser}>{item.name} (Order {index+1})</Typography>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <Typography className={classes.approvalType}>Approval</Typography>
                                <MuiThemeProvider theme={this.removeTheme()}>
                                  <IconButton onClick={() => {
                                    const {user_approval} = permit;
                                    user_approval.splice(index, 1);
                                    this.setState({
                                      permit: {
                                        ...permit,
                                        user_approval,
                                      },
                                    });
                                  }} style={{marginLeft: 8}}>
                                    <Close color='danger'/>
                                  </IconButton>
                              </MuiThemeProvider>
                            </div>
                          </div>
                        </Paper>
                      </Draggable>
                    );
                  })}

                </Container>
              </div>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
      </>
    );
  }

  renderEditOrderLeave = () => {
    const {classes, userApprovalData} = this.props;
    const {leave} = this.state;

    return (
      <>
        <ThemeProvider theme={this.accordionTheme()}>
          <Accordion variant='outlined' expanded={leave.expanded}>
            <AccordionSummary
              expandIcon={
                <IconButton
                  onClick={() => this.setState({
                    leave: {
                      ...this.state.leave,
                      expanded: !leave.expanded,
                    },
                  })}
                >
                  <ExpandMoreIcon />
                </IconButton>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Typography className={classes.cardTitle}>{leave.feature_name}</Typography>
                  <Switch
                    size='small'
                    color='primary'
                    checked={leave.status}
                    onChange={() => {
                      this.setState({
                        leave: {
                          ...leave,
                          status: !leave.status,
                        },
                      });
                    }}
                  />
                </div>
                <div style={{display: 'flex', marginTop: !leave.expanded ? 8 : null, marginBottom: !leave.expanded ? 32 : null}}>
                  {leave.master_approval?.map((list, index) => {
                    if (!leave.expanded) {
                      if (list.checked) {
                        if (leave.master_approval.length > 1) {
                          return (
                            <Typography key={index} className={classes.approvalList}>{list.name},&ensp;</Typography>
                          );
                        } else {
                          return (
                            <Typography key={index} className={classes.approvalList}>{`${list.name},`}&ensp;</Typography>
                          );
                        }
                      }
                    } else {
                      return <></>;
                    }
                  })}
                  {leave.user_approval?.map((list, index) => {
                    if (!leave.expanded) {
                      if (leave.user_approval.length > 1) {
                        return (
                          <Typography key={index} className={classes.approvalList}>{list.name},&ensp;</Typography>
                        );
                      } else {
                        return (
                          <Typography key={index} className={classes.approvalList}>{`${list.name}`}</Typography>
                        );
                      }
                    } else {
                      return <></>;
                    }
                  })}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {leave.master_approval.map((data, index) => {
                return (
                  <Paper key={index} className={classes.paperCheckbox} variant='outlined'>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <FormGroup>
                        <FormControlLabel
                          label={data.name}
                          control={
                            <Checkbox
                              checked={data.checked}
                              disabled={data.id === this.props.profile.user_id ? true : false}
                              onChange={(event) => {
                                const {checked} = event.target;
                                const {master_approval} = leave;
                                master_approval[index].checked = checked;
                                this.setState({
                                  leave: {
                                    ...leave,
                                    master_approval,
                                  },
                                });
                              }}
                              size='small'
                            />
                          }
                        />
                      </FormGroup>
                      <Typography className={classes.approvalType}>{'Master'}</Typography>
                    </div>
                  </Paper>
                );
              })}
              <Divider style={{marginTop: 18, marginBottom: 16}}/>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: 18}}>
                <Typography className={classes.approvalTooltip}>Approval List</Typography>
                <MuiThemeProvider theme={this.tooltipTheme()}>
                  <IconButton onClick={() => this.setState({modalGuideShow: !this.state.modalGuideShow})} style={{marginLeft: 4, bottom: 1}}>
                    <HelpOutline />
                  </IconButton>
                </MuiThemeProvider>
              </div>
              <Autocomplete
                  style={{}}
                  multiple
                  onChange={(event, newValue) => {
                    if (leave.user_approval.length < 1) {
                      const newData = [];
                      newData.push(newValue[0]);
                      this.setState({
                        leave: {
                          ...leave,
                          user_approval: newData,
                        },
                      });
                    } else {
                      const newData = leave.user_approval;
                      leave.user_approval.map((data) => {
                        if (data.id === newValue[0].id) {
                          toast('error', 'User already added');
                        } else {
                          newData.push(newValue[0]);
                        }
                      });

                      const filtered = newData.filter(
                        (obj, index) =>
                        newData.findIndex((item) => item.id === obj.id) === index,
                      );
                      this.setState({
                        leave: {
                          ...leave,
                          user_approval: filtered,
                        },
                      });
                    }
                  }}
                  id="controllable-states-demo"
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Search"
                      placeholder="Search keyword"
                    />
                  )}
                  options={userApprovalData}
                  getOptionLabel={(option) => option.name}
                  value={[]}
                  // isOptionEqualToValue={(option, value) => option.name === value.name}
                  disableClearable
                />
              <Typography className={classes.dragTextHelper}>Drag the items into the order you prefer</Typography>
              <div>
                <Container
                  onDrop={(dropResult) => {
                    // set state the new list order
                    const {addedIndex, removedIndex} = dropResult;
                    const {user_approval} = leave;
                    const ordered = arrayMoveImmutable(user_approval, removedIndex, addedIndex);
                    this.setState({
                      leave: {
                        ...leave,
                        user_approval: ordered,
                      },
                    });
                  }}
                >
                  {leave.user_approval.map((item, index) => {
                    return (
                      <Draggable key={index}>
                        <Paper className={classes.paperDrag} variant='outlined'>
                          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <IconButton style={{marginRight: 8}}>
                                <DragIndicator />
                              </IconButton>
                              <Typography className={classes.approvalUser}>{item.name} (Order {index+1})</Typography>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <Typography className={classes.approvalType}>Approval</Typography>
                                <MuiThemeProvider theme={this.removeTheme()}>
                                  <IconButton onClick={() => {
                                    const {user_approval} = leave;
                                    user_approval.splice(index, 1);
                                    this.setState({
                                      leave: {
                                        ...leave,
                                        user_approval,
                                      },
                                    });
                                  }} style={{marginLeft: 8}}>
                                    <Close color='danger'/>
                                  </IconButton>
                              </MuiThemeProvider>
                            </div>
                          </div>
                        </Paper>
                      </Draggable>
                    );
                  })}

                </Container>
              </div>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
      </>
    );
  }

  renderEditOrderReimburse = () => {
    const {classes, userApprovalData} = this.props;
    const {reimburse} = this.state;

    return (
      <>
        <ThemeProvider theme={this.accordionTheme()}>
          <Accordion variant='outlined' expanded={reimburse.expanded}>
            <AccordionSummary
              expandIcon={
                <IconButton
                  onClick={() => this.setState({
                    reimburse: {
                      ...this.state.reimburse,
                      expanded: !reimburse.expanded,
                    },
                  })}
                >
                  <ExpandMoreIcon />
                </IconButton>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Typography className={classes.cardTitle}>{reimburse.feature_name}</Typography>
                  <Switch
                    size='small'
                    color='primary'
                    checked={reimburse.status}
                    onChange={() => {
                      this.setState({
                        reimburse: {
                          ...reimburse,
                          status: !reimburse.status,
                        },
                      });
                    }}
                  />
                </div>
                <div style={{display: 'flex', marginTop: !reimburse.expanded ? 8 : null, marginBottom: !reimburse.expanded ? 32 : null}}>
                  {reimburse.master_approval?.map((list, index) => {
                    if (!reimburse.expanded) {
                      if (list.checked) {
                        if (reimburse.master_approval.length > 1) {
                          return (
                            <Typography key={index} className={classes.approvalList}>{list.name},&ensp;</Typography>
                          );
                        } else {
                          return (
                            <Typography key={index} className={classes.approvalList}>{`${list.name},`}&ensp;</Typography>
                          );
                        }
                      }
                    } else {
                      return <></>;
                    }
                  })}
                  {reimburse.user_approval?.map((list, index) => {
                    if (!reimburse.expanded) {
                      if (reimburse.user_approval.length > 1) {
                        return (
                          <Typography key={index} className={classes.approvalList}>{list.name},&ensp;</Typography>
                        );
                      } else {
                        return (
                          <Typography key={index} className={classes.approvalList}>{`${list.name}`}</Typography>
                        );
                      }
                    } else {
                      return <></>;
                    }
                  })}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {reimburse.master_approval.map((data, index) => {
                return (
                  <Paper key={index} className={classes.paperCheckbox} variant='outlined'>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <FormGroup>
                        <FormControlLabel
                          label={data.name}
                          control={
                            <Checkbox
                              checked={data.checked}
                              disabled={data.id === this.props.profile.user_id ? true : false}
                              onChange={(event) => {
                                const {checked} = event.target;
                                const {master_approval} = reimburse;
                                master_approval[index].checked = checked;
                                this.setState({
                                  reimburse: {
                                    ...reimburse,
                                    master_approval,
                                  },
                                });
                              }}
                              size='small'
                            />
                          }
                        />
                      </FormGroup>
                      <Typography className={classes.approvalType}>{'Master'}</Typography>
                    </div>
                  </Paper>
                );
              })}
              <Divider style={{marginTop: 18, marginBottom: 16}}/>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: 18}}>
                <Typography className={classes.approvalTooltip}>Approval List</Typography>
                <MuiThemeProvider theme={this.tooltipTheme()}>
                  <IconButton onClick={() => this.setState({modalGuideShow: !this.state.modalGuideShow})} style={{marginLeft: 4, bottom: 1}}>
                    <HelpOutline />
                  </IconButton>
                </MuiThemeProvider>
              </div>
              <Autocomplete
                  style={{}}
                  multiple
                  onChange={(event, newValue) => {
                    if (reimburse.user_approval.length < 1) {
                      const newData = [];
                      newData.push(newValue[0]);
                      this.setState({
                        reimburse: {
                          ...reimburse,
                          user_approval: newData,
                        },
                      });
                    } else {
                      const newData = reimburse.user_approval;
                      reimburse.user_approval.map((data) => {
                        if (data.id === newValue[0].id) {
                          toast('error', 'User already added');
                        } else {
                          newData.push(newValue[0]);
                        }
                      });

                      const filtered = newData.filter(
                        (obj, index) =>
                        newData.findIndex((item) => item.id === obj.id) === index,
                      );
                      this.setState({
                        reimburse: {
                          ...reimburse,
                          user_approval: filtered,
                        },
                      });
                    }
                  }}
                  id="controllable-states-demo"
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Search"
                      placeholder="Search keyword"
                    />
                  )}
                  options={userApprovalData}
                  getOptionLabel={(option) => option.name}
                  value={[]}
                  // isOptionEqualToValue={(option, value) => option.name === value.name}
                  disableClearable
                />
              <Typography className={classes.dragTextHelper}>Drag the items into the order you prefer</Typography>
              <div>
                <Container
                  onDrop={(dropResult) => {
                    // set state the new list order
                    const {addedIndex, removedIndex} = dropResult;
                    const {user_approval} = reimburse;
                    const ordered = arrayMoveImmutable(user_approval, removedIndex, addedIndex);
                    this.setState({
                      reimburse: {
                        ...reimburse,
                        user_approval: ordered,
                      },
                    });
                  }}
                >
                  {reimburse.user_approval.map((item, index) => {
                    return (
                      <Draggable key={index}>
                        <Paper className={classes.paperDrag} variant='outlined'>
                          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <IconButton style={{marginRight: 8}}>
                                <DragIndicator />
                              </IconButton>
                              <Typography className={classes.approvalUser}>{item.name} (Order {index+1})</Typography>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <Typography className={classes.approvalType}>Approval</Typography>
                                <MuiThemeProvider theme={this.removeTheme()}>
                                  <IconButton onClick={() => {
                                    const {user_approval} = reimburse;
                                    user_approval.splice(index, 1);
                                    this.setState({
                                      reimburse: {
                                        ...reimburse,
                                        user_approval,
                                      },
                                    });
                                  }} style={{marginLeft: 8}}>
                                    <Close color='danger'/>
                                  </IconButton>
                              </MuiThemeProvider>
                            </div>
                          </div>
                        </Paper>
                      </Draggable>
                    );
                  })}

                </Container>
              </div>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
      </>
    );
  }

  renderEditOrderEmployeeFacilities = () => {
    const {classes, userApprovalData} = this.props;
    const {employee_facilities} = this.state;

    return (
      <>
        <ThemeProvider theme={this.accordionTheme()}>
          <Accordion variant='outlined' expanded={employee_facilities.expanded}>
            <AccordionSummary
              expandIcon={
                <IconButton
                  onClick={() => this.setState({
                    employee_facilities: {
                      ...this.state.employee_facilities,
                      expanded: !employee_facilities.expanded,
                    },
                  })}
                >
                  <ExpandMoreIcon />
                </IconButton>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Typography className={classes.cardTitle}>{employee_facilities.feature_name}</Typography>
                  <Switch
                    size='small'
                    color='primary'
                    checked={employee_facilities.status}
                    onChange={() => {
                      this.setState({
                        employee_facilities: {
                          ...employee_facilities,
                          status: !employee_facilities.status,
                        },
                      });
                    }}
                  />
                </div>
                <div style={{display: 'flex', marginTop: !employee_facilities.expanded ? 8 : null, marginBottom: !employee_facilities.expanded ? 32 : null}}>
                  {employee_facilities.master_approval?.map((list, index) => {
                    if (!employee_facilities.expanded) {
                      if (list.checked) {
                        if (employee_facilities.master_approval.length > 1) {
                          return (
                            <Typography key={index} className={classes.approvalList}>{list.name},&ensp;</Typography>
                          );
                        } else {
                          return (
                            <Typography key={index} className={classes.approvalList}>{`${list.name},`}&ensp;</Typography>
                          );
                        }
                      }
                    } else {
                      return <></>;
                    }
                  })}
                  {employee_facilities.user_approval?.map((list, index) => {
                    if (!employee_facilities.expanded) {
                      if (employee_facilities.user_approval.length > 1) {
                        return (
                          <Typography key={index} className={classes.approvalList}>{list.name},&ensp;</Typography>
                        );
                      } else {
                        return (
                          <Typography key={index} className={classes.approvalList}>{`${list.name}`}</Typography>
                        );
                      }
                    } else {
                      return <></>;
                    }
                  })}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {employee_facilities.master_approval.map((data, index) => {
                return (
                  <Paper key={index} className={classes.paperCheckbox} variant='outlined'>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <FormGroup>
                        <FormControlLabel
                          label={data.name}
                          control={
                            <Checkbox
                              checked={data.checked}
                              disabled={data.id === this.props.profile.user_id ? true : false}
                              onChange={(event) => {
                                const {checked} = event.target;
                                const {master_approval} = employee_facilities;
                                master_approval[index].checked = checked;
                                this.setState({
                                  employee_facilities: {
                                    ...employee_facilities,
                                    master_approval,
                                  },
                                });
                              }}
                              size='small'
                            />
                          }
                        />
                      </FormGroup>
                      <Typography className={classes.approvalType}>{'Master'}</Typography>
                    </div>
                  </Paper>
                );
              })}
              <Divider style={{marginTop: 18, marginBottom: 16}}/>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: 18}}>
                <Typography className={classes.approvalTooltip}>Approval List</Typography>
                <MuiThemeProvider theme={this.tooltipTheme()}>
                  <IconButton onClick={() => this.setState({modalGuideShow: !this.state.modalGuideShow})} style={{marginLeft: 4, bottom: 1}}>
                    <HelpOutline />
                  </IconButton>
                </MuiThemeProvider>
              </div>
              <Autocomplete
                  style={{}}
                  multiple
                  onChange={(event, newValue) => {
                    if (employee_facilities.user_approval.length < 1) {
                      const newData = [];
                      newData.push(newValue[0]);
                      this.setState({
                        employee_facilities: {
                          ...employee_facilities,
                          user_approval: newData,
                        },
                      });
                    } else {
                      const newData = employee_facilities.user_approval;
                      employee_facilities.user_approval.map((data) => {
                        if (data.id === newValue[0].id) {
                          toast('error', 'User already added');
                        } else {
                          newData.push(newValue[0]);
                        }
                      });

                      const filtered = newData.filter(
                        (obj, index) =>
                        newData.findIndex((item) => item.id === obj.id) === index,
                      );
                      this.setState({
                        employee_facilities: {
                          ...employee_facilities,
                          user_approval: filtered,
                        },
                      });
                    }
                  }}
                  id="controllable-states-demo"
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Search"
                      placeholder="Search keyword"
                    />
                  )}
                  options={userApprovalData}
                  getOptionLabel={(option) => option.name}
                  value={[]}
                  // isOptionEqualToValue={(option, value) => option.name === value.name}
                  disableClearable
                />
              <Typography className={classes.dragTextHelper}>Drag the items into the order you prefer</Typography>
              <div>
                <Container
                  onDrop={(dropResult) => {
                    // set state the new list order
                    const {addedIndex, removedIndex} = dropResult;
                    const {user_approval} = employee_facilities;
                    const ordered = arrayMoveImmutable(user_approval, removedIndex, addedIndex);
                    this.setState({
                      employee_facilities: {
                        ...employee_facilities,
                        user_approval: ordered,
                      },
                    });
                  }}
                >
                  {employee_facilities.user_approval.map((item, index) => {
                    return (
                      <Draggable key={index}>
                        <Paper className={classes.paperDrag} variant='outlined'>
                          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <IconButton style={{marginRight: 8}}>
                                <DragIndicator />
                              </IconButton>
                              <Typography className={classes.approvalUser}>{item.name} (Order {index+1})</Typography>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <Typography className={classes.approvalType}>Approval</Typography>
                                <MuiThemeProvider theme={this.removeTheme()}>
                                  <IconButton onClick={() => {
                                    const {user_approval} = employee_facilities;
                                    user_approval.splice(index, 1);
                                    this.setState({
                                      employee_facilities: {
                                        ...employee_facilities,
                                        user_approval,
                                      },
                                    });
                                  }} style={{marginLeft: 8}}>
                                    <Close color='danger'/>
                                  </IconButton>
                              </MuiThemeProvider>
                            </div>
                          </div>
                        </Paper>
                      </Draggable>
                    );
                  })}

                </Container>
              </div>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
      </>
    );
  }

  renderModalGuide = () => {
    const {classes} = this.props;
    const {modalGuideShow, file} = this.state;

    const guideList= [
      {
        title: 'Master',
        description: 'Adalah pengguna yang memiliki hak untuk melakukan approval dari berbagai divisi. Type ini dapat terdiri dari beberapa pengguna. Master berlaku sebagai level approval terakhir. HR akan otomatis berlaku sebagai Master, dan Master dapat terdiri dari beberapa pengguna. Posisi Master akan selalu berada di setiap divisi dan juga feature. Untuk menonaktifkan notifikasi Anda cukup menonaktifkan checklist.',
      },
      {
        title: 'Approval',
        description: `Adalah pengguna yang hanya memiliki hak untuk mendapatkan notifikasi dan melakukan approval dari satu divisi saja. Type ini dapat terdiri dari beberapa pengguna. Anda akan melihat daftar approval berdasarkan divisi pada kolom search. Selanjutnya Anda dapat melakukan double click untuk memilih, dan melakukan drag and drop untuk mengatur urutan.`,
      },
    ];

    const orderList= [
      {
        title: 'Level 1',
        description: 'Merupakan pengguna yang terakhir melakukan approval ketika terdapat pengajuan.',
      },
      {
        title: 'Level 2',
        description: `Merupakan pengguna yang dapat melakukan approval setelah level 3 melakukan approval.`,
      },
      {
        title: 'Level 3-10',
        description: `Urutan dari approval ini diurutkan dari level yang terbesar ke terkecil. Contoh, Level 9 akan melakukan approval setelah level 10 melakukan approval. Begitu seterusnya hingga level 1.`,
      },
    ];

    return (
      <Dialog
        open={modalGuideShow}
        onClose={() => this.setState({modalGuideShow: false})}
        fullWidth
        maxWidth={'sm'}
      >
        <ModalTitle
          title={'Information'}
          onClose={() => this.setState({modalGuideShow: false})}
          style={{color: '#656464', fontSize: 20, fontWeight: '600'}}
        />
        <form onSubmit={this.handleSubmitImport} autoComplete="off">
          <DialogContent className={classes.dialogContentGuide}>
            <Typography className={classes.ApprovalTypeGuide} >Approval Type</Typography>
            {guideList.map((value, index) => {
              return (
                <div key={index} style={{marginBottom: 18}}>
                  <div style={{display: 'flex'}}>
                    <Typography className={classes.titleGuide} >•&nbsp;</Typography>
                    <Typography className={classes.titleGuide} >{value.title}</Typography>
                  </div>
                  <div style={{display: 'flex', marginTop: 8}}>
                    <Typography className={classes.subtitleGuide} style={{opacity: 0}}>•&nbsp;</Typography>
                    <Typography className={classes.subtitleGuide}>{value.description}</Typography>
                  </div>
                </div>
              );
            })}
            <Typography className={classes.ApprovalOrderGuide} >Approval Order</Typography>
            {orderList.map((value, index) => {
              return (
                <div key={index} style={{marginBottom: 18}}>
                  <div style={{display: 'flex'}}>
                    <Typography className={classes.titleGuide} >•&nbsp;</Typography>
                    <Typography className={classes.titleGuide} >{value.title}</Typography>
                  </div>
                  <div style={{display: 'flex', marginTop: 8}}>
                    <Typography className={classes.subtitleGuide} style={{opacity: 0}}>•&nbsp;</Typography>
                    <Typography className={classes.subtitleGuide}>{value.description}</Typography>
                  </div>
                </div>
              );
            })}
            <div style={{marginTop: 24, marginBottom: 12}}>
              <Typography className={classes.titleGuide} >Contoh:</Typography>
              <Typography className={classes.subtitleGuide}>Level 1 Leader</Typography>
              <Typography className={classes.subtitleGuide}>Level 2 Supervisor</Typography>
              <Typography className={classes.subtitleGuide}>Level 3 Manager</Typography>
              <Typography className={classes.subtitleGuide}>Level 4 HR</Typography>
            </div>
            {/* <Typography className={classes.subtitleGuide} style={{marginTop: 32, marginBottom: 32}}>
              Anda dapat mengatur lebih lanjut selengkapnya di fitur User Management kemudian pilih nama yang ingin Anda perbarui, lalu klik ikon <strong>‘update’</strong>.
            </Typography> */}
          </DialogContent>
        </form>
      </Dialog>
    );
  }

  render() {
    const {classes, isLoading} = this.props;
    return (
      <div className={classes.root}>
        {this.renderNavigation()}
        {this.renderDivisionCard()}
        <Typography className={classes.orderList}>Order List</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {this.renderEditOrderOvertime()}
          </Grid>
          <Grid item xs={6}>
            {this.renderEditOrderPermit()}
          </Grid>
          <Grid item xs={6}>
            {this.renderEditOrderLeave()}
          </Grid>
          <Grid item xs={6}>
            {this.renderEditOrderReimburse()}
          </Grid>
          <Grid item xs={6}>
            {this.renderEditOrderEmployeeFacilities()}
          </Grid>
        </Grid>
        {this.renderModalGuide()}
      </div>
    );
  }
}

const mapStateToProps = ({approvalOrder, auth}) => {
  return {
    auth: auth,
    isLoading: approvalOrder.isLoading,
    isError: approvalOrder.isError,
    isSuccess: approvalOrder.isSuccess,
    message: approvalOrder.message,
    trigger: approvalOrder.trigger,
    approvalOrderData: approvalOrder.aprovalOrderData,
    approvalOrderMeta: approvalOrder.aprovalOrderMeta,
    approvalOrderDetail: approvalOrder.approvalOrderDetail,
    masterApprovalData: approvalOrder.masterApproval,
    userApprovalData: approvalOrder.userApproval,
    profile: auth.data.profile,
  };
};

const dispatchToProps = (dispatch) => ({
  updateApprovalOrder: (id, params) => dispatch(updateApprovalOrder(id, params)),
  getDetailApproval: (id, params) => dispatch(getDetailApproval(id, params)),
  getMasterApproval: (params) => dispatch(getMasterApproval(params)),
  getUserApproval: (params) => dispatch(getUserApproval(params)),

  getProfile: (params) => dispatch(getProfile(params)),
});

export default connect(
    mapStateToProps,
    dispatchToProps,
)(withStyles(styles)(EditApproval));

/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, ThemeProvider} from '@material-ui/core/styles';
import styles from './styles';
import {
  Button,
  Grid,
  Avatar,
  Divider,
  Paper,
  CardMedia,
  Typography,
  Link,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {
  CheckCircleOutline,
  Search,
  SaveAlt,
  Refresh,
  WarningOutlined,
} from '@material-ui/icons';
import {toast, isObject, convDate} from '../../../helpers';
import {MInput, MKeyBoardDatepicker, MButton, ModalTitle, MSelect} from '../../../components/form';
import {Datatable} from '../../../components';
import _ from 'lodash';
import moment from 'moment';
import {ATTENDANCE_STATE as STATE} from '../../../redux/attendanceHistory/attendance.types';
import {getOrgAnalyticsDetails, getOrganizationAnalytics} from '../../../redux/organization/organization.actions';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  Chart as ChartJS,
} from 'chart.js/auto';
import {Line} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {checkSuperadmin} from '../../../helpers';
import svgEmployee from '../../../assets/img/dashboard/employee.svg';

const yearPickerTheme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: 14,
      },
    },
    MuiFormControl: {
      root: {
        maxWidth: 90,
      },
    },
  },
});

class OrganizationAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
      showDetail: false,
      resetDatatable: null,
      detailData: null,
      resetPage: null,
      errorMessage: null,
      id: null,
    };
  }

  UNSAFE_componentWillMount() {
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, exportData, message} = nextProps;

    if (trigger === STATE.EXPORT_SUCCESS && isSuccess && exportData) {
      toast('success', 'File successfully exported & downloaded');
    }

    if (isError && message && isObject(message)) this.setState({errorMessage: message});
    if (isError && message && !isObject(message)) toast('error', message);
  }

  componentDidMount() {
    this.handleCheckQueryParam();
  }

  fetchOrganizationAnalytics = () => {
    const {id, startDate} = this.state;

    this.props.getOrganizationAnalytics(
      id,
      {
        ...(startDate && {date: startDate}),
      },
      );
  }

  fetchOrgAnalytics = () => {
    const {page, limit, startDate, endDate, query, sortBy, order, method, completed, year, month, id} = this.state;
    this.props.getOrgAnalyticsDetails(
      id,
      {
      ...(page && {page}),
      ...(limit && {limit}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
    });
  }

  handleCheckQueryParam = () => {
    const queryParam = this.props.location.search.substring(1);
    if (queryParam) {
      const searchParam = new URLSearchParams(queryParam);
      const id = searchParam.get('id');
      const startDate = searchParam.get('startDate');
      const endDate = searchParam.get('endDate');

      this.setState({
        ...(id && {id}),
        ...(startDate && {startDate}),
        ...(endDate && {endDate}),
      },
      () => {
        this.fetchOrgAnalytics();
        this.fetchOrganizationAnalytics();
      });
    } else {
      this.fetchOrgAnalytics();
      this.fetchOrganizationAnalytics();
    }
  }

  toggleModal = (type, data) => {
    const {attendanceData} = this.props;
    if (type === 'detail') {
      const numberId = data.rowData[0]; // get detail attendance from datatable response, based on index
      const selected = attendanceData.filter((data) => data.no === numberId);
      const detailData = selected && selected.length ? selected[0] : null;
      this.setState({showDetail: true, detailData});
    } else if (type === 'close') {
      this.setState({showDetail: false, detailData: null});
    }
  };

  toggleResetDatatable = (status) => {
    this.setState(
        {resetDatatable: status},
        () => this.setState({resetDatatable: null}),
    );
  }

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };


  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      sortBy: null,
      order: 'desc',
      detailData: null,
      startDate: null,
      endDate: null,
    };
    this.setState(state, () => this.fetchOrgAnalytics());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  }

  handleReload = (params) => {
    this.setState(
        params,
        () => this.fetchOrgAnalytics(),
    );
  }

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  }

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState(
        {page: null},
        () => this.fetchOrgAnalytics(),
    );
  }, 500);

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState(
        {[name]: value, page: null},
        () => this.fetchOrgAnalytics(),
    );
    this.toggleResetPage();
    this.toggleResetDatatable(true);
  }

  handleChartDateChange = (params) => {
    const {value} = params;
    this.setState(
        {startDate: value, endDate: value, page: null},
        () => this.fetchOrgAnalytics(),
    );
    this.toggleResetPage();
    this.toggleResetDatatable(true);
  }

  renderLoader = (size = 15) => {
    const {classes} = this.props;
    return <CircularProgress className={classes.loaderColor} size={size} />;
  };

  renderFilter = () => {
    const {classes, exportLoading, attendanceTypes, message, exportData, isSuccess} = this.props;
    const {query, startDate, endDate} = this.state;

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={2} alignItems='center' direction='row'>
          <Grid item>
            <MKeyBoardDatepicker
              variant="outlined"
              className={classes.filterDatePicker}
              name="startDate"
              label="Start Date"
              value={startDate}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              maxDate={endDate ? new Date(endDate) : undefined}
              error={message && message.start_date ? message.start_date : undefined}
            />
          </Grid>
          <Grid item>
            <MKeyBoardDatepicker
              variant="outlined"
              className={classes.filterDatePicker}
              name="endDate"
              label="End Date"
              value={endDate}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              minDate={new Date(startDate)}
              error={message && message.end_date ? message.end_date : undefined}
            />
          </Grid>
          <Grid item>
            <div className={classes.btnFilterContainer}>
              <MButton
                className={classes.filterBtn}
                label="Reset"
                color="primary"
                icon={<Refresh/>}
                onClick={this.handleReset}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  renderModalDetail = () => {
    const {classes} = this.props;
    const {showDetail, detailData} = this.state;

    const name = detailData && detailData.name ? detailData.name : '-';
    const date = detailData && detailData.date ? convDate(detailData.date, 'D MMMM YYYY') : '-';
    const checkIn = detailData && detailData.check_in ? detailData.check_in : '-';
    const checkInImg = detailData && detailData.check_in_img ? detailData.check_in_img : <Avatar alt='user avatar' />;
    const checkOut = detailData && detailData.check_out ? detailData.check_out : '-';
    const checkOutImg = detailData && detailData.check_out_img ? detailData.check_out_img : <Avatar alt='user avatar' />;
    const checkInAddress = detailData && detailData.check_in_address ? detailData.check_in_address : '-';
    const checkOutAddress = detailData && detailData.check_out_address ? detailData.check_out_address : '-';
    const todo = detailData && detailData.todo ? detailData.todo : '-';
    const day = detailData && detailData.day ? detailData.day : '-';
    const method = detailData && detailData.method ? detailData.method : null;
    const break_in = detailData && detailData.break_in ? detailData.break_in : null;
    const break_out = detailData && detailData.break_out ? detailData.break_out : null;
    const approvedBy =
      detailData && detailData.approved_by ? detailData.approved_by : '-';
    const columns = ['Check In', 'Address', 'Todo', 'Photo'];
    const checkInLocation = detailData && detailData.check_in_location ? detailData.check_in_location : null;
    const checkOutLocation = detailData && detailData.check_out_location ? detailData.check_out_location : null;

    return (
      <Dialog
        open={showDetail}
        onClose={() => this.toggleModal('close')}
        maxWidth='md'
        fullWidth={true}
        scroll='paper'
        PaperProps={{
          style: {borderRadius: 15, paddingTop: 20, paddingBottom: 20},
        }}
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Attendance Detail"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={2}><Typography>Name </Typography></Grid>
            <Grid item><Typography> : </Typography></Grid>
            <Grid item xs zeroMinWidth><Typography>{name}</Typography></Grid>
          </Grid>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={2}><Typography>Day </Typography></Grid>
            <Grid item><Typography> : </Typography></Grid>
            <Grid item xs zeroMinWidth><Typography>{day}</Typography></Grid>
          </Grid>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={2}><Typography>Date </Typography></Grid>
            <Grid item><Typography> : </Typography></Grid>
            <Grid item xs zeroMinWidth><Typography>{convDate(date, 'D MMMM YYYY')} </Typography></Grid>
          </Grid>

          {
            method !== 'business' ?
            (
              <div>
                {method === 'remote' ? (<Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs={2}><Typography>Todo</Typography></Grid>
                  <Grid item><Typography> : </Typography></Grid>
                  <Grid item xs zeroMinWidth><Typography> <pre style={{margin: 0}}>{todo}</pre></Typography></Grid>
                </Grid>) : null}
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs={2}><Typography>Checkin Location</Typography></Grid>
                  <Grid item><Typography> : </Typography></Grid>
                  {checkInLocation ? <Grid item><Typography>{checkInLocation}</Typography></Grid> : null}
                  <Grid item xs zeroMinWidth><Typography>{checkInAddress}</Typography></Grid>
                </Grid>
                <Grid container wrap="nowrap" spacing={2} mb={5}>
                  <Grid item xs={2}><Typography>Checkout Location</Typography></Grid>
                  <Grid item><Typography> : </Typography></Grid>
                  {checkOutLocation ? <Grid item><Typography>{checkOutLocation}</Typography></Grid> : null}
                  <Grid item xs zeroMinWidth><Typography>{checkOutAddress}</Typography></Grid>
                </Grid>
                {method === 'office' ? (
                  <>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item xs={2}><Typography>Check In</Typography></Grid>
                      <Grid item><Typography> : </Typography></Grid>
                      <Grid item xs zeroMinWidth><Typography>{checkIn}</Typography></Grid>
                    </Grid>
                    <Grid container wrap="nowrap" spacing={2} mb={5}>
                      <Grid item xs={2}><Typography>Check Out</Typography></Grid>
                      <Grid item><Typography> : </Typography></Grid>
                      <Grid item xs zeroMinWidth><Typography>{checkOut}</Typography></Grid>
                    </Grid>
                  </>
                ) : null}
                {method !== 'overtime' ? (
                <>
                  {
                    break_in || break_out ? (
                      <>
                        <Grid container wrap="nowrap" spacing={2}>
                          <Grid item xs={2}><Typography>Break In</Typography></Grid>
                          <Grid item><Typography> : </Typography></Grid>
                          <Grid item xs zeroMinWidth><Typography>{break_in ? break_in : '-'}</Typography></Grid>
                        </Grid>
                        <Grid container wrap="nowrap" spacing={2} mb={5}>
                          <Grid item xs={2}><Typography>Break Out</Typography></Grid>
                          <Grid item><Typography> : </Typography></Grid>
                          <Grid item xs zeroMinWidth><Typography>{break_out ? break_out : '-'}</Typography></Grid>
                        </Grid>
                      </>
                    ) : null
                  }
                </>
                ): (
                  <Grid container wrap="nowrap" spacing={2} mb={5}>
                    <Grid item xs={2}><Typography>Approved By</Typography></Grid>
                    <Grid item><Typography> : </Typography></Grid>
                    <Grid item xs zeroMinWidth><Typography>{approvedBy}</Typography></Grid>
                  </Grid>
                )}

              </div>
            ) : ''
          }
          <br />
          <Divider />
          {
            method === 'business' ?
            (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {
                        columns.map((name, index) => (
                          <TableCell key={index.toString()}>{name}</TableCell>
                        ))
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      detailData.data.map((row) => (
                        <TableRow key={row.check_in_id}>
                          <TableCell component="th" scope="row">{row.check_in}</TableCell>
                          <TableCell component="th" scope="row">{row.check_in_address}</TableCell>
                          <TableCell component="th" scope="row">{row.todo}</TableCell>
                          <TableCell align="right">
                            <Link href={row.check_in_img} target="_blank" rel="noopener noreferrer">
                              <CardMedia
                                className={classes.tableImg}
                                alt="check in picture"
                                image={row.check_in_img}
                                component='image'
                              />
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null }
          { method === 'remote' || method ==='overtime' ?
            (
              <Grid container spacing={3} align="center">
                <Grid item xs={6}>
                  <Typography
                    className={classes.modalItemSpacing}
                  >
                    Check In
                  </Typography>
                  <Link href={checkInImg} target="_blank" rel="noopener noreferrer">
                    <CardMedia
                      className={[classes.modalAvatar, classes.imgSize]}
                      alt="check in picture"
                      image={checkInImg}
                      title='Check In'
                      component='image'
                    />
                  </Link>
                  <br />
                  <Grid container wrap="nowrap" spacing={2} align="center">
                    <Grid item xs={12}>
                      <Typography>Time &nbsp; : &nbsp; {checkIn ? checkIn : '-'} </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={classes.modalItemSpacing}
                  >
                    Check Out
                  </Typography>
                  <Link href={checkOutImg} target="_blank" rel="noopener noreferrer">
                    <CardMedia
                      className={[classes.modalAvatar, classes.imgSize]}
                      alt="check out picture"
                      image={checkOutImg}
                      title='Check Out'
                      component='image'
                    />
                  </Link>
                  <br />
                  <Grid container spacing={2} >
                    <Grid item xs={12}>
                      <Typography>Time &nbsp; : &nbsp; {checkOut ? checkOut : '-'} </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : null
          }
        </DialogContent>
      </Dialog>
    );
  }

  renderSmallCards = () => {
    const {classes, isLoading, analyticsOrg} = this.props;
    const contents = [
      {
        logo: svgEmployee,
        title: 'Last Active User',
        total: analyticsOrg && analyticsOrg.total_user,
        active: analyticsOrg && analyticsOrg.last_active_user,
      },
    ];

    const items = contents.map((value, index) => {
      return (
        <Grid item key={index.toString()} lg={3} xs={6}>
          <Paper className={classes.paper}>
            <Grid container wrap="nowrap" className={classes.iconPaddingSingle}>
              <Grid item xs={3}>
                <img src={value.logo} alt="img" style={{width: '100%'}} />
              </Grid>
              <Grid item xs={12} className={classes.marginLeftEmp}>
                {isLoading ? (
                  this.renderLoader(20)
                ) : (
                  <Typography className={classes.resultFontEmp}>
                    {value.active && value.active}{' / '}{value.total && value.total}
                  </Typography>
                )}
                <Typography>{value.title}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    });

    return (
      <Grid container wrap="nowrap" spacing={2}>
        {items}
      </Grid>
    );
  };

  renderLastWeekSummary = () => {
    const {
      classes,
      isLoading,
      analyticsOrg,
    } = this.props;

    const labels = analyticsOrg && analyticsOrg.lw_active_user.map((val) => moment(val.date).format('DD MMM YYYY'));
    const datas = analyticsOrg && analyticsOrg.lw_active_user.map((val) => val.active_user);

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          right: 100,
          top: 20,
        },
      },
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
        title: {
          display: false,
          text: 'Payment Summary',
        },
        datalabels: {
          display: true,
          align: '-45',
          listeners: {
            click: (context) => {
              const ind = context.dataIndex;
              const params = {value: moment(labels[ind])._d};
              this.handleChartDateChange(params);
            },
            enter: function(context) {
              context.hovered = true;
              return true;
            },
            leave: function(context) {
              context.hovered = false;
              return true;
            },
          },
          color: function(context) {
            // Change the label text color based on our new `hovered` context value.
            return context.hovered ? '#3f51b5' : '#00AFF0';
          },
        },
      },
      onClick: (e, element) => {
        if (element.length > 0) {
          const ind = element[0].index;
          const params = {value: moment(labels[ind])._d};
          this.handleChartDateChange(params);
        }
      },
      scales: {
        y: {
          min: 0,
          display: true,
          ticks: {
            display: true,
            stepSize: 1,
          },
        },
      },
    };

    const chartData = {
      labels,
      datasets: [
        {
          label: 'Dataset 2',
          data: datas,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };

    return (
      <div>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container justify='center' wrap="nowrap" className={classes.iconPaddingMulti}>
              <Grid item xs={6}>
                <Typography align='center' style={{fontWeight: 700, fontSize: 18}} >Activity Summary from Last Week</Typography>
              </Grid>
              </Grid>
              <Grid container wrap="nowrap" style={{minHeight: '30vh'}}>
                <Line options={chartOptions} data={chartData} plugins={[ChartDataLabels]}/>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    const {classes, analyticsDetails, analyticsMeta, isLoading, role} = this.props;
    const {resetDatatable, resetPage} = this.state;
    const columns = [
      {name: 'no', label: 'No', display: true},
      {name: 'employees.name', label: 'User', display: true, customBodyRender: (value) => value ? value : '-'},
      {name: 'employees.id', label: 'User Id', display: false, customBodyRender: (value) => value ? value : '-'},
      {name: 'attd_method', label: 'Attendance Method', display: true, customBodyRender: (value) => value ? value : '-'},
      {name: 'attd_type', label: 'Attendance Type', display: true, customBodyRender: (value) => value ? value : '-'},
      {
        name: 'date',
        label: 'Activity Date',
        display: true,
        customBodyRender: (value) => {
          if (value) {
            return moment(value).format('DD MMMM YYYY');
          } else {
            return '-';
          }
        },
      },
      {
        name: 'attd_time',
        label: 'Time',
        display: true,
        customBodyRender: (value) => {
          if (value) {
            return value;
          } else {
            return '-';
          }
        },
      },
    ];

    return role === 'Super Admin' && (
      <div className={classes.root}>
        {this.renderSmallCards()}
        {this.renderLastWeekSummary()}
        {this.renderFilter()}
        <Datatable
          creatable={false}
          title={'Organization Activity Details'}
          loading={isLoading}
          dataSource={analyticsDetails}
          total={analyticsMeta.total}
          page={analyticsMeta.page}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          // handleDetail={(params) => this.toggleModal('detail', params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
          hideActions={true}
        />
        {this.renderModalDetail()}
      </div>
    );
  }
}

const mapStateToProps = ({organization, auth}) => ({
  isLoading: organization.isLoading,
  isSuccess: organization.isSuccess,
  isError: organization.isError,
  message: organization.message,
  analyticsDetails: organization.analyticsDetails,
  analyticsMeta: organization.analyticsMeta,
  analyticsOrg: organization.analyticsOrg,
  isSuperAdmin: checkSuperadmin(auth),
  role: auth.data.profile.role,
});

const mapDispatchToProps = (dispatch) => ({
  getOrgAnalyticsDetails: (id, params) => dispatch(getOrgAnalyticsDetails(id, params)),
  getOrganizationAnalytics: (id, params) => dispatch(getOrganizationAnalytics(id, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrganizationAnalytics));

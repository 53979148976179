/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import styles from './styles';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Button,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Switch,
} from '@material-ui/core';
import {
  Search,
  Refresh,
  Send,
  Edit,
  Delete,
} from '@material-ui/icons';
import {toast, convDate, checkSuperadmin, showPopup, isObject} from '../../../helpers';
import {
  MInput,
  MButton,
  ModalTitle,
  MDatepicker,
  MAutoComplete,
  MInputMask,
} from '../../../components/form';
import {Datatable} from '../../../components';
import {checkStatus} from '../../../redux/organization/organization.actions';
import {ReactComponent as ResendEmail} from '../../../assets/icon/partner/resend.svg';
import {
  getPartner,
  getOnePartner,
  createPartner,
  deletePartner,
  updatePartner,
  togglePartner,
  resendEmail,
  clearState,
} from '../../../redux/partner/partner.actions';
import {PARTNER_STATE as STATE} from '../../../redux/partner/partner.types';


import _ from 'lodash';

class Partner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      modalFormShow: false,
      query: null,
      startDate: null,
      endDate: null,
      no_activity: false,
      accountActivation: false,
      showDetail: false,
      showReject: false,
      resetDatatable: null,
      detailData: null,
      resetPage: null,
      status: 'waiting',
      reason: '',
      selectedStatus: 'waiting',
      selected: null,
      formData: {
        partner_name: null,
        email: null,
        number_phone: null,
        bank_name: null,
        bank_account_number: null,
        bank_account_name: null,
        address: null,
        partner_fee: null,
      },
      inputValue: null,
      errorMessage: null,
      order: 'desc',
      sortBy: null,
      isEdit: false,
      partnerDataById: [],
    };
  }

  switchTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  });


  UNSAFE_componentWillMount() {
    this.redirectDashboardIfChild();
    // this.props.getOnePartner();
    this.fetchPartner();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message} = nextProps;

    if (isSuccess) {
      if (
        trigger === STATE.CREATE_SUCCESS ||
        trigger === STATE.UPDATE_SUCCESS ||
        trigger === STATE.DROP_SUCCESS ||
        trigger === STATE.TOGGLE_SUCCESS
      ) {
        this.handleResetFilter();
        this.setState({
          modalFormShow: false,
        });
        toast('success', message);
      }

      if (isSuccess === false) {
        if (
          trigger === STATE.TOGGLE_FAILED
        ) {
          // console.log(message);
          toast('success', message);
          // toast('success', message);
        }
      }

      if (
        trigger === STATE.RESEND_EMAIL_SUCCCESS
      ) {
        this.handleResetFilter();
        toast('success', 'Email activation has been sent!');
        // toast('success', message);
      }

      if (trigger === STATE.FETCH_BY_ID_SUCCCESS) {
        const {partnerDataById} = nextProps;
        this.handleReset();
        this.setState({
          isEdit: true,
          modalFormShow: !this.state.modalFormShow,
          formData: {
            id: partnerDataById?.id,
            partner_name: partnerDataById?.partner_name,
            email: partnerDataById?.email,
            bank_name: partnerDataById?.bank_name,
            number_phone: partnerDataById?.number_phone,
            bank_account_number: partnerDataById?.bank_account_number,
            bank_account_name: partnerDataById?.bank_account_name,
            address: partnerDataById?.address,
            partner_fee: partnerDataById?.partner_fee,
          },
        });
      }
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }


  handleResetForm = (closeModal) => {
    this.setState({
      formData: {
        id: null,
        name: null,
      },
      errorMessage: null,
    });
    if (closeModal) this.setState({showModal: false});
  };


  redirectDashboardIfChild = () => {
    const {isSuperAdmin, auth} = this.props;

    if (!isSuperAdmin) {
      if (auth.data.organization.is_child) {
        this.props.history.push('/dashboard');
      } else {
        // if(!userFeature.tenant_management){
        //   this.props.history.push('/dashboard');
        // }
      }
    }
  };

  fetchPartner = () => {
    const {isSuperAdmin} = this.props;

    if (isSuperAdmin) {
      const {
        page,
        limit,
        query,
        order,
        sortBy,
        startDate,
        endDate,
        no_activity,
      } = this.state;
      this.props.getPartner({
        page,
        limit,
        query,
        order,
        sortBy,
        startDate: no_activity ? null : convDate(startDate),
        endDate: no_activity ? null : convDate(endDate),
      });
    }
  }

  handleReload = (params) => {
    this.setState(params, () => this.fetchPartner());
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };


  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  };

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: 1}, () => this.fetchPartner());
  }, 1000);

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState({[name]: value, page: null}, () =>
      this.fetchPartner(),
    );
    this.toggleResetPage();
    this.toggleResetDatatable(true);
  };

  handleCheckboxFilter = (event, params) => {
    // const {formData, errorMessage} = this.state;
    const {name, checked} = event.target;
    this.setState(
        {
          [name]: checked,
          page: 1,
          startDate: name === 'no_activity' && null,
          endDate: name === 'no_activity' && null,
        },
        () => {
          this.toggleResetPage();
          this.fetchPartner();
        },
    );
  };


  handleResetFilter = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      order: 'desc',
      startDate: null,
      endDate: null,
      sortBy: null,
      no_activity: false,
    };
    this.setState(state, () => this.fetchPartner());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  };

  renderFilter = () => {
    const {classes, message} = this.props;
    const {query, startDate, endDate, no_activity} = this.state;

    return (
      <Paper variant='outlined' className={classes.paper}>
        <Grid container xs={12} spacing={2}>
          <MInput
            autoFocus
            className={`${classes.filter} ${classes.searchInput}`}
            fullWidth={false}
            variant="outlined"
            name="query"
            label="Search"
            placeholder="Enter search keyword"
            value={query}
            onChange={this.handleSearch}
            InputProps={{
              endAdornment: (
                <IconButton className={classes.searchIcon} position="end">
                  {' '}
                  <Search />
                </IconButton>
              ),
            }}
          />
          <MDatepicker
            fullWidth={false}
            className={`${classes.filter} ${classes.formControl} ${classes.searchDate}`}
            inputVariant="outlined"
            name="startDate"
            label="Start Date"
            value={startDate}
            onDateChange={this.handleFilterDateChange}
            placeholder="dd-mm-yyyy"
            maxDate={endDate ? new Date(endDate) : undefined}
            error={
              message && message.start_date ? message.start_date : undefined
            }
            disabled={no_activity}
          />
          <MDatepicker
            className={`${classes.filter} ${classes.formControl} ${classes.searchDate}`}
            inputVariant="outlined"
            name="endDate"
            label="End Date"
            value={endDate}
            onDateChange={this.handleFilterDateChange}
            placeholder="dd-mm-yyyy"
            minDate={new Date(startDate)}
            error={message && message.end_date ? message.end_date : undefined}
            disabled={no_activity}
          />
          {/* <MCheckboxItem
            classNameFC={classes.checkBoxFilter}
            name="no_activity"
            label="No Activity"
            onChange={this.handleCheckboxFilter}
            checked={no_activity}
          /> */}
          <div className={classes.btnFilterContainer}>
            <MButton
              className={classes.defaultBtn}
              label="Reset"
              icon={<Refresh />}
              onClick={this.handleResetFilter}
            />
          </div>
        </Grid>
      </Paper>
    );
  };

  handleResendActivation = (data) => {
    showPopup(
        'confirm',
        `Are you sure you want to resend account activation email to ${data[3]}`,
        'Yes',
        () => this.props.resendEmail(data[1]),
    );
  };

  handlePartnerDetail = (data) => {
    this.props.history.push({
      pathname: '/partner-detail',
      state: {
        detailData: data,
      },
    });
  }

  handleConfirmDelete = (data) => {
    if (data[12] > 0) {
      return toast('error', 'Cannot delete this partner because it is in use. Please remove all companies associated with this partner.');
    }

    showPopup(
        'confirm',
        'Are you sure you want to delete this data?',
        'Yes',
        () => this.props.deletePartner(data[1]),
    );
  };

  handleToggleAccount = (data) => {
    const params = {
      akun_status: data[13] === true ? false : true,
    };
    showPopup(
        'confirm',
        'Are you sure want to enable/disable this partner account?',
        'Yes',
        () => this.props.togglePartner(data[1], params),
    );
  };

  renderActions = (params) => {
    return (
      <Grid container wrap='nowrap' direction='row'>
        <Grid item>
          {params[13] === false ? (
            <Tooltip title="Resend Activation">
              <div style={{display: 'inline', marginRight: '5px'}}>
                <Button
                  variant="contained"
                  color="primary"
                  size='small'
                  onClick={() => this.handleResendActivation(params)}
                  startIcon={<ResendEmail />}
                  style={{
                    'minWidth': '10px',
                    'paddingRight': '0px',
                  }}
                >
                </Button>
              </div>
            </Tooltip>
          ) : (null)}
        </Grid>
        <Grid item>
          <Tooltip title="Detail">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color="primary"
                size='small'
                onClick={() => this.handlePartnerDetail(params)}
                startIcon={<Search />}
                style={{
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Edit">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color="primary"
                size='small'
                onClick={() => {
                  this.props.getOnePartner(params[1]);
                  // this.handleEditDialogToggle(params);
                }}
                startIcon={<Edit />}
                style={{
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Delete">
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                color="secondary"
                size='small'
                onClick={() => this.handleConfirmDelete(params)}
                startIcon={<Delete />}
                style={{
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}

              >
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid item>
          <MuiThemeProvider theme={this.switchTheme()}>
            <Tooltip title={this.state.accountActivation ? 'Deactivate Account' : 'Activate Account'}>
              <Switch
                size='small'
                color='primary'
                checked={params[13]}
                onChange={() => this.handleToggleAccount(params)}
                inputProps={{'aria-label': 'controlled'}}
              />
            </Tooltip>
          </MuiThemeProvider>
        </Grid>
      </Grid>
    );
  };

  handleFormToggle = (type) => {
    if (type === 'add') {
      this.handleReset();
      this.setState({
        isEdit: false,
        modalFormShow: !this.state.modalFormShow,
      });
    } else {
      this.handleReset();
      this.setState({
        modalFormShow: !this.state.modalFormShow,
      });
    }
  };

  handleEditDialogToggle = (data) => {

  };

  handleReset = () => {
    this.setState({
      formData: {
        id: null,
        partner_name: null,
        email: null,
        number_phone: null,
        bank_name: null,
        bank_account_number: null,
        bank_account_name: null,
        address: null,
        partner_fee: null,
      },
      modalImportShow: false,
      isEdit: false,
      errorMessage: null,
    });
    // this.toggleResetPage()
  };

  handleInputChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      formData: {...this.state.formData, [name]: value ? value : null},
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {isEdit} = this.state;
    const {id} = this.state.formData;
    const {
      partner_name,
      email,
      number_phone,
      bank_name,
      bank_account_number,
      bank_account_name,
      address,
    } = this.state.formData;
    const partner_fee = this.state.formData.partner_fee ? parseInt(this.state.formData.partner_fee) : null;

    const {createPartner, updatePartner} = this.props;
    const params = {
      ...(partner_name && {partner_name}),
      ...(email && {email}),
      ...(number_phone && {number_phone}),
      ...(bank_name && {bank_name}),
      ...(bank_account_number && {bank_account_number}),
      ...(bank_account_name && {bank_account_name}),
      ...(partner_fee && {partner_fee}),
      ...(address && {address}),
    };

    // console.log(params);
    return !isEdit ? createPartner(params) : updatePartner(id, params);
  }

  renderModalForm = () => {
    const {
      classes,
      isLoading,
    } = this.props;
    const {
      isEdit,
      modalFormShow,
      errorMessage,
    } = this.state;
    const {
      partner_name,
      email,
      number_phone,
      bank_name,
      bank_account_number,
      bank_account_name,
      address,
      partner_fee,
    } = this.state.formData;

    const options = [
      'BANK BCA',
      'BANK BENGKULU',
      'BANK BII',
      'BANK BINTANG MANUNGGAL',
      'BANK BISNIS INTERNASIONAL',
      'BANK BJB SYARIAH',
      'BANK BNI',
      'BANK BNI SYARIAH',
      'BANK BNP PARIBAS INDONESIA',
      'BANK BRI',
      'BANK BUANA IND',
      'BANK BUKOPIN',
      'BANK BUMI ARTA',
      'BANK BUMIPUTERA',
      'BANK CAPITAL INDONESIA, TBK.',
      'BANK CENTURY, TBK.',
      'BANK CHINA TRUST INDONESIA',
      'BANK COMMONWEALTH',
      'BANK CREDIT AGRICOLE INDOSUEZ',
      'BANK DANAMON',
      'BANK DBS INDONESIA',
      'BANK DIPO INTERNATIONAL',
      'BANK DKI',
      'BANK EKONOMI',
      'BANK EKSEKUTIF',
      'BANK EKSPOR INDONESIA',
      'BANK FAMA INTERNASIONAL',
      'BANK FINCONESIA',
      'BANK GANESHA',
      'BANK HAGA',
      'BANK HAGAKITA',
      'BANK HARDA',
      'BANK HARFA',
      'BANK HARMONI INTERNATIONAL',
      'BANK HIMPUNAN SAUDARA 1906',
      'BANK IFI',
      'BANK INA PERDANA',
      'BANK INDEX SELINDO',
      'BANK INDOMONEX',
      'BANK JABAR',
      'BANK JASA ARTA',
      'BANK JASA JAKARTA',
      'BANK JATENG',
      'BANK JATIM',
      'BANK KEPPEL TATLEE BUANA',
      'BANK KESAWAN',
      'BANK KESEJAHTERAAN EKONOMI',
      'BANK LAMPUNG',
      'BANK LIPPO',
      'BANK MALUKU',
      'BANK MANDIRI',
      'BANK MASPION',
      'BANK MAYAPADA',
      'BANK MAYBANK INDOCORP',
      'BANK MAYORA',
      'BANK MEGA',
      'BANK MERINCORP',
      'BANK MESTIKA',
      'BANK METRO EXPRESS',
      'BANK MITRANIAGA',
      'BANK MIZUHO INDONESIA',
      'BANK MUAMALAT',
      'BANK MULTI ARTA SENTOSA',
      'BANK MULTICOR TBK.',
      'BANK NAGARI',
      'BANK NIAGA',
      'BANK NISP',
      'BANK NTT',
      'BANK NUSANTARA PARAHYANGAN',
      'BANK OCBC – INDONESIA',
      'BANK OF CHINA LIMITED',
      'BANK PANIN',
      'BANK PERSYARIKATAN INDONESIA',
      'BANK PURBA DANARTA',
      'BANK RESONA PERDANIA',
      'BANK RIAU',
      'BANK ROYAL INDONESIA',
      'BANK SHINTA INDONESIA',
      'BANK SINAR HARAPAN BALI',
      'BANK SRI PARTHA',
      'BANK SULTRA',
      'BANK SULUT',
      'BANK SUMITOMO MITSUI INDONESIA',
      'BANK SUMSEL',
      'BANK SUMUT',
      'BANK SWADESI',
      'BANK SWAGUNA',
      'BANK SYARIAH MANDIRI',
      'BANK SYARIAH MEGA',
      'BANK TABUNGAN NEGARA (PERSERO)',
      'BANK TABUNGAN PENSIUNAN NASIONAL',
      'BANK UIB',
      'BANK UOB INDONESIA',
      'BANK VICTORIA INTERNATIONAL',
      'BANK WINDU KENTJANA',
      'BANK WOORI INDONESIA',
      'BANK YUDHA BHAKTI',
      'BPD ACEH',
      'BPD BALI',
      'BPD DIY',
      'BPD JAMBI',
      'BPD KALIMANTAN BARAT',
      'BPD KALSEL',
      'BPD KALTENG',
      'BPD NTB',
      'BPD NTB',
      'BPD PAPUA',
      'BPD SULAWESI TENGAH',
      'BPD SULSEL',
      'BPR KS (KARYAJATNIKA SEDAYA)',
      'CENTRATAMA NASIONAL BANK',
      'CITIBANK N.A.',
      'DEUTSCHE BANK AG.',
      'HALIM INDONESIA BANK',
      'ING INDONESIA BANK',
      'KOREA EXCHANGE BANK DANAMON',
      'LIMAN INTERNATIONAL BANK',
      'PERMATA BANK',
      'PRIMA MASTER BANK',
      'RABOBANK INTERNASIONAL INDONESIA',
      'STANDARD CHARTERED BANK',
      'THE BANGKOK BANK COMP. LTD',
      'THE BANK OF TOKYO MITSUBISHI UFJ LTD',
      'THE HONGKONG & SHANGHAI B.C.',
    ];

    const titleDialog = isEdit ? 'Update Partner' : 'Create Partner';
    return (
      <Dialog
        open={modalFormShow}
        onClose={() => this.handleFormToggle('close')}
      >
        <ModalTitle
          title={titleDialog}
          onClose={() => this.handleFormToggle('close')}
        />
        <form onSubmit={this.handleSubmit}>
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MInput
                  classNameFC={classes.formControlModalFullWidth}
                  name="partner_name"
                  label="Partner Name *"
                  placeholder="Enter partner full name"
                  defaultValue={partner_name}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.partner_name ?
                      errorMessage.partner_name :
                      undefined
                  }
                  autoFocus
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MInput
                  name="email"
                  label="Email"
                  placeholder="Email address *"
                  defaultValue={email}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.email ?
                      errorMessage.email :
                      undefined
                  }
                  type="email"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <MInputMask
                  classNameFC={classes.formControl}
                  name="number_phone"
                  label="Phone Number *"
                  placeholder="Enter phone number"
                  type="number"
                  defaultValue={number_phone}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.number_phone ?
                      errorMessage.number_phone.split(':')[0] :
                      undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* <Grid item xs={6}>
                <MInput
                  name="bank_name"
                  label="Bank Name"
                  placeholder="Enter bank name"
                  defaultValue={bank_name}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.bank_name ?
                      errorMessage.bank_name :
                      undefined
                  }
                  autoFocus
                  input
                />
              </Grid> */}
              <Grid item xs={6}>
                <MAutoComplete
                  // disabled={true}
                  label={'Bank Name *'}
                  name={'bank_name'}
                  value={bank_name}
                  onChange={(event, newValue) => {
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        bank_name: newValue,
                      },
                    });
                  }}
                  inputValue={this.state.inputValue}
                  onInputChange={(event, newInputValue) => {
                    this.setState({inputValue: newInputValue});
                  }}
                  options={options}
                  errorMessage={errorMessage && errorMessage.bank_name ?
                      errorMessage.bank_name :
                      undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <MInput
                  name="bank_account_number"
                  label="Bank Account Number *"
                  placeholder="Enter bank account number"
                  type="number"
                  defaultValue={bank_account_number}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.bank_account_number ?
                      errorMessage.bank_account_number :
                      undefined
                  }
                  autoFocus
                  input
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MInput
                  name="bank_account_name"
                  label="Bank Account Name *"
                  placeholder="Enter bank account name"
                  defaultValue={bank_account_name}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.bank_account_name ?
                      errorMessage.bank_account_name :
                      undefined
                  }
                  autoFocus
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MInput
                  name="partner_fee"
                  label="Partner Fee *"
                  placeholder="Enter partner fee"
                  defaultValue={partner_fee}
                  onBlur={this.handleInputChange}
                  onKeyDown={(event: any) => {
                    if (event.key === '.' || event.key === '-' || event.key === 'e' || event.key === ',') {
                      event.preventDefault();
                    }
                  }}
                  type="number"
                  error={
                    errorMessage && errorMessage.partner_fee ?
                      errorMessage.partner_fee :
                      undefined
                  }
                  autoFocus
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MInput
                  name="address"
                  label="Address *"
                  placeholder="Enter address"
                  defaultValue={address}
                  onBlur={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.address ?
                      errorMessage.address :
                      undefined
                  }
                  autoFocus
                  multiline={true}
                  rows={3}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.handleFormToggle('close')}
            />
            <MButton
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              loading={isLoading ? true : false}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };


  render() {
    const {
      classes,
      isLoading,
      partnerData,
      partnerMeta,
    } = this.props;
    const {resetDatatable, resetPage} =
      this.state;
    const columns = [
      {
        name: 'no', // params[0]
        label: 'No',
        display: true,
        width: '300px',
      },
      {
        name: 'id', // params[1]
        label: 'id',
        display: false,
      },
      {
        name: 'partner_name', // params[2]
        label: 'Account Representative Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'email', // params[3]
        label: 'E-mail',
        display: true,
      },
      {
        name: 'number_phone', // params[4]
        label: 'Phone Number',
        display: false,
      },
      {
        name: 'bank_name', // params[5]
        label: 'Bank Name',
        display: false,
      },
      {
        name: 'bank_account_number', // params[6]
        label: 'Bank Account Number',
        display: false,
      },
      {
        name: 'bank_account_name', // params[7]
        label: 'Bank Account Name',
        display: false,
      },
      {
        name: 'address', // params[8]
        label: 'Address',
        display: false,
      },
      {
        name: 'balance', // params[9]
        label: 'Revenue',
        display: true,
        customBodyRender: (value) => (value ? 'IDR ' + value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 'IDR ' + 0),
      },
      {
        name: 'last_activity', // params[10]
        label: 'Last Activity',
        display: false,
        customBodyRender: (value) => {
          return value ?
          (
            <Typography
              style={{whiteSpace: 'nowrap', fontSize: '14px'}}
            >
              {convDate(value, 'DD-MM-YYYY')}
            </Typography>
          ) :
          '-';
        },
      },
      {
        name: 'register_date', // params[11]
        label: 'Register Date',
        display: true,
        customBodyRender: (value) => {
          return value ?
          (
            <Typography
              style={{whiteSpace: 'nowrap', fontSize: '14px'}}
            >
              {convDate(value, 'DD-MM-YYYY')}
            </Typography>
          ) :
          '-';
        },
      },
      {
        name: 'total_member', // params[12]
        label: 'Total Member',
        display: true,
      },
      {
        name: 'akun_status', // params[13]
        label: 'Status',
        display: false,
      },
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Datatable
          title={'Account Representative List'}
          loading={isLoading}
          dataSource={partnerData}
          total={partnerMeta ? partnerMeta.total : 0}
          page={partnerMeta ? partnerMeta.page : 1}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleCreate={() => this.handleFormToggle('add')}
          customActions={(params) => this.renderActions(params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
        />
        {this.renderModalForm()}
      </div>
    );
  }
}

const mapStateToProps = ({auth, partner}) => {
  return {
    auth: auth,
    isSuperAdmin: checkSuperadmin(auth),
    isLoading: partner.isLoading,
    isError: partner.isError,
    isSuccess: partner.isSuccess,
    message: partner.message,
    trigger: partner.trigger,
    partnerData: partner.partnerData,
    partnerDataById: partner.partnerDataById,
    partnerMeta: partner.partnerMeta,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkStatus: () => dispatch(checkStatus()),
  getPartner: (params) => dispatch(getPartner(params)),
  getOnePartner: (id, params) => dispatch(getOnePartner(id, params)),
  createPartner: (params) => dispatch(createPartner(params)),
  updatePartner: (id, params) => dispatch(updatePartner(id, params)),
  togglePartner: (id, params) => dispatch(togglePartner(id, params)),
  resendEmail: (id) => dispatch(resendEmail(id)),
  deletePartner: (id) => dispatch(deletePartner(id)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(Partner));

export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginBottom: 10,
    borderRadius: 15,
    height: 400,
  },
  parentFontAttend: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '21px !important',
    color: '##00AFF0',
  },
  parentFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '16px',
    color: '#656464',
  },
  parentFontLight: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '450 !important',
    fontSize: '16px !important',
    color: '#656464',
    marginTop: '30px',
  },
  childFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    fontSize: '14px !important',
    color: '#4E4E4E',
  },
  dateFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    color: '#4E4E4E',
    marginTop: '12px',
  },
  fontTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '650 !important',
    fontSize: '24px !important',
    color: '#656464',
    paddingBottom: 10,
  },
  avatarFirst: {
    fontSize: 50,
    lineHeight: 80,
    margin: 'auto',
    color: 'white',
    borderRadius: '50%',
    width: '100%',
  },
  avatar: {
    fontSize: 50,
    lineHeight: 80,
    margin: 'auto',
    color: 'white',
    borderRadius: '50%',
    width: '80%',
    display: 'flex',
  },
  avatarSmall: {
    maxWidth: '50px',
    fontSize: 50,
    lineHeight: 80,
    margin: 'auto',
    color: 'white',
    marginTop: '10px',
    borderRadius: '50%',
  },
  avatarSmallFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    color: '#4E4E4E',
  },
  avatarRibbonGold: {
    fontSize: 50,
    lineHeight: 80,
    margin: 'auto',
    color: 'white',
    width: '100%',
    position: 'absolute',
    top: '90%',
    left: 0,
    right: 0,
  },
  avatarWheat: {
    fontSize: 50,
    lineHeight: 80,
    margin: 'auto',
    color: 'white',
    width: '140%',
    position: 'absolute',
    top: '20%',
    left: '-20%',
    right: 0,
  },
  avatarCrown: {
    fontSize: 50,
    lineHeight: 80,
    margin: 'auto',
    color: 'white',
    width: '50%',
    position: 'absolute',
    top: '-130%',
    left: 0,
    right: 0,
    bottom: 0,
  },
  avatarRibbon: {
    fontSize: 50,
    lineHeight: 80,
    margin: 'auto',
    color: 'white',
    width: '80%',
    position: 'absolute',
    top: '90%',
    left: 0,
    right: 0,
  },
  loaderContainer: {
    minHeight: 220,
    display: 'flex',
    width: '100%',
  },
  loader: {
    display: 'flex',
    margin: 'auto',
  },
  loaderText: {
    marginTop: 1,
    marginLeft: 13,
  },
  hidden: {
    visibility: 'hidden',
  },
  imgQR: {
    position: 'absolute',
    height: '250px !important',
    width: '250px !important',
  },
  title: {
    color: '#fff !important',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 0,
    marginBottom: 30,
  },
  titleDate: {
    color: '#fff !important',
    fontWeight: 300,
    fontSize: 20,
    lineHeight: 0,
    marginBottom: 30,
    marginLeft: 10,
  },
  qrSection: {
    paddingTop: 30,
  },
  center: {
    textAlign: 'center',
  },
  printTitle: {
    color: '#25bbf2',
    margin: '30px 0',
    fontSize: '3.5rem',
    textAlign: 'center',
  },
  eventTitle: {
    color: '#000',
    fontSize: '3.5rem',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  eventTime: {
    color: '#000',
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  eventAddress: {
    color: '#000',
    fontSize: '1.5rem',
  },
  eventWrapper: {
    margin: '0 180px',
  },
  printSubTitle: {
    marginTop: '30px',
    textAlign: 'center',
  },
  printPage: {
    minHeight: '100vh',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
  },
  printQRImg: {
    width: 750,
    height: 750,
    border: '3px solid black',
    borderRadius: 25,
  },
  printCompanyContainer: {
    justifyContent: 'center',
  },
  printLogo: {
    width: 'auto',
    height: 75,
  },
  printCompanyName: {
    marginLeft: 20,
    fontWeight: 600,
    fontSize: 24,
  },
  printCompanyAddress: {
    marginLeft: 20,
    fontWeight: 300,
    fontSize: 16,
  },
  printFooter: {
    display: 'flex',
    justifyContent: 'center',
  },
  printFooterText: {
    marginTop: 11,
  },
  pagiiLogo: {
    height: 50,
    marginRight: 20,
  },
  smooetsLogo: {
    height: 50,
    marginLeft: 20,
  },
  poweredLogo: {
    height: 25,
    marginLeft: 5,
    marginTop: 11,
  },
  poweredText: {
    marginTop: 11,
    marginLeft: 5,
    fontSize: 16,
  },
  qrCode: {
    'margin': '0 auto',
    'display': 'flex',
    '@media (max-width: 780px)': {
      marginTop: '50px !important',
      width: '100% !important',
    },
  },
  companyDetail: {
    '@media (max-width: 990px)': {
      marginTop: '30px',
    },
  },
  titleFont: {
    '@media (max-width: 990px)': {
      fontSize: '24px !important',
    },
  },
});

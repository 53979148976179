export default (theme) => ({
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'center',
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
  },
  searchInput: {
    width: '250px',
    marginRight: '20px',
    marginBottom: '8px',
  },
  filter: {
    marginRight: 25,
  },
  filterType: {
    marginLeft: 10,
    marginBottom: '8px',
  },
  filterCheckbox: {
    // display: "flex !important",
    height: '100% !important',
    marginLeft: 0,
    marginBottom: 0,
  },
  btnAction: {
    'marginRight': '8px',
    'backgroundColor': '#00AFF0',
    'color': '#FFF',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
  },
  paper: {
    paddingTop: 2,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    borderRadius: 18,
    boxShadow: 'none',
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
});

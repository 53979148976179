export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  organizationData: [],
  organizationDataById: [],
  organizationMeta: {page: 1, total: 0},
  orgTypeData: [],
  orgAdminData: [],
  orgAdminLoading: false,
  modalLoading: false,
  detailData: null,
  orgProfile: {
    name: null,
    logo: null,
  },
  message: {
    name: null,
    country_code: null,
    user_ids: null,
    job_title_ids: null,
    address: null,
    logo: null,
  },
  orgStatusData: [],
  analyticsData: [],
  analyticsMeta: {page: 1, total: 0},
  analyticsOrg: null,

  exportLoading: false,
  exportData: {file: null},

  isLoadingTerms: false,
  isErrorTerms: false,
  isSuccessTerms: false,
  messageTerms: '',
  triggerTerms: '',

  isLoadingAssignHR: false,
  isSuccessAssignHR: false,
  isErrorAssignHR: false,
};

export const ORGANIZATION_STATE = {
  REQUEST: 'ORGANIZATION_REQUEST',
  REQUEST_FAILED: 'ORGANIZATION_REQUEST_FAILED',
  CLEAR_STATE: 'ORGANIZATION_CLEAR_STATE',
  CHECK_STATUS: 'CHECK_STATUS',
  CHECK_STATUS_SUCCESS: 'CHECK_STATUS_SUCCESS',
  CHECK_STATUS_FAILED: 'CHECK_STATUS_FAILED',
  FETCH: 'ORGANIZATION_FETCH',
  FETCH_SUCCESS: 'ORGANIZATION_FETCH_SUCCESS',
  FETCH_FAILED: 'ORGANIZATION_FETCH_FAILED',
  FETCH_LIST_ORGANIZATION_SUCCESS: 'ORGANIZATION_LIST_FETCH_SUCCESS',
  FETCH_LIST_ORGANIZATION_FAILED: 'ORGANIZATION_LIST_FETCH_FAILED',
  FETCH_BY_ID: 'ORGANIZATION_FETCH_BY_ID',
  FETCH_BY_ID_SUCCESS: 'ORGANIZATION_FETCH_BY_ID_SUCCESS',
  FETCH_BY_ID_FAILED: 'ORGANIZATION_FETCH_BY_ID_FAILED',
  FETCH_ADMIN: 'ORGANIZATION_FETCH_ADMIN',
  FETCH_ADMIN_SUCCESS: 'ORGANIZATION_FETCH_ADMIN_SUCCESS',
  FETCH_ADMIN_FAILED: 'ORGANIZATION_FETCH_ADMIN_FAILED',
  FETCH_DETAIL: 'ORGANIZATION_FETCH_DETAIL',
  FETCH_DETAIL_SUCCESS: 'ORGANIZATION_FETCH_DETAIL_SUCCESS',
  FETCH_DETAIL_FAILED: 'ORGANIZATION_FETCH_DETAIL_FAILED',
  CREATE: 'ORGANIZATION_CREATE',
  CREATE_FAILED: 'ORGANIZATION_CREATE_FAILED',
  CREATE_SUCCESS: 'ORGANIZATION_CREATE_SUCCESS',
  CREATE_CHILD_FAILED: 'ORGANIZATION_CHILD_CREATE_FAILED',
  CREATE_CHILD_SUCCESS: 'ORGANIZATION_CHILD_CREATE_SUCCESS',
  DROP: 'ORGANIZATION_DROP',
  DROP_SUCCESS: 'ORGANIZATION_DROP_SUCCESS',
  DROP_CHILD_SUCCESS: 'ORGANIZATION_CHILD_DROP_SUCCESS',
  DROP_CHILD_FAILED: 'ORGANIZATION_CHILD_DROP_FAILED',
  PARENT_SUCCESS: 'PARENT_SUCCESS',
  PARENT_FAILED: 'PARENT_FAILED',
  ACTIVATE_INVITATION_SUCCESS: 'ACTIVATE_INVITATION_SUCCESS',
  ACTIVATE_INVITATION_FAILED: 'ACTIVATE_INVITATION_FAILED',
  ACTIVATE_PARENT_INVITATION_SUCCESS: 'ACTIVATE_PARENT_INVITATION_SUCCESS',
  ACTIVATE_PARENT_INVITATION_FAILED: 'ACTIVATE_PARENT_INVITATION_FAILED',
  CONFIRM_INVITATION_SUCCESS: 'CONFIRM_INVITATION_SUCCESS',
  CONFIRM_INVITATION_FAILED: 'CONFIRM_INVITATION_FAILED',
  DROP_FAILED: 'ORGANIZATION_DROP_FAILED',
  UPDATE: 'ORGANIZATION_UPDATE',
  UPDATE_SUCCESS: 'ORGANIZATION_UPDATE_SUCCESS',
  UPDATE_FAILED: 'ORGANIZATION_UPDATE_FAILED',
  INVITATION_SUCCESS: 'ORGANIZATION_INVITATION_SUCCESS',
  INVITATION_FAILED: 'ORGANIZATION_INVITATION_FAILED',
  FETCH_TYPE: 'ORGANIZATION_FETCH_TYPE',
  FETCH_TYPE_SUCCESS: 'ORGANIZATION_FETCH_TYPE_SUCCESS',
  FETCH_TYPE_FAILED: 'ORGANIZATION_FETCH_TYPE_FAILED',
  UPDATE_MY_PROFILE: 'ORGANIZATION_UPDATE_MY_PROFILE',
  FETCH_ORG_PROFILE: 'ORGANIZATION_FETCH_PROFILE',
  FETCH_ORG_PROFILE_SUCCESS: 'ORGANIZATION_FETCH_PROFILE_SUCCESS',
  FETCH_ORG_PROFILE_FAILED: 'ORGANIZATION_FETCH_PROFILE_FAILED',
  ORG_ANALYTICS: 'ORGANIZATION_FETCH_ANALYTICS',
  ORG_ANALYTICS_SUCCESS: 'ORGANIZATION_FETCH_ANALYTICS',
  ORG_ANALYTICS_FAILED: 'ORGANIZATION_FETCH_ANALYTICS',
  FETCH_ANALYTICS_DETAILS: 'ANALYTICS_DETAILS_FETCH',
  FETCH_ANALYTICS_DETAILS_SUCCESS: 'ANALYTICS_DETAILS_FETCH_SUCCESS',
  FETCH_ANALYTICS_DETAILS_FAILED: 'ANALYTICS_DETAILS_FETCH_FAILED',
  CHECK_ODOO: 'CHECK_ODOO',
  CHECK_ODOO_SUCCESS: 'CHECK_ODOO_SUCCESS',
  CHECK_ODOO_FAILED: 'CHECK_ODOO_FAILED',
  CLEAR_MESSAGE_ODOO: 'CHECK_ODOO_FAILED',
  EXPORT: 'ORGANIZATION_EXPORT',
  EXPORT_SUCCESS: 'ORGANIZATION_EXPORT_SUCCESS',
  EXPORT_FAILED: 'ORGANIZATION_EXPORT_FAILED',

  ACCEPT_TERMS: 'ORGANIZATION_ACCEPT_TERMS',
  ACCEPT_TERMS_SUCCESS: 'ORGANIZATION_ACCEPT_TERMS_SUCCESS',
  ACCEPT_TERMS_FAILED: 'ORGANIZATION_ACCEPT_TERMS_FAILED',

  ASSIGN_HR: 'ORGANIZATION_ASSIGN_HR',
  ASSIGN_HR_SUCCESS: 'ORGANIZATION_ASSIGN_HR_SUCCESS',
  ASSIGN_HR_FAILED: 'ORGANIZATION_ASSIGN_HR_FAILED',
};

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  Grid,
  Link,
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  CssBaseline,
  IconButton,
  Box,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
  Input,
} from '@material-ui/icons';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Recaptcha from 'react-recaptcha';
import LoginIcons from './../../assets/img/pagii-simple-attendance-solution-logo.png';
import styles from './styles';
import {LOGIN_STATE as STATE} from './../../redux/login/login.types';
import {MButton} from '../../components/form';
import {toast, isObject} from '../../helpers';
import {login, setRedirectURL, clearState} from './../../redux/login/login.actions';
import loginImage from '../../assets/img/login/loginImg.png'; // Import using relative path
import playStoreDownload from '../../assets/img/login/googlePlay.png'; // Import using relative path
import appStoreDownload from '../../assets/img/login/appStore.png'; // Import using relative path
import {Button} from '@mui/material';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      isVerified: true,
    };
  }

  getMuiTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiContainer: {
        maxWidthXs: {
          maxWidth: '75%',
        },
        maxWidthLg: {
          maxWidth: '1080px !important',
          maxheight: '680px !important',
        },
      },
      MuiFormControl: {
        marginNormal: {
          marginTop: '8px !important',
          marginBottom: '14px !important',
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 8,
        },
        input: {
          padding: '13.5px 14px !important',
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    const {token} = this.props;
    if (token) {
      toast('info', `You are already logged in.`);
      return this.props.history.push('/dashboard');
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const {message, isSuccess, isError, trigger, profile, redirectURL, refreshToken} = nextProps;
    if (this.props.trigger !== trigger && trigger === STATE.LOGIN_SUCCESS && isSuccess) {
      const URL = redirectURL ? redirectURL : '/dashboard'; // check if Private-route component is setting redirectURL props on redux
      this.props.history.push({pathname: URL, state: {prevURL: window.location.pathname}});
      localStorage.setItem('refreshToken', refreshToken);
      this.props.clearState();
      if (redirectURL) this.props.setRedirectURL(null); // cleanup redirect URL to null after redirecting
      toast('success', `Welcome ${profile && profile.full_name ? profile.full_name : 'user'}`);
    }

    if (isError && message && !isObject(message)) toast('error', message);
  }

  verifyCallback = (response) => {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  }
  onloadCallback = () => {
    this.setState({
      isVerified: false,
    });
  }
  expiredCallback = (response) => {
    if (!response) {
      this.setState({
        isVerified: false,
      });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.isVerified===true) {
      const {email, password} = this.state;
      this.props.login({email, password});
    } else {
      toast('error', `Please verify the CAPTCHA`);
    }
  };

  handleChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value});
  };

  handleTogglePassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  }

  renderLogin = () => {
    const {classes, isLoading} = this.props;
    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <Container component="main" >
          <CssBaseline />
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <Grid container justify='center'>
              {/* <Grid item xs>
                <Paper className={classes.papersLeft}>
                  <Box p={2} className={classes.paperImage} style={{backgroundImage: `url(${loginImage})`}}>
                    <img src={LoginIcons} style={{width: 'auto', height: '40px'}} alt="Logo" />
                    <Typography className={classes.imagePaperTitle}>Simple Way To Check In</Typography>
                    <Typography className={classes.imagePaperSubTitle}>
                    One tap to check in absences in everywhere while working remotely or just scan the QR while working in office
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item>
                        <a href="https://play.google.com/store/apps/details?id=com.pagii&utm_source=Website&utm_medium=DL_Button&utm_campaign=Register" target="_blank">
                          <img src={playStoreDownload} style={{width: 'auto', height: '32px'}} alt="Logo" />
                        </a>
                      </Grid>
                      <Grid item>
                        <a href="https://apple.co/3JsdDDl" target="_blank">
                          <img src={appStoreDownload} style={{width: 'auto', height: '32px'}} alt="Logo" />
                        </a>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid> */}
              <Grid item xs={6}>
                <Paper className={classes.papersRight} >
                  <Box p={2} className={classes.paper}>
                    {/* <img src={LoginIcons} style={{width: '80px', height: '80px', borderRadius: '50%'}} alt="Logo" /> */}
                    {/* <img src={LoginIcons} style={{width: 'auto', height: '80px'}} alt="Logo" /> */}
                    <Typography className={classes.loginTitle}>LOGIN</Typography>
                    <form className={classes.form} noValidate method="POST" onSubmit={this.handleSubmit}>
                      <Typography className={classes.formLabel}>Email Address</Typography>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        placeholder='name@email.com'
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={this.handleChange}
                      />
                      <Typography className={classes.formLabel}>Password</Typography>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        placeholder="password"
                        type={this.state.showPassword ? 'none' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        onChange={this.handleChange}
                        InputProps={{
                          endAdornment:
                            <IconButton position="end" onClick={this.handleTogglePassword}>
                              {!this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>,
                        }}
                      />

                      <Box align="center">
                        <Recaptcha
                          fullWidth
                          sitekey={RECAPTCHA_SITE_KEY}
                          render="explicit"
                          elementID="a1"
                          onloadCallback={this.onloadCallback}
                          expiredCallback={this.expiredCallback}
                          verifyCallback={this.verifyCallback}
                          // onloadCallback={true}
                          // expiredCallback={true}
                          // verifyCallback={true}
                        />
                      </Box>
                      <MButton
                        fullWidth
                        className={classes.submit}
                        label="Sign In"
                        color="primary"
                        type='submit'
                        loading={isLoading}
                        onClick={this.handleSubmitJob}
                      />
                    </form>
                    <Typography className={classes.copyright}>Copyright 2020 ©  PT Smooets Teknologi Outsourcing</Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </MuiThemeProvider>
    );
  }

  render() {
    return (
      <div>
        {this.renderLogin()}
      </div>
    );
  }
}

const mapStateToProps = ({auth, login}) => ({
  token: auth.data.authorization,
  profile: auth.data.profile,
  isLoading: login.isLoading,
  isSuccess: login.isSuccess,
  isError: login.isError,
  message: login.message,
  trigger: login.trigger,
  redirectURL: login.redirectURL,
  refreshToken: auth.data.refreshToken,
});

const mapDispatchToProps = (dispatch) => ({
  login: (params) => dispatch(login(params)),
  setRedirectURL: (params) => dispatch(setRedirectURL(params)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(Login));

import {ENDPOINTS, apiService} from '../config';

export const getApprovalOrder = (params) => {
  return apiService.get(
      ENDPOINTS.APPROVAL_ORDER.GET,
      {params},
  );
};

export const getDetailApproval = (id, params) => {
  return apiService.get(
      ENDPOINTS.APPROVAL_ORDER.DETAIL + id,
      {params},
  );
};

export const updateApprovalOrder = (id, params) => {
  return apiService.put(
      ENDPOINTS.APPROVAL_ORDER.UPDATE + id,
      params,
  );
};

export const getMasterApproval = (params) => {
  return apiService.get(
      ENDPOINTS.APPROVAL_ORDER.MASTER,
      {params},
  );
};

export const getUserApproval = (params) => {
  return apiService.get(
      ENDPOINTS.APPROVAL_ORDER.APPROVAL,
      {params},
  );
};


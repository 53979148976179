import {createTheme} from '@material-ui/core/styles';

const customTheme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        fontSize: '14px',
        fontWeight: '400',
      },
    },
    MuiRadio: {
      '$&checked': {
        color: '#00AFF0',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: '0px !important',
      },
    },
  },
  palette: {
    default: {
      main: '#F00B38',
    },
    primary: {
      main: '#00AFF0',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F00B38',
    },
    regular: {
      main: '#0198D0',
    },
  },
});

export default customTheme;

/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {INVOICE} from '../../services';
import {INVOICE_STATE as STATE} from './invoice.types';
import {transformObject, isArray} from '../../helpers';

export const getInvoice = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    INVOICE.getInvoice(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCESS, response.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.FETCH_FAILED, message));
            },
        );
  };
};

export const generateInvoice = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.GENERATE));
    INVOICE.generateInvoice(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.GENERATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.GENERATE_FAILED, message));
            },
        );
  };
};

export const selectPaymentPlan = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.SELECT));
    INVOICE.selectPaymentPlan(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.SELECT_PLAN_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.SELECT_PLAN_FAILED, message));
            },
        );
  };
};

export const getInvoiceDetail = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_DETAIL));
    INVOICE.getInvoiceDetail(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_DETAIL_SUCCESS, response.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.FETCH_DETAIL_FAILED, message));
            },
        );
  };
};

export const getInvoiceHistory = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_HISTORY));
    INVOICE.getInvoiceHistory(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_HISTORY_SUCCESS, response.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.FETCH_HISTORY_FAILED, message));
            },
        );
  };
};

export const getInvoicePrice = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_PRICE));
    INVOICE.getInvoicePrice(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_PRICE_SUCCESS, response.data));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.FETCH_PRICE_FAILED, message));
            },
        );
  };
};

export const updateInvoice = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    INVOICE.updateInvoice(id, params)
        .then(
            (response) => {
              // console.log('res', response.data.data.message)
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.FETCH_HISTORY_FAILED, message));
            },
        );
  };
};

export const updateInvoiceStatus = (id, params, status) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE_INVOICE));
    INVOICE.updateInvoiceStatus(id, params, status)
        .then(
            (response) => {
              // console.log('res', response.data.data.message)
              dispatch(requestSuccess(STATE.UPDATE_INVOICE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_INVOICE_FAILED, message));
            },
        );
  };
};

export function checkAmount() {
  return async (dispatch) => {
    dispatch(request(STATE.CHECK));
    INVOICE.checkInvoice()
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CHECK_AMOUNT, response.data.data));
            },
            () => {
              dispatch(requestFailed(STATE.CHECK_AMOUNT, 'Failed to check Amount status'));
            },
        );
  };
}

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

export const clearStateGenerate = () => {
  return (dispatch) => dispatch(resetStateGenerate());
};

const request = (requestType, custom = false) => ({
  type: custom ? requestType : STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const exportFailed = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE_INVOICE,
});

const resetStateGenerate = () => ({
  type: STATE.CLEAR_STATE_INVOICE_GENERATE,
});

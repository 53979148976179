/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  Paper,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  Switch,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import moment from 'moment';
import {HelpOutline, Send} from '@material-ui/icons';
import styles from './styles';
import {MButton} from '../../../components/form';
import {toast, isArray, isObject, checkSuperadmin} from '../../../helpers';
import {GENERAL_SETTING_STATE as STATE} from '../../../redux/generalSetting/generalSetting.types';
import {JOB_TITLE_STATE as JOB_STATE} from '../../../redux/jobTitle/jobTitle.types';
import {getJobTitle, createJob} from '../../../redux/jobTitle/jobTitle.actions';
import {
  getGeneralSetting,
  updateGeneralSetting,
} from '../../../redux/generalSetting/generalSetting.actions';
import {
  checkStatus,
} from '../../../redux/userManagement/userManagement.actions';
import _ from 'lodash';
import {updateOrganization} from '../../../redux/organization/organization.actions';
import {Typography} from '@mui/material';

class EmployeeFacilitiesSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cash_advance_radio: null,
      formData: {
        cash_advance: {value: null},
      },
      formDataUpdate: {
        cash_advance: {value: null},
      },
      formDataJob: {
        name: null,
        description: null,
      },
      settings: [],
      message: false,
      showModalEmployeeFacilities: false,
      showModalGroupEmail: false,
      errorMessage: null,
    };
  }

  getMuiTheme = () => createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
  })
  tooltipTheme = () => createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: 16,
          marginTop: 1,
        },
      },
      MuiIconButton: {
        root: {
          padding: 0,
        },
      },
    },
  })
  UNSAFE_componentWillMount() {
    this.props.getJobTitle();
    this.props.getGeneralSetting();
    this.props.checkStatus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {message, trigger, data, jobTrigger, jobMessage, isError, menusData, triggerUser} = nextProps;

    if (trigger === STATE.UPDATE_SUCCESS) {
      toast('success', message);
      this.props.getGeneralSetting();
      this.props.checkStatus();
    }

    if (trigger === STATE.FETCH_ALL_SUCCCESS) {
      const filtered = this.filterSetting(data);
      const office_inventory = filtered && filtered[0] ? filtered[0] : null;
      const cash_advance = filtered && filtered[1] ? filtered[1] : null;
      // if (checkInSetting && checkInSetting.value) checkInSetting.value = moment(checkInSetting.value, 'hh:mm');
      this.setState({
        settings: filtered,
        cash_advance_radio: cash_advance.value ? 'true' : 'false',
        formData: {
          office_inventory: office_inventory,
          cash_advance: cash_advance,
        },
        formDataUpdate: {
          office_inventory: office_inventory,
          cash_advance: cash_advance,
        },
      });
    }

    if (jobTrigger === JOB_STATE.CREATE_SUCCESS) {
      toast('success', jobMessage);
      this.toggleModalJobTitle();
      this.props.getJobTitle();
    }

    if (this.props.trigger !== trigger && trigger === STATE.UPDATE_FAILED && !isObject(message)) {
      toast('error', message);
      this.props.getGeneralSetting();
    }

    if (jobTrigger === JOB_STATE.CREATE_FAILED && !isObject(jobMessage)) toast('error', message);

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  filterSetting = (data) => {
    const filter = (data, key) => {
      const result = data.filter((value) => value.key === key);
      return isArray(result) ? result[0] : null;
    };
    const office_inventory = filter(data, 'office_inventory');
    const cash_advance = filter(data, 'cash_advance');

    return [office_inventory, cash_advance];
  }

  toggleModalJobTitle = () => {
    const {showModalEmployeeFacilities} = this.state;
    if (showModalEmployeeFacilities) this.handleResetJob();
    this.setState({showModalEmployeeFacilities: !showModalEmployeeFacilities});
  };

  handleSubmit = () => {
    const {formDataUpdate} = this.state;
    const {menusData} = this.props;
    const converted = [];
    let newVal;

    if (!menusData.shift) {
      const newFormDataUpdate = [
        formDataUpdate.office_inventory,
        formDataUpdate.cash_advance,
      ];

      const newDataUpdate = newFormDataUpdate.filter((values) => {
        return values !==null;
      });
      newDataUpdate.map((val, key) => {
        if (val.key === 'office_inventory') { // must be array, then stringify / toString
          newVal = val.value ? isArray(val.value) ? (val.value).toString() : val.value : null; // alt : val.value = JSON.stringify(val.value);
        }
        if (val.key === 'cash_advance') {
          newVal = val.value.toString();
        }

        converted.push({
          key: val.key,
          label: val.label,
          value: newVal,
          group: val.group,
        });
      });
    } else {
      const newFormDataUpdate = [
        formDataUpdate.office_inventory,
        formDataUpdate.cash_advance,
      ];

      const newDataUpdate = newFormDataUpdate.filter((values) => {
        return values !==null;
      });
      newDataUpdate.map((val, key) => {
        if (val.key === 'office_inventory') { // must be array, then stringify / toString
          newVal = val.value ? isArray(val.value) ? (val.value).toString() : val.value : null; // alt : val.value = JSON.stringify(val.value);
        }

        if (val.key === 'cash_advance') {
          newVal = val.value.toString();
        }

        converted.push({
          key: val.key,
          label: val.label,
          value: newVal,
          group: val.group,
        });
      });
    }


    const params = {data: converted};
    this.props.updateGeneralSetting(params);
  }

  handleSubmitJob = () => {
    const {name, description} = this.state.formDataJob;
    let orgTypeId = this.props.organization.organization_type_id;
        orgTypeId = orgTypeId ? [orgTypeId] : [];

    let params;
    if (description !== null && description !== '') {
      params = {name, description, type_ids: JSON.stringify(orgTypeId)};
    } else {
      params = {name, type_ids: JSON.stringify(orgTypeId)};
    }
    this.props.createJob(params);
  }

  handleInputChangeSetting = (type, params) => {
    let name;
    let value;
    if (type === 'text') {
      name = params.target.name;
      value = params.target.value;
    } else if (type === 'multiple') {
      name = params.name;
      value = params.value;
    } else if (type === 'datetime') {
      name = params.name;
      value = moment(params.value).format('HH:mm');
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          value,
        },
      },
      formDataUpdate: {
        ...this.state.formDataUpdate,
        [name]: {
          ...this.state.formDataUpdate[name],
          value,
        },
      },
    });
  };

  handleInputChangeJob = (event) => {
    const {value, name} = event.target;
    this.setState({
      formDataJob: {
        ...this.state.formDataJob,
        [name]: value,
      },
    });
  };

  handleRadioChange = (event) => {
    const {value, name} = event.target;
    const button = name === 'advantech' ? 'adv_radio' : name === 'cash_advance' ? 'cash_advance_radio' : name === 'payslip' ? 'payslip_radio' : null;
    this.setState({
      [button]: value,
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          value,
        },
      },
      formDataUpdate: {
        ...this.state.formDataUpdate,
        [name]: {
          ...this.state.formDataUpdate[name],
          value,
        },
      },
    });
  };

  handleResetJob = () => {
    this.setState({
      formDataJob: {
        name: null,
        description: null,
      },
    });
  }


  handleGetJobTitle = (array) => {
    const {jobData} = this.props;
    const filter = isArray(jobData) && array.length ? jobData.filter((item) => array.includes(item.id)) : null;
    if (filter) {
      return filter.map((value) => value.id);
    } else if (array.length !==0) {
      return [array];
    }
  }

  handleSwitchChange = (group, label, key, value) => {
    const converted = [];
    let newVal;
    if (value === false || value === 0) {
      newVal = 'true';
    } else if (value === true || value === 1) {
      newVal = 'false';
    }

    converted.push({
      key: key,
      label: label,
      value: newVal,
      group: group,
    });
    const params = {data: converted};

    // console.log(params);
    this.props.updateGeneralSetting(params);
  };

  renderForm = (settingArray) => {
    const {classes, jobData, jobLoading, menusData} = this.props;
    const {cash_advance_radio} = this.state;
    const {job_title, office_inventory} = this.state.formData;
    const jobTitleValueLb = office_inventory && office_inventory.value ? this.handleGetJobTitle(office_inventory.value) : undefined;
    const settingArr = _.remove(settingArray, {id: undefined});

    const result = settingArray.map((setting) => {
      if (setting) {
        const {id, key, value, label, group} = setting;
        if (!menusData.shift) {
          if (key === 'office_inventory') {
            return (
              <div key={id}>
                <h5 className={classes.subTitleEmployeeFacilities}>Employee Facilities Setting</h5>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <Grid container>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormControlLabel control={<Switch checked={value} onClick={() => this.handleSwitchChange(group, label, key, value)} />} label={
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <Typography>Office Inventory</Typography>
                            <MuiThemeProvider theme={this.tooltipTheme()}>
                              <Tooltip title="Merupakan fitur yang memungkinkan Anda untuk melakukan rekap data peminjaman inventaris kantor" style={{marginLeft: 6, bottom: 1}}>
                                <IconButton>
                                  <HelpOutline />
                                </IconButton>
                              </Tooltip>
                            </MuiThemeProvider>
                          </div>
                        } />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </MuiThemeProvider>
              </div>
            );
          } if (key === 'cash_advance') {
            return (
              <div key={id}>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <Grid container>
                    <Grid xs={6}>
                      <FormGroup>
                      <FormControlLabel control={<Switch checked={value} onClick={() => this.handleSwitchChange(group, label, key, value)} />} label={
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <Typography>Cash Advance</Typography>
                            <MuiThemeProvider theme={this.tooltipTheme()}>
                              <Tooltip title="Merupakan fitur yang memungkinkan Anda untuk melakukan rekap data dalam menyediakan cash advance dengan dua jenis metode pembayaran." style={{marginLeft: 6, bottom: 1}}>
                                <IconButton>
                                  <HelpOutline />
                                </IconButton>
                              </Tooltip>
                            </MuiThemeProvider>
                          </div>
                        } />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </MuiThemeProvider>
              </div>
            );
          }
        } else {
          if (key === 'office_inventory') {
            return (
              <div key={id}>
                <h5 className={classes.subTitleEmployeeFacilities}>Employee Facilities Setting</h5>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <Grid container>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormControlLabel control={<Switch checked={value} onClick={() => this.handleSwitchChange(group, label, key, value)} />} label={
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <Typography>Office Inventory</Typography>
                            <MuiThemeProvider theme={this.tooltipTheme()}>
                              <Tooltip title="Merupakan fitur yang memungkinkan Anda untuk melakukan rekap data peminjaman inventaris kantor" style={{marginLeft: 6, bottom: 1}}>
                                <IconButton>
                                  <HelpOutline />
                                </IconButton>
                              </Tooltip>
                            </MuiThemeProvider>
                          </div>
                        } />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </MuiThemeProvider>
              </div>
            );
          } if (key === 'cash_advance') {
            return (
              <div key={id}>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <Grid container>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormControlLabel control={<Switch checked={value} onClick={() => this.handleSwitchChange(group, label, key, value)} />} label={
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <Typography>Cash Advance</Typography>
                            <MuiThemeProvider theme={this.tooltipTheme()}>
                              <Tooltip title="Merupakan fitur yang memungkinkan Anda untuk melakukan rekap data dalam menyediakan cash advance dengan dua jenis metode pembayaran." style={{marginLeft: 6, bottom: 1}}>
                                <IconButton>
                                  <HelpOutline />
                                </IconButton>
                              </Tooltip>
                            </MuiThemeProvider>
                          </div>
                        }/>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </MuiThemeProvider>
              </div>
            );
          }
        }
      }
    });
    return result;
  }

  render() {
    const {classes, data, isLoading} = this.props;
    const {settings} = this.state;
    return (
      <div className={classes.root}>
        <Paper variant='outlined' className={classes.paperEmployeeFacilities}>
          <form>
            {
              settings.length > 0 ?
              this.renderForm(settings) : ''
            }
            {/* <div className={classes.btnContainer}>
              <MButton
                className={classes.defaultBtn}
                label="Update Setting"
                icon={<Send/>}
                onClick={() => this.handleSubmit()}
                loading={isLoading && data !== [] ? true : false}
              />
            </div> */}
          </form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = ({auth, generalSetting, jobTitle, userManagement}) => {
  return ({
    isSuperAdmin: checkSuperadmin(auth),
    organization: auth.data.organization,
    data: generalSetting.data,
    isLoading: generalSetting.isLoading,
    isSuccess: generalSetting.isSuccess,
    isError: generalSetting.isError,
    menusData: auth.data.menus,
    message: generalSetting.message,
    trigger: generalSetting.trigger,
    jobData: jobTitle.jobData,
    jobLoading: jobTitle.isLoading,
    jobMessage: jobTitle.message,
    jobTrigger: jobTitle.trigger,
  });
};

const mapDispatchToProps = (dispatch) => ({
  getGeneralSetting: (key) => dispatch(getGeneralSetting(key)),
  getJobTitle: (params) => dispatch(getJobTitle(params)),
  createJob: (params) => dispatch(createJob(params)),
  updateGeneralSetting: (params) => dispatch(updateGeneralSetting(params)),
  checkStatus: (params) => dispatch(checkStatus(params)),
  updateOrganization: (data, id) => dispatch(updateOrganization(data, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmployeeFacilitiesSetting));

/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Redirect, withRouter} from 'react-router-dom';
import {setRedirectURL} from '../redux/login/login.actions';

class PrivateRoute extends Component {
  render() {
    const componentProps = this.props;
    const {authed, location, redirectURL} = this.props;

    if (!authed && location) { // check if user being redirected from external source, eg: email approval
      const newURL = location.pathname + location.search;
      if (redirectURL !== newURL) this.props.setRedirectURL(newURL);
    }

    return (
      <Route
        {...componentProps}
        render={(props) => (
          authed ?
            <Component {...props} /> :
            <Redirect to="/login" />
        )}
      />
    );
  }
}

const mapStateToProps = ({auth, login}) => ({
  token: auth.data.authorization,
  redirectURL: login.redirectURL,
});

const mapDispatchToProps = (dispatch) => ({
  setRedirectURL: (params) => dispatch(setRedirectURL(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(PrivateRoute));



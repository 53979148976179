/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import styles from './styles';
import {
  Paper,
  Button,
  Grid,
  Avatar,
  Divider,
  CardMedia,
  Typography,
  Link,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Search,
  Refresh,
  CheckCircleOutline,
  CheckCircle,
  Cancel,
  Send,
} from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import GetAppIcon from '@material-ui/icons/GetApp';
import {toast, isObject, convDate, checkSuperadmin, checkFileUploadSize, validateRegex} from '../../helpers';
import {
  MInput,
  MKeyBoardDatepicker,
  MButton,
  ModalTitle,
  MSelect,
  MDatepicker,
} from '../../components/form';
import {Datatable, ModalDatatable} from '../../components';
import _ from 'lodash';
import {
  getInvoice,
  getInvoiceDetail,
  getInvoiceHistory,
  updateInvoice,
} from './../../redux/invoice/invoice.actions';
// import TaxIcon from '../../assets/img/tax.svg';
// import PdfIcon from '../../assets/img/pdf.svg';
import {INVOICE_STATE as STATE} from '../../redux/invoice/invoice.types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {checkStatus} from '../../redux/organization/organization.actions';
import DownloadIcon from '../../assets/img/download-icon.svg';
import moment from 'moment';
class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      name: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
      showDetail: false,
      showReject: false,
      detailData: null,
      resetDatatable: null,
      resetPage: null,
      status: 'all',
      errorMessage: null,
      errorFileSize: null,
      modalInvoiceDetail: false,
      modalEditShow: false,
      organization_id: null,
      invoice_id: null,
      isAttachment: false,
      attachmentUrl: null,
      formData: {
        org_name: null,
        org_address: null,
        pic_name_invoice: null,
        pic_number_invoice: null,
        due_date: null,
        start_period: null,
        end_period: null,
        remark: null,
        attachment: null,
        amount: null,
        fileURL: null,
      },
    };
  }

  getMuiTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiTypography: {
        root: {
          color: '#656464',
        },
        h6: {
          fontSize: 20,
          fontWeight: '600',
          textalign: 'start',
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    if (this.props.isSuperAdmin) {
      this.fetchInvoice();
    } else {
      this.fetchInvoiceHistory();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {isError, message, trigger, status, isSuccess} = nextProps;
    if (isSuccess) {
      if (trigger === STATE.UPDATE_SUCCESS) {
        this.handleResetModal();
        this.fetchInvoice();
        toast('success', message);
      }
    }
    if (isError && message && !isObject(message)) toast('error', message);

    // if (trigger === STATE.PUT_CHANGE_STATUS_SUCCESS && isSuccessPut) {
    //   if (status === 'approved') {
    //     toast('success', 'Successfuly Approved Paid Leave');
    //   } else if (status === 'rejected') {
    //     toast('success', 'Successfuly Rejected Paid Leave');
    //   }
    //   this.handleResetForm(true);
    //   this.props.checkStatus();
    // } else if (isErrorPut) {
    //   toast('error', 'Error Update Leave');
    //   if (messagePut) {
    //     this.setState({errorMessage: messagePut});
    //   }
    //   setTimeout(() => {
    //     nextProps.resetLeaveStatus();
    //   }, 1000);
    // }
  }

  fetchInvoice = () => {
    const {
      page,
      limit,
      startDate,
      endDate,
      name,
      sortBy,
      order,
      status,
    } = this.state;

    this.props.getInvoice({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(name && {name}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(status && status !== 'all' && {status}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
    });
  };

  fetchInvoiceHistory = () => {
    const {
      page,
      limit,
      sortBy,
      order,
    } = this.state;

    this.props.getInvoiceHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
    });
  }

  fetchInvoiceDetail = (id = null) => {
    const {
      page,
      limit,
      organization_id,
      sortBy,
      order,
    } = this.state;

    this.props.getInvoiceDetail(id ? id : organization_id, {
      ...(page && {page}),
      ...(limit && {limit}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
    });
  };

  handleResetModal = () => {
    this.setState({
      modalEditShow: false,
    });
  }

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      name: '',
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
      status: 'all',
    };
    this.setState(state, () => this.fetchInvoice());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  };

  handleReload = (params) => {
    this.setState(params, () => this.fetchInvoice());
  };

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null}, () => this.fetchInvoice());
    this.toggleResetPage();
  };

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState({[name]: convDate(value), page: null}, () => this.fetchInvoice());
    this.toggleResetPage();
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({name: value});
    this.handleDebounceSearch(value);
  };

  handleDebounceSearch = _.debounce((name) => {
    this.toggleResetPage();
    this.setState({page: null, isLoading: true}, () => this.fetchInvoice());
  }, 500);

  handleModalDetail = (params) => {
    const {invoiceData, isSuperAdmin} = this.props;

    if (isSuperAdmin) {
      const data = invoiceData.filter((value) => {
          return value.name === params[1];
      });
      this.setState({
          modalInvoiceDetail: true,
          organization_id: data[0].id,
      });
      this.fetchInvoiceDetail(data[0].id);
    } else {
    }
  }

  handleCloseModal = () => {
    const {modalInvoiceDetail} = this.state;

    this.setState({
      modalInvoiceDetail: false,
    });
  }

  renderFilter = () => {
    const {classes, message} = this.props;
    const {name, startDate, endDate, status} = this.state;
    const renderMethodOptions = () => {
      const options = [
        {id: 'all', name: 'All'},
        {id: 'paid', name: 'Paid'},
        {id: 'unpaid', name: 'Unpaid'},
      ];
      return options;
    };
    return (
      <Paper variant='outlined' className={classes.paper}>
        <MInput
          autoFocus
          className={`${classes.filter} ${classes.searchInput}`}
          fullWidth={false}
          variant="outlined"
          name="name"
          label="Search"
          placeholder="Search keyword"
          value={name}
          onChange={this.handleSearch}
          InputProps={{
            endAdornment: (
              <IconButton className={classes.searchIcon} position="end">
                {' '}
                <Search />
              </IconButton>
            ),
          }}
        />
        {/* <MSelect
          shrink={true}
          fullWidth={false}
          classNameFC={`${classes.formControl} ${classes.filter} ${classes.searchSelect}`}
          variant="outlined"
          name="status"
          label="Status"
          keyPair={['id', 'name']}
          options={renderMethodOptions()}
          value={status}
          onChange={this.handleFilterChange}
        />
        <MDatepicker
          className={`${classes.formControl} ${classes.filter}`}
          name="startDate"
          label="Start Date"
          value={startDate}
          onDateChange={this.handleFilterDateChange}
          placeholder="dd-mm-yyyy"
          maxDate={endDate ? new Date(endDate) : undefined}
          inputVariant="outlined"
          disableFuture
          openTo="year"
          views={['year', 'month', 'date']}
        />
        <MDatepicker
          className={`${classes.formControl} ${classes.filter}`}
          name="endDate"
          label="End Date"
          value={endDate}
          onDateChange={this.handleFilterDateChange}
          placeholder="dd-mm-yyyy"
          minDate={new Date(startDate)}
          inputVariant="outlined"
          disableFuture
          openTo="year"
          views={['year', 'month', 'date']}
        /> */}
        <div className={classes.btnFilterContainer}>
          <MButton
            className={classes.filterBtn}
            label="Reset"
            color="primary"
            icon={<Refresh />}
            onClick={this.handleReset}
          />
        </div>
      </Paper>
    );
  };

  renderActions = (params) => {
    const {invoiceData, isSuperAdmin} = this.props;
    const invoicePdf = invoiceData.filter((value) => {
      return value.no === params[0];
    });
    return (
      <div>
        <>
          {
            isSuperAdmin ? invoicePdf ? invoicePdf[0] ? invoicePdf[0].invoices.length > 0 ?
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Button
                variant="contained"
                // color="primary"
                size="small"
                style={{
                  'backgroundColor': '#00AFF0',
                  'boxShadow': 'none',
                  'color': '#fff',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#00A2DE',
                  },
                  'minWidth': '10px',
                  'paddingRight': '4px',
                }}
                onClick={() => this.handleToogleEdit('edit', params)}
              >
                <CreateIcon style={{width: 16, height: 18}} />
              </Button>
            </div> :
            '' : '' : '' : ''
          }
          {
              invoicePdf ? invoicePdf[0] ? invoicePdf[0].invoices.length > 0 ?
                <div style={{display: 'inline', marginRight: '5px'}}>
                    <a href={invoicePdf ? invoicePdf[0] ? invoicePdf[0].invoices.length > 0 ? invoicePdf[0].invoices[0].file : '#' : '#' : '#'} download target="_blank" style={{
                        color: '#fff !important',
                    }}>
                    <Button
                        variant="contained"
                        // color="primary"
                        size="small"
                        style={{
                          'backgroundColor': '#00AFF0',
                          'boxShadow': 'none',
                          'color': '#fff',
                          '&:hover': {
                            boxShadow: 'none',
                            backgroundColor: '#00A2DE',
                          },
                          'minWidth': '10px',
                          'paddingRight': '4px',
                        }}
                        >
                        <GetAppIcon style={{width: 16, height: 18}} />
                    </Button>
                    </a>
                </div> :
              '' : '' : ''
          }
          <div style={{display: 'inline'}}>
            <Button
              variant="contained"
              // color="primary"
              size="small"
              style={{
                'backgroundColor': '#00AFF0',
                'boxShadow': 'none',
                'color': '#fff',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: '#00A2DE',
                },
                'minWidth': '10px',
                'paddingRight': '4px',
              }}
              onClick={() => this.handleModalDetail(params)}
            >
              <Search style={{width: 16, height: 18}} />
            </Button>
          </div>
          {/* {
            invoicePdf ? invoicePdf[0] ? invoicePdf[0].invoices.length > 0 ?
              invoicePdf[0].invoices[0].attachment ?
              <div style={{display: 'inline', marginRight: '5px'}}>
                <a href={invoicePdf ? invoicePdf[0] ? invoicePdf[0].invoices.length > 0 ? invoicePdf[0].invoices[0].attachment : '#' : '#' : '#'} download target="_blank" style={{
                    color: '#fff !important'
                }}>
                  <Tooltip title="DOWNLOAD PDF TAX">
                    <Button
                      variant="contained"
                      // color="primary"
                      size="small"
                      style={{
                        'backgroundColor': '#05b2c3',
                        'color': '#fff',
                        '&:hover': {
                            backgroundColor: '#165c6b',
                        },
                        'minWidth': '10px',
                        'paddingRight': '8px'
                      }}
                    >
                      <img
                      src={TaxIcon}
                      style={{
                        width: '23px'
                      }}
                    />
                    </Button>
                  </Tooltip>
                </a>
              </div> : ''
            : '' : '' : ''
          } */}
        </>
      </div>

    );
  };

  renderActionHr = (params) => {
    return (
      <div>
        <>
          <div style={{display: 'inline', marginRight: '5px'}}>
            <a href={params ? params[10] : '#'} download target="_blank" style={{
                color: '#fff !important',
            }}>
              <Tooltip title="DOWNLOAD INVOICE">
                <Button
                  variant="contained"asd
                  // color="primary"
                  size="small"
                  style={{
                    'backgroundColor': '#00AFF0',
                    'color': '#fff',
                    'boxShadow': 'none',
                    '&:hover': {
                      backgroundColor: '#00A2DE',
                      boxShadow: 'none',
                    },
                    'minWidth': '10px',
                    'padding': '6px 8px 6px 8px',
                  }}
                >
                  <img style={{width: 16, height: 18}}
                    src={DownloadIcon} alt="delete"
                  />
                </Button>
              </Tooltip>
            </a>
          </div>
          {/* {
            params ?
              params[11] ?
                <div style={{display: 'inline', marginRight: '5px'}}>
                  <a href={params[11]} download target="_blank" style={{
                      color: '#fff !important'
                  }}>
                    <Tooltip title="DOWNLOAD PDF TAX">
                      <Button
                          variant="contained"
                          // color="primary"
                          size="small"
                          style={{
                          'backgroundColor': '#05b2c3',
                          'color': '#fff',
                          '&:hover': {
                              backgroundColor: '#165c6b',
                          },
                          'minWidth': '10px',
                          'paddingRight': '8px'
                          }}
                          >
                            <img
                            src={TaxIcon}
                            style={{
                              width: '23px'
                            }}
                          />
                      </Button>
                    </Tooltip>
                  </a>
                </div>
              : ''
            :

            ''
          } */}
        </>
      </div>
    );
  }

  renderCustomDetail = (params) => {
    return (
      <div style={{marginBottom: 2, marginTop: 2}}>
        <div style={{display: 'inline', marginRight: '5px'}}>
          <a href={params ? params[8] : '#'} download target="_blank" style={{
            color: '#fff !important',
            marginTop: 2,
            MarginBot: 2,
          }}>
            <Button
              variant="contained"
              // color="primary"
              size="small"
              style={{
                'backgroundColor': '#00AFF0',
                'boxShadow': 'none',
                'color': '#fff',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: '#00A2DE',
                },
                'minWidth': '10px',
                'paddingRight': '4px',
              }}
            >
              <GetAppIcon style={{width: 16, height: 18}}/>
            </Button>
          </a>
        </div>
      </div>
    );
  }

  renderModalDetail = () => {
    const {classes, isLoading, invoiceData, invoiceDetailData, invoiceDetailMeta} = this.props;
    const {modalInvoiceDetail, resetDatatable, resetPage, organization_id} = this.state;
    const getCompany = invoiceData.filter((value) => {
      return value.id === organization_id;
    });

    const columns = [
      {
        name: 'no',
        label: 'No',
        display: false,
      },
      {
        name: 'invoice_number',
        label: 'Invoice Number',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'currency',
        label: 'Currency',
        display: false,
        customBodyRender: (value) => ('IDR'),
      },
      {
        name: 'price',
        label: 'Amount',
        display: true,
        customBodyRender: (value) => (value ? 'IDR ' + value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0'),
      },
      {
        name: 'invoice_date',
        label: 'Invoice Date',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'due_date',
        label: 'Due Date',
        display: true,
        customBodyRender: (value) => {
          return value ? convDate(value, 'D MMMM YYYY') : '-';
        },
      },
      {
        name: 'payment_date',
        label: 'Payment Date',
        display: true,
        customBodyRender: (value) => {
          return value ? convDate(value, 'D MMMM YYYY') : '-';
        },
      },
      {
        name: 'file',
        label: 'File',
        display: false,
      },
      {
        name: 'attachment',
        label: 'attachment',
        display: false,
      },
    ];
    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <Dialog
          open={modalInvoiceDetail}
          onClose={() => this.handleCloseModal('close')}
          maxWidth='md'
          fullWidth={true}
          scroll='paper'
          titleStyle={{textalign: 'center'}}
        >
          <ModalTitle
            className={classes.titleMargin}
            title={'Generate Invoice'}
            onClose={() => this.handleCloseModal('close')}
          />
          <DialogContent className={classes.dialogContent}>
            <div className="mb-4">
              <Typography className={classes.companyName}>Company Name : {getCompany.length > 0 ? getCompany[0].name : '-'} </Typography>
            </div>
            <ModalDatatable
              creatable={false}
              loading={isLoading}
              dataSource={invoiceDetailData}
              total={invoiceDetailMeta ? invoiceDetailMeta.total : 0}
              page={invoiceDetailMeta ? invoiceDetailMeta.page : 1}
              columns={columns}
              customActions={(params) => this.renderCustomDetail(params)}
              handleReload={(params) => this.handleReload(params)}
              toggleResetAll={resetDatatable}
              toggleResetPage={resetPage}
              manualNumbering={false}
            />
          </DialogContent>
        </Dialog>
        </MuiThemeProvider>
    );
  }

  handleToogleEdit = (type, params = null) => {
    const {invoiceData} = this.props;
    if (type === 'edit') {
      const data = invoiceData.filter((value) => {
        return value.id === params[9];
      });
      const items = data[0].invoices[0];
      this.setState({
        modalEditShow: !this.state.modalEditShow,
        invoice_id: items.id,
        isAttachment: items.attachment ? true : false,
        attachmentUrl: items.attachment ? items.attachment : null,
        formData: {
          ...this.state.formData,
          org_name: params[1],
          org_address: params[13],
          pic_name_invoice: params[2],
          pic_number_invoice: params[12],
          due_date: items.due_date,
          start_period: items.start_period,
          end_period: items.end_period,
          amount: params[3],
          remark: items.remarks,
          attachment: null,
          // fileURL: PdfIcon
        },
      });
    } else {
      this.setState({
        modalEditShow: !this.state.modalEditShow,
      });
    }
  }

  handleInvoicePrice = (event) => {
    const {formData, errorMessage} = this.state;
    const regex = event.target.value;
    this.setState({
      formData: {
        ...formData,
        amount: regex,
      },
      errorMessage: {
        ...this.state.errorMessage,
        price: null,
      },
    });
  }

  handleInvoiceChange = (event) => {
    const {name, value} = event.target;
    const {formData, errorMessage} = this.state;
    this.setState({
      formData: {
        ...formData,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: null,
      },
    });
  }

  handleAttachmentChange = (event) => {
    const {formData, errorMessage} = this.state;
    const file = event.target.files[0];
    const fileSizeIsAllowed = checkFileUploadSize(file);
    if (fileSizeIsAllowed) {
      this.setState({
        errorFileSize: null,
        errorMessage: {...errorMessage, attachment: null},
        formData: {
          ...formData,
          attachment: file,
          // fileURL: PdfIcon,
        },
      });
    } else {
      let message;
      if (fileSizeIsAllowed === false) message = 'Maximum file size is ' + process.env.REACT_APP_MAX_UPLOAD_SIZE_MB + ' MB';
      if (fileSizeIsAllowed === null) message = 'Please select image';
      this.setState({
        errorFileSize: message,
        file: null,
        fileURL: null,
      });
    }
  };

  handleInvoiceDateChange = (event) => {
    const {formData, errorMessage} = this.state;
    this.setState({
      formData: {
        ...formData,
        [event.name]: event.value ? convDate(event.value) : null,
      },
      errorMessage: {
        ...errorMessage,
        [event.name]: null,
      },
    });
  }

  handleSubmitEdit = (event) => {
    event.preventDefault();
    const {updateInvoice} = this.props;
    const {
      pic_name_invoice, pic_number_invoice, due_date, start_period, end_period, amount, remark, attachment,
    } = this.state.formData;
    const {invoice_id} = this.state;
    const dataForm = new FormData();
    if (pic_name_invoice) dataForm.append('pic_name', pic_name_invoice);
    if (pic_number_invoice) dataForm.append('pic_number', pic_number_invoice);
    if (due_date) dataForm.append('due_date', due_date);
    if (start_period) dataForm.append('start_period', start_period);
    if (end_period) dataForm.append('end_period', end_period);
    if (amount) dataForm.append('price', amount);
    if (remark) dataForm.append('remarks', remark);
    if (attachment) dataForm.append('attachment', attachment);

    return updateInvoice(invoice_id, dataForm);
  }

  renderModalEdit = () => {
    const {org_name, org_address, pic_name_invoice, pic_number_invoice, due_date, start_period, end_period, remark, attachment, amount, fileURL} = this.state.formData;
    const {errorMessage, modalEditShow, errorFileSize, isAttachment, attachmentUrl} = this.state;
    const {classes, isLoading} = this.props;
    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <Dialog
          open={modalEditShow}
          onClose={() => this.handleToogleEdit('close')}
          maxWidth='md'
          fullWidth={true}
          scroll='paper'
          titleStyle={{textalign: 'center'}}
        >
          <ModalTitle
            className={classes.modalTitle}
            title={'Update Invoice'}
            // title={"Edit Invoice"}
            onClose={() => this.handleToogleEdit('close')}
          />
          <form onSubmit={this.handleSubmitEdit}>
            <DialogContent className={classes.dialogContentEdit}>
              <div style={{marginBottom: '12px'}}>
                <table>
                  <tr>
                    <td>Company</td>
                    <td style={{paddingLeft: 48, paddingRight: 18}}> : </td>
                    <td>{org_name ? org_name : '-'}</td>
                  </tr>
                  <tr>
                    <td >Address</td>
                    <td style={{paddingLeft: 48, paddingRight: 18}}>:</td>
                    <td style={{wordBreak: 'break-word'}}>{org_address ? org_address : '-'}</td>
                  </tr>
                </table>
              </div>
              <Grid container wrap="nowrap" spacing={2} style={{marginBottom: '14px'}}>
                <Grid item xs="6">
                  <MInput
                    autoFocus
                    fullWidth={true}
                    variant="outlined"
                    name="pic_name_invoice"
                    label="PIC Name"
                    placeholder="Enter Pic Name..."
                    defaultValue={pic_name_invoice}
                    error={errorMessage && errorMessage.pic_name ? errorMessage.pic_name : undefined}
                    onBlur={this.handleInvoiceChange}
                  />
                </Grid>
                <Grid item xs="6">
                  <MInput
                    fullWidth={true}
                    variant="outlined"
                    name="pic_number_invoice"
                    label="PIC Number"
                    placeholder="Enter Pic Number..."
                    defaultValue={pic_number_invoice}
                    error={errorMessage && errorMessage.pic_number ? errorMessage.pic_number : undefined}
                    onBlur={this.handleInvoiceChange}
                    type="number"
                  />
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2} style={{marginBottom: '22px'}}>
                <Grid item xs="6">
                  <MDatepicker
                    name="invoice_date"
                    label="Invoice Date"
                    value={convDate(new Date())}
                    disabled
                    onDateChange={(params) => this.handleInvoiceDateChange(params)}
                    // error={errorMessage && errorMessage.start_date ? errorMessage.start_date : undefined}
                    placeholder="dd-mm-yyyy"
                    inputVariant="outlined"
                    views={['year', 'month', 'date']}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs="6">
                  <MDatepicker
                    name="due_date"
                    label="Due Date"
                    value={due_date}
                    onDateChange={(params) => this.handleInvoiceDateChange(params)}
                    error={errorMessage && errorMessage.due_date ? errorMessage.due_date : undefined}
                    placeholder="dd-mm-yyyy"
                    inputVariant="outlined"
                    views={['year', 'month', 'date']}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2} style={{marginBottom: '8px'}}>
                <Grid item xs="6">
                  <MDatepicker
                    name="start_period"
                    label="Start Periode"
                    value={start_period}
                    onDateChange={(params) => this.handleInvoiceDateChange(params)}
                    error={errorMessage && errorMessage.start_period ? errorMessage.start_period : undefined}
                    placeholder="dd-mm-yyyy"
                    maxDate={end_period ? new Date(end_period) : undefined}
                    inputVariant="outlined"
                    views={['year', 'month', 'date']}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs="6">
                  <MDatepicker
                    name="end_period"
                    label="End Periode"
                    value={end_period}
                    onDateChange={(params) => this.handleInvoiceDateChange(params)}
                    error={errorMessage && errorMessage.end_period ? errorMessage.end_period : undefined}
                    placeholder="dd-mm-yyyy"
                    minDate={start_period ? new Date(start_period) : undefined}
                    inputVariant="outlined"
                    views={['year', 'month', 'date']}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2} style={{marginBottom: '2px'}}>
                <Grid item xs="6">
                  <MInput
                    name="currency"
                    label="Currency"
                    placeholder="Currency"
                    autoComplete="off"
                    disabled
                    value={'IDR'}
                  />
                </Grid>
                <Grid item xs="6">
                  <MInput
                    name="amount"
                    type="text"
                    label="Amount"
                    placeholder="Enter Amount"
                    autoComplete="off"
                    defaultValue={amount}
                    onBlur={this.handleInvoicePrice}
                    error={errorMessage && errorMessage.price ? errorMessage.price : undefined}
                  />
                </Grid>
              </Grid>
              <MInput
                style={{marginBottom: '12px'}}
                name="remark"
                type="text"
                label="Remark"
                placeholder="Enter Remarks"
                autoComplete="off"
                defaultValue={remark}
                onBlur={this.handleInvoiceChange}
                error={errorMessage && errorMessage.price ? errorMessage.price : undefined}
                multiline
              />
              <MInput
                style={{marginBottom: 14}}
                name="attachment"
                label="Attachment"
                type="file"
                onChange={this.handleAttachmentChange}
                error={
                  errorFileSize ?
                  errorFileSize :
                  (
                    errorMessage && errorMessage.attachment ?
                    errorMessage.attachment :
                    undefined
                  )
                }
              />
              {
                isAttachment ?
                  <>
                    <a href={attachmentUrl ? attachmentUrl : '#'} style={{marginBottom: '1em', display: 'block'}} target="_blank">Click here to see tax</a>
                    <img
                      src={fileURL}
                      style={{
                        width: '125px',
                      }}
                      alt="attachmentImg"
                    />
                  </> :
                    ''
              }
            </DialogContent>
            <DialogActions className={classes.dialogFooter}>
              <MButton
                className={classes.cancelBtn}
                label="Cancel"
                onClick={() => this.handleToogleEdit('close')}
              />
              <MButton
                className={classes.defaultBtn}
                label={'Update'}
                icon={<Send/>}
                type="submit"
                loading={isLoading ? true : false}
                onClick={this.handleSubmitEdit}
              />
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
    );
  }

  render() {
    const {classes, invoiceData, invoiceMeta, invoiceHistoryMeta, invoiceHistoryData, isLoading, isSuperAdmin} = this.props;
    const {resetDatatable, resetPage} = this.state;
    const columns = [
      {
        name: 'no',
        label: 'No',
        display: true,
      },
      {
        name: 'name',
        label: 'Company Name',
        display: isSuperAdmin ? true : false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'pic_name',
        label: 'PIC Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'price',
        label: 'Currency',
        display: false,
        customBodyRender: (value) => ('IDR'),
      },
      {
        name: 'invoices',
        label: 'Amount',
        display: isSuperAdmin ? true : false,
        customBodyRender: (value) => (value ? value.length > 0 ? 'IDR ' + value[0].price : 'IDR 0' : 'IDR 0'),
      },
      {
        name: 'price',
        label: 'Amount',
        display: isSuperAdmin ? false : true,
        customBodyRender: (value) => (value ? 'IDR ' + value : 'IDR 0'),
      },
      {
        name: 'invoice_date',
        label: 'Invoice Date',
        display: true,
        customBodyRender: (value) => {
          return value ? convDate(value, 'D MMMM YYYY') : '-';
        },
      },
      {
        name: 'due_date',
        label: 'Due Date',
        display: true,
        customBodyRender: (value) => {
          return value ? convDate(value, 'D MMMM YYYY') : '-';
        },
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          return value ?
          (
            value === 'paid' ?
            <span style={{fontWeight: '700'}} className="text-success">{value.charAt(0).toUpperCase() + value.slice(1)} </span> :
            value === 'unpaid' ?
            <span style={{fontWeight: '700'}} className="text-warning">{value.charAt(0).toUpperCase() + value.slice(1)} </span> :
            <span style={{fontWeight: '700'}} className="text-danger">{value.charAt(0).toUpperCase() + value.slice(1)} </span>
          ) : '-';
        },
      },
      {name: 'id', label: 'id', display: false},
      {name: 'file', label: 'file', display: false},
      {
        name: 'attachment',
        label: 'attachment',
        display: false,
      },
      {
        name: 'pic_number',
        label: 'PIC Number',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'address',
        label: 'Address',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'invoice_number',
        label: 'Invoice Number',
        display: isSuperAdmin ? false : true,
        customBodyRender: (value) => (value ? value : '-'),
      },
    ];
    return (
      <div className={classes.root}>
        {isSuperAdmin ? this.renderModalEdit() : ''}
        {isSuperAdmin ? this.renderFilter() : ''}
        <Datatable
          creatable={false}
          title={'Invoice'}
          loading={isLoading}
          dataSource={isSuperAdmin ? invoiceData : invoiceHistoryData}
          total={isSuperAdmin ? invoiceMeta ? invoiceMeta.total : 0 : invoiceHistoryMeta ? invoiceHistoryMeta.total : 0}
          page={isSuperAdmin ? invoiceMeta ? invoiceMeta.page : 1 : invoiceHistoryMeta ? invoiceHistoryMeta.page : 1}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          customActions={(data) => isSuperAdmin ? this.renderActions(data) : this.renderActionHr(data)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
        />
        {this.renderModalDetail()}
      </div>
    );
  }
}

const mapStateToProps = ({auth, invoice}) => {
  return {
    isSuperAdmin: checkSuperadmin(auth),
    isLoading: invoice.isLoading,
    isSuccess: invoice.isSuccess,
    isError: invoice.isError,
    message: invoice.message,
    invoiceData: invoice.invoiceData,
    invoiceDetailData: invoice.invoiceDetailData,
    invoiceHistoryData: invoice.invoiceHistoryData,
    invoiceMeta: invoice.invoiceMeta,
    invoiceDetailMeta: invoice.invoiceDetailMeta,
    invoiceHistoryMeta: invoice.invoiceHistoryMeta,
    trigger: invoice.trigger,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInvoice: (params) => dispatch(getInvoice(params)),
  getInvoiceDetail: (id, params) => dispatch(getInvoiceDetail(id, params)),
  getInvoiceHistory: (params) => dispatch(getInvoiceHistory(params)),
  updateInvoice: (id, params) => dispatch(updateInvoice(id, params)),
  checkStatus: () => dispatch(checkStatus()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(Invoice));

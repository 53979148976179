export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  divisionData: [],
  divisionDataByOrg: [],
  approvalDivisionData: [],
  divisionMeta: {page: 1, total: 0},
  message: {
    division_name: '',
    description: '',
    created_by: '',
  },
};

export const DIVISION_STATE = {
  REQUEST: 'DIVISION_REQUEST',
  REQUEST_FAILED: 'DIVISION_REQUEST_FAILED',
  CLEAR_STATE: 'DIVISION_CLEAR_STATE',
  FETCH: 'DIVISION_FETCH',
  FETCH_SUCCCESS: 'DIVISION_FETCH_SUCCCESS',
  FETCH_FAILED: 'DIVISION_FETCH_FAILED',
  CREATE: 'DIVISION_CREATE',
  CREATE_FAILED: 'DIVISION_CREATE_FAILED',
  CREATE_SUCCESS: 'DIVISION_CREATE_SUCCESS',
  UPDATE: 'DIVISION_UPDATE',
  UPDATE_SUCCESS: 'DIVISION_UPDATE_SUCCESS',
  UPDATE_FAILED: 'DIVISION_UPDATE_FAILED',
  DROP: 'DIVISION_DROP',
  DROP_SUCCESS: 'DIVISION_DROP_SUCCESS',
  DROP_FAILED: 'DIVISION_DROP_FAILED',
  FETCH_BY_ORG: 'DIVISION_FETCH_BY_ORG',
  FETCH_BY_ORG_SUCCCESS: 'DIVISION_FETCH_BY_ORG_SUCCCESS',
  FETCH_BY_ORG_FAILED: 'DIVISION_FETCH_BY_ORG_FAILED',
  FETCH_ON_APPROVAL: 'DIVISION_FETCH_ON_APPROVAL',
  FETCH_ON_APPROVAL_SUCCCESS: 'DIVISION_FETCH_ON_APPROVAL_SUCCCESS',
  FETCH_ON_APPROVAL_FAILED: 'DIVISION_FETCH_ON_APPROVAL_FAILED',
};

import {ENDPOINTS, partnerService} from '../config';

export const getWithdrawal = (params) => {
  return partnerService.get(
      ENDPOINTS.WITHDRAW.GET,
      {params},
  );
};

export const getOneWithdrawal = (id, params) => {
  return partnerService.get(
      ENDPOINTS.WITHDRAW.GET_ONE + id,
      {params},
  );
};

export const updateWithdrawal = (id, params) => {
  return partnerService.put(
      ENDPOINTS.WITHDRAW.UPDATE + id,
      params,
  );
};

export const rejectWithdrawal = (id, params) => {
  return partnerService.put(
      ENDPOINTS.WITHDRAW.REJECT + id + '/rejected',
      params,
  );
};



export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: null,
  message: null,
  data: [],
};

export const FORGOT_PASSWORD_STATE = {
  REQUEST: 'FORGOT_PASSWORD_REQUEST',
  REQUEST_FAILED: 'FORGOT_PASSWORD_REQUEST_FAILED',
  SUBMIT: 'FORGOT_PASSWORD_SUBMIT',
  SUBMIT_SUCCESS: 'FORGOT_PASSWORD_SUBMIT_SUCCESS',
  SUBMIT_FAILED: 'FORGOT_PASSWORD_SUBMIT_FAILED',
};

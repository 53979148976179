/* eslint-disable object-curly-spacing */
/* eslint-disable quotes */
import { persistor } from "../../config";
import { toast } from "../../helpers";
import { AUTH } from "../../services";
import { clearState, logout } from "../login/login.actions";
import { LOGIN_STATE } from "../login/login.types";
import { REFRESH_TOKEN_STATE, REFRESH_TOKEN_STATE as STATE } from "./refreshToken.types";

export const updateRefreshToken = (params) => {
  return (dispatch, getState) => {
    dispatch(request(STATE.UPDATE_REFRESH_TOKEN));
    AUTH.generateRefreshToken(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_REFRESH_TOKEN_SUCCESS, response.data));
              dispatch(requestSuccess(LOGIN_STATE.UPDATE_AUTHORIZATION_SUCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.UPDATE_REFRESH_TOKEN_FAILED, response.data.message));
              dispatch(requestFailed(LOGIN_STATE.UPDATE_AUTHORIZATION_FAILED, response.data.message));

              const role = getState()?.auth?.data?.profile?.role?.toLowerCase();

              persistor.pause();
              persistor.flush();
              persistor.purge().then(() => {
                clearState();
                logout();
                clearRefreshToken();
                localStorage.removeItem('refreshToken');
                toast('error', 'You have been login on another device');
              }).then(() => {
                let url = '/login';

                if (role === 'super admin') {
                  url = '/sysadm-login';
                }

                window.location.href = url;
              });
            },
        );
  };
};

export const clearRefreshToken = () => {
  return (dispatch) => dispatch({
    type: REFRESH_TOKEN_STATE.CLEAR_STATE,
  });
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

/* eslint-disable no-sparse-arrays */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import RoleManagement from './pages/roleManagement';
import AttendanceHistory from './pages/attendanceHistory';
import UserAdminManagement from './pages/adminManagement';
import FraudDetection from './pages/fraudDetection';
import OvertimeHistory from './pages/overtimeHistory';
import UserManagement from './pages/userManagement';
import ShiftManagement from './pages/shift/shiftManagement';
import ShiftSetting from './pages/shift/shiftSetting';
import Organization from './pages/organization';
import ConfirmPayment from './pages/confirmPayment';
import PaidLeave from './pages/paidLeave';
import JobTitle from './pages/jobTitle';
import CompanyType from './pages/companyType';
import Register from './pages/registers';
import RegisterCompany from './pages/registerCompany';
import RegisterUser from './pages/registerUser';
import RegistrationManagement from './pages/registrationManagement';
import Approval from './pages/approval';
import ApprovalOvertime from './pages/approvalOvertime';
import ChangePassword from './pages/changePassword';
import ForgotPassword from './pages/forgotPassword';
import CompanyActivation from './pages/companyActivation';
import GeneralSetting from './pages/generalSetting';
import CompanyProfile from './pages/generalSetting/hrAdmin/companyProfile';
import CompanyAnnouncements from './pages/generalSetting/hrAdmin/announcements';
import CompanyQR from './pages/generalSetting/hrAdmin/generateQR';
import WorkingTimeSettings from './pages/generalSetting/hrAdmin/workingTimeSettings';
import LeaderboardSettings from './pages/generalSetting/hrAdmin/leaderboardSettings';
import PayslipSettings from './pages/generalSetting/hrAdmin/payslipSettings';
import AdvantechSettings from './pages/generalSetting/hrAdmin/advantechSettings';
import EmployeeFacilitiesSetting from './pages/generalSetting/hrAdmin/employeeFacilitiesSetting';
import HRSetting from './pages/generalSetting/hrAdmin/index';
import EmailInvitationActivation from './pages/organization/email';
import EmailVerification from './pages/organization/email/verification';
import LandingPage from './pages/landingPage';
import PageNotFound from './pages/errorPage';
import QRPage from './pages/qrCode';
import Reimburse from './pages/reimburse';
import Permit from './pages/permit';
import LeaveConfig from './pages/leave/leaveConfig';
import LeaveManagement from './pages/leave/leaveManagement';
import MasterHoliday from './pages/leave/masterHoliday';
import ApprovalPermit from './pages/approvalPermit';
import ApprovalReimburse from './pages/approvalReimburse';
import ApprovalPayment from './pages/approvalPayment';
import Invoice from './pages/invoice';
import Leaderboard from './pages/leaderboard';
import PaymentAnalytics from './pages/analytics/payment';
import RegistrationAnalytics from './pages/analytics/registration';
import OrganizationAnalytics from './pages/analytics/organization';
import UserList from './pages/userManagement/superAdmin';
import UserActivation from './pages/userActivation';
import userManagements from './pages/userManagements';
import Partner from './pages/partnerManagement/partner';
import PartnerDetail from './pages/partnerManagement/partnerDetail';
import WithdrawRequest from './pages/partnerManagement/withdrawRequest';
import loginSSO from './pages/loginSSO';
import registerSSO from './pages/registerSSO';
import SSOWhitelist from './pages/ssoWhitelist';
import ssoActivation from './pages/ssoActivation';
import registers from './pages/registers';
import OfficeInventory from './pages/employeeFacilities/officeInventory';
import CashAdvance from './pages/employeeFacilities/cashAdvance';
import MasterInventory from './pages/employeeFacilities/masterInventory';
import MasterInventoryDetail from './pages/employeeFacilities/masterInventory/masterInventoryDetail';
import DivisionManagement from './pages/divisionManagement';
import Subscriptions from './pages/subscriptions';
import SubscriptionsHistory from './pages/subscriptionsHistory';
import ApprovalOrderSetting from './pages/generalSetting/hrAdmin/approvalOrderSetting';
import editApprovalOrder from './pages/generalSetting/hrAdmin/editApprovalOrder';
import EmployeeDetail from './pages/employeeDetail';
import ExportActivity from './pages/exportActivity';
import OldInvoice from './pages/oldInvoice';
import EventManagement from './pages/event/event-list';
import CreateEvent from './pages/event/event-list/create-event';
import EventDetail from './pages/event/event-list/detail';
import QREventPage from './pages/event/components/QREventPage';
import mobileFeatureSettings from './pages/generalSetting/hrAdmin/mobileFeatureSettings';
import EventGroup from './pages/event/event-group';
import CreateEventGroupPage from './pages/event/event-group/create-event-group';
import EventGroupDetail from './pages/event/event-group/detail';

const REGISTER_LINK = process.env.REACT_APP_REGISTER_LINK;

export default [
  {
    path: '/',
    exact: true,
    auth: false,
    component: LandingPage,
  },
  {
    path: '/419',
    exact: true,
    auth: false,
    component: PageNotFound,
  },
  {
    path: '/sysadm-login',
    exact: true,
    auth: false,
    component: Login,
  },
  {
    path: '/login',
    exact: true,
    auth: false,
    component: loginSSO,
  },
  {
    path: '/register',
    exact: true,
    auth: false,
    component: RegisterCompany,
  },
  {
    path: '/register-company',
    exact: true,
    auth: false,
    component: RegisterCompany,
  },
  {
    path: '/register-user',
    exact: true,
    auth: false,
    component: RegisterUser,
  },
  {
    path: '/registers',
    exact: true,
    auth: false,
    component: registers,
  },
  {
    path: '/register-sso',
    exact: true,
    auth: false,
    component: registerSSO,
  },
  {
    path: '/registration-management',
    exact: false,
    component: RegistrationManagement,
  },
  {
    path: `/approval/leave/:status/:token`,
    exact: true,
    auth: false,
    component: Approval,
  },
  {
    path: '/payment-analytics',
    exact: false,
    component: PaymentAnalytics,
  },
  {
    path: '/registration-analytics',
    exact: false,
    component: RegistrationAnalytics,
  },
  {
    path: '/organization-analytics',
    exact: false,
    component: OrganizationAnalytics,
  },
  {
    path: '/employees-list',
    exact: false,
    component: UserList,
  },
  {
    path: `/approval/overtime/:status/:token`,
    exact: true,
    auth: false,
    component: ApprovalOvertime,
  },
  {
    path: `/approval/permit/:status/:token`,
    exact: true,
    auth: false,
    component: ApprovalPermit,
  },
  {
    path: `/approval/reimburse/:status/:token`,
    exact: true,
    auth: false,
    component: ApprovalReimburse,
  },
  {
    path: `/approval/payment-confirmation/:status/:token`,
    exact: true,
    auth: false,
    component: ApprovalPayment,
  },
  {
    path: '/change-password',
    exact: true,
    auth: false,
    component: ChangePassword,
  },
  {
    path: '/forgot-password',
    exact: true,
    auth: false,
    component: ForgotPassword,
  },
  {
    path: '/dashboard',
    exact: false,
    component: Dashboard,
  },
  {
    path: '/leaderboard/:token',
    exact: true,
    auth: false,
    component: Leaderboard,
  },
  {
    path: '/role-management',
    exact: false,
    component: RoleManagement,
  },
  {
    path: '/attendance-history',
    exact: false,
    component: AttendanceHistory,
  },
  {
    path: '/fraud-detection',
    exact: false,
    component: FraudDetection,
  },
  {
    path: '/leave-config',
    exact: false,
    component: LeaveConfig,
  },
  {
    path: '/master-holiday',
    exact: false,
    component: MasterHoliday,
  },
  {
    path: '/leave-history',
    exact: false,
    component: LeaveManagement,
  },
  {
    path: '/overtime-history',
    exact: false,
    component: OvertimeHistory,
  },
  {
    path: '/admin-management',
    exact: false,
    component: UserAdminManagement,
  },
  {
    path: '/user-management',
    exact: false,
    component: UserManagement,
  },
  {
    path: '/non-organization-management',
    exact: false,
    component: UserManagement,
  },
  {
    path: '/user-managements',
    exact: false,
    component: userManagements,
  },
  {
    path: '/shift-management',
    exact: false,
    component: ShiftManagement,
  },
  {
    path: '/activation-company/:token',
    exact: true,
    component: EmailInvitationActivation,
  },
  {
    path: '/activation-account-company/:token',
    exact: true,
    component: EmailInvitationActivation,
  },
  {
    path: '/activation-invite-childs/:token',
    exact: true,
    component: EmailVerification,
  },
  {
    path: '/shift-setting',
    exact: false,
    component: ShiftSetting,
  },
  {
    path: '/general-setting',
    exact: false,
    component: GeneralSetting,
  },
  {
    path: '/organization-management',
    exact: false,
    component: Organization,
  },
  {
    path: '/account-representative',
    exact: false,
    component: Partner,
  },
  {
    path: '/partner-detail',
    exact: false,
    component: PartnerDetail,
  },

  {
    path: '/withdraw-request',
    exact: false,
    component: WithdrawRequest,
  },

  {
    path: '/payment-history',
    exact: false,
    component: ConfirmPayment,
  },
  {
    path: '/invoice',
    exact: false,
    component: Invoice,
  },
  {
    path: '/old-invoice',
    exact: false,
    component: OldInvoice,
  },
  {
    path: '/leave-management',
    exact: false,
    component: PaidLeave,
  },
  {
    path: '/reimburse',
    exact: false,
    component: Reimburse,
  },
  {
    path: '/permit',
    exact: false,
    component: Permit,
  },
  {
    path: '/job-title',
    exact: false,
    component: JobTitle,
  },
  {
    path: '/company-type',
    exact: false,
    component: CompanyType,
  },
  {
    path: '/company-profile',
    exact: false,
    component: CompanyProfile,
  },
  {
    path: '/account-activation/:token',
    exact: true,
    component: CompanyActivation,
  },
  {
    path: '/sso-activation',
    exact: true,
    component: ssoActivation,
  },
  {
    path: '/user/account-activation/:token',
    exact: true,
    component: UserActivation,
  },
  {
    path: '/announcements',
    exact: false,
    component: CompanyAnnouncements,
  },
  {
    path: '/generate',
    exact: false,
    component: CompanyQR,
  },
  {
    path: '/qrcode/:token',
    exact: true,
    auth: false,
    component: QRPage,
  },
  {
    path: '/event/qrcode/:token',
    exact: true,
    auth: false,
    component: QREventPage,
  },
  {
    path: '/' + process.env.PUBLIC_URL + (__filename),
    exact: true,
  },
  {
    path: '/working-time',
    exact: false,
    component: WorkingTimeSettings,
  },
  {
    path: '/leaderboard-settings',
    exact: false,
    component: LeaderboardSettings,
  },
  {
    path: '/payslip-settings',
    exact: false,
    component: PayslipSettings,
  },
  {
    path: '/advantech-settings',
    exact: false,
    component: AdvantechSettings,
  },
  {
    path: '/employee-facilities-setting',
    exact: false,
    component: EmployeeFacilitiesSetting,
  },
  {
    path: '/job-titles',
    exact: false,
    component: HRSetting,
  },
  {
    path: '/sso-management',
    exact: false,
    component: SSOWhitelist,
  },
  {
    path: '/office-inventory',
    exact: false,
    component: OfficeInventory,
  },
  {
    path: '/cash-advance',
    exact: false,
    component: CashAdvance,
  },
  {
    path: '/master-inventory',
    exact: false,
    component: MasterInventory,
  },
  {
    path: '/master-inventory-detail',
    exact: false,
    component: MasterInventoryDetail,
  },
  {
    path: '/subscriptions',
    exact: false,
    component: Subscriptions,
  },
  {
    path: '/subscriptions-history',
    exact: false,
    component: SubscriptionsHistory,
  },
  {
    path: '/division-management',
    exact: false,
    component: DivisionManagement,
  },
  {
    path: '/approval-order-setting',
    exact: false,
    component: ApprovalOrderSetting,
  },
  {
    path: '/edit-approval-order',
    exact: false,
    component: editApprovalOrder,
  },
  {
    path: '/employee-detail',
    exact: false,
    component: EmployeeDetail,
  },
  {
    path: '/export-activity',
    exact: false,
    component: ExportActivity,
  },
  {
    path: '/event-management',
    exact: false,
    component: EventManagement,
  },
  {
    path: '/event-management/create',
    exact: false,
    component: CreateEvent,
  },
  {
    path: '/event-management/update',
    exact: false,
    component: CreateEvent,
  },
  {
    path: '/event-management/detail',
    exact: false,
    component: EventDetail,
  },
  {
    path: '/mobile-feature-setting',
    exact: false,
    component: mobileFeatureSettings,
  },
  {
    path: '/event-group',
    exact: false,
    component: EventGroup,
  },
  {
    path: '/event-group/create',
    exact: false,
    component: CreateEventGroupPage,
  },
  {
    path: '/event-group/update',
    exact: false,
    component: CreateEventGroupPage,
  },
  {
    path: '/event-group/detail',
    exact: false,
    component: EventGroupDetail,
  },
];

import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import L from 'leaflet';
import React, {useEffect} from 'react';
import {MapContainer, TileLayer, Marker, Popup, useMapEvents} from 'react-leaflet';

// Fix the default icon issue in React-Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
});

const initialPosition = {
  lat: 51.505,
  lng: -0.09,
  label: null,
};

const LeafletGeoSearchMap = ({position, height, width}) => {
  return (
    <MapContainer
      center={position || initialPosition}
      zoom={13}
      style={{height: height || '400px', width: width || '100%', borderRadius: '5px'}}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <DraggableMarker position={position} />
    </MapContainer>
  );
};

export default LeafletGeoSearchMap;

function DraggableMarker({position}) {
  const map = useMapEvents({});

  useEffect(() => {
    if (map) {
      map.flyTo(position, map.getZoom());
    }
  }, [position, map]);

  return (
    <Marker
      draggable={false}
      position={position || initialPosition}
    >
      <Popup minWidth={90}>
        <span>{position?.label || 'You are here'}</span>
      </Popup>
    </Marker>
  );
}

export const initialState = {
  isShiftManagementLoading: false,
  isShiftManagementError: false,
  isShiftManagementSuccess: false,
  triggerShiftManagement: '',
  dataShiftManagement: [],
};

export const SHIFT_MANAGEMENT_STATE = {
  REQUEST: 'SHIFT_MANAGEMENT_REQUEST',
  REQUEST_FAILED: 'SHIFT_MANAGEMENT_REQUEST_FAILED',
  CLEAR_STATE: 'SHIFT_MANAGEMENT_CLEAR_STATE',
  FETCH_ALL: 'SHIFT_MANAGEMENT_FETCH_ALL',
  FETCH_ALL_SUCCCESS: 'SHIFT_MANAGEMENT_FETCH_ALL_SUCCCESS',
  FETCH_ALL_FAILED: 'SHIFT_MANAGEMENT_FETCH_ALL_FAILED',
  UPDATE: 'SHIFT_MANAGEMENT_UPDATE',
  UPDATE_SUCCESS: 'SHIFT_MANAGEMENT_UPDATE_SUCCESS',
  UPDATE_FAILED: 'SHIFT_MANAGEMENT_UPDATE_FAILED',
};

export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  ssoWhitelistData: [],
  ssoWhitelistList: [],
  ssoWhitelistDataById: [],
  ssoWhitelistMeta: {page: 1, total: 0},
  message: {
    name: '',
  },
};

export const SSO_WHITELIST_STATE = {
  REQUEST: 'SSO_WHITELIST_STATE_REQUEST',
  REQUEST_BY_ID: 'SSO_WHITELIST_STATE_REQUEST_ID',
  REQUEST_FAILED: 'SSO_WHITELIST_STATE_REQUEST_FAILED',
  CLEAR_STATE: 'SSO_WHITELIST_STATE_CLEAR_STATE',

  FETCH: 'SSO_WHITELIST_STATE_FETCH',
  FETCH_SUCCCESS: 'SSO_WHITELIST_STATE_FETCH_SUCCCESS',
  FETCH_FAILED: 'SSO_WHITELIST_STATE_FETCH_FAILED',

  FETCH_BY_ID: 'SSO_WHITELIST_STATE_FETCH_BY_ID',
  FETCH_BY_ID_SUCCCESS: 'SSO_WHITELIST_STATE_FETCH_BY_ID_SUCCCESS',
  FETCH_BY_ID_FAILED: 'SSO_WHITELIST_STATE_FETCH_BY_ID_FAILED',

  CREATE: 'SSO_WHITELIST_STATE_CREATE',
  CREATE_FAILED: 'SSO_WHITELIST_STATE_CREATE_FAILED',
  CREATE_SUCCESS: 'SSO_WHITELIST_STATE_CREATE_SUCCESS',

  UPDATE: 'SSO_WHITELIST_STATE_UPDATE',
  UPDATE_SUCCESS: 'SSO_WHITELIST_STATE_UPDATE_SUCCESS',
  UPDATE_FAILED: 'SSO_WHITELIST_STATE_UPDATE_FAILED',

  REGENERATE: 'SSO_WHITELIST_STATE_REGENERATE',
  REGENERATE_SUCCESS: 'SSO_WHITELIST_STATE_REGENERATE_SUCCESS',
  REGENERATE_FAILED: 'SSO_WHITELIST_STATE_REGENERATE_FAILED',

  DROP: 'SSO_WHITELIST_STATE_DROP',
  DROP_SUCCESS: 'SSO_WHITELIST_STATE_DROP_SUCCESS',
  DROP_FAILED: 'SSO_WHITELIST_STATE_DROP_FAILED',

  TOGGLE: 'SSO_WHITELIST_STATE_TOGGLE',
  TOGGLE_SUCCESS: 'SSO_WHITELIST_STATE_TOGGLE_SUCCESS',
  TOGGLE_FAILED: 'SSO_WHITELIST_STATE_TOGGLE_FAILED',
};

export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  partnerData: [],
  partnerList: [],
  partnerDataById: [],
  partnerMeta: {page: 1, total: 0},
  message: {
    name: '',
  },

  memberData: [],
  memberMeta: {page: 1, total: 0},
  isLoadingmember: false,
  isErrorMember: false,
  isSuccessMember: false,
  triggerMember: '',
};

export const PARTNER_STATE = {
  REQUEST: 'PARTNER_STATE_REQUEST',
  REQUEST_BY_ID: 'PARTNER_STATE_REQUEST_ID',
  REQUEST_FAILED: 'PARTNER_STATE_REQUEST_FAILED',
  CLEAR_STATE: 'PARTNER_STATE_CLEAR_STATE',

  FETCH: 'PARTNER_STATE_FETCH',
  FETCH_SUCCCESS: 'PARTNER_STATE_FETCH_SUCCCESS',
  FETCH_FAILED: 'PARTNER_STATE_FETCH_FAILED',

  FETCH_LIST: 'PARTNER_STATE_FETCH_LIST',
  FETCH_LIST_SUCCCESS: 'PARTNER_STATE_FETCH_LIST_SUCCCESS',
  FETCH_LIST_FAILED: 'PARTNER_STATE_FETCH_LIST_FAILED',

  FETCH_MEMBER: 'PARTNER_STATE_FETCH_MEMBER',
  FETCH_MEMBER_SUCCCESS: 'PARTNER_STATE_FETCH_MEMBER_SUCCCESS',
  FETCH_MEMBER_FAILED: 'PARTNER_STATE_FETCH_MEMBER_FAILED',

  FETCH_BY_ID: 'PARTNER_STATE_FETCH_BY_ID',
  FETCH_BY_ID_SUCCCESS: 'PARTNER_STATE_FETCH_BY_ID_SUCCCESS',
  FETCH_BY_ID_FAILED: 'PARTNER_STATE_FETCH_BY_ID_FAILED',

  FETCH_PROFILE: 'PARTNER_STATE_FETCH_BY_ID',
  FETCH_PROFILE_SUCCCESS: 'PARTNER_STATE_FETCH_BY_ID_SUCCCESS',
  FETCH_PROFILE_FAILED: 'PARTNER_STATE_FETCH_BY_ID_FAILED',

  RESEND_EMAIL: 'PARTNER_STATE_RESEND_EMAIL',
  RESEND_EMAIL_SUCCCESS: 'PARTNER_STATE_RESEND_EMAILSUCCCESS',
  RESEND_EMAIL_FAILED: 'PARTNER_STATE_RESEND_EMAIL_FAILED',

  CREATE: 'PARTNER_STATE_CREATE',
  CREATE_FAILED: 'PARTNER_STATE_CREATE_FAILED',
  CREATE_SUCCESS: 'PARTNER_STATE_CREATE_SUCCESS',

  UPDATE: 'PARTNER_STATE_UPDATE',
  UPDATE_SUCCESS: 'PARTNER_STATE_UPDATE_SUCCESS',
  UPDATE_FAILED: 'PARTNER_STATE_UPDATE_FAILED',

  DROP: 'PARTNER_STATE_DROP',
  DROP_SUCCESS: 'PARTNER_STATE_DROP_SUCCESS',
  DROP_FAILED: 'PARTNER_STATE_DROP_FAILED',

  TOGGLE: 'PARTNER_STATE_TOGGLE',
  TOGGLE_SUCCESS: 'PARTNER_STATE_TOGGLE_SUCCESS',
  TOGGLE_FAILED: 'PARTNER_STATE_TOGGLE_FAILED',

  REJECT: 'PARTNER_STATE_REJECT',
  REJECT_SUCCESS: 'PARTNER_STATE_REJECT_SUCCESS',
  REJECT_FAILED: 'PARTNER_STATE_REJECT_FAILED',

  APPROVE: 'PARTNER_STATE_APPROVE',
  APPROVE_SUCCESS: 'PARTNER_STATE_APPROVE_SUCCESS',
  APPROVE_FAILED: 'PARTNER_STATE_APPROVE_FAILED',

};

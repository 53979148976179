/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import {Send} from '@material-ui/icons';
import {COMPANY_TYPE_STATE as STATE} from '../../redux/companyType/companyType.types';
import {toast, showPopup, isObject, checkSuperadmin, isArray} from '../../helpers';
import {MInput, SelectMultiple, MButton, ModalTitle} from '../../components/form';
import {CompanyDatatable} from '../../components';
import styles from './styles';
import {
  getCompanyType,
  createCompanyType,
  updateCompanyType,
  deleteCompanyType,
  clearState,
} from '../../redux/companyType/companyType.actions';
import {getJobTitle} from '../../redux/jobTitle/jobTitle.actions';

class CompanyType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      searchFilter: null,
      showModal: false,
      editing: false,
      formData: {
        id: null,
        name: null,
        job_titles: [],
      },
      errorMessage: null,
      order: 'desc',
      sortBy: null,
    };
  }

  MuiTheme = () => createTheme({
    overrides: {
      MUIDataTableToolbar: {
        actions: {
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
      MUIDataTableHeadCell: {
        root: {
          '&:nth-child(1)': {
            backgroundColor: 'red !important',
            textalign: 'center !important',
            width: 20,
          },
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    this.fetchDatatable();
    this.props.getJobTitle();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message} = nextProps;

    if (isSuccess) {
      if (trigger === STATE.CREATE_SUCCESS || trigger === STATE.UPDATE_SUCCESS || trigger === STATE.DROP_SUCCESS) {
        this.handleResetForm(true);
        this.fetchDatatable();
        toast('success', message);
      }
    }

    if (this.props.trigger !== trigger && isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  fetchDatatable = () => {
    const {page, limit, searchFilter, order, sortBy} = this.state;
    this.props.getCompanyType({page, limit, query: searchFilter, order, sort_by: sortBy});
  }

  handleReload = (params) => {
    this.setState(
        params,
        () => this.fetchDatatable(),
    );
  }

  handleResetForm = (closeModal) => {
    this.setState({
      formData: {
        id: null,
        name: null,
        job_titles: [],
      },
      errorMessage: null,
    });
    if (closeModal) this.setState({showModal: false});
  }

  handleInputChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      formData: {...this.state.formData, [name]: value},
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  }

  handleMultipleChange = (name, value) => {
    this.setState({
      formData: {...this.state.formData, [name]: value},
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  }

  handleCreatePayment = () => {
    this.setState({showUpgradeModal: true});
  }

  handleMappingArray = (array) => {
    return array.map((value) => value.id);
  }

  handleFormToggle = (type, data = null) => {
    if (type === 'edit') {
      const job_title_ids = isArray(data[3]) ? this.handleMappingArray(data[3]) : [];
      this.setState({
        showModal: !this.state.showModal,
        editing: true,
        formData: {
          id: data[1],
          name: data[2],
          job_titles: job_title_ids,
        },
      });
    } else {
      this.handleResetForm();
      this.setState({
        editing: false,
        showModal: !this.state.showModal,
      });
    }
  }

  handleConfirmDelete = (data) => {
    showPopup(
        'confirm',
        'Are you sure you want to delete this data ?',
        'Yes',
        () => this.props.deleteCompanyType(data[1]),
    );
  }

  handleSearch = (keyword) => {
    this.setState({searchFilter: keyword, page: 1}, () => this.fetchDatatable());
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {createCompanyType, updateCompanyType} = this.props;
    const {editing} = this.state;
    const {id, name, job_titles} = this.state.formData;
    const params = {
      ...(name && {name}),
      ...(job_titles && isArray(job_titles) && {job_titles: JSON.stringify(job_titles)}),
    };

    return !editing ? createCompanyType(params) : updateCompanyType(id, params);
  }

  renderModalForm = () => {
    const {classes, isLoading, jobData, jobLoading} = this.props;
    const {showModal, editing, errorMessage} = this.state;
    const {name, job_titles} = this.state.formData;
    const titleDialog = editing ? 'Update Company Type' : 'Create Company Type';

    return (
      <Dialog
        open={showModal}
        onClose={() => this.handleFormToggle('close')}
      >
        <ModalTitle
          title={<div className={classes.subTitle}>{titleDialog}</div>}
          onClose={() => this.handleFormToggle('close')}
        />
        <form onSubmit={this.handleSubmit}>
          <DialogContent className={classes.dialogContent}>
            <MInput
              classNameFC={classes.formControl}
              autoFocus
              name="name"
              label="Company Type / Business Industry Name *"
              placeholder="Enter company type"
              defaultValue={name}
              onBlur={this.handleInputChange}
              error={errorMessage && errorMessage.name ? errorMessage.name : undefined}
              fullWidth
            />
            {/* <SelectMultiple
              className={classes.formControl}
              label="Job Titles"
              name="job_titles"
              placeholder="Select job titles for this company type / industry"
              keyPair={['id', 'name']}
              data={jobData}
              loading={jobLoading}
              value={job_titles}
              onChange={(value) => this.handleMultipleChange('job_titles', value)}
              error={errorMessage && errorMessage.job_titles ? errorMessage.job_titles : undefined}
            /> */}
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label='Cancel'
              onClick={() => this.handleFormToggle('close')}
            />
            <MButton
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              loading={isLoading}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  render() {
    const {classes, isLoading, companyTypeData, companyTypeMeta, isSuperAdmin} = this.props;
    const columns = [
      {name: 'id', label: 'id', display: false},
      {name: 'name', label: 'Company Types', display: true},
      {name: 'job_titles', label: 'Job Titles', display: false},
    ];

    return (
      <div className={classes.root}>
        <CompanyDatatable
          searchable
          title={'Company Types / Business Industries List'}
          loading={isLoading}
          dataSource={companyTypeData}
          total={companyTypeMeta ? companyTypeMeta.total : 0}
          page={companyTypeMeta ? companyTypeMeta.page : 1}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleCreate={() => this.handleFormToggle('add')}
          handleEdit={isSuperAdmin ? (data) => this.handleFormToggle('edit', data) : undefined}
          handleDelete={isSuperAdmin ? (data) => this.handleConfirmDelete(data) : undefined}
          handleSearch={(params) => this.handleSearch(params)}
        />
        {this.renderModalForm()}
      </div>
    );
  }
}

const stateToProps = ({auth, jobTitle, companyType}) => ({
  isSuperAdmin: checkSuperadmin(auth),
  isLoading: companyType.isLoading,
  isError: companyType.isError,
  isSuccess: companyType.isSuccess,
  message: companyType.message,
  trigger: companyType.trigger,
  companyTypeData: companyType.companyTypeData,
  companyTypeMeta: companyType.companyTypeMeta,
  jobData: jobTitle.jobData,
  jobMeta: jobTitle.jobMeta,
  jobLoading: jobTitle.isLoading,
});

const dispatchToProps = (dispatch) => ({
  getJobTitle: (params) => dispatch(getJobTitle(params)),
  getCompanyType: (params) => dispatch(getCompanyType(params)),
  createCompanyType: (params) => dispatch(createCompanyType(params)),
  updateCompanyType: (id, params) => dispatch(updateCompanyType(id, params)),
  deleteCompanyType: (id) => dispatch(deleteCompanyType(id)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(CompanyType));

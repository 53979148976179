export default (theme) => ({
    paper: {
        color: theme.palette.text.secondary,
        marginBottom: 36,
        borderRadius: 18,
        boxShadow: 'none',
        border: '',
        paddingTop: '24px',
        paddingRight: '24px',
        paddingBottom: '16px',
        paddingLeft: '24px',
    },
    topBox: {
        borderRadius: 18,
        boxShadow: 'none',
        border: '',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        backgroundColor: '#FFF',
        padding: '16px, 24px, 16px, 24px',
        marginBottom: '16px',
    },
    eventDashboard: {
        color: theme.palette.text.secondary,
        marginBottom: 36,
        borderRadius: 18,
        boxShadow: 'none',
        border: '',
        paddingTop: '12px',
        paddingRight: '16px',
        paddingBottom: '12px',
        paddingLeft: '16px',
    },
    titleText: {
        fontSize: 14,
        fontWeight: 600,
        color: '#656464',
    },
    valueText: {
        fontSize: 14,
        fontWeight: 400,
        color: '#656464',
        textTransform: 'capitalize',
    },
    dashboardItem: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '12px',
    },
    dashboardValue: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '16px',
    },
    loaderImg: {
        borderRadius: '6px',
        height: '190px',
        width: '190px',
    },
});

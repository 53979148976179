/* eslint-disable max-len */
import {initialState, MASTER_INVENTORY_STATE as STATE} from './masterInventory.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingMovement: true,
        isError: null,
        isErrorMovement: null,
        isSuccess: null,
        isSuccessMovement: null,
        trigger: action.requestType,
        triggerMovement: action.requestType,
      };

    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.requestType,
      };
    case STATE.FETCH_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        masterInventoryData: action.payload.data,
        masterInventoryMeta: action.payload.meta,
        trigger: action.type,
      };
    case STATE.FETCH_DETAIL_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        masterInventoryDataById: action.payload.data,
        trigger: action.type,
      };
    case STATE.GET_CATEGORY_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        masterInventoryCategory: action.payload.data,
        trigger: action.type,
      };
    case STATE.CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    case STATE.EXPORT:
      return {
        ...state,
        exportLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case STATE.EXPORT_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        isLoading: false,
        isError: false,
        isSuccess: true,
        exportData: action.payload,
        trigger: action.type,
      };
    case STATE.EXPORT_FAILED:
      return {
        ...state,
        exportLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.FETCH_CONDITION_HISTORY_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        conditionHistoryData: action.payload.data,
        conditionHistoryMeta: action.payload.meta,
        trigger: action.type,
      };
    case STATE.FETCH_MOVEMENT_HISTORY_SUCCCESS:
      return {
        ...state,
        isLoadingMovement: false,
        isSuccessMovement: true,
        movementHistoryData: action.payload.data,
        movementHistoryMeta: action.payload.meta,
        triggerMovement: action.type,
      };
    case STATE.FETCH_CONDITION_HISTORY_DETAIL_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        conditionHistoryDataById: action.payload.data,
        trigger: action.type,
      };
    case STATE.CREATE_CONDITION_HISTORY_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.UPDATE_CONDITION_HISTORY_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.DELETE_CONDITION_HISTORY_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.DELETE_IMAGE_SUCCCESS:
      return {
        ...state,
        isLoadingDelete: false,
        isSuccessDelete: true,
        messageDelete: action.payload,
        triggerDelete: action.type,
      };
    case STATE.DELETE_IMAGE_FAILED:
      return {
        ...state,
        isLoadingDelete: false,
        isSuccessDelete: false,
        messageDelete: action.payload,
        triggerDelete: action.type,
      };
    default:
      return state;
  }
};

/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {REGISTRATION_STATE as STATE} from '../../redux/registrationManagement/registration.types';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Button,
  Grid,
  Avatar,
  Divider,
  Paper,
  CardMedia,
  Typography,
  Link,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Chip,
} from '@material-ui/core';
import {
  CheckCircleOutline,
  Search,
  Refresh,
  CheckCircle,
  Cancel,
  Send,
} from '@material-ui/icons';
import {toast, isObject, convDate, getDay} from '../../helpers';
import {
  MInput,
  MKeyBoardDatepicker,
  MButton,
  ModalTitle,
  MSelect,
  MDatepicker,
  MKeyboardTimePicker,
} from '../../components/form';
import {Datatable} from '../../components';
import _ from 'lodash';
import {
  getListRegistration,
  putChangeRegistrationStatus,
} from './../../redux/registrationManagement/registration.actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';

class RegistrationManagement extends Component {
    constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      showDetail: false,
      showReject: false,
      resetDatatable: null,
      detailData: null,
      resetPage: null,
      status: 'waiting',
      reason: '',
      selectedStatus: 'waiting',
      selected: null,
      formData: {
        status: '',
      },
      errorMessage: null,
      order: 'desc',
      sortBy: null,
    };
  }
  UNSAFE_componentWillMount() {
    this.fetchAttendance();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isError,
      message,
      isLoadingPost,
      isSuccessPost,
      status,
      trigger,
    } = nextProps;
    // console.log(status)
    if (isError && message && !isObject(message)) toast('error', message);

    if (trigger === STATE.CHANGE_STATUS_REGISTRATION_SUCCESS && isSuccessPost) {
      if (status === 'approve') {
        toast('success', 'Successfuly Approved Registration');
      } else if (status === 'reject') {
        toast('success', 'Successfuly Rejected Registration');
      }

      this.handleResetForm(true);
      this.fetchAttendance();
      // this.props.checkStatus();
    }
  }

  fetchAttendance = () => {
    const {
      page,
      limit,
      query,
      status,
      order,
      sortBy,
    } = this.state;
    this.props.getListRegistration({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(status && status !== 'all' && {status}),
      ...(order && {order}),
      ...(sortBy && {sort_by: sortBy}),
    });
  };

  toggleModal = (type, data) => {
    const {registrationData} = this.props;
    if (type === 'detail') {
      const numberId = data.rowData[1]; // get detail attendance from datatable response, based on index
      const selected = registrationData.filter((data) => data.id === numberId);
      // console.log(selected)
      const detailData = selected && selected.length ? selected[0] : null;
      this.setState({
        showDetail: true,
        detailData,
        errorMessage: {},
        selectedStatus: detailData.status,
      });
      this.setState({});
    } else if (type === 'close') {
      this.setState({showDetail: false, detailData: null, showReject: false});
    }
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true}, () =>
      this.setState({resetPage: false}),
    );
  };

  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      order: 'desc',
      // method: 'all',
      status: 'waiting',
      detailData: null,
      sortBy: null,
    };
    this.setState(state, () => this.fetchAttendance());
    this.toggleResetPage();
    this.toggleResetDatatable(true);
  };

  handleReload = (params) => {
    this.setState(params, () => this.fetchAttendance());
  };

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null}, () => this.fetchAttendance());
    this.toggleResetPage();
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  };

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: null}, () => this.fetchAttendance());
  }, 500);

  handleInputChange = (event) => {
    const {formData, errorMessage, detailData} = this.state;
    const {value} = event.target;
    this.setState({
      selected: detailData.id,
      formData: {status: value},
    });
  };

  handleCheckInChange = (params) => {
    const {value} = params;
    const selectedTime = moment(value).format('HH:mm');
    const {formData, errorMessage} = this.state;
    const {check_out} = formData;
    if (selectedTime > check_out) {
      this.setState({
        formData: {
          ...this.state.formData,
          check_in: selectedTime,
          check_out: selectedTime,
        },
        errorMessage: errorMessage,
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          check_in: selectedTime,
        },
        errorMessage: errorMessage,
      });
    }
  }

  handleCheckOutChange = (params) => {
    const {value} = params;
    const selectedTime = moment(value).format('HH:mm');
    const {formData, errorMessage} = this.state;
    const {check_in} = formData;
    if (selectedTime <= check_in) {
      this.setState({
        formData: {
          ...this.state.formData,
          check_in: selectedTime,
          check_out: selectedTime,
        },
        errorMessage: errorMessage,
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          check_out: selectedTime,
        },
        errorMessage: errorMessage,
      });
    }
  }

  handleResetForm = (closeModal) => {
    this.setState({
      errorMessage: null,
      selected: null,
      formData: {
        status: '',
      },
    });
    if (closeModal) this.setState({showDetail: false, showReject: false});
  };

  renderModalDetail = () => {
    const {classes, isLoadingPost} = this.props;
    const {showDetail, detailData, errorMessage} = this.state;
    const name = detailData && detailData.name ? detailData.name : '-';
    const email = detailData && detailData.email ? detailData.email : '-';
    const phone = detailData && detailData.phone ? detailData.phone : '-';
    const organization_name = detailData && detailData.organization_name ? detailData.organization_name : '-';
    const organization_addres =
      detailData && detailData.organization_addres ?
        detailData.organization_addres :
        '-';
    const organization_type = detailData && detailData.organization_type ? detailData.organization_type : '-';
    const number_of_employees = detailData && detailData.number_of_employees ? detailData.number_of_employees : '-';

    const purpose = detailData && detailData.purpose ? detailData.purpose : null;

    let status = '';
    if (detailData && detailData.status) {
       if (detailData.status === 'approved') {
        status = 'approve';
       } else if (detailData.status === 'rejected') {
         status = 'reject';
       } else {
         status = detailData.status;
       }
      }
    const referral_id =
      detailData && detailData.referral_id ?
        detailData.referral_id :
        '-';
    const options = [
      {id: 'reject', name: 'Rejected'},
      {id: 'approve', name: 'Approved'},
      {id: 'waiting', name: 'Waiting'},
    ];

    return (
      <Dialog
        open={showDetail}
        onClose={() => this.toggleModal('close')}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Registration Detail"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={3}>
              <Typography>Referral Id</Typography>
            </Grid>
            <Grid item>
              <Typography> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography>{referral_id}</Typography>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={3}>
              <Typography>Name</Typography>
            </Grid>
            <Grid item>
              <Typography> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography>{name}</Typography>
            </Grid>
          </Grid>
            <Grid container wrap="nowrap" spacing={2} mb={5}>
              <Grid item xs={3}>
                <Typography>E-Mail</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{email}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2} mb={5}>
              <Grid item xs={3}>
                <Typography>Phone Number</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{phone}</Typography>
              </Grid>
            </Grid>
          <div>
            <br></br>
            <Divider/>
            <br></br>
            <Grid container wrap="nowrap" spacing={2} mb={5}>
              <Grid item xs={3}>
                <Typography>Organization Name</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{organization_name}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs={3}>
                <Typography>Organization Address</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{organization_addres}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs={3}>
                <Typography>Organization Type</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{organization_type}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs={3}>
                <Typography>Employees Number</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{number_of_employees}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs={3}>
                <Typography>Purpose</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{purpose}</Typography>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <MButton
            className={classes.btnClose}
            label="Close"
            onClick={() => this.toggleModal('close')}
          />
          {/* <MButton
            className={classes.defaultBtn}
            label="Update"
            icon={<Send />}
            onClick={() => this.changeRegistrationStatus()}
            loading={isLoadingPost}
          /> */}
        </DialogActions>
      </Dialog>
    );
  };

  handleChangeStatus = (data, status) => {
    this.setState({
      selected: data[1],
      formData: {
        status: status,
      },
    }, () => {
      if (status === 'approve') {
        Swal.fire({
          title: 'Confirmation',
          text: 'Are you sure, Approve this Registration ? ',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.value) {
            this.changeRegistrationStatus();
          }
        });
      } else {
        Swal.fire({
          title: 'Confirmation',
          text: 'Are you sure, Reject this Registration ? ',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.value) {
            this.changeRegistrationStatus();
          }
        });
      }
    });
  }

  changeRegistrationStatus = () => {
    const {formData, selectedStatus, selected} = this.state;
    const {status} = formData;
    const params = {};
      params.id = selected;
      if (selectedStatus !==status) {
        params.status = status;
      }
      this.setState({errorMessage: {}});
      this.props.putChangeRegistrationStatus(params);
  };

  renderActions = (params) => {
    return (
      <div>
        {
        params[7] && params[7] === 'waiting' && params[1] && params[1] !==null ?
        (<>
          <div style={{display: 'inline', marginRight: '5px'}}>
            <Tooltip title="APPROVED">
              <Button
                variant="contained"
                // color="primary"
                style={{
                  'backgroundColor': '#89d300',
                  'color': '#fff',
                  '&:hover': {
                    backgroundColor: '#c98200',
                  },
                  'minWidth': '10px',
                  'paddingRight': '3px',
                }}
                size="small"
                onClick={() => this.handleChangeStatus(params, 'approve')}
                startIcon={<CheckCircle />}
              >
              </Button>
            </Tooltip>
          </div>
          <div style={{display: 'inline', marginRight: '5px'}}>
            <Tooltip title="REJECTED">
              <Button
                variant="contained"
                // color="primary"
                size="small"
                style={{
                  'backgroundColor': '#d32e00',
                  'color': '#fff',
                  '&:hover': {
                    backgroundColor: '#c98200',
                  },
                  'minWidth': '10px',
                  'paddingRight': '3px',
                }}
                onClick={() => this.handleChangeStatus(params, 'reject')}
                startIcon={<Cancel />}
                >
              </Button>
            </Tooltip>
          </div>
        </>
      ) :
      null}

      </div>
    );
  };

  renderFilter = () => {
    const {classes, message} = this.props;
    const {query, startDate, endDate, status} = this.state;
    const renderMethodOptions = () => {
      const options = [
        {id: 'all', name: 'All'},
        {id: 'waiting', name: 'Waiting'},
        {id: 'approved', name: 'Approved'},
        {id: 'rejected', name: 'Rejected'},
      ];
      return options;
    };

    return (
      <Paper variant='outlined' className={classes.paper}>
        <Grid container alignItems="center">
          <MInput
            autoFocus
            className={`${classes.filter} ${classes.searchInput}`}
            fullWidth={false}
            variant="outlined"
            name="query"
            label="Search"
            placeholder="Search keyword"
            value={query}
            onChange={this.handleSearch}
            InputProps={{
              endAdornment: (
                <IconButton className={classes.searchIcon} position="end">
                  {' '}
                  <Search />
                </IconButton>
              ),
            }}
          />
        <MSelect
          shrink={true}
          fullWidth={false}
          classNameFC={`${classes.formControl} ${classes.filter} ${classes.selectInput}`}
          variant="outlined"
          name="status"
          label="Status"
          keyPair={['id', 'name']}
          options={renderMethodOptions()}
          value={status}
          onChange={this.handleFilterChange}
        />
        <div className={classes.btnFilterContainer}>
          <MButton
            className={classes.defaultBtn}
            label="Reset"
            color="primary"
            icon={<Refresh />}
            onClick={this.handleReset}
            style={{position: 'relative', top: 4}}
          />
        </div>
        </Grid>
      </Paper>
    );
  };

    render() {
    const {classes, registrationData, registrationMeta, isLoading} = this.props;
    const {resetDatatable, resetPage} = this.state;
    const columns = [
      {
        name: 'no',
        label: 'No',
        display: true,
        width: '300px',
      },
      {
        name: 'id',
        label: 'id',
        display: false,
      },
      {
        name: 'name',
        label: 'Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'email',
        label: 'E-mail',
        display: true,
      },
      {
        name: 'phone',
        label: 'Phone Number',
        display: true,
      },
      {
        name: 'number_of_employees',
        label: 'Number Of Employees',
        display: false,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value}</div>;
        },
      },
      {
        name: 'organization_name',
        label: 'Organization Name',
        display: true,
      },
      {
        name: 'organization_type',
        label: 'Organization Type',
        display: true,
      },
      {
        name: 'status',
        label: 'status',
        display: true,
        customBodyRender: (value) => {
          let cs = '';
          if (value === 'waiting') cs = '';
          if (value === 'approved') cs = 'primary';
          if (value === 'rejected') cs = 'secondary';
          return <Chip label={value} color={cs} />;
        },
      },
      {
        name: 'created_at',
        label: 'Register Date',
        display: true,
        customBodyRender: (value) => {
          return value ?
          (
            <Typography
              style={{whiteSpace: 'nowrap', fontSize: '14px'}}
            >
              {convDate(value, 'DD-MM-YYYY')}
            </Typography>
          ) :
          '-';
        },
      },
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Datatable
          // searchable
          creatable={false}
          title={'User Registration List'}
          loading={isLoading}
          dataSource={registrationData}
          total={registrationMeta.total}
          page={registrationMeta.page}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleDetail={(params) => this.toggleModal('detail', params)}
          // customActions={(params) => this.renderActions(params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
        />
        {this.renderModalDetail()}
      </div>
    );
  }
}

const mapStateToProps = ({registration, userManagement}) => {
  return {
    isLoading: registration.isLoading,
    isSuccess: registration.isSuccess,
    isError: registration.isError,
    message: registration.message,
    registrationData: registration.registrationData,
    registrationMeta: registration.registrationMeta,
    detailLoading: registration.detailLoading,
    detailData: registration.detailData,
    trigger: registration.trigger,
    attendanceTypes: userManagement.attendanceTypes,
    isLoadingPost: registration.isLoadingPost,
    isSuccessPost: registration.isSuccessPost,
    isErrorPost: registration.isErrorPost,
    messagePost: registration.messagePost,
    status: registration.status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getListRegistration: (params) => dispatch(getListRegistration(params)),
  putChangeRegistrationStatus: (params) =>
    dispatch(putChangeRegistrationStatus(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(RegistrationManagement));

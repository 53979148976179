/* eslint-disable no-dupe-keys */
export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  searchInput: {
    width: '310px',
  },
  searchSelect: {
    width: '150px',
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  modalTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#656464',
    textalign: 'start',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  checkInTrue: {
    color: '#89d300',
    fontSize: 20,
  },
  checkInFalse: {
    color: '#d30000',
    fontSize: 20,
  },
  tableHeaderIcon: {
    fontSize: 30,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    marginBottom: 30,
  },
  fade: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    width: 600,
  },
  modalItemSpacing: {
    margin: theme.spacing(1),
  },
  bold: {
    fontWeight: 'bold',
    color: 'black',
  },
  modalAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  modalCloseBtn: {
    float: 'right',
    marginBottom: 10,
  },
  dialogContent: {
    padding: 0,
  },
  cardColor: {
    backgroundColor: '#ffffff',
    width: '70%',
  },
  imgSize: {
    width: 128,
    height: 128,
  },
  modalStyle1: {
    overflow: 'scroll',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterBtn: {
    'marginTop': 25,
    'marginRight': 20,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#FFF',
  },
  filterBtnEnd: {
    marginTop: 25,
    marginRight: 0,
  },
  filter: {
    marginRight: 14,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  filterDatePicker: {
    marginRight: 25,
    maxWidth: 175,
  },
  detailBtn: {
    'backgroundColor': '#ef9c02',
    'color': '#fff',
    '&:hover': {
      backgroundColor: '#c98200',
    },
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  btnExport: {
    'backgroundColor': '#009688',
    '&:hover': {
      backgroundColor: '#00796b',
    },
    'color': '#fff',
  },
  modalTitle: {
    textalign: 'center',
    fontWeight: 600,
  },
  titleMargin: {
    position: 'relative',
    right: 9,
    marginBottom: 24,
  },
  companyName: {
    marginLeft: 16,
    fontSize: 14,
    fontWeight: 400,
  },
  tableImg: {
    height: 50,
    width: 50,
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'marginRight': '14px',
    'marginBottom': '14px',
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#d5d5d5',
    },
    'marginBottom': '14px',
  },

  badgePaid: {
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#005900',
    },
    'backgroundColor': '#008000',
    'color': 'white',
    'boxShadow': 'none',
    'borderRadius': 8,
    'height': 24,
    'fontSize': 12,
    'textTransform': 'capitalize',
  },
  badgeUnpaid: {
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#D09F53',
    },
    'backgroundColor': '#E5AF5A',
    'color': 'white',
    'boxShadow': 'none',
    'borderRadius': 8,
    'height': 24,
    'fontSize': 12,
    'textTransform': 'capitalize',
  },
  badgeInvalid: {
    'backgroundColor': '#F00B38',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#8E0721',
    },
    'color': 'white',
    'boxShadow': 'none',
    'borderRadius': 8,
    'height': 24,
    'fontSize': 12,
    'textTransform': 'capitalize',
  },

  formControlInvoices: {
    marginTop: '8px !important',
  },
  formControlAmount: {
    marginTop: '18px !important',
  },
  formControlDatePicker: {
    marginTop: '22px !important',
  },
});

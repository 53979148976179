export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: null,
  message: null,
  todayData: [],
  todayMeta: {page: 1, total: 0},
  monthData: [],
  monthMeta: {page: 1, total: 0},
};

export const LEADERBOARD_STATE = {
  REQUEST: 'LEADERBOARD_REQUEST',
  REQUEST_FAILED: 'LEADERBOARD_REQUEST_FAILED',
  FETCH_TODAY: 'TODAY_FETCH',
  FETCH_TODAY_SUCCESS: 'TODAY_FETCH_SUCCESS',
  FETCH_TODAY_FAILED: 'TODAY_FETCH_FAILED',
  FETCH_MONTH: 'MONTH_FETCH',
  FETCH_MONTH_SUCCESS: 'MONTH_FETCH_SUCCESS',
  FETCH_MONTH_FAILED: 'MONTH_FETCH_FAILED',
};

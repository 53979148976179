/* eslint-disable max-len */
import {ENDPOINTS, apiService} from '../config';

export const getPaidLeaveHistory = (params) => {
  return apiService.get(ENDPOINTS.PAID_LEAVE.HISTORY, {
    params,
  });
};

export const putPaidLeaveOvertime = (id, params) => {
  return apiService.put(
      ENDPOINTS.PAID_LEAVE.UPDATE + id,
      params,
  );
};

export const getPaidLeaveByToken = (token) => {
  return apiService.get(ENDPOINTS.APPROVAL.LEAVE_DETAILS + token);
};

export const confirmApproval = (params) => {
  return apiService.put(ENDPOINTS.APPROVAL.LEAVE_CONFIRM + params.status + '/' + params.token, {
    rejected_reason: params.rejected_reason,
  });
};

export const exportExcel = (params) => {
  return apiService.post(
      ENDPOINTS.PAID_LEAVE.EXPORT,
      params,
  );
};

import {Dialog, DialogActions, DialogContent, withStyles} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {MButton, MInput, ModalTitle} from '../../../../../components/form';
import styles from './styles';
import {Block} from '@material-ui/icons';
import {useSelector} from 'react-redux';

const initialState = {
  reason: null,
  error: null,
};

const BlockEventPopup = ({
  classes,
  open,
  toggle,
  handleSubmit,
}) => {
  const {isLoadingBlockEvent} = useSelector((state) => state?.eventManagement);

  const [state, setState] = useState({
    reason: null,
    error: null,
  });

  const handleChange = (event) => {
    const {name, value} = event?.target || {};

    setState((prevState) => ({
      ...prevState,
      [name]: value,
      error: value ? null : 'Reason is required',
    }));
  };

  const onSubmit = (e) => {
    e && e.preventDefault();

    if (!state?.reason) {
      setState((prevState) => ({...prevState, error: 'Reason is required'}));
      return;
    }

    handleSubmit(state?.reason);
  };

  useEffect(() => {
    if (!open) setState(initialState);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={toggle}
      maxWidth="sm"
      fullWidth={true}
      scroll="paper"
    >
      <ModalTitle
        className={classes.modalTitle}
        title="Block Event"
        onClick={toggle}
      />
      <DialogContent className={classes.dialogContent}>
        <MInput
          classNameFC={classes.formControl}
          multiline
          rows={2}
          rowsMax={3}
          name="reason"
          label="Reason"
          placeholder="Fill in the reason"
          onChange={handleChange}
          value={state?.reason}
          error={state?.error}
          readOnly={isLoadingBlockEvent}
        />
      </DialogContent>

      <DialogActions>
        <MButton
          className={classes.cancelBtn}
          label="Cancel"
          onClick={toggle}
          loading={isLoadingBlockEvent}
          disabled={isLoadingBlockEvent}
          />

        <MButton
          className={classes.defaultBtnReject}
          label="Block"
          icon={<Block />}
          loading={isLoadingBlockEvent}
          onClick={onSubmit}
          disabled={isLoadingBlockEvent}
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(BlockEventPopup);

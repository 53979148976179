export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
  trigger: '',
  creatable: null,
  creatableMessage: null,
  premium: null,
  trial: null,
  expiredDate: null,
  dueDate: null,
  expiredMessage: null,
  paymentMethod: null,
  errors: [],
  price: null,
  plan: null,
  payment: null,
  lastPlan: null,
  data: [],
};

export const UPGRADE_STATE = {
  REQUEST: 'UPGRADE_REQUEST',
  REQUEST_FAILED: 'UPGRADE_REQUEST_FAILED',
  CLEAR_STATE: 'UPGRADE_CLEAR_STATE',
  BANK_PAYMENT: 'UPGRADE_BANK_PAYMENT',
  BANK_PAYMENT_SUCCESS: 'UPGRADE_BANK_PAYMENT_SUCCESS',
  BANK_PAYMENT_FAILED: 'UPGRADE_BANK_PAYMENT_FAILED',

  CHECK: 'UPGRADE_CHECK',
  CHECK_SUCCESS: 'UPGRADE_CHECK_SUCCESS',
  CHECK_FAILED: 'UPGRADE_CHECK_FAILED',

};


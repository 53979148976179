import {Grid, IconButton, Tooltip, Typography, withStyles} from '@material-ui/core';
import React from 'react';
import {MButton, MInput} from '../../../../components/form';
import {Delete as DeleteIcon, HelpOutline} from '@material-ui/icons';
import styles from './styles';

const PaymentPackage = ({
  classes,
  packageKey,
  handleChange,
  handleDelete,
  disabled,
  register,
  isFirst,
  getValues,
  getError,
}) => {
  return (
    <Grid container style={{padding: '8px'}}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{paddingTop: '10px', paddingBottom: '10px'}}
      >
        <Typography className={classes.eventPaymentPackageHeader}>
          Package
        </Typography>

        {isFirst || disabled ? null : <MButton
          color="secondary"
          size="normal"
          onClick={() => handleDelete(packageKey)}
          icon={<DeleteIcon fontSize="medium" />}
          style={{
            background: '#E6B260',
            minWidth: '10px',
            paddingRight: '0px',
          }}
        />}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MInput
            {...register('packages[' + packageKey + '].name')}
            label="Package Name *"
            onChange={(event) => handleChange(packageKey, event)}
            value={getValues(`packages.${packageKey}.name`)}
            disabled={disabled}
            error={getError(packageKey, 'name', 'Package Name')}
          />
        </Grid>

        <Grid item xs={2}>
          <MInput
            {...register('packages[' + packageKey + '].price')}
            onChange={(event) => handleChange(packageKey, event)}
            type="number"
            min="1"
            value={getValues(`packages.${packageKey}.price`)}
            label="Package Price *"
            placeholder="50000"
            disabled={disabled}
            error={getError(packageKey, 'price', 'Package Price')}
          />
        </Grid>

        <Grid item xs={2}>
          <MInput
            {...register('packages[' + packageKey + '].maximum_participants')}
            type="number"
            label="Maximum Participants *"
            min="1"
            placeholder="5"
            onChange={(event) => handleChange(packageKey, event)}
            value={getValues(`packages.${packageKey}.maximum_participants`)}
            disabled={disabled}
            error={getError(packageKey, 'maximum_participants')}
          />
        </Grid>

        <Grid item xs={2}>
          <MInput
            {...register('packages[' + packageKey + '].index')}
            type="number"
            label="Package Order *"
            min="1"
            placeholder={packageKey + 1}
            onChange={(event) => handleChange(packageKey, event)}
            value={getValues(`packages.${packageKey}.index`)}
            InputProps={{
              endAdornment: (
                <IconButton className={classes.searchIcon} position="end">
                  <Tooltip title={'Determine the sort of packages using numeric index (1, 2 etc.)'}>
                    <HelpOutline fontSize="medium" />
                  </Tooltip>
                </IconButton>
              ),
            }}
            disabled={disabled}
            error={getError(packageKey, 'index', 'Package Order')}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <MInput
          {...register('packages[' + packageKey + '].description')}
          onChange={(event) => handleChange(packageKey, event)}
          value={getValues(`packages.${packageKey}.description`)}
          label="Description *"
          placeholder="Description"
          maxRows={5}
          InputProps={{
            endAdornment: (
              <IconButton className={classes.searchIcon} position="end">
                <Tooltip title={'For example: VIP ticket holder are eligible to have a lunch on the spot'}>
                  <HelpOutline fontSize="medium" />
                </Tooltip>
              </IconButton>
            ),
          }}
          error={getError(packageKey, 'description')}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PaymentPackage);

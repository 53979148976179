export default (theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      color: theme.palette.text.secondary,
      marginBottom: 36,
      borderRadius: 18,
      boxShadow: 'none',
    },
    datatable: {
      marginTop: 25,
    },
    title: {
      lineHeight: 0,
      marginBottom: 30,
      fontSize: '1.8em',
    },
    checkBox: {
      width: 70,
    },
    formControl: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    defaultBtn: {
      'backgroundColor': '#00AFF0',
      'boxShadow': 'none',
      '&:hover': {
        backgroundColor: '#00A2DE',
        boxShadow: 'none',
      },
      'color': '#fff',
    },
    cancelBtn: {
      'backgroundColor': '#e0e0e0',
      'boxShadow': 'none',
      '&:hover': {
        backgroundColor: '#d5d5d5',
        boxShadow: 'none',
      },
    },
    hasError: {
      color: 'red',
    },
    photo: {
      height: 150,
      width: 'auto',
      backgroundPosition: 'left',
      backgroundSize: 'contain',
    },
    defaultBtnReset: {
      'backgroundColor': '#00AFF0',
      'boxShadow': 'none',
      '&:hover': {
        backgroundColor: '#00A2DE',
        boxShadow: 'none',
      },
      'color': '#fff',
      'marginTop': 25,
      'marginRight': 15,
      'marginLeft': 10,
    },
    filterBtn: {
      marginTop: 25,
      marginRight: 15,
      marginLeft: 10,
    },
    filterBtnEnd: {
      marginTop: 25,
      marginRight: 15,
    },
    filter: {
      marginRight: 25,
    },
    filterSearch: {
      minWidth: 300,
      marginRight: 25,
    },
    btnFilterContainer: {
      display: 'inline-flex',
    },
    dialogFooter: {
      padding: 20,
    },
    dialogContent: {
      paddingTop: '0 !important',
    },
    searchIcon: {
      position: 'absolute',
      right: 0,
    },
    btnCrop: {
      marginTop: 10,
    },
    searchInput: {
      width: '350px',
    },
    syncTrue: {
      color: '#89d300',
      fontSize: 20,
    },
    syncFalse: {
      color: '#34a3ed',
      fontSize: 20,
    },
    syncWarn: {
      color: '#FFCC00',
      fontSize: 20,
    },
    btnExport: {
      'backgroundColor': '#009688',
      '&:hover': {
        backgroundColor: '#00796b',
      },
      'color': '#fff',
    },
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
    dropZoneArea: {
      padding: 15,
      minHeight: 160,
      minWidth: 500,
    },
    dropZoneText: {
      color: '#8D8D97',
      fontSize: '1.4em',
    },
    dropZoneIcon: {
      color: '#8D8D97',
    },
    hidden: {
      minWidth: 500,
      visibility: 'hidden',
      height: 0,
      minHeight: 0,
    },
  });


export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  jobData: [],
  jobDataByOrg: [],
  jobMeta: {page: 1, total: 0},
  message: {
    name: '',
  },
  exportLoading: false,
  exportData: {file: null},
};

export const JOB_TITLE_STATE = {
  REQUEST: 'JOB_TITLE_REQUEST',
  REQUEST_FAILED: 'JOB_TITLE_REQUEST_FAILED',
  CLEAR_STATE: 'JOB_TITLE_CLEAR_STATE',
  FETCH: 'JOB_TITLE_FETCH',
  FETCH_SUCCCESS: 'JOB_TITLE_FETCH_SUCCCESS',
  FETCH_FAILED: 'JOB_TITLE_FETCH_FAILED',
  CREATE: 'JOB_TITLE_CREATE',
  CREATE_FAILED: 'JOB_TITLE_CREATE_FAILED',
  CREATE_SUCCESS: 'JOB_TITLE_CREATE_SUCCESS',
  UPDATE: 'JOB_TITLE_UPDATE',
  UPDATE_SUCCESS: 'JOB_TITLE_UPDATE_SUCCESS',
  UPDATE_FAILED: 'JOB_TITLE_UPDATE_FAILED',
  DROP: 'JOB_TITLE_DROP',
  DROP_SUCCESS: 'JOB_TITLE_DROP_SUCCESS',
  DROP_FAILED: 'JOB_TITLE_DROP_FAILED',
  FETCH_BY_ORG: 'JOB_TITLE_FETCH_BY_ORG',
  FETCH_BY_ORG_SUCCCESS: 'JOB_TITLE_FETCH_BY_ORG_SUCCCESS',
  FETCH_BY_ORG_FAILED: 'JOB_TITLE_FETCH_BY_ORG_FAILED',
  EXPORT: 'JOB_TITLE_EXPORT',
  EXPORT_SUCCESS: 'JOB_TITLE_EXPORT_SUCCESS',
  EXPORT_FAILED: 'JOB_TITLE_EXPORT_FAILED',
};

/* eslint-disable no-dupe-keys */
export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: 2,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  paperTableFilter: {
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    paddingTop: 0,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    borderRadius: '0px 18px 0px 0px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },
  paperTabs: {
    paddingTop: 2,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: '0px 18px 18px 18px',
    boxShadow: 'none',
  },
  paperNavigation: {
    padding: '12px 16px 12px 16px',
    color: theme.palette.text.secondary,
    borderRadius: 18,
    boxShadow: 'none',
    marginBottom: 36,
  },
  paperTimeline: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  paperInformation: {
    borderRadius: 18,
    padding: 16,
    marginBottom: 36,
    boxShadow: 'none',
    color: theme.palette.text.secondary,
  },
  paperTitle: {
    fontSize: '18px',
    fontWeight: '700',
    color: '#656464',
    fontFamily: 'Poppins !important',
    marginLeft: 8,
  },
  formControlSearchBy: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: 150,
  },
  formControlSearchBy: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: 150,
  },
  formControlStatus: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 170,
  },
  formControl: {
    minWidth: 120,
  },
  formControlSearch: {
    minWidth: 100,
    marginTop: 16,
    marginBottom: 8,
    width: 360,
  },
  formControlDate: {
    minWidth: 100,
    marginTop: 32,
    marginBottom: 8,
    width: 180,
  },
  formControlModal: {
    minWidth: 120,
    marginBottom: theme.spacing(1),
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  accordionTitle: {
    fontSize: 14,
    fontWeight: '600',
    color: '#656464',
  },
  accordionValue: {
    fontSize: 14,
    fontWeight: '700',
    color: '#00A2DE',
  },
  statusMainText: {
    fontSize: 14,
    fontWeight: '600',
    color: '#00A2DE',
  },
  statusAvailable: {
    fontSize: 14,
    fontWeight: '700',
    color: '#00A2DE',
  },
  statusValueAvailable: {
    fontSize: 14,
    fontWeight: '700',
    color: '#00A2DE',
    textTransform: 'capitalize',
  },
  statusValueUnavailable: {
    fontSize: 14,
    fontWeight: '700',
    color: '#F00B38',
    textTransform: 'capitalize',
  },
  statusSubText: {
    fontSize: 12,
    fontWeight: '400',
    color: '#00A2DE',
  },
  timelineCondition: {
    fontSize: 14,
    fontWeight: '400',
    color: '#00A2DE',
  },
  statusRequest: {
    fontSize: 14,
    fontWeight: '700',
    color: '#00A2DE',
  },
  statusOngoing: {
    fontSize: 14,
    fontWeight: '700',
    color: '#FFC226',
  },
  statusRejected: {
    fontSize: 14,
    fontWeight: '700',
    color: '#F00B38',
  },
  statusCompleted: {
    fontSize: 14,
    fontWeight: '700',
    color: '#008000',
  },
  timelineDate: {
    fontSize: 12,
    fontWeight: '400',
    color: '#737373',
  },
  informationTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: '#656464',
    marginBottom: 8,
  },
  informationDetail: {
    fontSize: 14,
    fontWeight: '400',
    color: '#656464',
    lineBreak: 'anywhere',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  checkInTrue: {
    color: '#008000',
    fontSize: 20,
  },
  checkInFalse: {
    color: '#F00B38',
    fontSize: 20,
  },
  tableHeaderIcon: {
    fontSize: 30,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  fade: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    width: 600,
  },
  modalItemSpacing: {
    margin: theme.spacing(1),
  },
  bold: {
    fontWeight: 'bold',
    color: 'black',
  },
  modalAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  btnBack: {
    'boxShadow': 'none',
    'color': '#656464',
    'marginRight': '24px',
  },
  modalCloseBtn: {
    float: 'right',
    marginBottom: 10,
  },
  dialogFooter: {
    paddingBottom: 12,
    paddingTop: 12,
  },
  dialogContent: {
    paddingTop: '12 !important',
  },
  cardColor: {
    backgroundColor: '#ffffff',
    width: '70%',
  },
  imgSize: {
    width: 128,
    height: 128,
  },
  modalStyle1: {
    overflow: 'scroll',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterBtn: {
    'marginTop': 22,
    'marginRight': 20,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  filterBtnReset: {
    'marginTop': 40,
    'marginRight': 20,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  btnTabActive: {
    'backgroundColor': '#FFFFFF',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#00AFF0',
      boxShadow: 'none',
    },
    'color': '#00AFF0',
    'borderTop': '1px solid rgba(224, 224, 224, 1)',
    'borderLeft': '1px solid rgba(224, 224, 224, 1)',
    'borderRight': '1px solid rgba(224, 224, 224, 1)',
    'borderRadius': '10px 18px 8px 18px',
    'borderRadius': '8px 8px 0px 0px',
    'padding': '12px 16px 8px 16px',
  },
  btnTabInactive1: {
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    'borderTop': '2px solid rgba(224, 224, 224, 1)',
    'borderLeft': '2px solid rgba(224, 224, 224, 1)',
    'borderRight': '0px solid rgba(224, 224, 224, 1)',
    'borderRadius': '10px 18px 8px 18px',
    'borderRadius': '8px 8px 0px 0px',
    'padding': '12px 16px 8px 16px',
  },
  btnTabInactive2: {
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    'borderTop': '2px solid rgba(224, 224, 224, 1)',
    'borderLeft': '0px solid rgba(224, 224, 224, 1)',
    'borderRight': '2px solid rgba(224, 224, 224, 1)',
    'borderRadius': '10px 18px 8px 18px',
    'borderRadius': '8px 8px 0px 0px',
    'padding': '12px 16px 8px 16px',
  },
  btnTabInactive: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  filterBtnEnd: {
    marginTop: '22px',
    marginRight: 0,
  },
  filter: {
    marginRight: 25,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  filterDatePicker: {
    marginRight: 25,
    maxWidth: 140,
  },
  approvalDate: {
    width: '100%',
  },
  detailBtn: {
    'backgroundColor': '#00AFF0',
    'color': '#fff',
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#00A2DE',
    },
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  btnExport: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  tableImg: {
    height: 50,
    width: 50,
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'marginTop': '5px',
    'marginBottom': '5px',
    'marginRight': '16px',
  },
  createHistoryBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  defaultBtnReject: {
    'backgroundColor': '#E5AF5A',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#D09F53',
      boxShadow: 'none',
    },
    'color': '#fff',
    'marginTop': '5px',
    'marginBottom': '5px',
    'marginRight': '13px',
  },
  approveButton: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  rejectButton: {
    'backgroundColor': '#E5AF5A',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#D09F53',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow: 'none',
    },
    'color': 'rgba(0, 0, 0, 0.87);',
    'marginTop': '5px',
    'marginBottom': '5px',
  },

  lastUpdatedText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  lastUpdatedValue: {
    fontSize: 14,
    fontWeight: 600,
    color: '#656464',
  },
  timelineConditionMain: {
    fontSize: 12,
    fontWeight: 400,
    color: '#656464',
  },
  timelineConditionNotes: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  statusPaper: {
    padding: 12,
    borderRadius: 8,
    marginBottom: 24,
  },
  noHistory: {
    marginBottom: 8,
    marginLeft: 16,
    fontSize: 12,
    fontWeight: 400,
    color: '#656464',
  },
  statusText: {
    fontSize: 14,
    fontWeight: 600,
    color: '#656464',
  },
  showMore: {
    color: '#00AFF0',
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
  },
  statusForm: {
    marginTop: 8,
    color: '#777777',
    fontSize: 14,
    fontWeight: 400,
  },
  conditionHistoryForm: {
    color: '#444B55',
    fontSize: 14,
    fontWeight: 500,
  },
  inventoryInformation: {
    fontSize: 18,
    fontWeight: 700,
    color: '#656464',
    fontFamily: 'Poppins !important',
    marginBottom: 18,
  },
  informationSubtitle: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  photoTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: '#656464',
  },
  photoTitleError: {
    fontSize: 14,
    fontWeight: 400,
    color: '#F00B38',
  },
  photoSubTitle: {
    fontSize: 12,
    fontWeight: 400,
    color: '#656464',
  },
  photoSubTitleError: {
    fontSize: 12,
    fontWeight: 400,
    color: '#F00B38',
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  buttonUpload: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'marginTop': '8px',
    'marginBottom': '8px',
  },
  btnNavBack: {
    'position': 'absolute',
    'top': '50%',
    'right': '94%',
    'transform': 'translateY(-50%)',
    'zIndex': 1,
    'color': '#000',
    'backgroundColor': '#fff',
    'padding': 2,
    '&:hover': {
      backgroundColor: '#00A2DE',
      color: '#fff',
      boxShadow: 'none',
    },
  },
  btnNavForward: {
    'position': 'absolute',
    'top': '50%',
    'left': '94%',
    'transform': 'translateY(-50%)',
    'zIndex': 1,
    'color': '#000',
    'backgroundColor': '#fff',
    'padding': 2,
    '&:hover': {
      backgroundColor: '#00A2DE',
      color: '#fff',
      boxShadow: 'none',
    },
  },
});

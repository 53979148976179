export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  detailLoading: false,
  detailError: false,
  detailSuccess: false,
  detailData: '',
  trigger: null,
  message: null,
  registrationData: [],
  registrationMeta: {page: 1, total: 0},
  isLoadingPost: false,
  isSuccessPost: false,
  isErrorPost: false,
  messagePost: '',
  status: null,
};

export const REGISTRATION_STATE = {
  REQUEST: 'REGISTRATION_REQUEST',
  REQUEST_FAILED: 'REGISTRATION_REQUEST_FAILED',
  FETCH: 'REGISTRATION_FETCH',
  FETCH_SUCCESS: 'REGISTRATION_FETCH_SUCCESS',
  FETCH_FAILED: 'REGISTRATION_FETCH_FAILED',
  CHANGE_STATUS_REGISTRATION: 'CHANGE_STATUS_REGISTRATION',
  CHANGE_STATUS_REGISTRATION_SUCCESS: 'CHANGE_STATUS_REGISTRATION_SUCCESS',
  CHANGE_STATUS_REGISTRATION_FAILURE: 'CHANGE_STATUS_REGISTRATION_FAILURE',
};

/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import styles from './styles';
import {
  Button,
  Dialog,
  Paper,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  Search,
  Refresh,
} from '@material-ui/icons';
import {
  convDate,
} from '../../../helpers';
import SearchLogo from '../../../assets/img/Search.svg';
import {MInput, MKeyBoardDatepicker, MButton, ModalTitle, MSelect} from '../../../components/form';
import {LeaveDatatable} from '../../../components';
import _ from 'lodash';
import {checkStatus as checkStatusUser} from '../../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../../redux/organization/organization.actions';
import {
  getLeaveManagement,
} from './../../../redux/leaveManagement/leaveManagement.actions';
import {
  getLeaveManagementDetail,
  clearState,
} from './../../../redux/leaveManagementDetail/leaveManagementDetail.actions';
import {
  getLeaveConfig,
} from './../../../redux/leaveConfig/leaveConfig.actions';
import moment from 'moment';
import {LEAVE_MANAGEMENT_DETAIL_STATE as STATE} from './../../../redux/leaveManagementDetail/leaveManagementDetail.types';
import Datatable from '../../../components/datatable';
class LeaveManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      query: '',
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
      method: 'all',
      completed: null,
      showDetail: false,
      resetDatatable: null,
      detailData: null,
      resetPage: null,
      modalFormShow: false,
      isEdit: false,
      datas: {
        name: null,
        paidData: null,
        paidleaves: null,
      },
      paidLength: 0,
      selectedName: null,
      selectedId: null,
      loadingModal: false,
      isLoadingClick: false,
    };
  }

  getMuiTheme = () => createTheme({
    overrides: {
      MuiDialog: {
        paperScrollPaper: {
          display: 'inline !important',
        },
      },
    },
  });

  UNSAFE_componentWillMount() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const name = url.searchParams.get('query');

    // this.props.checkStatus(); // check company feature status availability {remote, business, office}
    this.props.clearState();
    if (name) {
      this.setState({query: name}, () => {
        this.handleDebounceSearch(name);
      });
    } else {
      this.fetchManagement();
      this.fetchLeave();
    }
    this.props.checkStatus();
    this.props.checkStatusUser();
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {triggerDetail, isSuccessDetail, leaveManagementDetailData} = nextProps;

    if (isSuccessDetail) {
      if (triggerDetail === STATE.FETCH_DETAIL_SUCCCESS) {
        this.setState({
          loadingModal: false,
          isLoadingClick: false,
        });
      }
    }

    // if (trigger === STATE.EXPORT_SUCCESS && isSuccess && exportData) {
    //   toast('success', 'File successfully exported & downloaded');
    // }

    // if (isError && message && !isObject(message)) toast('error', message);
  }

  componentDidMount() {
    // this.handleCheckQueryParam();
  }

  handleFormToggle = (isEdit, params) => {
    this.setState({
      modalFormShow: !this.state.modalFormShow,
      isLoadingClick: true,
    });
    const {leaveManagementDetailData} = this.props;
    const datas = leaveManagementDetailData.filter((value) => {
      if (params !== undefined) {
        return value.name === params[1];
      }
    });
    let paidData;
    const mapping = datas.map((value) => {
      this.setState({
        datas: {...this.state.datas,
          name: value.name,
        },
      }, () => {
        if (value.paidleaves.length > 0) {
          value.paidleaves.map((field) => {
            this.setState({
              datas: {...this.state.datas,
                total_length: field.data.length,
                paidleaves: value.paidleaves,
                paidData: field.data,
              },
            });
          });
        } else {
          this.setState({
            datas: {...this.state.datas,
              paidleaves: [],
              paidData: [],
            },
          });
        }
      });
    });
  }
  handleClickDetail = (isEdit, params) => {
    this.setState({
      modalFormShow: true,
    });
    this.props.getLeaveManagementDetail({
      id: params[4],
      page: this.state.page,
      limit: this.state.limit,
    });
    this.setState({
      selectedName: params[1],
      selectedId: params[4],
    });
    // const {leaveManagementData} = this.props;
    // const datas = leaveManagementData.filter((value) => {
    //   if (params !== undefined) {
    //     return value.name === params[1];
    //   }
    // });
    // let paidData;
    // const mapping = datas.map((value) => {
    //   this.setState({
    //     datas: {...this.state.datas,
    //       name: value.name,
    //     },
    //   }, () => {
    //     if (value.paidleaves.length > 0) {
    //       value.paidleaves.map((field) => {
    //         this.setState({
    //           datas: {...this.state.datas,
    //             total_length: field.data.length,
    //             paidleaves: value.paidleaves,
    //             paidData: field.data,
    //           },
    //         });
    //       });
    //     } else {
    //       this.setState({
    //         datas: {...this.state.datas,
    //           paidleaves: [],
    //           paidData: [],
    //         },
    //       });
    //     }
    //   });
    // });
  }

  fetchLeave() {
    const {page, limit} = this.state;
    this.props.getLeaveConfig({page, limit});
  }

  fetchManagement = () => {
    const {page, limit, query} = this.state;
    this.props.getLeaveManagement({page, limit, query});
  }
  fetchManagementDetail = () => {
    const {page, limit, query} = this.state;
    this.props.getLeaveManagementDetail({
      id: this.state.selectedId,
      page: this.state.page,
      limit: this.state.limit,
    });
  }

  handleReload = (params) => {
    this.setState(
        params,
        () => this.fetchManagement(),
    );
  }
  handleReloadDetail = (params) => {
    this.setState({
      loadingModal: true,
    });
    this.setState(
        params,
        () => this.fetchManagementDetail(),
    );
  }

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: false}),
    );
  };

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: null}, () => this.fetchManagement());
  }, 500);

  renderModalForm = (show, params) => {
    const {
      classes,
      isLoading,
      leaveManagementDetailData,
      leaveManagementDetailMeta,
    } = this.props;
    const renderMethodOptions = () => {
      const options = [
        {id: 'all', name: 'All'},
        // {id: 'waiting', name: 'Waiting'},
        {id: 'approved', name: 'Approved'},
        {id: 'rejected', name: 'Rejected'},
      ];
      return options;
    };
    const {modalFormShow} = this.state;
    const {name, paidData, paidleaves, total_length} = this.state.datas;
    const titleDialog = 'Leave History Detail : '+ this.state.selectedName +'';
    const columns = [
      {name: 'details', label: 'Leave', display: true, customBodyRender: (value) => value ? value : '-'},
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          if (value === 'approved') {
            return <strong className="text-success">{value}</strong>;
          } else if (value === 'rejected') {
            return <strong className="text-danger">{value}</strong>;
          } else if (value === 'waiting') {
            return <strong className="text-warning">{value}</strong>;
          } else {
            return '-';
          }
        },
      },
      {
        name: 'start_date',
        label: 'Start Date',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? convDate(value, 'DD-MM-YYYY') : '-'}</div>;
        },
      },
      {
        name: 'end_date',
        label: 'End Date',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? convDate(value, 'DD-MM-YYYY') : '-'}</div>;
        },
      },
      {name: 'total_days', label: 'Duration', display: true, customBodyRender: (value) => value && value > 1 ? `${value} Days` : value ? `${value} Day` : '-'},
    ];
    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <Dialog
          open={modalFormShow}
          onClose={() => {
            this.handleFormToggle('close');
            this.setState({
              datas: {
                ...this.state.datas,
                paidData: [],
              },
            });
          }}
          maxWidth='md'
          fullWidth={true}
          scroll='paper'
          titlestyle={{textalign: 'center'}}
        >
          <ModalTitle
            title={titleDialog}
            onClose={() => this.handleFormToggle('close')}
          />
          {/* <MSelect
            shrink={true}
            fullWidth={false}
            classNameFC={`${classes.formControl} ${classes.filter}`}
            variant="standard"
            name="status"
            label="Status"
            keyPair={['id', 'name']}
            options={renderMethodOptions()}
            // value={status}
            // onChange={this.handleFilterChange}
          /> */}
          <DialogContent className={classes.dialogContent}>
            <LeaveDatatable
              className={classes.datatable}
              creatable={false}
              loading={this.state.isLoadingClick}
              dataSource={leaveManagementDetailData}
              total={leaveManagementDetailMeta ? leaveManagementDetailMeta.total : 0}
              page={leaveManagementDetailMeta ? leaveManagementDetailMeta.page : 1}
              columns={columns}
              customActions={false}
              handleReload={(params) => this.handleReloadDetail(params)}
              hideActions={true}
              manualNumbering={false}
            />
            <div>
            </div>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  };
  renderActions = (params) => {
    return (
      <Tooltip title="Detail">
        <div>
          <Button
            variant="contained"
            // color="primary"
            style={{
              'backgroundColor': '#00AFF0',
              'color': '#fff',
              'boxShadow': 'none',
              '&:hover': {
                backgroundColor: '#0198D0',
                boxShadow: 'none',
              },
              'minWidth': '10px',
              'paddingRight': '4px',
            }}
            size="small"
            // onClick={() => this.handleFormToggle(true, params)}
            onClick={() => this.handleClickDetail(true, params)}
            // startIcon={<SearchIcon />}
          >
            <img
              src={SearchLogo}
              alt="search_logo"
            />
          </Button>
        </div>
      </Tooltip>
    );
  };
  renderAddButton = () => {
    const {classes} = this.props;
    return (
      <div className={classes.MUIDataTableToolbar}>
        <Typography className={classes.tableTitle}>Leave History</Typography>
      </div>
    );
  }

  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      sortBy: null,
      order: 'desc',
      method: 'all',
    };
    this.setState(state, () => this.fetchManagement());
    this.toggleResetPage();
  };

  renderFilter = () => {
    const {classes, message} = this.props;
    const {startDate, endDate, errorMessage, query} = this.state;
    return (
      <Paper variant='outlined' className={classes.paper}>
        {/* <MKeyBoardDatepicker
          className={classes.filterDatePicker}
          name="startDate"
          label="Start Date"
          value={startDate}
          onDateChange={this.handleFilterDateChange}
          placeholder="dd-mm-yyyy"
          maxDate={endDate ? new Date(endDate) : undefined}
          error={errorMessage && errorMessage.start_date ? errorMessage.start_date : undefined}
        />
        <MKeyBoardDatepicker
          className={classes.filterDatePicker}
          name="endDate"
          label="End Date"
          value={endDate}
          onDateChange={this.handleFilterDateChange}
          placeholder="dd-mm-yyyy"
          minDate={new Date(startDate)}
          error={errorMessage && errorMessage.end_date ? errorMessage.end_date : undefined}
        /> */}
        <div>

        </div>
        <MInput
          className={`${classes.filter} ${classes.searchInput}`}
          fullWidth={false}
          variant="standard"
          name="query"
          label="Name"
          placeholder="Search name"
          value={query}
          onChange={this.handleSearch}
          pRight={40}
          InputProps={{
            endAdornment: (
              <IconButton className={classes.searchIcon} position="end" >
                {' '}
                <Search
                  style={{
                    position: 'absolute',
                    right: '-30px',
                  }}
                />
              </IconButton>
            ),
          }}
        />
        <div className={classes.btnFilterContainer}>
          <MButton
            className={classes.filterBtn}
            label="Reset"
            color="primary"
            icon={<Refresh />}
            onClick={this.handleReset}
          />
        </div>
      </Paper>
    );
  };

  render() {
    const {classes, isLoading, leaveManagementData, leaveManagementMeta, leaveConfigData} = this.props;
    const {resetDatatable, resetPage} = this.state;

    const columns = [
      {name: 'no', label: 'No', display: true, customBodyRender: (value) => value ? value : '-'},
      {name: 'name', label: 'Name', display: true, customBodyRender: (value) => value ? value : '-'},
      {name: 'leave_taken', label: 'Leave Taken', display: true,
        customBodyRender: (value) => value ? value : '0'},
      {name: 'leave_remaining', label: 'Leave Remaining', display: true, customBodyRender: (value) => value ? value : '-'},
      {name: 'id', label: 'id', display: false, customBodyRender: (value) => value ? value : '-'},
    ];
    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <LeaveDatatable
          creatable={false}
          title={this.renderAddButton()}
          loading={isLoading}
          dataSource={leaveManagementData}
          total={leaveManagementMeta ? leaveManagementMeta.total : 0}
          page={leaveManagementMeta ? leaveManagementMeta.page : 1}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          customActions={(params) => this.renderActions(params)}
          handleCreate={(params) => this.handleFormToggle('add', params)}
          manualNumbering={true}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
        />
        {this.renderModalForm()}
      </div>
    );
  }
}

const mapStateToProps = ({leaveManagement, leaveManagementDetail, leaveConfig}) => ({
  isLoading: leaveManagement.isLoading,
  isSuccess: leaveManagement.isSuccess,
  isError: leaveManagement.isError,
  message: leaveManagement.message,
  leaveManagementData: leaveManagement.leaveManagementData,
  leaveManagementMeta: leaveManagement.leaveManagementMeta,
  leaveConfigData: leaveConfig.leaveConfigData,

  isLoadingDetail: leaveManagementDetail.isLoadingDetail,
  isSuccessDetail: leaveManagementDetail.isSuccessDetail,
  isErrorDetail: leaveManagementDetail.isErrorDetail,
  messageDetail: leaveManagementDetail.messageDetail,
  triggerDetail: leaveManagementDetail.triggerDetail,
  leaveManagementDetailData: leaveManagementDetail.leaveManagementDetailData,
  leaveManagementDetailMeta: leaveManagementDetail.leaveManagementDetailMeta,
});

const mapDispatchToProps = (dispatch) => ({
  getLeaveManagementDetail: (params) => dispatch(getLeaveManagementDetail(params)),
  getLeaveManagement: (params) => dispatch(getLeaveManagement(params)),
  getLeaveConfig: (params) => dispatch(getLeaveConfig(params)),
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),
  clearState: () => dispatch(clearState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeaveManagement));

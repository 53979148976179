import {initialState, LEADERBOARD_STATE} from './leaderboard.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case LEADERBOARD_STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case LEADERBOARD_STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case LEADERBOARD_STATE.FETCH_TODAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        todayData: action.payload.data,
        todayMeta: action.payload.meta,
        trigger: action.type,
      };
    case LEADERBOARD_STATE.FETCH_TODAY_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        todayData: [],
        message: action.payload,
        trigger: action.type,
      };
    case LEADERBOARD_STATE.FETCH_MONTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        monthData: action.payload.data,
        monthMeta: action.payload.meta,
        trigger: action.type,
      };
    case LEADERBOARD_STATE.FETCH_MONTH_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        monthData: [],
        message: action.payload,
        trigger: action.type,
      };
    default:
      return state;
  }
};

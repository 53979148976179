export const initialState = {
  isLoading: false,
  isLoadingDetail: false,
  isError: false,
  isErrorDetail: false,
  isSuccessDetail: false,
  isSuccess: false,
  trigger: '',
  officeInventoryData: [],
  officeInventoryDataById: [],
  officeInventoryMeta: {page: 1, total: 0},
  message: {
    name: '',
  },

  exportLoading: false,
  exportData: {file: null},
  isLoadingPost: false,
  isSuccessPost: false,
  isErrorPost: false,
  messagePost: '',
  status: null,
};

export const OFFICE_INVENTORY_STATE = {
  REQUEST: 'OFFICE_INVENTORY_STATE_REQUEST',
  REQUEST_BY_ID: 'OFFICE_INVENTORY_STATE_REQUEST_ID',
  REQUEST_FAILED: 'OFFICE_INVENTORY_STATE_REQUEST_FAILED',
  CLEAR_STATE: 'OFFICE_INVENTORY_STATE_CLEAR_STATE',

  FETCH: 'OFFICE_INVENTORY_STATE_FETCH',
  FETCH_SUCCCESS: 'OFFICE_INVENTORY_STATE_FETCH_SUCCCESS',
  FETCH_FAILED: 'OFFICE_INVENTORY_STATE_FETCH_FAILED',

  FETCH_BY_ID: 'OFFICE_INVENTORY_STATE_FETCH_BY_ID',
  FETCH_BY_ID_SUCCCESS: 'OFFICE_INVENTORY_STATE_FETCH_BY_ID_SUCCCESS',
  FETCH_BY_ID_FAILED: 'OFFICE_INVENTORY_STATE_FETCH_BY_ID_FAILED',

  REVIEW: 'OFFICE_INVENTORY_STATE_REVIEW',
  REVIEW_SUCCESS: 'OFFICE_INVENTORY_STATE_REVIEW_SUCCESS',
  REVIEW_FAILED: 'OFFICE_INVENTORY_STATE_REVIEW_FAILED',

  ACCEPT: 'OFFICE_INVENTORY_STATE_ACCEPT',
  ACCEPT_SUCCESS: 'OFFICE_INVENTORY_STATE_ACCEPT_SUCCESS',
  ACCEPT_FAILED: 'OFFICE_INVENTORY_STATE_ACCEPT_FAILED',

  DENY: 'OFFICE_INVENTORY_STATE_DENY',
  DENY_SUCCESS: 'OFFICE_INVENTORY_STATE_DENY_SUCCESS',
  DENY_FAILED: 'OFFICE_INVENTORY_STATE_DENY_FAILED',

  EXPORT: 'OFFICE_INVENTORY_STATE_EXPORT',
  EXPORT_SUCCESS: 'OFFICE_INVENTORY_STATE_EXPORT_SUCCESS',
  EXPORT_FAILED: 'OFFICE_INVENTORY_STATE_EXPORT_FAILED',
};

/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {initialState, GENERAL_SETTING_STATE as STATE} from './generalSetting.types';
import {UPGRADE_STATE} from '../upgradeAccount/upgradeAccount.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.requestType,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.requestType,
      };
    case STATE.FETCH_ALL_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        data: action.payload.data,
        trigger: STATE.FETCH_ALL_SUCCCESS,
      };
    case STATE.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.UPDATE_SUCCESS,
      };
    case STATE.PUBLISH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.payload,
        trigger: STATE.PUBLISH_SUCCESS,
      };
    case STATE.FETCH_QR_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        qr: action.payload.file,
        autoRefresh: action.payload.qr_auto_refresh,
        qrPageLink: action.payload.qr_url ? window.location.origin + '/qrcode/' + action.payload.qr_url : null,
        token: action.payload.qr_url,
        company: {
          name: action.payload.organization_name,
          address: action.payload.organization_address,
          logo: action.payload.organization_logo,
        },
        trigger: STATE.FETCH_QR_SETTINGS_SUCCESS,
      };
    case STATE.GENERATE_QR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        qr: action.payload.file,
        autoRefresh: action.payload.qr_auto_refresh,
        trigger: STATE.GENERATE_QR_SUCCESS,
      };
    case STATE.GENERATE_LINK_SUCCESS:
      const tokenLink = action.payload.url && action.payload.url.substring(action.payload.url.lastIndexOf('/') + 1);
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        qrPageLink: action.payload.url,
        token: tokenLink,
        trigger: STATE.GENERATE_LINK_SUCCESS,
      };
    case STATE.UPDATE_AUTO_REFRESH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        autoRefresh: action.payload.qr_auto_refresh,
        trigger: STATE.UPDATE_AUTO_REFRESH_SUCCESS,
      };
    case STATE.CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        qr: action.payload.file,
        autoRefresh: action.payload.qr_auto_refresh,
        company: {
          name: action.payload.organization_name,
          address: action.payload.organization_address,
          logo: action.payload.organization_logo,
        },
        trigger: STATE.CHECK_TOKEN_SUCCESS,
      };
    case STATE.FETCH_BANNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bannerLogin: action.payload.data,
        trigger: STATE.FETCH_BANNER_SUCCESS,
      };
    case STATE.POST_BANNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingUploadBanner: false,
        isSuccessUploadBanner: true,
        banner: action.payload.data,
        trigger: STATE.POST_BANNER_SUCCESS,
      };
    case STATE.CLEAR_STATE:
      return {
        ...state,
        isLoading: false,
        isError: null,
        isSuccess: null,
        data: [],
        message: null,
        trigger: STATE.CLEAR_STATE,
      };
    default:
      return state;
  }
};

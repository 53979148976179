/* eslint-disable max-len */
import {ENDPOINTS, apiService} from '../config';
export const getListRegistration = (params) => {
  return apiService.get(ENDPOINTS.REGISTRATION.LIST, {
    params,
  });
};

export const putChangeStatusRegistration = (params) => {
  return apiService.post(ENDPOINTS.REGISTRATION.CHANGE_STATUS, params);
};

// export const getListRegistrationByToken = (token) => {
//   return apiService.get(ENDPOINTS.APPROVAL.OVERTIME_DETAIL + token);
// }

// export const confirmApprovalOvertime = (params) => {
//   return apiService.put(ENDPOINTS.APPROVAL.OVERTIME_CONFIRM + params.status + '/' + params.token, {
//     rejected_reason: params.rejected_reason
//   });
// }

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
  Link,
  Grid,
} from '@material-ui/core';
import {
  Twitter,
  Instagram,
  Facebook,
  LinkedIn,
} from '@material-ui/icons';
import styles from './styles';
import '../../assets/plugin/bootstrap/css/bootstrap.min.css';
import '../../assets/style/style.css';
import '../../assets/style/mobile.css';
import PagiiLogo from '../../assets/img/pagii-simple-attendance-solution-logo.png';
import Background from '../../assets/img/bg.png';
import SmooetsLogo from '../../assets/img/main-logo-smooets-horizontal-sm.png';

class WebTemplate extends React.Component {
  render() {
    const {classes, header, footer, customContainer} = this.props;
    return (
      <div className={customContainer ? customContainer : classes.webTemplate}>
        {
          !customContainer && (
            <div className="container-fluid">
              <div className="row" style={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: ' no-repeat',
                backgroundSize: 'cover',
                height: '',
              }}>
                <div className="col-md-6 offset-md-3 p-xs-0" style={{backgroundColor: 'rgba(255, 255, 255, 0.57)'}}>
                  {
                    header && (
                      <div className={classes.headerTemplate} style={{backgroundColor: 'transparent'}}>
                        <img src={PagiiLogo} className={classes.imgHeaderTemplate} alt="img"/>
                      </div>
                    )
                  }
                  {this.props.children}
                </div>
              </div>
            </div>
          )
        }
        {customContainer && this.props.children}
        {
          footer && (
            <footer className={`section-footer ${classes.footerTemplate}`}>
              <div className="container text-center">
                <div className="row">
                  <div className="col-md app-logo">
                    <a target="_blank" href="https://pagii.co">
                      <img src={PagiiLogo} className={`${'app-logo'} ${classes.footerLogo}`} alt="img"/>
                    </a>
                  </div>
                  <div className="col-6 col-md">
                    <h5>About Us</h5>
                    <ul className="list-unstyled text-small">
                      <li><a target="_blank" href="https://pagii.co/#section-about">About Pagii</a></li>
                      <li><a target="_blank" href="https://pagii.co/blog" rel="noopener noreferrer">Blog</a></li>
                    </ul>
                  </div>
                  <div className="col-6 col-md-3">
                    <h5>Help</h5>
                    <ul className="list-unstyled text-small">
                      <li><a target="_blank" href="https://pagii.co/en/privacy.php">Privacy</a></li>
                      {/* <li><a href="https://pagii.co/en/terms.php">Terms of Use</a></li> */}
                    </ul>
                  </div>
                  <div className="col-6 col-md">
                    <h5>Contact</h5>
                    <ul className="list-unstyled text-small">
                      <li><Link href="mailto:info@smooets.com">info@smooets.com</Link></li>
                      <li>+62 821 1641 7101</li>
                    </ul>
                  </div>
                  <div className="col-6 col-md" >
                    <h5>Social Media</h5>
                    <Grid container justify= 'space-around'>
                      <Link href="https://www.facebook.com/pagii.co/" target="_blank" rel="noopener noreferrer"><Facebook style={{color: '#fff'}}/></Link>
                      <Link href="https://www.twitter.com/PagiiCo/" target="_blank" rel="noopener noreferrer"><Twitter style={{color: '#fff'}}/></Link>
                      <Link href="https://www.instagram.com/pagii.co/" target="_blank" rel="noopener noreferrer"><Instagram style={{color: '#fff'}}/></Link>
                      <Link href="https://www.linkedin.com/company/pagii" target="_blank" rel="noopener noreferrer"><LinkedIn style={{color: '#fff'}}/></Link>
                    </Grid>
                    <div style={{whiteSpace: 'nowrap', marginTop: 10, alignContent: 'center'}}>
                      <h6 className="mt-4" style={{display: 'inline', marginRight: 5}}>Powered By:</h6>
                      <a target="_blank" href="https://www.smooets.com/">
                        <img src={SmooetsLogo} className="company-logo" alt="img"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <hr className="border-white"/>
                  </div>
                  <div className="col-12">
                    <span className="copyright">Product of Pagii & Mahapatih Sibernusa Teknologi</span>
                  </div>
                </div>
              </div> */}
            </footer>

          )
        }
      </div>
    );
  }
}

export default (withStyles(styles)(WebTemplate));

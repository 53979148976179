
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
import React, {useEffect} from 'react';
import {
    TextField,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Grid,
    IconButton,
    Checkbox,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import {Add as AddIcon, Remove as RemoveIcon, Delete as DeleteIcon} from '@material-ui/icons';
import './styles.css';

const CustomFieldManager = ({initialData, setOutput, fields, setFields, error, setError, getErrorForm, clearError, disabled}) => {
    // Set initial fields from backend data
    useEffect(() => {
        if (initialData && initialData.length > 0) {
            const formattedFields = initialData.map((field) => ({
                name: field.name,
                type: field.type,
                options: field.options || [],
                isRequired: field.is_required || false,
            }));
            setFields(formattedFields);
        }
    }, [initialData, setFields]);

    // Add a new custom field
    const handleAddField = () => {
        setFields([...fields, {name: '', type: '', options: [''], isRequired: false}]);
        setError(''); // Reset error on adding new field
    };

    // Change the field's properties
    const handleFieldChange = (index, field, value) => {
        const newFields = fields.map((item, idx) =>
            idx === index ?
                {
                    ...item,
                    [field]: value,
                    options: (value === 'checkbox' || value === 'multiselect' || value === 'options' || value === 'radio') && item.options.length === 0 ? [''] : item.options,
                } :
                item,
        );
        setFields(newFields);
    };

    // Add an option for the specified field
    const handleAddOption = (index) => {
        const newFields = fields.map((item, idx) =>
            idx === index ? {...item, options: [...item.options, '']} : item,
        );
        setFields(newFields);
    };

    // Change a specific option's value
    const handleOptionChange = (fieldIndex, optionIndex, value) => {
        const newFields = fields.map((field, idx) => {
            if (idx === fieldIndex) {
                const newOptions = field.options.map((option, optIdx) =>
                    optIdx === optionIndex ? value : option,
                );
                return {...field, options: newOptions};
            }
            return field;
        });
        setFields(newFields);
        clearError('custom_options');
    };

    // Remove a specific option
    const handleRemoveOption = (fieldIndex, optionIndex) => {
        const newFields = fields.map((field, idx) => {
            if (idx === fieldIndex) {
                const newOptions = field.options.filter((_, optIdx) => optIdx !== optionIndex);
                return {...field, options: newOptions};
            }
            return field;
        });
        setFields(newFields);
    };

    // Remove an entire custom field
    const handleRemoveField = (index) => {
        setFields(fields.filter((_, idx) => idx !== index));
    };

    // Automatically update output whenever fields change and validate
    useEffect(() => {
        const newOutput = {};
        fields.forEach((field, index) => {
            newOutput[`custom_field[${index}][name]`] = field.name;
            newOutput[`custom_field[${index}][is_required]`] = field.isRequired;
            newOutput[`custom_field[${index}][type]`] = field.type;
            if (field.options && (field.type === 'checkbox' || field.type === 'multiselect' || field.type === 'options' || field.type === 'radio')) {
                field.options.forEach((option, optIndex) => {
                    newOutput[`custom_field[${index}][options][${optIndex}]`] = option;
                });
            }
        });
        setOutput(newOutput);
    }, [fields, setOutput]);


    return (
        <div style={{width: '100%', padding: '8px'}}>
            <div className='custom-field-info'>Add a custom field to be displayed during registration.</div>
            {error && <div className="error-message">{error}</div>}
            <Button variant="contained" color="primary" onClick={handleAddField} disabled={disabled}>
                Add Field
            </Button>
            <div style={{marginTop: '20px', width: '100%'}}>
                {fields.length > 0 &&
                    fields.map((field, index) => (
                        <Grid container spacing={2} key={index} style={{marginBottom: '20px'}} alignItems="center">
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Name Field"
                                    variant="outlined"
                                    required={true}
                                    disabled={disabled}
                                    value={field.name}
                                    onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                                    error={!field.name && error || getErrorForm('title', 'Event Name') && !field.name}
                                    helperText={(!field.name && (error) || getErrorForm('title', 'Event Name') && !field.name) ? 'Field name is required' : ''}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth variant="outlined" error={!field.type && error}>
                                    <InputLabel style={{color: getErrorForm('title', 'Event Name') && !field.type ? '#f44336' : ''}}>Field Type *</InputLabel>
                                    <Select
                                        disabled={disabled}
                                        required={true}
                                        value={field.type}
                                        onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
                                        label="Field Type"
                                        error={getErrorForm('title', 'Event Name') && !field.type}
                                        style={{color: getErrorForm('title', 'Event Name') && !field.type ? '#f44336' : ''}}
                                    >
                                        <MenuItem value="checkbox">Checkbox</MenuItem>
                                        <MenuItem value="multiselect">Multi select</MenuItem>
                                        <MenuItem value="radio">Radio</MenuItem>
                                        <MenuItem value="attachment">Attachment</MenuItem>
                                        <MenuItem value="options">Options</MenuItem>
                                        <MenuItem value="date">Date</MenuItem>
                                        <MenuItem value="textarea">TextArea</MenuItem>
                                        <MenuItem value="text">Text</MenuItem>
                                    </Select>
                                    {(!field.type && error || getErrorForm('title', 'Event Name')) && !field.type && <FormHelperText style={{color: '#f44336'}}>Field type is required</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={2.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={disabled}
                                            checked={field.isRequired}
                                            onChange={(e) => handleFieldChange(index, 'isRequired', e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="Mark fields required"
                                />
                            </Grid>
                            {!disabled &&
                                <Grid item>
                                    <IconButton color="action" onClick={() => handleRemoveField(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            }
                            {(field.type === 'checkbox' || field.type === 'multiselect' || field.type === 'options' || field.type === 'radio') && (
                                <Grid item xs={12} style={{marginTop: '10px', paddingLeft: 25}}>
                                    <div style={{paddingBottom: 5}}>Options:</div>
                                    {field.options.map((option, optIdx) => (
                                        <Grid container spacing={1} key={optIdx} alignItems="center" style={{marginBottom: '10px'}}>
                                            <Grid item xs={5}>
                                                <TextField
                                                    fullWidth
                                                    disabled={disabled}
                                                    variant="outlined"
                                                    placeholder={`Option ${optIdx + 1}`}
                                                    value={option}
                                                    required={true}
                                                    onChange={(e) => handleOptionChange(index, optIdx, e.target.value)}
                                                    error={(!option && error || getErrorForm('title', 'Event Name') && !option) ? true : false}
                                                    helperText={(!option && error || getErrorForm('title', 'Event Name') && !option) ? 'Option value is required' : ''}
                                                />
                                            </Grid>
                                            {!disabled &&
                                                <Grid item>
                                                    {optIdx === field.options.length - 1 && (
                                                        <IconButton color="action" onClick={() => handleAddOption(index)}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    )}
                                                    {field.options.length > 1 && (
                                                        <IconButton color="action" onClick={() => handleRemoveOption(index, optIdx)}>
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    )}
                                                </Grid>
                                            }

                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    ))

                }
            </div >
        </div >
    );
};

export default CustomFieldManager;

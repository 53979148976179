/* eslint-disable max-len */
import {WITHDRAW} from '../../services';
import {WITHDRAW_STATE as STATE} from './withdraw.types';
import {transformObject, isArray} from '../../helpers';

export const getWithdrawal = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    WITHDRAW.getWithdrawal(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getOneWithdrawal = (id, params) => {
  return (dispatch) => {
    dispatch(requestId(STATE.FETCH_BY_ID));
    WITHDRAW.getOneWithdrawal(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_BY_ID_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_BY_ID_FAILED, response.data.message));
            },
        );
  };
};

export const updateWithdrawal = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    WITHDRAW.updateWithdrawal(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const rejectWithdrawal = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.REJECT));
    WITHDRAW.rejectWithdrawal(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.REJECT_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.REJECT_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestId = (requestType) => ({
  type: STATE.REQUEST_BY_ID,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  detailLoading: false,
  detailError: false,
  detailSuccess: false,
  detailData: '',
  exportLoading: false,
  exportData: {file: null},
  trigger: null,
  message: null,
  invoiceData: [],
  invoiceMeta: {page: 1, total: 0},
  invoiceDetailData: [],
  invoiceHistoryData: [],
  invoicePriceData: [],
  invoiceHistoryMeta: {page: 1, total: 0},
  invoiceDetailMeta: {page: 1, total: 0},
  total: null,
};

export const INVOICE_STATE = {
  REQUEST: 'INVOICE_REQUEST',
  REQUEST_FAILED: 'INVOICE_REQUEST_FAILED',
  FETCH: 'INVOICE_FETCH',
  FETCH_SUCCESS: 'INVOICE_FETCH_SUCCESS',
  CHECK_AMOUNT: 'INVOICE_CHECK_AMOUNT',
  FETCH_DETAIL: 'INVOICE_FETCH_DETAIL',
  FETCH_DETAIL_SUCCESS: 'INVOICE_FETCH_DETAIL_SUCCESS',
  FETCH_HISTORY: 'INVOICE_FETCH_HISTORY',
  FETCH_PRICE: 'INVOICE_FETCH_PRICE',
  FETCH_HISTORY_SUCCESS: 'INVOICE_FETCH_HISTORY_SUCCESS',
  FETCH_PRICE_SUCCESS: 'INVOICE_FETCH_PRICE_SUCCESS',
  FETCH_DETAIL_FAILED: 'INVOICE_FETCH_DETAIL_FAILED',
  FETCH_HISTORY_FAILED: 'INVOICE_FETCH_HISTORY_FAILED',
  FETCH_PRICE_FAILED: 'INVOICE_FETCH_PRICE_FAILED',
  GENERATE: 'GENERATE',
  GENERATE_SUCCESS: 'INVOICE_GENERATE_SUCCESS',
  GENERATE_FAILED: 'INVOICE_GENERATE_FAILED',
  SELECT: 'SELECT',
  SELECT_PLAN_SUCCESS: 'INVOICE_PLAN_SUCCESS',
  SELECT_PLAN_FAILED: 'INVOICE_PLAN_FAILED',
  UPDATE: 'INVOICE_UPDATE',
  UPDATE_SUCCESS: 'INVOICE_UPDATE_SUCCESS',
  UPDATE_FAILED: 'INVOICE_UPDATE_FAILED',
  UPDATE_INVOICE: 'INVOICE_UPDATE_INVOICE',
  UPDATE_INVOICE_SUCCESS: 'INVOICE_UPDATE_INVOICE_SUCCESS',
  UPDATE_INVOICE_FAILED: 'INVOICE_UPDATE_INVOICE_FAILED',
  CLEAR_STATE_INVOICE: 'INVOICE_CLEAR_STATE',
  CLEAR_STATE_INVOICE_GENERATE: 'INVOICE_GENERATE_CLEAR_STATE',
};

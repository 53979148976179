/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {
  Typography,
  Tooltip,
  Button,
  Grid,
  Paper,
} from '@material-ui/core';
import {Send, Delete, Refresh, Search, Warning} from '@material-ui/icons';
import styles from './styles';
import {showPopup, toast, isObject, checkSuperadmin, convDate} from '../../helpers';
import _ from 'lodash';

import {Datatable} from '../../components';
import {MInput, MButton, ModalTitle, MKeyBoardDatepicker, MSelect} from '../../components/form';
import {Download} from '@mui/icons-material';
import {getExportActivity, clearState} from '../../redux/exportActivity/exportActivity.actions';
import {EXPORT_ACTIVITY_STATE as STATE} from '../../redux/exportActivity/exportActivity.types';


class DivisionManagement extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      order: 'desc',
      sortBy: null,
      errorMessage: null,
      // Filter
      date: null,
      feature: 'all',

      resetDatatable: null,
      resetPage: null,
    };
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiDialog: {
        paperWidthSm: {
          width: 575,
        },
      },
      MuiDialogContent: {
        root: {
          '&:first-child': {
            paddingTop: 0,
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  UNSAFE_componentWillMount() {
    this.fetchDatatable();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
  }

  componentWillUnmount() {
  }

  fetchDatatable = () => {
    const {page, limit, order, sortBy, date, feature} = this.state;
    this.props.getExportActivity({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(order && {order}),
      ...(sortBy && {sort_by: sortBy}),
      ...(date && {date: convDate(date)}),
      ...(feature && feature !== 'all' && {feature}),
    });
  }

  handleReload = (params) => {
    this.setState(
        params,
        () => this.fetchDatatable(),
    );
  }

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null});
  };

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState(
        {[name]: value, page: null},
    );
  };

  handleDownloadActivity = (params) => {
    const file = params[7];
    window.location.href = file;
  }

  handleApplyFilter = () => {
    this.fetchDatatable();
  }

  handleResetFilter = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      sortBy: null,
      order: 'desc',
      date: null,
      feature: 'all',
    };
    this.setState(state, () => this.fetchDatatable());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  }

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: false}),
    );
  };

  renderFilter = () => {
    const {classes} = this.props;
    const {date, feature} = this.state;

    const renderFeatureName = () => {
      const options = [
        {id: 'all', name: 'All'},
        {id: 'user_management', name: 'User Management'},
        {id: 'attendance_history', name: 'Attendance History'},
        // {id: 'overtime_history', name: 'Overtime History'},
        // {id: 'leave_management', name: 'Leave Management'},
        // {id: 'permit', name: 'Permit'},
        // {id: 'reimburse', name: 'Reimburse'},
        // {id: 'cash_advance', name: 'Cash Advance'},
        // {id: 'office_inventory', name: 'Office Inventory'},
        // {id: 'master_inventory', name: 'Master Inventory'},
      ];
      return options;
    };

    return (
      <Paper variant='outlined' className={classes.paper}>
        <MKeyBoardDatepicker
          classNameFC={`${classes.formControlSearchBy} ${classes.filter}`}
          // classNameFC={`${classes.formControlStatus} ${classes.filter}`}
          name="date"
          label="Date"
          value={date}
          onDateChange={this.handleFilterDateChange}
          placeholder="dd-mm-yyyy"
          maxDate={date ? new Date(date) : undefined}
        />
        <MSelect
          shrink={true}
          fullWidth={false}
          classNameFC={`${classes.formControlStatus} ${classes.filter}`}
          variant="standard"
          name="feature"
          label="Feature Name"
          keyPair={['id', 'name']}
          options={renderFeatureName()}
          value={feature}
          onChange={this.handleFilterChange}
        />
        <div className={classes.btnFilterContainer}>
          <MButton
            className={`${classes.btnExport} ${classes.filterBtnEnd}`}
            label="Apply"
            color="primary"
            onClick={this.handleApplyFilter}
          />
          <MButton
            id="reset"
            className={`${classes.btnExport} ${classes.filterBtnEnd}`}
            label="Reset"
            icon={<Refresh/>}
            onClick={this.handleResetFilter}
          />
        </div>
      </Paper>
    );
  }

  renderActions = (params) => {
    const status = params[5];
    return (
      <Grid container wrap='nowrap' direction='row'>
        <Grid item>
          {status === 'waiting' ? <></> : (
            <Tooltip title={status === 'failed' ? 'We failed to prepare data due to a server error' : 'Download' }>
              <div style={{display: 'inline', marginRight: '5px'}}>
                <Button
                  variant="contained"
                  size='small'
                  onClick={() => {
                      if (status !== 'failed') {
                        this.handleDownloadActivity(params);
                      }
                    }
                  }
                  startIcon={status === 'failed' ? <Warning/> : <Download />}
                  style={
                    status === 'success' ?
                    {
                      'backgroundColor': '#00AFF0',
                      'boxShadow': 'none',
                      'color': '#fff',
                      '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#0198D0',
                      },
                      'minWidth': '10px',
                      'paddingRight': '0px',
                    } : {
                      'backgroundColor': 'transparent',
                      'boxShadow': 'none',
                      'color': '#E5AF5A',
                      'minWidth': '10px',
                      'paddingRight': '0px',
                      'cursor': 'default',
                    }
                  }
                >
                </Button>
              </div>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    );
  };

  render() {
    const {classes, isLoading, exportActivityData, exportActivityMeta} = this.props;

    const dummyData = [
      {
        id: 1,
        date: '2023/11/07',
        feature: 'overtime',
        file_name: 'SMT_Overtime_07112023',
        status: 'waiting',
      },
      {
        id: 2,
        date: '2023/11/06',
        feature: 'user_management',
        file_name: 'SMT_UserManagement_06112023',
        status: 'success',
      },
      {
        id: 3,
        date: '2023/11/05',
        feature: 'attendance_history',
        file_name: 'SMT_AttendanceHistory_05112023',
        status: 'success',
      },
      {
        id: 4,
        date: '2023/10/30',
        feature: 'permit',
        file_name: 'SMT_Permit_05112023',
        status: 'failed',
      },
    ];

    const columns = [
      {name: 'id', label: 'id', display: false},
      {
        name: 'created_at',
        label: 'Date',
        display: true,
        customBodyRender: (value) => (value ? convDate(value, 'DD-MM-YYYY') : '-'),
      },
      {
        name: 'feature',
        label: 'Feature Name',
        display: true,
        customBodyRender: (value) => (value ? (
          <Typography style={{fontSize: 13, textTransform: 'capitalize'}}>
            {value.replace('_', ' ')}
          </Typography>
        ) : '-'),
      },
      {
        name: 'file_name',
        label: 'File Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'remarks',
        label: 'Filter By',
        display: true,
        customBodyRender: (value, tableMeta) => {
          const filtered = tableMeta.rowData[5];
          delete filtered.page;
          delete filtered.limit;
          delete filtered.order;
          const data = JSON.stringify(filtered).replace('{', '').replace('}', '').replace(/['"]+/g, '').replace(/,(?=[^\s])/g, ', ').replace(/_/g, ' ').replace(/:/g, ': ');
          return (data) ? (
            <Typography style={{fontSize: 13, textTransform: 'capitalize'}}>
            {data}
          </Typography>
          ) : '-';
        },
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          if (value === 'success') {
            return <strong style={{color: '#008000'}}>{'Success'}</strong>;
          } else if (value === 'waiting') {
            return <strong style={{color: '#FFC226'}}>{'Waiting'}</strong>;
          } else if (value === 'on_process') {
            return <strong style={{color: '#00AFF0'}}>{'On Process'}</strong>;
          } else if (value === 'failed') {
            return <strong style={{color: '#F00B38'}}>{'Failed'}</strong>;
          } else {
            return '-';
          }
        },
      },
      {
        name: 'file_path',
        label: 'File Path',
        display: false,
        customBodyRender: (value) => (value ? value : '-'),
      },
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}

        <Datatable
          title={'Activity List'}

          // // Dummy Data
          // loading={false}
          // dataSource={dummyData}
          // total={dummyData ? dummyData.length : 0}
          // page={1}
          // columns={columns}

          // API Data
          loading={isLoading}
          dataSource={exportActivityData}
          total={exportActivityMeta ? exportActivityMeta.total : 0}
          page={exportActivityMeta ? exportActivityMeta.page : 1}
          columns={columns}

          handleReload={(params) => this.handleReload(params)}
          customActions={(data) => this.renderActions(data)}
          creatable={false}
        />
      </div>
    );
  }
}

const mapStateToProps = ({auth, exportActivity}) => {
  return {
    isSuperAdmin: checkSuperadmin(auth),
    isLoading: exportActivity.isLoading,
    isError: exportActivity.isError,
    isSuccess: exportActivity.isSuccess,
    message: exportActivity.message,
    trigger: exportActivity.trigger,
    exportActivityData: exportActivity.exportActivityData,
    exportActivityMeta: exportActivity.exportActivityMeta,
  };
};

const dispatchToProps = (dispatch) => ({
  getExportActivity: (params) => dispatch(getExportActivity(params)),
});

export default connect(
    mapStateToProps,
    dispatchToProps,
)(withStyles(styles)(DivisionManagement));

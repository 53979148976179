/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
import {initialState, ROLE_STATE as STATE} from './rolemanagement.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.requestType,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.requestType,
      };
    case STATE.FETCH_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        roleData: action.payload.data,
        roleMeta: action.payload.meta,
        trigger: action.type,
      };
    case STATE.CREATE_FAILED:
      const validation = {};
      for (let i = 0; i < action.message.length; i++) validation[action.message[i].field] = action.message[i].value;
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: validation,
        trigger: action.type,
      };
    case STATE.CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.DROP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

/* eslint-disable max-len */
import {ENDPOINTS, apiService} from '../config';
export const getOvertimeHistory = (params) => {
  return apiService.get(ENDPOINTS.OVERTIME.HISTORY, {
    params,
  });
};

export const exportExcel = (params) => {
  return apiService.post(
      ENDPOINTS.OVERTIME.EXPORT,
      params,
  );
};

export const putChangeStatusOvertime = (params) => {
  return apiService.put(ENDPOINTS.OVERTIME.CHANGE_STATUS, params);
};

export const getOvertimeHistoryByToken = (token) => {
  return apiService.get(ENDPOINTS.APPROVAL.OVERTIME_DETAIL + token);
};

export const confirmApprovalOvertime = (params) => {
  return apiService.put(ENDPOINTS.APPROVAL.OVERTIME_CONFIRM + params.status + '/' + params.token, {
    rejected_reason: params.rejected_reason,
  });
};

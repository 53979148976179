/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {initialState, SHIFT_MANAGEMENT_STATE as STATE} from './shiftManagement.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isShiftManagementLoading: true,
        isShiftManagementError: null,
        isShiftManagementSuccess: null,
        triggerShiftManagement: action.requestType,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isShiftManagementLoading: false,
        isShiftManagementError: true,
        messageShiftManagement: action.payload,
        triggerShiftManagement: action.requestType,
      };
    case STATE.FETCH_ALL_SUCCCESS:
      return {
        ...state,
        isShiftManagementLoading: false,
        isShiftManagementSuccess: true,
        dataShiftManagement: action.payload.data,
        triggerShiftManagement: STATE.FETCH_ALL_SUCCCESS,
      };
    case STATE.FETCH_ALL_FAILED:
      return {
        ...state,
        isShiftManagementLoading: false,
        isShiftManagementError: true,
        messageShiftManagement: action.payload,
        triggerShiftManagement: STATE.FETCH_ALL_FAILED,
      };
    case STATE.UPDATE_SUCCESS:
      return {
        ...state,
        isShiftManagementLoading: false,
        isShiftManagementError: false,
        isShiftManagementSuccess: true,
        messageShiftManagement: action.payload,
        triggerShiftManagement: STATE.UPDATE_SUCCESS,
      };
    case STATE.UPDATE_FAILED:
      return {
        ...state,
        isShiftManagementLoading: false,
        isShiftManagementError: true,
        messageShiftManagement: action.payload,
        triggerShiftManagement: STATE.UPDATE_FAILED,
      };
    case STATE.CLEAR_STATE:
      return {
        ...state,
        isShiftManagementLoading: false,
        isShiftManagementError: null,
        isShiftManagementSuccess: null,
        dataShiftManagement: [],
        messageShiftManagement: null,
        triggerShiftManagement: STATE.CLEAR_STATE,
      };
    default:
      return state;
  }
};

import {ENDPOINTS, apiService} from '../config';

export const getCashAdvance = (params) => {
  return apiService.get(
      ENDPOINTS.CASH_ADVANCE.ALL,
      {params},
  );
};

export const getCashAdvanceDetail = (id) => {
  return apiService.get(
      ENDPOINTS.CASH_ADVANCE.DETAIL + id,
  );
};


export const completeCashAdvance = (id, params) => {
  return apiService.put(
      ENDPOINTS.CASH_ADVANCE.COMPLETE + id,
      params,
  );
};

export const acceptCashAdvance = (id, params) => {
  return apiService.put(
      ENDPOINTS.CASH_ADVANCE.ACCEPT + id,
      params,
  );
};

export const denyCashAdvance = (id, params) => {
  return apiService.put(
      ENDPOINTS.CASH_ADVANCE.DENY + id,
      params,
  );
};


export const exportExcel = (params) => {
  return apiService.post(
      ENDPOINTS.CASH_ADVANCE.EXPORT,
      params,
  );
};



/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {Dialog, DialogActions, DialogContent} from '@material-ui/core';
import {Send} from '@material-ui/icons';
import {JOB_TITLE_STATE as STATE} from '../../redux/jobTitle/jobTitle.types';
import {toast, showPopup, isObject, checkSuperadmin} from '../../helpers';
import {MInput, MButton, ModalTitle} from '../../components/form';
import {Datatable} from '../../components';
import {
  getJobTitle,
  createJob,
  updateJob,
  deleteJob,
  clearState,
} from '../../redux/jobTitle/jobTitle.actions';

class JobTitle extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      searchFilter: null,
      showModal: false,
      editing: false,
      formData: {
        id: null,
        name: null,
        description: null,
      },
      errorMessage: null,
      order: 'desc',
      sortBy: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchJob();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message} = nextProps;

    if (isSuccess) {
      if (
        trigger === STATE.CREATE_SUCCESS ||
        trigger === STATE.UPDATE_SUCCESS ||
        trigger === STATE.DROP_SUCCESS
      ) {
        this.handleResetForm(true);
        this.fetchJob();
        toast('success', message);
      }
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  fetchJob = () => {
    const {page, limit, searchFilter, order, sortBy} = this.state;
    this.props.getJobTitle({
      page,
      limit,
      query: searchFilter,
      order,
      sort_by: sortBy,
    });
  };

  handleReload = (params) => {
    this.setState(params, () => this.fetchJob());
  };

  handleResetForm = (closeModal) => {
    this.setState({
      formData: {
        id: null,
        name: null,
        description: null,
      },
      errorMessage: null,
    });
    if (closeModal) this.setState({showModal: false});
  };

  handleInputChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      formData: {...this.state.formData, [name]: value ? value : null},
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  };

  handleFormToggle = (type, data = null) => {
    if (type === 'edit') {
      this.setState({
        showModal: !this.state.showModal,
        editing: true,
        formData: {
          id: data[1],
          name: data[3],
          description: data[4],
        },
      });
    } else {
      this.handleResetForm();
      this.setState({
        editing: false,
        showModal: !this.state.showModal,
      });
    }
  };

  handleConfirmDelete = (data) => {
    showPopup(
        'confirm',
        'Are you sure you want to delete this data ?',
        'Yes',
        () => this.props.deleteJob(data[1]),
    );
  };

  handleSearch = (keyword) => {
    this.setState({searchFilter: keyword, page: 1}, () => this.fetchJob());
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {createJob, updateJob} = this.props;
    const {editing} = this.state;
    const {id, name, description} = this.state.formData;
    const params = {
      ...(name && {name}),
      ...(description && {description}),
    };

    return !editing ? createJob(params) : updateJob(id, params);
  };

  renderModalForm = () => {
    const {classes, isLoading} = this.props;
    const {showModal, editing, errorMessage} = this.state;
    const {name, description} = this.state.formData;
    const titleDialog = editing ? 'Update Job Title' : 'Create Job Title';

    return (
      <Dialog open={showModal} onClose={() => this.handleFormToggle('close')}>
        <ModalTitle
          className={classes.modalTitle}
          title={titleDialog}
          onClose={() => this.handleFormToggle('close')}
        />
        <form onSubmit={this.handleSubmit}>
          <DialogContent className={classes.dialogContent}>
            <MInput
              classNameFC={classes.formControl}
              autoFocus
              name="name"
              label="Division *"
              placeholder="Enter division name"
              defaultValue={name}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.name ?
                  errorMessage.name :
                  undefined
              }
              fullWidth
            />
            <MInput
              classNameFC={classes.formControl}
              multiline
              rowsMax={5}
              name="description"
              label="Description"
              placeholder="Enter division description"
              defaultValue={description}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.description ?
                  errorMessage.description :
                  undefined
              }
              fullWidth
            />
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.btnCancel}
              label="Cancel"
              onClick={() => this.handleFormToggle('close')}
            />
            <MButton
              className={classes.defaultBtn}
              label={titleDialog}
              type="submit"
              icon={<Send />}
              loading={isLoading}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  render() {
    const {classes, isLoading, jobData, jobMeta} = this.props;
    const columns = [
      {name: 'id', label: 'id', display: false},
      {name: 'organization_types', label: 'Company Type', display: false},
      {
        name: 'name',
        label: 'Job Title Name',
        display: true,
        setCellHeaderProps: () => {
          return {align: 'center'};
        },
      },
      {
        name: 'description',
        label: 'Description',
        display: true,
        setCellHeaderProps: () => {
          return {align: 'center'};
        },
      },
      {
        name: 'created_by',
        label: 'Created By',
        display: true,
        setCellHeaderProps: () => {
          return {align: 'center'};
        },
      },
    ];

    return (
      <div className={classes.root}>
        <Datatable
          searchable
          title={'Job List'}
          loading={isLoading}
          dataSource={jobData}
          total={jobMeta ? jobMeta.total : 0}
          page={jobMeta ? jobMeta.page : 1}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleCreate={() => this.handleFormToggle('add')}
          handleEdit={(data) => this.handleFormToggle('edit', data)}
          handleDelete={(data) => this.handleConfirmDelete(data)}
          handleSearch={(params) => this.handleSearch(params)}
        />
        {this.renderModalForm()}
      </div>
    );
  }
}

const stateToProps = ({auth, jobTitle}) => ({
  isSuperAdmin: checkSuperadmin(auth),
  isLoading: jobTitle.isLoading,
  isError: jobTitle.isError,
  isSuccess: jobTitle.isSuccess,
  message: jobTitle.message,
  trigger: jobTitle.trigger,
  jobData: jobTitle.jobData,
  jobMeta: jobTitle.jobMeta,
});

const dispatchToProps = (dispatch) => ({
  getJobTitle: (params) => dispatch(getJobTitle(params)),
  createJob: (params) => dispatch(createJob(params)),
  updateJob: (id, params) => dispatch(updateJob(id, params)),
  deleteJob: (id) => dispatch(deleteJob(id)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(JobTitle));

import {initialState, APPROVAL_STATE} from './approvalPermit.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case APPROVAL_STATE.GET_APPROVAL_PERMIT_BY_TOKEN:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        isDone: false,
        message: null,
      };
    case APPROVAL_STATE.GET_APPROVAL_PERMIT_BY_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        isDone: false,
        message: null,
        data: action.data?.data,
      };
    case APPROVAL_STATE.GET_APPROVAL_PERMIT_BY_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        isDone: true,
        message: action.data?.message,
      };
    case APPROVAL_STATE.SUBMIT_APPROVAL_PERMIT:
      return {
        ...state,
        isLoadingSubmitApproval: true,
        isSuccessSubmitApproval: false,
        isError: false,
        isDone: false,
        message: null,
      };
    case APPROVAL_STATE.SUBMIT_APPROVAL_PERMIT_SUCCESS:
      return {
        ...state,
        isLoadingSubmitApproval: false,
        isSuccessSubmitApproval: true,
        isError: false,
        isDone: false,
        message: null,
      };
    case APPROVAL_STATE.SUBMIT_APPROVAL_PERMIT_FAILURE:
      return {
        ...state,
        isLoadingSubmitApproval: false,
        isSuccessSubmitApproval: false,
        isError: true,
        isDone: false,
        message: action.data?.message,
      };
    case APPROVAL_STATE.RESET_LOADER:
      return {
        ...state,
        isLoadingSubmitApproval: false,
        isSuccessSubmitApproval: false,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: null,
      };
    default:
      return state;
  }
};

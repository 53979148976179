import {ENDPOINTS, apiService} from '../config';

export const getFraudDetection = (params) => {
  return apiService.get(
      ENDPOINTS.FRAUD.HISTORY,
      {params},
  );
};

export const getFraudDetail = (id) => {
  return apiService.get(
      ENDPOINTS.FRAUD.DETAILS + id,
  );
};

export const exportExcel = (params) => {
  return apiService.post(
      ENDPOINTS.FRAUD.EXPORT,
      params,
  );
};

export const getFraudNoRecord = (params) => {
  return apiService.get(
      ENDPOINTS.FRAUD.NO_RECORD,
      {params},
  );
};

export const putChangeStatusFraud = (params) => {
  return apiService.put(ENDPOINTS.FRAUD.CHANGE_STATUS, params);
};

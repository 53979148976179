export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  aprovalOrderData: [],
  masterApproval: [],
  userApproval: [],
  approvalOrderDetail: [],
  divisionMeta: {page: 1, total: 0},
  message: {
    division_name: '',
    feature: '',
  },
};

export const APPROVAL_ORDER_STATE = {
  REQUEST: 'APPROVAL_ORDER_REQUEST',
  REQUEST_FAILED: 'APPROVAL_ORDER_REQUEST_FAILED',
  CLEAR_STATE: 'APPROVAL_ORDER_CLEAR_STATE',
  FETCH: 'APPROVAL_ORDER_FETCH',
  FETCH_SUCCCESS: 'APPROVAL_ORDER_FETCH_SUCCCESS',
  FETCH_FAILED: 'APPROVAL_ORDER_FETCH_FAILED',
  UPDATE: 'APPROVAL_ORDER_UPDATE',
  UPDATE_SUCCESS: 'APPROVAL_ORDER_UPDATE_SUCCESS',
  UPDATE_FAILED: 'APPROVAL_ORDER_UPDATE_FAILED',

  FETCH_DETAIL: 'APPROVAL_ORDER_FETCH_DETAIL',
  FETCH_DETAIL_SUCCCESS: 'APPROVAL_ORDER_FETCH_DETAIL_SUCCCESS',
  FETCH_DETAIL_FAILED: 'APPROVAL_ORDER_FETCH_FAILED',
  FETCH_MASTER: 'APPROVAL_ORDER_FETCH_MASTER',
  FETCH_MASTER_SUCCCESS: 'APPROVAL_ORDER_FETCH_MASTER_SUCCCESS',
  FETCH_MASTER_FAILED: 'APPROVAL_ORDER_FETCH_MASTER_FAILED',
  FETCH_APPROVAL: 'APPROVAL_ORDER_FETCH_APPROVAL',
  FETCH_APPROVAL_SUCCCESS: 'APPROVAL_ORDER_FETCH_APPROVAL_SUCCCESS',
  FETCH_APPROVAL_FAILED: 'APPROVAL_ORDER_FETCH_APPROVAL_FAILED',
};

/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import {
  createTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import moment from 'moment';
import {Send, Add} from '@material-ui/icons';
import styles from './styles';
import {MInput, SelectMultiple, MButton, ModalTitle} from '../../../components/form';
import {toast, isArray, isObject, checkSuperadmin} from '../../../helpers';
import {GENERAL_SETTING_STATE as STATE} from '../../../redux/generalSetting/generalSetting.types';
import {JOB_TITLE_STATE as JOB_STATE} from '../../../redux/jobTitle/jobTitle.types';
import {getJobTitle, createJob, exportExcel} from '../../../redux/jobTitle/jobTitle.actions';
import {
  getGeneralSetting,
  updateGeneralSetting,
} from './../../../redux/generalSetting/generalSetting.actions';
import {
  checkStatus,
} from './../../../redux/userManagement/userManagement.actions';
// import _ from 'lodash';
import {updateOrganization} from '../../../redux/organization/organization.actions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {ThemeProvider, createTheme as styleTheme} from '@mui/material/styles';
import {clearState} from '../../../redux/jobTitle/jobTitle.actions';


class HRSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adv_radio: null,
      leaderboard_radio: null,
      payslip_radio: null,
      formData: {
        job_title: [],
      },
      formDataUpdate: {
        job_title: [],
      },
      formDataJob: {
        name: null,
        description: null,
      },
      settings: [],
      message: false,
      showModalJobTitle: false,
      errorMessage: null,

      jobTitleData: null,
      jobTitleLoading: false,
      hideForm: true,
      anchorEl: null,
      loadingExport: false,
    };
  }

  getMuiTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiChip: {
        root: {
          backgroundColor: '#00AFF0',
          color: '#FFFFFF',
        },
      },
    },
  })

  exportTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          backgroundColor: '#00AFF0',
          color: '#FFFFFF',
        },
      },
      MuiButtonBase: {
        root: {
          backgroundColor: '#00AFF0',
          color: '#FFFFFF',
        },
      },
    },
  })

  autocompleteTheme = () => styleTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            paddingTop: 12,
          },
          root: {
            paddingTop: 12,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: '#00AFF0',
            color: '#FFFFFF',
          },
          deleteIcon: {
            color: '#FFFFFF',
          },
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    this.props.checkStatus();
    this.props.getGeneralSetting();
    this.props.getJobTitle();
  }

  componentDidMount() {
    this.props.getJobTitle();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      message,
      trigger,
      data,
      jobTrigger,
      jobSuccess,
      jobData,
      jobMessage,
      isError,
      // menusData,
      // triggerUser,
      isSuccessPost,
      exportData,
      isSuccess,
      isSuccessExport,
      isErrorExport,
      triggerExport,
    } = nextProps;

  if (isSuccessExport) {
    if (triggerExport === JOB_STATE.EXPORT_SUCCESS && exportData) {
      toast('success', 'File successfully exported & downloaded');
      this.setState({
        loadingExport: false,
      });
      this.props.clearState();
    }

    if (triggerExport === JOB_STATE.EXPORT_FAILED && exportData) {
      toast('error', 'An error occured');
      this.setState({
        loadingExport: false,
      });
      this.props.clearState();
    }
  }


    if (jobSuccess) {
      if (jobTrigger === JOB_STATE.FETCH_SUCCCESS) {
        this.setState({
          jobTitleData: jobData,
          jobTitleLoading: false,
          hideForm: false,
        });
      }
    }

    if (trigger === STATE.UPDATE_SUCCESS) {
      toast('success', message);
      this.props.clearState();
      this.props.getGeneralSetting();
      this.props.checkStatus();
    }

    if (trigger === STATE.FETCH_ALL_SUCCCESS) {
      const filtered = this.filterSetting(data);
      const jobSetting = filtered && filtered[0] ? filtered[0] : null;
      // if (checkInSetting && checkInSetting.value) checkInSetting.value = moment(checkInSetting.value, 'hh:mm');
      this.setState({
        settings: filtered,
        formData: {
          job_title: jobSetting,
        },
        formDataUpdate: {
          job_title: jobSetting,
        },
      });
    }


    if (jobTrigger === JOB_STATE.CREATE_SUCCESS) {
      toast('success', jobMessage);
      this.toggleModalJobTitle();
      this.props.getJobTitle();
    }

    if (this.props.trigger !== trigger && trigger === STATE.UPDATE_FAILED && !isObject(message)) {
      toast('error', message);
      this.props.getGeneralSetting();
    }

    if (jobTrigger === JOB_STATE.CREATE_FAILED && !isObject(jobMessage)) toast('error', message);

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
    }
  }

  filterSetting = (data) => {
    const filter = (data, key) => {
      const result = data.filter((value) => value.key === key);
      return isArray(result) ? result[0] : null;
    };
    const jobSetting = filter(data, 'job_title');

    return [jobSetting];
  }

  toggleModalJobTitle = () => {
    const {showModalJobTitle} = this.state;
    if (showModalJobTitle) this.handleResetJob();
    this.setState({showModalJobTitle: !showModalJobTitle});
  };

  toggleModalGroupEmail = () => {
    const {showModalGroupEmail} = this.state;
    this.setState({showModalGroupEmail: !showModalGroupEmail});
  }

  handleSubmit = () => {
    const {formDataUpdate} = this.state;
    const {menusData} = this.props;
    const converted = [];
    let newVal;

    if (!menusData.shift) {
      const newFormDataUpdate = [
        formDataUpdate.job_title,
      ];

      const newDataUpdate = newFormDataUpdate.filter((values) => {
        return values !==null;
      });
      newDataUpdate.map((val, key) => {
        if (val.key === 'job_title') { // must be array, then stringify / toString
          newVal = isArray(val.value) ? (val.value).toString() : val.value; // alt : val.value = JSON.stringify(val.value);
        }

        converted.push({
          key: val.key,
          label: val.label,
          value: newVal,
          group: val.group,
        });
      });
    } else {
      const newFormDataUpdate = [
        formDataUpdate.job_title,
      ];

      const newDataUpdate = newFormDataUpdate.filter((values) => {
        return values !==null;
      });
      newDataUpdate.map((val, key) => {
        if (val.key === 'job_title') { // must be array, then stringify / toString
          newVal = isArray(val.value) ? (val.value).toString() : val.value; // alt : val.value = JSON.stringify(val.value);
        }

        converted.push({
          key: val.key,
          label: val.label,
          value: newVal,
          group: val.group,
        });
      });
    }


    // this.setState({
    //   hideForm: !this.state.hideForm,
    // });
    const params = {data: converted};
    this.props.updateGeneralSetting(params);
  }

  handleSubmitJob = () => {
    const {name, description} = this.state.formDataJob;
    let orgTypeId = this.props.organization.organization_type_id;
        orgTypeId = orgTypeId ? [orgTypeId] : [];

    let params;
    if (description !== null && description !== '') {
      params = {name, description, type_ids: JSON.stringify(orgTypeId)};
    } else {
      params = {name, type_ids: JSON.stringify(orgTypeId)};
    }
    this.props.createJob(params);
  }

  handleSubmitEmail = () => {
    const {formDataEmail} = this.state;
    this.setState({
      formData: {
        ...this.state.formData,
        group_email: {
          ...this.state.formData.group_email,
          value: this.state.formData.group_email.value.concat({email: formDataEmail.email}),
        },
      },
      formDataUpdate: {
        ...this.state.formDataUpdate,
        group_email: {
          ...this.state.formDataUpdate.group_email,
          value: this.state.formDataUpdate.group_email.value.concat({email: formDataEmail.email}),
        },
      },
      formDataEmail: {
        email: '',
        errorEmail: '',
      },
      showModalGroupEmail: false,
    });
  }

  handleInputChangeSetting = (type, params) => {
    let name;
    let value;
    if (type === 'text') {
      name = params.target.name;
      value = params.target.value;
    } else if (type === 'multiple') {
      name = params.name;
      value = params.value;
    } else if (type === 'datetime') {
      name = params.name;
      value = moment(params.value).format('HH:mm');
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: {
          ...this.state.formData[name],
          value,
        },
      },
      formDataUpdate: {
        ...this.state.formDataUpdate,
        [name]: {
          ...this.state.formDataUpdate[name],
          value,
        },
      },
    });
  };

  handleInputChangeJob = (event) => {
    const {value, name} = event.target;
    this.setState({
      formDataJob: {
        ...this.state.formDataJob,
        [name]: value,
      },
    });
  };

  handleInputChangeEmail = (event) => {
    const {value, name} = event.target;
    this.setState({
      formDataEmail: {
        ...this.state.formDataEmail,
        [name]: value,
      },
    });
  }

  handleResetJob = () => {
    this.setState({
      formDataJob: {
        name: null,
        description: null,
      },
    });
  }

  handleGetJobTitle = (array) => {
    const {jobData} = this.props;
    const filter = isArray(jobData) && array.length ? jobData.filter((item) => array.includes(item.id)) : null;
    if (filter) {
      return filter.map((value) => value.id);
    } else if (array.length !==0) {
      return [array];
    }
  }

  renderModalJobTitle = () => {
    const {classes, isLoading, jobMessage} = this.props;
    const {showModalJobTitle} = this.state;
    const {name, description} = this.state.formData;

    return (
      <Dialog
        open={showModalJobTitle}
        onClose={this.toggleModalJobTitle}
      >
        <ModalTitle
          style={{
            fontSize: '18px',
            fontWeight: '700',
            color: '#656464',
          }}
          title="Add New Job Title"
          onClose={this.toggleModalJobTitle}
        />
        <form>
          <DialogContent className={classes.dialogContent}>
            <MInput
              classNameFC={classes.formControl}
              autoFocus
              name="name"
              label="Job Title *"
              placeholder="Enter job title name"
              defaultValue={name}
              onBlur={this.handleInputChangeJob}
              error={jobMessage && jobMessage.name ? jobMessage.name : undefined}
              fullWidth
            />
            <MInput
              classNameFC={classes.formControl}
              multiline
              rows={3}
              rowsMax={5}
              name="description"
              label="Description"
              placeholder="Enter job title description"
              defaultValue={description}
              onBlur={this.handleInputChangeJob}
              error={jobMessage && jobMessage.description ? jobMessage.description : undefined}
              fullWidth
            />
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label='Cancel'
              size='small'
              onClick={this.toggleModalJobTitle}
            />
            <MButton
              className={classes.defaultBtn}
              label="Submit"
              color="primary"
              size='small'
              icon={<Send />}
              loading={isLoading}
              onClick={this.handleSubmitJob}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  renderModalGroupEmail = () => {
    const {classes, isLoading, jobMessage} = this.props;
    const {showModalGroupEmail} = this.state;
    const {email} = this.state.formDataEmail;

    return (
      <Dialog
        open={showModalGroupEmail}
        onClose={this.toggleModalGroupEmail}
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Add New Email"
          onClose={this.toggleModalGroupEmail}
        />
        <form>
          <DialogContent className={classes.dialogContent}>
            <MInput
              classNameFC={classes.formControl}
              autoFocus
              type="email"
              name="email"
              label="Email"
              placeholder="Enter email"
              defaultValue={email}
              onBlur={this.handleInputChangeEmail}
              error={jobMessage && jobMessage.email ? jobMessage.email : undefined}
              fullWidth
            />
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              label='Cancel'
              size='small'
              onClick={this.toggleModalGroupEmail}
            />
            <MButton
              className={classes.btnSubmitJob}
              label="Submit"
              color="primary"
              size='small'
              icon={<Send />}
              loading={isLoading}
              onClick={this.handleSubmitEmail}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  renderForm = (settingArray) => {
    const {
      classes,
      // jobData,
      jobLoading,
      menusData,
    } = this.props;
    const {job_title} = this.state.formData;
    const jobTitleValue = job_title && job_title.value ? this.handleGetJobTitle(job_title.value) : undefined;
    // const settingArr = _.remove(settingArray, {id: undefined});
    const result = settingArray.map((setting) => {
      if (setting) {
        const {id, key, label} = setting;
        if (!menusData.shift) {
          if (key === 'job_title') {
            return (
              <MuiThemeProvider theme={this.getMuiTheme()}>

              <div key={id}>
                <h5 style={{marginTop: 10}}>Active Job Titles</h5>
                <Grid container>
                  <Grid item xs={10}>
                    {this.state.hideForm === true ? (null) :
                        (
                          <ThemeProvider theme={this.autocompleteTheme()}>
                            <SelectMultiple
                              className={classes.formControl}
                              label={label}
                              name={key}
                              placeholder="Select active job titles for your company"
                              keyPair={['id', 'name']}
                              data={this.state.jobTitleData ? this.state.jobTitleData : []}
                              loading={this.state.jobTitleLoading}
                              value={jobTitleValue}
                              onChange={(event) => this.handleInputChangeSetting('multiple', {name: key, value: event})}
                            />
                          </ThemeProvider>
                    )}
                  </Grid>
                  <Grid item xs={2} className={classes.btnAddContainer}>
                    <MButton
                      className={classes.btnAddNew}
                      label="Add New"
                      size='small'
                      icon={<Add />}
                      // loading={this.state.jobTitleLoading}
                      onClick={this.toggleModalJobTitle}
                    />
                  </Grid>
                  {this.renderModalJobTitle()}
                </Grid>
              </div>
              </MuiThemeProvider>
            );
          }
        } else {
          if (key === 'job_title') {
            return (
              <MuiThemeProvider theme={this.getMuiTheme()}>

              <div key={id}>
                <Typography className={classes.paperTitle}>Active Job Titles</Typography>
                <Grid container>
                  <Grid item xs={10}>
                  {this.state.hideForm === true ? (null) :
                    (
                      <ThemeProvider theme={this.autocompleteTheme()}>
                        <SelectMultiple
                          className={classes.formControl}
                          label={label}
                          name={key}
                          placeholder="Select active job titles for your company"
                          keyPair={['id', 'name']}
                          data={this.state.jobTitleData ? this.state.jobTitleData : []}
                          loading={this.state.jobTitleLoading}
                          value={jobTitleValue}
                          onChange={(event) => this.handleInputChangeSetting('multiple', {name: key, value: event})}
                        />
                      </ThemeProvider>
                    )}
                  </Grid>
                  <Grid item xs={2} className={classes.btnAddContainer}>
                    <MButton
                      className={classes.addBtn}
                      label="Add New"
                      color="primary"
                      size='small'
                      icon={<Add />}
                      // loading={jobLoading}
                      onClick={this.toggleModalJobTitle}
                    />
                  </Grid>
                  {this.renderModalJobTitle()}
                </Grid>
              </div>
              </MuiThemeProvider>
            );
          }
        }
        // else if (key === 'group_email') {
        //   const {formData} = this.state;
        //   const {group_email} = formData;
        //   const email_values = group_email.value.length > 0 ? group_email.value.map((val) => {
        //     return val.email;
        //   }) : [];
        //   return (
        //     <div key={id}>
        //       <h5>Group Emails</h5>
        //       <Grid container>
        //         <Grid item xs={10}>
        //           <SelectMultiple
        //             className={classes.formControl}
        //             label={label}
        //             name={key}
        //             placeholder="Select emails for your company"
        //             keyPair={['email', 'email']}
        //             data={group_email.value}
        //             value={email_values}
        //             onChange={(event) => {
        //               const newValue = [];
        //               if (event.length > 0) {
        //                 event.map((val) => {
        //                   return newValue.push({email: val});
        //                 });
        //               }
        //               this.setState({
        //                 formData: {
        //                   ...this.state.formData,
        //                   group_email: {
        //                     ...this.state.formData.group_email,
        //                     value: newValue,
        //                   },
        //                 },
        //                 formDataUpdate: {
        //                   ...this.state.formDataUpdate,
        //                   group_email: {
        //                     ...this.state.formDataUpdate.group_email,
        //                     value: newValue,
        //                   },
        //                 },
        //               });
        //             }}
        //           />
        //         </Grid>
        //         <Grid item xs={2} className={classes.btnAddContainer}>
        //           <MButton
        //             className={classes.btnAdd}
        //             label="Add New"
        //             color="primary"
        //             size='small'
        //             icon={<Add />}
        //             loading={jobLoading}
        //             onClick={this.toggleModalGroupEmail}
        //           />
        //         </Grid>
        //         {this.renderModalGroupEmail()}
        //       </Grid>
        //     </div>
        //   );
        // } else if (key === 'notification') {
        //   const dataNotif = this.state.formData.notification.value;
        //   return (
        //     <div key={id}>
        //       <h5 className={classes.subTitle}>Notification</h5>
        //       <Grid container>
        //       {dataNotif.map((valNotif) => {
        //         const checkedNotifPush = valNotif.push === 'true' ? true : valNotif.push === 'false' ? false : valNotif.push;
        //         const checkedNotifEmail = valNotif.email === 'true' ? true : valNotif.email === 'false' ? false : valNotif.email;
        //         return (
        //             <Grid xs={4} spacing={2}>
        //               <MCheckboxGroup
        //                 label={`Feature ${valNotif.feature.replace(/\b\w/g, (c) => c.toUpperCase())}`}
        //               >
        //                 <MCheckboxItem
        //                   name={`push_${valNotif.feature}`}
        //                   label={`Push`}
        //                   checked={checkedNotifPush}
        //                   onChange={this.handleCheckBoxNotif}
        //                 />
        //                 <MCheckboxItem
        //                   name={`email_${valNotif.feature}`}
        //                   label={`Email`}
        //                   checked={checkedNotifEmail}
        //                   onChange={this.handleCheckBoxNotif}
        //                 />
        //               </MCheckboxGroup>
        //             </Grid>
        //         );
        //       })}
        //       </Grid>
        //     </div>
        //   );
        // }
      }
    });
    return result;
  }

  handleCheckBoxNotif = (event) => {
    const {formData} = this.state;
    const {notification} = formData;
    const {name, checked} = event.target;
    const featureOption = name.split('_');
    notification.value.map((newVal) => {
      if (newVal.feature === featureOption[1]) {
        if (featureOption[0] === 'email') {
          newVal.email = checked;
        } else if (featureOption[0] === 'push') {
          newVal.push = checked;
        }
      }
      return newVal;
    });
    this.setState({
      formData: {
        ...this.state.formData,
        notification: notification,
      },
    });
  }

  toggleExport = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  toggleClose = () => {
    this.setState({
      anchorEl: false,
    });
  }

  handleExportJobTitle = (option) => {
    this.props.exportExcel({
      ...(option && {option: option}),
    });
    this.setState({anchorEl: false, loadingExport: true});
  }

  render() {
    const {
      classes,
      data,
      isLoading,
      // menusData,
    } = this.props;
    const {
      settings,
      anchorEl,
      // errorMessage,
      // payslip_radio,
    } = this.state;

    const open = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        <Paper variant='outlined' className={classes.paper}>
          <form>
            {
              settings.length > 0 ?
              this.renderForm(settings) : ''
            }
            <div className={classes.btnContainer} style={{display: 'flex'}}>
              <MButton
                className={classes.defaultBtn}
                label="Update Setting"
                icon={<Send/>}
                onClick={() => this.handleSubmit()}
                loading={isLoading && data.length > 0 ? true : false}
              />
              <MuiThemeProvider theme={this.exportTheme()}>

                <Button
                  id="demo-customized-button"
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={(event) => this.toggleExport(event)}
                  endIcon={<KeyboardArrowDownIcon />}
                  className={classes.defaultBtn}
                  color='primary'
                  style={{marginLeft: 8}}
                  loading={this.state.loadingExport}
                  disabled={isLoading && data.length > 0 ? true : false}
                  startIcon={isLoading ? <CircularProgress color="inherit" size={15} /> : null}
                >
                  EXPORT
                </Button>
              </MuiThemeProvider>

                <Menu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => this.toggleClose()}
                >
                  <MenuItem onClick={() => this.handleExportJobTitle('selected')} disableRipple>
                    Selected Job Title
                  </MenuItem>
                  <MenuItem onClick={() => this.handleExportJobTitle('all')} disableRipple>
                    All Job Title
                  </MenuItem>
                </Menu>
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = ({auth, generalSetting, jobTitle, userManagement}) => {
  return ({
    isSuperAdmin: checkSuperadmin(auth),
    organization: auth.data.organization,
    data: generalSetting.data,
    isLoading: generalSetting.isLoading,
    isSuccess: generalSetting.isSuccess,
    isError: generalSetting.isError,
    menusData: auth.data.menus,
    message: generalSetting.message,
    trigger: generalSetting.trigger,
    jobData: jobTitle.jobData,
    jobLoading: jobTitle.isLoading,
    jobMessage: jobTitle.message,
    jobTrigger: jobTitle.trigger,
    jobSuccess: jobTitle.isSuccess,
    exportLoading: jobTitle.exportLoading,
    exportData: jobTitle.exportData.file,
    triggerExport: jobTitle.triggerExport,
    isErrorExport: jobTitle.isErrorExport,
    isSuccessExport: jobTitle.isSuccessExport,
  });
};

const mapDispatchToProps = (dispatch) => ({
  getGeneralSetting: (key) => dispatch(getGeneralSetting(key)),
  getJobTitle: (params) => dispatch(getJobTitle(params)),
  createJob: (params) => dispatch(createJob(params)),
  updateGeneralSetting: (params) => dispatch(updateGeneralSetting(params)),
  checkStatus: (params) => dispatch(checkStatus(params)),
  updateOrganization: (data, id) => dispatch(updateOrganization(data, id)),
  exportExcel: (params) => dispatch(exportExcel(params)),
  clearState: () => dispatch(clearState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HRSetting));

/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getFirestore} from 'firebase/firestore';
import {getMessaging, isSupported, onMessage} from 'firebase/messaging';

const FIREBASE_APIKEY = process.env.REACT_APP_FIREBASE_APIKEY;
const FIREBASE_AUTHDOMAIN = process.env.REACT_APP_FIREBASE_AUTHDOMAIN;
const FIREBASE_PROJECTID = process.env.REACT_APP_FIREBASE_PROJECTID;
const FIREBASE_STORAGEBUCKET = process.env.REACT_APP_FIREBASE_STORAGEBUCKET;
const FIREBASE_MESSAGINGSENDERID = process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID;
const FIREBASE_APPID = process.env.REACT_APP_FIREBASE_APPID;
const FIREBASE_MEASURMENTID = process.env.REACT_APP_FIREBASE_MEASURMENTID;

export const firebaseConfig = {
  apiKey: FIREBASE_APIKEY,
  authDomain: FIREBASE_AUTHDOMAIN,
  projectId: FIREBASE_PROJECTID,
  storageBucket: FIREBASE_STORAGEBUCKET,
  messagingSenderId: FIREBASE_MESSAGINGSENDERID,
  appId: FIREBASE_APPID,
  measurementId: FIREBASE_MEASURMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


let messaging = null;

async function fcmSupport() {
  const supported = await isSupported();
  if (supported) {
    messaging = getMessaging();
  }
}
fcmSupport();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

  onMessage(function(payload) {
    console.log('Message received. ', payload);
  });

export default getFirestore();

/* eslint-disable max-len */

import axios from 'axios';
import {store} from './index';
import {showPopup} from '../helpers';

const {getState} = store;

const partnerService = axios.create({
  baseURL: process.env.REACT_APP_API_PARTNER + process.env.REACT_APP_API_PREFIX + process.env.REACT_APP_API_VERSION,
  timeout: process.env.REACT_APP_API_TIMEOUT,
});

// set Global Request Header
partnerService.interceptors.request.use((config) => {
  const token = getState().auth.data.authorization;
  if (token) config.headers.Authorization = token;
  config.headers.Platform = 'web';
  return config;
});

// set Global 401 / 500 Response handler
partnerService.interceptors.response.use(
    (response) => response,
    (error) => {
      const {response} = error;
      let errMsg = 'Something is wrong, please try again later';

      if (error.response) {
        const errCode = error.response.status;
        console.log('interceptors-error-response : ', error.response);
        if (!response.data.data && errCode !== 403) {
          // * handle default error from server / axios (eg: timeout, 404/500 errors)
          if (process.env.REACT_APP_MODE === 'DEV') {
            errMsg = error.response.data.message ? error.response.data.data.message : error.response.statusText;
          }
          showPopup('error', errMsg);
        }
      } else {
        // * handle other global error (connection, api, etc)
        console.log('interceptors-xhr-error : ', error.toJSON());
        errMsg = (error.code === 'ECONNABORTED') ? 'Warning! There was a problem with your network connection' : errMsg;
        error = {response: {data: {meta: {code: 400}, data: {message: null}}}}; // imitate backend standard error response
        showPopup('error', errMsg);
      }
      return Promise.reject(error); // return all response as promise error
    },
);

export default partnerService;

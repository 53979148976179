export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  isLoadingRead: false,
  isErrorRead: false,
  isSuccessRead: false,
  triggerRead: '',
  notificationData: [],
  notificationMeta: {page: 1, total: 0},
  message: {},
};

export const NOTIFICATION_STATE = {
  REQUEST: 'NOTIFICATION_REQUEST',
  REQUEST_FAILED: 'NOTIFICATION_REQUEST_FAILED',
  CLEAR_STATE: 'NOTIFICATION_CLEAR_STATE',
  REQUEST_READ: 'NOTIFICATION_REQUEST_READ',
  REQUEST_READ_FAILED: 'NOTIFICATION_REQUEST_READ_FAILED',
  CLEAR_STATE_READ: 'NOTIFICATION_CLEAR_STATE_READ',
  GET: 'NOTIFICATION_GET',
  GET_SUCCESS: 'NOTIFICATION_GET_SUCCESS',
  GET_FAILED: 'NOTIFICATION_GET_FAILED',
  READ: 'NOTIFICATION_READ',
  READ_SUCCESS: 'NOTIFICATION_READ_SUCCESS',
  READ_FAILED: 'NOTIFICATION_READ_FAILED',
};

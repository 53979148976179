/* eslint-disable max-len */
import {LEAVE_CONFIG} from '../../services';
import {LEAVE_CONFIG_STATE as STATE} from './leaveConfig.types';
import {transformObject, isArray} from '../../helpers';

export const getLeaveConfig = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    LEAVE_CONFIG.getLeaveConfig(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const createLeaveConfig = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE));
    LEAVE_CONFIG.createLeaveConfig(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CREATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CREATE_FAILED, message));
            },
        );
  };
};

export const updateLeaveConfig = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    LEAVE_CONFIG.updateLeaveConfig(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const deleteLeaveConfig = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.DROP));
    LEAVE_CONFIG.deleteLeaveConfig(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DROP_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.DROP_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

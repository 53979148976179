/* eslint-disable max-len */
import {initialState, GLOBAL_STATE as STATE} from './global.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.ANNOUNCEMENT_SAVE_DRAFT:
      return {
        ...state,
        isDraftSaved: true,
        trigger: STATE.ANNOUNCEMENT_SAVE_DRAFT,
      };
    case STATE.ANNOUNCEMENT_RESET_DRAFT:
      return {
        ...state,
        isDraftSaved: false,
        trigger: STATE.ANNOUNCEMENT_RESET_DRAFT,
      };
    case STATE.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
        trigger: STATE.CLEAR_STATE,
      };
    default:
      return state;
  }
};

/* eslint-disable max-len */
import {PAYMENT} from '../../services';
import {PAYMENT_STATE as STATE} from './confirmPayment.types';
import {transformObject, isArray} from '../../helpers';

export const getPayment = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    PAYMENT.getAll(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const checkToken = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.CHECK_TOKEN));
    PAYMENT.checkToken(id)
        .then(
          (response) => {
            // console.log(response.data)
            dispatch(requestSuccess(STATE.CHECK_TOKEN_SUCCESS, response.data));
          },
          (error) => {
            // console.log(error)
            const response = error.response.data;
            dispatch(requestFailed(STATE.CHECK_TOKEN_FAILED, response.data.message));
          },
        );
  };
};

export const createPayment = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE));
    PAYMENT.postPayment(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CREATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CREATE_FAILED, message));
            },
        );
  };
};

export const updatePayment = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    PAYMENT.updatePayment(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const deletePayment = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.DROP));
    PAYMENT.dropPayment(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DROP_SUCCESS, response.data.data.message));
            },
            (error) => {
              const message = error.response.data.data.message;
              dispatch(requestFailed(STATE.DROP_FAILED, message));
            },
        );
  };
};

export const getPaymentByOrg = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    PAYMENT.getAllByOrg(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getPaymentAnalytics = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_ANALYTICS));
    PAYMENT.getPaymentAnalytics(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_ANALYTICS_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_ANALYTICS_FAILED, response.data.message));
            },
        );
  };
};

export const getPaymentAnalyticsDetails = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_ANALYTICS_DETAILS));
    PAYMENT.getPaymentAnalyticsDetails(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_ANALYTICS_DETAILS_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_ANALYTICS_DETAILS_FAILED, response.data.message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
} from '@material-ui/core';
import {Menu} from '@material-ui/icons';
import {checkMobile} from '../../helpers';
import WebTemplate from '../../components/template';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../../assets/plugin/bootstrap/css/bootstrap.min.css';
import '../../assets/style/style.css';
import '../../assets/style/mobile.css';
import styles from './styles';

import PagiiLogo from '../../assets/img/pagii-simple-attendance-solution-logo.png';
import SvgFeatureCheckin from '../../assets/img/Pagii-attendance-app-feature-Check-in-from-home.svg';
import SvgFeatureWFO from '../../assets/img/Pagii-attendance-app-feature-Check-in-from-office.svg';
import SvgFeatureBusiness from '../../assets/img/Pagii-attendance-app-feature-business-trip-tracking.svg';
import SvgFeatureOvertime from '../../assets/img/Pagii-attendance-app-feature-overtime-tracking.svg';
import SvgFeatureLeave from '../../assets/img/Pagii-attendance-app-feature-paid-leave-tracker.svg';
import SvgFeatureHistory from '../../assets/img/Pagii-attendance-app-feature-see-work-history-tracking.svg';
import SvgFeatureAnnounce from '../../assets/img/Pagii-attendance-app-feature-announcement-information.svg';
import SvgFeatureProfile from '../../assets/img/Pagii-attendance-app-feature-Profile-information.svg';
import ImgAdv1 from '../../assets/img/attendance-tracking-app-easy-to-use.png';
import ImgAdv2 from '../../assets/img/Attendance-tracking-app-make-more-productive.png';
import ImgAdv3 from '../../assets/img/Attendance-tracking-app-is-easy-to-analyze-data.png';
import SvgBgShapeRight from '../../assets/img/background-shape-right.svg';
import ImgAppStore from '../../assets/img/Pagii-Attendance-App-on-AppStore.png';
import ImgPlayStore from '../../assets/img/Pagii-Attendance-App-on-Google-PlayStore.png';
import SvgCheckAttendance from '../../assets/img/About-Pagii-attendance-app-tracker.svg';
import SvgMainBg from '../../assets/img/main-background-gradient.svg';
import ImgMainPreview from '../../assets/img/Simple-Attendance-Solution-App-by-Pagii.png';
import ImgPreviewSkew from '../../assets/img/Why-choose-attendance-app-by-Pagii.png';

// import SvgBgWide from '../../assets/img/background-shape-wide.svg';
// import ImgUser1 from '../../assets/img/testimony/user-1.jpg';
// import ImgUser2 from '../../assets/img/testimony/user-2.jpg';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const TAWK_URL = process.env.REACT_APP_TAWK_URL;
const IS_MOBILE = checkMobile();

class LandingPage extends React.Component {
  constructor() {
    super();
    this.refSectionFeature = React.createRef();
    this.state = {
      mobile: false,
    };
  }

  UNSAFE_componentWillMount() {
    // this.loadTawkScript();
    window.location.replace('https://pagii.co');
  }

  componentDidMount() {
    if (!IS_MOBILE) AOS.init();
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    // this.unloadTawkScript();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  // loadTawkScript = () => {
  //   const s1 = document.createElement('script');
  //   const s0 = document.getElementsByTagName('script')[0];
  //   s1.async = true;
  //   s1.src = TAWK_URL;
  //   s1.charset = 'UTF-8';
  //   s1.id = 'tawkScript';
  //   s1.setAttribute('crossorigin', '*');
  //   s0.parentNode.insertBefore(s1, s0);
  //   document.body.appendChild(s1);
  // };

  // unloadTawkScript = () => {
  //   const s1 = document.getElementById('tawkScript');
  //   s1.parentNode.removeChild(s1);
  // };

  redirectPage = (path) => {
    const baseURL = window.location.origin;
    window.location.href = baseURL + path;
  };

  handleWindowResize = () => {
    const {mobile} = this.state;
    if (window.innerWidth < 992) {
      AOS.init({disable: true});
      if (!mobile) this.setState({mobile: true});
    } else {
      AOS.init();
      if (mobile) this.setState({mobile: false});
    }
  };

  handleScroll = (ref) => {
    ref.current.scrollIntoView({behavior: 'smooth'});
  };

  renderNavbar = () => {
    const {mobile} = this.state;
    const {classes} = this.props;
    if (mobile) {
      return (
        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary
            classes={{content: classes.expansionPanelSummaryContent}}
            className={classes.expansionPanelSummary}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              className={classes.menuButton}
            >
              <Menu />
            </IconButton>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <img
              alt="pagii-logo"
              src={PagiiLogo}
              className={classes.navLogoMobile}
              onClick={() => window.location.replace('https://pagii.co')}
            />
            <div className={classes.btnNavContainer}>
              <Button
                className={classes.btnLogin}
                onClick={() => this.redirectPage('/login')}
              >
                login
              </Button>
              <Button
                className={classes.btnRegister}
                onClick={() => this.props.history.push('/register')}
              >
                try for free
              </Button>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return (
        <AppBar position="static" className={classes.navbar}>
          <Toolbar className={classes.navToolbar}>
            <img
              alt="pagii-logo"
              src={PagiiLogo}
              className={classes.navLogo}
              onClick={() => window.location.replace('https://pagii.co')}
            />
            <div className={classes.btnNavContainer}>
              <Button
                className={classes.btnLogin}
                onClick={() => this.redirectPage('/login')}
              >
                login
              </Button>
              <Button
                className={classes.btnRegister}
                onClick={() => this.props.history.push('/register')}
              >
                try for free
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      );
    }
  };

  render() {
    const {classes} = this.props;
    return (
      <div
        className={classes.landingPage}
        id="landing-page"
        data-aos-easing="ease"
        data-aos-duration="400"
        data-aos-delay="0"
      >
        {this.renderNavbar()}
        <section id="section-home">
          <img
            src={SvgMainBg}
            className="background-img"
            data-aos="zoom-in"
            data-aos-duration="800"
            alt="mainBG"
          />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 home__img">
                <img
                  src={ImgMainPreview}
                  data-aos="zoom-out"
                  alt="Simple Attendance Solution App by Pagii | powered by Smooets."
                  data-aos-duration="800"
                  className="desktop-only featured-img"
                />
              </div>
              <div
                className="col-md-6 home__txt"
                alt="Simple Attendance Solution App by Pagii | powered by Smooets."
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <img
                  src={ImgMainPreview}
                  data-aos="zoom-out"
                  data-aos-duration="800"
                  className="mobile-only featured-img"
                  alt="imgMain"
                />
                <h1>Easy Way to <div className="highlight">Attendance Check</div></h1>
                <p className="mt-2">
                  Smart application to make it easier for employees and managers
                  to organize attendance
                </p>
                <div className="download-anchor mt-3">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.pagii"
                    className="btn-download"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ImgPlayStore} alt="Pagii - Mobile Attendance Tracking App on Google Playstore." />
                  </a>
                  <a
                    href="https://apps.apple.com/id/app/pagii/id1514367830"
                    className="btn-download"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ImgAppStore} alt="Pagii - Mobile Attendance Tracking App on Appstore." />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="scroll-down">
            <a
              href="#section-feature"
              className="btn-scroll"
              id="scroll-to-feature"
            >
              <span></span>
            </a>
          </div>
        </section>
        <section id="section-feature" ref={this.refSectionFeature}>
          <span className="title-caption">feature</span>
          <h2 className="mb-4">
            {`Features of Attendance App by `}
            <div className="highlight">Pagii.</div>
          </h2>
          <div className="container feature-container">
            <div className="row">
              <div className="col-md-3 d-flex align-items-stretch">
                <div className="card-feature">
                  <div className="card-img-wrapper wfh">
                    <img src={SvgFeatureCheckin} className="card-img" alt="Pagii attendance app feature Check-in from home." />
                  </div>
                  <span className="adv-title">Check In from Home</span>
                  <p className="adv-caption">
                    You can easily check in from home by one tap button
                  </p>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-stretch">
                <div className="card-feature">
                  <div className="card-img-wrapper wfo">
                    <img src={SvgFeatureWFO} className="card-img" alt="Pagii attendance app feature Check-in from office."/>
                  </div>
                  <span className="adv-title">Check In from Office</span>
                  <p className="adv-caption">
                    You can easily check in from office by scan the QR code
                  </p>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-stretch">
                <div className="card-feature">
                  <div className="card-img-wrapper trip">
                    <img src={SvgFeatureBusiness} className="card-img" alt="Pagii attendance app feature business trip tracking." />
                  </div>
                  <span className="adv-title">Business Trip</span>
                  <p className="adv-caption">
                    You can easily check in from everywhere you should report
                  </p>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-stretch">
                <div className="card-feature">
                  <div className="card-img-wrapper overtime">
                    <img src={SvgFeatureOvertime} className="card-img" alt="Pagii attendance app feature overtime tracking." />
                  </div>
                  <span className="adv-title">Overtime</span>
                  <p className="adv-caption">
                    You can easily overtime from everywhere by one tap button
                  </p>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-stretch">
                <div className="card-feature">
                  <div className="card-img-wrapper leave">
                    <img src={SvgFeatureLeave} className="card-img" alt="Pagii attendance app feature leave tracker.svg" />
                  </div>
                  <span className="adv-title">Leave</span>
                  <p className="adv-caption">
                    You can easily request leave by fill the form
                  </p>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-stretch">
                <div className="card-feature">
                  <div className="card-img-wrapper history">
                    <img src={SvgFeatureHistory} className="card-img" alt="Pagii attendance app feature see work history tracking." />
                  </div>
                  <span className="adv-title">See Work History</span>
                  <p className="adv-caption">
                    You can easily see your work history by category
                  </p>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-stretch">
                <div className="card-feature">
                  <div className="card-img-wrapper announce">
                    <img src={SvgFeatureAnnounce} className="card-img" alt="Pagii attendance app feature announcement information." />
                  </div>
                  <span className="adv-title">Announcement</span>
                  <p className="adv-caption">
                    You can see recently announcement from your company admin
                  </p>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-stretch">
                <div className="card-feature">
                  <div className="card-img-wrapper profile">
                    <img src={SvgFeatureProfile} className="card-img" alt="Pagii attendance app feature Profile information" />
                  </div>
                  <span className="adv-title">Profile</span>
                  <p className="adv-caption">
                    You can see your account info in profile menu
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="scroll-down">
            <a
              href="#section-about"
              className="btn-scroll"
              id="scroll-to-about"
            >
              <span></span>
            </a>
          </div>
        </section>
        <section id="section-about">
          <img
            alt="bg-shape"
            src={SvgBgShapeRight}
            className="background-img desktop-only aos-init"
            data-aos="zoom-in"
            data-aos-duration="800"
          />
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 about__txt aos-init"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <span className="title-caption">definition</span>
                <h2 className="mb-4">
                  About Attendance App by <div className="highlight">Pagii</div>
                </h2>
                <p>
                  Pagii is a mobile application that run both in android and
                  iOS. It help employees and managers to manage their attendance
                  like: check in and check out. In this mobile application, user
                  also can view latest information from the company, including:
                  holiday calendar notice, new employees, and the other
                  information.
                </p>
                <p>
                  Pagii is a product presence via mobile apps that can be used
                  flexibly when working at home or at the office. And the data
                  record is stored in the Morning Office Back Office
                </p>
              </div>
              <div className="col-md-6 about__img">
                <img
                  alt="About Pagii mobile attendance tracking app"
                  src={SvgCheckAttendance}
                  data-aos="zoom-out"
                  data-aos-duration="800"
                  className="aos-init"
                />
              </div>
            </div>
          </div>
          <div id="scroll-down">
            <a
              href="#section-advantages"
              className="btn-scroll"
              id="scroll-to-adv"
            >
              <span></span>
            </a>
          </div>
        </section>
        <section id="section-advantages">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img
                  alt="Why you should choose attendance tracking app by Pagii"
                  src={ImgPreviewSkew}
                  className="adv-preview big-screen-only"
                />
              </div>
              <div className="col-md-6">
                <span className="title-caption">advantages</span>
                <h2 className="mb-5">
                  Why choose Attendance App by{' '}
                  <div className="highlight">Pagii</div>
                </h2>
                <div className="row adv-card">
                  <div className="col-3 col-lg-2">
                    <div className="adv-card-img-wrapper">
                      <img
                        alt="Attendance tracking app is easy to use."
                        src={ImgAdv1}
                        className="adv-card-img"
                      />
                    </div>
                  </div>
                  <div className="col-9 col-lg-10">
                    <span className="adv-title">Easy to Use</span>
                    <p className="adv-caption">
                      This application is easy to use, just one tap to check in
                    </p>
                  </div>
                </div>
                <div className="row adv-card">
                  <div className="col-3 col-lg-2">
                    <div className="adv-card-img-wrapper">
                      <img
                        alt="Attendance tracking app make more productive"
                        src={ImgAdv2}
                        className="adv-card-img"
                      />
                    </div>
                  </div>
                  <div className="col-9 col-lg-10">
                    <span className="adv-title">Make More Productive</span>
                    <p className="adv-caption">
                      This application make user more productive
                    </p>
                  </div>
                </div>
                <div className="row adv-card">
                  <div className="col-3 col-lg-2">
                    <div className="adv-card-img-wrapper">
                      <img
                        alt="Attendance tracking app is easy to analyze data"
                        src={ImgAdv3}
                        className="adv-card-img"
                      />
                    </div>
                  </div>
                  <div className="col-9 col-lg-10">
                    <span className="adv-title">Easy to Analyze</span>
                    <p className="adv-caption">
                      This application is easy to analyze the attendance
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section
          id="section-testimony"
          style={{
            backgroundImage: `url(${SvgBgWide})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                <span className="title-caption">testimony</span>
                <h2>What <div className="highlight">They Say</div></h2>
                <div className="landing-carousel" data-aos="fade-right" data-aos-duration="1800">
                  <OwlCarousel
                    className="owl-carousel owl-theme simple-owl owl-testimony mt-4 py-4"
                    margin={10}
                    items={1}
                    autoplay
                    loop
                    nav
                  >
                    <div className="item">
                      <img alt="img-user-1" src={ImgUser1} className="client-photo"/>
                      <span className="client-name mt-3">Martin Atkinson</span>
                      <span className="client-scope">CEO of Roaster Inc.</span>
                      <p className="client-feedback mt-4">{`"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et "`}</p>
                    </div>
                    <div className="item">
                      <img alt="img-user-2" src={ImgUser2} className="client-photo"/>
                      <span className="client-name mt-3">Mark Hughes</span>
                      <span className="client-scope">General Manager of Senhua Tech</span>
                      <p className="client-feedback mt-4">{`"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et "`}</p>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section id="section-cta">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <h2><div className="highlight">Subscribe</div> to Our Newsletter</h2>
                <p className="mb-4">
                  Get the latest application updates, news, and special offers delivered right to your inbox.
                </p>
                <form>
                  <div className="input-group mb-3">
                    <input type="text" className="form-control mr-3" placeholder="Enter your email"/>
                    <button className="btn btn-link" type="button">subscribe</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section> */}
        <WebTemplate footer customContainer />
      </div>
    );
  }
}

export default withStyles(styles)(LandingPage);

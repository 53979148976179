export const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  trigger: '',
  roleData: [],
  roleMeta: {page: 1, total: 0},
  message: {
    name: '',
    description: '',
  },
};

export const ROLE_STATE = {
  REQUEST: 'ROLE_REQUEST',
  REQUEST_FAILED: 'ROLE_REQUEST_FAILED',
  CLEAR_STATE: 'ROLE_CLEAR_STATE',
  FETCH: 'ROLE_FETCH',
  FETCH_SUCCCESS: 'ROLE_FETCH_SUCCCESS',
  FETCH_FAILED: 'ROLE_FETCH_FAILED',
  CREATE: 'ROLE_CREATE',
  CREATE_FAILED: 'ROLE_CREATE_FAILED',
  CREATE_SUCCESS: 'ROLE_CREATE_SUCCESS',
  UPDATE: 'ROLE_UPDATE',
  UPDATE_SUCCESS: 'ROLE_UPDATE_SUCCESS',
  UPDATE_FAILED: 'ROLE_UPDATE_FAILED',
  DROP: 'ROLE_DROP',
  DROP_SUCCESS: 'ROLE_DROP_SUCCESS',
  DROP_FAILED: 'ROLE_DROP_FAILED',
};

import {initialState, ATTENDANCE_STATE} from './attendance.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case ATTENDANCE_STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case ATTENDANCE_STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    case ATTENDANCE_STATE.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        attendanceData: action.payload.data,
        attendanceMeta: action.payload.meta,
        trigger: action.type,
      };
    case ATTENDANCE_STATE.FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        attendanceData: [],
        message: action.payload,
        trigger: action.type,
      };
    case ATTENDANCE_STATE.FETCH_DETAIL:
      return {
        ...state,
        detailLoading: true,
        isSuccess: false,
        isError: false,
        message: null,
        detailData: null,
        trigger: action.type,
      };
    case ATTENDANCE_STATE.FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        isSuccess: true,
        detailData: action.payload,
        trigger: action.type,
      };
    case ATTENDANCE_STATE.FETCH_DETAIL_FAILED:
      return {
        ...state,
        detailLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case ATTENDANCE_STATE.EXPORT:
      return {
        ...state,
        exportLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        trigger: action.type,
      };
    case ATTENDANCE_STATE.EXPORT_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        isError: false,
        isSuccess: true,
        exportData: action.payload,
        trigger: action.type,
      };
    case ATTENDANCE_STATE.EXPORT_FAILED:
      return {
        ...state,
        exportLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        trigger: action.type,
      };
    default:
      return state;
  }
};

/* eslint-disable max-len */
import {PARTNER} from '../../services';
import {PARTNER_STATE as STATE} from './partner.types';
import {transformObject, isArray} from '../../helpers';

export const getPartner = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    PARTNER.getPartner(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_FAILED, response.data.message));
            },
        );
  };
};

export const getPartnerList = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_LIST));
    PARTNER.getPartnerList(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_LIST_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_LIST_FAILED, response.data.message));
            },
        );
  };
};

export const resendEmail = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.RESEND_EMAIL));
    PARTNER.resendEmail(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.RESEND_EMAIL_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.RESEND_EMAIL_FAILED, response.data.message));
            },
        );
  };
};

export const getMember = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_MEMBER));
    PARTNER.getMember(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_MEMBER_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_MEMBER_FAILED, response.data.message));
            },
        );
  };
};

export const getOnePartner = (params) => {
  return (dispatch) => {
    dispatch(requestId(STATE.FETCH_BY_ID));
    PARTNER.getOnePartner(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_BY_ID_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_BY_ID_FAILED, response.data.message));
            },
        );
  };
};

export const createPartner = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.CREATE));
    PARTNER.createPartner(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.CREATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.CREATE_FAILED, message));
            },
        );
  };
};

export const updatePartner = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.TOGGLE));
    PARTNER.updatePartner(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.TOGGLE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.TOGGLE_FAILED, message));
            },
        );
  };
};

export const togglePartner = (id, params) => {
  return (dispatch) => {
    dispatch(request(STATE.UPDATE));
    PARTNER.togglePartner(id, params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.UPDATE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.UPDATE_FAILED, message));
            },
        );
  };
};

export const deletePartner = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.DROP));
    PARTNER.deletePartner(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.DROP_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.DROP_FAILED, message));
            },
        );
  };
};

export const rejectPartner = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.REJECT));
    PARTNER.rejectPartner(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.REJECT_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.REJECT_FAILED, message));
            },
        );
  };
};

export const approvePartner = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.APPROVE));
    PARTNER.approvePartner(params)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.APPROVE_SUCCESS, response.data.data.message));
            },
            (error) => {
              const errors = error.response.data.data.errors;
              const message = isArray(errors) ? transformObject(errors) : error.response.data.data.message;
              dispatch(requestFailed(STATE.APPROVE_FAILED, message));
            },
        );
  };
};

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: STATE.REQUEST,
  requestType,
});

const requestId = (requestType) => ({
  type: STATE.REQUEST_BY_ID,
  requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});

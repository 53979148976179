export default (theme) => ({
  dialogFooter: {
    padding: 20,
  },
  btnCancel: {
    'color': '#FFF',
    'backgroundColor': '#E5AF5A',
    'marginRight': '10px',
    '&:hover': {
      backgroundColor: '#E5AF5A',
    },
  },
  DialogContent: {
    padding: '0px 24px',
    width: '450px',
    height: '30px',
  },
  fieldEventName: {
    display: 'block',
    border: 0,
    padding: '10px 24px',
  },
  btnPublish: {
    'color': '#FFF',
    'backgroundColor': '#00AFF0',
    '&:hover': {
      backgroundColor: '#00AFF0',
    },
  },
});

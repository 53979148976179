/* eslint-disable max-len */
export const initialState = {
  isLoading: false,
  isLoadingMovement: false,
  isLoadingDetail: false,
  isLoadingDelete: false,
  isError: false,
  isErrorMovement: false,
  isErrorDelete: false,
  isErrorDetail: false,
  isSuccessDetail: false,
  isSuccessDelete: false,
  isSuccess: false,
  isSuccessMovement: false,
  trigger: '',
  triggerMovement: '',
  triggerDelete: '',
  masterInventoryData: [],
  masterInventoryDataById: [],
  masterInventoryCategory: [],
  masterInventoryMeta: {page: 1, total: 0},
  message: {
    name: '',
  },
  messageMovement: {
    name: '',
  },

  conditionHistoryData: [],
  conditionHistoryDataById: [],
  conditionHistoryMeta: {page: 1, total: 0},

  movementHistoryData: [],
  movementHistoryDataById: [],
  movementHistoryMeta: {page: 1, total: 0},

  exportLoading: false,
  exportData: {file: null},
  isLoadingPost: false,
  isSuccessPost: false,
  isErrorPost: false,
  messagePost: '',
  status: null,
};

export const MASTER_INVENTORY_STATE = {
  REQUEST: 'MASTER_INVENTORY_STATE_REQUEST',
  REQUEST_BY_ID: 'MASTER_INVENTORY_STATE_REQUEST_ID',
  REQUEST_FAILED: 'MASTER_INVENTORY_STATE_REQUEST_FAILED',
  CLEAR_STATE: 'MASTER_INVENTORY_STATE_CLEAR_STATE',

  FETCH: 'MASTER_INVENTORY_STATE_FETCH',
  FETCH_SUCCCESS: 'MASTER_INVENTORY_STATE_FETCH_SUCCCESS',
  FETCH_FAILED: 'MASTER_INVENTORY_STATE_FETCH_FAILED',

  FETCH_DETAIL: 'MASTER_INVENTORY_STATE_FETCH_DETAIL',
  FETCH_DETAIL_SUCCCESS: 'MASTER_INVENTORY_STATE_FETCH_DETAIL_SUCCCESS',
  FETCH_DETAIL_FAILED: 'MASTER_INVENTORY_STATE_FETCH_DETAIL_FAILED',

  GET_CATEGORY: 'MASTER_INVENTORY_STATE_GET_CATEGORY',
  GET_CATEGORY_SUCCCESS: 'MASTER_INVENTORY_STATE_GET_CATEGORY_SUCCCESS',
  GET_CATEGORY_FAILED: 'MASTER_INVENTORY_STATE_GET_CATEGORY_FAILED',

  CREATE: 'MASTER_INVENTORY_STATE_CREATE',
  CREATE_SUCCESS: 'MASTER_INVENTORY_STATE_CREATE_SUCCESS',
  CREATE_FAILED: 'MASTER_INVENTORY_STATE_CREATE_FAILED',

  UPDATE: 'MASTER_INVENTORY_STATE_UPDATE',
  UPDATE_SUCCESS: 'MASTER_INVENTORY_STATE_UPDATE_SUCCESS',
  UPDATE_FAILED: 'MASTER_INVENTORY_STATE_UPDATE_FAILED',

  DELETE: 'MASTER_INVENTORY_STATE_DELETE',
  DELETE_SUCCESS: 'MASTER_INVENTORY_STATE_DELETE_SUCCESS',
  DELETE_FAILED: 'MASTER_INVENTORY_STATE_DELETE_FAILED',

  EXPORT: 'MASTER_INVENTORY_STATE_EXPORT',
  EXPORT_SUCCESS: 'MASTER_INVENTORY_STATE_EXPORT_SUCCESS',
  EXPORT_FAILED: 'MASTER_INVENTORY_STATE_EXPORT_FAILED',

  FETCH_CONDITION_HISTORY: 'MASTER_INVENTORY_STATE_FETCH_CONDITION_HISTORY',
  FETCH_CONDITION_HISTORY_SUCCCESS: 'MASTER_INVENTORY_STATE_FETCH_CONDITION_HISTORY_SUCCCESS',
  FETCH_CONDITION_HISTORY_FAILED: 'MASTER_INVENTORY_STATE_FETCH_CONDITION_HISTORY_FAILED',

  FETCH_MOVEMENT_HISTORY: 'MASTER_INVENTORY_STATE_FETCH_MOVEMENT_HISTORY',
  FETCH_MOVEMENT_HISTORY_SUCCCESS: 'MASTER_INVENTORY_STATE_FETCH_MOVEMENT_HISTORY_SUCCCESS',
  FETCH_MOVEMENT_HISTORY_FAILED: 'MASTER_INVENTORY_STATE_FETCH_MOVEMENT_HISTORY_FAILED',

  FETCH_CONDITION_HISTORY_DETAIL: 'MASTER_INVENTORY_STATE_FETCH_CONDITION_HISTORY_DETAIL',
  FETCH_CONDITION_HISTORY_DETAIL_SUCCCESS: 'MASTER_INVENTORY_STATE_FETCH_CONDITION_HISTORY_DETAIL_SUCCCESS',
  FETCH_CONDITION_HISTORY_DETAIL_FAILED: 'MASTER_INVENTORY_STATE_FETCH_CONDITION_HISTORY_DETAIL_FAILED',

  CREATE_CONDITION_HISTORY: 'MASTER_INVENTORY_STATE_CREATE_CONDITION_HISTORY',
  CREATE_CONDITION_HISTORY_SUCCCESS: 'MASTER_INVENTORY_STATE_CREATE_CONDITION_HISTORY_SUCCCESS',
  CREATE_CONDITION_HISTORY_FAILED: 'MASTER_INVENTORY_STATE_CREATE_CONDITION_HISTORY_FAILED',

  UPDATE_CONDITION_HISTORY: 'MASTER_INVENTORY_STATE_UPDATE_CONDITION_HISTORY',
  UPDATE_CONDITION_HISTORY_SUCCCESS: 'MASTER_INVENTORY_STATE_UPDATE_CONDITION_HISTORY_SUCCCESS',
  UPDATE_CONDITION_HISTORY_FAILED: 'MASTER_INVENTORY_STATE_UPDATE_CONDITION_HISTORY_FAILED',

  DELETE_CONDITION_HISTORY: 'MASTER_INVENTORY_STATE_DELETE_CONDITION_HISTORY',
  DELETE_CONDITION_HISTORY_SUCCCESS: 'MASTER_INVENTORY_STATE_DELETE_CONDITION_HISTORY_SUCCCESS',
  DELETE_CONDITION_HISTORY_FAILED: 'MASTER_INVENTORY_STATE_DELETE_CONDITION_HISTORY_FAILED',

  DELETE_IMAGE: 'MASTER_INVENTORY_STATE_DELETE_IMAGE',
  DELETE_IMAGE_SUCCCESS: 'MASTER_INVENTORY_STATE_DELETE_IMAGE_SUCCCESS',
  DELETE_IMAGE_FAILED: 'MASTER_INVENTORY_STATE_DELETE_IMAGE_FAILED',
};
